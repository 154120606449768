export default {
    datepickerRow: {
        display: "flex",
        alignItems: "center",

        "@media (max-width: 767px)": {
            flexDirection: "column",
        },
    },
    datepickerContainer: {
        display: "flex",
        alignItems: "center",
        marginRight: 15,

        "@media (max-width: 767px)": {
            marginRight: 0,
        },
    },
    datepickerBtn: {
        opacity: ".3",
    },
};
