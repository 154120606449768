import actionCreators from "./actionCreators";
import CalendarEvent from "../../fixtures/CalendarEvent";

export const initialState = {
    events: {
        data: [],
        start: null,
    },
    subject: { id: null },
    searchBy: null,
    event: CalendarEvent,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.selectCalendarSubject.type: {
            let events = state.events;
            if (payload.id === null) {
                // clear event if subject is null
                events = initialState.events;
            }
            return {
                ...state,
                subject: payload,
                events: events,
            };
        }
        case actionCreators.createListEvents.type:
            return {
                ...state,
                events: payload,
            };
        case actionCreators.clearListEvents.type:
            return {
                ...state,
                events: initialState.events,
            };
        case actionCreators.receiveEventDetails.type:
            return {
                ...state,
                event: payload,
            };
        case actionCreators.clearEventDetails.type:
            return {
                ...state,
                event: initialState.event,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.setSearchBy.type:
            return {
                ...state,
                searchBy: payload,
            };
        default:
            return state;
    }
};
