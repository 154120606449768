import { of } from "rxjs";
import { mergeMap, mapTo, catchError, concatMap, tap, switchMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as sessiontActionCreators } from "../Sessions/actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";
import FileSaver from "file-saver";
import { push } from "connected-react-router";

const officeHoursAppointmentsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListOfficeHoursAppointments.type,
            actionCreators.getListPastOfficeHoursAppointments.type,
            actionCreators.loadMoreOfficeHoursAppointments.type,
            actionCreators.loadMorePastOfficeHoursAppointments.type,
            actionCreators.getOneOfficeHoursAppointmentDetails.type,
            actionCreators.getListOfficeHoursAppointmentHistory.type,
            actionCreators.loadMoreOfficeHoursAppointmentHistory.type,
            actionCreators.getSyncDetails.type,
            actionCreators.gettOfficeHoursSchoolYearsAndTerms.type,
            actionCreators.downloadOfficeHoursAppointmentsHistoryReport.type,
            actionCreators.cancelOfficeHoursAppointment.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const officeHoursAppointmentsClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListOfficeHoursAppointments.type,
            actionCreators.updateListOfficeHoursAppointments.type,
            actionCreators.createOneOfficeHoursAppointmentSession.type,
            actionCreators.createListOfficeHoursAppointmentHistory.type,
            actionCreators.updateListOfficeHoursAppointmentHistory.type,
            actionCreators.updateSyncDetails.type,
            actionCreators.createOfficeHoursSchoolYearsAndTerms.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListOfficeHoursAppointmentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListOfficeHoursAppointments.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/${payload.userId}/appointments/?${
                            payload.query ? payload.query : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createListOfficeHoursAppointments.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getListPastOfficeHoursAppointmentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListPastOfficeHoursAppointments.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/${payload.userId}/past-appointments/?${
                            payload.query ? payload.query : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createListOfficeHoursAppointments.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreOfficeHoursAppointmentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreOfficeHoursAppointments.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/${payload.userId}/appointments/?${
                            payload.query ? payload.query : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListOfficeHoursAppointments.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMorePastOfficeHoursAppointmentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMorePastOfficeHoursAppointments.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/${payload.userId}/past-appointments/?${
                            payload.query ? payload.query : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListOfficeHoursAppointments.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneOfficeHoursAppointmentDetailsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneOfficeHoursAppointmentDetails.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/office-hours-session-slot/${payload}/`)).pipe(
                concatMap(({ response }) =>
                    of(
                        actionCreators.createOneOfficeHoursAppointmentDetails.create(response),
                        actionCreators.getOneOfficeHoursAppointmentSession.create(
                            response.sessionId
                        )
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneOfficeHoursAppointmentSessionEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneOfficeHoursAppointmentSession.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/office-hours-session/${payload}/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createOneOfficeHoursAppointmentSession.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getListOfficeHoursAppointmentHistoryEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListOfficeHoursAppointmentHistory.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours-session-slot-history/${payload.slotId}/?${
                            payload.query ? payload.query : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createListOfficeHoursAppointmentHistory.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreOfficeHoursAppointmentHistoryEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreOfficeHoursAppointmentHistory.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours-session-slot-history/${payload.slotId}/?${
                            payload.query ? payload.query : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListOfficeHoursAppointmentHistory.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );
const getSyncDetailsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSyncDetails.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl(`api/calendar/sync/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateSyncDetails.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const gettOfficeHoursSchoolYearsAndTermsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.gettOfficeHoursSchoolYearsAndTerms.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl("staff/office-hours-report/school-year/")).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createOfficeHoursSchoolYearsAndTerms.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const downloadOfficeHoursAppointmentsHistoryReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.downloadOfficeHoursAppointmentsHistoryReport.type),
        mergeMap(({ payload }) =>
            ajax
                .blob(
                    ajax.apiUrl(
                        `staff/office-hours-report/${payload.userId}/appointment-history/${payload.ext}/?from=${payload.from}&to=${payload.to}`
                    )
                )
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(
                            response,
                            `AppointmentsHistoryReport${payload.userId}.${payload.ext}`
                        )
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const cancelOfficeHoursAppointmentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.cancelOfficeHoursAppointment.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(
                        `staff/office-hours-session-slot/${payload.slotId}/cancel-booking/`
                    ),
                    payload.data
                )
                .pipe(
                    mergeMap(() => of(actionCreators.pushListAppointments.create())),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const markOfficeHoursAppointmentAsStudentAttendedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.markOfficeHoursAppointmentAsStudentAttended.type),
        mergeMap(({ payload }) =>
            ajax
                .put(ajax.apiUrl(`staff/office-hours-session-slot/${payload}/student-attended/`))
                .pipe(
                    concatMap(({ response }) =>
                        of(
                            actionCreators.updateOfficeHoursAppointmentStudentAttendance.create(
                                response
                            ),
                            sessiontActionCreators.updateOfficeHoursSessionSlotAttendance.create(
                                response
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const markOfficeHoursAppointmentAsStudentNotAttendedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.markOfficeHoursAppointmentAsStudentNotAttended.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(`staff/office-hours-session-slot/${payload}/student-not-attended/`)
                )
                .pipe(
                    concatMap(({ response }) =>
                        of(
                            actionCreators.updateOfficeHoursAppointmentAttendanceComment.create(
                                response
                            ),
                            sessiontActionCreators.updateOfficeHoursSessionSlotAttendance.create(
                                response
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const putOfficeHoursAppointmentAttendanceCommentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putOfficeHoursAppointmentAttendanceComment.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(`staff/office-hours-session-slot/${payload.slotId}/add-comment/`),
                    payload.data
                )
                .pipe(
                    concatMap(({ response }) =>
                        of(
                            actionCreators.updateOfficeHoursAppointmentAttendanceComment.create(
                                response
                            ),
                            sessiontActionCreators.updateOfficeHoursSessionSlotAttendance.create(
                                response
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const pushListAppointmentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListAppointments.type),
        mapTo(push("/bookings/appointments"))
    );

export const epics = combineEpics(
    officeHoursAppointmentsStartLoadingEpic,
    officeHoursAppointmentsClearLoadingEpic,
    getListOfficeHoursAppointmentsEpic,
    getListPastOfficeHoursAppointmentsEpic,
    loadMoreOfficeHoursAppointmentsEpic,
    loadMorePastOfficeHoursAppointmentsEpic,
    getOneOfficeHoursAppointmentDetailsEpic,
    getOneOfficeHoursAppointmentSessionEpic,
    getListOfficeHoursAppointmentHistoryEpic,
    loadMoreOfficeHoursAppointmentHistoryEpic,
    getSyncDetailsEpic,
    gettOfficeHoursSchoolYearsAndTermsEpic,
    downloadOfficeHoursAppointmentsHistoryReportEpic,
    cancelOfficeHoursAppointmentEpic,
    pushListAppointmentsEpic,
    markOfficeHoursAppointmentAsStudentAttendedEpic,
    markOfficeHoursAppointmentAsStudentNotAttendedEpic,
    putOfficeHoursAppointmentAttendanceCommentEpic
);
