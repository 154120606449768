import { Component } from "react";
import { connect } from "react-redux";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as audienceActionCreators } from "../../../store/UsersAndAudiences/Audiences/actionCreators";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import PromptDialog from "../../../common/components/PromptDialog";

import { MainTitle } from "../../../common/components/MainTitle";
import FilterRow from "../../../common/components/FilterRow";
import { TableComponent, RedirectButton } from "../../../common/components/UsersAndAudiences";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import { audienceTypeCallback } from "../../../common/services/tableCallbacks";
import formsCss from "../../../common/styles/forms.css";
import {
    EditIcon,
    DeleteIcon,
    DownloadIcon,
    AutoRenewIcon,
    PersonIcon,
} from "../../../common/components/icons";
import { withQueryParams } from "../../../common/components/withQueryParams";
import { prepareFilterQuery } from "../../../common/services/utils";
import { hasAdminRole } from "../../../common/services/Auth";
import { default as channelActionCreators } from "../../../store/Channels/Pages/actionCreators";

const callbacks = {
    audienceType: audienceTypeCallback,
};

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            numStudents: "SH",
            lseNumStudents: "LSE",
            audienceType: "Type",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            lseNumStudents: tableCss.numStudents,
        },
    },
];

const buttons = items => index => {
    const result = [];

    if (
        items[index] &&
        (items[index].audienceType === "draft" || items[index].audienceType === "published")
    ) {
        result.push({
            path: `/users-and-audiences/audiences/edit/${items[index] ? items[index].id : ""}`,
            label: "Edit",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
        });

        result.push({
            path: `${items[index] ? items[index].id : null}`,
            label: "Delete",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        });
    } else if (items[index] && items[index].audienceType === "custom") {
        result.push({
            path: `/users-and-audiences/audiences/edit-custom/${
                items[index] ? items[index].id : ""
            }`,
            label: "Edit",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
        });

        result.push({
            path: `${items[index] ? items[index].id : null}`,
            label: "Delete",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        });
    }

    if (items[index]) {
        result.push({
            path: { id: items[index].id, extension: "xlsx" },
            label: "Report",
            fnLabel: "report",
            icon: <DownloadIcon css={{ ...tableCss.actionsMenuItemIcon }} />,
        });
    }

    return result;
};

class AudienceListContainer extends Component {
    state = {
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
    };

    componentDidMount() {
        const { setTitle, getListAudience, queryParams } = this.props;
        const { filter$ } = this.state;
        setTitle("Audiences");

        const params = this.props.initWithQueryParams({
            filter: "",
            page: 1,
        });

        if (queryParams !== prepareFilterQuery(params)) {
            getListAudience({ ...params, page: 1 });
        }

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(filter => {
            const params = this.props.setQueryParams({ ...filter, page: 1 });
            getListAudience(params);
        });
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    handleFilterChange = ({ target: { value } }) => {
        const { filter$ } = this.state;
        const params = this.props.setQueryParams({
            filter: value ? value : "",
            page: 1,
        });
        filter$.next(params);
    };

    handleLoadMoreClick = page => () => {
        const { loadMoreAudience } = this.props;
        const params = this.props.setQueryParams({ page });
        loadMoreAudience(params);
    };

    remove = itemID => {
        this.handleOpen(itemID);
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removeAudience({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    render() {
        const {
            audiences,
            count,
            nextPage,
            permissionLevel,
            refreshAllFollowers,
            history: { push },
            downloadReport,
        } = this.props;
        const { remove } = this;
        const { filter } = this.props.getQueryParams();
        return (
            <div>
                <MainTitle title="Audiences" type="[Users &amp; Audiences]" />
                <div css={[globalsCss.actions, globalsCss.actionsSpaceBetween]}>
                    <div>
                        <RedirectButton
                            label="Create new custom audience"
                            url="/users-and-audiences/audiences/new-custom"
                            {...{ push }}
                            css={globalsCss.helpers.mr20}
                        />
                        <RedirectButton
                            label="Upload new audience from XLS"
                            url="/users-and-audiences/audiences/new"
                            {...{ push }}
                        />
                    </div>
                    {hasAdminRole(permissionLevel) && (
                        <RedirectButton
                            label="Recalculate followers (All Pages) "
                            onClick={() => refreshAllFollowers()}
                            variant="text"
                            color="default"
                            elementLeft={<PersonIcon css={formsCss.btnIcon} />}
                        />
                    )}
                </div>
                <Paper elevation={1}>
                    <FilterRow filter={filter} onFilterChangeHandler={this.handleFilterChange} />
                    <TableComponent
                        items={audiences}
                        fns={{ push: push, remove, report: downloadReport }}
                        buttons={buttons(audiences)}
                        decorators={decorators}
                        callbacks={callbacks}
                    />

                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this audience?
                    </PromptDialog>
                </Paper>
                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.handleLoadMoreClick(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {audiences.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListAudience: audienceActionCreators.getListAudience.create,
    loadMoreAudience: audienceActionCreators.loadMoreAudience.create,
    downloadReport: audienceActionCreators.downloadAudienceReport.create,
    removeAudience: audienceActionCreators.removeAudience.create,
    refreshAllFollowers: channelActionCreators.refreshAllFollowers.create,
};

const mapStateToProps = state => ({
    audiences: state.Audience.list.data.map(a => ({
        ...a,
        audienceType: a.audienceType === "departament" ? "automatic" : a.audienceType,
    })),
    count: state.Audience.list.count,
    nextPage: state.Audience.list.nextPage,
    queryParams: state.Audience.list.queryParams,
    permissionLevel: state.Auth.permissionLevel,
});

export default connect(mapStateToProps, dispatchToProps)(withQueryParams(AudienceListContainer));
