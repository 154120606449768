import moment from "moment-timezone";

export default values => {
    const errors = {};
    const requiredFields = ["status", "name", "startAt", "location", "description", "info"];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    if (!values.channel || !values.channel.id || !values.channel.name) {
        errors["channel"] = "Required";
    }

    if (values["status"] === "scheduled") {
        if (!values["publicationDate"]) {
            errors["publicationDate"] = "Required";
        } else if (moment(values["publicationDate"]).diff(moment()) <= 0) {
            errors["publicationDate"] = "Scheduled date must be in the future";
        }
    }

    if (values["charCount"] && parseInt(values["charCount"]) > 500) {
        errors["description"] = "We recommend you keep posts to around 500 characters";
    }

    return errors;
};
