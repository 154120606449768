import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";

const startLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getComments.type, actionCreators.deleteComment.type),
        mapTo(UIActionCreators.setLoading.create())
    );

const clearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updateComments.type,
            actionCreators.removeComment.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getCommentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getComments.type),
        switchMap(({ payload: { id, page } }) =>
            ajax.get(ajax.apiUrl(`api/comment/post/${id}/${page}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateComments.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const deleteCommentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteComment.type),
        switchMap(({ payload: { id } }) =>
            ajax
                .remove(ajax.apiUrl(`staff/comment/${id}/`))
                .pipe(
                    mapTo(actionCreators.removeComment.create(id)),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    startLoadingEpic,
    clearLoadingEpic,
    getCommentsEpic,
    deleteCommentEpic
);
