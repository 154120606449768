/** @jsx jsx */
import { jsx } from "@emotion/core";
import Paper from "@material-ui/core/Paper";

import globalsCss from "../../common/styles/globals.css";
import bugsnagClient from "../../common/services/bugsnag";

const ServerError = () => {
    bugsnagClient.notify(new Error("Error page displayed"), function(event) {
        event.severity = "error";
    });
    return (
        <Paper elevation={1}>
            <div css={globalsCss.inner}>
                <h1>500</h1>
                <h3>Internal Server Error</h3>
                <p>
                    We&apos;re sorry, the server encountered an internal error and was unable to
                    complete your request.
                </p>
            </div>
        </Paper>
    );
};

export default ServerError;
