/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import toolbarCss from "../styles/toolbar.css";
import datepickerCss from "../styles/datepicker.css";
import { ClearIcon } from "./icons";

export default withStyles(toolbarCss)(
    ({
        onDateChangeHandler,
        from,
        to,
        isFromError,
        isToError,
        classes,
        labelDate = "Filter by Publication Date",
    }) => (
        <AppBar
            position="static"
            classes={{
                root: classes.appBarRoot,
            }}
            elevation={0}
        >
            <Toolbar
                classes={{
                    root: classes.toolbarRoot,
                }}
            >
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>{labelDate}:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Date:</span>
                        </span>
                    </Typography>
                    <div css={datepickerCss.datepickerRow}>
                        <div css={datepickerCss.datepickerContainer}>
                            <DatePicker
                                placeholder="From..."
                                value={from !== "" ? from : null}
                                onChange={value =>
                                    onDateChangeHandler("from")(
                                        value.set({ hour: 0, minute: 0, second: 0 })
                                    )
                                }
                                fullWidth
                                autoOk={true}
                                format={"D MMM YYYY"}
                                color="primary"
                                css={toolbarCss.toolbarDatePicker}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                error={!!isFromError}
                                helperText={isFromError ? isFromError : ""}
                            />
                            <IconButton
                                onClick={() => onDateChangeHandler("from")(null)}
                                css={datepickerCss.datepickerBtn}
                            >
                                <ClearIcon />
                            </IconButton>
                        </div>
                        <div css={datepickerCss.datepickerContainer}>
                            <DatePicker
                                placeholder="To..."
                                value={to !== "" ? to : null}
                                onChange={value =>
                                    onDateChangeHandler("to")(
                                        value.set({ hour: 23, minute: 59, second: 59 })
                                    )
                                }
                                fullWidth
                                autoOk={true}
                                format={"D MMM YYYY"}
                                color="primary"
                                css={toolbarCss.toolbarDatePicker}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                error={!!isToError}
                                helperText={isToError ? isToError : ""}
                            />
                            <IconButton
                                onClick={() => onDateChangeHandler("to")(null)}
                                css={datepickerCss.datepickerBtn}
                            >
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
);
