import actionCreators from "./actionCreators";
import academic from "../../../fixtures/OfficeHoursAcademic";

export const initialState = {
    academics: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    academic,
    errorRes: { error: "", errors: {} },
    currentFormType: null,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListOfficeHoursAcademics.type:
            return {
                ...state,
                academics: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListOfficeHoursAcademics.type:
            payload.data = [
                ...state.academics.data,
                ...payload.data.filter(
                    academic => !state.academics.data.map(item => item.id).includes(academic.id)
                ),
            ];
            return {
                ...state,
                academics: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneOfficeHoursAcademic.type:
            return {
                ...state,
                academic:
                    payload.data && payload.data.length > 0
                        ? payload.data[0]
                        : initialState.academic,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOfficeHoursAcademic.type:
            return {
                ...state,
                academic: initialState.academic,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
