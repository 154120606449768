import { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, TableRow, TableCell } from "@material-ui/core";
import { ImageIcon } from "./icons";
import tableCss from "../styles/table.css";
import dialogCss from "./../styles/dialog.css";

import { decorateTableRow, decorateTableHeader } from "../services/decorateTableItems";
import ContextMenu from "./ContextMenu";
import RowDialogField from "./RowDialogField";
/** @jsx jsx */
import { jsx } from "@emotion/core";

export const RowItem = withStyles(tableCss)(
    ({ item, fns, buttons, decorators, columns, callbacks, config, classes }) => (
        <TableRow key={item[config.key]} className={classes.tableRow}>
            {columns.map(prop => (
                <TableCell
                    key={prop}
                    // 1st apply default style
                    className={classes[prop]}
                    // 2nd add custom style from decorators
                    style={
                        decorators && decorators.length
                            ? decorateTableRow(prop, decorators)
                            : undefined
                    }
                >
                    {prop === "photoUrl" && item[prop] ? (
                        <Fragment>
                            <strong className={classes.showOnMobile}>Photo:&nbsp;</strong>
                            <RowDialogField
                                iconImage={<ImageIcon />}
                                dialogContent={
                                    <img src={item[prop]} alt="" css={dialogCss.dialogImgImg} />
                                }
                                maxWidth="sm"
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <strong className={classes.showOnMobile}>
                                {decorators
                                    ? decorateTableHeader(prop, decorators)
                                    : prop.replace(/\b\w/g, chr => chr.toUpperCase())}
                                :&nbsp;
                            </strong>
                            {callbacks[prop] ? callbacks[prop](item, prop) : item[prop]}
                        </Fragment>
                    )}
                </TableCell>
            ))}
            {buttons ? (
                <TableCell className={classes.actions}>
                    {Object.keys(buttons).length === 1 ? (
                        !!buttons[0] && (
                            <IconButton
                                aria-label={buttons[0].label}
                                onClick={() => {
                                    if (
                                        buttons[0].fnLabel === "push" &&
                                        typeof buttons[0].path === "string"
                                    )
                                        fns[buttons[0].fnLabel]({
                                            pathname: buttons[0].path,
                                            search: buttons[0].search
                                                ? buttons[0].search
                                                : undefined,
                                            state: buttons[0].state ? buttons[0].state : undefined,
                                        });
                                    else fns[buttons[0].fnLabel](buttons[0].path);
                                }}
                            >
                                {buttons[0].icon}
                            </IconButton>
                        )
                    ) : (
                        <ContextMenu {...{ buttons, fns }} />
                    )}
                </TableCell>
            ) : (
                <TableCell className={classes.actions}></TableCell>
            )}
        </TableRow>
    )
);
