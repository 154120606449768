import { spacing } from "../../UIGlobals";

export const gridStyle = {
    row: {
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: -spacing.space2comma5,
        marginRight: -spacing.space2comma5,
    },
    col: {
        flexBasis: "33.33333%",
        maxWidth: "33.33333%",
        flex: "0 0 auto",
        paddingLeft: spacing.space2comma5,
        paddingRight: spacing.space2comma5,
        "@media (max-width: 768px)": {
            flexBasis: "100%",
            maxWidth: "100%",
        },
    },
};
