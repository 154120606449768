import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./Form";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { default as administratorsActionCreators } from "../../../store/OfficeHours/Administrators/actionCreators";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";

class OfficeHoursAcademicsNewContainer extends Component {
    componentDidMount() {
        const { setTitle } = this.props;
        setTitle("Bookings \u203A Associated Academics");
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    onSubmit = values => {
        const { addOfficeHoursAcademicToAdmin } = this.props;
        const last = values.admins.slice(-1)[0];
        for (let { id } of values.admins) {
            addOfficeHoursAcademicToAdmin({
                id,
                academicId: values.academic.id,
                redirectToListAcademics: last.id === id,
            });
        }
    };

    render() {
        const {
            initialValues,
            history: { push },
            admins,
            academic,
            errorRes: { error, errors },
            getAcademics,
            getAdmins,
            clearAcademics,
            clearAdmins,
        } = this.props;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.NEW;

        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Add New Managed Academic</h2>
                        <Form
                            {...{
                                initialValues,
                                onSubmit,
                                currentFormType,
                                push,
                                admins,
                                academic,
                                getAcademics,
                                getAdmins,
                                clearAcademics,
                                clearAdmins,
                                errors,
                                formError: error,
                            }}
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getAcademics: AutoCompleteActionCreators.getOfficeHoursAcademics.create,
    getAdmins: AutoCompleteActionCreators.getOfficeHoursAdministrators.create,
    clearAcademics: AutoCompleteActionCreators.clearOfficeHoursAcademics.create,
    clearAdmins: AutoCompleteActionCreators.clearOfficeHoursAdministrators.create,
    addOfficeHoursAcademicToAdmin:
        administratorsActionCreators.addOfficeHoursAcademicToAdmin.create,
};
const mapStateToProps = ({ OfficeHoursAcademics, AutoComplete }) => ({
    initialValues: { academic: {}, admins: [] },
    errorRes: OfficeHoursAcademics.errorRes,
    admins: AutoComplete.officehoursadministrators,
    academic: AutoComplete.officehoursacademics,
});
export default connect(mapStateToProps, dispatchToProps)(OfficeHoursAcademicsNewContainer);
