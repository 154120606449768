import { Fragment } from "react";
import { RadioGroup } from "@material-ui/core";

import formsCss from "../../../common/styles/forms.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";

export const RenderRadioGroup = ({
    input,
    label,
    meta: { touched, error },
    errorRes,
    children,
    className = "row",
    ...rest
}) => (
    <Fragment>
        {label && <label css={formsCss.labelBig}>{label}</label>}
        <RadioGroup
            {...input}
            {...rest}
            value={input.value}
            onChange={(event, value) => input.onChange(value)}
            className={className}
        >
            {children}
        </RadioGroup>
        {touched && (error || errorRes) && (
            <span css={formsCss.genericError}>{touched && (error || errorRes)}</span>
        )}
    </Fragment>
);
