import { of } from "rxjs";
import { switchMap, mergeMap, catchError, withLatestFrom, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import * as ajax from "../../common/services/utils";

const froalaRemoveImagesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeFroalaImages.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl("api/image/delete"), action.payload).pipe(
                mergeMap(({ response }) => of(actionCreators.logFroalaError.create(response))),
                catchError(err => of(actionCreators.logFroalaError.create(err)))
            )
        )
    );

const froalaCommitChangesEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionCreators.commitFroalaChanges.type),
        withLatestFrom(state$),
        map(([, { Froala }]) => Froala.removedImages),
        mergeMap(images => {
            if (images.length > 0) {
                return of(
                    actionCreators.cleanFroalaImages.create(),
                    actionCreators.removeFroalaImages.create(images)
                );
            }

            return of(actionCreators.cleanFroalaImages.create());
        })
    );

const froalaRollbackChangesEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionCreators.rollbackFroalaChanges.type),
        withLatestFrom(state$),
        map(([, { Froala }]) => Froala.removedImages),
        mergeMap(images => {
            if (images.length > 0) {
                return of(
                    actionCreators.cleanFroalaImages.create(),
                    actionCreators.removeFroalaImages.create(images)
                );
            }

            return of(actionCreators.cleanFroalaImages.create());
        })
    );

export const epics = combineEpics(
    froalaRemoveImagesEpic,
    froalaCommitChangesEpic,
    froalaRollbackChangesEpic
);
