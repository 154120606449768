/** @jsx jsx */
import { jsx } from "@emotion/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Tooltip } from "@material-ui/core";

export const InfoTooltip = ({ text, mt = 0, mr = 0, mb = 12, ml = 16 }) => {
    return (
        <Tooltip
            title={text}
            aria-label={text}
            css={{
                fontSize: 16,
                cursor: "help",
                margin: `${mt ? mt : 0}px ${mr ? mr : 0}px ${mb ? mb : 0}px ${ml ? ml : 0}px`,
                color: "#949494",
            }}
            disableFocusListener
            disableTouchListener
        >
            <HelpOutlineIcon fontSize="inherit" color="inherit" />
        </Tooltip>
    );
};
