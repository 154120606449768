import { Component } from "react";
import Avatar from "../Avatar";
import { channelTitle } from "./ChannelTitleStyles.js";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { TimeFromNow } from "../Time";

class ChannelTitle extends Component {
    render() {
        const {
            channel: { iconUrl, name },
            publicationDate,
        } = this.props;

        return (
            <div css={channelTitle.body}>
                <div css={channelTitle.bodyLeft}>
                    <Avatar src={iconUrl} />
                </div>
                <div css={channelTitle.bodyCenter}>
                    <div css={channelTitle.name}>
                        <div css={channelTitle.nameLink}>{name}</div>
                    </div>
                    <TimeFromNow css={channelTitle.date} date={publicationDate} />
                </div>
            </div>
        );
    }
}

export default ChannelTitle;
