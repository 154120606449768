import { prepareFilterQuery } from "./utils";

export const updateListOnFilterChange = ({ getFn, filter }) => {
    getFn(prepareFilterQuery(filter));
};

export const rawUpdateListOnFilterChange = ({ getFn, filter }) => {
    getFn(filter);
};

export const rawForceUpdateListOnFilterChange = ({ getFn, filter }) => {
    getFn(filter);
};
