import { of, empty, concat } from "rxjs";
import { mergeMap, mapTo, switchMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import { default as sessionActionCreators } from "../Sessions/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";
import { GENERIC_AVAILABILITY } from "../../../app/OfficeHours/ManageOfficeHours/types";

const officeHoursAvailabilitiesStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getMyOfficeHoursAcademics.type,
            actionCreators.loadMoreMyOfficeHoursAcademics.type,
            actionCreators.getMyOfficeHoursTeams.type,
            actionCreators.getMyOfficeHoursTeamAcademics.type,
            actionCreators.loadMoreMyOfficeHoursTeamAcademics.type,
            actionCreators.getUserAvailabilities.type,
            actionCreators.getTeamUserAvailabilities.type,
            actionCreators.getOneMyOfficeHoursAcademic.type,
            actionCreators.getOneMyOfficeHoursTeam.type,
            actionCreators.pushMyOfficeHoursAvailability.type,
            actionCreators.putMyOfficeHoursAvailability.type,
            actionCreators.safePutMyOfficeHoursAvailability.type,
            actionCreators.pushMyOfficeHoursGenericAvailability.type,
            actionCreators.putMyOfficeHoursGenericAvailability.type,
            actionCreators.safePutMyOfficeHoursGenericAvailability.type,
            actionCreators.removeMyOfficeHoursAvailability.type,
            actionCreators.getOneMyOfficeHoursAvailability.type,
            actionCreators.getOfficeCalendarSlots.type,
            actionCreators.getSlotsForCancellation.type,
            actionCreators.getSlotsForAvailability.type,
            actionCreators.getSlotsForGenericCancellation.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const officeHoursAvailabilitiesClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createMyOfficeHoursAcademics.type,
            actionCreators.updateListMyOfficeHoursAcademics.type,
            actionCreators.createMyOfficeHoursTeams.type,
            actionCreators.createMyOfficeHoursTeamAcademics.type,
            actionCreators.updateListMyOfficeHoursTeamAcademics.type,
            actionCreators.errorResponse.type,
            actionCreators.createAvailabilities.type,
            actionCreators.createOneMyOfficeHoursAcademic.type,
            actionCreators.createOneMyOfficeHoursTeam.type,
            actionCreators.pushListMyOfficeHoursAvailabilities.type,
            actionCreators.pushListMyOfficeHoursTeamAvailabilities.type,
            actionCreators.createOneMyOfficeHoursAvailability.type,
            actionCreators.createOfficeCalendarSlots.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getMyOfficeHoursAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getMyOfficeHoursAcademics.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/academics/?${action.payload ? action.payload : ""}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createMyOfficeHoursAcademics.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreMyOfficeHoursAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreMyOfficeHoursAcademics.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/academics/?${action.payload ? action.payload : ""}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListMyOfficeHoursAcademics.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getMyOfficeHoursTeamsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getMyOfficeHoursTeams.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/office-hours-team/${action.payload}/teams/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createMyOfficeHoursTeams.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getMyOfficeHoursTeamAcademicsEpics = action$ =>
    action$.pipe(
        ofType(actionCreators.getMyOfficeHoursTeamAcademics.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours-team/${action.payload.teamId}/members/?${
                            action.payload.filter ? `filter=${action.payload.filter}` : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createMyOfficeHoursTeamAcademics.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreMyOfficeHoursTeamAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreMyOfficeHoursTeamAcademics.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours-team/${action.payload.teamId}/members/?${
                            action.payload.query ? action.payload.query : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListMyOfficeHoursTeamAcademics.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getUserAvailabilitiesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getUserAvailabilities.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/office-hours/availability/${payload.userId}/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createAvailabilities.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getTeamUserAvailabilitiesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getTeamUserAvailabilities.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/team/${payload.teamId}/availability/${payload.userId}/`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createAvailabilities.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getTeamGenericAvailabilitiesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getTeamGenericAvailabilities.type),
        switchMap(({ payload }) =>
            ajax
                .get(ajax.apiUrl(`staff/office-hours/team/${payload.teamId}/generic-availability/`))
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createAvailabilities.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneMyOfficeHoursAcademicEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneMyOfficeHoursAcademic.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/office-hours/academics/${payload}/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createOneMyOfficeHoursAcademic.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneMyOfficeHoursTeamEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneMyOfficeHoursTeam.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/office-hours-team/${payload}/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createOneMyOfficeHoursTeam.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newMyOfficeHoursAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushMyOfficeHoursAvailability.type),
        mergeMap(({ payload }) =>
            ajax
                .post(
                    ajax.apiUrl(
                        `staff/office-hours/availability/${payload.action}/?${
                            payload.teamMemberId
                                ? `teamMemberId=${payload.teamMemberId}`
                                : `academicId=${payload.academicId}`
                        }`
                    ),
                    payload.availability
                )
                .pipe(
                    mergeMap(() =>
                        concat(
                            of(actionCreators.hideConfirmForceRequest.create()),
                            of(
                                payload.isAcademicContext
                                    ? actionCreators.pushListMyOfficeHoursAvailabilities.create(
                                          payload.academicId
                                      )
                                    : actionCreators.pushListMyOfficeHoursTeamAvailabilities.create(
                                          {
                                              teamMemberId: payload.teamMemberId,
                                              teamId: payload.teamId,
                                          }
                                      )
                            ),
                            of(sessionActionCreators.clearOfficeHoursSessions.create())
                        )
                    ),

                    catchError(res => {
                        const handleError = errorHandler(actionCreators.errorResponse.create);
                        let errorResponse = handleError(res);
                        if (res.status === 409) {
                            errorResponse = concat(
                                errorResponse,
                                of(
                                    actionCreators.showConfirmForceRequest.create(
                                        res.response.hasSlotConflict === false &&
                                            res.response.hasMeetingConflict === true
                                            ? 1
                                            : res.response.hasSlotConflict === true &&
                                              res.response.hasMeetingConflict === false
                                            ? 2
                                            : res.response.hasSlotConflict === true &&
                                              res.response.hasMeetingConflict === true
                                            ? 3
                                            : false
                                    )
                                )
                            );
                        }

                        return errorResponse;
                    })
                )
        )
    );

const pushListMyOfficeHoursAvailabilitiesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListMyOfficeHoursAvailabilities.type),
        mergeMap(({ payload }) => of(push(`/bookings/availability/${payload}/manage`)))
    );

const pushListMyOfficeHoursTeamAvailabilitiesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListMyOfficeHoursTeamAvailabilities.type),
        mergeMap(({ payload }) =>
            of(push(`/bookings/availability/team/${payload.teamId}/${payload.teamMemberId}/manage`))
        )
    );

const removeMyOfficeHoursAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeMyOfficeHoursAvailability.type),
        mergeMap(({ payload }) =>
            ajax
                .remove(
                    ajax.apiUrl(`staff/office-hours/availability/${payload.id}/`),
                    {},
                    payload.reason ? { reason: payload.reason } : undefined
                )
                .pipe(
                    mergeMap(() =>
                        concat(
                            of(
                                payload.isGeneric === true
                                    ? actionCreators.pushListMyOfficeHoursGenericAvailabilitiesEpic.create(
                                          {
                                              teamId: payload.teamId,
                                          }
                                      )
                                    : payload.academicId && payload.academicId > 0
                                    ? actionCreators.pushListMyOfficeHoursAvailabilities.create(
                                          payload.academicId
                                      )
                                    : actionCreators.pushListMyOfficeHoursTeamAvailabilities.create(
                                          {
                                              teamMemberId: payload.teamMemberId,
                                              teamId: payload.teamId,
                                          }
                                      )
                            ),
                            of(actionCreators.clearSlotsForCancellation.create()),
                            of(sessionActionCreators.clearOfficeHoursSessions.create())
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneMyOfficeHoursAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneMyOfficeHoursAvailability.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/office-hours/availability/${payload}/details/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createOneMyOfficeHoursAvailability.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const safePutMyOfficeHoursAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.safePutMyOfficeHoursAvailability.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(`staff/office-hours/availability-safe-update/${payload.id}/`),
                    payload.availability
                )
                .pipe(
                    mergeMap(() =>
                        concat(
                            of(actionCreators.clearSlotsForCancellation.create()),
                            of(
                                payload.academicId && payload.academicId > 0
                                    ? actionCreators.pushListMyOfficeHoursAvailabilities.create(
                                          payload.academicId
                                      )
                                    : actionCreators.pushListMyOfficeHoursTeamAvailabilities.create(
                                          {
                                              teamMemberId: payload.teamMemberId,
                                              teamId: payload.teamId,
                                          }
                                      )
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const putMyOfficeHoursAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putMyOfficeHoursAvailability.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(
                        `staff/office-hours/${payload.id}/availability-full-update/${payload.action}/`
                    ),
                    payload.availability
                )
                .pipe(
                    mergeMap(() =>
                        concat(
                            of(actionCreators.clearSlotsForCancellation.create()),
                            of(
                                payload.academicId && payload.academicId > 0
                                    ? actionCreators.pushListMyOfficeHoursAvailabilities.create(
                                          payload.academicId
                                      )
                                    : actionCreators.pushListMyOfficeHoursTeamAvailabilities.create(
                                          {
                                              teamMemberId: payload.teamMemberId,
                                              teamId: payload.teamId,
                                          }
                                      )
                            ),
                            of(sessionActionCreators.clearOfficeHoursSessions.create()),
                            of(actionCreators.hideConfirmForceRequest.create())
                        )
                    ),
                    catchError(res =>
                        concat(
                            errorHandler(actionCreators.errorResponse.create)(res),
                            res.status === 409
                                ? of(
                                      actionCreators.showConfirmForceRequest.create(
                                          res.response.hasSlotConflict === false &&
                                              res.response.hasMeetingConflict === true
                                              ? 1
                                              : res.response.hasSlotConflict === true &&
                                                res.response.hasMeetingConflict === false
                                              ? 2
                                              : res.response.hasSlotConflict === true &&
                                                res.response.hasMeetingConflict === true
                                              ? 3
                                              : false
                                      )
                                  )
                                : empty()
                        )
                    )
                )
        )
    );

const getOfficeCalendarSlotsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeCalendarSlots.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/calendar-sessions/${
                            action.payload.id
                        }/?${ajax.prepareFilterQuery(action.payload.query)}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createOfficeCalendarSlots.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOfficeHoursDefaultAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeHoursDefaultAvailability.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/${payload}/office-hours-settings/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createOfficeHoursDefaultAvailability.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const putOfficeHoursDefaultAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putOfficeHoursDefaultAvailability.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(`staff/${payload.id}/office-hours-settings/`),
                    payload.availability
                )
                .pipe(
                    mergeMap(() => of(push(`/bookings/availability`))),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getSlotsForCancellationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSlotsForCancellation.type),
        switchMap(({ payload }) =>
            ajax
                .post(
                    ajax.apiUrl(`staff/office-hours/${payload.id}/slots-for-cancellation/`),
                    payload.availability
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createSlotsForCancellation.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getSlotsForGenericCancellationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSlotsForGenericCancellation.type),
        switchMap(({ payload }) =>
            ajax
                .post(
                    ajax.apiUrl(`staff/office-hours/${payload.id}/slots-for-cancellation-generic/`),
                    payload.availability
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createSlotsForCancellation.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getSlotsForAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSlotsForAvailability.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/office-hours/${payload}/slots-for-availability/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createSlotsForCancellation.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newMyOfficeHoursGenericAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushMyOfficeHoursGenericAvailability.type),
        mergeMap(({ payload }) =>
            ajax
                .post(
                    ajax.apiUrl(
                        `staff/office-hours/generic-availability/${
                            payload.action
                        }/?${`officeHoursTeamId=${payload.teamId}`}`
                    ),
                    payload.availability
                )
                .pipe(
                    mergeMap(() =>
                        concat(
                            of(actionCreators.hideConfirmForceRequest.create()),
                            of(
                                actionCreators.pushListMyOfficeHoursGenericAvailabilities.create({
                                    teamId: payload.teamId,
                                })
                            )
                        )
                    ),
                    catchError(res => {
                        const handleError = errorHandler(actionCreators.errorResponse.create);
                        let errorResponse = handleError(res);
                        if (res.status === 409) {
                            errorResponse = concat(
                                errorResponse,
                                of(
                                    actionCreators.showConfirmForceRequest.create(
                                        res.response.hasSlotConflict === false &&
                                            res.response.hasMeetingConflict === true
                                            ? 1
                                            : res.response.hasSlotConflict === true &&
                                              res.response.hasMeetingConflict === false
                                            ? 2
                                            : res.response.hasSlotConflict === true &&
                                              res.response.hasMeetingConflict === true
                                            ? 3
                                            : false
                                    )
                                )
                            );
                        }

                        return errorResponse;
                    })
                )
        )
    );

const pushListMyOfficeHoursGenericAvailabilitiesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListMyOfficeHoursGenericAvailabilities.type),
        mergeMap(({ payload }) =>
            of(push(`/bookings/availability/team/${payload.teamId}/${GENERIC_AVAILABILITY}/manage`))
        )
    );

const safePutMyOfficeHoursGenericAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.safePutMyOfficeHoursGenericAvailability.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(
                        `staff/office-hours/generic-availability-safe-update/${payload.id}/`
                    ),
                    payload.availability
                )
                .pipe(
                    mergeMap(() =>
                        concat(
                            of(actionCreators.clearSlotsForCancellation.create()),
                            of(
                                actionCreators.pushListMyOfficeHoursGenericAvailabilities.create({
                                    teamId: payload.teamId,
                                })
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const putMyOfficeHoursGenericAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putMyOfficeHoursGenericAvailability.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(
                        `staff/office-hours/${payload.id}/generic-availability-full-update/${payload.action}/`
                    ),
                    payload.availability
                )
                .pipe(
                    mergeMap(() =>
                        concat(
                            of(actionCreators.clearSlotsForCancellation.create()),
                            of(
                                actionCreators.pushListMyOfficeHoursGenericAvailabilities.create({
                                    teamId: payload.teamId,
                                })
                            ),
                            of(actionCreators.hideConfirmForceRequest.create())
                        )
                    ),
                    catchError(res =>
                        concat(
                            errorHandler(actionCreators.errorResponse.create)(res),
                            res.status === 409
                                ? of(
                                      actionCreators.showConfirmForceRequest.create(
                                          res.response.hasSlotConflict === false &&
                                              res.response.hasMeetingConflict === true
                                              ? 1
                                              : res.response.hasSlotConflict === true &&
                                                res.response.hasMeetingConflict === false
                                              ? 2
                                              : res.response.hasSlotConflict === true &&
                                                res.response.hasMeetingConflict === true
                                              ? 3
                                              : false
                                      )
                                  )
                                : empty()
                        )
                    )
                )
        )
    );

const getOneMyOfficeHoursGenericAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneMyOfficeHoursGenericAvailability.type),
        switchMap(({ payload }) =>
            ajax
                .get(ajax.apiUrl(`staff/office-hours/generic-availability/${payload}/details/`))
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createOneMyOfficeHoursAvailability.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    officeHoursAvailabilitiesStartLoadingEpic,
    officeHoursAvailabilitiesClearLoadingEpic,
    getMyOfficeHoursAcademicsEpic,
    loadMoreMyOfficeHoursAcademicsEpic,
    getMyOfficeHoursTeamsEpic,
    getMyOfficeHoursTeamAcademicsEpics,
    loadMoreMyOfficeHoursTeamAcademicsEpic,
    getUserAvailabilitiesEpic,
    getTeamUserAvailabilitiesEpic,
    getTeamGenericAvailabilitiesEpic,
    getOneMyOfficeHoursAcademicEpic,
    getOneMyOfficeHoursTeamEpic,
    newMyOfficeHoursAvailabilityEpic,
    pushListMyOfficeHoursAvailabilitiesEpic,
    pushListMyOfficeHoursTeamAvailabilitiesEpic,
    removeMyOfficeHoursAvailabilityEpic,
    getOneMyOfficeHoursAvailabilityEpic,
    safePutMyOfficeHoursAvailabilityEpic,
    putMyOfficeHoursAvailabilityEpic,
    getOfficeCalendarSlotsEpic,
    getOfficeHoursDefaultAvailabilityEpic,
    putOfficeHoursDefaultAvailabilityEpic,
    getSlotsForCancellationEpic,
    getSlotsForAvailabilityEpic,
    getSlotsForGenericCancellationEpic,
    newMyOfficeHoursGenericAvailabilityEpic,
    pushListMyOfficeHoursGenericAvailabilitiesEpic,
    safePutMyOfficeHoursGenericAvailabilityEpic,
    putMyOfficeHoursGenericAvailabilityEpic,
    getOneMyOfficeHoursGenericAvailabilityEpic
);
