import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Administrators/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { TableComponent, RedirectButton } from "../../../common/components/OfficeHours";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { prepareFilterQuery } from "../../../common/services/utils";
import { updateListOnFilterChange } from "../../../common/services/FilterList";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";

import PromptDialog from "../../../common/components/PromptDialog";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";

import FilterRow from "../../../common/components/FilterRow";
import TabsComponent from "../../../common/components/Tabs";
import formsCss from "../../../common/styles/forms.css";
import { EditIcon, DeleteIcon, AutoRenewIcon } from "../../../common/components/icons";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            numAssociatedAcademics: "# Academics",
        },
    },
];

const buttons = items => index => [
    {
        path: `/bookings/administrators/edit/${items[index] ? items[index].id : ""}`,
        label: "Edit",
        fnLabel: "push",
        icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

class OfficeHoursAdministratorsListContainer extends Component {
    state = {
        filter: "",
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true)
            this.props.removeOfficeHoursAdministrator({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    componentDidMount() {
        const { setTitle, getListOfficeHoursAdministrators } = this.props;
        const { filter$ } = this.state;

        setTitle("Bookings \u203A Administrators");
        getListOfficeHoursAdministrators(prepareFilterQuery(this.state.filter));
        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                updateListOnFilterChange({
                    getFn: getListOfficeHoursAdministrators,
                    filter: term,
                })
            );
        });
    }

    onFilterChangeHandler = ({ target: { value } }) => {
        const { filter$ } = this.state;
        this.setState({ filter: value }, () => filter$.next({ filter: this.state.filter }));
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMoreOfficeHoursAdministrators } = this.props;
        const { filter } = this.state;
        loadMoreOfficeHoursAdministrators(
            prepareFilterQuery({
                filter,
                page,
            })
        );
    };

    componentWillUnmount() {
        this.props.clearTitle();
    }

    remove = itemID => {
        this.handleOpen(itemID);
    };

    render() {
        const {
            administrators,
            history: { push },
            nextPage,
            count,
        } = this.props;
        const { filter } = this.state;
        const { onFilterChangeHandler, onLoadMoreClickHandler, remove } = this;

        return (
            <div>
                <Paper elevation={1}>
                    <TabsComponent
                        activeIndex={0}
                        tabs={[
                            {
                                label: "Administrators",
                                selected: true,
                                fnLabel: "push",
                                param: `/bookings/administrators`,
                            },
                            {
                                label: "Associated academics",
                                selected: false,
                                fnLabel: "push",
                                param: `/bookings/administrators/academics`,
                            },
                        ]}
                        fns={{ push }}
                    />
                </Paper>

                <div css={globalsCss.actions}>
                    <RedirectButton
                        label="Add new bookings administrator"
                        url={`/bookings/administrators/new`}
                        {...{ push }}
                    />
                </div>
                <Paper elevation={1}>
                    <FilterRow filter={filter} onFilterChangeHandler={onFilterChangeHandler} />
                    <TableComponent
                        items={administrators}
                        fns={{ push, remove }}
                        buttons={buttons(administrators)}
                        decorators={decorators}
                    />

                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this administrator?
                    </PromptDialog>
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {administrators.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListOfficeHoursAdministrators: actionCreators.getListOfficeHoursAdministrators.create,
    loadMoreOfficeHoursAdministrators: actionCreators.loadMoreOfficeHoursAdministrators.create,
    removeOfficeHoursAdministrator: actionCreators.removeOfficeHoursAdministrator.create,
};

const mapStateToProps = ({ OfficeHoursAdministrators }) => ({
    administrators: OfficeHoursAdministrators.administrators.data,
    count: OfficeHoursAdministrators.administrators.count,
    nextPage: OfficeHoursAdministrators.administrators.nextPage,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursAdministratorsListContainer);
