import { Component } from "react";
import { connect } from "react-redux";
import Form from "./Form";
import actionCreators from "../../../store/UsersAndAudiences/LseStaff/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import currentFormTypes from "../../../common/components/CurrentFormTypes";
import formsCss from "../../../common/styles/forms.css";
import { MainTitle } from "../../../common/components/MainTitle";
import { USER_TYPE_STANDARD } from "./values";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class NewStaffContainer extends Component {
    componentDidMount() {
        const { setTitle, setCurrentFormType } = this.props;
        setTitle("LSE Staff");
        setCurrentFormType(currentFormTypes.NEW);
    }
    componentWillUnmount() {
        this.props.clearTitle();
    }

    onSubmit = values => {
        this.props.postStaff({ ...values, hasBookings: values.hasBookings === "Y" ? true : false });
        this.props.setLoading();
    };

    render() {
        const {
            currentFormType,
            initialValues,
            history: { push },
            errorRes: { error, errors },
        } = this.props;

        initialValues.permissionLevel = USER_TYPE_STANDARD;
        initialValues.hasBookings = "N";

        const { onSubmit } = this;
        return (
            <div>
                <MainTitle title="LSE Staff" type="[Users &amp; Audiences]" />
                <div css={formsCss.genericError}>{error}</div>
                <Form {...{ initialValues, onSubmit, currentFormType, push, errors }} />
            </div>
        );
    }
}
const dispatchToProps = {
    postStaff: actionCreators.postStaff.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    setLoading: mainMenuActionCreators.setLoading.create,
};
const mapStateToProps = state => ({
    errorRes: state.LseStaff.errorRes,
    currentFormType: state.LseStaff.currentFormType,
    initialValues: state.LseStaff.user,
});
export default connect(mapStateToProps, dispatchToProps)(NewStaffContainer);
