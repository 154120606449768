import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { mergeMap, catchError, switchMap } from "rxjs/operators";
import actionCreators from "./actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";

const autocompletegGetStaffUsersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getStaffUsers.type),
        switchMap(({ payload }) =>
            ajax
                .get(ajax.apiUrl("staff/autocomplete/users"), {
                    type: "staff",
                    ...payload,
                })
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.receivedStaffUsers.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const autocompletegGetStudentUsersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getStudentUsers.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/autocomplete/users"), { type: "student", ...payload }).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.receivedStudentUsers.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const autocompletegGetAllUsersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getAllUsers.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/autocomplete/users"), payload).pipe(
                mergeMap(({ response }) => of(actionCreators.receivedAllUsers.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const autocompletegGetPagesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getPages.type),
        switchMap(({ payload }) =>
            ajax
                .get(ajax.apiUrl("staff/autocomplete/channels"), { category: "page", ...payload })
                .pipe(
                    mergeMap(({ response }) => of(actionCreators.receivedPages.create(response))),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const autocompletegGetPostsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getPosts.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/autocomplete/posts"), { kind: "text", ...payload }).pipe(
                mergeMap(({ response }) => of(actionCreators.receivedPosts.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const autocompletegGetEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getEvents.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/autocomplete/posts"), { kind: "event", ...payload }).pipe(
                mergeMap(({ response }) => of(actionCreators.receivedEvents.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const autocompletegGetAudiencesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getAudiences.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/autocomplete/audiences"), payload).pipe(
                mergeMap(({ response }) => of(actionCreators.receivedAudiences.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const autocompleteGetLseModulesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getLseModules.type),
        switchMap(({ payload: query }) =>
            query.filter
                ? ajax.get(ajax.apiUrl("staff/autocomplete/lse-modules"), query).pipe(
                      mergeMap(({ response }) => of(actionCreators.setLseModules.create(response))),
                      catchError(errorHandler(actionCreators.errorResponse.create))
                  )
                : of(actionCreators.setLseModules.create([]))
        )
    );

const autocompleteOfficeHoursAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeHoursAcademics.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/autocomplete/academics/"), payload).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.receivedOfficeHoursAcademics.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const autocompleteOfficeHoursAdministratorsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeHoursAdministrators.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/autocomplete/admin/"), payload).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.receivedOfficeHoursAdministrators.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOfficeHoursTeamsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeHoursTeams.type),
        switchMap(({ payload: query }) =>
            query.filter
                ? ajax.get(ajax.apiUrl("staff/autocomplete/teams"), query).pipe(
                      mergeMap(({ response }) =>
                          of(actionCreators.setOfficeHoursTeams.create(response))
                      ),
                      catchError(errorHandler(actionCreators.errorResponse.create))
                  )
                : of(actionCreators.setOfficeHoursTeams.create([]))
        )
    );

const getChannelsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getChannels.type),
        switchMap(({ payload: query }) =>
            query.filter
                ? ajax
                      .get(ajax.apiUrl("staff/autocomplete/channels"), {
                          category: "all",
                          ...query,
                      })
                      .pipe(
                          mergeMap(({ response }) =>
                              of(actionCreators.receivedChannels.create(response))
                          ),
                          catchError(errorHandler(actionCreators.errorResponse.create))
                      )
                : of(actionCreators.receivedChannels.create([]))
        )
    );

export const epics = combineEpics(
    autocompletegGetStaffUsersEpic,
    autocompletegGetStudentUsersEpic,
    autocompletegGetAllUsersEpic,
    autocompletegGetPagesEpic,
    autocompletegGetPostsEpic,
    autocompletegGetEventsEpic,
    autocompletegGetAudiencesEpic,
    autocompleteGetLseModulesEpic,
    autocompleteOfficeHoursAcademicsEpic,
    autocompleteOfficeHoursAdministratorsEpic,
    getOfficeHoursTeamsEpic,
    getChannelsEpic
);
