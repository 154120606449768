import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "../common/services/history";
import { reducer as formReducer } from "redux-form";

import AuthReducer, { AuthState } from "./Auth/reducer";
import AutoCompleteReducer, { AutoCompleteState } from "./AutoComplete/reducer";
import FroalaReducer from "./Froala/reducer";
import LseStudentsReducer from "./UsersAndAudiences/LseStudents/reducer";
import LseStaffReducer from "./UsersAndAudiences/LseStaff/reducer";
import AudienceReducer from "./UsersAndAudiences/Audiences/reducer";
import OfficeHoursAdministratorsReducer from "./OfficeHours/Administrators/reducer";
import OfficeHoursAcademicsReducer from "./OfficeHours/Academics/reducer";
import OfficeHoursTeamsReducer from "./OfficeHours/Teams/reducer.js";
import OfficeHoursAvailabilitiesReducer from "./OfficeHours/Availabilities/reducer";
import OfficeHoursSessionsReducer from "./OfficeHours/Sessions/reducer";
import OfficeHoursAppointmentsReducer from "./OfficeHours/Appointments/reducer";
import CalendarEventsReducer from "./CalendarAndEvents/reducer";
import ChannelPagePreviewReducer from "./Channels/reducer";
import ChannelPageReducer from "./Channels/Pages/reducer";
import ChannelPageFollowersReducer from "./Channels/Pages/Followers/reducer";
import ChannelGroupReducer from "./Channels/Groups/reducer";
import ChannelGroupMemberReducer from "./Channels/Groups/Members/reducer";
import ChannelPostsReducer from "./Channels/Posts/reducer";
import ChannelEventsReducer from "./Channels/Events/reducer";
import DiscussionReducer from "./Discussion/reducer";
import NotificationReducer, { NotificationState } from "./Notification/reducer";
import CampusMappingReducer, { CampusMappingState } from "./CampusMapping/reducer";
import SupportPageReducer from "./SupportPage/reducer";
import SupportHomepageReducer from "./CmsSupport/reducer";
import FeedbackReducer from "./Feedback/reducer";
import HowToCategoryReducer from "./HowTo/Category/reducer";
import HowToArticleReducer from "./HowTo/Category/Articles/reducer.js";
import DashboardReducer from "./Dashboard/reducer";
import SettingsSchoolYearsReducer from "./Settings/SchoolYears/reducer";
import MainMenuReducer from "./MainMenu/reducer";
import OfficeHoursReport from "./OfficeHours/Reports/reducer";
import NotificationBannerReducer from "./NotificationBanner/reducer";
import PersistentEventsReducer from "./PersistentEvents/reducer";
import SideLinkssReducer from "./SideLinks/reducer";
import SnackbarReducer from "./Snackbar/reducer";

export interface AppState {
    Auth: AuthState;
    CampusMapping: CampusMappingState;
    Notification: NotificationState;
    AutoComplete: AutoCompleteState;
}

export default combineReducers({
    Auth: AuthReducer,
    AutoComplete: AutoCompleteReducer,
    Froala: FroalaReducer,
    form: formReducer,
    MainMenu: MainMenuReducer,
    LseStaff: LseStaffReducer,
    LseStudents: LseStudentsReducer,
    Audience: AudienceReducer,
    OfficeHoursAdministrators: OfficeHoursAdministratorsReducer,
    OfficeHoursAcademics: OfficeHoursAcademicsReducer,
    OfficeHoursTeams: OfficeHoursTeamsReducer,
    OfficeHoursAvailabilities: OfficeHoursAvailabilitiesReducer,
    OfficeHoursSessions: OfficeHoursSessionsReducer,
    OfficeHoursAppointments: OfficeHoursAppointmentsReducer,
    OfficeHoursReport,
    CalendarEvents: CalendarEventsReducer,
    ChannelPage: ChannelPageReducer,
    ChannelPageFollowers: ChannelPageFollowersReducer,
    ChannelGroup: ChannelGroupReducer,
    ChannelGroupMember: ChannelGroupMemberReducer,
    ChannelPreview: ChannelPagePreviewReducer,
    ChannelPosts: ChannelPostsReducer,
    ChannelEvents: ChannelEventsReducer,
    router: connectRouter(history),
    Discussion: DiscussionReducer,
    Notification: NotificationReducer,
    CampusMapping: CampusMappingReducer,
    SupportPage: SupportPageReducer,
    CmsSupport: SupportHomepageReducer,
    Feedback: FeedbackReducer,
    HowToCategory: HowToCategoryReducer,
    HowToArticle: HowToArticleReducer,
    Dashboard: DashboardReducer,
    SettingsSchoolYears: SettingsSchoolYearsReducer,
    NotificationBanner: NotificationBannerReducer,
    PersistentEvents: PersistentEventsReducer,
    SideLinks: SideLinkssReducer,
    Snackbar: SnackbarReducer,
});
