export default values => {
    const errors = {};
    const requiredFields = [
        "name",
        "startFirstTerm",
        "endFirstTerm",
        "startSecondTerm",
        "endSecondTerm",
        "startThirdTerm",
        "endThirdTerm",
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    if (
        values.startFirstTerm &&
        values.endFirstTerm &&
        values.startFirstTerm >= values.endFirstTerm
    ) {
        errors.endFirstTerm = "Autumn Term end date must be later than its start date";
    }

    if (
        values.startSecondTerm &&
        values.endFirstTerm &&
        values.startSecondTerm <= values.endFirstTerm
    ) {
        errors.startSecondTerm = "Winter Term start date must be later than Autumn Terms end date";
    }

    if (
        values.startSecondTerm &&
        values.endSecondTerm &&
        values.startSecondTerm >= values.endSecondTerm
    ) {
        errors.endSecondTerm = "Winter Term end date must be later than its start date";
    }

    if (
        values.startThirdTerm &&
        values.endSecondTerm &&
        values.startThirdTerm <= values.endSecondTerm
    ) {
        errors.startThirdTerm = "Spring Term start date must be later than Winter Terms end date";
    }

    if (
        values.startThirdTerm &&
        values.endThirdTerm &&
        values.startThirdTerm >= values.endThirdTerm
    ) {
        errors.endThirdTerm = "Spring Term end date must be later than its start date";
    }

    return errors;
};
