import ActionCreator from "../../actionCreator";

export default {
    getListOfficeHoursTeams: ActionCreator("GET_LIST_OFFICE_HOURS_TEAMS"),
    createListOfficeHoursTeams: ActionCreator("CREATE_LIST_OFFICE_HOURS_TEAMS"),
    postOfficeHoursTeam: ActionCreator("POST_OFFICE_HOURS_TEAM"),
    getOneOfficeHoursTeam: ActionCreator("GET_ONE_OFFICE_HOURS_TEAM"),
    clearOfficeHoursTeam: ActionCreator("CLEAR_OFFICE_HOURS_TEAM"),
    putOfficeHoursTeam: ActionCreator("PUT_OFFICE_HOURS_TEAM"),
    updateOneOfficeHoursTeam: ActionCreator("UPDATE_ONE_OFFICE_HOURS_TEAM"),
    pushListOfficeHoursTeams: ActionCreator("PUSH_LIST_OFFICE_HOURS_TEAMS"),
    removeOfficeHoursTeam: ActionCreator("REMOVE_OFFICE_HOURS_TEAM"),
    errorResponse: ActionCreator("ERROR_RESPONSE_OFFICE_HOURS_TEAMS"),
    getTeamSlotsForCancellation: ActionCreator(
        "GET_OFFICE_HOURS_TEAM_AVAILABILITY_SLOTS_FOR_CANCELLATION"
    ),
    createTeamSlotsForCancellation: ActionCreator(
        "CREATE_OFFICE_HOURS_TEAM_AVAILABILITY_SLOTS_FOR_CANCELLATION"
    ),
    clearTeamSlotsForCancellation: ActionCreator(
        "CLEAR_OFFICE_HOURS_TEAM_AVAILABILITY_SLOTS_FOR_CANCELLATION"
    ),
};
