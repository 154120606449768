import { spacing } from "../../UIGlobals";

export const contentContainer = {
    container: {
        maxWidth: "100%",
    },
    inner: {
        maxWidth: 1144,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: 30,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
    },
};
