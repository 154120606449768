/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../styles/forms.css";

const imageThumbnail = ({ imageUrl, imageFile, removeField, alt, ...props }) => {
    if (imageFile && imageFile.name) {
        return (
            <div css={formsCss.uploadImagePlaceholder}>
                <img
                    alt={alt || ""}
                    src={imageFile.base64}
                    {...props}
                    css={formsCss.uploadImageImg}
                />
            </div>
        );
    }

    if (imageUrl && !removeField) {
        return (
            <div css={formsCss.uploadImagePlaceholder}>
                <img alt={alt || ""} src={imageUrl} {...props} css={formsCss.uploadImageImg} />
            </div>
        );
    }

    return null;
};

export const ImageThumbnail = imageThumbnail;
