// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change, registerField } from "redux-form";
import { validate, asyncValidate } from "./validate";
import {
    RenderTextField,
    RenderSelectField,
    RenderDatepicker,
    RenderTimepicker,
    RenderRadioGroup,
    RenderCheckboxField,
    RenderRadio,
} from "../../../../common/components/FormFields";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";

import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem, Button, FormControl, FormLabel } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import moment from "moment-timezone";

import { AutoRenewIcon } from "../../../../common/components/icons";

import PromptDialog from "../../../../common/components/PromptDialog";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";

import {
    REPEATING_DAILY,
    REPEATING_WEEKLY,
    REPEATING_MONTHLY,
    REPEAT_IN_TERM,
    REPEAT_IN_ACADEMIC_YEAR,
    REPEAT_N_TIMES,
    REPEAT_UNTIL,
    TYPE_REPEATING,
    TYPE_NON_REPEATING,
    MEETING_ZOOM,
    MEETING_CUSTOM,
    MEETING_NONE,
    SESSION_BOOKABLE,
    SESSION_DROPIN,
} from "../types";
import globalsCss from "../../../../common/styles/globals.css";
import { convertToDate } from "../../../../common/services/parseDates";
import { InfoTooltip } from "../../../../common/components/InfoTooltip";
import Parser from "html-react-parser";

const FORM_TYPE_FIELD = "Field";

class Form extends Component {
    state = {
        repeating: null,
        openDialog: false,
        dailyNtimes: null,
        dailyDate: null,
        weeklyNtimes: null,
        weeklyDate: null,
        monthlyNtimes: null,
        monthlyDate: null,
        meetingUrl: "",
    };

    componentDidMount() {
        const { addField, initialValues } = this.props;

        // add aditional 'artificial' form fields
        addField("repeatingType", FORM_TYPE_FIELD);

        addField("daily.config", FORM_TYPE_FIELD);
        addField("daily.ntimes", FORM_TYPE_FIELD);
        addField("daily.until", FORM_TYPE_FIELD);

        addField("weekly.config", FORM_TYPE_FIELD);
        addField("weekly.ntimes", FORM_TYPE_FIELD);
        addField("weekly.until", FORM_TYPE_FIELD);
        addField("weekly.repeatSat", FORM_TYPE_FIELD);

        addField("monthly.config", FORM_TYPE_FIELD);
        addField("monthly.ntimes", FORM_TYPE_FIELD);
        addField("monthly.until", FORM_TYPE_FIELD);

        addField("timeInAdvanceValue", FORM_TYPE_FIELD);
        addField("summaryAtTimeValue", FORM_TYPE_FIELD);

        addField("meetingUrl", FORM_TYPE_FIELD);
        addField("integrateWithZoom", FORM_TYPE_FIELD);

        if (initialValues && initialValues.id > 0 && initialValues.repeating === TYPE_REPEATING) {
            this.initRepeatingForm();
        }

        this.props.initialize(this.props.initialValues);
        if (this.props.initialValues.meetingUrl)
            this.setState({ meetingUrl: this.props.initialValues.meetingUrl });
    }

    componentDidUpdate(prevProps) {
        const { initialValues, formErrors, touch, popupErrors, updateForm } = this.props;

        if (!prevProps.initialValues.meetingUrl && initialValues.meetingUrl) {
            this.setState({ meetingUrl: initialValues.meetingUrl });
        }

        if (
            initialValues &&
            initialValues.id !== prevProps.initialValues.id &&
            initialValues.id > 0 &&
            initialValues.repeating === TYPE_REPEATING
        ) {
            this.initRepeatingForm();
        }

        // auto-touch all fields from async errors
        if (!!popupErrors && formErrors !== prevProps.formErrors) {
            const toTouch = [];

            for (const key in formErrors) {
                if (typeof formErrors[key] === "object" && formErrors[key] !== null) {
                    for (const subkey in formErrors[key]) {
                        formErrors[key].hasOwnProperty(subkey) && toTouch.push(`${key}.${subkey}`);
                    }
                } else {
                    formErrors.hasOwnProperty(key) && toTouch.push(key);
                }
            }
            touch(...toTouch);
        }

        // prefill weekly repeating weekday
        if (!prevProps.id && initialValues.id && initialValues.repeating === TYPE_NON_REPEATING) {
            if (initialValues.date) {
                this.updatePrefilledWeeklyDay(initialValues.date);
            }
            if (!initialValues.repeatingRule) {
                updateForm("daily.every", 1);
                updateForm("weekly.every", 1);
                updateForm("monthly.every", 1);
            }
        }
    }

    initRepeatingForm = () => {
        const { initialValues } = this.props;

        if (initialValues.repeating === TYPE_REPEATING) {
            if (initialValues.daily) {
                this.handleRepeatingChange(REPEATING_DAILY, false);
                this.setState({
                    dailyNtimes: initialValues.daily.ntimes,
                    dailyDate: initialValues.daily.until,
                });
            } else if (initialValues.weekly) {
                this.handleRepeatingChange(REPEATING_WEEKLY, false);
                this.setState({
                    weeklyNtimes: initialValues.weekly.ntimes,
                    weeklyDate: initialValues.weekly.until,
                });
            } else if (initialValues.monthly) {
                this.handleRepeatingChange(REPEATING_MONTHLY, false);
                this.setState({
                    monthlyNtimes: initialValues.monthly.ntimes,
                    monthlyDate: initialValues.monthly.until,
                });
            }
        }
    };

    handleOpen = () => {
        this.setState({ openDialog: true });
    };

    handleClose = async () => {
        /*
         * if repearing popup is opened, launch aynsc validation and
         * close only if no errors returned (popup is valid)
         */
        const asyncValidationErrors = this.props.formFields.repeating
            ? await this.props.asyncValidate()
            : false;
        if (!asyncValidationErrors) {
            this.setState({ openDialog: false });
            if (this.props.formFields.repeating) this.props.clearAsyncError();
        }
    };

    handleRepeatingChange = (value, openDialog) => {
        this.setState({ repeating: value });
        this.props.updateForm("repeatingType", value);
        if (openDialog) this.setState({ openDialog: true });
    };

    handleRepeatingConfigure = () => {
        this.setState({ openDialog: true });
    };

    handleFormSubmit = event => {
        const { valid, handleSubmit, formErrors } = this.props;
        const { repeating } = this.state;
        handleSubmit(event);
        if (!valid) {
            if (
                (formErrors[REPEATING_DAILY] && repeating === REPEATING_DAILY) ||
                (formErrors[REPEATING_WEEKLY] && repeating === REPEATING_WEEKLY) ||
                (formErrors[REPEATING_MONTHLY] && repeating === REPEATING_MONTHLY)
            )
                this.setState({ openDialog: true });
        }
    };

    onChangeStartAt = (event, newValue) => {
        const { formFields, updateForm } = this.props;
        if (formFields) {
            if (!formFields.endAt || formFields.endAt <= newValue) {
                const endAt = new moment(newValue);
                endAt.set("hour", endAt.get("hour") + 1);
                updateForm("endAt", endAt.utc().format());
            }
        }
    };

    // LSH-2499 - Automatically change dropdown for end time to pm
    // if start time is in morning and user changes end time to 12
    normalizeEndAt = value => {
        const { formFields } = this.props;
        if (formFields) {
            const endAt = new moment(value);
            if (
                endAt.tz("Europe/London").get("hour") === 0 &&
                (!formFields.startAt ||
                    moment(formFields.startAt)
                        .tz("Europe/London")
                        .get("hour") < 12)
            ) {
                endAt.set("hour", endAt.get("hour") + 12);
                return endAt.utc().format();
            } else return value;
        }
    };

    updatePrefilledWeeklyDay = newValue => {
        const { formFields, updateForm } = this.props;

        if (formFields) {
            updateForm(
                "weekly.repeatSun",
                moment(newValue)
                    .tz("Europe/London")
                    .day() === 0
            );
            updateForm(
                "weekly.repeatMon",
                moment(newValue)
                    .tz("Europe/London")
                    .day() === 1
            );
            updateForm(
                "weekly.repeatTue",
                moment(newValue)
                    .tz("Europe/London")
                    .day() === 2
            );
            updateForm(
                "weekly.repeatWed",
                moment(newValue)
                    .tz("Europe/London")
                    .day() === 3
            );
            updateForm(
                "weekly.repeatThu",
                moment(newValue)
                    .tz("Europe/London")
                    .day() === 4
            );
            updateForm(
                "weekly.repeatFri",
                moment(newValue)
                    .tz("Europe/London")
                    .day() === 5
            );
            updateForm(
                "weekly.repeatSat",
                moment(newValue)
                    .tz("Europe/London")
                    .day() === 6
            );
        }
    };

    render() {
        const {
            push,
            currentFormType,
            errors,
            formError,
            formFields,
            updateForm,
            formErrors,
            academicId,
            teamId,
            teamMemberId,
            classes,
            isGenericAvailability,
        } = this.props;
        const {
            repeating,
            openDialog,
            dailyNtimes,
            dailyDate,
            weeklyNtimes,
            weeklyDate,
            monthlyNtimes,
            monthlyDate,
            meetingUrl,
        } = this.state;

        return (
            <form onSubmit={this.handleFormSubmit}>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="title"
                            errorRes={errors.title}
                            component={RenderTextField}
                            label="Title"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="date"
                            errorRes={errors.date}
                            component={RenderDatepicker}
                            format={null}
                            hintText=" "
                            label="Date"
                            fullWidth
                            css={formsCss.field}
                            onChange={this.updatePrefilledWeeklyDay}
                        />
                    </div>
                    <div className="col-sm-8 col-xs-12">
                        <div css={formsCss.inlineAlignTopWrap}>
                            <div css={formsCss.inlineItemDoubleGap}>
                                <Field
                                    name="startAt"
                                    component={RenderTimepicker}
                                    format={null}
                                    hintText="HH:MM"
                                    label="From"
                                    onChange={this.onChangeStartAt}
                                    errorRes={errors.start}
                                    css={formsCss.field}
                                />
                            </div>
                            <div css={formsCss.inlineItemDoubleGap}>
                                <Field
                                    name="endAt"
                                    component={RenderTimepicker}
                                    format={null}
                                    hintText="HH:MM"
                                    label="To"
                                    // normalize={this.normalizeEndAt}
                                    normalize={this.normalizeEndAt}
                                    errorRes={errors.end}
                                    css={formsCss.field}
                                />
                            </div>
                            <div css={formsCss.time}>
                                Time: <strong>Europe/London</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div css={globalsCss.helpers.mt15}>
                    <Field
                        name="repeating"
                        errorRes={errors.status}
                        component={RenderRadioGroup}
                        row
                    >
                        <RenderRadio
                            value={TYPE_NON_REPEATING}
                            label="Only on that day"
                            className="col-sm-3 col-xs-12"
                        />
                        <RenderRadio
                            value={TYPE_REPEATING}
                            label="Repeating"
                            className="col-sm-3 col-xs-12"
                        />
                    </Field>
                </div>
                {formFields && formFields.repeating === TYPE_REPEATING && (
                    <div className="row bottom-xs">
                        <div className="col-sm-4 col-xs-12">
                            <TextField
                                select
                                label={"How often?"}
                                margin="normal"
                                color="primary"
                                fullWidth
                                onChange={({ target: { value } }) =>
                                    this.handleRepeatingChange(value, true)
                                }
                                value={repeating ? repeating : ""}
                                error={!!formErrors.repeatingType}
                                helperText={formErrors.repeatingType}
                                InputProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                        error: classes.inputError,
                                    },
                                }}
                                SelectProps={{
                                    MenuProps: {
                                        MenuListProps: {
                                            disablePadding: true,
                                            dense: true,
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={`repeating.daily`} value={REPEATING_DAILY}>
                                    daily
                                </MenuItem>
                                <MenuItem key={`repeating.weekly`} value={REPEATING_WEEKLY}>
                                    weekly
                                </MenuItem>
                                <MenuItem key={`repeating.monthly`} value={REPEATING_MONTHLY}>
                                    monthly
                                </MenuItem>
                            </TextField>
                        </div>
                        {repeating && (
                            <div className="col-sm-4 col-xs-12">
                                <div css={formsCss.field}>
                                    <Button
                                        onClick={() => this.handleRepeatingConfigure()}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        <AutoRenewIcon css={formsCss.btnIcon} />
                                        Configure
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <hr className="bold" />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Information for students</label>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="row">
                            <div className="col-xs-12">
                                <Field
                                    name="location"
                                    errorRes={errors && errors.location}
                                    component={RenderTextField}
                                    label="Location"
                                    fullWidth
                                />
                            </div>
                            <div className="col-xs-12">
                                <Field
                                    name="appointmentForm"
                                    errorRes={errors && errors.appointmentForm}
                                    component={RenderTextField}
                                    label="Appointment type"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <Field
                            name="note"
                            errorRes={errors && !!errors.note}
                            component={RenderTextField}
                            label="Notes to students making bookings"
                            fullWidth
                            multiline
                            rows={5}
                            placeholder="Share any useful information about this availability with your students here"
                            inputProps={{
                                style: { height: 91 },
                            }}
                        />
                    </div>
                </div>
                <hr className="bold" />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Online meetings</label>
                    </div>
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <Field
                            name="onlineMeetings"
                            component={RenderRadioGroup}
                            row
                            onChange={(ev, value) => {
                                if (value === MEETING_ZOOM) {
                                    updateForm("meetingUrl", "");
                                    updateForm("integrateWithZoom", true);
                                } else if (value === MEETING_CUSTOM) {
                                    updateForm("meetingUrl", meetingUrl);
                                    updateForm("integrateWithZoom", false);
                                } else if (value === MEETING_NONE) {
                                    updateForm("meetingUrl", "");
                                    updateForm("integrateWithZoom", false);
                                }
                            }}
                            onBlur={e => e.preventDefault()}
                        >
                            {!isGenericAvailability && (
                                <RenderRadio
                                    value={MEETING_ZOOM}
                                    label={
                                        <div>
                                            <span>Generate Zoom links automatically</span>
                                            <InfoTooltip
                                                mb={0}
                                                text="Each session created by this availability will have a unique Zoom meeting link, with the waiting room feature turned on"
                                            />
                                            <div css={[formsCss.labelHelp, globalsCss.helpers.mt5]}>
                                                <em>
                                                    You will see these links in your Zoom account,
                                                    in Student Hub booking session pages, and in
                                                    Student Hub email notifications (if enabled
                                                    below)
                                                </em>
                                            </div>
                                            {errors && errors.integrateWithZoom && (
                                                <div css={formsCss.error}>
                                                    {Parser(
                                                        errors.integrateWithZoom.replace(
                                                            /\\"/g,
                                                            '"'
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    }
                                    className="col-lg-12 col-sm-12 col-xs-12"
                                />
                            )}
                            <RenderRadio
                                value={MEETING_CUSTOM}
                                label={
                                    <div>
                                        <span>
                                            Provide a personal meeting link or other joining
                                            instructions
                                        </span>
                                        {formFields.onlineMeetings === MEETING_CUSTOM && (
                                            <div>
                                                <div css={globalsCss.helpers.mt15}>
                                                    <div>
                                                        <TextField
                                                            css={[
                                                                formsCss.inlineItem,
                                                                formsCss.inlineItemField,
                                                            ]}
                                                            label="Online meeting details"
                                                            color="primary"
                                                            error={!!formErrors.meetingUrl}
                                                            placeholder={
                                                                "e.g. your personal Zoom link, or instructions for Teams calls"
                                                            }
                                                            fullWidth={true}
                                                            multiline
                                                            rows={5}
                                                            rowsMax={5}
                                                            onChange={({ target: { value } }) => {
                                                                this.setState({
                                                                    meetingUrl: value,
                                                                });
                                                            }}
                                                            onBlur={() => {
                                                                updateForm(
                                                                    "meetingUrl",
                                                                    meetingUrl
                                                                );
                                                            }}
                                                            value={meetingUrl ? meetingUrl : ""}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.inputRoot,
                                                                    error: classes.inputError,
                                                                },
                                                            }}
                                                            margin="none"
                                                        />
                                                    </div>
                                                </div>
                                                {errors && errors.meetingUrl && (
                                                    <div css={formsCss.error}>
                                                        {errors.meetingUrl}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                }
                                className="col-lg-12 col-sm-12 col-xs-12"
                            />
                            <RenderRadio
                                value={MEETING_NONE}
                                label={
                                    <div>
                                        <span>No online meeting option</span>
                                    </div>
                                }
                                className="col-lg-12 col-sm-12 col-xs-12"
                            />
                        </Field>
                    </div>
                </div>
                <hr className="bold" />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Appointment settings</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="mode"
                                component={RenderSelectField}
                                label="Session type"
                                errorRes={errors && errors.duration}
                                options={[
                                    { value: SESSION_BOOKABLE, label: "Bookable" },
                                    { value: SESSION_DROPIN, label: "Drop-in" },
                                ]}
                            />
                            {formFields.mode === SESSION_DROPIN && (
                                <InfoTooltip text="Students will see the times of drop-ins but will not be able to book them." />
                            )}
                        </div>
                    </div>
                </div>
                {formFields.mode === SESSION_BOOKABLE && (
                    <>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="duration"
                                    component={RenderSelectField}
                                    label="Appointment duration"
                                    errorRes={errors && errors.duration}
                                    options={[
                                        { value: null, label: "" },
                                        { value: 5, label: "5 minutes" },
                                        { value: 10, label: "10 minutes" },
                                        { value: 15, label: "15 minutes" },
                                        { value: 20, label: "20 minutes" },
                                        { value: 25, label: "25 minutes" },
                                        { value: 30, label: "30 minutes" },
                                        { value: 35, label: "35 minutes" },
                                        { value: 40, label: "40 minutes" },
                                        { value: 45, label: "45 minutes" },
                                        { value: 50, label: "50 minutes" },
                                        { value: 55, label: "55 minutes" },
                                        { value: 60, label: "60 minutes" },
                                        { value: 90, label: "90 minutes" },
                                    ]}
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <div css={formsCss.inlineAlignBottom}>
                                    <Field
                                        name="maxNumberOfAttendees"
                                        errorRes={errors && errors.maxNumberOfAttendees}
                                        component={RenderTextField}
                                        label="Number of attendees per appointment slot"
                                        fullWidth
                                        type="number"
                                        inputProps={{ min: "1" }}
                                        placeholder="Maximum number of attendees per slot"
                                    />
                                    <InfoTooltip text="Allows you to define how many students can book into the same appointment slot. Students will not be able to see who else has booked into a slot." />
                                </div>
                            </div>

                            <div className="col-sm-4 col-xs-12">
                                <div css={formsCss.inlineAlignBottom}>
                                    <Field
                                        name="maxAppointmentsPerSession"
                                        errorRes={errors && errors.maxAppointmentsPerSession}
                                        component={RenderTextField}
                                        label="Number of slots per student per session"
                                        fullWidth
                                        type="number"
                                        inputProps={{ min: "1" }}
                                        placeholder="Maximum number of bookings per student"
                                    />
                                    <InfoTooltip text="Allows you to define how many appointments a student can book in one session." />
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col-xs-12">
                                <label css={formsCss.labelBig}>Student booking settings</label>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <div css={formsCss.inlineAlignBottom}>
                                    <Field
                                        name="earliestBooking"
                                        component={RenderSelectField}
                                        label="Earliest time students can book from"
                                        errorRes={
                                            errors &&
                                            errors.settings &&
                                            errors.settings.earliestBooking
                                        }
                                        options={[
                                            { value: 0, label: "No limit" },
                                            { value: 24 * 60, label: "1 day in advance" },
                                            { value: 48 * 60, label: "2 days in advance" },
                                            { value: 72 * 60, label: "3 days in advance" },
                                            { value: 96 * 60, label: "4 days in advance" },
                                            { value: 120 * 60, label: "5 days in advance" },
                                            { value: 144 * 60, label: "6 days in advance" },
                                            { value: 168 * 60, label: "7 days in advance" },
                                            { value: 192 * 60, label: "8 days in advance" },
                                            { value: 216 * 60, label: "9 days in advance" },
                                            { value: 240 * 60, label: "10 days in advance" },
                                            { value: 264 * 60, label: "11 days in advance" },
                                            { value: 288 * 60, label: "12 days in advance" },
                                            { value: 312 * 60, label: "13 days in advance" },
                                            { value: 336 * 60, label: "14 days in advance" },
                                        ]}
                                    />
                                    <InfoTooltip text="Allows you to set how many days in advance a student can book an appointment with you." />
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <div css={formsCss.inlineAlignBottom}>
                                    <Field
                                        name="latestBooking"
                                        component={RenderSelectField}
                                        label="Latest time students can book until"
                                        errorRes={
                                            errors &&
                                            errors.settings &&
                                            errors.settings.latestBooking
                                        }
                                        options={[
                                            { value: 0, label: "No limit" },
                                            { value: 30, label: "30 minutes in advance" },
                                            { value: 1 * 60, label: "1 hour in advance" },
                                            { value: 2 * 60, label: "2 hours in advance" },
                                            { value: 3 * 60, label: "3 hours in advance" },
                                            { value: 4 * 60, label: "4 hours in advance" },
                                            { value: 5 * 60, label: "5 hours in advance" },
                                            { value: 6 * 60, label: "6 hours in advance" },
                                            { value: 12 * 60, label: "12 hours in advance" },
                                            { value: 24 * 60, label: "24 hours in advance" },
                                            { value: 48 * 60, label: "48 hours in advance" },
                                            { value: 72 * 60, label: "3 days in advance" },
                                            { value: 96 * 60, label: "4 days in advance" },
                                            { value: 120 * 60, label: "5 days in advance" },
                                            { value: 144 * 60, label: "6 days in advance" },
                                            { value: 168 * 60, label: "7 days in advance" },
                                        ]}
                                    />
                                    <InfoTooltip text="Allows you to set the latest time a student can book an appointment with you." />
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <div css={globalsCss.helpers.mt20}>
                                    <Field
                                        name="allowFileUpload"
                                        label="Allow students to upload files"
                                        component={RenderCheckboxField}
                                        errorRes={errors && errors.allowFileUpload}
                                    />
                                    <Field
                                        name="requireBookingNote"
                                        label="Require students to add a booking note"
                                        component={RenderCheckboxField}
                                        errorRes={errors && errors.requireBookingNote}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <hr className="bold" />
                {!isGenericAvailability && (
                    <>
                        <div className="row">
                            <div className="col-xs-12">
                                <label css={formsCss.labelBig}>
                                    My email notification preferences
                                </label>
                            </div>

                            <div className="col-sm-4 col-xs-12">
                                <div css={formsCss.inlineAlignBottom}>
                                    <Field
                                        name="email"
                                        errorRes={errors && errors.email}
                                        component={RenderTextField}
                                        label="Preferred email address"
                                        fullWidth
                                    />
                                    <InfoTooltip text="This is the email address that notifications about your sessions will be sent to. You have the option to change this email address by editing the field." />
                                </div>
                            </div>

                            <>
                                <div className="col-sm-4 col-xs-12">
                                    <FormControl margin="normal" component="fieldset">
                                        <FormLabel component="div" css={formsCss.labelRadioGroup}>
                                            Send me a summary email:
                                        </FormLabel>
                                        <div css={formsCss.checkboxBox}>
                                            <div>
                                                <Field
                                                    name="atEightOnSessionDay"
                                                    label="At 8.00am on the session day"
                                                    component={RenderCheckboxField}
                                                    onChange={(e, value) => {
                                                        if (value) {
                                                            if (
                                                                formFields.receiveSummaryEmailAheadUpcomingAppointments ===
                                                                true
                                                            )
                                                                updateForm(
                                                                    "receiveSummaryEmailAheadUpcomingAppointments",
                                                                    false
                                                                );
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <Field
                                                    name="summaryAtTime"
                                                    component={RenderCheckboxField}
                                                    onChange={(e, value) => {
                                                        if (value) {
                                                            if (
                                                                formFields.receiveSummaryEmailAheadUpcomingAppointments ===
                                                                true
                                                            )
                                                                updateForm(
                                                                    "receiveSummaryEmailAheadUpcomingAppointments",
                                                                    false
                                                                );
                                                        }
                                                    }}
                                                    label={
                                                        <div
                                                            css={formsCss.inlineAlignTop}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            <div css={formsCss.inlineItem}>At</div>
                                                            <TextField
                                                                css={[
                                                                    formsCss.inlineItem,
                                                                    formsCss.inlineItemField,
                                                                    { width: 100 },
                                                                ]}
                                                                disabled={
                                                                    formFields &&
                                                                    formFields.receiveSummaryEmailAheadUpcomingAppointments ===
                                                                        true
                                                                }
                                                                select
                                                                margin="none"
                                                                color="primary"
                                                                fullWidth={false}
                                                                error={
                                                                    !!formErrors.summaryAtTimeValue
                                                                }
                                                                helperText={
                                                                    formErrors.summaryAtTimeValue
                                                                }
                                                                onChange={({
                                                                    target: { value },
                                                                }) => {
                                                                    updateForm(
                                                                        "summaryAtTime",
                                                                        !!value
                                                                    );
                                                                    updateForm(
                                                                        "summaryAtTimeValue",
                                                                        value
                                                                    );
                                                                }}
                                                                value={
                                                                    formFields.summaryAtTimeValue
                                                                        ? formFields.summaryAtTimeValue
                                                                        : ""
                                                                }
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.inputRoot,
                                                                        error: classes.inputError,
                                                                    },
                                                                }}
                                                                SelectProps={{
                                                                    MenuProps: {
                                                                        MenuListProps: {
                                                                            disablePadding: true,
                                                                            dense: true,
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem key={`aots_0`} value={0}>
                                                                    Choose time
                                                                </MenuItem>
                                                                <MenuItem key={`aots_1`} value={9}>
                                                                    9.00am
                                                                </MenuItem>
                                                                <MenuItem key={`aots_2`} value={10}>
                                                                    10.00am
                                                                </MenuItem>
                                                                <MenuItem key={`aots_3`} value={11}>
                                                                    11.00am
                                                                </MenuItem>
                                                                <MenuItem key={`aots_4`} value={12}>
                                                                    12.00pm
                                                                </MenuItem>
                                                                <MenuItem key={`aots_5`} value={13}>
                                                                    1.00pm
                                                                </MenuItem>
                                                                <MenuItem key={`aots_6`} value={14}>
                                                                    2.00pm
                                                                </MenuItem>
                                                                <MenuItem key={`aots_7`} value={15}>
                                                                    3.00pm
                                                                </MenuItem>
                                                                <MenuItem key={`aots_8`} value={16}>
                                                                    4.00pm
                                                                </MenuItem>
                                                                <MenuItem key={`aots_9`} value={17}>
                                                                    5.00pm
                                                                </MenuItem>
                                                            </TextField>
                                                            <div css={formsCss.inlineItem}>
                                                                on the session day
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <Field
                                                    name="timeInAdvance"
                                                    component={RenderCheckboxField}
                                                    onChange={(e, value) => {
                                                        if (value) {
                                                            if (
                                                                formFields.receiveSummaryEmailAheadUpcomingAppointments ===
                                                                true
                                                            )
                                                                updateForm(
                                                                    "receiveSummaryEmailAheadUpcomingAppointments",
                                                                    false
                                                                );
                                                        }
                                                    }}
                                                    label={
                                                        <div
                                                            css={formsCss.inlineAlignTop}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            <TextField
                                                                css={[
                                                                    formsCss.inlineItem,
                                                                    formsCss.inlineItemField,
                                                                    { width: 120 },
                                                                ]}
                                                                disabled={
                                                                    formFields &&
                                                                    formFields.receiveSummaryEmailAheadUpcomingAppointments ===
                                                                        true
                                                                }
                                                                select
                                                                margin="none"
                                                                color="primary"
                                                                fullWidth={false}
                                                                error={
                                                                    !!formErrors.timeInAdvanceValue
                                                                }
                                                                helperText={
                                                                    formErrors.timeInAdvanceValue
                                                                }
                                                                onChange={({
                                                                    target: { value },
                                                                }) => {
                                                                    updateForm(
                                                                        "timeInAdvance",
                                                                        !!value
                                                                    );
                                                                    updateForm(
                                                                        "timeInAdvanceValue",
                                                                        value
                                                                    );
                                                                }}
                                                                value={
                                                                    formFields.timeInAdvanceValue
                                                                        ? formFields.timeInAdvanceValue
                                                                        : ""
                                                                }
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.inputRoot,
                                                                        error: classes.inputError,
                                                                    },
                                                                }}
                                                                SelectProps={{
                                                                    MenuProps: {
                                                                        MenuListProps: {
                                                                            disablePadding: true,
                                                                            dense: true,
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem key={`tia_0`} value={0}>
                                                                    Choose time
                                                                </MenuItem>
                                                                <MenuItem key={`tia_1`} value={30}>
                                                                    30 minutes
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`tia_2`}
                                                                    value={60 * 1}
                                                                >
                                                                    1 hour
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`tia_3`}
                                                                    value={60 * 2}
                                                                >
                                                                    2 hours
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`tia_4`}
                                                                    value={60 * 3}
                                                                >
                                                                    3 hours
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`tia_5`}
                                                                    value={60 * 4}
                                                                >
                                                                    4 hours
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`tia_6`}
                                                                    value={60 * 5}
                                                                >
                                                                    5 hours
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`tia_7`}
                                                                    value={60 * 6}
                                                                >
                                                                    6 hours
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`tia_8`}
                                                                    value={60 * 12}
                                                                >
                                                                    12 hours
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`tia_9`}
                                                                    value={60 * 24}
                                                                >
                                                                    24 hours
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`tia_10`}
                                                                    value={60 * 48}
                                                                >
                                                                    48 hours
                                                                </MenuItem>
                                                            </TextField>
                                                            <div css={formsCss.inlineItem}>
                                                                before the session
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            {formFields.mode === SESSION_BOOKABLE && (
                                                <div>
                                                    <Field
                                                        name="atLatestBookingTime"
                                                        label="Once students can no longer book"
                                                        component={RenderCheckboxField}
                                                        onChange={(e, value) => {
                                                            if (value) {
                                                                if (
                                                                    formFields.receiveSummaryEmailAheadUpcomingAppointments ===
                                                                    true
                                                                )
                                                                    updateForm(
                                                                        "receiveSummaryEmailAheadUpcomingAppointments",
                                                                        false
                                                                    );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div>Or</div>
                                            <div>
                                                <Field
                                                    name="receiveSummaryEmailAheadUpcomingAppointments"
                                                    label="No, don't send me summary emails"
                                                    component={RenderCheckboxField}
                                                    errorRes={
                                                        errors &&
                                                        errors.receiveSummaryEmailAheadUpcomingAppointments
                                                    }
                                                    onChange={(e, value) => {
                                                        if (value) {
                                                            updateForm(
                                                                "atEightOnSessionDay",
                                                                false
                                                            );
                                                            updateForm("timeInAdvance", false);
                                                            updateForm("summaryAtTime", false);
                                                            updateForm(
                                                                "atLatestBookingTime",
                                                                false
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </FormControl>
                                </div>
                            </>

                            <div className="col-sm-4 col-xs-12"></div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-xs-12">
                                <label css={formsCss.labelBig}>My calendar preferences</label>
                            </div>

                            <div className="col-sm-4 col-xs-12">
                                <FormControl margin="normal" component="fieldset">
                                    <Field
                                        name="addSessionPlaceholders"
                                        label="Add session placeholders to my calendar"
                                        component={RenderCheckboxField}
                                    />

                                    <label css={formsCss.note}>
                                        You will receive calendar emails for all sessions created
                                        from this availability
                                    </label>
                                </FormControl>
                            </div>
                            {formFields.mode === SESSION_BOOKABLE && (
                                <div className="col-sm-4 col-xs-12">
                                    <FormControl margin="normal" component="fieldset">
                                        <Field
                                            name="addBookedAppointments"
                                            label="Add individual bookings to my calendar"
                                            component={RenderCheckboxField}
                                        />

                                        <label css={formsCss.note}>
                                            You will receive calendar emails when students make and
                                            cancel bookings
                                        </label>
                                    </FormControl>
                                </div>
                            )}
                            <div className="col-sm-4 col-xs-12"></div>
                        </div>
                    </>
                )}

                {(formError || (formErrors.genericError && this.props.submitFailed)) && (
                    <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                        {formError
                            ? formError.split("\n").map((item, key) => {
                                  return (
                                      <span key={key}>
                                          {Parser(item)}

                                          <br />
                                      </span>
                                  );
                              })
                            : formErrors.genericError && this.props.submitFailed
                            ? formErrors.genericError
                            : ""}
                    </div>
                )}

                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        {currentFormType === currentFormTypes.NEW
                            ? `Save New ${teamId ? " Team " : ""} Availability`
                            : `Save ${teamId ? " Team " : ""} Availability`}
                    </Button>
                    <Button
                        onClick={() =>
                            academicId
                                ? push(`/bookings/availability/${academicId}/manage`)
                                : push(
                                      `/bookings/availability/team/${teamId}/${teamMemberId}/manage`
                                  )
                        }
                        css={formsCss.btnBack}
                    >
                        Back to the list
                    </Button>
                </div>

                <PromptDialog
                    open={openDialog && repeating === REPEATING_DAILY}
                    handleClose={this.handleClose}
                    confirmLabel={"Save & Close"}
                    cancelLabel={false}
                    maxWidth="sm"
                    fullWidth
                >
                    <div>
                        <div css={formsCss.field}>
                            <div css={formsCss.inline}>
                                <div css={formsCss.inlineItem}>Repeat every</div>
                                <Field
                                    name="daily.every"
                                    component={RenderSelectField}
                                    errorRes={formErrors.daily && formErrors.daily.every}
                                    options={Array.from(Array(30).keys()).map(index => {
                                        return { value: index + 1, label: index + 1 };
                                    })}
                                    fullWidth={false}
                                    margin="none"
                                    css={formsCss.inlineItem}
                                />
                                <div css={formsCss.inlineItem}>
                                    day
                                    {formFields && formFields.daily && formFields.daily.every > 1
                                        ? "s"
                                        : ""}
                                </div>
                            </div>
                        </div>
                        <hr css={globalsCss.hrDialog} />
                        <div>
                            <Field
                                name="daily.config"
                                component={RenderRadioGroup}
                                errorRes={formErrors.daily && formErrors.daily.config}
                                onBlur={e => e.preventDefault()}
                            >
                                <RenderRadio
                                    value={REPEAT_IN_TERM}
                                    label={"Repeat in current term time"}
                                    className="col-xs-12"
                                />

                                <RenderRadio
                                    value={REPEAT_IN_ACADEMIC_YEAR}
                                    label={"Repeat in term time until end of academic year"}
                                    className="col-xs-12"
                                />

                                <RenderRadio
                                    value={REPEAT_N_TIMES}
                                    label={
                                        <div css={formsCss.inlineAlignTop}>
                                            <div css={formsCss.inlineItem}>Repeat</div>
                                            <TextField
                                                css={[
                                                    formsCss.inlineItem,
                                                    formsCss.inlineItemField,
                                                ]}
                                                select
                                                color="primary"
                                                error={
                                                    !!(formErrors.daily && formErrors.daily.ntimes)
                                                }
                                                helperText={
                                                    formErrors.daily && formErrors.daily.ntimes
                                                }
                                                fullWidth={false}
                                                onChange={({ target: { value } }) =>
                                                    this.setState({ dailyNtimes: value }, () => {
                                                        updateForm("daily.config", REPEAT_N_TIMES);
                                                        updateForm("daily.ntimes", value);
                                                    })
                                                }
                                                value={dailyNtimes ? dailyNtimes : ""}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputRoot,
                                                        error: classes.inputError,
                                                    },
                                                }}
                                                margin="none"
                                            >
                                                {Array.from(Array(30).keys()).map(index => {
                                                    return (
                                                        <MenuItem
                                                            key={`d${index}`}
                                                            value={index + 1}
                                                        >
                                                            {`${index + 1}`}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                            <div css={formsCss.inlineItem}>
                                                time
                                                {formFields &&
                                                formFields.daily &&
                                                formFields.daily.ntimes === 1
                                                    ? ""
                                                    : "s"}
                                            </div>
                                        </div>
                                    }
                                    className="col-xs-12"
                                />

                                <RenderRadio
                                    value={REPEAT_UNTIL}
                                    label={
                                        <div css={formsCss.inlineAlignTop}>
                                            <div css={formsCss.inlineItem}>Repeat until</div>
                                            <DatePicker
                                                name="dailyuntil"
                                                value={convertToDate(dailyDate)}
                                                onChange={value =>
                                                    this.setState({ dailyDate: value }, () => {
                                                        updateForm("daily.config", REPEAT_UNTIL);
                                                        updateForm("daily.until", value);
                                                    })
                                                }
                                                format={"D MMM YYYY"}
                                                autoOk={true}
                                                css={[
                                                    formsCss.inlineItem,
                                                    formsCss.inlineItemField,
                                                ]}
                                                error={
                                                    !!(formErrors.daily && formErrors.daily.until)
                                                }
                                                helperText={
                                                    formErrors.daily && formErrors.daily.until
                                                }
                                                color="primary"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputRoot,
                                                        error: classes.inputError,
                                                    },
                                                }}
                                            />
                                        </div>
                                    }
                                    className="col-xs-12"
                                />
                            </Field>
                        </div>
                    </div>
                </PromptDialog>
                <PromptDialog
                    open={openDialog && repeating === REPEATING_WEEKLY}
                    handleClose={this.handleClose}
                    confirmLabel={"Save & Close"}
                    cancelLabel={false}
                    maxWidth="sm"
                    fullWidth
                >
                    <div>
                        <div css={formsCss.field}>
                            <div css={formsCss.inline}>
                                <div css={formsCss.inlineItem}>Repeat every</div>
                                <Field
                                    css={formsCss.inlineItem}
                                    name="weekly.every"
                                    component={RenderSelectField}
                                    errorRes={formErrors.weekly && formErrors.weekly.every}
                                    options={Array.from(Array(10).keys()).map(index => {
                                        return { value: index + 1, label: index + 1 };
                                    })}
                                    fullWidth={false}
                                    margin="none"
                                />
                                <div css={formsCss.inlineItem}>
                                    week
                                    {formFields && formFields.weekly && formFields.weekly.every > 1
                                        ? "s"
                                        : ""}
                                </div>
                            </div>
                        </div>
                        <div css={formsCss.field}>
                            <div className="row">
                                <div className="col-xs-12">Repeat on:</div>
                                <div className="col-sm-2 col-xs-12">
                                    <Field
                                        name="weekly.repeatMon"
                                        label="Mon"
                                        component={RenderCheckboxField}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <Field
                                        name="weekly.repeatTue"
                                        label="Tue"
                                        component={RenderCheckboxField}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <Field
                                        name="weekly.repeatWed"
                                        label="Wed"
                                        component={RenderCheckboxField}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <Field
                                        name="weekly.repeatThu"
                                        label="Thu"
                                        component={RenderCheckboxField}
                                    />
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                    <Field
                                        name="weekly.repeatFri"
                                        label="Fri"
                                        component={RenderCheckboxField}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <Field
                                        name="weekly.repeatSat"
                                        label="Sat"
                                        component={RenderCheckboxField}
                                        css={formsCss.labelSecondary}
                                        errorRes={formErrors.weekly && formErrors.weekly.repeatSat}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <Field
                                        name="weekly.repeatSun"
                                        label="Sun"
                                        component={RenderCheckboxField}
                                        css={formsCss.labelSecondary}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr css={globalsCss.hrDialog} />
                        <div>
                            {formErrors.weekly && formErrors.weekly.config}
                            <Field
                                name="weekly.config"
                                component={RenderRadioGroup}
                                errorRes={formErrors.weekly && formErrors.weekly.config}
                                onBlur={e => e.preventDefault()}
                            >
                                <RenderRadio
                                    value={REPEAT_IN_TERM}
                                    label={"Repeat in current term time"}
                                    className="col-xs-12"
                                />

                                <RenderRadio
                                    value={REPEAT_IN_ACADEMIC_YEAR}
                                    label={"Repeat in term time until end of academic year"}
                                    className="col-xs-12"
                                />

                                <RenderRadio
                                    value={REPEAT_N_TIMES}
                                    label={
                                        <div css={formsCss.inlineAlignTop}>
                                            <div css={formsCss.inlineItem}>Repeat</div>
                                            <TextField
                                                css={[
                                                    formsCss.inlineItem,
                                                    formsCss.inlineItemField,
                                                ]}
                                                select
                                                margin="none"
                                                color="primary"
                                                fullWidth={false}
                                                error={
                                                    !!(
                                                        formErrors.weekly &&
                                                        formErrors.weekly.ntimes
                                                    )
                                                }
                                                helperText={
                                                    formErrors.weekly && formErrors.weekly.ntimes
                                                }
                                                onChange={({ target: { value } }) =>
                                                    this.setState({ weeklyNtimes: value }, () => {
                                                        updateForm("weekly.config", REPEAT_N_TIMES);
                                                        updateForm("weekly.ntimes", value);
                                                    })
                                                }
                                                value={weeklyNtimes ? weeklyNtimes : ""}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputRoot,
                                                        error: classes.inputError,
                                                    },
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        MenuListProps: {
                                                            disablePadding: true,
                                                            dense: true,
                                                        },
                                                    },
                                                }}
                                            >
                                                {Array.from(Array(30).keys()).map(index => {
                                                    return (
                                                        <MenuItem
                                                            key={`d${index}`}
                                                            value={index + 1}
                                                        >
                                                            {`${index + 1}`}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                            <div css={formsCss.inlineItem}>
                                                time
                                                {formFields &&
                                                formFields.weekly &&
                                                formFields.weekly.ntimes === 1
                                                    ? ""
                                                    : "s"}
                                            </div>
                                        </div>
                                    }
                                    className="col-xs-12"
                                />

                                <RenderRadio
                                    value={REPEAT_UNTIL}
                                    label={
                                        <div css={formsCss.inlineAlignTop}>
                                            <div css={formsCss.inlineItem}>Repeat until</div>
                                            <DatePicker
                                                name="weeklyuntil"
                                                value={convertToDate(weeklyDate)}
                                                onChange={value =>
                                                    this.setState({ weeklyDate: value }, () => {
                                                        updateForm("weekly.config", REPEAT_UNTIL);
                                                        updateForm("weekly.until", value);
                                                    })
                                                }
                                                format={"D MMM YYYY"}
                                                autoOk={true}
                                                fullWidth={false}
                                                error={
                                                    !!(formErrors.weekly && formErrors.weekly.until)
                                                }
                                                helperText={
                                                    formErrors.weekly && formErrors.weekly.until
                                                }
                                                color="primary"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputRoot,
                                                        error: classes.inputError,
                                                    },
                                                }}
                                                css={[
                                                    formsCss.inlineItem,
                                                    formsCss.inlineItemField,
                                                ]}
                                            />
                                        </div>
                                    }
                                    className="col-xs-12"
                                />
                            </Field>
                        </div>
                    </div>
                </PromptDialog>
                <PromptDialog
                    open={openDialog && repeating === REPEATING_MONTHLY}
                    handleClose={this.handleClose}
                    confirmLabel={"Save & Close"}
                    cancelLabel={false}
                    maxWidth="sm"
                    fullWidth
                >
                    <div>
                        <div css={formsCss.field}>
                            <div css={formsCss.inline}>
                                <div css={formsCss.inlineItem}>Repeat every</div>
                                <Field
                                    name="monthly.every"
                                    component={RenderSelectField}
                                    errorRes={formErrors.monthly && formErrors.monthly.every}
                                    options={Array.from(Array(10).keys()).map(index => {
                                        return { value: index + 1, label: index + 1 };
                                    })}
                                    css={formsCss.inlineItem}
                                    fullWidth={false}
                                    margin="none"
                                />
                                <div css={formsCss.inlineItem}>
                                    month
                                    {formFields &&
                                    formFields.monthly &&
                                    formFields.monthly.every > 1
                                        ? "s"
                                        : ""}
                                </div>
                            </div>
                        </div>
                        <hr css={globalsCss.hrDialog} />
                        <div>
                            <Field
                                name="monthly.config"
                                component={RenderRadioGroup}
                                errorRes={formErrors.monthly && formErrors.monthly.config}
                                onBlur={e => e.preventDefault()}
                            >
                                <RenderRadio
                                    value={REPEAT_IN_TERM}
                                    label={"Repeat in current term time"}
                                    className="col-xs-12"
                                />

                                <RenderRadio
                                    value={REPEAT_IN_ACADEMIC_YEAR}
                                    label={"Repeat in term time until end of academic year"}
                                    className="col-xs-12"
                                />

                                <RenderRadio
                                    value={REPEAT_N_TIMES}
                                    label={
                                        <div css={formsCss.inlineAlignTop}>
                                            <div css={formsCss.inlineItem}>Repeat</div>
                                            <TextField
                                                select
                                                color="primary"
                                                fullWidth={false}
                                                error={
                                                    !!(
                                                        formErrors.monthly &&
                                                        formErrors.monthly.ntimes
                                                    )
                                                }
                                                helperText={
                                                    formErrors.monthly && formErrors.monthly.ntimes
                                                }
                                                onChange={({ target: { value } }) =>
                                                    this.setState({ monthlyNtimes: value }, () => {
                                                        updateForm(
                                                            "monthly.config",
                                                            REPEAT_N_TIMES
                                                        );
                                                        updateForm("monthly.ntimes", value);
                                                    })
                                                }
                                                value={monthlyNtimes ? monthlyNtimes : ""}
                                                margin="none"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputRoot,
                                                        error: classes.inputError,
                                                    },
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        MenuListProps: {
                                                            disablePadding: true,
                                                            dense: true,
                                                        },
                                                    },
                                                }}
                                                css={[
                                                    formsCss.inlineItem,
                                                    formsCss.inlineItemField,
                                                ]}
                                            >
                                                {Array.from(Array(30).keys()).map(index => {
                                                    return (
                                                        <MenuItem
                                                            key={`d${index}`}
                                                            value={index + 1}
                                                        >
                                                            {`${index + 1}`}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                            <div css={formsCss.inlineItem}>
                                                time
                                                {formFields &&
                                                formFields.monthly &&
                                                formFields.monthly.ntimes === 1
                                                    ? ""
                                                    : "s"}
                                            </div>
                                        </div>
                                    }
                                    className="col-xs-12"
                                />

                                <RenderRadio
                                    value={REPEAT_UNTIL}
                                    label={
                                        <div css={formsCss.inlineAlignTop}>
                                            <div css={formsCss.inlineItem}>Repeat until</div>
                                            <DatePicker
                                                name="monthlyuntil"
                                                value={convertToDate(monthlyDate)}
                                                onChange={value =>
                                                    this.setState({ monthlyDate: value }, () => {
                                                        updateForm("monthly.config", REPEAT_UNTIL);
                                                        updateForm("monthly.until", value);
                                                    })
                                                }
                                                onBlur={() => {
                                                    return;
                                                }}
                                                format={"D MMM YYYY"}
                                                autoOk={true}
                                                css={[
                                                    formsCss.inlineItem,
                                                    formsCss.inlineItemField,
                                                ]}
                                                error={
                                                    !!(
                                                        formErrors.monthly &&
                                                        formErrors.monthly.until
                                                    )
                                                }
                                                helperText={
                                                    formErrors.monthly && formErrors.monthly.until
                                                }
                                                color="primary"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.inputRoot,
                                                        error: classes.inputError,
                                                    },
                                                }}
                                            />
                                        </div>
                                    }
                                    className="col-xs-12"
                                />
                            </Field>
                        </div>
                    </div>
                </PromptDialog>
            </form>
        );
    }
}

const FORM_NAME = "OfficeHoursAvailability";

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = ({ form }) => ({
    formFields: selector(
        { form },
        "startAt",
        "endAt",
        "repeating",
        "timeInAdvance",
        "summaryAtTime",
        "timeInAdvanceValue",
        "summaryAtTimeValue",
        "receiveSummaryEmailAheadUpcomingAppointments",
        "addBookedAppointments",
        "addSessionPlaceholders",
        "integrateWithZoom",
        "daily.every",
        "weekly.every",
        "monthly.every",
        "daily.ntimes",
        "weekly.ntimes",
        "monthly.ntimes",
        "hasBookedSlots",
        "onlineMeetings",
        "mode"
    ),
    formErrors: form[FORM_NAME]
        ? form[FORM_NAME].asyncErrors
            ? form[FORM_NAME].asyncErrors
            : form[FORM_NAME].syncErrors
            ? form[FORM_NAME].syncErrors
            : {}
        : {},
    popupErrors:
        form[FORM_NAME] && form[FORM_NAME].asyncErrors ? form[FORM_NAME].asyncErrors : null,
});

const dispatchToProps = {
    updateForm: (key, newValue) => change(FORM_NAME, key, newValue),
    addField: (key, newValue) => registerField(FORM_NAME, key, newValue),
};

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    touchOnBlur: false,
    validate,
    asyncValidate,
    asyncBlurFields: ["repeatingType"],
})(withStyles(formsCss)(Form));

export default connect(mapStateToProps, dispatchToProps)(form);
