import actionCreators from "./actionCreators";

export const initialState = {
    sideLinks: [],
    errorRes: { error: "", errors: {} },
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateSidelinks.type:
            return {
                ...state,
                sideLinks: payload,
                errorRes: initialState.errorRes,
            };

        case actionCreators.clearSideLinks.type:
            return {
                ...state,
                sideLinks: initialState.sideLinks,
            };

        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
