import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Paper, Button } from "@material-ui/core";
import actionCreators from "../../../../store/PersistentEvents/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { TableComponent } from "../../../../common/components/DefaultTable";
import { RedirectButton } from "../../../../common/components/RedirectButton";

import { MainTitle } from "../../../../common/components/MainTitle";
import PromptDialog from "../../../../common/components/PromptDialog";
import globalsCss from "../../../../common/styles/globals.css";
import tableCss from "../../../../common/styles/table.css";

import { decorateTableItemsJobTypes } from "../../../../common/services/decorateTableItems";

//import TriggerTask from "./components/TriggerTask";
import formsCss from "../../../../common/styles/forms.css";
import {
    DeleteIcon,
    AutoRenewIcon,
    EditIcon,
    PostIcon,
    ArchiveIcon,
} from "../../../../common/components/icons";
import PersistentEventsFilterRow from "../components/PersistentEventsFilterRow";
import { GetFormattedDate, GetFormattedTime } from "../../../../common/components/Time";
import { withQueryParams } from "../../../../common/components/withQueryParams";
import { prepareFilterQuery } from "../../../../common/services/utils";
import { statusCallback } from "../../../../common/services/tableCallbacks";
import RowDialogField from "../../../../common/components/RowDialogField";

const callbacks = {
    status: statusCallback,
    isOptional: (item, prop) => (item[prop] ? "Optional" : "Standard"),
    // eslint-disable-next-line react/display-name
    targeting: item =>
        item.targeting ? (
            <RowDialogField
                iconImage={<PostIcon />}
                dialogContent={
                    <div>
                        {item.targeting.channels && item.targeting.channels.length > 0 && (
                            <div>
                                <strong>Pages:</strong>
                                <ul
                                    css={[
                                        globalsCss.helpers.mt5,
                                        item.targeting.audiences &&
                                        item.targeting.audiences.length > 0
                                            ? globalsCss.helpers.mb25
                                            : globalsCss.helpers.mb5,
                                    ]}
                                >
                                    {item.targeting.channels.map((c, index) => {
                                        return <li key={index}>{c.name}</li>;
                                    })}
                                </ul>
                            </div>
                        )}

                        {item.targeting.audiences && item.targeting.audiences.length > 0 && (
                            <div>
                                <strong>Audiences:</strong>
                                <ul css={[globalsCss.helpers.mt5, globalsCss.helpers.mb5]}>
                                    {item.targeting.audiences.map((c, index) => {
                                        return <li key={index}>{c.name}</li>;
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                }
                maxWidth="sm"
                fullWidth
            />
        ) : (
            ""
        ),
};

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            targeting: "Targeting",
            isOptional: "Attendance",
            dateTime: "Date",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            name: tableCss.columnWidth300,
            dateTime: tableCss.columnWidth300,
            targeting: tableCss.img,
        },
    },
];

const buttons = items => index => [
    ...(items[index].status !== "archived"
        ? [
              {
                  path: `/calendar/lse-calendar/events/edit/${items[index].id}`,
                  label: "Edit",
                  fnLabel: "push",
                  icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
                  hide: !items[index].id,
              },
          ]
        : []),
    {
        path: items[index].id,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        hide: !items[index].id,
    },
    ...(items[index].status === "live"
        ? [
              {
                  path: `${items[index] ? items[index].id : null}`,
                  label: "Archive",
                  fnLabel: "archive",
                  icon: <ArchiveIcon css={tableCss.actionsMenuItemIcon} />,
              },
          ]
        : []),
];

const columns = ["status", "name", "dateTime", "location", "isOptional", "targeting"];

class PersistentEventsListContainer extends Component {
    state = {
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
    };

    componentDidMount() {
        const { getPersistentEvents, setTitle, queryParams } = this.props;
        const { filter$ } = this.state;
        setTitle("LSE Calendar Events");

        const params = this.props.initWithQueryParams({
            filter: "",
            from: "",
            to: "",
            page: 1,
        });

        if (queryParams !== prepareFilterQuery(params)) {
            getPersistentEvents({ ...params, page: 1 });
        }

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            const params = this.props.setQueryParams({ ...term, page: 1 });
            getPersistentEvents(params);
        });
    }

    handler = ({ field, value }) => {
        const { filter$ } = this.state;

        const params = this.props.setQueryParams({
            [field]: value
                ? field === "from" || field === "to"
                    ? value.toISOString()
                    : value
                : "",
            page: 1,
        });
        filter$.next(params);
    };

    componentWillUnmount() {
        this.props.clearTitle();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onDateChangeHandler = (field, newValue = undefined) => value => {
        this.handler({ field, value: newValue !== undefined ? newValue : value });
    };

    onFilterChangeHandler = ({ target: { value } }) => {
        this.handler({ field: "filter", value });
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMorePersistentEvents } = this.props;
        const params = this.props.setQueryParams({ page });
        loadMorePersistentEvents(params);
    };

    handleOpen = id => {
        this.setState({ removeDialog: true, removeId: id });
    };

    handleClose = confirmed => {
        if (confirmed === true && this.state.removeId) {
            this.props.deletePersistentEvents(this.state.removeId);
        }

        this.setState({ removeDialog: false, removeId: null });
    };

    remove = itemId => {
        this.handleOpen(itemId);
    };

    archive = itemId => {
        this.props.archivePersistentEvent(itemId);
    };

    render() {
        const {
            events,
            count,
            nextPage,
            history: { push },
        } = this.props;
        const { removeDialog } = this.state;
        const { filter, from, to } = this.props.getQueryParams();
        const { onDateChangeHandler, onFilterChangeHandler, archive, remove } = this;

        return (
            <div>
                <MainTitle title="LSE Calendar Events" />
                <div css={globalsCss.actions}>
                    <div css={globalsCss.actionsItem}>
                        <RedirectButton
                            label="Add New LSE Calendar Event"
                            url="/calendar/lse-calendar/events/new"
                            {...{ push }}
                        />
                    </div>
                </div>
                <Paper elevation={1}>
                    <PersistentEventsFilterRow
                        {...{
                            from,
                            to,
                            filter,
                            onFilterChangeHandler,
                            onDateChangeHandler,
                        }}
                    />
                    <TableComponent
                        items={events}
                        fns={{ push, archive, remove }}
                        decorators={decorators}
                        columns={columns}
                        buttons={buttons(events)}
                        callbacks={callbacks}
                    />
                </Paper>
                <PromptDialog open={removeDialog} handleClose={this.handleClose}>
                    Are you sure you wanted to delete this event?
                </PromptDialog>
                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {events.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ PersistentEvents: { events } }) => ({
    events: events.data.map(i => {
        return {
            id: i.id,
            name: i.name,
            status: i.status,
            isOptional: i.isOptional,
            dateTime: `${GetFormattedDate(i.startAt, "ddd D MMM YYYY")} ${
                !i.isAllDay
                    ? `${GetFormattedTime(i.startAt, "h.mma")} - ${GetFormattedTime(
                          i.endAt,
                          "h.mma"
                      )}`
                    : "- All day"
            }`,
            location: i.location,
            targeting:
                i.status === "archived"
                    ? {
                          channels: i.archivedAudience.Channels ? i.archivedAudience.Channels : [],
                          audiences: i.archivedAudience.Audiences
                              ? i.archivedAudience.Audiences
                              : [],
                      }
                    : {
                          channels: i.channels,
                          audiences: i.customAudiences,
                      },
        };
    }),
    count: events.count,
    nextPage: events.nextPage,
    queryParams: events.queryParams,
});

const mapDispatchToProps = {
    getPersistentEvents: actionCreators.getPersistentEvents.create,
    deletePersistentEvents: actionCreators.deletePersistentEvents.create,
    loadMorePersistentEvents: actionCreators.loadMorePersistentEvents.create,
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    archivePersistentEvent: actionCreators.archivePersistentEvent.create,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withQueryParams(PersistentEventsListContainer));
