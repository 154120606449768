/** @jsx jsx */
import { jsx } from "@emotion/core";

import formsCss from "../../../common/styles/forms.css";
import AutoCompleteDownshift from "../Downshift/AutoCompleteDownshift";

export const RenderAutocomplete = ({
    input,
    meta: { touched, error },
    errorRes,
    handleInput,
    dataSource,
    onNewRequest,
    onDroppedFocus,
    labelCallback,
    subtitleCallback,
    numCallback,
    single,
    floatingLabelText,
    inputValue,
    noAvatar,
    onChangeCallback,
    blacklistedIds,
    disabled,
}) => {
    return (
        <div css={formsCss.autoCompleteFullWidth}>
            <AutoCompleteDownshift
                floatingLabelText={floatingLabelText || "Full Name or [SSO] Username"}
                selectedItems={input.value ? (single ? [input.value] : input.value) : []}
                onChange={input.onChange}
                onUpdateInput={handleInput}
                onNewRequest={onNewRequest}
                dataSource={dataSource}
                onBlur={onDroppedFocus}
                single={!!single}
                noAvatar={!!noAvatar}
                inputValue={inputValue}
                labelCallback={labelCallback || (item => `${item.firstName} ${item.lastName}`)}
                subtitleCallback={
                    subtitleCallback ||
                    (item => (item.lseEmail ? item.lseEmail : item.email ? item.email : null))
                }
                numCallback={numCallback}
                errorRes={!!touched && (!!error || !!errorRes)}
                onChangeCallback={onChangeCallback}
                blacklistedIds={blacklistedIds}
                disabled={!!disabled}
            />
            {touched && (error || errorRes) && (
                <span css={[formsCss.errorSmall, formsCss.errorSmallMT]}>
                    {touched && (error || errorRes)}
                </span>
            )}
        </div>
    );
};
