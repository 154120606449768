import React, { FC, forwardRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useFormContext, Controller } from "react-hook-form";
import actionCreators from "src/store/Froala/actionCreators";
import formsCss from "src/common/styles/forms.css";
import { useSelector, useDispatch } from "react-redux";

// Froala
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "font-awesome/css/font-awesome.css";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins/emoticons.min.js";
import { AppState } from "src/store/reducers";

import { simpleConfig, postConfig, articleConfig, baseConfig } from "./helpers/WyswigConfigs";

/** @jsx jsx */
import { jsx } from "@emotion/core";

export enum WysiwygTypes {
    Simple,
    Post,
    Article,
    Base,
}

interface OwnProps {
    name: string;
    label?: string;
    type: WysiwygTypes;
    errorRes?: string;
}

const RHFWysiwyg: FC<OwnProps> = forwardRef(({ name, label, type, errorRes, ...other }, ref) => {
    const { control } = useFormContext();
    const dispatch = useDispatch();
    const token = useSelector((state: AppState) => state.Auth.token);

    const addImage = i => dispatch(actionCreators.addFroalaImage.create(i));
    const deleteImage = () => dispatch(actionCreators.deleteFroalaImage.create());

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <div>
                        {label && <label css={formsCss.label}>{label}</label>}
                        <FroalaEditor
                            language="en_gb"
                            onModelChange={field.onChange}
                            config={
                                type === WysiwygTypes.Simple
                                    ? simpleConfig()
                                    : type === WysiwygTypes.Post
                                    ? postConfig()
                                    : type === WysiwygTypes.Article
                                    ? articleConfig(addImage, deleteImage, token)
                                    : baseConfig()
                            }
                            {...other}
                            {...field}
                            model={field.value || ""}
                            inputRef={ref}
                        />
                        <span css={formsCss.genericError}>{error || errorRes}</span>
                    </div>
                );
            }}
        />
    );
});

const RenderWysiwyg = withStyles(formsCss)(RHFWysiwyg);

export default RenderWysiwyg;
