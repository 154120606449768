const letterSpacing = {
    big: -1.1,
    middle: -0.6,
    small: -0.4,
    little: -0.2,
};

const fontWeight = {
    regular: 400,
    bold: 700,
    black: 900,
};

export { letterSpacing, fontWeight };
