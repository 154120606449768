import ActionCreator from "../../actionCreator";

export default {
    getListSchoolYears: ActionCreator("GET_LIST_SCHOOL_YEARS"),
    createListSchoolYears: ActionCreator("CREATE_LIST_SCHOOL_YEARS"),
    loadMoreSchoolYears: ActionCreator("LOAD_MORE_SCHOOL_YEARS"),
    updateListSchoolYears: ActionCreator("UPDATE_LIST_SCHOOL_YEARS"),
    postSchoolYear: ActionCreator("POST_SCHOOL_YEAR"),
    getOneSchoolYear: ActionCreator("GET_ONE_SCHOOL_YEAR"),
    clearSchoolYear: ActionCreator("CLEAR_SCHOOL_YEAR"),
    putSchoolYear: ActionCreator("PUT_SCHOOL_YEAR"),
    updateOneSchoolYear: ActionCreator("UPDATE_ONE_SCHOOL_YEAR"),
    pushListSchoolYears: ActionCreator("PUSH_LIST_SCHOOL_YEARS"),
    removeSchoolYear: ActionCreator("REMOVE_SCHOOL_YEAR"),
    addSchoolYearHoliday: ActionCreator("ADD_SCHOOL_YEAR_HOLIDAY"),
    removeSchoolYearHoliday: ActionCreator("REMOVE_SCHOOL_YEAR_HOLIDAY"),
    errorResponse: ActionCreator("ERROR_RESPONSE_SCHOOL_YEARS"),
};
