export const mergeLists = (data, payload, hashCallback = x => x.id, processCallback = x => x) => {
    const idMap = {};
    const results = [...data];
    results.forEach((obj, index) => {
        idMap[hashCallback(obj)] = index;
    });

    payload.forEach(obj => {
        if (hashCallback(obj) in idMap) {
            // update object
            results[idMap[hashCallback(obj)]] = processCallback(obj);
        } else {
            idMap[hashCallback(obj)] = results.length;
            results.push(processCallback(obj));
        }
    });

    return results;
};

export const removeFromList = (list, payload) => ({
    data: list.data.filter(item => item.id !== payload),
    count: list.count - 1, // assuming that remove action successfully removed 1 item
    nextPage: list.nextPage,
});

export const removeProp = (obj, prop) => {
    let { [prop]: omit, ...res } = obj;
    return res;
};
