import { isValidEmail } from "../../../../common/services/formHelpers";
import moment from "moment-timezone";

import {
    REPEATING_DAILY,
    REPEATING_WEEKLY,
    REPEATING_MONTHLY,
    REPEAT_N_TIMES,
    REPEAT_UNTIL,
    TYPE_REPEATING,
    MEETING_CUSTOM,
} from "../types";

export const asyncValidate = values => {
    const res = new Promise((resolve, reject) => {
        let errors = {};
        if (values.repeating === TYPE_REPEATING) {
            // repeating daily
            if (values.repeatingType === REPEATING_DAILY && values.daily) {
                if (!values.daily.config) {
                    if (!errors.daily) errors.daily = {};
                    errors.daily.config = "Required";
                }

                if (values.daily.config === REPEAT_N_TIMES && !values.daily.ntimes) {
                    if (!errors.daily) errors.daily = {};
                    errors.daily.ntimes = "Required";
                }

                if (values.daily.config === REPEAT_UNTIL && !values.daily.until) {
                    if (!errors.daily) errors.daily = {};
                    errors.daily.until = "Required";
                }

                if (values.daily.config === REPEAT_UNTIL && !!values.daily.until) {
                    if (moment(values.daily.until).diff(moment()) <= 0) {
                        if (!errors.daily) errors.daily = {};
                        errors.daily.until = "Selected date must be in the future";
                    }
                }
            }

            // repeating weekly
            else if (values.repeatingType === REPEATING_WEEKLY && values.weekly) {
                if (
                    !values.weekly.repeatMon &&
                    !values.weekly.repeatTue &&
                    !values.weekly.repeatWed &&
                    !values.weekly.repeatThu &&
                    !values.weekly.repeatFri &&
                    !values.weekly.repeatSat &&
                    !values.weekly.repeatSun
                ) {
                    if (!errors.weekly) errors.weekly = {};
                    errors.weekly.repeatSat = "Required";
                }

                if (!values.weekly.config) {
                    if (!errors.weekly) errors.weekly = {};
                    errors.weekly.config = "Required";
                }

                if (values.weekly.config === REPEAT_N_TIMES && !values.weekly.ntimes) {
                    if (!errors.weekly) errors.weekly = {};
                    errors.weekly.ntimes = "Required";
                }

                if (values.weekly.config === REPEAT_UNTIL && !values.weekly.until) {
                    if (!errors.weekly) errors.weekly = {};
                    errors.weekly.until = "Required";
                }

                if (values.weekly.config === REPEAT_UNTIL && !!values.weekly.until) {
                    if (moment(values.weekly.until).diff(moment()) <= 0) {
                        if (!errors.weekly) errors.weekly = {};
                        errors.weekly.until = "Selected date must be in the future";
                    }
                }
            }

            // repeating monthly
            else if (values.repeatingType === REPEATING_MONTHLY && values.monthly) {
                if (!values.monthly.config) {
                    if (!errors.monthly) errors.monthly = {};
                    errors.monthly.config = "Required";
                }

                if (values.monthly.config === REPEAT_N_TIMES && !values.monthly.ntimes) {
                    if (!errors.monthly) errors.monthly = {};
                    errors.monthly.ntimes = "Required";
                }

                if (values.monthly.config === REPEAT_UNTIL && !values.monthly.until) {
                    if (!errors.monthly) errors.monthly = {};
                    errors.monthly.until = "Required";
                }

                if (values.monthly.config === REPEAT_UNTIL && !!values.monthly.until) {
                    if (moment(values.monthly.until).diff(moment()) <= 0) {
                        if (!errors.monthly) errors.monthly = {};
                        errors.monthly.until = "Selected date must be in the future";
                    }
                }
            }
        }

        if (Object.keys(errors).length > 0) {
            reject(errors);
        } else resolve();
    });

    return res;
};

export const validate = values => {
    let errors = {};
    const requiredFields = ["title", "date", "startAt", "endAt", "duration", "onlineMeetings"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    if (!values.id && values.date) {
        let date = moment(values.date);
        if (values.startAt) {
            const startAt = moment(values.startAt);
            date = date.set({
                hour: startAt.get("hour"),
                minute: startAt.get("minute"),
                second: 0,
                millisecond: 0,
            });
        }
        if (date.diff(moment()) <= 0) errors.date = "Selected date must be in the future";
    }

    if (
        values.startAt &&
        values.startAt &&
        moment(values.startAt)
            .tz("Europe/London")
            .isAfter(moment(values.endAt).tz("Europe/London"))
    ) {
        errors.endAt = "Start time must be earlier than end time";
    }

    if (values.repeating === TYPE_REPEATING) {
        // no repeating type
        if (!values.repeatingType) {
            errors.repeatingType = "Please provide additional configuration";
        }
    }

    // email
    if (!!values.email && !isValidEmail(values.email)) {
        errors.email = `Invalid email`;
    }

    if (!values.receiveSummaryEmailAheadUpcomingAppointments) {
        if (
            !values.atEightOnSessionDay &&
            !values.timeInAdvance &&
            !values.summaryAtTime &&
            !values.atLatestBookingTime
        ) {
            errors.receiveSummaryEmailAheadUpcomingAppointments =
                "Please choose at least one option";
        } else if (!!values.timeInAdvance && !values.timeInAdvanceValue) {
            errors.timeInAdvanceValue = "Required";
        } else if (!!values.summaryAtTime && !values.summaryAtTimeValue) {
            errors.summaryAtTimeValue = "Required";
        }
    }

    // onlineMeetings & meetingUrl
    if (!!values.onlineMeetings && values.onlineMeetings === MEETING_CUSTOM && !values.meetingUrl) {
        errors.meetingUrl = "Required";
    }

    // general form error
    if (!values.date || !values.startAt || !values.endAt)
        errors.genericError = "Please choose the date, start time and end time";

    // required
    if (values.requireBookingNote && !values.note) {
        errors.note = "Required";
        errors.genericError = "Please add instructions for the students' booking message";
    }

    return errors;
};
