import { Component } from "react";
import { reduxForm, Field } from "redux-form";

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { connect } from "react-redux";
import { rawUpdateListOnFilterChange } from "../../../../../common/services/FilterList";
import { default as autoCompleteActionCreators } from "../../../../../store/AutoComplete/actionCreators";

import validate from "./validate";
import { RenderAutocomplete } from "../../../../../common/components/FormFields";

import { Button, Paper } from "@material-ui/core";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../../common/styles/globals.css";
import formsCss from "../../../../../common/styles/forms.css";

class Form extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const { getStudentUsers, clearStudentUsers } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            if (query.filter !== "") {
                rawUpdateListOnFilterChange({
                    getFn: getStudentUsers,
                    filter: { filter: query.filter },
                });
            } else clearStudentUsers();
        });
        this.props.initialize(this.props.initialValues);
    }

    onStudentChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () =>
            searchText$.next({ filter: this.state.searchText, type: "student" })
        );
    };

    render() {
        const { errors, handleSubmit, push, groupId, students, clearStudentUsers } = this.props;
        const { onStudentChangeHandler } = this;

        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>Add New Follower</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row" css={globalsCss.helpers.mt25}>
                            <div className="col-sm-6 col-xs-12">
                                <Field
                                    floatingLabelText="LSE Student(s)"
                                    name="students"
                                    errorRes={errors.academic}
                                    handleInput={onStudentChangeHandler}
                                    dataSource={students}
                                    component={RenderAutocomplete}
                                    onDroppedFocus={() => clearStudentUsers()}
                                />
                            </div>
                        </div>
                        <div css={formsCss.actions}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Add New Follower
                            </Button>
                            <Button
                                onClick={() => push(`/channels/pages/${groupId}/followers`)}
                                css={formsCss.btnBack}
                            >
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}

const dispatchToProps = {
    getStudentUsers: autoCompleteActionCreators.getStudentUsers.create,
    clearStudentUsers: autoCompleteActionCreators.clearStudentUsers.create,
};

const mapStateToProps = ({ AutoComplete }) => ({
    students: AutoComplete.students.map(user =>
        Object.assign({ fullName: `${user.firstName} ${user.lastName}` }, user)
    ),
});

export default connect(
    mapStateToProps,
    dispatchToProps
)(
    reduxForm({
        form: "Member",
        enableReinitialize: true,
        validate,
    })(Form)
);
