export default {
    id: null,
    status: "",
    publicationDate: null,
    postType: { id: null, name: "" },
    description: "",
    imageFile: {
        name: "",
        size: "",
        type: "",
        base64: "",
    },
    imageUrl: null,
    article: "",
    hasArticle: false,
};
