import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo, concatMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";
import { push } from "connected-react-router";

const apiGetEvents = payload => ajax.get(ajax.apiUrl(`manager/calendar/lse-event/`), payload);
const apiGetEvent = id => ajax.get(ajax.apiUrl(`manager/calendar/lse-event/${id}/`));
const apiPostEvent = payload => ajax.post(ajax.apiUrl(`manager/calendar/lse-event/`), payload);
const apiPutEvent = (id, payload) =>
    ajax.put(ajax.apiUrl(`manager/calendar/lse-event/${id}/`), payload);
const apiDeleteEvent = id => ajax.remove(ajax.apiUrl(`manager/calendar/lse-event/${id}/`));
const apiArchiveEvent = id => ajax.put(ajax.apiUrl(`manager/calendar/lse-event/${id}/archive/`));

const startLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getPersistentEvents.type,
            actionCreators.deletePersistentEvents.type,
            actionCreators.postPersistentEvents.type,
            actionCreators.putPersistentEvents.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const clearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updatePersistentEvents.type,
            actionCreators.updateMorePersistentEvents.type,
            actionCreators.updateDeletedPersistentEvents.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getPersistentEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getPersistentEvents.type),
        switchMap(({ payload: params }) =>
            apiGetEvents(params).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updatePersistentEvents.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );
const getPersistentEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getPersistentEvent.type),
        switchMap(({ payload: id }) =>
            apiGetEvent(id).pipe(
                mergeMap(({ response }) => of(actionCreators.setPersistentEvent.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );
const loadMorePersistentEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMorePersistentEvents.type),
        switchMap(({ payload }) =>
            apiGetEvents(payload).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateMorePersistentEvents.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const deletePersistentEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deletePersistentEvents.type),
        switchMap(({ payload }) =>
            apiDeleteEvent(payload).pipe(
                mapTo(actionCreators.updateDeletedPersistentEvents.create(JSON.parse(payload))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const postPersistentEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postPersistentEvents.type),
        switchMap(({ payload }) =>
            apiPostEvent(payload).pipe(
                concatMap(() =>
                    of(
                        actionCreators.clearQueryParams.create(),
                        UIActionCreators.clearLoading.create(),
                        push("/calendar/lse-calendar/events")
                    )
                ),

                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const putPersistentEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putPersistentEvents.type),
        switchMap(({ payload }) =>
            apiPutEvent(payload.id, payload).pipe(
                concatMap(() =>
                    of(
                        actionCreators.clearQueryParams.create(),
                        UIActionCreators.clearLoading.create(),
                        push("/calendar/lse-calendar/events")
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const archivePersistentEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.archivePersistentEvent.type),
        switchMap(({ payload }) =>
            apiArchiveEvent(payload).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateArchivedPersistentEvent.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    startLoadingEpic,
    clearLoadingEpic,
    getPersistentEventsEpic,
    getPersistentEventEpic,
    loadMorePersistentEventsEpic,
    deletePersistentEventsEpic,
    postPersistentEventsEpic,
    putPersistentEventsEpic,
    archivePersistentEventEpic
);
