import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import sidebarCss from "../../styles/sidebar.css";

class MainMenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    renderLink = props => <Link to={this.props.to} {...props} />;

    handleToggle = () => this.setState({ open: !this.state.open });
    handleOnClick = () => {
        !this.props.to && this.setState({ open: !this.state.open });
    };

    render() {
        const { to, title, icon, children, onClick, classes } = this.props;
        const { open } = this.state;

        return (
            <Fragment>
                <ListItem
                    button
                    classes={{
                        root: classes.rootMenuItem,
                    }}
                    component={to && this.renderLink}
                    // onClick={this.handleOnClick}
                    onClick={children ? this.handleOnClick : onClick}
                >
                    {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText
                        inset
                        disableTypography
                        primary={title}
                        classes={{
                            root: classes.rootMenuItemText,
                        }}
                    />
                    {!!children && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                </ListItem>
                {!!children && (
                    <Collapse in={open} timeout="auto">
                        <List component="div">{children}</List>
                    </Collapse>
                )}
            </Fragment>
        );
    }
}

export default withStyles(sidebarCss)(MainMenuItem);
