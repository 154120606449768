import { of } from "rxjs";
import { switchMap, mergeMap, catchError, mapTo, concatMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import { default as froalaActionCreators } from "../../Froala/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";

const postStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListPost.type,
            actionCreators.loadMorePosts.type,
            actionCreators.getOnePost.type,
            actionCreators.postPost.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const postClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListPost.type,
            actionCreators.updateListPost.type,
            actionCreators.updateOnePost.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListPostsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListPost.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/posts/"), payload).pipe(
                mergeMap(({ response }) => of(actionCreators.createListPost.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMorePostsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMorePosts.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/posts/"), payload).pipe(
                mergeMap(({ response }) => of(actionCreators.updateListPost.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOnePostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOnePost.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/posts/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOnePost.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newPostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postPost.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl(`staff/posts/`), action.payload.post).pipe(
                concatMap(() =>
                    of(
                        froalaActionCreators.commitFroalaChanges.create(),
                        actionCreators.clearQueryParams.create(),
                        UIActionCreators.clearLoading.create(),
                        push(
                            `/channels/posts?channelId=${action.payload.post.channel.id}&channelName=${action.payload.post.channel.name}`
                        )
                    )
                ),

                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const editPostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putPost.type),
        switchMap(action =>
            ajax
                .put(ajax.apiUrl(`staff/posts/${action.payload.post.id}/`), action.payload.post)
                .pipe(
                    concatMap(() =>
                        of(
                            froalaActionCreators.commitFroalaChanges.create(),
                            actionCreators.clearQueryParams.create(),
                            UIActionCreators.clearLoading.create(),
                            push(
                                `/channels/posts?channelId=${action.payload.post.channel.id}&channelName=${action.payload.post.channel.name}`
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removePostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removePost.type),
        switchMap(action =>
            ajax
                .remove(ajax.apiUrl(`staff/posts/${action.payload.id}/`))
                .pipe(
                    mapTo(actionCreators.getListPost.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const archivePostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.archivePost.type),
        switchMap(action =>
            ajax.put(ajax.apiUrl(`staff/posts/${action.payload}/archive/`)).pipe(
                concatMap(() => of(actionCreators.updateArchivedPost.create(action.payload))),

                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    postStartLoadingEpic,
    postClearLoadingEpic,
    getListPostsEpic,
    loadMorePostsEpic,
    getOnePostEpic,
    newPostEpic,
    editPostEpic,
    removePostEpic,
    archivePostEpic
);
