import { of, concat } from "rxjs";
import { mergeMap, switchMap, catchError, mapTo, tap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../../store/MainMenu/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";
import { push } from "connected-react-router";
import FileSaver from "file-saver";

const staffStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListStaff.type,
            actionCreators.loadMoreStaff.type,
            actionCreators.getOneStaff.type,
            actionCreators.putStaff.type,
            actionCreators.uploadStaffListXml.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const staffClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListStaff.type,
            actionCreators.updateListStaff.type,
            actionCreators.updateOneStaff.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListStaffEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListStaff.type),
        switchMap(action =>
            ajax
                .get(ajax.apiUrl(`admin/staff/?${action.payload}`))
                .pipe(
                    mergeMap(({ response }) => of(actionCreators.createListStaff.create(response)))
                )
        )
    );
const loadMoreStaffEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreStaff.type),
        mergeMap(action =>
            ajax.get(ajax.apiUrl(`admin/staff/?${action.payload}`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateListStaff.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneStaffEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneStaff.type),
        mergeMap(action =>
            ajax.get(ajax.apiUrl(`admin/staff/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOneStaff.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newStaffEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postStaff.type),
        mergeMap(action =>
            ajax
                .post(ajax.apiUrl("admin/staff/"), action.payload)
                .pipe(
                    mapTo(actionCreators.loadListStaff.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const editStaffEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putStaff.type),
        mergeMap(action =>
            ajax.patch(ajax.apiUrl(`admin/staff/${action.payload.id}/`), action.payload).pipe(
                mergeMap(({ response }) => of(actionCreators.loadListStaff.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadListStaffEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadListStaff.type),
        mapTo(push("/users-and-audiences/lse-staff"))
    );

const removeStaffEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeStaff.type),
        mergeMap(action =>
            ajax.remove(ajax.apiUrl(`admin/staff/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.getListStaff.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const staffListDownloadReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.downloadStaffList.type),
        switchMap(() =>
            ajax.blob(ajax.apiUrl(`admin/staff/export.xls`)).pipe(
                tap(({ response }) => FileSaver.saveAs(response, `StudentHubStaff.xls`)),
                mapTo(UIActionCreators.clearLoading.create()),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const downloadStaffListXmlTemplateEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.downloadStaffListXmlTemplate.type),
        switchMap(() =>
            ajax.blob(ajax.apiUrl(`admin/staff/import-template/`)).pipe(
                tap(({ response }) =>
                    FileSaver.saveAs(response, `StudentHubStaffUploadTemplate.xlsx`)
                ),
                mapTo(UIActionCreators.clearLoading.create()),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const uploadStaffListXmlEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.uploadStaffListXml.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl("admin/staff/import/"), action.payload).pipe(
                mergeMap(() =>
                    concat(
                        of(UIActionCreators.clearLoading.create()),
                        of(actionCreators.toggleBulkUploadDialog.create()),
                        of(actionCreators.getListStaff.create())
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    staffStartLoadingEpic,
    staffClearLoadingEpic,
    getListStaffEpic,
    loadMoreStaffEpic,
    getOneStaffEpic,
    newStaffEpic,
    editStaffEpic,
    loadListStaffEpic,
    removeStaffEpic,
    staffListDownloadReportEpic,
    downloadStaffListXmlTemplateEpic,
    uploadStaffListXmlEpic
);
