import background from "../../images/background.png";
import { colors, fontWeight, spacing } from "../../UIGlobals";

export const header = {
    background: {
        maxWidth: "100%",
        width: "100%",
        height: 200,
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        zIndex: 1,
    },
    container: {
        // zIndex: 2,
        // marginTop: -200,
        // minHeight: 200,
        // "@media (min-width: 1024px)": {
        //     marginLeft: "auto",
        //     marginRight: "auto",
        //     width: 1144,
        // },
        // "@media (max-width: 1024px)": {
        //     width: "100%",
        // },
        maxWidth: 1144,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
    },
    inner: {
        paddingTop: 32,
        display: "flex",
        flexDirection: "column",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        "@media (max-width: 768px)": {
            paddingRight: 0,
            paddingLeft: 0,
        },
        // "@media (min-width: 1024px)": {
        //     marginLeft: -100,
        //     marginRight: -100,
        // },
        // "@media (max-width: 1024px)": {
        //     marginLeft: 16,
        //     marginRight: 16,
        // },
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
    },
    headline: {
        fontSize: 40,
        fontWeight: fontWeight.black,
        lineHeight: 1,
        color: colors.white,
    },
    subHeadline: {
        paddingTop: 5,
        fontSize: 24,
        lineHeight: 1,
        fontWeight: fontWeight.regular,
        color: colors.white,
    },
};
