import { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import actionCreators from "../../../../../store/OfficeHours/Reports/actionCreators";
import appointmentsActionCreators from "../../../../../store/OfficeHours/Appointments/actionCreators";
import { RenderSelectField, RenderDatepicker } from "../../../../../common/components/FormFields";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles, Button } from "@material-ui/core";
import formsCss from "../../../../../common/styles/forms.css";
import globalsCss from "../../../../../common/styles/globals.css";
import { processSchoolYears } from "../../helpers/schoolYear";
import { validateRequiredFields } from "../../../../../common/services/formHelpers";

class StudentHistoryForm extends Component {
    componentDidMount() {
        const {
            departments,
            schoolYears,
            yearGroups,
            getOfficeReportDepartmentList,
            gettOfficeHoursSchoolYearsAndTerms,
            getOfficeReportYearGroupList,
            clearErrorResponse,
        } = this.props;
        clearErrorResponse();
        if (departments.length === 0) {
            getOfficeReportDepartmentList();
        }

        if (yearGroups.length === 0) {
            getOfficeReportYearGroupList();
        }

        if (schoolYears === null) {
            gettOfficeHoursSchoolYearsAndTerms();
        }

        this.props.initialize(this.props.initialValues);
    }

    clearSchoolYearSelect = () => {
        this.props.updateForm("schoolyear", null);
    };

    handleChangeDepartmentId = (e, value) => {
        this.props.updateForm("programmeYear", null);
        this.props.updateForm("yearGroup", null);
        this.props.getOfficeReportProgrammeList({ id: value });
    };

    handleChangeSchoolYear = (e, value) => {
        const dates = value.split("#");
        this.props.updateForm("from", dates[0]);
        this.props.updateForm("to", dates[1]);
    };

    onSubmit = values => {
        const { programmeYear, schoolyear, ...rest } = values;

        if (programmeYear) {
            const splitRes = programmeYear.split("|");
            const programmeId = splitRes[0];
            const academicYear = splitRes[1];
            this.props.createOfficeStudentByProgrammeReport({ programmeId, academicYear, ...rest });
        } else {
            this.props.createOfficeStudentByProgrammeReport({ ...rest });
        }
    };

    render() {
        const {
            handleSubmit,
            departments,
            programmes,
            schoolYears,
            yearGroups,
            formDept,
            formProgramme,
            formYearGroup,
            errorRes: { error, errors },
        } = this.props;

        if (departments.length === 0 || schoolYears === null) return null;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.labelBig}>Select department</div>
                        <Field
                            name="departmentId"
                            component={RenderSelectField}
                            label="Select value"
                            errorRes={errors.departmentId}
                            options={departments.map(d => ({
                                value: d.id,
                                label: d.name,
                            }))}
                            onChange={this.handleChangeDepartmentId}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.labelBig}>Select programme</div>
                        <Field
                            name="programmeYear"
                            component={RenderSelectField}
                            label="Select value"
                            errorRes={errors.programmeId}
                            disabled={!formDept || programmes.length == 0}
                            options={[
                                ...(formProgramme
                                    ? [
                                          {
                                              value: null,
                                              label: " ",
                                          },
                                      ]
                                    : []),
                                ...programmes.map((p, i) => ({
                                    value: `${p.id}|${p.academicYear}`,
                                    label: `${p.name} - ${p.academicYear}`,
                                })),
                            ]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.labelBig}>Select year group</div>
                        <Field
                            name="yearGroup"
                            component={RenderSelectField}
                            label="Select value"
                            errorRes={errors.yearGroup}
                            disabled={!formDept || yearGroups.length == 0}
                            options={[
                                ...(formYearGroup
                                    ? [
                                          {
                                              value: null,
                                              label: " ",
                                          },
                                      ]
                                    : []),
                                ...yearGroups.map(yg => ({
                                    value: `${yg}`,
                                    label: `${yg}`,
                                })),
                            ]}
                        />
                    </div>
                </div>
                <div css={globalsCss.helpers.mt20}>
                    <h4>Time period</h4>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.labelBig}>Select year, term ... </div>
                        <Field
                            name="schoolyear"
                            component={RenderSelectField}
                            label="Select value"
                            errorRes={errors.schoolyear}
                            options={processSchoolYears(schoolYears)}
                            onChange={this.handleChangeSchoolYear}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <div css={formsCss.labelBig}>...or time period</div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="from"
                            errorRes={errors.from}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                            css={formsCss.field}
                            onChange={this.clearSchoolYearSelect}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="to"
                            errorRes={errors.to}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                            css={formsCss.field}
                        />
                    </div>
                </div>

                {error && (
                    <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>{error}</div>
                )}

                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        Generate report
                    </Button>
                </div>
            </form>
        );
    }
}

const FORM_NAME = "OfficeHoursReportStudentHistory";

const validate = values => {
    const requiredFields = {
        departmentId: "Select department",
        // programmeYear: "Select programme",
        academicYear: "Select academic year",
        // yearGroup: "Select year group",
        from: "Required",
        to: "Required",
    };

    if (!!values.from && !!values.to && values.from > values.to)
        return { to: "End date must be greater than start date" };

    return validateRequiredFields(requiredFields, values);
};

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
})(withStyles(formsCss)(StudentHistoryForm));

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = ({
    form,
    OfficeHoursReport: { departments, selectedDepartment, programmes, yearGroups, errorRes },
    OfficeHoursAppointments: { schoolYears },
}) => ({
    formSchoolyear: selector({ form }, "schoolyear"),
    formDept: selector({ form }, "departmentId"),
    formProgramme: selector({ form }, "programmeYear"),
    formYearGroup: selector({ form }, "yearGroup"),
    schoolYears,
    departments,
    selectedDepartment,
    programmes,
    yearGroups,
    errorRes,
});

const dispatchToProps = {
    updateForm: (key, newValue) => change(FORM_NAME, key, newValue),
    getOfficeReportDepartmentList: actionCreators.getOfficeReportDepartmentList.create,
    getOfficeReportProgrammeList: actionCreators.getOfficeReportProgrammeList.create,
    getOfficeReportYearGroupList: actionCreators.getOfficeReportYearGroupList.create,
    gettOfficeHoursSchoolYearsAndTerms:
        appointmentsActionCreators.gettOfficeHoursSchoolYearsAndTerms.create,
    clearErrorResponse: actionCreators.clearErrorResponse.create,
    createOfficeStudentByProgrammeReport:
        actionCreators.createOfficeStudentByProgrammeReport.create,
};

export default connect(mapStateToProps, dispatchToProps)(form);
