import moment from "moment-timezone";
import {
    REPEATING_DAILY,
    REPEATING_WEEKLY,
    REPEAT_N_TIMES,
    REPEAT_UNTIL,
    TYPE_REPEATING,
} from "../types";

export const composeAvailabilityRequest = (values, isGenericAvailability = false) => {
    const output = {};

    const date = moment(values.date);
    const startAt = moment(values.startAt);
    const endAt = moment(values.endAt);

    // title
    output.title = values.title;

    // start
    output.start = date
        .set({
            hour: startAt.get("hour"),
            minute: startAt.get("minute"),
            second: 0,
            millisecond: 0,
        })
        .toDate();

    // end
    output.end = date
        .set({
            hour: endAt.get("hour"),
            minute: endAt.get("minute"),
            second: 0,
            millisecond: 0,
        })
        .toDate();

    // repeatingRule
    if (values.repeating === TYPE_REPEATING) {
        output.repeatingRule = {};

        output.repeatingRule.type = values.repeatingType;
        output.repeatingRule.repeatEvery =
            values.repeatingType === REPEATING_DAILY
                ? values.daily.every
                : values.repeatingType === REPEATING_WEEKLY
                ? values.weekly.every
                : values.monthly.every;

        let weekdays = [];
        if (values.repeatingType === REPEATING_WEEKLY) {
            if (values[REPEATING_WEEKLY].repeatMon === true) weekdays.push(1);
            if (values[REPEATING_WEEKLY].repeatTue === true) weekdays.push(2);
            if (values[REPEATING_WEEKLY].repeatWed === true) weekdays.push(3);
            if (values[REPEATING_WEEKLY].repeatThu === true) weekdays.push(4);
            if (values[REPEATING_WEEKLY].repeatFri === true) weekdays.push(5);
            if (values[REPEATING_WEEKLY].repeatSat === true) weekdays.push(6);
            if (values[REPEATING_WEEKLY].repeatSun === true) weekdays.push(7);
        }

        output.repeatingRule.weekdays = weekdays;
        output.repeatingRule.finishType = values[values.repeatingType].config;

        output.repeatingRule.repeatTimes =
            values[values.repeatingType].config === REPEAT_N_TIMES
                ? values[values.repeatingType].ntimes
                : 0;

        output.repeatingRule.repeatDate =
            values[values.repeatingType].config === REPEAT_UNTIL
                ? values[values.repeatingType].until
                : null;
    } else {
        output.repeatingRule = null;
    }

    // --> settings
    let settings = {};
    settings.location = values.location ? values.location : null;
    settings.appointmentForm = values.appointmentForm ? values.appointmentForm : null;
    settings.note = values.note ? values.note : null;
    if (!isGenericAvailability) settings.email = values.email ? values.email : null;
    settings.maxNumberOfAttendees = values.maxNumberOfAttendees
        ? Number(values.maxNumberOfAttendees)
        : null;
    settings.maxAppointmentsPerSession = values.maxAppointmentsPerSession
        ? Number(values.maxAppointmentsPerSession)
        : null;
    settings.duration = values.duration ? values.duration : null;
    settings.earliestBooking = values.earliestBooking;
    settings.latestBooking = values.latestBooking;
    settings.allowFileUpload = values.allowFileUpload ? true : false;
    settings.requireBookingNote = values.requireBookingNote ? true : false;

    if (!isGenericAvailability) {
        settings.addSessionPlaceholders = values.addSessionPlaceholders;
        settings.addBookedAppointments = values.addBookedAppointments;
        settings.receiveSummaryEmailAheadUpcomingAppointments = !values.receiveSummaryEmailAheadUpcomingAppointments;
    }

    if (!isGenericAvailability) {
        if (!values.receiveSummaryEmailAheadUpcomingAppointments) {
            settings.atEightOnSessionDay = !!values.atEightOnSessionDay;
            settings.timeInAdvance =
                !!values.timeInAdvance && !!values.timeInAdvanceValue
                    ? values.timeInAdvanceValue
                    : null;
            settings.summaryAtTime =
                !!values.summaryAtTime && !!values.summaryAtTimeValue
                    ? values.summaryAtTimeValue
                    : null;
            settings.atLatestBookingTime = !!values.atLatestBookingTime;
        } else {
            settings.atEightOnSessionDay = false;
            settings.timeInAdvance = null;
            settings.summaryAtTime = null;
            settings.atLatestBookingTime = false;
        }
    }

    settings.meetingUrl = values.meetingUrl ? values.meetingUrl : null;
    if (!isGenericAvailability)
        settings.integrateWithZoom = values.integrateWithZoom ? values.integrateWithZoom : false;

    output.mode = values.mode;

    output.settings = settings;

    return output;
};

export const isSafeUpdate = (oldValues, newValues) => {
    const fieldNames = [
        "daily",
        "weekly",
        "monthly",
        "date",
        "startAt",
        "endAt",
        "maxAppointmentsPerSession",
        "maxNumberOfAttendees",
        "repeating",
        "duration",
        "integrateWithZoom",
        "meetingUrl",
    ];

    for (let fieldName of fieldNames) {
        if (newValues[fieldName] !== oldValues[fieldName]) return false;
    }

    return true;
};
