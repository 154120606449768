import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import formsCss from "../../styles/forms.css";

const renderTextField = ({
    input,
    label,
    meta: { touched, error },
    errorRes,
    classes,
    placeholder,
    ...custom
}) => (
    <TextField
        error={!!touched && (!!error || !!errorRes)}
        placeholder={placeholder ? placeholder : label}
        label={label}
        helperText={touched && (error || errorRes)}
        fullWidth
        margin="normal"
        color="primary"
        InputProps={{
            classes: {
                root: classes.inputRoot,
                error: classes.inputError,
            },
        }}
        // InputLabelProps={{
        //     classes: {
        //         root: classes.labelRoot,
        //         focused: classes.labelFocused,
        //         error: classes.labelError,
        //     },
        // }}
        {...input}
        {...custom}
    />
);

export const RenderTextField = withStyles(formsCss)(renderTextField);
