// eslint-disable-next-line no-unused-vars
import React from "react";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import toolbarCss from "../../../common/styles/toolbar.css";
// import formsCss from "../../../common/styles/forms.css";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, InputBase, MenuItem, Toolbar, Typography, TextField } from "@material-ui/core";
import * as config from "../Groups/values";

export default withStyles(toolbarCss)(
    ({ onFilterChangeHandler, filter, type, classes, context }) => (
        <AppBar
            position="static"
            classes={{
                root: classes.appBarRoot,
            }}
            elevation={0}
        >
            <Toolbar
                classes={{
                    root: classes.toolbarRoot,
                }}
            >
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>
                                Filter by keyword or ID:
                            </span>
                            <span css={toolbarCss.labelShowOnMobile}>Filter:</span>
                        </span>
                    </Typography>
                    <InputBase
                        placeholder="..."
                        value={filter || ""}
                        onChange={onFilterChangeHandler("filter")}
                        fullWidth
                        autoComplete="off"
                    />
                </div>
                {context === "group" && (
                    <>
                        <div css={toolbarCss.toolbarSeparator} />
                        <div css={toolbarCss.toolbarGroup}>
                            <Typography>
                                <span css={toolbarCss.toolbarTitle}>
                                    <span css={toolbarCss.labelShowOnDesktop}>
                                        Filter by status:
                                    </span>
                                    <span css={toolbarCss.labelShowOnMobile}>Status:</span>
                                </span>
                            </Typography>
                            <TextField
                                select
                                margin="none"
                                color="primary"
                                fullWidth={context === "page"}
                                onChange={onFilterChangeHandler("type")}
                                value={type}
                                InputProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                        error: classes.inputError,
                                    },
                                }}
                                SelectProps={{
                                    displayEmpty: true,
                                    MenuProps: {
                                        MenuListProps: {
                                            disablePadding: true,
                                            dense: true,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="">All</MenuItem>

                                <MenuItem value={config.USER_TYPE_OWNER}>Owners</MenuItem>
                                <MenuItem value={config.USER_TYPE_MEMBER}>Members</MenuItem>
                                <MenuItem value={config.USER_GROUP_STATUS_JOINREQUEST}>
                                    Pending (requested access)
                                </MenuItem>
                                <MenuItem value={config.USER_GROUP_STATUS_INVITATION}>
                                    Pending (invited)
                                </MenuItem>
                            </TextField>
                        </div>
                    </>
                )}
            </Toolbar>
        </AppBar>
    )
);
