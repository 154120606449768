import ActionCreator from "../../actionCreator";

export default {
    getListEvent: ActionCreator("CHANNEL_GET_LIST_EVENT"),
    loadMoreEvents: ActionCreator("CHANNEL_LOAD_MORE_EVENTS"),
    getOneEvent: ActionCreator("CHANNEL_GET_ONE_EVENT"),
    postEvent: ActionCreator("CHANNEL_POST_EVENT"),
    putEvent: ActionCreator("CHANNEL_PUT_EVENT"),
    createListEvent: ActionCreator("CHANNEL_CREATE_LIST_EVENT"),
    updateListEvent: ActionCreator("CHANNEL_UPDATE_LIST_EVENT"),
    updateOneEvent: ActionCreator("CHANNEL_UPDATE_ONE_EVENT"),
    errorResponse: ActionCreator("CHANNEL_ERROR_RESPONSE_EVENT"),
    setCurrentFormType: ActionCreator("CHANNEL_SET_CURRENT_FORM_TYPE_EVENT"),
    setDateFieldStatus: ActionCreator("CHANNEL_SET_DATE_FIELD_STATUS_EVENT"),
    removeEvent: ActionCreator("CHANNEL_REMOVE_EVENT"),
    clearPageEvent: ActionCreator("CHANNEL_CLEAR_EVENT"),
    clearErrors: ActionCreator("CLEAR_ERROR_CHANNEL_EVENT"),
    clearQueryParams: ActionCreator("CLEAR_QUERY_PARAMS_CHANNEL_EVENT"),
    archiveEvent: ActionCreator("CHANNEL_ARCHIVE_EVENT"),
    updateArchivedEvent: ActionCreator("CHANNEL_UPDATE_ARCHIVED_EVENT"),
};
