import { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./Form/";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import actionCreators from "../../../store/Channels/Groups/actionCreators";
import formsCss from "../../../common/styles/forms.css";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";

class EditGroupChannelContainer extends Component {
    componentDidMount() {
        const { setTitle, getOneGroup, match } = this.props;
        setTitle("Channels \u203A Groups");
        getOneGroup({ id: match.params.id });
    }

    componentWillUnmount() {
        const { clearTitle, clearGroup } = this.props;
        clearGroup();
        clearTitle();
    }

    onSubmit = values => {
        this.props.putGroup(values);
    };

    render() {
        const {
            initialValues,
            history: { push },
            owners,
            audiences,
            errorRes: { error, errors },
            updateOwners,
            getOwners,
            getAudiences,
        } = this.props;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.EDIT;

        return (
            <div>
                <div css={formsCss.genericError}>{error}</div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Edit Group</h2>
                        {initialValues.id && (
                            <Form
                                {...{
                                    initialValues,
                                    onSubmit,
                                    currentFormType,
                                    push,
                                    owners,
                                    errors,
                                    getOwners,
                                    updateOwners,
                                    audiences,
                                    getAudiences,
                                }}
                            />
                        )}
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneGroup: actionCreators.getOneGroup.create,
    getOwners: AutoCompleteActionCreators.getAllUsers.create,
    getAudiences: AutoCompleteActionCreators.getAudiences.create,
    putGroup: actionCreators.putGroup.create,
    clearGroup: actionCreators.clearGroup.create,
    updateOwners: newValue => change("ChannelGroup", "owners", newValue), // change owners
};
const mapStateToProps = ({ ChannelGroup, AutoComplete }) => ({
    initialValues: ChannelGroup.group,
    errorRes: ChannelGroup.errorRes,
    currentFormType: ChannelGroup.currentFormType,
    audiences: AutoComplete.audiences,
    owners: AutoComplete.users.map(owner =>
        Object.assign({ fullName: `${owner.firstName} ${owner.lastName}` }, owner)
    ),
});
export default connect(mapStateToProps, dispatchToProps)(EditGroupChannelContainer);
