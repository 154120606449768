/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Tabs, Tab } from "@material-ui/core";
import tabsCSS from "../styles/tabs.css";
import React, { useState } from "react";

const TabsComponent = ({ activeIndex, tabs, fns }) => {
    const [active, setActive] = useState(activeIndex);

    return (
        <div css={tabsCSS.tabs}>
            <Tabs value={activeIndex} scrollButtons="off" textColor="secondary" variant="fullWidth">
                {tabs.map(({ label, fnLabel, param }, index) => (
                    <Tab
                        key={index}
                        value={index}
                        {...{ label }}
                        onClick={() => {
                            if (active !== index) {
                                setActive(index);
                                fns[fnLabel](param);
                            }
                        }}
                    />
                ))}
            </Tabs>
        </div>
    );
};

export default TabsComponent;
