import { colors, spacing } from "../../UIGlobals";
/** @jsx jsx */
import { jsx } from "@emotion/core";

export const RenderTextField = ({
    input,
    label,
    meta: { touched, error },
    errorRes,
    ...custom
}) => (
    <div>
        <input {...input} placeholder={label} {...custom} />
        {touched && (error || errorRes) && (
            <span
                css={{
                    display: "block",
                    color: colors.red,
                    fontSize: 14,
                    paddingTop: spacing.space0,
                }}
            >
                {error || errorRes}
            </span>
        )}
    </div>
);
