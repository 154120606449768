import { of } from "rxjs";
import { mergeMap, catchError, mapTo, switchMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";

const sideLinksStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getSidelinks.type,
            actionCreators.deleteSidelink.type,
            actionCreators.addSideLinkSection.type,
            actionCreators.addSideLinkItem.type,
            actionCreators.editSideLinkSection.type,
            actionCreators.editSideLinkItem.type,
            actionCreators.moveUpSideLink.type,
            actionCreators.moveDownSideLink.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const sideLinksClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updateSidelinks.type, actionCreators.errorResponse.type),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getSidelinksEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSidelinks.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl(`manager/side-links/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateSidelinks.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const editSideLinkSectionEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.editSideLinkSection.type),
        mergeMap(action =>
            ajax
                .put(
                    ajax.apiUrl(`manager/side-links/section/${action.payload.id}/`),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) => of(actionCreators.updateSidelinks.create(response))),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const editSideLinkItemEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.editSideLinkItem.type),
        mergeMap(action =>
            ajax
                .put(ajax.apiUrl(`manager/side-links/link/${action.payload.id}/`), action.payload)
                .pipe(
                    mergeMap(({ response }) => of(actionCreators.updateSidelinks.create(response))),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const addSideLinkSectionEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.addSideLinkSection.type),
        mergeMap(action =>
            ajax
                .post(
                    ajax.apiUrl(
                        action.payload.parentId
                            ? `manager/side-links/section-child/${action.payload.parentId}/`
                            : `manager/side-links/section/`
                    ),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) => of(actionCreators.updateSidelinks.create(response))),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const addSideLinkItemEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.addSideLinkItem.type),
        mergeMap(action =>
            ajax
                .post(
                    ajax.apiUrl(`manager/side-links/link-child/${action.payload.parentId}/`),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) => of(actionCreators.updateSidelinks.create(response))),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const deleteSidelinkEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteSidelink.type),
        mergeMap(({ payload }) =>
            ajax.remove(ajax.apiUrl(`manager/side-links/${payload.id}/`), {}).pipe(
                mergeMap(({ response }) => of(actionCreators.updateSidelinks.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const moveUpSideLinkEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.moveUpSideLink.type),
        mergeMap(action =>
            ajax.put(ajax.apiUrl(`manager/side-links/${action.payload.id}/move-up/`), {}).pipe(
                mergeMap(({ response }) => of(actionCreators.updateSidelinks.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const moveDownSideLinkEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.moveDownSideLink.type),
        mergeMap(action =>
            ajax.put(ajax.apiUrl(`manager/side-links/${action.payload.id}/move-down/`), {}).pipe(
                mergeMap(({ response }) => of(actionCreators.updateSidelinks.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    sideLinksStartLoadingEpic,
    sideLinksClearLoadingEpic,
    getSidelinksEpic,
    editSideLinkSectionEpic,
    editSideLinkItemEpic,
    addSideLinkSectionEpic,
    addSideLinkItemEpic,
    deleteSidelinkEpic,
    moveUpSideLinkEpic,
    moveDownSideLinkEpic
);
