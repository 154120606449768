// eslint-disable-next-line no-unused-vars
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Appointments/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { TableComponent } from "../../../common/components/OfficeHours";
import { Dialog, Button, Paper, Typography } from "@material-ui/core";
import { prepareFilterQuery } from "../../../common/services/utils";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import tableCss from "../../../common/styles/table.css";
import { MainTitle } from "../../../common/components/MainTitle";
import { SubTitle } from "../../../common/components/SubTitle";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import { bookingStatusCallback } from "../../../common/services/tableCallbacks";
import formsCss from "../../../common/styles/forms.css";
import { AutoRenewIcon, PostIcon } from "../../../common/components/icons";

import RowDialogField from "../../../common/components/RowDialogField";
import globalsCss from "../../../common/styles/globals.css";
import CommentForm from "./CommentForm";
import { GetFormattedDate, GetFormattedTime } from "../../../common/components/Time";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            date: "Date",
            time: "Time",
            status: "Status",
            by: "By",
            message: "Note / File",
        },
    },
];

const callbacks = {
    status: bookingStatusCallback,
    // eslint-disable-next-line react/display-name
    message: item =>
        item.message || item.attachment ? (
            <RowDialogField
                iconImage={<PostIcon />}
                dialogContent={
                    <div>
                        {item.message && (
                            <div>
                                <span css={formsCss.labelNoMargin}>Note:</span> {item.message}
                            </div>
                        )}
                        {item.attachment && (
                            <div css={item.message && globalsCss.helpers.mt15}>
                                <span css={formsCss.labelNoMargin}>Attachment:</span>
                                <div css={globalsCss.helpers.mt5}>
                                    <Button
                                        href={item.attachment}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color="secondary"
                                        variant="contained"
                                        size="small"
                                    >
                                        Download
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                }
                maxWidth="xs"
                fullWidth
            />
        ) : (
            ""
        ),
};

const columns = ["id", "status", "date", "time", "by", "message"];

class OfficeHoursAppointmentsDetailsContainer extends Component {
    state = {
        attendanceCommentDialog: false,
        meetingNotesDialog: false,
        meetingNotes: "",
    };

    componentDidMount() {
        const {
            setTitle,
            getOneOfficeHoursAppointmentDetails,
            getListOfficeHoursAppointmentHistory,
            match,
        } = this.props;
        setTitle("Bookings \u203A View Bookings");
        getOneOfficeHoursAppointmentDetails(match.params.id);
        getListOfficeHoursAppointmentHistory({
            slotId: match.params.id,
            query: prepareFilterQuery({ page: 1 }),
        });
    }

    onLoadMoreClickHandler = page => () => {
        const { match, loadMoreOfficeHoursAppointmentHistory } = this.props;
        loadMoreOfficeHoursAppointmentHistory({
            slotId: match.params.id,
            query: prepareFilterQuery({
                page,
            }),
        });
    };

    componentWillUnmount() {
        const {
            clearTitle,
            clearOneOfficeHoursAppointmentDetails,
            clearOfficeHoursAppointmentHistory,
        } = this.props;
        clearTitle();
        clearOneOfficeHoursAppointmentDetails();
        clearOfficeHoursAppointmentHistory();
    }

    toggleStudentAttendance = () => {
        const {
            markOfficeHoursAppointmentAsStudentAttended,
            markOfficeHoursAppointmentAsStudentNotAttended,
            appointment,
            match,
        } = this.props;

        if (appointment.studentAttended === true)
            markOfficeHoursAppointmentAsStudentNotAttended(match.params.id);
        else markOfficeHoursAppointmentAsStudentAttended(match.params.id);
    };

    toggleStudentAttendanceComment = () => {
        this.setState({ attendanceCommentDialog: !this.state.attendanceCommentDialog });
    };

    onAttendanceCommentFormSubmit = values => {
        const { putOfficeHoursAppointmentAttendanceComment, match } = this.props;
        putOfficeHoursAppointmentAttendanceComment({
            slotId: match.params.id,
            data: values,
        });
        this.toggleStudentAttendanceComment();
    };

    urlify(text) {
        if (!text) return;

        const urlRegex = /(https?:\/\/[^\s]+)/g;
        let i = 0;
        return text.split("\n").map((item, key) => {
            return (
                <span key={key}>
                    {item.split(urlRegex).map(part => {
                        if (part.match(urlRegex)) {
                            i = i + 1;
                            return (
                                <a href={part} key={i} target="_blank" rel="noopener noreferrer">
                                    {part}
                                </a>
                            );
                        }
                        return part;
                    })}
                    <br />
                </span>
            );
        });
    }

    render() {
        const {
            appointment,
            slotHistory,
            history: { push },
            nextPage,
            count,
            errorRes: { error, errors },
            markOfficeHoursAppointmentAsStudentNotAttended,
            match,
        } = this.props;
        const {
            onLoadMoreClickHandler,
            toggleStudentAttendance,
            onAttendanceCommentFormSubmit,
            toggleStudentAttendanceComment,
        } = this;

        const earliestBooking = [
            { value: null, label: "" },
            { value: 0, label: "No limit" },
            { value: 24 * 60, label: "1 day in advance" },
            { value: 48 * 60, label: "2 days in advance" },
            { value: 72 * 60, label: "3 days in advance" },
            { value: 96 * 60, label: "4 days in advance" },
            { value: 120 * 60, label: "5 days in advance" },
            { value: 144 * 60, label: "6 days in advance" },
            { value: 168 * 60, label: "7 days in advance" },
            { value: 192 * 60, label: "8 days in advance" },
            { value: 216 * 60, label: "9 days in advance" },
            { value: 240 * 60, label: "10 days in advance" },
            { value: 264 * 60, label: "11 days in advance" },
            { value: 288 * 60, label: "12 days in advance" },
            { value: 312 * 60, label: "13 days in advance" },
            { value: 336 * 60, label: "14 days in advance" },
        ];

        const latestBooking = [
            { value: null, label: "" },
            { value: 0, label: "No limit" },
            { value: 30, label: "30 minutes in advance" },
            { value: 1 * 60, label: "1 hour in advance" },
            { value: 2 * 60, label: "2 hours in advance" },
            { value: 3 * 60, label: "3 hours in advance" },
            { value: 4 * 60, label: "4 hours in advance" },
            { value: 5 * 60, label: "5 hours in advance" },
            { value: 6 * 60, label: "6 hours in advance" },
            { value: 12 * 60, label: "12 hours in advance" },
            { value: 24 * 60, label: "24 hours in advance" },
            { value: 48 * 60, label: "48 hours in advance" },
            { value: 72 * 60, label: "3 days in advance" },
            { value: 96 * 60, label: "4 days in advance" },
            { value: 120 * 60, label: "5 days in advance" },
            { value: 144 * 60, label: "6 days in advance" },
            { value: 168 * 60, label: "7 days in advance" },
        ];

        const session = appointment && appointment.session ? appointment.session : null;

        return appointment ? (
            <div>
                <Fragment>
                    <MainTitle title={`${appointment.academicName}`} type={"[Time Slot Details]"} />
                    <Paper elevation={1}>
                        <div css={globalsCss.inner}>
                            {appointment && appointment.apiUser && (
                                <Fragment>
                                    <div css={globalsCss.userCard}>
                                        {!!appointment.apiUser.photoUrl && (
                                            <div
                                                css={{
                                                    backgroundImage: `url(
                                                            ${appointment.apiUser.photoUrl}
                                                        )`,
                                                    ...globalsCss.userCardAvatar,
                                                }}
                                            />
                                        )}
                                        <div css={globalsCss.userCardBody}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Appointment with:{" "}
                                                <strong>
                                                    {appointment.apiUser.firstName}{" "}
                                                    {appointment.apiUser.lastName}
                                                </strong>
                                            </Typography>
                                            <div className="row">
                                                <div
                                                    className="col-sm-6 col-xs-12"
                                                    css={globalsCss.helpers.mt15}
                                                >
                                                    <Typography component="p" variant="subtitle1">
                                                        <strong>Date: </strong>{" "}
                                                        {GetFormattedDate(appointment.start)}
                                                    </Typography>
                                                    <Typography component="p" variant="subtitle1">
                                                        <strong>Time:</strong>{" "}
                                                        {`${GetFormattedDate(
                                                            appointment.start,
                                                            "h.mma"
                                                        )} - ${GetFormattedDate(
                                                            appointment.end,
                                                            "h.mma"
                                                        )}`}
                                                    </Typography>
                                                </div>
                                                <div
                                                    className="col-sm-6 col-xs-12"
                                                    css={globalsCss.helpers.mt15}
                                                >
                                                    {appointment.session &&
                                                        appointment.session.zoomStartUrl && (
                                                            <Button
                                                                href={
                                                                    appointment.session.zoomStartUrl
                                                                }
                                                                size="small"
                                                                variant="contained"
                                                                color="secondary"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Join Zoom Meeting
                                                            </Button>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div
                                                    className="col-sm-12 col-xs-12"
                                                    css={globalsCss.helpers.mt15}
                                                >
                                                    {!!appointment.message && (
                                                        <Typography
                                                            component="p"
                                                            variant="subtitle1"
                                                        >
                                                            <strong>
                                                                Student&apos;s Note / File:{" "}
                                                            </strong>{" "}
                                                            {appointment.message}
                                                        </Typography>
                                                    )}
                                                    {!!appointment.apiUser.email && (
                                                        <Typography
                                                            component="p"
                                                            variant="subtitle1"
                                                        >
                                                            <strong>E-mail (private): </strong>{" "}
                                                            {appointment.apiUser.email}
                                                        </Typography>
                                                    )}
                                                    {!!appointment.apiUser.lseEmail && (
                                                        <Typography
                                                            component="p"
                                                            variant="subtitle1"
                                                        >
                                                            <strong>E-mail (LSE): </strong>{" "}
                                                            {appointment.apiUser.lseEmail}
                                                        </Typography>
                                                    )}
                                                    {!!appointment.apiUser.playRef && (
                                                        <Typography
                                                            component="p"
                                                            variant="subtitle1"
                                                        >
                                                            <strong>playRef: </strong>{" "}
                                                            {appointment.apiUser.playRef}
                                                        </Typography>
                                                    )}
                                                    {!!appointment.sourceFileName && (
                                                        <div css={globalsCss.helpers.mt15}>
                                                            <Button
                                                                href={appointment.sourceFileName}
                                                                size="small"
                                                                variant="contained"
                                                                color="secondary"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Download attachment
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </Fragment>
                            )}
                            {session && (
                                <Fragment>
                                    <SubTitle
                                        title="Availability details"
                                        marginTop={appointment && appointment.apiUser}
                                        noMarginBottom
                                    />
                                    <div className="row">
                                        <div
                                            className="col-sm-4 col-xs-12"
                                            css={globalsCss.helpers.mt15}
                                        >
                                            <strong>Title:</strong>
                                            <div>{session.availabilityRule.title}</div>
                                        </div>
                                        {session.availabilityRule.settings.location && (
                                            <div
                                                className="col-sm-4 col-xs-12"
                                                css={globalsCss.helpers.mt15}
                                            >
                                                <strong>Location:</strong>
                                                <div>
                                                    {session.availabilityRule.settings.location}
                                                </div>
                                            </div>
                                        )}
                                        {session.availabilityRule.settings.appointmentForm && (
                                            <div
                                                className="col-sm-4 col-xs-12"
                                                css={globalsCss.helpers.mt15}
                                            >
                                                <strong>Appointment type:</strong>
                                                <div>
                                                    {
                                                        session.availabilityRule.settings
                                                            .appointmentForm
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {session.availabilityRule.settings.meetingUrl && (
                                            <div
                                                className="col-sm-4 col-xs-12"
                                                css={globalsCss.helpers.mt15}
                                            >
                                                <strong>Online meeting details:</strong>
                                                <div style={{ marginLeft: -8 }}>
                                                    <Button
                                                        onClick={() =>
                                                            this.setState({
                                                                meetingNotesDialog: true,
                                                                meetingNotes:
                                                                    session.availabilityRule
                                                                        .settings.meetingUrl,
                                                            })
                                                        }
                                                    >
                                                        View Details
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                        {session.availabilityRule.settings.maxNumberOfAttendees &&
                                            session.availabilityRule.settings.maxNumberOfAttendees >
                                                1 && (
                                                <div
                                                    className="col-sm-4 col-xs-12"
                                                    css={globalsCss.helpers.mt15}
                                                >
                                                    <strong>
                                                        Maximum number of attendees per slot
                                                    </strong>
                                                    <div>
                                                        {
                                                            session.availabilityRule.settings
                                                                .maxNumberOfAttendees
                                                        }
                                                    </div>
                                                </div>
                                            )}

                                        {session.availabilityRule.settings
                                            .maxAppointmentsPerSession &&
                                            session.availabilityRule.settings
                                                .maxAppointmentsPerSession > 1 && (
                                                <div
                                                    className="col-sm-4 col-xs-12"
                                                    css={globalsCss.helpers.mt15}
                                                >
                                                    <strong>
                                                        Maximum number of bookings per student
                                                    </strong>
                                                    <div>
                                                        {
                                                            session.availabilityRule.settings
                                                                .maxAppointmentsPerSession
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        {!!session.availabilityRule.settings.earliestBooking && (
                                            <div
                                                className="col-sm-4 col-xs-12"
                                                css={globalsCss.helpers.mt15}
                                            >
                                                <strong>Earliest booking:</strong>
                                                <div>
                                                    {
                                                        earliestBooking.find(
                                                            item =>
                                                                item.value ===
                                                                session.availabilityRule.settings
                                                                    .earliestBooking
                                                        ).label
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {!!session.availabilityRule.settings.latestBooking && (
                                            <div
                                                className="col-sm-4 col-xs-12"
                                                css={globalsCss.helpers.mt15}
                                            >
                                                <strong>Latest booking:</strong>
                                                <div>
                                                    {
                                                        latestBooking.find(
                                                            item =>
                                                                item.value ===
                                                                session.availabilityRule.settings
                                                                    .latestBooking
                                                        ).label
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {session.availabilityRule.settings.note && (
                                            <div
                                                className="col-sm-4 col-xs-12"
                                                css={globalsCss.helpers.mt15}
                                            >
                                                <strong>Note for students:</strong>
                                                <div>{session.availabilityRule.settings.note}</div>
                                            </div>
                                        )}
                                    </div>
                                    <hr />
                                </Fragment>
                            )}
                            <Fragment>
                                <SubTitle title="Attendance" marginTop={true} noMarginBottom />
                                <div className="row">
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <strong>Student attended?</strong>
                                        <div>
                                            {appointment.studentAttended === null
                                                ? "n/a"
                                                : appointment.studentAttended
                                                ? "Yes"
                                                : "No"}
                                        </div>
                                        <div css={globalsCss.actionsAttendance}>
                                            <Button onClick={toggleStudentAttendance}>
                                                {appointment.studentAttended === true
                                                    ? "Mark as NOT attended"
                                                    : "Mark as attended"}
                                            </Button>

                                            {appointment.studentAttended === null && (
                                                <Button
                                                    onClick={() =>
                                                        markOfficeHoursAppointmentAsStudentNotAttended(
                                                            match.params.id
                                                        )
                                                    }
                                                >
                                                    Mark as NOT attended
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <strong>Comment</strong>
                                        <div>
                                            {appointment.comment ? appointment.comment : "n/a"}
                                        </div>
                                        <div css={globalsCss.actionsAttendance}>
                                            <Button onClick={toggleStudentAttendanceComment}>
                                                {!appointment.comment
                                                    ? "Add attendance comment"
                                                    : "Amend comment"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                    </Paper>

                    {session && slotHistory.length > 0 && (
                        <div css={globalsCss.helpers.mt30}>
                            <SubTitle
                                title="Time slot history"
                                css={globalsCss.subTitleMarginMobile}
                            />

                            <Paper elevation={1}>
                                <TableComponent
                                    items={slotHistory}
                                    fns={{ push }}
                                    decorators={decorators}
                                    callbacks={callbacks}
                                    columns={columns}
                                />
                            </Paper>
                            {nextPage > 0 && (
                                <div css={tableCss.loadMore}>
                                    <Button
                                        onClick={onLoadMoreClickHandler(nextPage)}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        <AutoRenewIcon css={formsCss.btnIcon} />
                                        Load more
                                    </Button>
                                    <span css={tableCss.loadMoreLabel}>
                                        <small>
                                            (showing {slotHistory.length} out of {count})
                                        </small>
                                    </span>
                                </div>
                            )}
                            <div css={tableCss.loadMore}>
                                <Button
                                    onClick={() => this.props.history.goBack()}
                                    css={formsCss.btnBack}
                                >
                                    Back to the list
                                </Button>
                            </div>
                        </div>
                    )}
                </Fragment>

                <Dialog
                    open={this.state.attendanceCommentDialog}
                    onClose={toggleStudentAttendanceComment}
                    maxWidth="xs"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>Attendance comment</h2>
                        {error && <div css={formsCss.genericError}>{error}</div>}
                        <CommentForm
                            {...{
                                onSubmit: onAttendanceCommentFormSubmit,
                                push,
                                errors,
                                fieldLabel: "Comment",
                                buttonLabel: appointment.comment ? "Amend comment" : "Add comment",
                                initialValues: {
                                    comment: appointment.comment,
                                },
                                simpleLayout: false,
                            }}
                        />
                    </div>
                </Dialog>

                <Dialog
                    open={this.state.meetingNotesDialog}
                    onClose={() => this.setState({ meetingNotesDialog: false, meetingNotes: "" })}
                    maxWidth="xs"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>Online Meeting Notes</h2>
                        {this.urlify(this.state.meetingNotes)}
                    </div>
                </Dialog>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneOfficeHoursAppointmentDetails: actionCreators.getOneOfficeHoursAppointmentDetails.create,
    clearOneOfficeHoursAppointmentDetails:
        actionCreators.clearOneOfficeHoursAppointmentDetails.create,
    getListOfficeHoursAppointmentHistory:
        actionCreators.getListOfficeHoursAppointmentHistory.create,
    loadMoreOfficeHoursAppointmentHistory:
        actionCreators.loadMoreOfficeHoursAppointmentHistory.create,
    clearOfficeHoursAppointmentHistory: actionCreators.clearOfficeHoursAppointmentHistory.create,
    markOfficeHoursAppointmentAsStudentAttended:
        actionCreators.markOfficeHoursAppointmentAsStudentAttended.create,
    markOfficeHoursAppointmentAsStudentNotAttended:
        actionCreators.markOfficeHoursAppointmentAsStudentNotAttended.create,
    putOfficeHoursAppointmentAttendanceComment:
        actionCreators.putOfficeHoursAppointmentAttendanceComment.create,
};

const mapStateToProps = ({ OfficeHoursAppointments, Auth }) => ({
    slotHistory: OfficeHoursAppointments.slotHistory.data.map(item => {
        return {
            id: item.id,
            date: GetFormattedDate(item.date, "ddd D MMMM YYYY"),
            time: GetFormattedTime(item.date, "h.mma"),
            status: item.action,
            by: item.apiUser ? `${item.apiUser.firstName} ${item.apiUser.lastName}` : "",
            message: item.message ? item.message : null,
            attachment: item.sourceFileName,
        };
    }),
    count: OfficeHoursAppointments.slotHistory.count,
    nextPage: OfficeHoursAppointments.slotHistory.nextPage,
    appointment: OfficeHoursAppointments.appointment,
    errorRes: OfficeHoursAppointments.errorRes,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursAppointmentsDetailsContainer);
