import React, { Component } from "react";
import "./index.css";
import MainMenu from "../common/components/MainMenu";
import ServerErrorPage from "../common/components/ServerError";

import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import actionCreators from "../store/Auth/actionCreators";
import DashboardContainer from "./Dashboard";

import PageChannelListContainer from "./Channels/Pages/List";
import NewPageChannelContainer from "./Channels/Pages/New";
import EditPageChannelContainer from "./Channels/Pages/Edit";

import ChannelPostListContainer from "./Channels/Posts/List";
import ChannelPostPreviewContainer from "./Channels/Posts/Preview";
import ChannelPostEditContainer from "./Channels/Posts/Edit";
import ChannelPostNewContainer from "./Channels/Posts/New";

import ChannelEventListContainer from "./Channels/Events/List";
import ChannelEventPreviewContainer from "./Channels/Events/Preview";
import ChannelEditEventContainer from "./Channels/Events/Edit";
import ChannelNewEventContainer from "./Channels/Events/New";

import GroupChannelListContainer from "./Channels/Groups/List";
import NewGroupChannelContainer from "./Channels/Groups/New";
import EditGroupChannelContainer from "./Channels/Groups/Edit";

import GroupChannelMembersListContainer from "./Channels/Groups/Members/List";
import GroupChannelNewMembersContainer from "./Channels/Groups/Members/New";

import PageChannelFollowersListContainer from "./Channels/Pages/Followers/List";
import PageChannelNewFollowersContainer from "./Channels/Pages/Followers/New";

import ChannelPreviewContainer from "./Channels/Preview";

import CalendarSelectContainer from "./CalendarAndEvents/BrowseEvents/CalendarSelect";
import CalendarEventListContainer from "./CalendarAndEvents/BrowseEvents/List";
import CalendarEventDetailsContainer from "./CalendarAndEvents/BrowseEvents/EventDetails";
import AddCalendarEventContainer from "./CalendarAndEvents/BrowseEvents/AddEvent";

import LseStudentListContainer from "./UsersAndAudiences/LseStudents/List";
import EditStudentContainer from "./UsersAndAudiences/LseStudents/Edit";

import LseStaffListContainer from "./UsersAndAudiences/LseStaff/List";
import NewStaffContainer from "./UsersAndAudiences/LseStaff/New";
import EditStaffContainer from "./UsersAndAudiences/LseStaff/Edit";

import AudienceListContainer from "./UsersAndAudiences/Audiences/List";
import NewAudienceContainer from "./UsersAndAudiences/Audiences/New";
import EditAudienceContainer from "./UsersAndAudiences/Audiences/Edit";
import NewCustomAudienceContainer from "./UsersAndAudiences/Audiences/NewCustom";
import EditCustomAudienceContainer from "./UsersAndAudiences/Audiences/EditCustom";

import CampusMappingListContainer from "./CampusMapping/List";
import AddEditCampusMappingContainer from "./CampusMapping/AddEditCampusMapping";

import EditSupportPageContainer from "./SupportPage/Edit";
import HowToCategoryListContainer from "./HowTo/Category/List";
import HowToCategoryNewContainer from "./HowTo/Category/New";
import HowToCategoryEditContainer from "./HowTo/Category/Edit";
import HowToArticlesListContainer from "./HowTo/Category/Articles/List";
import HowToArticlesNewContainer from "./HowTo/Category/Articles/New";
import HowToArticlesEditContainer from "./HowTo/Category/Articles/Edit";
import HowToArticlesPreviewContainer from "./HowTo/Category/Articles/Preview";

import ListSchoolYearsContainer from "./Settings/SchoolYears/List";
import NewSchoolYearsContainer from "./Settings/SchoolYears/New";
import EditSchoolYearsContainer from "./Settings/SchoolYears/Edit";
import HolidaysSchoolYearsContainer from "./Settings/SchoolYears/Holidays";

import NotLoggedIn from "./Auth/NotLoggedIn";
import UserNotFound from "./Auth/UserNotFound";

import { isAdmin, isManager, isUser } from "../common/services/Auth";

import OfficeReportsRoutes from "./OfficeHours/routes/OfficeHoursRoutes";
import { ROUTE_OFFICE_HOURS } from "./OfficeHours/routes";

import NotificationBannersListContainer from "./CalendarAndEvents/NotificationBanners/containers/NotificationBannersListContainer";
import NotificationBannersNewContainer from "./CalendarAndEvents/NotificationBanners/containers/NotificationBannerNewContainer";
import NotificationBannersEditContainer from "./CalendarAndEvents/NotificationBanners/containers/NotificationBannerEditContainer";

import PersistentEventsListContainer from "./CalendarAndEvents/PersistentEvents/containers/PersistentEventsListContainer";
import PersistentEventsNewContainer from "./CalendarAndEvents/PersistentEvents/containers/PersistentEventsNewContainer";
import PersistentEventsEditContainer from "./CalendarAndEvents/PersistentEvents/containers/PersistentEventsEditContainer";

import { ROUTE_SIDELINKS } from "./SideLinks/routes";
import SideLinksRoutes from "./SideLinks/routes/SideLinksRoutes";

import ListStandardNotification from "./Notifications/containers/ListStandardNotification";
import AddEditStandardNotification from "./Notifications/containers/AddEditStandardNotification";
import PreviewStandardNotification from "./Notifications/containers/PreviewStandardNotification";
import ListTimetableNotification from "./Notifications/containers/ListTimetableNotification";
import AddEditTimetableNotification from "./Notifications/containers/AddEditTimetableNotification";
import PreviewTimetableNotification from "./Notifications/containers/PreviewTimetableNotification";
import ListEmailTemplates from "./Notifications/containers/ListEmailTemplates";
import AddEditEmailTemplates from "./Notifications/containers/AddEditEmailTemplates";

export class AppContent extends Component {
    componentDidMount() {
        const { checkStorage } = this.props;
        checkStorage();
    }

    render() {
        const { isLogged, permissionLevel, authError } = this.props;
        return (
            <div>
                {isLogged && permissionLevel ? (
                    <div>
                        <MainMenu />
                        <div className="container">
                            <Switch>
                                <Route
                                    exact
                                    path="/channels/pages"
                                    component={isUser(PageChannelListContainer)}
                                />
                                <Route
                                    exact
                                    path="/channels/pages/new"
                                    component={isUser(NewPageChannelContainer)}
                                />
                                <Route
                                    exact
                                    path="/channels/pages/:id"
                                    component={isUser(ChannelPreviewContainer)}
                                />
                                <Route
                                    exact
                                    path="/channels/pages/edit/:id"
                                    component={isUser(EditPageChannelContainer)}
                                />
                                <Route
                                    path="/channels/pages/:id/followers/new"
                                    component={isUser(PageChannelNewFollowersContainer)}
                                />
                                <Route
                                    path="/channels/pages/:id/followers"
                                    component={isUser(PageChannelFollowersListContainer)}
                                />
                                <Route
                                    path="/channels/groups/edit/:id"
                                    component={isUser(EditGroupChannelContainer)}
                                />
                                <Route
                                    path="/channels/groups/new"
                                    component={isUser(NewGroupChannelContainer)}
                                />
                                <Route
                                    exact
                                    path="/channels/groups/:id"
                                    component={isUser(ChannelPreviewContainer)}
                                />
                                <Route
                                    path="/channels/groups/:groupId/members/new"
                                    component={isUser(GroupChannelNewMembersContainer)}
                                />
                                <Route
                                    path="/channels/groups/:groupId/members"
                                    component={isUser(GroupChannelMembersListContainer)}
                                />
                                <Route
                                    path="/channels/groups"
                                    component={isUser(GroupChannelListContainer)}
                                />
                                <Route
                                    path="/channels/posts/new"
                                    component={isUser(ChannelPostNewContainer)}
                                />
                                <Route
                                    path="/channels/posts/edit/:id"
                                    component={isUser(ChannelPostEditContainer)}
                                />
                                <Route
                                    path="/channels/posts/:id"
                                    component={isUser(ChannelPostPreviewContainer)}
                                />
                                <Route
                                    path="/channels/posts"
                                    component={isUser(ChannelPostListContainer)}
                                />
                                <Route
                                    path="/channels/events/new"
                                    component={isUser(ChannelNewEventContainer)}
                                />
                                <Route
                                    path="/channels/events/edit/:id"
                                    component={isUser(ChannelEditEventContainer)}
                                />
                                <Route
                                    path="/channels/events/:id"
                                    component={isUser(ChannelEventPreviewContainer)}
                                />
                                <Route
                                    path="/channels/events"
                                    component={isUser(ChannelEventListContainer)}
                                />
                                <Route
                                    path="/calendar/browse-events/created/:type/:uid"
                                    component={isManager(CalendarEventDetailsContainer)}
                                />
                                <Route
                                    path="/calendar/browse-events/:user/:type/:uid"
                                    component={isManager(CalendarEventDetailsContainer)}
                                />
                                <Route
                                    path="/calendar/browse-events/:user"
                                    component={isManager(CalendarEventListContainer)}
                                />
                                <Route
                                    path="/calendar/browse-events"
                                    component={isManager(CalendarSelectContainer)}
                                />
                                <Route
                                    path="/calendar/add-new-kad-event"
                                    component={isManager(AddCalendarEventContainer)}
                                />
                                <Route
                                    path="/calendar/notification-banners/new"
                                    component={isManager(NotificationBannersNewContainer)}
                                />
                                <Route
                                    path="/calendar/notification-banners/edit/:id"
                                    component={isManager(NotificationBannersEditContainer)}
                                />
                                <Route
                                    path="/calendar/notification-banners"
                                    component={isManager(NotificationBannersListContainer)}
                                />
                                <Route
                                    path="/calendar/lse-calendar/events/new"
                                    component={isManager(PersistentEventsNewContainer)}
                                />
                                <Route
                                    path="/calendar/lse-calendar/events/edit/:id"
                                    component={isManager(PersistentEventsEditContainer)}
                                />
                                <Route
                                    path="/calendar/lse-calendar/events"
                                    component={isManager(PersistentEventsListContainer)}
                                />

                                <Route
                                    exact
                                    path="/notifications/standard/list"
                                    component={isManager(ListStandardNotification)}
                                />
                                <Route
                                    exact
                                    path="/notifications/standard/new"
                                    component={isManager(AddEditStandardNotification)}
                                />
                                <Route
                                    exact
                                    path="/notifications/standard/edit/:id"
                                    component={isManager(AddEditStandardNotification)}
                                />
                                <Route
                                    exact
                                    path="/notifications/standard/preview/:id"
                                    component={isManager(PreviewStandardNotification)}
                                />

                                <Route
                                    exact
                                    path="/notifications/timetable/list"
                                    component={isManager(ListTimetableNotification)}
                                />
                                <Route
                                    exact
                                    path="/notifications/timetable/new"
                                    component={isManager(AddEditTimetableNotification)}
                                />
                                <Route
                                    exact
                                    path="/notifications/timetable/edit/:id"
                                    component={isManager(AddEditTimetableNotification)}
                                />
                                <Route
                                    exact
                                    path="/notifications/timetable/preview/:id"
                                    component={isManager(PreviewTimetableNotification)}
                                />
                                <Route
                                    exact
                                    path="/notifications/email-templates/list"
                                    component={isManager(ListEmailTemplates)}
                                />
                                <Route
                                    exact
                                    path="/notifications/email-templates/new"
                                    component={isManager(AddEditEmailTemplates)}
                                />
                                <Route
                                    exact
                                    path="/notifications/email-templates/edit/:id"
                                    component={isManager(AddEditEmailTemplates)}
                                />
                                <Route
                                    path="/bookings/:id/bookings/:user/:type/:uid"
                                    component={isManager(CalendarEventDetailsContainer)}
                                />
                                <Route
                                    exact
                                    path="/campus-mapping"
                                    component={isManager(CampusMappingListContainer)}
                                />
                                <Route
                                    exact
                                    path="/campus-mapping/new"
                                    component={isManager(AddEditCampusMappingContainer)}
                                />
                                <Route
                                    exact
                                    path="/campus-mapping/edit/:id"
                                    component={isManager(AddEditCampusMappingContainer)}
                                />
                                <Route
                                    exact
                                    path="/cms-support-pages/overview"
                                    component={isAdmin(EditSupportPageContainer)}
                                />
                                <Route
                                    exact
                                    path="/knowledge-base/categories"
                                    component={isAdmin(HowToCategoryListContainer)}
                                />
                                <Route
                                    exact
                                    path="/knowledge-base/categories/new"
                                    component={isAdmin(HowToCategoryNewContainer)}
                                />
                                <Route
                                    exact
                                    path="/knowledge-base/categories/edit/:id"
                                    component={isAdmin(HowToCategoryEditContainer)}
                                />
                                <Route
                                    exact
                                    path="/knowledge-base/categories/:categoryId/articles"
                                    component={isAdmin(HowToArticlesListContainer)}
                                />
                                <Route
                                    exact
                                    path="/knowledge-base/categories/:categoryId/articles/new"
                                    component={isAdmin(HowToArticlesNewContainer)}
                                />
                                <Route
                                    exact
                                    path="/knowledge-base/categories/:categoryId/articles/edit/:id"
                                    component={isAdmin(HowToArticlesEditContainer)}
                                />
                                <Route
                                    exact
                                    path="/knowledge-base/categories/:categoryId/articles/preview/:id"
                                    component={isAdmin(HowToArticlesPreviewContainer)}
                                />
                                <Route
                                    path="/users-and-audiences/lse-students/edit/:id"
                                    component={isAdmin(EditStudentContainer)}
                                />
                                <Route
                                    exact
                                    path="/users-and-audiences/lse-students"
                                    component={isAdmin(LseStudentListContainer)}
                                />
                                <Route
                                    exact
                                    path="/users-and-audiences/lse-staff"
                                    component={isAdmin(LseStaffListContainer)}
                                />
                                <Route
                                    exact
                                    path="/users-and-audiences/lse-staff/new"
                                    component={isAdmin(NewStaffContainer)}
                                />
                                <Route
                                    exact
                                    path="/users-and-audiences/lse-staff/edit/:id"
                                    component={isAdmin(EditStaffContainer)}
                                />

                                <Route
                                    path="/users-and-audiences/audiences/edit/:id"
                                    component={isManager(EditAudienceContainer)}
                                />
                                <Route
                                    path="/users-and-audiences/audiences/new"
                                    component={isManager(NewAudienceContainer)}
                                />
                                <Route
                                    path="/users-and-audiences/audiences/edit-custom/:id"
                                    component={isManager(EditCustomAudienceContainer)}
                                />
                                <Route
                                    path="/users-and-audiences/audiences/new-custom"
                                    component={isManager(NewCustomAudienceContainer)}
                                />
                                <Route
                                    path="/users-and-audiences/audiences"
                                    component={isManager(AudienceListContainer)}
                                />

                                <Route
                                    exact
                                    path="/settings/school-years"
                                    component={isAdmin(ListSchoolYearsContainer)}
                                />
                                <Route
                                    exact
                                    path="/settings/school-years/new"
                                    component={isAdmin(NewSchoolYearsContainer)}
                                />
                                <Route
                                    exact
                                    path="/settings/school-years/edit/:id"
                                    component={isAdmin(EditSchoolYearsContainer)}
                                />
                                <Route
                                    exact
                                    path="/settings/school-years/holidays/:id"
                                    component={isAdmin(HolidaysSchoolYearsContainer)}
                                />

                                <Route
                                    path={ROUTE_OFFICE_HOURS}
                                    component={isUser(OfficeReportsRoutes)}
                                />

                                <Route
                                    path={ROUTE_SIDELINKS}
                                    component={isManager(SideLinksRoutes)}
                                />

                                <Route exact path="/" component={isUser(DashboardContainer)} />
                            </Switch>
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        {authError ? <UserNotFound /> : <NotLoggedIn />}
                    </div>
                )}

                <Route path="/error" component={ServerErrorPage} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isLogged: state.Auth.isLogged,
    authError: state.Auth.errorRes.error,
    permissionLevel: state.Auth.permissionLevel,
});
export const dispatchToProps = {
    checkStorage: actionCreators.checkStorage.create,
};
export default connect(mapStateToProps, dispatchToProps)(AppContent);
