export default {
    id: null,
    isActive: true,
    name: "",
    officeHoursTitle: "",
    settings: {
        maxAppointmentsPerSession: 1,
        maxNumberOfAttendees: 1,
        earliestBooking: 0,
        latestBooking: 0,
        allowFileUpload: true,
        requireBookingNote: false,
        receiveSummaryEmailAheadUpcomingAppointments: false,
    },
    admins: [],
    users: [],
    teamSummaryAtEight: true,
};
