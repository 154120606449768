export default {
    dialogImgContent: {
        maxWidth: "548px",
        width: "auto",
        textAlign: "center",
    },
    dialogImgCloseBtn: {
        width: 44,
        height: 44,
        backgroundColor: "#fff",
        fontSize: 25,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        right: 0,
    },
    dialogImgImg: {
        maxWidth: "500px",
        display: "block",
    },
    dialogContentRoot: {
        padding: 24,
    },
};
