import { of } from "rxjs";
import { mergeMap, catchError, tap, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import * as ajax from "../../common/services/utils";
import FileSaver from "file-saver";

const supportHomepageStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getHomepage.type, actionCreators.getKnowledgeBaseCategories.type),
        mapTo(UIActionCreators.setLoading.create())
    );

const supportHomepageClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createHomepage.type,
            actionCreators.updateKnowledgeBaseCategories.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getHomepageEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getHomepage.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl(`all/homepage/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.createHomepage.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const downloadGuideEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.downloadGuide.type),
        switchMap(action =>
            ajax
                .blob(ajax.apiUrl(`all/download-user-guide/${action.payload.id}`), {
                    fileType: action.payload.fileType,
                })
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(response, `${action.payload.fileType}.pdf`)
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getKnowledgeBaseCategoriesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getKnowledgeBaseCategories.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl(`all/how-to-category/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateKnowledgeBaseCategories.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getKnowledgeBaseArticlesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getKnowledgeBaseArticles.type),
        switchMap(action =>
            ajax
                .get(ajax.apiUrl(`all/how-to-category/${action.payload.categoryId}/articles-list/`))
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateKnowledgeBaseArticles.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneKnowledgeBaseArticleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneKnowledgeBaseArticle.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `all/how-to-category/${action.payload.categoryId}/articles/${action.payload.articleId}/`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateOneOneKnowledgeBaseArticle.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getIntroKnowledgeBaseArticleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getIntroKnowledgeBaseArticle.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(`all/how-to-category/${action.payload.categoryId}/articles-intro/`)
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateOneOneKnowledgeBaseArticle.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    getHomepageEpic,
    supportHomepageStartLoadingEpic,
    supportHomepageClearLoadingEpic,
    downloadGuideEpic,
    getKnowledgeBaseCategoriesEpic,
    getKnowledgeBaseArticlesEpic,
    getOneKnowledgeBaseArticleEpic,
    getIntroKnowledgeBaseArticleEpic
);
