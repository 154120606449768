import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { TableComponent, RedirectButton } from "../../../../common/components/OfficeHours";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { prepareFilterQuery } from "../../../../common/services/utils";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import tableCss from "../../../../common/styles/table.css";

import { decorateTableItemsJobTypes } from "../../../../common/services/decorateTableItems";
import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";

import FilterRow from "../../../../common/components/FilterRow";
import TabsComponent from "../../../../common/components/Tabs";
import formsCss from "../../../../common/styles/forms.css";
import { AvailabilityIcon, AddIcon, AutoRenewIcon } from "../../../../common/components/icons";
import globalsCss from "../../../../common/styles/globals.css";
import { GENERIC_AVAILABILITY } from "../types";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            name: "Name",
            upcomingSessions: "Upcoming sessions",
        },
    },
];

const buttons = (teamId, items) => index => [
    {
        path: `/bookings/availability/team/${teamId}/${
            items[index] ? items[index].teamMemberId : ""
        }/new`,
        label: "New team availability",
        fnLabel: "push",
        icon: <AddIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/bookings/availability/team/${teamId}/${
            items[index] ? items[index].teamMemberId : ""
        }/manage`,
        label: "View availabilities",
        fnLabel: "push",
        icon: <AvailabilityIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

const columns = ["id", "name", "upcomingSessions"];

class OfficeHoursManageTeamListContainer extends Component {
    state = {
        teamId: null,
        filter: "",
        filter$: new Subject(),
    };

    componentDidMount() {
        const {
            setTitle,
            getMyOfficeHoursTeams,
            userId,
            location,
            clearOneMyOfficeHoursAcademic,
            clearOneMyOfficeHoursTeam,
            clearMyOfficeHoursTeams,
            getMyOfficeHoursTeamAcademics,
        } = this.props;

        const { teamId, filter$ } = this.state;

        clearOneMyOfficeHoursAcademic();
        clearOneMyOfficeHoursTeam();
        clearMyOfficeHoursTeams();

        setTitle("Bookings \u203A Manage Availability");

        getMyOfficeHoursTeams(userId);

        if (location && location.search) {
            this.setState({
                teamId: location.search.replace("?", ""),
            });
        }

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                rawUpdateListOnFilterChange({
                    getFn: getMyOfficeHoursTeamAcademics,
                    filter: term,
                    teamId: teamId,
                })
            );
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { getMyOfficeHoursTeamAcademics } = this.props;
        const { filter, teamId } = this.state;

        // team change
        if (prevState.teamId !== teamId) {
            getMyOfficeHoursTeamAcademics({
                teamId,
                filter,
            });
        }
    }

    onFilterChangeHandler = ({ target: { value } }) => {
        const { teamId, filter$ } = this.state;
        this.setState({ filter: value }, () => filter$.next({ teamId, filter: this.state.filter }));
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMoreMyOfficeHoursTeamAcademics } = this.props;
        const { filter, teamId } = this.state;
        loadMoreMyOfficeHoursTeamAcademics({
            teamId,
            query: prepareFilterQuery({
                filter,
                page,
            }),
        });
    };

    componentWillUnmount() {
        const { clearMyOfficeHoursTeamAcademics, clearTitle } = this.props;
        clearTitle();
        clearMyOfficeHoursTeamAcademics();
    }

    render() {
        const {
            teamMembers,
            teams,
            history: { push },
            nextPage,
            count,
            classes,
        } = this.props;
        const { filter, teamId } = this.state;
        const { onFilterChangeHandler, onLoadMoreClickHandler, remove } = this;

        const currentTeam = teamId > 0 ? teams.find(team => team.id === parseInt(teamId)) : null;

        const teamMembersList =
            currentTeam && currentTeam.enableGenericAvailabilities
                ? [
                      ...(currentTeam && currentTeam.userType === "admin"
                          ? [
                                {
                                    id: "",
                                    teamMemberId: GENERIC_AVAILABILITY,
                                    name: <em>Generic availabilities</em>,
                                    upcomingSessions: currentTeam.genericUpcomingOfficeHours,
                                },
                            ]
                          : []),
                      ...teamMembers,
                  ]
                : teamMembers;

        return (
            <div>
                <Paper elevation={0}>
                    <TabsComponent
                        activeIndex={1}
                        tabs={[
                            {
                                label: "Individual",
                                selected: false,
                                fnLabel: "push",
                                param: `/bookings/availability`,
                            },
                            {
                                label: "Teams",
                                selected: true,
                                fnLabel: "push",
                                param: `/bookings/availability/team`,
                            },
                        ]}
                        fns={{ push }}
                    />
                </Paper>

                {teams && teams.length > 0 && (
                    <div css={globalsCss.helpers.mb15}>
                        <Paper elevation={1}>
                            <div css={globalsCss.inner}>
                                <TextField
                                    select
                                    label={"Select team"}
                                    fullWidth
                                    margin="none"
                                    color="primary"
                                    onChange={
                                        ({ target: { value } }) =>
                                            push(`/bookings/availability/team?${value}`)
                                        // this.setState({ teamId: value })
                                    }
                                    value={teamId ? teamId : ""}
                                    InputProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                            error: classes.inputError,
                                        },
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            MenuListProps: {
                                                disablePadding: true,
                                                dense: true,
                                            },
                                        },
                                    }}
                                >
                                    {teams.map((item, index) => (
                                        <MenuItem key={`team.${index}`} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Paper>
                    </div>
                )}

                {teamId > 0 && (
                    <div>
                        {teamMembers.length > 0 && (
                            <div css={[globalsCss.actions, globalsCss.actionsRight]}>
                                <RedirectButton
                                    label="Browse Team Sessions"
                                    url={`/bookings/browse/team/${teamId}/0`}
                                    variant="text"
                                    color="default"
                                    {...{ push }}
                                />
                            </div>
                        )}

                        <Paper elevation={1}>
                            <FilterRow
                                filter={filter}
                                onFilterChangeHandler={onFilterChangeHandler}
                            />
                            <TableComponent
                                items={teamMembersList}
                                fns={{ push, remove }}
                                buttons={buttons(teamId, teamMembersList)}
                                decorators={decorators}
                                columns={columns}
                            />
                        </Paper>
                    </div>
                )}

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {teamMembers.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getMyOfficeHoursTeamAcademics: actionCreators.getMyOfficeHoursTeamAcademics.create,
    loadMoreMyOfficeHoursTeamAcademics: actionCreators.loadMoreMyOfficeHoursTeamAcademics.create,
    getMyOfficeHoursTeams: actionCreators.getMyOfficeHoursTeams.create,
    clearMyOfficeHoursTeamAcademics: actionCreators.clearMyOfficeHoursTeamAcademics.create,
    clearOneMyOfficeHoursAcademic: actionCreators.clearOneMyOfficeHoursAcademic.create,
    clearOneMyOfficeHoursTeam: actionCreators.clearOneMyOfficeHoursTeam.create,
    clearMyOfficeHoursTeams: actionCreators.clearMyOfficeHoursTeams.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities, Auth }) => ({
    teamMembers: OfficeHoursAvailabilities.teamMembers.data.map(item => {
        return {
            id: item.userId,
            teamMemberId: item.id,
            name: `${item.firstName} ${item.lastName}`,
            upcomingSessions: item.upcomingOfficeHours,
        };
    }),
    count: OfficeHoursAvailabilities.teamMembers.count,
    nextPage: OfficeHoursAvailabilities.teamMembers.nextPage,
    teams: OfficeHoursAvailabilities.teams
        ? OfficeHoursAvailabilities.teams.filter(team => team.userType === "admin")
        : [],
    userId: Auth.id,
});

export default connect(
    mapStateToProps,
    dispatchToProps
)(withStyles(formsCss)(OfficeHoursManageTeamListContainer));
