import ActionCreator from "../actionCreator";

export default {
    getComments: ActionCreator("GET_COMMENTS"),
    updateComments: ActionCreator("UPDATE_COMMENTS"),
    clearComments: ActionCreator("CLEAR_COMMENTS"),
    removeComment: ActionCreator("REMOVE_COMMENT"),
    deleteComment: ActionCreator("DELETE_COMMENT"),
    errorResponse: ActionCreator("ERROR_RESPONSE_COMMENTS"),
};
