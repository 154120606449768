import actionCreators from "./actionCreators";
import administrator from "../../../fixtures/OfficeHoursAdministrator";

export const initialState = {
    administrators: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    administrator,
    importQueue: 0,
    importResult: null,
    errorRes: { error: "", errors: {} },
    currentFormType: null,
    copyAssociatedAcademics: null,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListOfficeHoursAdministrators.type:
            return {
                ...state,
                administrators: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListOfficeHoursAdministrators.type:
            payload.data = [
                ...state.administrators.data,
                ...payload.data.filter(
                    administrator =>
                        !state.administrators.data.map(item => item.id).includes(administrator.id)
                ),
            ];
            return {
                ...state,
                administrators: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneOfficeHoursAdministrator.type:
            return {
                ...state,
                administrator: payload.data ? payload.data[0] : initialState.administrator,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneOfficeHoursAdministratorAcademics.type:
            return {
                ...state,
                administrator: { ...state.administrator, academics: payload },
                errorRes: initialState.errorRes,
            };

        case actionCreators.clearOneOfficeHoursAdministrator.type:
            return {
                ...state,
                administrator: initialState.administrator,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.importOfficeHoursAdministratorAcademicsSetQueue.type:
            return {
                ...state,
                importQueue: payload,
                importResult: [],
            };
        case actionCreators.importOfficeHoursAdministratorAcademicsReduceQueue.type:
            return {
                ...state,
                importQueue: state.importQueue - 1,
                importResult:
                    !!payload &&
                    payload.id &&
                    !state.importResult.find(item => item.id === payload.id)
                        ? [...state.importResult, payload]
                        : state.importResult,
            };
        case actionCreators.importOfficeHoursAdministratorAcademicsComplete.type:
            return {
                ...state,
                importQueue: initialState.importQueue,
                importResult: initialState.importResult,
            };

        case actionCreators.addCopyAssociatedAcademics.type:
            return {
                ...state,
                copyAssociatedAcademics: payload,
                errorRes: initialState.errorRes,
            };

        case actionCreators.clearCopyAssociatedAcademics.type:
            return {
                ...state,
                copyAssociatedAcademics: initialState.copyAssociatedAcademics,
                errorRes: initialState.errorRes,
            };

        default:
            return state;
    }
};
