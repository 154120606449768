import ActionCreator from "../actionCreator";

export default {
    getOverview: ActionCreator("GET_SUPPORT_PAGE_OVERVIEW"),
    putOverview: ActionCreator("PUT_SUPPORT_PAGE_OVERVIEW"),
    setCurrentFormType: ActionCreator("SET_CURRENT_FORM_TYPE_SUPPORT_PAGE"),
    errorResponse: ActionCreator("ERROR_RESPONSE_SUPPORT_PAGE"),
    setLinkTypeStatus: ActionCreator("SUPPORT_PAGE_SET_IS_LINK_STATUS"),
    updateOverview: ActionCreator("UPDATE_OVERVIEW"),
};
