import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { Button, Dialog, Paper } from "@material-ui/core";

import { MainTitle } from "../../../common/components/MainTitle";
import Calendar from "../../../common/components/Calendar/Calendar";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";

import { GetFormattedDate } from "../../../common/components/Time";

const Event = ({ event }) => {
    return (
        <span>
            {event.title}
            {event.desc && " | " + event.desc}
        </span>
    );
};

const EventAgenda = ({ event }) => {
    return (
        <div>
            <div>{event.title}</div>
            <div>{event.desc}</div>
        </div>
    );
};

const EventDay = ({ event }) => {
    return (
        <span>
            {event.title}
            {event.desc && " | " + event.desc}
        </span>
    );
};

class OfficeHoursPreviewAvailabilityContainer extends Component {
    state = {
        date: new Date(),
        view: window.innerWidth < 768 ? "agenda" : "month",
        currentEventTitle: "",
        currentEventSlot: "",
        currentEventFrom: "",
        currentEventTo: "",
        currentEventLocation: "",
        currentEventAppointments: "",
        isShowDialogEvent: false,
        initiated: false,
        isAcademicContext: null,
        memberDetails: null,
    };

    getQuery() {
        const { date } = this.state;
        return {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
        };
    }

    getEvents() {
        const { calendar } = this.props;
        const events = calendar.map(slot => ({
            id: slot.id,
            title: `[${slot.title}] ${GetFormattedDate(slot.start, "h.mma")} - ${GetFormattedDate(
                slot.end,
                "h.mma"
            )}`,
            desc: slot.location,
            start: new Date(slot.start),
            end: new Date(slot.end),
            bookings: slot.bookings,
            rawTitle: slot.title,
            allDay: false,
        }));
        return events;
    }

    showDialogEvent = event => {
        this.setState({
            currentEventTitle: event.rawTitle,
            currentEventSlot: GetFormattedDate(event.start, "dddd D MMMM YYYY"),
            currentEventFrom: GetFormattedDate(event.start, "h.mma"),
            currentEventTo: GetFormattedDate(event.end, "h.mma"),
            currentEventLocation: event.desc,
            currentEventAppointments:
                event.bookings.length > 0
                    ? `${event.bookings.length} appointment${event.bookings.length > 1 ? "s" : ""}`
                    : "No appointments yet",
            isShowDialogEvent: true,
        });
    };

    hideDialogEvent = () => {
        this.setState({ isShowDialogEvent: false });
    };

    resize = () => {
        if (
            (window.innerWidth < 768 && this.state.view === "month") ||
            (window.innerWidth >= 768 && this.state.view === "agenda")
        ) {
            window.location.reload();
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.team !== nextProps.team ||
            this.props.academic !== nextProps.academic ||
            this.props.calendar !== nextProps.calendar ||
            nextState.view !== this.state.view ||
            nextState.isShowDialogEvent !== this.state.isShowDialogEvent ||
            nextState.date.getUTCMonth() !== this.state.date.getUTCMonth()
        );
    }

    componentDidMount() {
        const {
            setTitle,
            match,
            academic,
            getOneMyOfficeHoursAcademic,
            team,
            getOneMyOfficeHoursTeam,
        } = this.props;
        setTitle("Bookings \u203A Manage Availability");

        const isAcademicContext = Boolean(match && match.params && match.params.userId);
        this.setState({ isAcademicContext }, () => {
            if ((!isAcademicContext && !team) || (isAcademicContext && !academic)) {
                if (isAcademicContext && !academic)
                    getOneMyOfficeHoursAcademic(match.params.userId);
                if (!isAcademicContext && !team) getOneMyOfficeHoursTeam(match.params.teamId);
            } else {
                this.initComponent();
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { getOfficeCalendarSlots, academic, team } = this.props;
        const { initiated, isAcademicContext, memberDetails } = this.state;

        if (initiated && prevState.date.getUTCMonth() !== this.state.date.getUTCMonth()) {
            getOfficeCalendarSlots({
                id: isAcademicContext ? academic.id : memberDetails.userId,
                query: this.getQuery(),
            });
        }

        if (
            ((isAcademicContext && academic && academic.id > 0) || (team && team.id > 0)) &&
            !initiated
        ) {
            this.initComponent();
        }
    }

    getTeamMemberDetails = team => {
        const { match } = this.props;

        for (let user of team.users) {
            for (let userMember of user.officeHoursTeamMembers) {
                if (userMember.id === Number(match.params.teamMemberId))
                    return {
                        ...userMember,
                        userId: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                    };
            }
        }

        return false;
    };

    initComponent() {
        const { getOfficeCalendarSlots, academic, team } = this.props;
        const { isAcademicContext } = this.state;
        const memberDetails = !isAcademicContext ? this.getTeamMemberDetails(team) : {};

        this.setState({ initiated: true, memberDetails: memberDetails }, () => {
            getOfficeCalendarSlots({
                id: isAcademicContext ? academic.id : memberDetails.userId,
                query: this.getQuery(),
            });
            window.addEventListener("resize", this.resize);
        });
    }

    componentWillUnmount() {
        const { clearOfficeCalendarSlots, clearTitle } = this.props;
        clearOfficeCalendarSlots();
        clearTitle();
        window.removeEventListener("resize", this.resize);
    }

    render() {
        const {
            date,
            view,
            currentEventTitle,
            currentEventSlot,
            currentEventFrom,
            currentEventTo,
            currentEventLocation,
            currentEventAppointments,
            isShowDialogEvent,
            memberDetails,
            isAcademicContext,
        } = this.state;
        const { showDialogEvent, hideDialogEvent } = this;
        const {
            academic,
            match,
            history: { push },
        } = this.props;

        return (isAcademicContext && academic) || (!isAcademicContext && memberDetails) ? (
            <div>
                <MainTitle
                    title={
                        isAcademicContext
                            ? `${academic.firstName} ${academic.lastName}`
                            : `${memberDetails.firstName} ${memberDetails.lastName}`
                    }
                    type={`[Calendar Preview]`}
                />
                <Paper elevation={1} className="rbc-calendar-container">
                    <Calendar
                        events={this.getEvents()}
                        defaultDate={date}
                        defaultView={view}
                        onNavigate={(date, view) => {
                            this.setState({ date: date, view: view });
                        }}
                        onSelectEvent={event => showDialogEvent(event)}
                        components={{
                            event: Event,
                            agenda: {
                                event: EventAgenda,
                            },
                            day: {
                                event: EventDay,
                            },
                        }}
                    />
                    <div css={globalsCss.actionsBottom}>
                        <Button
                            onClick={() =>
                                push(
                                    match && match.params.teamMemberId
                                        ? `/bookings/availability/team/${match.params.teamId}/${match.params.teamMemberId}/manage`
                                        : `/bookings/availability/${match.params.academicId}/manage`
                                )
                            }
                        >
                            Back to the list
                        </Button>
                    </div>
                    <Dialog
                        open={isShowDialogEvent}
                        onClose={hideDialogEvent}
                        maxWidth="xs"
                        fullWidth
                    >
                        <div css={globalsCss.innerDialog}>
                            <p css={{ color: "#000" }}>
                                <strong>Availability:</strong> {currentEventTitle}
                            </p>
                            <p css={{ color: "#000" }}>
                                <strong>Date:</strong> {currentEventSlot}
                            </p>
                            <p css={{ color: "#000" }}>
                                <strong>Time:</strong> {currentEventFrom} - {currentEventTo}
                            </p>
                            {currentEventLocation && (
                                <p css={{ color: "#000" }}>
                                    <strong>Location:</strong> {currentEventLocation}
                                </p>
                            )}
                            <p css={{ color: "#000" }}>{currentEventAppointments}</p>
                        </div>
                    </Dialog>
                </Paper>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOfficeCalendarSlots: actionCreators.getOfficeCalendarSlots.create,
    clearOfficeCalendarSlots: actionCreators.clearOfficeCalendarSlots.create,
    getOneMyOfficeHoursAcademic: actionCreators.getOneMyOfficeHoursAcademic.create,
    getOneMyOfficeHoursTeam: actionCreators.getOneMyOfficeHoursTeam.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities }) => ({
    calendar: OfficeHoursAvailabilities.calendar,
    academic: OfficeHoursAvailabilities.academic,
    team: OfficeHoursAvailabilities.team,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursPreviewAvailabilityContainer);
