export default {
    id: null,
    status: "",
    publicationDate: null,
    name: "",
    startAt: null,
    endAt: null,
    description: "",
    location: "",
    signpostUrl: "",
    imageFile: {
        name: "",
        size: "",
        type: "",
        base64: "",
    },
    imageUrl: null,
    info: "none",
};
