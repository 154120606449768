import ActionCreator from "../../actionCreator";

export default {
    getListOfficeHoursAdministrators: ActionCreator("GET_LIST_OFFICE_HOURS_ADMINISTRATORS"),
    createListOfficeHoursAdministrators: ActionCreator("CREATE_LIST_OFFICE_HOURS_ADMINISTRATORS"),
    loadMoreOfficeHoursAdministrators: ActionCreator("LOAD_MORE_OFFICE_HOURS_ADMINISTRATORS"),
    updateListOfficeHoursAdministrators: ActionCreator("UPDATE_LIST_OFFICE_HOURS_ADMINISTRATORS"),
    postOfficeHoursAdministrator: ActionCreator("POST_OFFICE_HOURS_ADMINISTRATOR"),
    getOneOfficeHoursAdministrator: ActionCreator("GET_ONE_OFFICE_HOURS_ADMINISTRATOR"),
    getOneOfficeHoursAdministratorAcademics: ActionCreator(
        "GET_ONE_OFFICE_HOURS_ADMINISTRATOR_ACADEMICS"
    ),
    clearOneOfficeHoursAdministrator: ActionCreator("CLEAR_OFFICE_HOURS_ADMINISTRATOR"),
    updateOneOfficeHoursAdministrator: ActionCreator("UPDATE_ONE_OFFICE_HOURS_ADMINISTRATOR"),
    updateOneOfficeHoursAdministratorAcademics: ActionCreator(
        "UPDATE_ONE_OFFICE_HOURS_ADMINISTRATOR_ACADEMICS"
    ),
    pushListOfficeHoursAdministrators: ActionCreator("PUSH_LIST_OFFICE_HOURS_ADMINISTRATORS"),
    removeOfficeHoursAdministrator: ActionCreator("REMOVE_OFFICE_HOURS_ADMINISTRATOR"),
    addOfficeHoursAcademicToAdmin: ActionCreator("ADD_OFFICE_HOURS_ACADEMIC_TO_ADMINISTRATOR"),
    removeOfficeHoursAcademicFromAdmin: ActionCreator(
        "REMOVE_OFFICE_HOURS_ACADEMIC_FROM_ADMINISTRATOR"
    ),
    replaceOfficeHoursAdministrator: ActionCreator("REPLACE_OFFICE_HOURS_ADMINISTRATOR"),
    errorResponse: ActionCreator("ERROR_RESPONSE_OFFICE_HOURS_ADMINISTRATORS"),
    importOfficeHoursAdministratorAcademics: ActionCreator(
        "IMPORT_OFFICE_HOURS_ADMINISTRATOR_ACADEMICS"
    ),
    importOfficeHoursAdministratorAcademicsSetQueue: ActionCreator(
        "IMPORT_OFFICE_HOURS_ADMINISTRATOR_ACADEMICS_SET_QUEUE"
    ),
    importOfficeHoursAdministratorAcademicsReduceQueue: ActionCreator(
        "IMPORT_OFFICE_HOURS_ADMINISTRATOR_ACADEMICS_REDUCE_QUEUE"
    ),
    importOfficeHoursAdministratorAcademicsComplete: ActionCreator(
        "IMPORT_OFFICE_HOURS_ADMINISTRATOR_ACADEMICS_COMPLETE"
    ),
    getCopyAssociatedAcademics: ActionCreator("GET_COPY_ASSOCIATED_OFFICE_HOURS_ADMINISTRATOR"),
    addCopyAssociatedAcademics: ActionCreator("ADD_COPY_ASSOCIATED_OFFICE_HOURS_ADMINISTRATOR"),
    clearCopyAssociatedAcademics: ActionCreator("CLEAR_COPY_ASSOCIATED_OFFICE_HOURS_ADMINISTRATOR"),
};
