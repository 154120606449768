import { combineEpics } from "redux-observable";

import { epics as MainMenuEpics } from "./MainMenu/epics";
import { epics as AuthEpics } from "./Auth/epics";
import { epics as AutoCompleteEpics } from "./AutoComplete/epics";
import { epics as FroalaEpics } from "./Froala/epics";
import { epics as LseStudentsEpics } from "./UsersAndAudiences/LseStudents/epics";
import { epics as LseStaffEpics } from "./UsersAndAudiences/LseStaff/epics";
import { epics as AudienceEpics } from "./UsersAndAudiences/Audiences/epics";
import { epics as OfficeHoursAdministratorsEpics } from "./OfficeHours/Administrators/epics";
import { epics as OfficeHoursAcademicsEpics } from "./OfficeHours/Academics/epics";
import { epics as OfficeHoursTeamsEpics } from "./OfficeHours/Teams/epics";
import { epics as OfficeHoursAvailabilitiesEpics } from "./OfficeHours/Availabilities/epics";
import { epics as OfficeHoursSessionsEpics } from "./OfficeHours/Sessions/epics";
import { epics as OfficeHoursAppointmentsEpics } from "./OfficeHours/Appointments/epics";
import { epics as CalendarEventsEpics } from "./CalendarAndEvents/epics";
import { epics as ChannelPagePreviewEpics } from "./Channels/epics";
import { epics as ChannelPageEpics } from "./Channels/Pages/epics";
import { epics as ChannelPageFollowersEpics } from "./Channels/Pages/Followers/epics";
import { epics as ChannelGroupEpics } from "./Channels/Groups/epics";
import { epics as ChannelGroupMemberEpics } from "./Channels/Groups/Members/epics";
import { epics as ChannelPostEpics } from "./Channels/Posts/epics";
import { epics as ChannelEventEpics } from "./Channels/Events/epics";
import { epics as DiscussionEpics } from "./Discussion/epics";
import { epics as NotificationEpics } from "./Notification/epics";
import { epics as CampusMappingEpics } from "./CampusMapping/epics";
import { epics as SupportPageEpics } from "./SupportPage/epics";
import { epics as SupportHomepageEpics } from "./CmsSupport/epics";
import { epics as FeedbackEpics } from "./Feedback/epics";
import { epics as HowToCategoryEpics } from "./HowTo/Category/epics";
import { epics as HowToArticleEpics } from "./HowTo/Category/Articles/epics";
import { epics as DashboardEpics } from "./Dashboard/epics";
import { epics as SettingsSchoolYearsEpics } from "./Settings/SchoolYears/epics";
import { officeHoursReportEpics } from "./OfficeHours/Reports/epics";
import { epics as NotificationBannerEpics } from "./NotificationBanner/epics";
import { epics as PersistentEventsEpics } from "./PersistentEvents/epics";
import { epics as SideLinksEpics } from "./SideLinks/epics";

export const rootEpic = combineEpics(
    AuthEpics,
    AutoCompleteEpics,
    FroalaEpics,
    LseStaffEpics,
    LseStudentsEpics,
    AudienceEpics,
    OfficeHoursAdministratorsEpics,
    OfficeHoursAcademicsEpics,
    OfficeHoursTeamsEpics,
    OfficeHoursAvailabilitiesEpics,
    OfficeHoursSessionsEpics,
    OfficeHoursAppointmentsEpics,
    CalendarEventsEpics,
    ChannelPageEpics,
    ChannelPageFollowersEpics,
    ChannelGroupEpics,
    ChannelGroupMemberEpics,
    ChannelPagePreviewEpics,
    MainMenuEpics,
    DiscussionEpics,
    NotificationEpics,
    CampusMappingEpics,
    SupportPageEpics,
    SupportHomepageEpics,
    FeedbackEpics,
    HowToCategoryEpics,
    HowToArticleEpics,
    DashboardEpics,
    SettingsSchoolYearsEpics,
    officeHoursReportEpics,
    NotificationBannerEpics,
    ChannelPostEpics,
    ChannelEventEpics,
    PersistentEventsEpics,
    SideLinksEpics
);
