import ActionCreator from "../actionCreator";

export default {
    getCalendarSubject: ActionCreator("CALENDAR_GET_SUBJECT"),
    receiveCalendarSubject: ActionCreator("CALENDAR_RECEIVE_SUBJECT"),
    selectCalendarSubject: ActionCreator("CALENDAR_SELECT_SUBJECT"),
    getListEvents: ActionCreator("CALENDAR_GET_LIST_EVENTS"),
    createListEvents: ActionCreator("CALENDAR_CREATE_LIST_EVENTS"),
    clearListEvents: ActionCreator("CALENDAR_CLEAR_LIST_EVENTS"),
    getEventDetails: ActionCreator("CALENDAR_GET_EVENT_DETAILS"),
    getCreatedEventDetails: ActionCreator("CALENDAR_GET_CREATED_EVENT_DETAILS"),
    receiveEventDetails: ActionCreator("CALENDAR_RECEIVE_EVENT_DETAILS"),
    clearEventDetails: ActionCreator("CALENDAR_CLEAR_EVENT_DETAILS"),
    postEvent: ActionCreator("CALENDAR_POST_EVENT"),
    deleteEvent: ActionCreator("CALENDAR_DELETE_EVENT"),
    errorResponse: ActionCreator("CALENDAR_ERROR_RESPONSE"),
    setSearchBy: ActionCreator("CALENDAR_SET_SEARCH_BY"),
};
