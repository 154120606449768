import { Component } from "react";
import { connect } from "react-redux";
import { TableComponent, RedirectButton } from "../../../common/components/Channels";
import actionCreators from "../../../store/Channels/Groups/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as channelsActionCreators } from "../../../store/Channels/actionCreators";
import { prepareFilterQuery } from "../../../common/services/utils";
import { updateListOnFilterChange } from "../../../common/services/FilterList";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PromptDialog from "../../../common/components/PromptDialog";
import ChannelFilterRow from "../../../common/components/ChannelFilterRow";
import FilterRow from "../../../common/components/FilterRow";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";
import { hasAdminRole } from "../../../common/services/Auth";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import {
    statusCallback,
    permissionCallback,
    boolCallback,
} from "../../../common/services/tableCallbacks";
import formsCss from "../../../common/styles/forms.css";
import {
    PreviewIcon,
    EditIcon,
    PostIcon,
    EventIcon,
    DeleteIcon,
    AutoRenewIcon,
    MemberIcon,
    UpgradeIcon,
    DowngradeIcon,
} from "../../../common/components/icons";
import { MainTitle } from "../../../common/components/MainTitle";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            groupType: "Group Type",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            numFollowers: tableCss.columnWidth150,
            groupType: tableCss.columnWidth250,
            isRecommended: tableCss.columnWidth150,
        },
    },
];

const callbacks = {
    permissionLevel: permissionCallback,
    status: statusCallback,
    isRecommended: boolCallback,
};

const buttons = (items, isAdmin) => index => [
    {
        path: `/channels/groups/${items[index] ? items[index].id : ""}`,
        label: "Preview",
        fnLabel: "push",
        icon: <PreviewIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/channels/groups/edit/${items[index] ? items[index].id : ""}`,
        label: "Edit",
        fnLabel: "push",
        icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/channels/posts`,
        label: "Posts",
        fnLabel: "push",
        state: { id: items[index].id, name: items[index].name },
        icon: <PostIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/channels/events`,
        label: "Events",
        fnLabel: "push",
        state: { id: items[index].id, name: items[index].name },
        icon: <EventIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/channels/groups/${items[index] ? items[index].id : ""}/members`,
        label: "Members",
        fnLabel: "push",
        icon: <MemberIcon css={tableCss.actionsMenuItemIcon} />,
    },
    ...(isAdmin
        ? [
              !items[index].isRecommended
                  ? {
                        path: `${items[index] ? items[index].id : null}`,
                        label: "Set as recommended",
                        fnLabel: "setAsRecommended",
                        icon: <UpgradeIcon css={tableCss.actionsMenuItemIcon} />,
                    }
                  : {
                        path: `${items[index] ? items[index].id : null}`,
                        label: "Remove from recommended",
                        fnLabel: "removeFromRecommended",
                        icon: <DowngradeIcon css={tableCss.actionsMenuItemIcon} />,
                    },
          ]
        : []),
    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

class GroupChannelListContainer extends Component {
    state = {
        filter: "",
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
        recommended: "all",
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removeGroup({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    componentDidMount() {
        const { setTitle, getListGroup } = this.props;
        const { filter$ } = this.state;
        setTitle("Channels \u203A Groups");
        getListGroup(prepareFilterQuery(this.state.filter));
        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                updateListOnFilterChange({
                    getFn: getListGroup,
                    filter: term,
                })
            );
        });
    }

    onRecommendedChangeHandler = value => {
        const { filter$ } = this.state;
        this.setState({ recommended: value ? "yes" : "all" }, () =>
            filter$.next({ filter: this.state.filter, recommended: this.state.recommended })
        );
    };

    onFilterChangeHandler = ({ target: { value } }) => {
        const { filter$ } = this.state;
        this.setState({ filter: value }, () =>
            filter$.next({ filter: this.state.filter, recommended: this.state.recommended })
        );
    };

    onLoadMoreClickHandler = page => () => {
        const { filter } = this.state;
        this.props.loadMoreGroups(prepareFilterQuery({ filter, page }));
    };

    componentWillUnmount() {
        this.props.clearTitle();
    }

    remove = itemID => {
        this.handleOpen(itemID);
    };

    render() {
        const {
            groups,
            history: { push },
            nextPage,
            count,
            setChannelAsRecommended,
            removeChannelFromRecommended,
            permissionLevel,
        } = this.props;
        const { filter } = this.state;
        const { remove } = this;

        let columns = ["id", "name", "numFollowers", "groupType"];
        if (hasAdminRole(permissionLevel)) columns = [...columns, "isRecommended"];

        return (
            <div>
                <div>
                    <MainTitle title="Groups" type="[Channels]" />
                    <div css={[globalsCss.actions, globalsCss.actionsSpaceBetween]}>
                        <RedirectButton
                            label="Add new group"
                            url="/channels/groups/new"
                            {...{ push }}
                        />
                    </div>
                </div>

                <Paper elevation={1}>
                    {hasAdminRole(permissionLevel) ? (
                        <ChannelFilterRow
                            filter={filter}
                            onFilterChangeHandler={this.onFilterChangeHandler}
                            onRecommendedChangeHandler={this.onRecommendedChangeHandler}
                        />
                    ) : (
                        <FilterRow
                            filter={filter}
                            onFilterChangeHandler={this.onFilterChangeHandler}
                        />
                    )}
                    <TableComponent
                        items={groups}
                        fns={{
                            push,
                            remove,
                            setAsRecommended: id => setChannelAsRecommended({ id, type: "groups" }),
                            removeFromRecommended: id =>
                                removeChannelFromRecommended({ id, type: "groups" }),
                        }}
                        buttons={buttons(groups, hasAdminRole(permissionLevel))}
                        callbacks={callbacks}
                        decorators={decorators}
                        columns={columns}
                    />

                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this group? All posts, events &amp; group
                        content will be lost!
                    </PromptDialog>
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {groups.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListGroup: actionCreators.getListGroup.create,
    loadMoreGroups: actionCreators.loadMoreGroups.create,
    removeGroup: actionCreators.removeGroup.create,
    setChannelAsRecommended: channelsActionCreators.setChannelAsRecommended.create,
    removeChannelFromRecommended: channelsActionCreators.removeChannelFromRecommended.create,
};
const mapStateToProps = ({ ChannelGroup, Auth }) => ({
    groups: ChannelGroup.groups.data,
    count: ChannelGroup.groups.count,
    nextPage: ChannelGroup.groups.nextPage,
    permissionLevel: Auth.permissionLevel,
});
export default connect(mapStateToProps, dispatchToProps)(GroupChannelListContainer);
