import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { colors } from "../../UIGlobals";
import SvgElement from "../SvgElements/SvgElements.js";
import { buttonStyle } from "./buttonStyles";

class Button extends Component {
    render() {
        const { children, href, primary, isSmall, width, type, ...props } = this.props;
        const ButtonElement = href ? "a" : "button";

        return (
            // <a css={button.button}>
            //     <SvgElement
            //         name="button"
            //         css={button.shape}
            //         viewBox=" 0 0 200 34"
            //         height="34"
            //         fill={colors.primary}
            //     />
            //     <span css={button.text}>Sign into the CMS</span>
            // </a>
            <ButtonElement
                css={[
                    buttonStyle.body,
                    isSmall && buttonStyle.bodySmall,
                    width && { width: parseInt(width, 10) },
                ]}
                type={type}
                href={href}
                {...props}
            >
                <SvgElement
                    name="buttonShape"
                    css={buttonStyle.shape}
                    viewBox=" 0 0 338 48"
                    width="100%"
                    height={isSmall ? "42" : "48"}
                    fill={primary ? colors.primary : colors.secondary}
                    preserveAspectRatio="none"
                />
                <span css={[buttonStyle.text, isSmall && buttonStyle.textSmall]}>{children}</span>
            </ButtonElement>
        );
    }
}

export { Button };
