import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./Form";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import actionCreators from "../../../store/OfficeHours/Administrators/actionCreators";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";

class OfficeHoursAdministratorsEditContainer extends Component {
    state = {
        initialAcademics: null,
    };

    componentDidMount() {
        const { setTitle, getOneOfficeHoursAdministrator, match } = this.props;
        setTitle("Bookings \u203A Administrators");
        getOneOfficeHoursAdministrator({ id: match.params.id });
    }

    componentDidUpdate() {
        const { initialValues } = this.props;
        const { initialAcademics } = this.state;

        if (
            initialValues &&
            initialValues.user &&
            !!initialValues.user.id &&
            initialValues.academics &&
            initialValues.academics.length > 0 &&
            !initialAcademics
        ) {
            this.setState({ initialAcademics: { ...initialValues } });
        }
    }

    componentWillUnmount() {
        const { clearTitle, clearOneOfficeHoursAdministrator } = this.props;
        clearTitle();
        clearOneOfficeHoursAdministrator();
    }

    onSubmit = values => {
        const {
            addOfficeHoursAcademicToAdmin,
            removeOfficeHoursAcademicFromAdmin,
            replaceOfficeHoursAdministrator,
            history: { push },
        } = this.props;
        const { initialAcademics } = this.state;

        // same admin as before - only update associated academics
        // -----
        if (values.user.id === initialAcademics.user.id) {
            const newAcademics = values.academics;
            const originalAcademics = initialAcademics.academics;

            let toAdd = [];
            let toRemove = [];

            // collect
            for (let { id } of originalAcademics) {
                const isRemoved =
                    newAcademics.length > 0 && newAcademics.find(obj => obj.id === id);

                if (!isRemoved) {
                    toRemove.push({
                        id: values.user.id,
                        academicId: id,
                    });
                }
            }
            for (let { id } of newAcademics) {
                const isAdded =
                    originalAcademics.length > 0 && originalAcademics.find(obj => obj.id === id);

                if (!isAdded) {
                    toAdd.push({
                        id: values.user.id,
                        academicId: id,
                    });
                }
            }

            // execute
            const lastToAdd = toAdd.slice(-1)[0];
            for (let item of toAdd) {
                addOfficeHoursAcademicToAdmin({
                    ...item,
                    redirectToListAdministrators:
                        lastToAdd.academicId === item.academicId && toRemove.length === 0,
                });
            }
            const lastToRemove = toRemove.slice(-1)[0];

            for (let item of toRemove) {
                removeOfficeHoursAcademicFromAdmin({
                    ...item,
                    redirectToListAdministrators: lastToRemove.academicId === item.academicId,
                });
            }

            if (toRemove.length === 0 && toAdd.length === 0) {
                push("/bookings/administrators");
            }
        }

        // new admin - remove old admin and associate all new academics
        // -----
        else {
            replaceOfficeHoursAdministrator({
                new: values,
                removeId: initialAcademics.user.id,
            });
        }
    };

    render() {
        const {
            history: { push },
            users,
            academics,
            errorRes: { error, errors },
            getUsers,
            getAcademics,
            clearOfficeHoursAdministrators,
            clearOfficeHoursAcademics,
            admins,
            getAdmins,
            clearAdmins,
        } = this.props;
        const { onSubmit } = this;
        const { initialAcademics } = this.state;
        const currentFormType = CurrentFormTypes.EDIT;

        return initialAcademics && initialAcademics.academics ? (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Edit Bookings Administrator</h2>
                        <Form
                            {...{
                                initialValues: initialAcademics,
                                onSubmit,
                                currentFormType,
                                push,
                                users,
                                academics,
                                getUsers,
                                getAcademics,
                                clearUsers: clearOfficeHoursAdministrators,
                                clearAcademics: clearOfficeHoursAcademics,
                                errors,
                                formError: error,
                                admins,
                                getAdmins,
                                clearAdmins,
                            }}
                        />
                    </div>
                </Paper>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getUsers: AutoCompleteActionCreators.getStaffUsers.create,
    getAcademics: AutoCompleteActionCreators.getOfficeHoursAcademics.create,
    clearOfficeHoursAdministrators:
        AutoCompleteActionCreators.clearOfficeHoursAdministrators.create,
    clearOfficeHoursAcademics: AutoCompleteActionCreators.clearOfficeHoursAcademics.create,
    getOneOfficeHoursAdministrator: actionCreators.getOneOfficeHoursAdministrator.create,
    clearOneOfficeHoursAdministrator: actionCreators.clearOneOfficeHoursAdministrator.create,
    addOfficeHoursAcademicToAdmin: actionCreators.addOfficeHoursAcademicToAdmin.create,
    removeOfficeHoursAcademicFromAdmin: actionCreators.removeOfficeHoursAcademicFromAdmin.create,
    replaceOfficeHoursAdministrator: actionCreators.replaceOfficeHoursAdministrator.create,
    getAdmins: AutoCompleteActionCreators.getOfficeHoursAdministrators.create,
    clearAdmins: AutoCompleteActionCreators.clearOfficeHoursAdministrators.create,
};

const mapStateToProps = ({ OfficeHoursAdministrators, AutoComplete }) => ({
    initialValues: {
        user: {
            id: OfficeHoursAdministrators.administrator.id,
            firstName: OfficeHoursAdministrators.administrator.firstName,
            lastName: OfficeHoursAdministrators.administrator.lastName,
        },
        academics: OfficeHoursAdministrators.administrator.academics,
    },
    errorRes: OfficeHoursAdministrators.errorRes,
    users: AutoComplete.staff,
    academics: AutoComplete.officehoursacademics,
    admins: AutoComplete.officehoursadministrators,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursAdministratorsEditContainer);
