import React, { FC, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { default as mainMenuActionCreators } from "src/store/MainMenu/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { AppState } from "src/store/reducers";
import CampusMappingActios from "src/store/CampusMapping/actionCreators";
import CampusMappingForm, {
    CampusMappingFormValues,
} from "src/app/CampusMapping/forms/CampusMappingForm";
import { CurrentFormType } from "src/types/generic";

interface MatchParams {
    id: string;
}
const AddEditCampusMappingContainer: FC<RouteComponentProps<MatchParams>> = ({
    match,
    history,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(mainMenuActionCreators.setTitle.create("Campus Mapping"));
        const id = match.params.id;
        if (id) dispatch(CampusMappingActios.getOneBuilding.create({ id: id }));

        return () => {
            dispatch(mainMenuActionCreators.clearTitle.create());
        };
    }, []);

    const building = useSelector((state: AppState) => state.CampusMapping.building);

    const { error, errors } = useSelector((state: AppState) => state.CampusMapping.errorRes);

    const onSubmit = async (values: CampusMappingFormValues) => {
        if (building && building.id) dispatch(CampusMappingActios.putBuilding.create(values));
        else dispatch(CampusMappingActios.postBuilding.create(values));
    };

    return (
        <div>
            <CampusMappingForm
                {...{
                    formType: building && building.id ? CurrentFormType.EDIT : CurrentFormType.NEW,
                    initialValues: building,
                    onSubmit,
                    error,
                    errors,
                    push: history.push,
                }}
            />
        </div>
    );
};

export default AddEditCampusMappingContainer;
