import React, { FC, useState, useEffect } from "react";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { Button, Paper } from "@material-ui/core";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Notification/actionCreators";
import mainMenuActionCreators from "../../../store/MainMenu/actionCreators";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";
import formsCss from "../../../common/styles/forms.css";
import { MainTitle } from "../../../common/components/MainTitle";
import { RedirectButton } from "../../../common/components/RedirectButton";
import { TableComponent } from "../../../common/components/DefaultTable";
import FilterRow from "../../../common/components/FilterRow";
import PromptDialog from "../../../common/components/PromptDialog";
import { rawUpdateListOnFilterChange } from "../../../common/services/FilterList";
import { AutoRenewIcon, EditIcon, DeleteIcon } from "../../../common/components/icons";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";

const ListEmailTemplates: FC<{ history }> = ({ history }) => {
    const dispatch = useDispatch();
    const { nextPage, data, count } = useSelector(
        (state: AppState) => state.Notification.templates
    );

    const [filter, setFilter] = useState(undefined);
    const [subscription, setSubscription] = useState<Subscription | undefined>(undefined);
    const [removeDialog, setRemoveDialog] = useState(false);
    const [removeId, setRemoveId] = useState(null);
    const [filter$, setFilter$] = useState(new Subject());
    const { push } = history;

    const decorators = [
        {
            type: decorateTableItemsJobTypes.TRANSLATE,
            payload: {
                name: "Name",
            },
        },
    ];

    const columns = ["id", "name"];

    const buttons = items => index => [
        {
            path: `/notifications/email-templates/edit/${items[index].id}`,
            label: "Edit",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
            hide: !items[index].id,
        },
        {
            path: items[index].id,
            label: "Delete",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
            hide: !items[index].id,
        },
    ];

    const getTemplateList = payload => dispatch(actionCreators.getTemplateList.create(payload));

    useEffect(() => {
        dispatch(mainMenuActionCreators.setTitle.create("Notifications"));
        dispatch(actionCreators.getTemplateList.create({}));
        setSubscription(
            filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(filter => {
                rawUpdateListOnFilterChange({
                    getFn: getTemplateList,
                    filter,
                });
            })
        );

        return () => {
            dispatch(mainMenuActionCreators.clearTitle.create());

            if (subscription) {
                subscription.unsubscribe();
                setSubscription(undefined);
            }
        };
    }, []);

    const onFilterChangeHandler = ({ target: { value } }) => {
        setFilter(value || undefined);
        filter$.next({ filter: value || undefined });
    };

    const onLoadMoreClickHandler = page => () => {
        dispatch(actionCreators.loadMoreTemplates.create({ filter, page }));
    };

    const handleOpen = id => {
        setRemoveDialog(true);
        setRemoveId(id);
    };

    const handleClose = confirmed => {
        if (confirmed === true && removeId) {
            dispatch(actionCreators.removeTemplate.create(removeId));
        }
        setRemoveDialog(false);
        setRemoveId(null);
    };

    const remove = itemID => {
        handleOpen(itemID);
    };

    const RedirectButtonJS = RedirectButton as any;

    return (
        <div>
            <MainTitle title="Timetable Email Templates" />
            <div css={globalsCss.actionsNotifications}>
                <div className="row">
                    <div className="col-lg-4 col-xs-12">
                        <RedirectButtonJS
                            label="Add new template"
                            url="/notifications/email-templates/new"
                            {...{ push }}
                        />
                    </div>
                </div>
            </div>
            <Paper elevation={1}>
                <FilterRow filter={filter || ""} onFilterChangeHandler={onFilterChangeHandler} />

                <TableComponent
                    items={data}
                    fns={{ push, remove: remove }}
                    decorators={decorators}
                    columns={columns}
                    buttons={buttons(data)}
                />
            </Paper>
            <PromptDialog open={removeDialog} handleClose={handleClose}>
                Are you sure you want to delete this template?
            </PromptDialog>
            {nextPage > 0 && (
                <div css={tableCss.loadMore}>
                    <Button
                        onClick={onLoadMoreClickHandler(nextPage)}
                        variant="contained"
                        color="secondary"
                    >
                        <AutoRenewIcon css={formsCss.btnIcon} />
                        Load more
                    </Button>
                    <span css={tableCss.loadMoreLabel}>
                        <small>
                            (showing {data.length} out of {count})
                        </small>
                    </span>
                </div>
            )}
        </div>
    );
};

export default ListEmailTemplates;
