import { Component } from "react";
import { Field, reduxForm } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import validate from "./validate";
import { formStyle } from "./HomeStyles";
import { Button, Icon } from "../common/UIElements";
import { RenderTextField } from "../common/UIElements/FormFields/TextField";
import { RenderTextDescription } from "../common/UIElements/FormFields/TextDescription";
import { RenderSelectField } from "../common/UIElements/FormFields/SelectField";

class FeedbackForm extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const { handleSubmit, onSubmit, subjects, initialValues, errors } = this.props;
        return (
            <form onSubmit={handleSubmit(onSubmit)} css={formStyle.body}>
                <div css={formStyle.group}>
                    <label css={formStyle.label}>What can we help you with?</label>
                    <div css={formStyle.select}>
                        <Field
                            name="requestType"
                            component={RenderSelectField}
                            errorRes={errors.requestType}
                        >
                            <option value="" disabled />
                            {subjects.map(option => {
                                return (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                );
                            })}
                        </Field>
                        <span css={formStyle.selectIcon}>
                            <Icon name="select" width="12" height="12" fill="rgba(0,0,0,0.87)" />
                        </span>
                    </div>
                </div>
                <div css={formStyle.row}>
                    <div css={formStyle.col}>
                        <div css={formStyle.group}>
                            <label css={formStyle.label}>Full name</label>
                            <Field
                                name="name"
                                component={RenderTextField}
                                errorRes={errors.name}
                                readOnly={!!initialValues.name}
                            />
                        </div>
                    </div>
                    <div css={formStyle.col}>
                        <div css={formStyle.group}>
                            <label css={formStyle.label}>LSE email address</label>
                            <Field
                                name="email"
                                component={RenderTextField}
                                errorRes={errors.email}
                                readOnly={!!initialValues.email}
                            />
                        </div>
                    </div>
                </div>
                <div css={formStyle.group}>
                    <label css={formStyle.label}>Summary</label>
                    <Field name="subject" component={RenderTextField} errorRes={errors.subject} />
                </div>
                <div css={formStyle.group}>
                    <label css={formStyle.label}>Message</label>
                    <Field name="body" component={RenderTextDescription} errorRes={errors.body} />
                </div>
                <div css={formStyle.actions}>
                    <Button type="submit">Send feedback</Button>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: "feedback",
    enableReinitialize: true,
    validate,
})(FeedbackForm);
