import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    FormProvider,
    RHFTextField,
    RHFAutoComplete,
    RHFRadioGroup,
    RHFSelect,
    RHFTimepicker,
    RHFDatepicker,
} from "src/common/components/RHF-Fields";
import { CurrentFormType } from "src/types/generic";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "src/common/styles/globals.css";
import formsCss from "src/common/styles/forms.css";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import actionCreators from "../../../store/Notification/actionCreators";
import { Button, Paper } from "@material-ui/core";
import {
    StandardNotification,
    MobileDeeplinkType,
    MessageType,
    NotificationDelivery,
} from "../types/Notification";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import TabsComponent from "../../../common/components/Tabs";
import { hasAdminRole } from "../../../common/services/Auth";
import { default as autoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { rawUpdateListOnFilterChange } from "../../../common/services/FilterList";

export type StandardNotificationFormValues = StandardNotification;

const StandardNotificationSchema = () =>
    yup.object().shape({
        type: yup
            .string()
            .typeError("Required")
            .required("Required"),
        message: yup
            .string()
            .typeError("Required")
            .required("Required"),

        subject: yup.mixed().when(["type"], type => {
            if (type === MessageType.EMAIL) {
                return yup.string().required("Required");
            } else {
                return yup.string().notRequired();
            }
        }),
        sendAs: yup.mixed().when(["type"], type => {
            if (type === MessageType.EMAIL) {
                return yup.string().required("Required");
            } else {
                return yup.string().notRequired();
            }
        }),
        scheduledAt: yup.mixed().when(["status"], status => {
            if (status === NotificationDelivery.SCHEDULE) {
                return yup
                    .date()
                    .required("Required")
                    .typeError("Required")
                    .min(new Date(), "Scheduled date must be in the future");
            } else {
                return yup.mixed().notRequired();
            }
        }),
        audience: yup.object().shape({
            pages: yup.mixed().test("pagesHasValue", "Required", (value, context) => {
                return (
                    context.parent["students-broadcast"] ||
                    context.parent.audiences.length > 0 ||
                    context.parent.pages.length > 0 ||
                    context.parent.students.length > 0
                );
            }),
            audiences: yup.mixed().test("audiencesHasValue", "Required", (value, context) => {
                return (
                    context.parent["students-broadcast"] ||
                    context.parent.audiences.length > 0 ||
                    context.parent.pages.length > 0 ||
                    context.parent.students.length > 0
                );
            }),
            students: yup.mixed().test("studentsHasValue", "Required", (value, context) => {
                return (
                    context.parent["students-broadcast"] ||
                    context.parent.audiences.length > 0 ||
                    context.parent.pages.length > 0 ||
                    context.parent.students.length > 0
                );
            }),
        }),
    });

interface OwnProps {
    initialValues: StandardNotification;
    formType: CurrentFormType;
    error?: string;
    errors?: { [key: string]: string };
    onSubmit: (data: StandardNotificationFormValues) => void;
    push: (pathname: string) => void;
}

const StandardNotificationForm: FC<OwnProps> = ({
    initialValues,
    formType,
    error,
    errors,
    onSubmit,
    push,
}) => {
    const dispatch = useDispatch();
    const notificationsEmails = useSelector(
        (state: AppState) => state.Notification.notificationsEmails
    );
    const permissionLevel = useSelector((state: AppState) => state.Auth.permissionLevel);

    const [pagesFilter$, setPagesFilter$] = useState(new Subject());
    const [pagesSubscription, setPagesSubscription] = useState<Subscription | undefined>(undefined);
    const pages = useSelector((state: AppState) => state.AutoComplete.pages);
    const getPages = params => dispatch(autoCompleteActionCreators.getPages.create(params));

    const [studentsFilter$, setStudentsFilter$] = useState(new Subject());
    const [studentsSubscription, setStudentsSubscription] = useState<Subscription | undefined>(
        undefined
    );
    const students = useSelector((state: AppState) => state.AutoComplete.students);
    const getStudents = params =>
        dispatch(autoCompleteActionCreators.getStudentUsers.create(params));

    const [audiencesFilter$, setAudiencesFilter$] = useState(new Subject());
    const [audiencesSubscription, setAudiencesSubscription] = useState<Subscription | undefined>(
        undefined
    );
    const audiences = useSelector((state: AppState) => state.AutoComplete.audiences);
    const getAudiences = params => dispatch(autoCompleteActionCreators.getAudiences.create(params));

    const [postsFilter$, setPostsFilter$] = useState(new Subject());
    const [postsSubscription, setPostsSubscription] = useState<Subscription | undefined>(undefined);
    const posts = useSelector((state: AppState) => state.AutoComplete.posts);
    const getPosts = params => dispatch(autoCompleteActionCreators.getPosts.create(params));

    const [eventsFilter$, setEventsFilter$] = useState(new Subject());
    const [eventsSubscription, setEventsSubscription] = useState<Subscription | undefined>(
        undefined
    );
    const events = useSelector((state: AppState) => state.AutoComplete.events);
    const getEvents = params => dispatch(autoCompleteActionCreators.getEvents.create(params));

    const [mobileDeeplinkType, setMobileDeeplinkType] = useState<MobileDeeplinkType>(
        MobileDeeplinkType.PAGE
    );

    const isDesktop = useMediaQuery("(min-width:960px)");
    const [allStudents, setAllStudents] = useState<boolean>(false);

    const [initiated, setInitiated] = useState<boolean>(false);

    const methods = useForm<StandardNotification>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(StandardNotificationSchema()),
        defaultValues: initialValues,
    });
    const {
        handleSubmit,
        formState,
        reset,
        setValue,
        watch,
        formState: { errors: formErrors },
    } = methods;

    const watchType = watch("type");
    const watchStatus = watch("status");

    useEffect(() => {
        if (initiated) {
            if (watchType === MessageType.EMAIL) {
                setValue("deeplinkType", "");
            } else setValue("deeplinkType", MobileDeeplinkType.PAGE);
            setValue("link", "");
        }
    }, [watchType]);

    useEffect(() => {
        setPagesSubscription(
            pagesFilter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                rawUpdateListOnFilterChange({
                    getFn: getPages,
                    filter: term,
                });
            })
        );

        setStudentsSubscription(
            studentsFilter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                rawUpdateListOnFilterChange({
                    getFn: getStudents,
                    filter: term,
                });
            })
        );

        setAudiencesSubscription(
            audiencesFilter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                rawUpdateListOnFilterChange({
                    getFn: getAudiences,
                    filter: term,
                });
            })
        );

        setPostsSubscription(
            postsFilter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                rawUpdateListOnFilterChange({
                    getFn: getPosts,
                    filter: term,
                });
            })
        );

        setEventsSubscription(
            eventsFilter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                rawUpdateListOnFilterChange({
                    getFn: getEvents,
                    filter: term,
                });
            })
        );

        return () => {
            if (pagesSubscription) {
                pagesSubscription.unsubscribe();
                setPagesSubscription(undefined);
            }
            if (studentsSubscription) {
                studentsSubscription.unsubscribe();
                setStudentsSubscription(undefined);
            }
            if (audiencesSubscription) {
                audiencesSubscription.unsubscribe();
                setAudiencesSubscription(undefined);
            }
            if (postsSubscription) {
                postsSubscription.unsubscribe();
                setPostsSubscription(undefined);
            }
            if (eventsSubscription) {
                eventsSubscription.unsubscribe();
                setEventsSubscription(undefined);
            }

            dispatch(actionCreators.clearNotification.create());
        };
    }, []);

    useEffect(() => {
        reset(initialValues);
        if (initialValues.audience["students-broadcast"] === true) setAllStudents(true);
        setInitiated(true);
    }, [initialValues]);

    useEffect(() => {
        if (!notificationsEmails) dispatch(actionCreators.getNotificationEmails.create());
    }, [notificationsEmails]);

    useEffect(() => {
        setValue("audience.students-broadcast", allStudents);
    }, [allStudents]);

    return (
        <Paper elevation={1}>
            <div css={globalsCss.inner}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-xs-12">
                            <RHFRadioGroup
                                name="status"
                                options={[
                                    NotificationDelivery.DRAFT,
                                    NotificationDelivery.IMMEDIATELLY,
                                    NotificationDelivery.SCHEDULE,
                                    ...(initialValues.status === NotificationDelivery.SENT
                                        ? [NotificationDelivery.SENT]
                                        : []),
                                ]}
                                getOptionLabel={[
                                    "Draft",
                                    initialValues.status === NotificationDelivery.IMMEDIATELLY
                                        ? "Sending"
                                        : "Send immediately",
                                    "Schedule at",
                                    ...(initialValues.status === NotificationDelivery.SENT
                                        ? ["Sent"]
                                        : []),
                                ]}
                            />
                        </div>
                    </div>
                    {watchStatus === NotificationDelivery.SCHEDULE && (
                        <div css={[formsCss.scheduledAt]}>
                            <RHFDatepicker
                                name="scheduledAt"
                                placeholder="Enter Date"
                                errorRes={errors && errors.scheduledAt}
                            />
                            <RHFTimepicker name="scheduledAt" placeholder="HH:MM" />
                        </div>
                    )}
                    <hr />
                    {hasAdminRole(permissionLevel) && (
                        <TabsComponent
                            activeIndex={allStudents ? 1 : 0}
                            tabs={[
                                {
                                    label: "Select receipients",
                                    selected: !allStudents,
                                    fnLabel: "setAllStudents",
                                    param: false,
                                },
                                {
                                    label: isDesktop
                                        ? "Send to all students (Broadcast)"
                                        : "Send to all",
                                    selected: allStudents,
                                    fnLabel: "setAllStudents",
                                    param: true,
                                },
                            ]}
                            fns={{ setAllStudents }}
                        />
                    )}
                    {allStudents ? (
                        <div css={globalsCss.helpers.mt45}>
                            Notification will be sent to all students.
                        </div>
                    ) : (
                        <div>
                            <div className="row" css={globalsCss.helpers.mt25}>
                                <div className="col-sm-6 col-xs-12">
                                    <label css={formsCss.ownersLabel}>
                                        Page Channels&apos; Followers
                                    </label>
                                    <RHFAutoComplete
                                        name="audience.pages"
                                        handleInput={value => {
                                            if (value) {
                                                return pagesFilter$.next({
                                                    filter: value,
                                                    withFollowers: "yes",
                                                });
                                            }
                                        }}
                                        dataSource={pages}
                                        label="Page name or ID"
                                        labelCallback={item => item.information || item.name}
                                        numCallback={item => item.numFollowers}
                                        onDroppedFocus={() =>
                                            dispatch(autoCompleteActionCreators.clearPages.create())
                                        }
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <label css={formsCss.ownersLabel}>LSE Student(s)</label>

                                    <RHFAutoComplete
                                        name="audience.students"
                                        handleInput={value => {
                                            if (value) {
                                                return studentsFilter$.next({ filter: value });
                                            }
                                        }}
                                        dataSource={students}
                                        label="Full name or ID"
                                        onDroppedFocus={() =>
                                            dispatch(
                                                autoCompleteActionCreators.clearStudentUsers.create()
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <label css={formsCss.ownersLabel}>Custom Audience(s)</label>
                                    <RHFAutoComplete
                                        name="audience.audiences"
                                        handleInput={value => {
                                            if (value) {
                                                return audiencesFilter$.next({ filter: value });
                                            }
                                        }}
                                        dataSource={audiences}
                                        label="Audience name or ID"
                                        labelCallback={item => item.information || item.name}
                                        onDroppedFocus={() =>
                                            dispatch(
                                                autoCompleteActionCreators.clearAudiences.create()
                                            )
                                        }
                                    />
                                </div>
                            </div>

                            {errors && errors.audience && (
                                <span css={formsCss.genericError}>{errors.audience}</span>
                            )}
                        </div>
                    )}

                    <hr />
                    <h4 css={[globalsCss.helpers.mt35, globalsCss.helpers.mb20]}>
                        Notification Type
                    </h4>

                    <div className="row">
                        <div className="col-xs-12">
                            <RHFRadioGroup
                                name="type"
                                options={[MessageType.EMAIL, MessageType.MOBILE]}
                                getOptionLabel={["E-mail", "Mobile Notification"]}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            {watchType === MessageType.EMAIL ? (
                                <div>
                                    <RHFTextField
                                        name="subject"
                                        label="Subject"
                                        errorRes={errors && errors.subject}
                                    />

                                    <RHFSelect
                                        name="sendAs"
                                        label="Send as"
                                        errorRes={errors && errors.sendAs}
                                        options={
                                            notificationsEmails
                                                ? notificationsEmails.map(item => {
                                                      return {
                                                          value: item.key,
                                                          label: `${item.name} <${item.email}>`,
                                                      };
                                                  })
                                                : []
                                        }
                                    />

                                    <RHFTextField
                                        name="deeplinkType"
                                        errorRes={errors && errors.deeplinkType}
                                        label="Attach URL"
                                        placeholder="https://"
                                    />
                                </div>
                            ) : (
                                <>
                                    <RHFSelect
                                        name="deeplinkType"
                                        label="Deeplink"
                                        errorRes={errors && errors.mobileDeeplinkType}
                                        options={[
                                            {
                                                value: MobileDeeplinkType.PAGE,
                                                label: "Page",
                                            },
                                            {
                                                value: MobileDeeplinkType.POST,
                                                label: "Post",
                                            },
                                            {
                                                value: MobileDeeplinkType.EVENT,
                                                label: "Event",
                                            },
                                        ]}
                                        onChange={(v: MobileDeeplinkType) => {
                                            setMobileDeeplinkType(v);
                                            setValue("link", "");
                                        }}
                                    />

                                    <RHFAutoComplete
                                        name="link"
                                        handleInput={value => {
                                            if (value) {
                                                if (mobileDeeplinkType === MobileDeeplinkType.PAGE)
                                                    return pagesFilter$.next({ filter: value });
                                                else if (
                                                    mobileDeeplinkType === MobileDeeplinkType.POST
                                                )
                                                    return postsFilter$.next({ filter: value });
                                                else if (
                                                    mobileDeeplinkType === MobileDeeplinkType.EVENT
                                                )
                                                    return eventsFilter$.next({ filter: value });
                                            }
                                        }}
                                        dataSource={
                                            mobileDeeplinkType === MobileDeeplinkType.PAGE
                                                ? pages
                                                : mobileDeeplinkType === MobileDeeplinkType.POST
                                                ? posts
                                                : mobileDeeplinkType === MobileDeeplinkType.EVENT
                                                ? events
                                                : []
                                        }
                                        label={`${mobileDeeplinkType} name or ID`}
                                        labelCallback={item => item.information || item.name}
                                        onDroppedFocus={() =>
                                            mobileDeeplinkType === MobileDeeplinkType.PAGE
                                                ? dispatch(
                                                      autoCompleteActionCreators.clearPages.create()
                                                  )
                                                : mobileDeeplinkType === MobileDeeplinkType.POST
                                                ? dispatch(
                                                      autoCompleteActionCreators.clearPosts.create()
                                                  )
                                                : mobileDeeplinkType === MobileDeeplinkType.EVENT
                                                ? dispatch(
                                                      autoCompleteActionCreators.clearEvents.create()
                                                  )
                                                : []
                                        }
                                        single
                                        noAvatar
                                    />
                                </>
                            )}
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <RHFTextField
                                name="message"
                                errorRes={errors && errors.message}
                                label="Message"
                                fullWidth
                                multiline
                                rows={watchType === MessageType.EMAIL ? 9 : 5}
                                rowsMax={watchType === MessageType.EMAIL ? 9 : 5}
                            />
                        </div>
                    </div>

                    {error && (
                        <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                            {error}
                        </div>
                    )}
                    {formErrors && Object.keys(formErrors).length > 0 && (
                        <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                            Please check for errors on this page
                        </div>
                    )}
                    <div css={formsCss.actions}>
                        <Button
                            disabled={formState.isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            css={formsCss.btnSubmit}
                        >
                            {formType === CurrentFormType.NEW
                                ? "Add standard notification"
                                : "Save standard notification"}
                        </Button>
                        <Button
                            disabled={formState.isSubmitting}
                            onClick={() => push("/notifications/standard/list")}
                            css={formsCss.btnBack}
                        >
                            Back to the list
                        </Button>
                    </div>
                </FormProvider>
            </div>
        </Paper>
    );
};

export default StandardNotificationForm;
