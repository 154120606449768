import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import formsCss from "../../styles/forms.css";

const renderTextDescription = ({
    input,
    label,
    meta: { touched, error },
    errorRes,
    classes,
    ...custom
}) => (
    <TextField
        multiline
        rows="4"
        placeholder={label}
        label={label}
        helperText={touched && (error || errorRes)}
        error={!!touched && (!!error || !!errorRes)}
        fullWidth
        margin="normal"
        color="primary"
        InputProps={{
            classes: {
                root: classes.inputRoot,
                error: classes.inputError,
            },
        }}
        {...input}
        {...custom}
    />
);

export const RenderTextDescription = withStyles(formsCss)(renderTextDescription);
