import { spacing } from "./spacing";

export default {
    appbar: {},
    sidebar: {
        display: "flex",
        flexDirection: "column",
        width: 300,
        height: "100vh",
    },
    logo: {
        padding: 16,
        borderBottom: "1px solid #eee",
    },
    logoImg: {
        width: 140,
        height: 50,
        display: "block",
    },
    menu: {
        flex: "1",
        overflowY: "auto",
        padding: "25px 0",
    },
    menuItem: {
        display: "flex",
        fontSize: 14,
    },
    menuItemInner: {
        paddingLeft: 60,
    },
    menuItemIcon: {
        opacity: "1",
    },
    menuSubItem: {
        fontSize: 14,
    },
    menuSubItemInner: {
        paddingLeft: 60,
        marginLeft: 0,
    },
    rootMenuItem: {
        paddingTop: spacing.space1comma5,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1comma5,
        paddingLeft: spacing.space2,
    },
    rootMenuItemText: {
        paddingLeft: 0,
        color: "rgb(66,66,66)",
        fontSize: 14,
    },
    rootMenuSubItem: {
        paddingLeft: 56,
    },
    rootMenuSubItemText: {
        color: "rgb(66,66,66)",
        fontSize: 14,
    },
    adminRoot: {
        backgroundColor: "#f5f5f5",
        borderTop: "1px solid #eee",
        fontSize: 14,
        paddingTop: 16,
        paddingRight: 50,
        paddingBottom: 16,
        paddingLeft: 16,
    },
    adminTitlePrimary: {
        fontSize: 14,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    adminTitleSecondary: {
        fontSize: 14,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    mainAppBar: {
        backgroundColor: "#fff",
        boxShadow: "rgba(204, 204, 204, 0.12) 0px 1px 6px, rgba(204, 204, 204, 0.12) 0px 1px 4px",
    },
    mainAppBarIcon: {
        color: "#949494",
        marginRight: 10,
        marginLeft: -16,
    },
    mainAppBarTitle: {
        fontSize: 20,
    },
};
