import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./Form";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import actionCreators from "../../../store/OfficeHours/Administrators/actionCreators";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";

class OfficeHoursAdministratorsNewContainer extends Component {
    state = {
        initialAcademics: null,
    };

    componentDidMount() {
        const { setTitle } = this.props;
        setTitle("Bookings \u203A Administrators");
    }

    componentDidUpdate() {
        const { initialValues } = this.props;
        const { initialAcademics } = this.state;

        if (initialValues && !initialAcademics) {
            this.setState({ initialAcademics: { ...initialValues } });
        }
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    onSubmit = values => {
        this.props.postOfficeHoursAdministrator(values);
    };

    render() {
        const {
            history: { push },
            users,
            academics,
            errorRes: { error, errors },
            getUsers,
            getAcademics,
            clearStaffUsers,
            clearOfficeHoursAcademics,
            admins,
            getAdmins,
            clearAdmins,
        } = this.props;
        const { onSubmit } = this;
        const { initialAcademics } = this.state;
        const currentFormType = CurrentFormTypes.NEW;

        return initialAcademics ? (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Add New Bookings Administrator</h2>
                        <Form
                            {...{
                                initialValues: initialAcademics,
                                onSubmit,
                                currentFormType,
                                push,
                                users,
                                academics,
                                getUsers,
                                getAcademics,
                                clearUsers: clearStaffUsers,
                                clearAcademics: clearOfficeHoursAcademics,
                                errors,
                                formError: error,
                                admins,
                                getAdmins,
                                clearAdmins,
                            }}
                        />
                    </div>
                </Paper>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getUsers: AutoCompleteActionCreators.getStaffUsers.create,
    getAcademics: AutoCompleteActionCreators.getOfficeHoursAcademics.create,
    clearStaffUsers: AutoCompleteActionCreators.clearStaffUsers.create,
    clearOfficeHoursAcademics: AutoCompleteActionCreators.clearOfficeHoursAcademics.create,
    postOfficeHoursAdministrator: actionCreators.postOfficeHoursAdministrator.create,
    getAdmins: AutoCompleteActionCreators.getOfficeHoursAdministrators.create,
    clearAdmins: AutoCompleteActionCreators.clearOfficeHoursAdministrators.create,
};
const mapStateToProps = ({ OfficeHoursAdministrators, AutoComplete }) => ({
    initialValues: { user: {}, academics: [] },
    errorRes: OfficeHoursAdministrators.errorRes,
    users: AutoComplete.staff,
    academics: AutoComplete.officehoursacademics,
    admins: AutoComplete.officehoursadministrators,
});
export default connect(mapStateToProps, dispatchToProps)(OfficeHoursAdministratorsNewContainer);
