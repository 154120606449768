/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { FC, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    InputBase,
    TextField,
    MenuItem,
} from "@material-ui/core";
import toolbarCss from "../../../common/styles/toolbar.css";
import { DatePicker } from "material-ui-pickers";
import datepickerCss from "../../../common/styles/datepicker.css";
import { ClearIcon } from "../../../common/components/icons";
import { notificationSenderMap } from "../../../common/services/tableCallbacks";
import AutoCompleteInline from "src/common/components/Downshift/AutoCompleteInline";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { default as autoCompleteActionCreators } from "src/store/AutoComplete/actionCreators";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import { LseModule } from "../types/TeachingDetails";

interface OwnProps {
    from?: Date;
    to?: Date;
    filter?: string;
    sender?: string;
    module?: LseModule;
    onFilterChangeHandler: any;
    onDateChangeHandler: any;
    onModuleChangeHandler: any;
    classes: any;
}

const TimetableNotificationsFilterRow: FC<OwnProps> = ({
    from,
    to,
    filter,
    sender,
    module,
    onFilterChangeHandler,
    onDateChangeHandler,
    onModuleChangeHandler,
    classes,
}) => {
    const dispatch = useDispatch();
    const [moduleSearch$, setModuleSearch$] = useState(new Subject());
    const [modulesSubscription, setModulesSubscription] = useState<Subscription | undefined>(
        undefined
    );
    const modules = useSelector((state: AppState) => state.AutoComplete.lseModules);
    const getModules = params => dispatch(autoCompleteActionCreators.getLseModules.create(params));
    const clearModules = () => dispatch(autoCompleteActionCreators.clearLseModules.create());

    useEffect(() => {
        setModulesSubscription(
            moduleSearch$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                if (term !== "") {
                    getModules({ filter: term });
                } else {
                    clearModules();
                    onModuleChangeHandler({ teachingCode: "", teachingTitle: "" });
                }
            })
        );

        return () => {
            if (modulesSubscription) {
                modulesSubscription.unsubscribe();
                setModulesSubscription(undefined);
            }
        };
    }, []);

    const AutoCompleteInlineJS = AutoCompleteInline as any;

    return (
        <AppBar
            position="static"
            classes={{
                root: classes.appBarRoot,
            }}
            elevation={0}
        >
            <Toolbar
                classes={{
                    root: classes.toolbarRootBorder,
                }}
            >
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Keyword or ID:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Filter:</span>
                        </span>
                    </Typography>
                    <InputBase
                        placeholder="..."
                        value={filter || ""}
                        onChange={onFilterChangeHandler("filter")}
                        fullWidth
                        autoComplete="off"
                    />
                </div>
                <div css={toolbarCss.toolbarSeparator} />
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Sender:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Sender:</span>
                        </span>
                    </Typography>
                    <TextField
                        select
                        margin="none"
                        color="primary"
                        fullWidth
                        onChange={onFilterChangeHandler("sender")}
                        value={sender || ""}
                        InputProps={{
                            classes: {
                                root: classes.inputRoot,
                                error: classes.inputError,
                            },
                        }}
                        SelectProps={{
                            displayEmpty: true,
                            MenuProps: {
                                MenuListProps: {
                                    disablePadding: true,
                                    dense: true,
                                },
                            },
                        }}
                    >
                        <MenuItem value="">All Senders</MenuItem>
                        {Object.entries(notificationSenderMap).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div css={toolbarCss.toolbarSeparator} />
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Date:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Date:</span>
                        </span>
                    </Typography>
                    <div css={datepickerCss.datepickerContainer}>
                        <DatePicker
                            placeholder="From..."
                            value={from ? from : null}
                            onChange={value =>
                                onDateChangeHandler("from")(
                                    value.set({ hour: 0, minute: 0, second: 0 })
                                )
                            }
                            css={toolbarCss.toolbarDatePicker}
                            fullWidth={true}
                            autoOk={true}
                            format={"D MMM YYYY"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                if (from) onDateChangeHandler("from")(null);
                            }}
                            css={datepickerCss.datepickerBtn}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                    <div css={datepickerCss.datepickerContainer}>
                        <DatePicker
                            placeholder="To..."
                            value={to ? to : null}
                            onChange={value => {
                                onDateChangeHandler("to")(
                                    value.set({ hour: 23, minute: 59, second: 59 })
                                );
                            }}
                            css={toolbarCss.toolbarDatePicker}
                            fullWidth={true}
                            autoOk={true}
                            format={"D MMM YYYY"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                if (from) onDateChangeHandler("to")(null);
                            }}
                            css={datepickerCss.datepickerBtn}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                </div>
            </Toolbar>
            <Toolbar
                classes={{
                    root: classes.toolbarRoot,
                }}
            >
                <div
                    css={[
                        toolbarCss.toolbarGroup,
                        toolbarCss.toolbarGroupStretch,
                        toolbarCss.toolbarGroupAlignCenter,
                    ]}
                >
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Module:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Module:</span>
                        </span>
                    </Typography>

                    <AutoCompleteInlineJS
                        single={true}
                        selectedItems={module}
                        dataSource={modules}
                        onUpdateInput={value => {
                            if (value) {
                                return moduleSearch$.next(value);
                            }
                        }}
                        onBlur={() => clearModules()}
                        onChange={onModuleChangeHandler}
                        labelCallback={item => `${item.teachingCode} ${item.teachingTitle}`}
                        multiline
                        fullWidth
                    />
                    <IconButton
                        onClick={() => {
                            if (module && !!module.teachingCode)
                                onModuleChangeHandler({ teachingCode: "", teachingTitle: "" });
                        }}
                        css={datepickerCss.datepickerBtn}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default withStyles(toolbarCss)(TimetableNotificationsFilterRow);
