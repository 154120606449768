import React from "react";

export default {
    times: {
        svg: (
            <path d="M24 1.186L22.814 0 12 10.814 1.186 0 0 1.186 10.814 12 0 22.814 1.186 24 12 13.186 22.814 24 24 22.814 13.186 12z" />
        ),
    },
    select: {
        svg: <path d="M4.159 7.841L12 0l7.841 7.841H4.16zm0 8.318H19.84L12 24l-7.841-7.841z" />,
    },
    help: {
        svg: (
            <path d="M12 5.333c-2.67 0-4.02 2.469-4.02 4h1.67C9.65 8.669 10.35 7 12 7c2.1 0 2.33 1.626 2.33 2.324 0 .722-.55 1.284-1.45 2.121-.9.825-1.91 1.761-1.91 3.204v.039h1.67v-.039C12.64 13.022 16 12 16 9.324c0-2.096-1.34-3.991-4-3.991m0 14.333c-.4 0-.83-.025-1.34-.08l-.76-.078-.55.52C8.6 20.728 7.82 21.376 7 22v-3.332l-.86-.476c-2.8-1.566-4.48-4.13-4.48-6.859C1.66 6.738 6.3 3 12 3s10.33 3.738 10.33 8.333c0 4.595-4.63 8.333-10.33 8.333m0-18.333c-6.63 0-12 4.476-12 10 0 3.467 2.12 6.52 5.33 8.313v3.012c0 .786.65 1.342 1.34 1.342.26 0 .52-.078.76-.248a29.616 29.616 0 0 0 3.05-2.507c.5.052 1 .088 1.52.088 6.63 0 12-4.476 12-10s-5.37-10-12-10m-.21 13.987c-.66 0-1.2.537-1.2 1.2 0 .662.54 1.2 1.2 1.2.67 0 1.2-.538 1.2-1.2 0-.663-.53-1.2-1.2-1.2" />
        ),
    },
};
