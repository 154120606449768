/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import { sidebarMenuStyle } from "./SidebarMenuStyles";

const SidebarMenu = ({ children, style }) => (
    <ul css={[sidebarMenuStyle.body, style]}>{children}</ul>
);

const SidebarMenuItem = ({ to, children, isActive, isExternal }) => (
    <li css={sidebarMenuStyle.item}>
        {isExternal ? (
            <div
                onClick={() => window.open(to, "_blank")}
                css={[sidebarMenuStyle.link, isActive && sidebarMenuStyle.linkActive]}
            >
                {children}
            </div>
        ) : (
            <Link
                to={to}
                css={{ ...sidebarMenuStyle.link, ...(isActive && sidebarMenuStyle.linkActive) }}
            >
                {children}
            </Link>
        )}
    </li>
);

export { SidebarMenu, SidebarMenuItem };
