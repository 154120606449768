import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { MainTitle } from "../../../common/components/MainTitle";
import Form from "./TemplateForm";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";
import { MEETING_ZOOM, MEETING_CUSTOM, MEETING_NONE } from "./types";

class OfficeHoursDefaultAvailabilityContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            getOneMyOfficeHoursAcademic,
            getOfficeHoursDefaultAvailability,
            match,
            academic,
        } = this.props;
        setTitle("Bookings \u203A Manage Availability");
        if (!academic) getOneMyOfficeHoursAcademic(match.params.userId);
        getOfficeHoursDefaultAvailability(match.params.userId);
    }

    componentWillUnmount() {
        const { clearOfficeHoursDefaultAvailability, clearTitle } = this.props;
        clearTitle();
        clearOfficeHoursDefaultAvailability();
    }

    onSubmit = values => {
        const { match, putOfficeHoursDefaultAvailability } = this.props;

        const availability = {
            settings: {
                ...values.settings,
                maxNumberOfAttendees: values.settings.maxNumberOfAttendees
                    ? Number(values.settings.maxNumberOfAttendees)
                    : null,
                maxAppointmentsPerSession: values.settings.maxAppointmentsPerSession
                    ? Number(values.settings.maxAppointmentsPerSession)
                    : null,
                allowFileUpload: values.settings.allowFileUpload ? true : false,
                requireBookingNote: values.settings.requireBookingNote ? true : false,
                receiveSummaryEmailAheadUpcomingAppointments: !values.settings
                    .receiveSummaryEmailAheadUpcomingAppointments,
                ...(!values.settings.receiveSummaryEmailAheadUpcomingAppointments
                    ? {
                          atEightOnSessionDay: !!values.settings.atEightOnSessionDay,
                          timeInAdvance:
                              !!values.settings.timeInAdvance &&
                              !!values.settings.timeInAdvanceValue
                                  ? Number(values.settings.timeInAdvanceValue)
                                  : null,
                          summaryAtTime:
                              !!values.settings.summaryAtTime &&
                              !!values.settings.summaryAtTimeValue
                                  ? Number(values.settings.summaryAtTimeValue)
                                  : null,
                          atLatestBookingTime: !!values.settings.atLatestBookingTime,
                      }
                    : {
                          timeInAdvance: null,
                          summaryAtTime: null,
                      }),
            },
            maxAppointmentsPerUserPerTerm: values.maxAppointmentsPerUserPerTerm
                ? Number(values.maxAppointmentsPerUserPerTerm)
                : null,
        };

        putOfficeHoursDefaultAvailability({
            id: match.params.userId,
            availability,
        });
    };

    render() {
        const {
            history: { push },
            academic,
            initialValues,
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;

        return academic ? (
            <div>
                <MainTitle
                    title={`${academic.firstName} ${academic.lastName}`}
                    type={"[Default Settings]"}
                />
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Your Default Availability Settings</h2>
                        <Form
                            {...{
                                initialValues: initialValues
                                    ? {
                                          ...initialValues,
                                          settings: {
                                              ...initialValues.settings,
                                              ...(initialValues.settings.timeInAdvance > 0
                                                  ? {
                                                        timeInAdvance: true,
                                                        timeInAdvanceValue:
                                                            initialValues.settings.timeInAdvance,
                                                    }
                                                  : {
                                                        timeInAdvance: false,
                                                    }),
                                              ...(initialValues.settings.summaryAtTime > 0
                                                  ? {
                                                        summaryAtTime: true,
                                                        summaryAtTimeValue:
                                                            initialValues.settings.summaryAtTime,
                                                    }
                                                  : {
                                                        summaryAtTime: false,
                                                    }),
                                              onlineMeetings:
                                                  !initialValues.settings.meetingUrl &&
                                                  !!initialValues.settings.integrateWithZoom
                                                      ? MEETING_ZOOM
                                                      : !!initialValues.settings.meetingUrl &&
                                                        !initialValues.settings.integrateWithZoom
                                                      ? MEETING_CUSTOM
                                                      : MEETING_NONE,
                                          },
                                      }
                                    : null,
                                onSubmit,
                                push,
                                errors,
                                formError: error,
                                academicId: academic.id,
                            }}
                        />
                    </div>
                </Paper>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneMyOfficeHoursAcademic: actionCreators.getOneMyOfficeHoursAcademic.create,
    getOfficeHoursDefaultAvailability: actionCreators.getOfficeHoursDefaultAvailability.create,
    putOfficeHoursDefaultAvailability: actionCreators.putOfficeHoursDefaultAvailability.create,
    clearOfficeHoursDefaultAvailability: actionCreators.clearOfficeHoursDefaultAvailability.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities }) => ({
    academic: OfficeHoursAvailabilities.academic,
    errorRes: OfficeHoursAvailabilities.errorRes,
    initialValues: OfficeHoursAvailabilities.availabilityTemplate,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursDefaultAvailabilityContainer);
