import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/Channels/Posts/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as froalaActionCreators } from "../../../store/Froala/actionCreators";
import { default as autocompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import Form from "./Form";
import currentFormTypes from "../../../common/components/CurrentFormTypes";
import initialPostValues from "../../../fixtures/ChannelPost";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../common/styles/forms.css";

class ChannelNewPostContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            match: {
                params: { id },
            },
            setCurrentFormType,
            cleanFroalaImages,
        } = this.props;
        const type = id ? currentFormTypes.EDIT : currentFormTypes.NEW;
        setCurrentFormType(type);
        cleanFroalaImages();
        setTitle("Channels \u203A Pages");
    }

    componentWillUnmount() {
        const { clearTitle, rollbackFroalaChanges } = this.props;
        clearTitle();
        // remove added and not saved images
        rollbackFroalaChanges();
    }

    onSubmit = values => {
        const { postPost, hasArticle } = this.props;
        postPost({
            post: {
                ...values,
                article: hasArticle ? values.article : null,
                imageFile:
                    values.imageFile && values.imageFile.base64 !== "" ? values.imageFile : null,
            },
        });
    };

    render() {
        const {
            currentFormType,
            initialValues,
            history: { goBack },
            channelPostTypes,
            hasArticle,
            hasDate,
            setDateFieldStatus,
            channels,
            getChannels,
            clearChannels,
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;

        return (
            <div>
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    initialValues={{
                        ...initialValues,
                        status: "draft",
                        channel:
                            this.props.history.location.state &&
                            !!this.props.history.location.state.id
                                ? this.props.history.location.state
                                : undefined,
                    }}
                    {...{
                        onSubmit,
                        currentFormType,
                        goBack,
                        channelPostTypes,
                        hasArticle,
                        hasDate,
                        setDateFieldStatus,
                        channels,
                        getChannels,
                        clearChannels,
                        errors,
                    }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOnePost: actionCreators.getOnePost.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
    postPost: actionCreators.postPost.create,
    setDateFieldStatus: actionCreators.setDateFieldStatus.create,
    cleanFroalaImages: froalaActionCreators.cleanFroalaImages.create,
    rollbackFroalaChanges: froalaActionCreators.rollbackFroalaChanges.create,
    getChannels: autocompleteActionCreators.getChannels.create,
    clearChannels: autocompleteActionCreators.clearChannels.create,
};
const mapStateToProps = ({
    ChannelPosts: { errorRes, currentFormType, hasArticle, hasDate },
    AutoComplete: { channels },
}) => ({
    initialValues: initialPostValues,
    errorRes,
    currentFormType,
    hasArticle,
    hasDate,
    channels,
});

export default connect(mapStateToProps, dispatchToProps)(ChannelNewPostContainer);
