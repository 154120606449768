import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/NotificationBanner/actionCreators";
import mainMenuActionCreators from "../../../../store/MainMenu/actionCreators";
import { MainTitle } from "../../../../common/components/MainTitle";
import formsCss from "../../../../common/styles/forms.css";
import NotificationBannerForm from "../components/NotificationBannerForm";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class NotificationBannerAddContainer extends Component {
    componentDidMount() {
        const { setTitle } = this.props;
        setTitle("Notifications");
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    formSubmitHandler = values => {
        this.props.postNotification({
            ...values,
            isDisplayedContinuously: values.isDisplayedContinuously === "true" ? true : false,
        });
    };

    render() {
        const {
            notification,
            history: { push },
            errorRes: { error, errors },
        } = this.props;

        return (
            <div>
                <MainTitle title="Add Calendar Banner" />
                <div css={formsCss.genericError}>{error}</div>
                <NotificationBannerForm
                    submitText="Add Calendar Banner"
                    initialValues={{
                        ...notification,
                        isDisplayedContinuously: notification.isDisplayedContinuously
                            ? "true"
                            : "false",
                    }}
                    onSubmit={this.formSubmitHandler}
                    {...{ errors, push }}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ NotificationBanner: { errorRes, notification } }) => ({
    errorRes,
    notification,
});

const dispatchToProps = {
    postNotification: actionCreators.postNotificationBanner.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    setTitle: mainMenuActionCreators.setTitle.create,
};

export default connect(mapStateToProps, dispatchToProps)(NotificationBannerAddContainer);
