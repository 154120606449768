import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/Channels/Events/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as autocompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import Form from "./Form";
import currentFormTypes from "../../../common/components/CurrentFormTypes";
import { change } from "redux-form";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../common/styles/forms.css";

class ChannelEditEventContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            getOneEvent,
            match: {
                params: { id },
            },
            setCurrentFormType,
        } = this.props;
        const type = id ? currentFormTypes.EDIT : currentFormTypes.NEW;
        setCurrentFormType(type);
        setTitle("Channels \u203A Pages");
        getOneEvent({ id });
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    onSubmit = values => {
        const { putEvent } = this.props;
        putEvent({
            event: {
                ...values,
                imageFile:
                    values.imageFile && values.imageFile.base64 !== "" ? values.imageFile : null,
            },
        });
    };

    render() {
        const {
            currentFormType,
            initialValues,
            history: { goBack },
            channelPostTypes,
            errorRes: { error, errors },
            formValues,
            setDateFieldStatus,
            hasDate,
            channels,
            getChannels,
            clearChannels,
            changeStartAt,
            changeEndAt,
        } = this.props;
        const { onSubmit } = this;

        return (
            <div>
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    initialValues={initialValues}
                    {...{
                        onSubmit,
                        currentFormType,
                        goBack,
                        channelPostTypes,
                        formValues,
                        errors,
                        setDateFieldStatus,
                        hasDate,
                        channels,
                        getChannels,
                        clearChannels,
                        changeStartAt,
                        changeEndAt,
                    }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneEvent: actionCreators.getOneEvent.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
    putEvent: actionCreators.putEvent.create,
    setDateFieldStatus: actionCreators.setDateFieldStatus.create,
    getChannels: autocompleteActionCreators.getChannels.create,
    clearChannels: autocompleteActionCreators.clearChannels.create,
    changeEndAt: newValue => change("Event", "endAt", newValue), // reset endAt value
    changeStartAt: newValue => change("Event", "startAt", newValue), // reset startAt value
};
const mapStateToProps = ({
    ChannelEvents: { event, currentFormType, errorRes, hasDate },
    AutoComplete: { channels },
    form: { Event },
}) => ({
    initialValues: event,
    errorRes,
    hasDate,
    currentFormType,
    formValues: Event,
    channels,
});

export default connect(mapStateToProps, dispatchToProps)(ChannelEditEventContainer);
