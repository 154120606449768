import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";

const categoryStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListCategory.type,
            actionCreators.loadMoreCategories.type,
            actionCreators.getOneCategory.type,
            actionCreators.postCategory.type,
            actionCreators.moveDownCategory.type,
            actionCreators.moveUpCategory.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const categoryClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListCategory.type,
            actionCreators.updateListCategory.type,
            actionCreators.updateOneCategory.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListCategoriesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListCategory.type),
        switchMap(action =>
            ajax
                .get(ajax.apiUrl(`admin/how-to-category/?${action.payload ? action.payload : ""}`))
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createListCategory.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreCategoriesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreCategories.type),
        switchMap(action =>
            ajax
                .get(ajax.apiUrl(`admin/how-to-category/?${action.payload ? action.payload : ""}`))
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListCategory.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneCategoryEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneCategory.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`admin/how-to-category/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOneCategory.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newCategoryEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postCategory.type),
        switchMap(action =>
            ajax
                .post(ajax.apiUrl("admin/how-to-category/"), action.payload)
                .pipe(
                    mapTo(actionCreators.pushListCategories.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const editCategoryEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putCategory.type),
        switchMap(action =>
            ajax
                .put(ajax.apiUrl(`admin/how-to-category/${action.payload.id}/`), action.payload)
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.pushListCategories.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const pushListCategoriesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListCategories.type),
        mapTo(push(`/knowledge-base/categories/`))
    );

const removeCategoryEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeCategory.type),
        switchMap(action =>
            ajax.remove(ajax.apiUrl(`admin/how-to-category/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.getListCategory.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const moveUpCategoryEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.moveUpCategory.type),
        switchMap(action =>
            ajax
                .patch(
                    ajax.apiUrl(`admin/how-to-category/${action.payload.id}/up/`),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) => of(actionCreators.getListCategory.create(response))),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const moveDownCategoryEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.moveDownCategory.type),
        switchMap(action =>
            ajax
                .patch(
                    ajax.apiUrl(`admin/how-to-category/${action.payload.id}/down/`),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) => of(actionCreators.getListCategory.create(response))),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    categoryStartLoadingEpic,
    categoryClearLoadingEpic,
    getListCategoriesEpic,
    loadMoreCategoriesEpic,
    getOneCategoryEpic,
    newCategoryEpic,
    editCategoryEpic,
    pushListCategoriesEpic,
    removeCategoryEpic,
    moveUpCategoryEpic,
    moveDownCategoryEpic
);
