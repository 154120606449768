import { fontWeight } from "./typography";
import { spacing } from "./spacing";
import { colors } from "./colors";
import * as CSS from "csstype";
import { StyleRules } from "@material-ui/core/styles";

// const rwdRow = {
//     display: "block",
//     border: "0 none",
//     width: "100%",
//     height: "auto",
//     padding: "2px 15px",
//     textAlign: "left",
// };

const textOverflow = {
    overflow: "hidden",
    whiteSpace: "nowrap" as CSS.WhiteSpaceProperty,
    textOverflow: "ellipsis",
};

const style: StyleRules<string> = {
    rootTable: {
        maxWidth: "100%",
        width: "100%",
        tableLayout: "fixed",
        // whiteSpace: "nowrap",
        "@media (max-width:767px)": {
            display: "block",
        },
    },
    rootTableHeader: {},
    rootTableBody: {
        overflow: "visible",
        width: "100%",
        "@media (max-width:767px)": {
            display: "block",
        },
    },
    showOnMobile: {
        display: "none",
        "@media (max-width:767px)": {
            display: "inline",
        },
    },
    hideOnMobile: {
        "@media (max-width:767px)": {
            display: "none",
        },
    },
    tableRow: {
        // ...textOverflow,
        // [theme.breakpoints.down("xs")]: rwdRow,
    },

    id: {
        width: 56,
        borderRight: "1px solid #e0e0e0",
        padding: 0,
        textAlign: "center",
        whiteSpace: "nowrap",
        "@media (max-width:767px)": {
            width: "auto",
            textAlign: "left",
            padding: "2px 15px",
            borderRight: "0 none",
        },
    },

    /*
     * START of custom styles
     */

    img: {
        width: 150,
        textAlign: "center",
        "@media (max-width:767px)": {
            width: "auto",
            textAlign: "left",
        },
    },
    status: {
        width: 150,
        textAlign: "left",
        "@media (max-width:767px)": {
            width: "auto",
            textAlign: "left",
        },
    },
    cleanedDescription: {
        ...textOverflow,
    },
    description: {
        ...textOverflow,
    },

    /*
     * END of custom styles
     */

    eventItem: {
        display: "block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",

        "@media (max-width:767px)": {
            fontWeight: fontWeight.medium as CSS.FontWeightProperty,
            textOverflow: "unset",
            overflow: "visible",
            whiteSpace: "normal",
        },
    },
    eventItemDate: {
        display: "block",
    },
    calendarItem: {
        display: "block",

        "@media (max-width:767px)": {
            display: "inline",
        },
    },
    calendarSourceItem: {
        display: "block",
        fontSize: 10,
        opacity: 0.7,
        marginTop: spacing.space0,
        "@media (max-width:767px)": {
            display: "inline",
        },
    },
    audienceItem: {
        display: "block",
    },

    actions: {
        width: 96,
        paddingRight: 8,
        paddingLeft: 8,
        textAlign: "right",

        "@media (max-width:767px)": {
            width: 48,
            padding: 0,
            position: "absolute",
            top: "50%",
            right: 4,
            transform: "translateY(-50%)",
        },
    },
    actionsMenu: {
        width: 150,
    },
    actionsMenuWide: {
        width: 200,
    },
    actionsMenuList: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    actionsMenuItem: {
        fontSize: 14,
        minHeight: 36,
        lineHeight: "36px",
    },
    actionsMenuItemInner: {
        paddingLeft: 52,
    },
    actionsMenuItemIcon: {
        width: 20,
        height: 20,
        // marginTop: 8,
        // marginBottom: 8,
    },
    loadMore: {
        marginTop: 25,
        textAlign: "left",

        "@media (max-width:767px)": {
            textAlign: "center",
        },
    },
    loadMoreLabel: {
        marginLeft: 15,
        opacity: ".5",
        fontSize: 15,
        fontStyle: "italic",

        "@media (max-width:767px)": {
            display: "block",
            textAlign: "center",
            margin: "5px 0 0",
        },
    },
    contextMenuPaper: {
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: "pink",
    },
    contextMenuItem: {
        paddingTop: 6,
        paddingBottom: 6,
    },
    contextMenuItemText: {
        paddingLeft: 0,
        color: "rgb(66,66,66)",
        fontSize: 14,
    },
    buttonIcon: {},
    dateTimeExtended: {
        width: 480,
    },
    numStudents: {
        width: 120,
        // textAlign: "center",
    },
    audienceType: {
        width: 200,
    },
    columnWidth100: {
        width: 100,
    },
    columnWidth120: {
        width: 120,
    },
    columnWidth150: {
        width: 150,
    },
    columnWidth180: {
        width: 180,
    },
    columnWidth200: {
        width: 200,
    },
    columnWidth250: {
        width: 250,
    },
    columnWidth300: {
        width: 300,
    },
    columnWidth350: {
        width: 350,
    },
    columnWidth400: {
        width: 400,
    },
};

export default style;

export const sideLinkCss = {
    body: {
        display: "flex",
        alignItems: "center",
    },
    sectionIcon: {
        color: colors.black42,
        fontSize: 18,
        display: "flex",
        alignItems: "center",
        marginRight: 12,
    },
    linkIcon: {
        fontSize: 18,
        display: "flex",
        alignItems: "center",
        marginRight: 12,
        marginTop: -2,
    },
};
