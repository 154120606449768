import { Dictionary } from "src/types/generic";
import actionCreators from "./actionCreators";

export interface AutoCompleteOption {
    id: string | number;
    name?: string;
    label?: string;
}

export interface AutoCompleteState {
    errorRes: { error: string; errors: Dictionary<string> };
    staff: AutoCompleteOption[];
    students: AutoCompleteOption[];
    users: AutoCompleteOption[];
    audiences: AutoCompleteOption[];
    pages: AutoCompleteOption[];
    posts: AutoCompleteOption[];
    events: AutoCompleteOption[];
    officehoursacademics: AutoCompleteOption[];
    officehoursadministrators: AutoCompleteOption[];
    officeHoursTeams: AutoCompleteOption[];
    lseModules: AutoCompleteOption[];
    channels: AutoCompleteOption[];
}

export const initialState = {
    staff: [],
    students: [],
    users: [],
    audiences: [],
    pages: [],
    posts: [],
    events: [],
    officehoursacademics: [],
    officehoursadministrators: [],
    officeHoursTeams: [],
    lseModules: [],
    channels: [],
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.receivedStaffUsers.type:
            return {
                ...state,
                staff: payload,
            };
        case actionCreators.clearStaffUsers.type:
            return {
                ...state,
                staff: [],
            };
        case actionCreators.receivedStudentUsers.type:
            return {
                ...state,
                students: payload,
            };
        case actionCreators.clearStudentUsers.type:
            return {
                ...state,
                students: [],
            };
        case actionCreators.receivedAllUsers.type:
            return {
                ...state,
                users: payload,
            };
        case actionCreators.clearAllUsers.type:
            return {
                ...state,
                users: [],
            };
        case actionCreators.receivedPages.type:
            return {
                ...state,
                pages: payload,
            };
        case actionCreators.clearPages.type:
            return {
                ...state,
                pages: [],
            };
        case actionCreators.receivedPosts.type:
            return {
                ...state,
                posts: payload,
            };
        case actionCreators.clearPosts.type:
            return {
                ...state,
                posts: [],
            };
        case actionCreators.receivedEvents.type:
            return {
                ...state,
                events: payload,
            };
        case actionCreators.clearEvents.type:
            return {
                ...state,
                events: [],
            };
        case actionCreators.receivedAudiences.type:
            return {
                ...state,
                audiences: payload,
            };
        case actionCreators.clearAudiences.type:
            return {
                ...state,
                audiences: [],
            };
        case actionCreators.receivedChannels.type:
            return {
                ...state,
                channels: payload,
            };
        case actionCreators.clearChannels.type:
            return {
                ...state,
                channels: [],
            };
        case actionCreators.receivedOfficeHoursAcademics.type:
            return {
                ...state,
                officehoursacademics: payload,
            };
        case actionCreators.clearOfficeHoursAcademics.type:
            return {
                ...state,
                officehoursacademics: [],
            };
        case actionCreators.receivedOfficeHoursAdministrators.type:
            return {
                ...state,
                officehoursadministrators: payload,
            };
        case actionCreators.clearOfficeHoursAdministrators.type:
            return {
                ...state,
                officehoursadministrators: [],
            };
        case actionCreators.setOfficeHoursTeams.type:
            return {
                ...state,
                officeHoursTeams: payload,
            };
        case actionCreators.setLseModules.type:
            return {
                ...state,
                lseModules: payload,
            };
        case actionCreators.clearLseModules.type:
            return {
                ...state,
                lseModules: initialState.lseModules,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
