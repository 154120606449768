import Button from "@material-ui/core/Button";
/** @jsx jsx */
import { jsx } from "@emotion/core";

export const RedirectButton = ({
    label,
    url,
    urlState,
    push,
    className,
    style,
    variant,
    color,
    elementLeft,
    onClick,
}) => (
    <Button
        onClick={() =>
            onClick && !url
                ? onClick()
                : urlState
                ? push({
                      pathname: url,
                      state: urlState,
                  })
                : push(url)
        }
        variant={variant ? variant : "contained"}
        color={color ? color : "primary"}
        css={style}
        {...{ className }}
    >
        {elementLeft && elementLeft}
        {label}
    </Button>
);
