// eslint-disable-next-line no-unused-vars
import React from "react";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import toolbarCss from "../../../../common/styles/toolbar.css";
// import formsCss from "../../../common/styles/forms.css";
import { withStyles } from "@material-ui/core/styles";
import { USER_TYPE_STANDARD, USER_TYPE_MANAGER, USER_TYPE_ADMIN } from "../values";
import { AppBar, InputBase, MenuItem, Toolbar, Typography, TextField } from "@material-ui/core";

export default withStyles(toolbarCss)(
    ({ onFilterChangeHandler, filter, permission, classes, context }) => (
        <AppBar
            position="static"
            classes={{
                root: classes.appBarRoot,
            }}
            elevation={0}
        >
            <Toolbar
                classes={{
                    root: classes.toolbarRoot,
                }}
            >
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>
                                Filter by keyword or ID:
                            </span>
                            <span css={toolbarCss.labelShowOnMobile}>Filter:</span>
                        </span>
                    </Typography>

                    <InputBase
                        placeholder="..."
                        value={filter || ""}
                        onChange={onFilterChangeHandler("filter")}
                        fullWidth
                        autoComplete="off"
                    />
                </div>
                <div css={toolbarCss.toolbarSeparator} />
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Filter by type:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Type:</span>
                        </span>
                    </Typography>
                    <TextField
                        select
                        margin="none"
                        color="primary"
                        fullWidth={context === "page"}
                        onChange={onFilterChangeHandler("permission")}
                        value={permission}
                        InputProps={{
                            classes: {
                                root: classes.inputRoot,
                                error: classes.inputError,
                            },
                        }}
                        SelectProps={{
                            displayEmpty: true,
                            MenuProps: {
                                MenuListProps: {
                                    disablePadding: true,
                                    dense: true,
                                },
                            },
                        }}
                    >
                        <MenuItem value="">All</MenuItem>

                        <MenuItem value={USER_TYPE_STANDARD}>Regular Users</MenuItem>
                        <MenuItem value={USER_TYPE_MANAGER}>Managers</MenuItem>
                        <MenuItem value={USER_TYPE_ADMIN}>Admins</MenuItem>
                    </TextField>
                </div>
            </Toolbar>
        </AppBar>
    )
);
