export const mergeByIds = (data, payload) => {
    const idMap = {};
    const results = [...data];

    if (results.length > 0) {
        results.forEach((obj, index) => {
            idMap[obj.id] = index;
        });

        payload.forEach(obj => {
            if (obj.id in idMap) {
                // update object
                results[idMap[obj.id]] = obj;
            } else {
                idMap[obj.id] = results.length;
                results.push(obj);
            }
        });
    }

    return results;
};
