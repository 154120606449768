import { withStyles, Paper } from "@material-ui/core";
import Downshift from "downshift";
import { AvatarChip } from "../AvatarChip";
import { renderRawInput, renderSuggestion } from "./helpers";
import { styles } from "./styles";
import globalsCss from "../../styles/globals.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";

const AutoCompleteInline = ({
    classes,
    selectedItems,
    dataSource,
    labelCallback,
    numCallback,
    single,
    noAvatar,
    onBlur,
    onUpdateInput,
    onChange,
    onNewRequest,
    multiline,
}) => {
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setInputValue(
            !single || !selectedItems || !selectedItems.id ? "" : labelCallback(selectedItems)
        );
    }, []);

    useEffect(() => {
        setInputValue(
            !single || !selectedItems || !selectedItems.id ? "" : labelCallback(selectedItems)
        );
    }, [selectedItems]);

    const handleInputChange = event => {
        setInputValue(event.target.value);
        onUpdateInput(event.target.value);
    };

    const handleNewRequest = selectedItems => {
        onNewRequest && onNewRequest(selectedItems);
        onChange(selectedItems);
    };

    const handleChange = item => {
        if (!item) return;

        if (single) {
            setInputValue("");
            handleNewRequest(item);
        } else {
            setInputValue("");
            if (selectedItems.map(obj => obj.id).indexOf(item.id) === -1) {
                handleNewRequest([...selectedItems, item]);
            }
        }
    };

    const handleDelete = item => () => {
        if (single) {
            setInputValue("");
            handleNewRequest(null);
        } else {
            handleNewRequest(selectedItems.filter(obj => obj.id !== item.id));
        }
    };

    return (
        <Downshift
            inputValue={inputValue}
            itemToString={item => (item && item.id ? labelCallback(item) : "")}
            onChange={handleChange}
            selectedItem={selectedItems}
        >
            {({
                getInputProps,
                getItemProps,
                isOpen,
                selectedItem: selectedItem2,
                highlightedIndex,
            }) => (
                <div
                    className={classes.container}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    {single &&
                        // !selectedItems &&
                        renderRawInput({
                            fullWidth: true,
                            autoComplete: "off",
                            ...getInputProps({
                                onChange: handleInputChange,
                                onBlur,
                            }),
                            inputProps: {
                                style: { textOverflow: "ellipsis" },
                            },
                        })}
                    {isOpen ? (
                        <Paper className={classes.paper} square>
                            {dataSource.map((item, index) =>
                                renderSuggestion({
                                    id: item.id,
                                    label: labelCallback(item),
                                    index,
                                    itemProps: getItemProps({ item }),
                                    highlightedIndex,
                                    selectedItem: selectedItem2,
                                    multiline: multiline,
                                })
                            )}
                        </Paper>
                    ) : null}

                    {!single && selectedItems.length > 0 && (
                        <div css={globalsCss.chipsContainer}>
                            {selectedItems.map(item => (
                                <AvatarChip
                                    key={item.id}
                                    onDelete={handleDelete(item)}
                                    name={labelCallback(item)}
                                    numFollowers={numCallback && numCallback(item)}
                                    noAvatar={!!noAvatar}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </Downshift>
    );
};

export default withStyles(styles)(AutoCompleteInline);
