import { NotificationDelivery, MessageType } from "../types/Notification";

export const moduleLabel = module => `${module.teachingCode} ${module.teachingTitle}`;

export const groupLabel = group =>
    `${group.teachingType} ${group.teachingGroup} (${group.count} student${
        group.count != 1 ? "s" : ""
    })`;

export const convertFormToNotification = values => {
    return {
        ...values,
        mobileMessage: values.type === MessageType.EMAIL ? null : values.mobileMessage,
        scheduledAt: values.status === NotificationDelivery.SCHEDULE ? values.scheduledAt : null,
        group: undefined,
        teachings: undefined,
        template: values.template ? { id: values.template } : undefined,
        audience: {
            lseModules: [
                {
                    module: values.module,
                    group:
                        values.group !== "*" && values.selectedGroup
                            ? values.selectedGroup
                            : undefined,
                    teachings: values.selectedGroup
                        ? values.teachings.includes("*")
                            ? values.selectedGroup.teachings.map(({ id }) => id)
                            : values.teachings
                        : undefined,
                },
            ],
        },
    };
};
