import React from "react";
import { Provider } from "react-redux";
import history from "./common/services/history";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import $ from "jquery";
import ErrorFallback from "./common/components/ErrorFallback";
import AppWrapper from "./app/AppWrapper";
import AppSupport from "./support/AppSupport";
import { initBugsnag } from "./common/services/bugsnag";
import { store } from "../src/store";
import { clarity } from "react-microsoft-clarity";

const ErrorBoundary = initBugsnag() as any;

declare global {
    interface Window {
        $: any;
    }
}

window.$ = $;

if (process.env.REACT_APP_MSFT_CLARITY_ID) {
    clarity.init(process.env.REACT_APP_MSFT_CLARITY_ID);
    clarity.consent();
}

const App: React.FunctionComponent = () => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/support" component={AppSupport} />
                    <Route path="/" component={AppWrapper} />
                </Switch>
            </ConnectedRouter>
        </Provider>
    </ErrorBoundary>
);

export default App;
