import ActionCreator from "../actionCreator";

export default {
    getSidelinks: ActionCreator("GET_SIDELINKS"),
    updateSidelinks: ActionCreator("UPDATE_SIDELINKS"),
    deleteSidelink: ActionCreator("DELETE_ONE_SIDELINKS"),
    addSideLinkSection: ActionCreator("ADD_SECTION_SIDELINKS"),
    addSideLinkItem: ActionCreator("ADD_ITEMSIDELINKS"),
    editSideLinkSection: ActionCreator("EDIT_SECTION_SIDELINKS"),
    editSideLinkItem: ActionCreator("EDIT_ITEM_SIDELINKS"),
    moveUpSideLink: ActionCreator("MOVE_UP_SIDELINKS"),
    moveDownSideLink: ActionCreator("MOVE_DOWN_SIDELINKS"),
    clearSideLinks: ActionCreator("CLEAR_SIDELINKS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_SIDELINKS"),
};
