import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Radio } from "@material-ui/core";
import formsCss from "../../styles/forms.css";

const renderRadio = ({ label, value, classes, ...rest }) => (
    <FormControlLabel
        value={value}
        label={label}
        control={<Radio />}
        classes={{
            root: classes.formControlRoot,
            label: classes.radioLabel,
        }}
        {...rest}
    />
);

export const RenderRadio = withStyles(formsCss)(renderRadio);
