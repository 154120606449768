import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./Form";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import actionCreators from "../../../store/OfficeHours/Teams/actionCreators";
import OfficeHoursTeamValues from "../../../fixtures/OfficeHoursTeam";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";

class OfficeHoursManageTeamsNewContainer extends Component {
    componentDidMount() {
        const { setTitle } = this.props;
        setTitle("Bookings \u203A Manage Teams");
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    onSubmit = values => {
        if (values.settings.maxAppointmentsPerSession)
            values.settings.maxAppointmentsPerSession = Number(
                values.settings.maxAppointmentsPerSession
            );
        else values.settings.maxAppointmentsPerSession = null;

        if (values.settings.maxNumberOfAttendees)
            values.settings.maxNumberOfAttendees = Number(values.settings.maxNumberOfAttendees);
        else values.settings.maxNumberOfAttendees = null;

        if (values.maxAppointmentsPerUserPerTerm)
            values.maxAppointmentsPerUserPerTerm = Number(values.maxAppointmentsPerUserPerTerm);
        else values.maxAppointmentsPerUserPerTerm = null;

        if (values.maxAppointmentsPerUserPerDay)
            values.maxAppointmentsPerUserPerDay = Number(values.maxAppointmentsPerUserPerDay);
        else values.maxAppointmentsPerUserPerDay = null;

        if (values.maxAppointmentsPerUserPerWeek)
            values.maxAppointmentsPerUserPerWeek = Number(values.maxAppointmentsPerUserPerWeek);
        else values.maxAppointmentsPerUserPerWeek = null;

        values.settings.receiveSummaryEmailAheadUpcomingAppointments = !values.settings
            .receiveSummaryEmailAheadUpcomingAppointments;

        if (values.settings.receiveSummaryEmailAheadUpcomingAppointments) {
            values.settings.atEightOnSessionDay = !!values.settings.atEightOnSessionDay;
            values.settings.timeInAdvance =
                !!values.settings.timeInAdvance && !!values.settings.timeInAdvanceValue
                    ? values.settings.timeInAdvanceValue
                    : null;
            values.settings.summaryAtTime =
                !!values.settings.summaryAtTime && !!values.settings.summaryAtTimeValue
                    ? values.settings.summaryAtTimeValue
                    : null;
            values.settings.atLatestBookingTime = !!values.settings.atLatestBookingTime;
        } else {
            values.settings.atEightOnSessionDay = false;
            values.settings.timeInAdvance = null;
            values.settings.summaryAtTime = null;
            values.settings.atLatestBookingTime = false;
        }

        if (!values.dontReceiveTeamSummaryEmails) {
            values.teamSummaryAtEight = !!values.teamSummaryAtEight;
            values.teamSummaryAtTime =
                !!values.teamSummaryAtTime && !!values.teamSummaryAtTimeValue
                    ? values.teamSummaryAtTimeValue
                    : null;
        } else {
            values.teamSummaryAtEight = false;
            values.teamSummaryAtTime = null;
        }

        values.isActive = values.isActive === "true" ? true : false;
        this.props.postOfficeHoursTeam(values);
    };

    render() {
        const {
            initialValues,
            admins,
            academics,
            getUsers,
            clearUsers,
            history: { push },
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.NEW;

        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Add New Team</h2>
                        <Form
                            {...{
                                initialValues: {
                                    ...initialValues,
                                    isActive: initialValues.isActive === true ? "true" : "false",
                                    dontReceiveTeamSummaryEmails: false,
                                    settings: {
                                        ...initialValues.settings,
                                        receiveSummaryEmailAheadUpcomingAppointments: !initialValues
                                            .settings.receiveSummaryEmailAheadUpcomingAppointments,
                                    },
                                },
                                currentFormType,
                                onSubmit,
                                push,
                                errors,
                                formError: error,
                                admins,
                                academics,
                                getUsers,
                                clearUsers,
                            }}
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    postOfficeHoursTeam: actionCreators.postOfficeHoursTeam.create,
    getUsers: AutoCompleteActionCreators.getStaffUsers.create,
    clearUsers: AutoCompleteActionCreators.clearStaffUsers.create,
};
const mapStateToProps = ({ AutoComplete, OfficeHoursTeams }) => ({
    initialValues: OfficeHoursTeamValues,
    errorRes: OfficeHoursTeams.errorRes,
    admins: AutoComplete.staff,
    academics: AutoComplete.staff,
});
export default connect(mapStateToProps, dispatchToProps)(OfficeHoursManageTeamsNewContainer);
