import actionCreators from "./actionCreators";
import channel from "../../fixtures/ChannelPreview";

const initialState = {
    channel,
    posts: [],
    page: 1,
    hasMore: false,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createChannel.type:
            return {
                ...state,
                channel: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateChannelPosts.type:
            return {
                ...state,
                posts: payload.length
                    ? state.posts
                          .concat(payload)
                          .reduce(
                              (x, y) => (x.map(({ id }) => id).includes(y.id) ? x : [...x, y]),
                              []
                          )
                    : state.posts,
                page: state.page + 1,
                hasMore: payload && payload.length === 25,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorMsg: payload,
            };
        case actionCreators.clearChannel.type:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
