import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { decorateTableRow, decorateTableHeader } from "../services/decorateTableItems";
import tableCss from "../styles/table.css";

export const HeaderRowItem = withStyles(tableCss)(({ decorators, columns, classes }) =>
    columns.map(prop => (
        <TableCell
            key={prop}
            // 1st apply default style
            className={classes[prop]}
            // 2nd add custom style from decorators
            style={decorators && decorators.length ? decorateTableRow(prop, decorators) : undefined}
        >
            {decorators
                ? decorateTableHeader(prop, decorators)
                : prop.replace(/\b\w/g, chr => chr.toUpperCase())}
        </TableCell>
    ))
);
