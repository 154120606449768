import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";

import { default as calendarActionCreators } from "../../../store/CalendarAndEvents/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";

import { MainTitle } from "../../../common/components/MainTitle";
import globalsCss from "../../../common/styles/globals.css";
import eventsCss from "../../../common/styles/event.css";
import formsCss from "../../../common/styles/forms.css";

import {
    dateFormatCallback,
    calendarCategoryCallback,
} from "../../../common/services/tableCallbacks";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { AvatarChip } from "../../../common/components/AvatarChip";
import { GetFormattedDate } from "../../../common/components/Time";

class CalendarEventDetailsContainer extends Component {
    state = {
        subject: {},
    };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.user && this.state.subject !== this.props.subject) {
            this.setState({ subject: this.props.subject });
        }
    }
    /*
    DEPRECIATED: componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.user && this.state.subject !== nextProps.subject) {
            this.setState({ subject: nextProps.subject });
        }
    }
    */

    componentDidMount() {
        const {
            setTitle,
            getEventDetails,
            getCreatedEventDetails,
            getCalendarSubject,
            match,
        } = this.props;
        setTitle("Calendar & Events \u203A Student Calendar Lookup");
        if (match.params.user) {
            getCalendarSubject(match.params.user);
            getEventDetails({
                id: match.params.user,
                type: match.params.type,
                uid: match.params.uid,
            });
        } else {
            this.setState({ subject: { firstName: "Created", lastName: "event" } });
            getCreatedEventDetails({
                uid: match.params.uid,
            });
        }
    }

    componentWillUnmount() {
        this.props.clearTitle();
        this.props.clearEventDetails();
    }

    render() {
        const {
            event,
            history: { push },
            match: { params },
        } = this.props;
        const { subject } = this.state;
        const timeCallback = dt => GetFormattedDate(dt, "h.mma");

        return (
            event.uid && (
                <div>
                    <MainTitle
                        title={`${subject.firstName} ${subject.lastName}`}
                        type="[Calendar Events]"
                    />
                    <Paper elevation={1}>
                        <div css={globalsCss.inner}>
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <h2 css={[globalsCss.subtitleTitle, { margin: "0 0 8px" }]}>
                                        {event.name}
                                    </h2>
                                </div>
                            </div>

                            <div className="row">
                                <h3 css={[eventsCss.dateTime]} className="col-sm-1 col-xs-12">
                                    {!event.isAllDay ? "From " : "When"}
                                </h3>
                                <h3 css={[eventsCss.dateTime]} className="col-sm-11 col-xs-12">
                                    {dateFormatCallback(event, "startAt")}
                                    {!event.isAllDay && " " + timeCallback(event.startAt)}
                                </h3>
                            </div>

                            <div className="row">
                                <h3 css={[eventsCss.dateTime]} className="col-sm-1 col-xs-12">
                                    To
                                </h3>
                                <h3 css={[eventsCss.dateTime]} className="col-sm-11 col-xs-12">
                                    {dateFormatCallback(event, "endAt")}
                                    {!event.isAllDay && " " + timeCallback(event.endAt)}
                                </h3>
                            </div>

                            <hr css={globalsCss.hrDouble} />

                            {event.location && (
                                <div className="row" css={eventsCss.label}>
                                    <div className="col-sm-1 col-xs-12">Location</div>
                                    <div className="col-sm-11 col-xs-12" css={eventsCss.value}>
                                        {event.location}
                                    </div>
                                </div>
                            )}

                            <div className="row" css={eventsCss.label}>
                                <div className="col-sm-1 col-xs-12">Calendar</div>
                                <div className="col-sm-11 col-xs-12" css={eventsCss.value}>
                                    {calendarCategoryCallback(event, "calendarType")}
                                    {event.source && (
                                        <span css={eventsCss.source}>
                                            [<strong>Source:</strong> &nbsp; {event.source}]
                                        </span>
                                    )}
                                </div>
                            </div>

                            {event.notes && (
                                <div className="row" css={eventsCss.label}>
                                    <div className="col-sm-1 col-xs-12">Notes</div>
                                    <div className="col-sm-11 col-xs-12" css={eventsCss.value}>
                                        {event.notes}
                                    </div>
                                </div>
                            )}

                            {event.attendees.length > 0 && (
                                <div className="row" css={eventsCss.label}>
                                    <div className="col-sm-1 col-xs-12">Invitees</div>
                                    <div className="col-sm-11 col-xs-12" css={eventsCss.value}>
                                        {event.attendees.map(item => (
                                            <AvatarChip
                                                key={item.id}
                                                className={
                                                    item.isConfirmed ? "confirmed" : "pending"
                                                }
                                                name={`${item.firstName} ${item.lastName}`}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            <div css={formsCss.actions}>
                                {subject.id ? (
                                    params.id ? (
                                        <Button
                                            css={formsCss.btnBack}
                                            onClick={() => push(`/bookings/${params.id}/bookings`)}
                                        >
                                            Back to the bookings
                                        </Button>
                                    ) : (
                                        <Button
                                            css={formsCss.btnBack}
                                            onClick={() =>
                                                push(`/calendar/browse-events/${subject.id}/`)
                                            }
                                        >
                                            Back to the list
                                        </Button>
                                    )
                                ) : (
                                    <Button
                                        css={formsCss.btnBack}
                                        onClick={() => push(`/calendar/add-new-kad-event`)}
                                    >
                                        Add another event
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Paper>
                </div>
            )
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getCalendarSubject: calendarActionCreators.getCalendarSubject.create,
    getEventDetails: calendarActionCreators.getEventDetails.create,
    getCreatedEventDetails: calendarActionCreators.getCreatedEventDetails.create,
    clearEventDetails: calendarActionCreators.clearEventDetails.create,
    deleteEvent: calendarActionCreators.deleteEvent.create,
};

const mapStateToProps = ({ CalendarEvents }) => ({
    subject: CalendarEvents.subject,
    event: CalendarEvents.event,
});

export default connect(mapStateToProps, dispatchToProps)(CalendarEventDetailsContainer);
