import { of, concat } from "rxjs";
import { mergeMap, catchError, mapTo, switchMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import { default as availabilityActionCreators } from "../Availabilities/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";
import { push } from "connected-react-router";
import { reset } from "redux-form";

const officeHoursSessionsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getOfficeHoursSessions.type,
            actionCreators.loadMoreOfficeHoursSessions.type,
            actionCreators.getOfficeHoursTeamSessions.type,
            actionCreators.loadMoreOfficeHoursTeamSessions.type,
            actionCreators.getOfficeHoursSessionDetails.type,
            actionCreators.makeOfficeHoursBooking.type,
            actionCreators.cancelOfficeHoursBooking.type,
            actionCreators.blockOfficeHoursSlot.type,
            actionCreators.removeBlockOfficeHoursSlot.type,
            actionCreators.bookDropInSession.type,
            actionCreators.cancelBookingDropInSession.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const officeHoursSessionsClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createOfficeHoursSessions.type,
            actionCreators.updateOfficeHoursSessions.type,
            actionCreators.createOfficeHoursSessionSlots.type,
            actionCreators.updateAlteredOfficeHoursSlot.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getOfficeHoursSessionsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeHoursSessions.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours-session/for-user/${payload.academicId}/${
                            payload.showPast ? "past" : "future"
                        }/?${payload.query ? payload.query : ""}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createOfficeHoursSessions.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreMyOfficeHoursSessionsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreOfficeHoursSessions.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours-session/for-user/${payload.academicId}/${
                            payload.showPast ? "past" : "future"
                        }/?${payload.query ? payload.query : ""}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateOfficeHoursSessions.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOfficeHoursTeamSessionsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeHoursTeamSessions.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours-session/for-team/${payload.teamId}/${
                            payload.showPast ? "past" : "future"
                        }/?${payload.query ? payload.query : ""}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createOfficeHoursSessions.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreMyOfficeHoursTeamSessionsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreOfficeHoursTeamSessions.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours-session/for-team/${payload.teamId}/${
                            payload.showPast ? "past" : "future"
                        }/?${payload.query ? payload.query : ""}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateOfficeHoursSessions.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOfficeHoursSessionDetailsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeHoursSessionDetails.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/office-hours-session/${payload}/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createOfficeHoursSessionDetails.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const deleteOfficeHoursSessionEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteOfficeHoursSession.type),
        mergeMap(({ payload }) =>
            ajax
                .remove(
                    ajax.apiUrl(`staff/office-hours-session/${payload.sessionId}/`),
                    {},
                    payload.reason ? { reason: payload.reason } : undefined
                )
                .pipe(
                    mergeMap(() =>
                        concat(
                            of(availabilityActionCreators.clearSlotsForCancellation.create()),
                            of(push(payload.redirectUrl))
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOfficeHoursSessionSlotsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeHoursSessionSlots.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl(`staff/office-hours-session/${payload}/slots/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createOfficeHoursSessionSlots.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const makeOfficeHoursBookingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.makeOfficeHoursBooking.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(
                        `staff/office-hours-session-slot/${payload.slotId}/book/${payload.userId}/`
                    ),
                    payload.data
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.updateAlteredOfficeHoursSlot.create({
                                slotId: payload.slotId,
                                slot: response,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const cancelOfficeHoursBookingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.cancelOfficeHoursBooking.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(
                        `staff/office-hours-session-slot/${payload.slotId}/cancel-booking/`
                    ),
                    payload.data
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.updateAlteredOfficeHoursSlot.create({
                                slotId: payload.slotId,
                                slot: response,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const blockOfficeHoursSlotEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.blockOfficeHoursSlot.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(`staff/office-hours-session-slot/${payload.slotId}/block/`),
                    payload.data
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.updateAlteredOfficeHoursSlot.create({
                                slotId: payload.slotId,
                                slot: response,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removeBlockOfficeHoursSlotEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeBlockOfficeHoursSlot.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(`staff/office-hours-session-slot/${payload.slotId}/unblock/`),
                    payload.data
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.updateAlteredOfficeHoursSlot.create({
                                slotId: payload.slotId,
                                slot: response,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const assignOfficeHoursGenericAvailabilitySessionEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.assignOfficeHoursGenericAvailabilitySession.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    ajax.apiUrl(
                        `staff/office-hours-session/${payload.id}/assign/${payload.teamMemberId}/`
                    ),
                    {}
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.updateAssignedOfficeHoursGenericAvailabilitySession.create(
                                response
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const bookDropInSessionEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.bookDropInSession.type),
        mergeMap(({ payload }) =>
            ajax
                .post(
                    ajax.apiUrl(`staff/office-hours-session/${payload.id}/book-drop-in/`),
                    payload
                )
                .pipe(
                    mergeMap(() =>
                        of(
                            reset("OfficeHoursAddDropInAttendee"),
                            actionCreators.getOfficeHoursSessionSlots.create(payload.id)
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const cancelBookingDropInSessionEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.cancelBookingDropInSession.type),
        mergeMap(({ payload }) =>
            ajax
                .remove(
                    ajax.apiUrl(
                        `staff/office-hours-session-slot/${payload.slotId}/cancel-booking-drop-in/`
                    )
                )
                .pipe(
                    mergeMap(() =>
                        of(actionCreators.getOfficeHoursSessionSlots.create(payload.id))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    officeHoursSessionsStartLoadingEpic,
    officeHoursSessionsClearLoadingEpic,
    getOfficeHoursSessionsEpic,
    loadMoreMyOfficeHoursSessionsEpic,
    getOfficeHoursTeamSessionsEpic,
    loadMoreMyOfficeHoursTeamSessionsEpic,
    getOfficeHoursSessionDetailsEpic,
    getOfficeHoursSessionSlotsEpic,
    makeOfficeHoursBookingEpic,
    cancelOfficeHoursBookingEpic,
    blockOfficeHoursSlotEpic,
    removeBlockOfficeHoursSlotEpic,
    deleteOfficeHoursSessionEpic,
    assignOfficeHoursGenericAvailabilitySessionEpic,
    bookDropInSessionEpic,
    cancelBookingDropInSessionEpic
);
