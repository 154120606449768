/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useFormContext, Controller } from "react-hook-form";
import React, { FC, Fragment, forwardRef } from "react";
import AutoCompleteDownshift from "../Downshift/AutoCompleteDownshift";
import formsCss from "src/common/styles/forms.css";

interface OwnProps {
    name: string;
    label: string;
    errorRes?: string;
    handleInput: any;
    dataSource: any;
    onNewRequest?: any;
    onDroppedFocus?: any;
    labelCallback?: any;
    subtitleCallback?: any;
    numCallback?: any;
    single?: any;
    inputValue?: any;
    noAvatar?: any;
    onChangeCallback?: any;
    blacklistedIds?: any;
    disabled?: any;
}

const RHFAutoComplete: FC<OwnProps> = forwardRef(
    (
        {
            name,
            label,
            errorRes,
            handleInput,
            dataSource,
            onNewRequest,
            onDroppedFocus,
            labelCallback,
            subtitleCallback,
            numCallback,
            single,
            inputValue,
            noAvatar,
            onChangeCallback,
            blacklistedIds,
            disabled,
        },
        ref
    ) => {
        const { control } = useFormContext();
        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <Fragment>
                            <AutoCompleteDownshift
                                floatingLabelText={label}
                                selectedItems={
                                    field.value ? (single ? [field.value] : field.value) : []
                                }
                                onChange={field.onChange}
                                onUpdateInput={handleInput}
                                onNewRequest={onNewRequest}
                                dataSource={dataSource}
                                onBlur={onDroppedFocus}
                                single={!!single}
                                noAvatar={!!noAvatar}
                                inputValue={inputValue}
                                labelCallback={
                                    labelCallback || (item => `${item.firstName} ${item.lastName}`)
                                }
                                subtitleCallback={
                                    subtitleCallback ||
                                    (item =>
                                        item.lseEmail
                                            ? item.lseEmail
                                            : item.email
                                            ? item.email
                                            : null)
                                }
                                numCallback={numCallback}
                                errorRes={!!error || !!errorRes}
                                onChangeCallback={onChangeCallback}
                                blacklistedIds={blacklistedIds}
                                disabled={!!disabled}
                            />

                            {(error || errorRes) && (
                                <div css={formsCss.errorSmall}>
                                    {error && error.message
                                        ? error.message
                                        : errorRes
                                        ? errorRes
                                        : ""}
                                </div>
                            )}
                        </Fragment>
                    );
                }}
            />
        );
    }
);

const RenderAutoComplete = RHFAutoComplete;

export default RenderAutoComplete;
