// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change, registerField } from "redux-form";
import {
    RenderTextField,
    RenderSelectField,
    RenderCheckboxField,
    RenderRadioGroup,
    RenderRadio,
} from "../../../../common/components/FormFields";
import { MEETING_ZOOM, MEETING_CUSTOM, MEETING_NONE } from "../types";

import { withStyles } from "@material-ui/core/styles";
import { Button, FormLabel, FormControl, TextField, MenuItem } from "@material-ui/core";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";

import { validate } from "./validate";
import globalsCss from "../../../../common/styles/globals.css";
import { InfoTooltip } from "../../../../common/components/InfoTooltip";

const FORM_TYPE_FIELD = "Field";

class Form extends Component {
    state = {
        meetingUrl: "",
    };

    componentDidMount() {
        const { addField, initialValues } = this.props;
        addField("settings.timeInAdvanceValue", FORM_TYPE_FIELD);
        addField("settings.summaryAtTimeValue", FORM_TYPE_FIELD);
        this.props.initialize(initialValues);
    }

    componentDidUpdate(prevProps) {
        const { initialValues } = this.props;

        if (initialValues && !prevProps.initialValues) {
            this.setState({ meetingUrl: initialValues.settings.meetingUrl });
        }
    }

    render() {
        const {
            push,
            errors,
            formError,
            formErrors,
            formFields,
            updateForm,
            handleSubmit,
            classes,
        } = this.props;
        const { meetingUrl } = this.state;

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Information for students</label>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <Field
                            name="settings.location"
                            errorRes={errors && errors.settings && errors.settings.location}
                            component={RenderTextField}
                            label="Location"
                            fullWidth
                        />
                        <Field
                            name="settings.appointmentForm"
                            errorRes={errors && errors.settings && errors.settings.appointmentForm}
                            component={RenderTextField}
                            label="Appointment type"
                            fullWidth
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <Field
                            name="settings.note"
                            error={errors && errors.settings && errors.settings.note}
                            component={RenderTextField}
                            label="Notes to students making bookings"
                            fullWidth
                            multiline
                            rows={5}
                            rowsMax={5}
                            placeholder="Share any useful information about this availability with your students here"
                        />
                    </div>
                </div>
                <hr className="bold" />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Online meetings</label>
                    </div>
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <Field
                            name="settings.onlineMeetings"
                            component={RenderRadioGroup}
                            row
                            onChange={(ev, value) => {
                                if (value === MEETING_ZOOM) {
                                    updateForm("settings.meetingUrl", "");
                                    updateForm("settings.integrateWithZoom", true);
                                } else if (value === MEETING_CUSTOM) {
                                    updateForm("settings.meetingUrl", meetingUrl);
                                    updateForm("settings.integrateWithZoom", false);
                                } else if (value === MEETING_NONE) {
                                    updateForm("settings.meetingUrl", "");
                                    updateForm("settings.integrateWithZoom", false);
                                }
                            }}
                            onBlur={e => e.preventDefault()}
                        >
                            <RenderRadio
                                value={MEETING_ZOOM}
                                label={
                                    <div>
                                        <span>Generate Zoom links automatically</span>
                                        <InfoTooltip
                                            mb={0}
                                            text="Each session created by this availability will have a unique Zoom meeting link, with the waiting room feature turned on"
                                        />
                                        <div css={[formsCss.labelHelp, globalsCss.helpers.mt5]}>
                                            <em>
                                                You will see these links in your Zoom account, in
                                                Student Hub booking session pages, and in Student
                                                Hub email notifications (if enabled below)
                                            </em>
                                        </div>
                                    </div>
                                }
                                className="col-lg-12 col-sm-12 col-xs-12"
                            />
                            <RenderRadio
                                value={MEETING_CUSTOM}
                                label={
                                    <div>
                                        <span>
                                            Provide a personal meeting link or other joining
                                            instructions
                                        </span>
                                        {formFields &&
                                            formFields.settings &&
                                            formFields.settings.onlineMeetings ===
                                                MEETING_CUSTOM && (
                                                <div css={globalsCss.helpers.mt15}>
                                                    <div>
                                                        <TextField
                                                            css={[
                                                                formsCss.inlineItem,
                                                                formsCss.inlineItemField,
                                                            ]}
                                                            label="Online meeting details"
                                                            color="primary"
                                                            error={!!formErrors.meetingUrl}
                                                            placeholder={
                                                                "e.g. your personal Zoom link, or instructions for Teams calls"
                                                            }
                                                            fullWidth={true}
                                                            multiline
                                                            rows={5}
                                                            rowsMax={5}
                                                            onChange={({ target: { value } }) => {
                                                                this.setState({
                                                                    meetingUrl: value,
                                                                });
                                                            }}
                                                            onBlur={() => {
                                                                updateForm(
                                                                    "settings.meetingUrl",
                                                                    meetingUrl
                                                                );
                                                            }}
                                                            value={meetingUrl ? meetingUrl : ""}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.inputRoot,
                                                                    error: classes.inputError,
                                                                },
                                                            }}
                                                            margin="none"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                }
                                className="col-lg-12 col-sm-12 col-xs-12"
                            />
                            <RenderRadio
                                value={MEETING_NONE}
                                label={
                                    <div>
                                        <span>No online meeting option</span>
                                    </div>
                                }
                                className="col-lg-12 col-sm-12 col-xs-12"
                            />
                        </Field>
                    </div>
                </div>
                <hr className="bold" />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Appointment settings</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="settings.duration"
                            component={RenderSelectField}
                            label="Appointment duration"
                            errorRes={errors && errors.settings && errors.settings.duration}
                            options={[
                                { value: null, label: "" },
                                { value: 5, label: "5 minutes" },
                                { value: 10, label: "10 minutes" },
                                { value: 15, label: "15 minutes" },
                                { value: 20, label: "20 minutes" },
                                { value: 25, label: "25 minutes" },
                                { value: 30, label: "30 minutes" },
                                { value: 35, label: "35 minutes" },
                                { value: 40, label: "40 minutes" },
                                { value: 45, label: "45 minutes" },
                                { value: 50, label: "50 minutes" },
                                { value: 55, label: "55 minutes" },
                                { value: 60, label: "60 minutes" },
                                { value: 90, label: "90 minutes" },
                            ]}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="settings.maxNumberOfAttendees"
                                errorRes={
                                    errors &&
                                    errors.settings &&
                                    errors.settings.maxNumberOfAttendees
                                }
                                component={RenderTextField}
                                label="Number of attendees per appointment slot"
                                fullWidth
                                type="number"
                                inputProps={{ min: "1" }}
                                placeholder="Maximum number of attendees per slot"
                            />
                            <InfoTooltip text="Allows you to define how many students can book into the same appointment slot. Students will not be able to see who else has booked into a slot." />
                        </div>
                    </div>

                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="settings.maxAppointmentsPerSession"
                                errorRes={
                                    errors &&
                                    errors.settings &&
                                    errors.settings.maxAppointmentsPerSession
                                }
                                component={RenderTextField}
                                label="Number of slots per student per session"
                                fullWidth
                                type="number"
                                inputProps={{ min: "1" }}
                                placeholder="Maximum number of bookings per student"
                            />
                            <InfoTooltip text="Allows you to define how many appointments a student can book in one session." />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Student booking settings</label>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="settings.earliestBooking"
                                component={RenderSelectField}
                                label="Earliest time students can book from"
                                errorRes={
                                    errors && errors.settings && errors.settings.earliestBooking
                                }
                                options={[
                                    { value: 0, label: "No limit" },
                                    { value: 24 * 60, label: "1 day in advance" },
                                    { value: 48 * 60, label: "2 days in advance" },
                                    { value: 72 * 60, label: "3 days in advance" },
                                    { value: 96 * 60, label: "4 days in advance" },
                                    { value: 120 * 60, label: "5 days in advance" },
                                    { value: 144 * 60, label: "6 days in advance" },
                                    { value: 168 * 60, label: "7 days in advance" },
                                    { value: 192 * 60, label: "8 days in advance" },
                                    { value: 216 * 60, label: "9 days in advance" },
                                    { value: 240 * 60, label: "10 days in advance" },
                                    { value: 264 * 60, label: "11 days in advance" },
                                    { value: 288 * 60, label: "12 days in advance" },
                                    { value: 312 * 60, label: "13 days in advance" },
                                    { value: 336 * 60, label: "14 days in advance" },
                                ]}
                            />
                            <InfoTooltip text="Allows you to set how many days in advance a student can book an appointment with you." />
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="settings.latestBooking"
                                component={RenderSelectField}
                                label="Latest time students can book until"
                                errorRes={
                                    errors && errors.settings && errors.settings.latestBooking
                                }
                                options={[
                                    { value: 0, label: "No limit" },
                                    { value: 30, label: "30 minutes in advance" },
                                    { value: 1 * 60, label: "1 hour in advance" },
                                    { value: 2 * 60, label: "2 hours in advance" },
                                    { value: 3 * 60, label: "3 hours in advance" },
                                    { value: 4 * 60, label: "4 hours in advance" },
                                    { value: 5 * 60, label: "5 hours in advance" },
                                    { value: 6 * 60, label: "6 hours in advance" },
                                    { value: 12 * 60, label: "12 hours in advance" },
                                    { value: 24 * 60, label: "24 hours in advance" },
                                    { value: 48 * 60, label: "48 hours in advance" },
                                    { value: 72 * 60, label: "3 days in advance" },
                                    { value: 96 * 60, label: "4 days in advance" },
                                    { value: 120 * 60, label: "5 days in advance" },
                                    { value: 144 * 60, label: "6 days in advance" },
                                    { value: 168 * 60, label: "7 days in advance" },
                                ]}
                            />
                            <InfoTooltip text="Allows you to set the latest time a student can book an appointment with you." />
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={globalsCss.helpers.mt20}>
                            <Field
                                name="settings.allowFileUpload"
                                label="Allow students to upload files"
                                component={RenderCheckboxField}
                                errorRes={
                                    errors && errors.settings && errors.settings.allowFileUpload
                                }
                            />
                            <Field
                                name="settings.requireBookingNote"
                                label=" Require students to add a booking note"
                                component={RenderCheckboxField}
                                errorRes={
                                    errors && errors.settings && errors.settings.requireBookingNote
                                }
                            />
                        </div>
                    </div>
                </div>
                <hr className="bold" />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>My email notification preferences</label>
                    </div>

                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="settings.email"
                                errorRes={errors && errors.settings && errors.settings.email}
                                component={RenderTextField}
                                label="Notifications email address"
                                fullWidth
                            />
                            <InfoTooltip text="This is the email address that notifications about your sessions will be sent to. You have the option to change this email address by editing the field." />
                        </div>
                    </div>

                    <div className="col-sm-4 col-xs-12">
                        <FormControl margin="normal" component="fieldset">
                            <FormLabel component="div" css={formsCss.labelRadioGroup}>
                                Send me a summary email for my bookings:
                            </FormLabel>

                            <div css={formsCss.checkboxBox}>
                                <div>
                                    <Field
                                        name="settings.atEightOnSessionDay"
                                        errorRes={
                                            errors &&
                                            errors.settings &&
                                            errors.settings.atEightOnSessionDay
                                        }
                                        label="At 08:00 on the session day"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.settings
                                                        .receiveSummaryEmailAheadUpcomingAppointments ===
                                                    true
                                                )
                                                    updateForm(
                                                        "settings.receiveSummaryEmailAheadUpcomingAppointments",
                                                        false
                                                    );
                                            }
                                        }}
                                    />
                                </div>

                                <div>
                                    <Field
                                        name="settings.summaryAtTime"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.settings
                                                        .receiveSummaryEmailAheadUpcomingAppointments ===
                                                    true
                                                )
                                                    updateForm(
                                                        "settings.receiveSummaryEmailAheadUpcomingAppointments",
                                                        false
                                                    );
                                            }
                                        }}
                                        label={
                                            <div
                                                css={formsCss.inlineAlignTop}
                                                onClick={e => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <div css={formsCss.inlineItem}>At</div>
                                                <TextField
                                                    css={[
                                                        formsCss.inlineItem,
                                                        formsCss.inlineItemField,
                                                        { width: 100 },
                                                    ]}
                                                    disabled={
                                                        formFields &&
                                                        formFields.settings &&
                                                        formFields.settings
                                                            .receiveSummaryEmailAheadUpcomingAppointments ===
                                                            true
                                                    }
                                                    select
                                                    margin="none"
                                                    color="primary"
                                                    fullWidth={false}
                                                    error={
                                                        !!formErrors &&
                                                        !!formErrors.settings &&
                                                        !!formErrors.settings.summaryAtTimeValue
                                                    }
                                                    helperText={
                                                        formErrors &&
                                                        formErrors.settings &&
                                                        formErrors.settings.summaryAtTimeValue
                                                    }
                                                    onChange={({ target: { value } }) => {
                                                        updateForm(
                                                            "settings.summaryAtTime",
                                                            !!value
                                                        );
                                                        updateForm(
                                                            "settings.summaryAtTimeValue",
                                                            value
                                                        );
                                                    }}
                                                    value={
                                                        formFields &&
                                                        formFields.settings &&
                                                        formFields.settings.summaryAtTimeValue
                                                            ? formFields.settings.summaryAtTimeValue
                                                            : ""
                                                    }
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.inputRoot,
                                                            error: classes.inputError,
                                                        },
                                                    }}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            MenuListProps: {
                                                                disablePadding: true,
                                                                dense: true,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem key={`aots_0`} value={0}>
                                                        Choose time
                                                    </MenuItem>
                                                    <MenuItem key={`aots_1`} value={9}>
                                                        9.00am
                                                    </MenuItem>
                                                    <MenuItem key={`aots_2`} value={10}>
                                                        10.00am
                                                    </MenuItem>
                                                    <MenuItem key={`aots_3`} value={11}>
                                                        11.00am
                                                    </MenuItem>
                                                    <MenuItem key={`aots_4`} value={12}>
                                                        12.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_5`} value={13}>
                                                        1.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_6`} value={14}>
                                                        2.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_7`} value={15}>
                                                        3.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_8`} value={16}>
                                                        4.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_9`} value={17}>
                                                        5.00pm
                                                    </MenuItem>
                                                </TextField>
                                                <div css={formsCss.inlineItem}>
                                                    on the session day
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>

                                <div>
                                    <Field
                                        name="settings.timeInAdvance"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.settings
                                                        .receiveSummaryEmailAheadUpcomingAppointments ===
                                                    true
                                                )
                                                    updateForm(
                                                        "settings.receiveSummaryEmailAheadUpcomingAppointments",
                                                        false
                                                    );
                                            }
                                        }}
                                        errorRes={
                                            errors &&
                                            errors.settings &&
                                            errors.settings.timeInAdvance
                                        }
                                        label={
                                            <div
                                                css={formsCss.inlineAlignTop}
                                                onClick={e => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <TextField
                                                    css={[
                                                        formsCss.inlineItem,
                                                        formsCss.inlineItemField,
                                                        { width: 120 },
                                                    ]}
                                                    disabled={
                                                        formFields &&
                                                        formFields.settings &&
                                                        formFields.settings
                                                            .receiveSummaryEmailAheadUpcomingAppointments ===
                                                            true
                                                    }
                                                    select
                                                    margin="none"
                                                    color="primary"
                                                    fullWidth={false}
                                                    error={
                                                        !!formErrors &&
                                                        !!formErrors.settings &&
                                                        !!formErrors.settings.timeInAdvanceValue
                                                    }
                                                    helperText={
                                                        formErrors &&
                                                        formErrors.settings &&
                                                        formErrors.settings.timeInAdvanceValue
                                                    }
                                                    onChange={({ target: { value } }) => {
                                                        updateForm(
                                                            "settings.timeInAdvance",
                                                            !!value
                                                        );
                                                        updateForm(
                                                            "settings.timeInAdvanceValue",
                                                            value
                                                        );
                                                    }}
                                                    value={
                                                        formFields &&
                                                        formFields.settings &&
                                                        formFields.settings.timeInAdvanceValue
                                                            ? formFields.settings.timeInAdvanceValue
                                                            : ""
                                                    }
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.inputRoot,
                                                            error: classes.inputError,
                                                        },
                                                    }}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            MenuListProps: {
                                                                disablePadding: true,
                                                                dense: true,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem key={`tia_0`} value={0}>
                                                        Choose time
                                                    </MenuItem>
                                                    <MenuItem key={`tia_1`} value={30}>
                                                        30 minutes
                                                    </MenuItem>
                                                    <MenuItem key={`tia_2`} value={60 * 1}>
                                                        1 hour
                                                    </MenuItem>
                                                    <MenuItem key={`tia_3`} value={60 * 2}>
                                                        2 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_4`} value={60 * 3}>
                                                        3 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_5`} value={60 * 4}>
                                                        4 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_6`} value={60 * 5}>
                                                        5 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_7`} value={60 * 6}>
                                                        6 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_8`} value={60 * 12}>
                                                        12 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_9`} value={60 * 24}>
                                                        24 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_10`} value={60 * 48}>
                                                        48 hours
                                                    </MenuItem>
                                                </TextField>
                                                <div css={formsCss.inlineItem}>
                                                    before the session
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div>
                                    <Field
                                        name="settings.atLatestBookingTime"
                                        errorRes={
                                            errors &&
                                            errors.settings &&
                                            errors.settings.atLatestBookingTime
                                        }
                                        label="Once students can no longer book"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.settings
                                                        .receiveSummaryEmailAheadUpcomingAppointments ===
                                                    true
                                                )
                                                    updateForm(
                                                        "settings.receiveSummaryEmailAheadUpcomingAppointments",
                                                        false
                                                    );
                                            }
                                        }}
                                    />
                                </div>
                                <div>Or</div>
                                <div>
                                    <Field
                                        name="settings.receiveSummaryEmailAheadUpcomingAppointments"
                                        label="No, don't send me summary emails"
                                        component={RenderCheckboxField}
                                        errorRes={
                                            errors &&
                                            errors.settings &&
                                            errors.settings
                                                .receiveSummaryEmailAheadUpcomingAppointments
                                        }
                                        onChange={(e, value) => {
                                            if (value) {
                                                updateForm("settings.atEightOnSessionDay", false);
                                                updateForm("settings.timeInAdvance", false);
                                                updateForm("settings.summaryAtTime", false);
                                                updateForm("settings.atLatestBookingTime", false);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </FormControl>
                    </div>

                    <div className="col-sm-4 col-xs-12"></div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>My calendar preferences</label>
                    </div>

                    <div className="col-sm-4 col-xs-12">
                        <FormControl margin="normal" component="fieldset">
                            <Field
                                name="settings.addSessionPlaceholders"
                                label="Add session placeholders to my calendar"
                                component={RenderCheckboxField}
                            />

                            <label css={formsCss.note}>
                                You will receive calendar emails for all sessions created from this
                                availability
                            </label>
                        </FormControl>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <FormControl margin="normal" component="fieldset">
                            <Field
                                name="settings.addBookedAppointments"
                                label="Add individual bookings to my calendar"
                                component={RenderCheckboxField}
                            />

                            <label css={formsCss.note}>
                                You will receive calendar emails when students make and cancel
                                bookings
                            </label>
                        </FormControl>
                    </div>
                    <div className="col-sm-4 col-xs-12"></div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Availability limits</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="maxAppointmentsPerUserPerTerm"
                                errorRes={errors && errors.maxAppointmentsPerUserPerTerm}
                                component={RenderTextField}
                                label="Number of slots per student per term"
                                fullWidth
                                type="number"
                                inputProps={{ min: "1" }}
                                placeholder="Maximum number of bookings per student per term"
                            />
                            <InfoTooltip text="Allows you to define how many appointments a student can book across multiple sessions in one term." />
                        </div>
                    </div>
                </div>

                {formError && (
                    <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                        {formError}
                    </div>
                )}
                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        Save Default Settings
                    </Button>
                    <Button onClick={() => push("/bookings/availability")} css={formsCss.btnBack}>
                        Back to the list
                    </Button>
                </div>
            </form>
        );
    }
}

const FORM_NAME = "OfficeHoursDefaultAvailability";

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = ({ form }) => ({
    formFields: selector(
        { form },
        "settings.integrateWithZoom",
        "settings.timeInAdvance",
        "settings.timeInAdvanceValue",
        "settings.summaryAtTime",
        "settings.summaryAtTimeValue",
        "settings.receiveSummaryEmailAheadUpcomingAppointments",
        "settings.addBookedAppointments",
        "settings.addSessionPlaceholders",
        "settings.onlineMeetings"
    ),
    formErrors: form[FORM_NAME]
        ? form[FORM_NAME].syncErrors
            ? form[FORM_NAME].syncErrors
            : {}
        : {},
});

const dispatchToProps = {
    updateForm: (key, newValue) => change(FORM_NAME, key, newValue),
    addField: (key, newValue) => registerField(FORM_NAME, key, newValue),
};

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    touchOnBlur: false,
    validate,
})(withStyles(formsCss)(Form));

export default connect(mapStateToProps, dispatchToProps)(form);
