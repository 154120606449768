import actionCreators from "./actionCreators";

export const initialState = {
    users: {
        data: [],
        count: 0,
        nextPage: null,
    },
    user: {
        id: null,
        lseId: null,
        firstName: null,
        lastName: null,
        lastNameLocked: false,
        status: null,
        email: null,
        permissionLevel: null,
        hasBookings: null,
    },
    currentFormType: null,
    isBulkUploadDialogOpen: false,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListStaff.type:
            return {
                ...state,
                users: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListStaff.type: {
            const ids = state.users.data.map(user => user.id);
            payload.data = [
                ...state.users.data,
                ...payload.data.filter(user => ids.indexOf(user.id) === -1),
            ];
            return {
                ...state,
                users: payload,
                errorRes: initialState.errorRes,
            };
        }
        case actionCreators.clearListStaff.type:
            return {
                ...state,
                users: { ...initialState.users },
            };
        case actionCreators.updateOneStaff.type:
            return {
                ...state,
                user: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.setCurrentFormType.type:
            return {
                ...state,
                currentFormType: payload,
                user: initialState.user,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.toggleBulkUploadDialog.type:
            return {
                ...state,
                isBulkUploadDialogOpen: !state.isBulkUploadDialogOpen,
                errorRes: initialState.errorRes,
            };
        default:
            return state;
    }
};
