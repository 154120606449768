import React from "react";
import moment from "moment-timezone";

export const GetFormattedDate = (date, format = "dddd D MMMM YYYY") =>
    date
        ? moment(date)
              .tz("Europe/London")
              .format(format)
        : "n/a";

export const GetFormattedTime = (date, format = "h.mma") => {
    let usedFormat = format.includes("mm")
        ? moment(date).format("m") === "0"
            ? format.replace(".mm", "")
            : format
        : format;

    return date
        ? moment(date)
              .tz("Europe/London")
              .format(usedFormat)
        : "n/a";
};

export const FormatTime = ({ date, format = "dddd D MMMM YYYY h.mma", empty = "n/a", ...rest }) => (
    <time {...rest}>
        {date
            ? moment(date)
                  .tz("Europe/London")
                  .format(format)
            : empty}
    </time>
);

export const DateTime = ({ date, ...rest }) => (
    <FormatTime {...rest} date={date} format="D MMMM YYYY h.mma" />
);

export const DateTimeFull = ({ date, ...rest }) => (
    <FormatTime {...rest} date={date} format="dddd D MMMM YYYY h.mma" />
);

export const Date = ({ date, format, ...rest }) => (
    <FormatTime {...rest} date={date} format={format ? format : "dddd D MMMM YYYY"} />
);

export const Time = ({ date, ...rest }) => <FormatTime {...rest} date={date} format="h.mma" />;

export const TimeFromNow = ({ date, empty = "n/a", ...rest }) => (
    <time dateTime={date} {...rest}>
        {date ? moment(date).fromNow() : empty}
    </time>
);
