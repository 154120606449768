import ActionCreator from "../actionCreator";

export default {
    getStaffUsers: ActionCreator("AUTOCOMPLETE_GET_STAFF"),
    receivedStaffUsers: ActionCreator("AUTOCOMPLETE_RECEIVED_STAFF"),
    clearStaffUsers: ActionCreator("AUTOCOMPLETE_CLEAR_STAFF"),
    getStudentUsers: ActionCreator("AUTOCOMPLETE_GET_STUDENTS"),
    receivedStudentUsers: ActionCreator("AUTOCOMPLETE_RECEIVED_STUDENTS"),
    clearStudentUsers: ActionCreator("AUTOCOMPLETE_CLEAR_STUDENTS"),
    getAllUsers: ActionCreator("AUTOCOMPLETE_GET_USERS"),
    receivedAllUsers: ActionCreator("AUTOCOMPLETE_RECEIVED_USERS"),
    clearAllUsers: ActionCreator("AUTOCOMPLETE_CLEAR_USERS"),
    getAudiences: ActionCreator("AUTOCOMPLETE_GET_AUDIENCES"),
    receivedAudiences: ActionCreator("AUTOCOMPLETE_RECEIVED_AUDIENCES"),
    clearAudiences: ActionCreator("AUTOCOMPLETE_CLEAR_AUDIENCES"),
    getPages: ActionCreator("AUTOCOMPLETE_GET_PAGES"),
    receivedPages: ActionCreator("AUTOCOMPLETE_RECEIVED_PAGES"),
    clearPages: ActionCreator("AUTOCOMPLETE_CLEAR_PAGES"),
    getPosts: ActionCreator("AUTOCOMPLETE_GET_POSTS"),
    receivedPosts: ActionCreator("AUTOCOMPLETE_RECEIVED_POSTS"),
    clearPosts: ActionCreator("AUTOCOMPLETE_CLEAR_POSTS"),
    getEvents: ActionCreator("AUTOCOMPLETE_GET_EVENTS"),
    receivedEvents: ActionCreator("AUTOCOMPLETE_RECEIVED_EVENTS"),
    clearEvents: ActionCreator("AUTOCOMPLETE_CLEAR_EVENTS"),
    getChannels: ActionCreator("AUTOCOMPLETE_GET_CHANNELS"),
    receivedChannels: ActionCreator("AUTOCOMPLETE_RECEIVED_CHANNELS"),
    clearChannels: ActionCreator("AUTOCOMPLETE_CLEAR_CHANNELS"),
    getOfficeHoursAcademics: ActionCreator("AUTOCOMPLETE_GET_OFFICE_HOURS_ACADEMICS"),
    receivedOfficeHoursAcademics: ActionCreator("AUTOCOMPLETE_OFFICE_HOURS_ACADEMICS"),
    clearOfficeHoursAcademics: ActionCreator("AUTOCOMPLETE_CLEAR_OFFICE_HOURS_ACADEMICS"),
    getOfficeHoursAdministrators: ActionCreator("AUTOCOMPLETE_GET_OFFICE_HOURS_ADMINISTRATORS"),
    receivedOfficeHoursAdministrators: ActionCreator("AUTOCOMPLETE_OFFICE_HOURS_ADMINISTRATORS"),
    clearOfficeHoursAdministrators: ActionCreator("AUTOCOMPLETE_CLEAR_OFFICE_HOURS_ADMINISTRATORS"),
    getLseModules: ActionCreator("AUTOCOMPLETE_GET_LSEMODULES"),
    setLseModules: ActionCreator("AUTOCOMPLETE_SET_LSEMODULES"),
    clearLseModules: ActionCreator("AUTOCOMPLETE_CLEAR_LSEMODULES"),
    getOfficeHoursTeams: ActionCreator("AUTOCOMPLETE_GET_OFFICE_HOURS_TEAMS"),
    setOfficeHoursTeams: ActionCreator("AUTOCOMPLETE_SET_OFFICE_HOURS_TEAMS"),
    errorResponse: ActionCreator("AUTOCOMPLETE_ERROR_RESPONSE"),
};
