/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import formsCss from "../../styles/forms.css";
import CheckboxWithLabel from "./helpers/CheckboxWithLabel";

class RenderCheckboxComponent extends Component {
    state = {
        isChecked: false,
    };

    componentDidMount() {
        this.setState({ isChecked: Boolean(this.props.input.value) });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.input.value !== this.props.input.value) {
            this.setState({ isChecked: this.props.input.value });
        }
    }

    handleToggle = () => this.setState({ isChecked: !this.state.isChecked });

    render() {
        const {
            input,
            label,
            meta: { touched, error },
            errorRes,
            classes,
            ...custom
        } = this.props;

        return (
            <div>
                <CheckboxWithLabel
                    label={label}
                    classes={classes}
                    {...input}
                    value={String(this.state.isChecked)}
                    checked={this.state.isChecked}
                    onBlur={() => input.onBlur(input.value)}
                    onChange={e => {
                        input.onChange(e);
                        this.handleToggle();
                    }}
                    {...custom}
                />
                {touched && (error || errorRes) && (
                    <span css={[formsCss.genericError, formsCss.genericErrorMarginNone]}>
                        {touched && (error || errorRes)}
                    </span>
                )}
            </div>
        );
    }
}

export const RenderCheckboxField = withStyles(formsCss)(RenderCheckboxComponent);
