import actionCreators from "./actionCreators";
import schoolYear from "../../../fixtures/SettingsSchoolYear";

export const initialState = {
    // TODO: move to reports
    schoolYears: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    schoolYear,
    errorRes: { error: "", errors: {} },
    currentFormType: null,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListSchoolYears.type:
            return {
                ...state,
                schoolYears: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListSchoolYears.type:
            payload.data = [
                ...state.schoolYears.data,
                ...payload.data.filter(
                    schoolYear =>
                        !state.schoolYears.data.map(item => item.id).includes(schoolYear.id)
                ),
            ];
            return {
                ...state,
                schoolYears: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneSchoolYear.type:
            return {
                ...state,
                schoolYear: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearSchoolYear.type:
            return {
                ...state,
                schoolYear: initialState.schoolYear,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
