export default values => {
    const errors = {};
    const requiredFields = ["name"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    if (!values["users"] || (values["users"] && Object.keys(values["users"]).length === 0)) {
        errors["users"] = "Required";
    }

    if (!values["admins"] || (values["admins"] && Object.keys(values["admins"]).length === 0)) {
        errors["admins"] = "Required";
    }

    if (values.settings && !values.settings.receiveSummaryEmailAheadUpcomingAppointments) {
        if (
            !values.settings.atEightOnSessionDay &&
            !values.settings.timeInAdvance &&
            !values.settings.summaryAtTime &&
            !values.settings.atLatestBookingTime
        ) {
            errors.settings = {
                receiveSummaryEmailAheadUpcomingAppointments: "Please choose at least one option",
            };
        } else if (!!values.settings.timeInAdvance && !values.settings.timeInAdvanceValue) {
            errors.settings = { timeInAdvanceValue: "Required" };
        } else if (!!values.settings.summaryAtTime && !values.settings.summaryAtTimeValue) {
            errors.settings = { summaryAtTimeValue: "Required" };
        }
    }

    if (!values.dontReceiveTeamSummaryEmails && values.settings && !values.settings.email) {
        errors.settings = {
            ...(errors.settings ? errors.settings : {}),
            email: "Please enter a team mailbox to receive team summary emails",
        };
    }

    return errors;
};
