import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { DatePicker } from "material-ui-pickers";
import { convertUtc2London, convertLondon2Utc } from "../../../common/services/parseDates";
import formsCss from "../../styles/forms.css";
import moment from "moment-timezone";

// ---------------------------
// Standard Date Picker
// SHOULD BE used together with TimePicker component
// For standalone date picker -> handle date conversion (convertToUTCDate)
// ---------------------------
export const RenderDatepicker = withStyles(formsCss)(
    ({ input, meta: { touched, error }, errorRes, hintText, classes, ...rest }) => {
        const errorText = errorRes !== null && touched && (error || errorRes);

        const d = moment.tz("Europe/London").set({
            hour: 0,
            minute: 0,
            second: 0,
        });
        const now = moment(input.value ? input.value : d);

        const londonOffset = now.tz("Europe/London").utcOffset();
        const diff = londonOffset;

        const roundTo5 = value => (value % 5 !== 0 ? Math.floor(value / 5) * 5 : value);

        return (
            <div>
                <DatePicker
                    {...input}
                    {...rest}
                    value={input.value === null ? null : convertUtc2London(input.value).toDate()}
                    onChange={value => {
                        let newValue;
                        const newTime = moment(value);

                        if (input.value) {
                            // existng value deetcted - update only date
                            newValue = moment(input.value);
                            newValue.set("year", newTime.get("year"));
                            newValue.set("month", newTime.get("month"));
                            newValue.set("date", newTime.get("date"));
                            newValue.set("minute", roundTo5(newTime.get("minute")));
                        } else {
                            // no initial value - reset to now()
                            newValue = convertLondon2Utc(
                                input.value === null
                                    ? newTime
                                          .set("minute", roundTo5(newTime.get("minute")))
                                          .utc()
                                          .add(diff, "minutes")
                                          .format()
                                    : newTime
                                          .set("minute", roundTo5(newTime.get("minute")))
                                          .add(diff, "minutes")
                                          .format()
                            );
                        }
                        input.onChange(newValue && newValue.format());
                    }}
                    onDismiss={() => {
                        input.onBlur();
                    }}
                    format={"dddd D MMMM YYYY"}
                    onBlur={() => {
                        return;
                    }}
                    autoOk={true}
                    placeholder={hintText ? hintText : "dddd D MMMM YYYY"}
                    error={!!errorText}
                    helperText={errorText}
                    color="primary"
                    InputProps={{
                        classes: {
                            root: classes.inputRoot,
                            error: classes.inputError,
                        },
                    }}
                />
            </div>
        );
    }
);
