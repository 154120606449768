import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo, concatMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import { default as availabilitiesActionCreators } from "../Availabilities/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";

const officeHoursTeamsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListOfficeHoursTeams.type,
            actionCreators.getOneOfficeHoursTeam.type,
            actionCreators.postOfficeHoursTeam.type,
            actionCreators.putOfficeHoursTeam.type,
            actionCreators.getTeamSlotsForCancellation.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const officeHoursTeamsClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListOfficeHoursTeams.type,
            actionCreators.updateOneOfficeHoursTeam.type,
            actionCreators.pushListOfficeHoursTeams.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListOfficeHoursTeamsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListOfficeHoursTeams.type),
        mergeMap(action =>
            ajax
                .get(
                    ajax.apiUrl(`staff/office-hours-team/?${action.payload ? action.payload : ""}`)
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createListOfficeHoursTeams.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneOfficeHoursTeamEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneOfficeHoursTeam.type),
        mergeMap(action =>
            ajax.get(ajax.apiUrl(`staff/office-hours-team/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateOneOfficeHoursTeam.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newOfficeHoursTeamEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postOfficeHoursTeam.type),
        mergeMap(action =>
            ajax
                .post(ajax.apiUrl("staff/office-hours-team/"), action.payload)
                .pipe(
                    mapTo(actionCreators.pushListOfficeHoursTeams.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const editOfficeHoursTeamEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putOfficeHoursTeam.type),
        mergeMap(action =>
            ajax
                .put(ajax.apiUrl(`staff/office-hours-team/${action.payload.id}/`), action.payload)
                .pipe(
                    concatMap(({ response }) =>
                        of(
                            actionCreators.pushListOfficeHoursTeams.create(response),
                            availabilitiesActionCreators.clearOneMyOfficeHoursTeam.create()
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removeOfficeHoursTeamEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeOfficeHoursTeam.type),
        mergeMap(action =>
            ajax.remove(ajax.apiUrl(`staff/office-hours-team/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.pushListOfficeHoursTeams.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const pushListOfficeHoursTeamsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListOfficeHoursTeams.type),
        mapTo(push(`/bookings/manage-teams`))
    );

const getTeamSlotsForCancellationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getTeamSlotsForCancellation.type),
        switchMap(({ payload }) =>
            ajax
                .post(
                    ajax.apiUrl(
                        `staff/office-hours-team/${payload.teamId}/slots-for-cancellation/`
                    ),
                    {
                        users: payload.users,
                    }
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createTeamSlotsForCancellation.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    officeHoursTeamsStartLoadingEpic,
    officeHoursTeamsClearLoadingEpic,
    getListOfficeHoursTeamsEpic,
    newOfficeHoursTeamEpic,
    pushListOfficeHoursTeamsEpic,
    getOneOfficeHoursTeamEpic,
    editOfficeHoursTeamEpic,
    removeOfficeHoursTeamEpic,
    getTeamSlotsForCancellationEpic
);
