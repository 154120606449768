export default {
    id: 0,
    name: "",
    startFirstTerm: null,
    endFirstTerm: null,
    startSecondTerm: null,
    endSecondTerm: null,
    startThirdTerm: null,
    endThirdTerm: null,
    schoolYearHolidays: {},
};
