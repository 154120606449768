/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import {
    RenderAutocomplete,
    RenderSelectField,
    RenderDatepicker,
} from "../../../../../common/components/FormFields";
import { Button } from "@material-ui/core";
import formsCss from "../../../../../common/styles/forms.css";
import { rawUpdateListOnFilterChange } from "../../../../../common/services/FilterList";
import { withStyles } from "@material-ui/core/styles";
import globalsCss from "../../../../../common/styles/globals.css";
import { processSchoolYears } from "../../helpers/schoolYear";
import { validateRequiredFields } from "../../../../../common/services/formHelpers";

class BookingHistoryForm extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const { getUsers, clearUsers } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            if (query !== "") {
                rawUpdateListOnFilterChange({
                    getFn: getUsers,
                    filter: { filter: query },
                });
            } else clearUsers();
        });
        this.props.initialize(this.props.initialValues);
    }

    onChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () => searchText$.next(this.state.searchText));
    };

    componentDidUpdate(prevProps) {
        const { formSchoolyear, updateForm } = this.props;
        if (prevProps.formSchoolyear !== formSchoolyear && formSchoolyear) {
            const dates = formSchoolyear.split("#");
            updateForm("from", dates[0]);
            updateForm("to", dates[1]);
        }
    }

    clearSchoolYearSelect = () => {
        this.props.updateForm("schoolyear", null);
    };

    render() {
        const { handleSubmit, students, clearUsers, schoolYears, errors, formError } = this.props;
        const { clearSchoolYearSelect } = this;

        return schoolYears ? (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <label css={formsCss.labelBig}>Student name</label>
                        <Field
                            name="users"
                            errorRes={errors.users}
                            handleInput={this.onChangeHandler}
                            dataSource={students}
                            component={RenderAutocomplete}
                            single
                            onDroppedFocus={() => clearUsers()}
                        />
                    </div>
                </div>
                <hr />
                <div css={globalsCss.helpers.mt20}>
                    <h4>Time period</h4>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.labelBig}>Select year, term ... </div>
                        <Field
                            name="schoolyear"
                            component={RenderSelectField}
                            label="Select value"
                            errorRes={errors.schoolyear}
                            options={processSchoolYears(schoolYears)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <div css={formsCss.labelBig}>...or time period</div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="from"
                            errorRes={errors.from}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                            css={formsCss.field}
                            onChange={() => clearSchoolYearSelect()}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="to"
                            errorRes={errors.to}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                            css={formsCss.field}
                        />
                    </div>
                </div>

                {formError && (
                    <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                        {formError}
                    </div>
                )}

                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        Generate report
                    </Button>
                </div>
            </form>
        ) : (
            <div />
        );
    }
}

const FORM_NAME = "OfficeHoursReport";

const selector = formValueSelector(FORM_NAME);

const validate = values => {
    const requiredFields = {
        from: "Required",
        to: "Required",
        users: "Required",
    };

    if (!!values.from && !!values.to && values.from > values.to)
        return { to: "End date must be greater than start date" };

    return validateRequiredFields(requiredFields, values);
};

const mapStateToProps = ({ form }) => ({
    formSchoolyear: selector({ form }, "schoolyear"),
});

const dispatchToProps = {
    updateForm: (key, newValue) => change(FORM_NAME, key, newValue),
};

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
})(withStyles(formsCss)(BookingHistoryForm));

export default connect(mapStateToProps, dispatchToProps)(form);
