import { Component } from "react";
import { connect } from "react-redux";
import { TableComponent, RedirectButton } from "../../../common/components/HowTo";
import actionCreators from "../../../store/HowTo/Category/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { prepareFilterQuery } from "../../../common/services/utils";
import { updateListOnFilterChange } from "../../../common/services/FilterList";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PromptDialog from "../../../common/components/PromptDialog";
import FilterRow from "../../../common/components/FilterRow";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import { statusCallback, permissionCallback } from "../../../common/services/tableCallbacks";
import formsCss from "../../../common/styles/forms.css";
import {
    EditIcon,
    PostIcon,
    DeleteIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    AutoRenewIcon,
} from "../../../common/components/icons";

const decorators = [
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            name: tableCss.columnWidth300,
        },
    },
];

const callbacks = {
    permissionLevel: permissionCallback,
    status: statusCallback,
};

const buttons = items => index => [
    {
        path: `/knowledge-base/categories/edit/${items[index] ? items[index].id : ""}`,
        label: "Edit",
        fnLabel: "push",
        icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/knowledge-base/categories/${items[index] ? items[index].id : ""}/articles`,
        label: "Articles",
        fnLabel: "push",
        icon: <PostIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Up",
        fnLabel: "moveup",
        icon: <ArrowUpIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Down",
        fnLabel: "movedn",
        icon: <ArrowDownIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

const columns = ["id", "status", "name", "description"];

class HowToCategoryListContainer extends Component {
    state = {
        filter: "",
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
        categoryId: null,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removeCategory({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    componentDidMount() {
        const { setTitle, getListCategory } = this.props;
        const { search } = this.props.location;
        if (search) {
            const id = search.split("=")[1];
            this.setState({ categoryId: JSON.parse(id) });
        }
        const { filter$ } = this.state;
        setTitle("Knowledge Base \u203A Categories");
        getListCategory(prepareFilterQuery(this.state.filter));
        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                updateListOnFilterChange({
                    getFn: getListCategory,
                    filter: term,
                })
            );
        });
    }

    onFilterChangeHandler = ({ target: { value } }) => {
        const { filter$ } = this.state;
        this.setState({ filter: value }, () => filter$.next({ filter: this.state.filter }));
    };

    onLoadMoreClickHandler = page => () => {
        const { filter } = this.state;
        this.props.loadMoreCategories(prepareFilterQuery({ filter, page }));
    };

    componentWillUnmount() {
        this.props.clearTitle();
    }

    remove = itemID => {
        this.handleOpen(itemID);
    };

    moveDown = itemID => {
        const { moveDownCategory } = this.props;
        moveDownCategory({ id: itemID });
    };

    moveUp = itemID => {
        const { moveUpCategory } = this.props;
        moveUpCategory({ id: itemID });
    };

    render() {
        const {
            categories,
            history: { push },
            nextPage,
            count,
        } = this.props;
        const { filter, categoryId } = this.state;
        const { remove, moveUp, moveDown } = this;

        return (
            <div>
                <div css={globalsCss.actions}>
                    <RedirectButton
                        label="Add new category"
                        url="/knowledge-base/categories/new"
                        {...{ push }}
                    />
                </div>
                <Paper elevation={1}>
                    <FilterRow filter={filter} onFilterChangeHandler={this.onFilterChangeHandler} />
                    <TableComponent
                        items={
                            categoryId
                                ? categories.filter(({ id }) => id === categoryId)
                                : categories
                        }
                        fns={{ push, remove, moveup: moveUp, movedn: moveDown }}
                        buttons={buttons(
                            categoryId
                                ? categories.filter(({ id }) => id === categoryId)
                                : categories
                        )}
                        callbacks={callbacks}
                        decorators={decorators}
                        columns={columns}
                    />

                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this category? All articles content will be
                        lost!
                    </PromptDialog>
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {categories.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListCategory: actionCreators.getListCategory.create,
    loadMoreCategories: actionCreators.loadMoreCategories.create,
    removeCategory: actionCreators.removeCategory.create,
    moveUpCategory: actionCreators.moveUpCategory.create,
    moveDownCategory: actionCreators.moveDownCategory.create,
};
const mapStateToProps = ({ HowToCategory }) => ({
    categories: HowToCategory.categories.data,
    count: HowToCategory.categories.count,
    nextPage: HowToCategory.categories.nextPage,
});
export default connect(mapStateToProps, dispatchToProps)(HowToCategoryListContainer);
