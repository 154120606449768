import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    FormProvider,
    RHFTextField,
    RHFAutoComplete,
    RHFRadioGroup,
    RHFSelect,
    RHFMultiCheckbox,
    RHItemsList,
    RHFTimepicker,
    RHFDatepicker,
} from "src/common/components/RHF-Fields";
import { CurrentFormType } from "src/types/generic";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "src/common/styles/globals.css";
import formsCss from "src/common/styles/forms.css";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import actionCreators from "../../../store/Notification/actionCreators";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    CircularProgress,
    Slide,
} from "@material-ui/core";
import { TimetableNotification, NotificationDelivery, MessageType } from "../types/Notification";
import { default as autoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { rawUpdateListOnFilterChange } from "../../../common/services/FilterList";
import { convertFormToNotification, groupLabel, moduleLabel } from "../containers/helpers";
import { LseModule } from "../types/TeachingDetails";
import { LseGroup } from "../types/LseGroups";
import { TableComponent } from "../../../common/components/DefaultTable";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import { AutoRenewIcon } from "../../../common/components/icons";
import tableCss from "../../../common/styles/table.css";
import { isValidEmail } from "../../../common/services/formHelpers";
import { Receipients } from "../containers/PreviewTimetableNotification";
import { NotificationPreview } from "./NotificationPreview";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export interface TimetableNotificationFormValues extends TimetableNotification {
    module: LseModule;
    group: string; // '*' = All Groups
    teachings: string[]; // ['*'] = All Teachings
    selectedGroup?: any;
}

const TimetableNotificationSchema = () =>
    yup.object().shape({
        module: yup
            .object()
            .typeError("Required")
            .required("Required"),
        type: yup
            .string()
            .typeError("Required")
            .required("Required"),
        scheduledAt: yup.mixed().when(["status"], status => {
            if (status === NotificationDelivery.SCHEDULE) {
                return yup
                    .date()
                    .required("Required")
                    .typeError("Required")
                    .min(new Date(), "Scheduled date must be in the future");
            } else {
                return yup.mixed().notRequired();
            }
        }),
        sendAs: yup.mixed().when(["type"], type => {
            if (type === MessageType.EMAIL || type === MessageType.EMAIL_AND_MOBILE) {
                return yup
                    .string()
                    .required("Required")
                    .typeError("Required");
            } else {
                return yup.mixed().notRequired();
            }
        }),
        message: yup.mixed().when(["type"], type => {
            if (type === MessageType.EMAIL || type === MessageType.EMAIL_AND_MOBILE) {
                return yup
                    .string()
                    .required("Required")
                    .typeError("Required");
            } else {
                return yup.mixed().notRequired();
            }
        }),
        mobileMessage: yup.mixed().when(["type"], type => {
            if (type === MessageType.MOBILE || type === MessageType.EMAIL_AND_MOBILE) {
                return yup
                    .string()
                    .required("Required")
                    .typeError("Required");
            } else {
                return yup.mixed().notRequired();
            }
        }),
        subject: yup.mixed().when(["type"], type => {
            if (type === MessageType.EMAIL || type === MessageType.EMAIL_AND_MOBILE) {
                return yup
                    .string()
                    .required("Required")
                    .typeError("Required");
            } else {
                return yup.mixed().notRequired();
            }
        }),
        additionalEmail: yup
            .mixed()
            .test("additionalEmailHasValue", "List contains invalid email(s)", (value, context) => {
                let res = true;
                for (const el of value || []) {
                    if (!isValidEmail(el)) {
                        res = false;
                        break;
                    }
                }
                return res;
            }),
    });

interface OwnProps {
    initialValues: TimetableNotification;
    formType: CurrentFormType;
    error?: string;
    errors?: { [key: string]: string };
    onSubmit: (data: TimetableNotificationFormValues) => void;
    push: (pathname: string) => void;
}

const TimetableNotificationForm: FC<OwnProps> = ({
    initialValues,
    formType,
    error,
    errors,
    onSubmit,
    push,
}) => {
    const [initiated, setInitiated] = useState<boolean>(false);

    const dispatch = useDispatch();
    const notificationsEmails = useSelector(
        (state: AppState) => state.Notification.notificationsEmails
    );
    const groups = useSelector((state: AppState) => state.Notification.lseGroups);
    const teachingsDetails = useSelector((state: AppState) => state.Notification.teachingsDetails);
    const templates = useSelector((state: AppState) => state.Notification.templates.data);
    const notificationEmailPreview = useSelector(
        (state: AppState) => state.Notification.notificationEmailPreview
    );

    const [modulesFilter$, setModulesFilter$] = useState(new Subject());
    const [modulesSubscription, setModulesSubscription] = useState<Subscription | undefined>(
        undefined
    );
    const modules = useSelector((state: AppState) => state.AutoComplete.lseModules);
    const getModules = params => dispatch(autoCompleteActionCreators.getLseModules.create(params));

    const [openStudentsModal, setOpenStudentsModal] = useState<boolean>(false);
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

    const methods = useForm<TimetableNotificationFormValues>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(TimetableNotificationSchema()),
        defaultValues: initialValues,
    });
    const {
        handleSubmit,
        formState,
        reset,
        setValue,
        getValues,
        watch,
        formState: { errors: formErrors },
    } = methods;

    const watchModule = watch("module");
    const watchGroup = watch("group");
    const watchTeachings = watch("teachings");
    const watchTemplate = watch("template");
    const watchType = watch("type");
    const watchStatus = watch("status");
    const watchMessage = watch("message");
    const watchMobileMessage = watch("mobileMessage");

    const [selectedGroup, setSelectedGroup] = useState<LseGroup | undefined>(undefined);
    const [formToNotification, setFormToNotification] = useState<any>(undefined);

    useEffect(() => {
        setModulesSubscription(
            modulesFilter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                rawUpdateListOnFilterChange({
                    getFn: getModules,
                    filter: term,
                });
            })
        );
        dispatch(actionCreators.getTemplateList.create({ size: 100 })); // get all templates

        return () => {
            if (modulesSubscription) {
                modulesSubscription.unsubscribe();
                setModulesSubscription(undefined);
            }

            dispatch(actionCreators.clearNotification.create());
            dispatch(actionCreators.setLseModuleGroups.create([]));
            dispatch(actionCreators.clearSelectedTeachingsStudents.create([]));

            setInitiated(false);
        };
    }, []);

    useEffect(() => {
        reset(initialValues);
        if (initialValues.module) {
            setInitiated(false);
            dispatch(actionCreators.getLseModuleGroups.create(initialValues.module.teachingCode));
        } else setInitiated(true);
    }, [initialValues.id]);

    useEffect(() => {
        if (!initiated && groups && groups.length > 0) {
            const selected = groups.find((g: LseGroup) => g.teachingGroup === initialValues.group);
            if (initialValues.group === "*") {
                dispatch(
                    actionCreators.getSelectedTeachingsStudents.create({
                        page: 1,
                        teachingCode: initialValues.module.teachingCode,
                    })
                );
            } else if (selected) {
                setSelectedGroup(selected);
                setValue("selectedGroup", selected);
                dispatch(
                    actionCreators.getSelectedTeachingsStudents.create({
                        page: 1,
                        teachingCode: selected.teachingCode,
                        teachingGroup: selected.teachingGroup,
                    })
                );
            }
            setInitiated(true);
        }
    }, [initiated, initialValues, groups]);

    useEffect(() => {
        if (!notificationsEmails) dispatch(actionCreators.getNotificationEmails.create());
    }, [notificationsEmails]);

    useEffect(() => {
        if (watchTemplate && initiated) {
            const selectedTemplate = templates.find(i => i.id == watchTemplate);
            if (selectedTemplate) {
                setValue("message", selectedTemplate.content);
                setValue("subject", selectedTemplate.subject);
                setValue("mobileMessage", selectedTemplate.name);
                setValue("sendAs", selectedTemplate.sendAs);
            }
        }
    }, [watchTemplate && watchTemplate.id ? watchTemplate.id : watchTemplate]);

    useEffect(() => {
        if (watchModule && !!initiated) {
            setValue("group", "*");
            setValue("teachings", ["*"]);
            dispatch(actionCreators.setLseModuleGroups.create([]));
            dispatch(actionCreators.clearSelectedTeachingsStudents.create([]));
            setSelectedGroup(undefined);
            setValue("selectedGroup", undefined);
            dispatch(actionCreators.getLseModuleGroups.create(watchModule.teachingCode));
            dispatch(
                actionCreators.getSelectedTeachingsStudents.create({
                    page: 1,
                    teachingCode: watchModule.teachingCode,
                })
            );
        }
    }, [watchModule && watchModule.id ? watchModule.id : watchModule]);

    useEffect(() => {
        if (watchGroup && !!initiated) {
            if (watchGroup !== "*") {
                const selected = groups.find((g: LseGroup) => g.teachingGroup === watchGroup);
                if (selected) {
                    setSelectedGroup(selected);
                    setValue("selectedGroup", selected);
                    dispatch(
                        actionCreators.getSelectedTeachingsStudents.create({
                            page: 1,
                            teachingCode: selected.teachingCode,
                            teachingGroup: selected.teachingGroup,
                        })
                    );
                }
            } else if (watchGroup === "*") {
                setSelectedGroup(undefined);
                setValue("selectedGroup", undefined);
                setValue("teachings", ["*"]);
                dispatch(
                    actionCreators.getSelectedTeachingsStudents.create({
                        page: 1,
                        teachingCode: watchModule.teachingCode,
                    })
                );
            }
        }
    }, [watchGroup]);

    return (
        <Paper elevation={1}>
            <div css={globalsCss.inner}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-xs-12">
                            <RHFRadioGroup
                                name="status"
                                options={[
                                    NotificationDelivery.DRAFT,
                                    NotificationDelivery.IMMEDIATELLY,
                                    NotificationDelivery.SCHEDULE,
                                    ...(initialValues.status === NotificationDelivery.SENT
                                        ? [NotificationDelivery.SENT]
                                        : []),
                                ]}
                                getOptionLabel={[
                                    "Draft",
                                    initialValues.status === NotificationDelivery.IMMEDIATELLY
                                        ? "Sending"
                                        : "Send immediately",
                                    "Schedule at",
                                    ...(initialValues.status === NotificationDelivery.SENT
                                        ? ["Sent"]
                                        : []),
                                ]}
                            />
                        </div>
                    </div>

                    {watchStatus === NotificationDelivery.SCHEDULE && (
                        <div css={[formsCss.scheduledAt]}>
                            <RHFDatepicker
                                name="scheduledAt"
                                placeholder="Enter Date"
                                errorRes={errors && errors.scheduledAt}
                            />
                            <RHFTimepicker name="scheduledAt" placeholder="HH:MM" />
                        </div>
                    )}
                    <hr />

                    <div className="row">
                        <div className="col-xs-12">
                            <label css={formsCss.labelBig}>Select Module</label>
                        </div>
                        <div className="col-sm-8 col-xs-12">
                            <RHFAutoComplete
                                name="module"
                                handleInput={value => {
                                    if (value) {
                                        return modulesFilter$.next({ filter: value });
                                    }
                                }}
                                dataSource={modules}
                                label="Select Module"
                                labelCallback={item => moduleLabel(item)}
                                onDroppedFocus={() =>
                                    dispatch(autoCompleteActionCreators.clearLseModules.create())
                                }
                                single
                                noAvatar
                            />
                        </div>

                        {watchModule && (
                            <div className="col-sm-4 col-xs-12">
                                <RHFSelect
                                    name="group"
                                    label="Select group code"
                                    errorRes={errors && errors.group}
                                    options={[
                                        {
                                            value: "*",
                                            label: "All groups",
                                        },
                                        ...(groups
                                            ? groups.map((item: LseGroup) => {
                                                  return {
                                                      value: item.teachingGroup,
                                                      label: groupLabel(item),
                                                  };
                                              })
                                            : []),
                                    ]}
                                />
                            </div>
                        )}
                    </div>

                    {watchModule && (!watchGroup || watchGroup === "*") && (
                        <div css={[globalsCss.helpers.mt20]}>
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    Students:
                                    <span>&nbsp;</span>
                                    <span>
                                        {teachingsDetails && teachingsDetails.count === null ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                                thickness={2}
                                            />
                                        ) : (
                                            <span>
                                                <strong>{teachingsDetails.count}</strong>
                                                <span>&nbsp;&nbsp;</span>
                                                {teachingsDetails && teachingsDetails.count > 0 && (
                                                    <Button
                                                        onClick={() => setOpenStudentsModal(true)}
                                                        variant="contained"
                                                        color="secondary"
                                                        css={formsCss.btnSubmit}
                                                    >
                                                        Show
                                                    </Button>
                                                )}
                                            </span>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    {watchModule && selectedGroup && (
                        <RHFMultiCheckbox
                            name="teachings"
                            label={`(${watchModule.teachingCode} ${selectedGroup.teachingGroup}) Teachings`}
                            options={
                                watchGroup && selectedGroup.teachings
                                    ? ["*", ...selectedGroup.teachings.map(i => `${i.id}`)]
                                    : []
                            }
                            getOptionLabel={
                                watchGroup && selectedGroup.teachings
                                    ? [
                                          "All teachings",
                                          ...selectedGroup.teachings.map(teaching => (
                                              <div key={teaching.id}>
                                                  <span css={globalsCss.block}>
                                                      {teaching.day.substring(0, 3)}{" "}
                                                      {teaching.startTime} - {teaching.finishTime}
                                                  </span>
                                                  <div css={globalsCss.listingRow}>
                                                      <div css={globalsCss.listingRowTitle}>
                                                          Week
                                                      </div>
                                                      <div css={globalsCss.listingRowValue}>
                                                          {teaching.weeksNumber}
                                                      </div>
                                                  </div>
                                                  <div css={globalsCss.listingRow}>
                                                      <div css={globalsCss.listingRowTitle}>
                                                          Room
                                                      </div>
                                                      <div css={globalsCss.listingRowValue}>
                                                          {teaching.roomId}
                                                      </div>
                                                  </div>
                                              </div>
                                          )),
                                      ]
                                    : []
                            }
                            handleControlledSelection={(clicked: string) => {
                                if (clicked === "*") {
                                    setValue("teachings", ["*"]);
                                } else {
                                    const exists = watchTeachings.find(i => i === clicked);
                                    if (exists && exists.length > 0) {
                                        setValue(
                                            "teachings",
                                            watchTeachings.filter(i => i !== clicked)
                                        );
                                    } else {
                                        const newValue = watchTeachings.filter(i => i !== "*");
                                        newValue.push(clicked);
                                        setValue("teachings", newValue);
                                    }
                                }
                            }}
                        />
                    )}

                    {!!errors && errors.audience && (
                        <div css={formsCss.genericError}>{errors?.audience}</div>
                    )}

                    {watchModule && watchGroup && watchGroup !== "*" && (
                        <div css={[globalsCss.helpers.mt20]}>
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    Students:
                                    <span>&nbsp;</span>
                                    <span>
                                        {teachingsDetails && teachingsDetails.count === null ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                                thickness={2}
                                            />
                                        ) : (
                                            <span>
                                                <strong>{teachingsDetails.count}</strong>
                                                <span>&nbsp;&nbsp;</span>
                                                {teachingsDetails && teachingsDetails.count > 0 && (
                                                    <Button
                                                        onClick={() => setOpenStudentsModal(true)}
                                                        variant="contained"
                                                        color="secondary"
                                                        css={formsCss.btnSubmit}
                                                    >
                                                        Show
                                                    </Button>
                                                )}
                                            </span>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    <hr />

                    <div className="row">
                        <div className="col-xs-12">
                            <label css={formsCss.labelBig}>{"Notification Type"}</label>
                            <RHFRadioGroup
                                name="type"
                                options={[
                                    MessageType.EMAIL,
                                    MessageType.EMAIL_AND_MOBILE,
                                    MessageType.MOBILE,
                                ]}
                                getOptionLabel={[
                                    "Email only",
                                    "Email and Mobile",
                                    <div css={formsCss.inlineAlignTop} key={3}>
                                        <div css={formsCss.inlineItem}>Mobile only</div>
                                        <div css={formsCss.inlineItem}>
                                            <em css={{ fontSize: 13 }}>
                                                (will be sent only to Students registered in Student
                                                Hub)
                                            </em>
                                        </div>
                                    </div>,
                                ]}
                            />
                        </div>
                    </div>

                    {watchType !== MessageType.MOBILE && (
                        <>
                            <hr />
                            <div className="row">
                                <div className="col-sm-4 col-xs-12">
                                    <RHFSelect
                                        name="template"
                                        label="Select Email Template"
                                        errorRes={errors && errors.template}
                                        options={
                                            templates
                                                ? templates.map(t => {
                                                      return {
                                                          value: t.id,
                                                          label: t.name,
                                                      };
                                                  })
                                                : []
                                        }
                                    />
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                    <RHFTextField
                                        name="subject"
                                        label="Email Subject"
                                        errorRes={errors && errors.subject}
                                        fullWidth
                                    />
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                    <RHFSelect
                                        name="sendAs"
                                        label="Send as"
                                        errorRes={errors && errors.sendAs}
                                        options={
                                            notificationsEmails
                                                ? notificationsEmails.map(item => {
                                                      return {
                                                          value: item.key,
                                                          label: `${item.name} <${item.email}>`,
                                                      };
                                                  })
                                                : []
                                        }
                                    />
                                </div>
                                <div className="col-xs-12">
                                    <RHFTextField
                                        name="message"
                                        label="Email Template Preview"
                                        errorRes={errors && errors.message}
                                        rows={4}
                                        rowsMax={25}
                                        fullWidth
                                        multiline
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <RHFTextField
                                        name="additionalEmailText"
                                        label="Additional Text (to appear in email):"
                                        errorRes={errors && errors.additionalEmailText}
                                        rows={1}
                                        rowsMax={4}
                                        fullWidth
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <RHItemsList
                                        name="additionalEmail"
                                        label="Additional recipients:"
                                        placeholder="Enter email address - then press enter to confirm"
                                        errorRes={errors && errors.additionalEmail}
                                        validateItem={isValidEmail}
                                        validateError="Invalid email address"
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {watchType !== MessageType.EMAIL && (
                        <>
                            <hr />
                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <RHFTextField
                                        name="mobileMessage"
                                        label="Mobile notification message"
                                        errorRes={errors && errors.mobileMessage}
                                        rows={1}
                                        rowsMax={4}
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {error && (
                        <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                            {error}
                        </div>
                    )}
                    {formErrors && Object.keys(formErrors).length > 0 && (
                        <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                            Please check for errors on this page
                        </div>
                    )}
                    <div css={formsCss.actions}>
                        <Button
                            disabled={
                                !watchModule ||
                                (watchType === MessageType.MOBILE && !watchMobileMessage) ||
                                (watchType === MessageType.EMAIL && !watchMessage) ||
                                (watchType === MessageType.EMAIL_AND_MOBILE &&
                                    (!watchMessage || !watchMobileMessage))
                            }
                            onClick={() => setOpenPreviewModal(true)}
                            variant="contained"
                            color="secondary"
                            css={formsCss.btnSubmit}
                        >
                            Preview
                        </Button>
                        <Button
                            disabled={formState.isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            css={formsCss.btnSubmit}
                        >
                            {formType === CurrentFormType.NEW
                                ? "Add timetable notification"
                                : "Save timetable notification"}
                        </Button>
                        <Button
                            disabled={formState.isSubmitting}
                            onClick={() => push("/notifications/timetable/list")}
                            css={formsCss.btnBack}
                        >
                            Back to the list
                        </Button>
                    </div>
                </FormProvider>

                <Dialog
                    open={openStudentsModal}
                    onClose={() => setOpenStudentsModal(false)}
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="md"
                    disableBackdropClick
                >
                    {openStudentsModal && (
                        <>
                            <DialogTitle>
                                {selectedGroup ? (
                                    <span>
                                        {selectedGroup.teachingCode} {selectedGroup.teachingGroup}{" "}
                                        {selectedGroup.teachingType
                                            ? `(${selectedGroup.teachingType})`
                                            : ""}{" "}
                                        students
                                    </span>
                                ) : (
                                    <span>{watchModule.teachingCode} students</span>
                                )}
                            </DialogTitle>
                            <DialogContent>
                                <TableComponent
                                    items={teachingsDetails.data.map((i, index) => {
                                        return { ...i, id: index };
                                    })}
                                    decorators={[
                                        {
                                            type: decorateTableItemsJobTypes.TRANSLATE,
                                            payload: {
                                                knownAs: "First name",
                                                lastName: "Last name",
                                                playRef: "Playref",
                                                email: "Email address",
                                            },
                                        },
                                    ]}
                                    columns={["knownAs", "lastName", "playRef", "email"]}
                                />
                                {teachingsDetails.nextPage > 0 && (
                                    <div css={tableCss.loadMore}>
                                        <Button
                                            onClick={() => {
                                                dispatch(
                                                    actionCreators.getSelectedTeachingsStudents.create(
                                                        {
                                                            page: teachingsDetails.nextPage,
                                                            teachingCode: watchModule.teachingCode,
                                                            ...(selectedGroup &&
                                                            selectedGroup.teachingGroup
                                                                ? {
                                                                      teachingGroup:
                                                                          selectedGroup.teachingGroup,
                                                                  }
                                                                : {}),
                                                        }
                                                    )
                                                );
                                            }}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            <AutoRenewIcon css={formsCss.btnIcon} />
                                            Load more
                                        </Button>
                                        <span css={tableCss.loadMoreLabel}>
                                            <small>
                                                (showing {teachingsDetails.data.length} out of{" "}
                                                {teachingsDetails.count})
                                            </small>
                                        </span>
                                    </div>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenStudentsModal(false)}>Close</Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>

                <Dialog
                    open={openPreviewModal}
                    onClose={() => setOpenPreviewModal(false)}
                    TransitionComponent={Transition}
                    onEntered={() => {
                        const converted = convertFormToNotification(getValues());
                        setFormToNotification(converted);
                        dispatch(
                            actionCreators.getNotificationEmailPreview.create({
                                audience: converted.audience,
                                message: converted.message,
                                subject: converted.subject,
                                additionalEmailText: converted.additionalEmailText,
                            })
                        );
                    }}
                    onExited={() => {
                        setFormToNotification(null);
                        dispatch(actionCreators.setNotificationEmailPreview.create(null));
                    }}
                    fullWidth
                    maxWidth="md"
                    disableBackdropClick
                >
                    {openPreviewModal && formToNotification ? (
                        <>
                            <DialogTitle>Timetable Notification Preview</DialogTitle>
                            <DialogContent>
                                <div css={globalsCss.helpers.mt15}>
                                    <Receipients
                                        notification={formToNotification as TimetableNotification}
                                    />
                                    {formToNotification.additionalEmail &&
                                        formToNotification.additionalEmail.length > 0 && (
                                            <div>
                                                <strong>Additional recipients:</strong>
                                                <br />
                                                {formToNotification.additionalEmail.map(i => (
                                                    <div key={i}>
                                                        {i}
                                                        <br />
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                </div>

                                <hr />

                                <div css={globalsCss.helpers.mt35}>
                                    <NotificationPreview
                                        notification={formToNotification}
                                        notificationsEmails={notificationsEmails}
                                        notificationEmailPreview={notificationEmailPreview}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenPreviewModal(false)}>
                                    Back to edit
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <div />
                    )}
                </Dialog>
            </div>
        </Paper>
    );
};

export default TimetableNotificationForm;
