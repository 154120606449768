import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import formsCss from "../../../styles/forms.css";

const CheckboxWithLabel = withStyles(formsCss)(({ label, classes, ...props }) => (
    <FormControlLabel
        control={
            <Checkbox
                classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checkboxChecked,
                }}
                {...props}
            />
        }
        classes={{
            root: classes.formControlRoot,
            label: classes.radioLabel,
        }}
        label={label}
    />
));

export default CheckboxWithLabel;
