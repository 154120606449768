import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import formsCss from "../../styles/forms.css";

export const renderSelectField = ({
    input: { value, onChange, onBlur, ...inputRest },
    label,
    meta: { touched, error },
    errorRes,
    options,
    name,
    classes,
    formatCallback = value => value, // converts redux value to string
    parseCallback = value => value, // converts string to redux value
    ...custom
}) => (
    <TextField
        select
        error={!!touched && (!!error || !!errorRes)}
        placeholder={label}
        label={label}
        helperText={touched && (error || errorRes)}
        fullWidth
        margin="normal"
        color="primary"
        InputProps={{
            classes: {
                root: classes.inputRoot,
                error: classes.inputError,
            },
        }}
        {...inputRest}
        {...custom}
        value={formatCallback(value)}
        onChange={({ target: { value } }) => onChange(parseCallback(value))}
        onBlur={() => onBlur()} // don't want redux-form update form value from DOM element
        SelectProps={{
            MenuProps: {
                MenuListProps: {
                    disablePadding: true,
                    dense: true,
                },
            },
        }}
    >
        {options.map((item, index) => (
            <MenuItem key={`select${name}.${index}`} value={formatCallback(item.value)}>
                {item.label}
            </MenuItem>
        ))}
    </TextField>
);

export const RenderSelectField = withStyles(formsCss)(renderSelectField);
