import ActionCreator from "../../actionCreator";

export default {
    getListGroup: ActionCreator("GET_LIST_CHANNEL_GROUP"),
    createListGroup: ActionCreator("CREATE_LIST_CHANNEL_GROUP"),
    loadMoreGroups: ActionCreator("LOAD_MORE_CHANNEL_GROUPS"),
    updateListGroup: ActionCreator("UPDATE_LIST_CHANNEL_GROUP"),
    postGroup: ActionCreator("POST_CHANNEL_GROUP"),
    getOneGroup: ActionCreator("GET_ONE_CHANNEL_GROUP"),
    clearGroup: ActionCreator("CLEAR_CHANNEL_GROUP"),
    putGroup: ActionCreator("PUT_CHANNEL_GROUP"),
    updateOneGroup: ActionCreator("UPDATE_ONE_CHANNEL_GROUP"),
    pushListGroups: ActionCreator("PUSH_LIST_CHANNEL_GROUP"),
    removeGroup: ActionCreator("REMOVE_CHANNEL_GROUP"),
    errorResponse: ActionCreator("ERROR_RESPONSE_CHANNEL_GROUP"),
    clearErrors: ActionCreator("CLEAR_ERROR_CHANNEL_GROUP"),
};
