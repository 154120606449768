import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import HolidayForm from "./HolidayForm/";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import actionCreators from "../../../store/Settings/SchoolYears/actionCreators";
import formsCss from "../../../common/styles/forms.css";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";
import { dateFormatCallback } from "../../../common/services/tableCallbacks";
import { HolidayCard } from "./HolidayCard";
import Button from "@material-ui/core/Button";

class HolidaysSchoolYearsContainer extends Component {
    componentDidMount() {
        const { setTitle, getOneSchoolYear, match } = this.props;
        setTitle("Settings \u203A Academic Years");
        getOneSchoolYear({ id: match.params.id });
    }

    componentWillUnmount() {
        const { clearTitle, clearSchoolYear } = this.props;
        clearSchoolYear();
        clearTitle();
    }

    onSubmit = values => {
        const { addSchoolYearHoliday, match } = this.props;
        addSchoolYearHoliday({ ...values, id: match.params.id });
    };

    render() {
        const {
            schoolYear,
            removeSchoolYearHoliday,
            history: { push },
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;

        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.innerSmallBottom}>
                        <h2 css={globalsCss.subtitleTitle}>Academic Year Holidays</h2>
                        {error && <div css={formsCss.genericError}>{error}</div>}
                        {schoolYear.id > 0 && (
                            <div>
                                <div className="row">
                                    <div
                                        className="col-xs-12 col-sm-12"
                                        css={globalsCss.helpers.mb15}
                                    >
                                        <div>{schoolYear.name}</div>
                                    </div>
                                    <div
                                        className="col-xs-12 col-sm-4"
                                        css={globalsCss.helpers.mb15}
                                    >
                                        <strong css={formsCss.labelSmall}>Autumn Term</strong>
                                        <div>
                                            {dateFormatCallback(schoolYear, "startFirstTerm")}{" "}
                                            <br />
                                            {dateFormatCallback(schoolYear, "endFirstTerm")}
                                        </div>
                                    </div>
                                    <div
                                        className="col-xs-12 col-sm-4"
                                        css={globalsCss.helpers.mb15}
                                    >
                                        <strong css={formsCss.labelSmall}>Winter Term</strong>
                                        <div>
                                            {dateFormatCallback(schoolYear, "startSecondTerm")}{" "}
                                            <br />
                                            {dateFormatCallback(schoolYear, "endSecondTerm")}
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-4">
                                        <strong css={formsCss.labelSmall}>Spring Term</strong>
                                        <div>
                                            {dateFormatCallback(schoolYear, "startThirdTerm")}{" "}
                                            <br />
                                            {dateFormatCallback(schoolYear, "endThirdTerm")}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <HolidayForm
                                    {...{
                                        schoolYear,
                                        onSubmit,
                                        errors,
                                        removeSchoolYearHoliday,
                                    }}
                                />
                                {schoolYear.schoolYearHolidays && (
                                    <div>
                                        <div className="row">
                                            {schoolYear.schoolYearHolidays.map(holiday => (
                                                <div
                                                    className="col-sm-4 col-xs-12"
                                                    css={globalsCss.helpers.mt25}
                                                    key={holiday.id}
                                                >
                                                    <HolidayCard
                                                        schoolYearId={schoolYear.id}
                                                        removeSchoolYearHoliday={
                                                            removeSchoolYearHoliday
                                                        }
                                                        holiday={holiday}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div css={formsCss.actionsSmall}>
                                    <Button
                                        onClick={() => push("/settings/school-years")}
                                        css={formsCss.btnBack}
                                    >
                                        Back to the list
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneSchoolYear: actionCreators.getOneSchoolYear.create,
    clearSchoolYear: actionCreators.clearSchoolYear.create,
    addSchoolYearHoliday: actionCreators.addSchoolYearHoliday.create,
    removeSchoolYearHoliday: actionCreators.removeSchoolYearHoliday.create,
};
const mapStateToProps = ({ SettingsSchoolYears }) => ({
    schoolYear: SettingsSchoolYears.schoolYear,
    errorRes: SettingsSchoolYears.errorRes,
});
export default connect(mapStateToProps, dispatchToProps)(HolidaysSchoolYearsContainer);
