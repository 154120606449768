import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/Channels/Events/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as autocompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { TableComponent, RedirectButton } from "../../../common/components/Channels";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";

import PromptDialog from "../../../common/components/PromptDialog";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";

import parseDates from "../../../common/services/parseDates";

import ChannelDateFilterRow from "../../../common/components/ChannelDateFilterRow";
import { statusCallback, postContentCallback } from "../../../common/services/tableCallbacks";
import { MainTitle } from "../../../common/components/MainTitle";
import formsCss from "../../../common/styles/forms.css";
import {
    PreviewIcon,
    EditIcon,
    DeleteIcon,
    AutoRenewIcon,
    ArchiveIcon,
    UnarchiveIcon,
} from "../../../common/components/icons";
import { withQueryParams } from "../../../common/components/withQueryParams";
import { prepareFilterQuery } from "../../../common/services/utils";

const callbacks = {
    primaryChannel: item => (item.channel ? item.channel.name : ""),
    status: statusCallback,
    cleanedDescription: postContentCallback,
};

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            publicationDate: "Publication Date",
            primaryChannel: "Primary channel",
            cleanedDescription: "Description",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            publicationDate: tableCss.columnWidth200,
            primaryChannel: tableCss.columnWidth250,
        },
    },
];

const buttons = items => index => [
    {
        path: `events/${items[index] ? items[index].id : ""}`,
        label: "Preview",
        fnLabel: "push",
        icon: <PreviewIcon css={tableCss.actionsMenuItemIcon} />,
    },
    ...(items[index].status === "archived"
        ? [
              {
                  path: `events/edit/${items[index] ? items[index].id : ""}`,
                  label: "Unarchive",
                  fnLabel: "push",
                  icon: <UnarchiveIcon css={tableCss.actionsMenuItemIcon} />,
              },
          ]
        : [
              {
                  path: `events/edit/${items[index] ? items[index].id : ""}`,
                  label: "Edit",
                  fnLabel: "push",
                  icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
              },
          ]),

    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
    },
    ...(items[index].status === "live"
        ? [
              {
                  path: `${items[index] ? items[index].id : null}`,
                  label: "Archive",
                  fnLabel: "archive",
                  icon: <ArchiveIcon css={tableCss.actionsMenuItemIcon} />,
              },
          ]
        : []),
];

class ChannelEventsListContainer extends Component {
    state = {
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removeEvent({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    remove = itemId => {
        this.handleOpen(itemId);
    };

    archive = itemId => {
        this.props.archiveEvent(itemId);
    };

    componentDidMount() {
        const { setTitle, getEventList, queryParams } = this.props;
        const { filter$ } = this.state;
        setTitle("Channels \u203A Events");

        const params = this.props.initWithQueryParams({
            channelId:
                this.props.location && this.props.location.state && this.props.location.state.id
                    ? parseInt(this.props.location.state.id)
                    : "",
            channelName:
                this.props.location && this.props.location.state && this.props.location.state.name
                    ? this.props.location.state.name
                    : "",
            from: "",
            to: "",
            filter: "",
            page: 1,
        });

        if (queryParams !== prepareFilterQuery(params)) {
            getEventList({ ...params, page: 1 });
        }

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(filter => {
            const params = this.props.setQueryParams({ ...filter, page: 1 });
            getEventList({ ...params, page: 1 });
        });
    }

    handler = ({ field, value }) => {
        const { filter$ } = this.state;

        if (field === "channel") {
            const params = this.props.setQueryParams({
                channelId: value.id ? parseInt(value.id) : "",
                channelName: value.name,
                page: 1,
            });
            filter$.next(params);
        } else {
            const params = this.props.setQueryParams({
                [field]: value
                    ? field === "from" || field === "to"
                        ? value.toISOString()
                        : value
                    : "",
                page: 1,
            });
            filter$.next(params);
        }
    };

    onDateChangeHandler = (field, newValue = undefined) => value => {
        this.handler({ field, value: newValue !== undefined ? newValue : value });
    };

    onFilterChangeHandler = ({ target: { value } }) => {
        this.handler({ field: "filter", value });
    };

    onChannelChangeHandler = value => {
        this.handler({ field: "channel", value });
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMoreEvents } = this.props;
        const params = this.props.setQueryParams({ page });
        loadMoreEvents(params);
    };

    render() {
        const {
            events,
            history: { push },
            nextPage,
            count,
            channels,
            getChannels,
            clearChannels,
        } = this.props;
        const { channelId, channelName, from, to, filter } = this.props.getQueryParams();
        const {
            onFilterChangeHandler,
            onChannelChangeHandler,
            onLoadMoreClickHandler,
            onDateChangeHandler,
            remove,
            archive,
        } = this;

        const channel = { id: channelId, name: channelName };

        return (
            <div>
                <MainTitle title="Events" type="[Channels]" />
                <div css={globalsCss.actions}>
                    <RedirectButton
                        label="Add new event"
                        url={`/channels/events/new`}
                        urlState={channel ? channel : undefined}
                        {...{ push }}
                    />
                </div>
                <Paper elevation={1}>
                    <ChannelDateFilterRow
                        {...{
                            from,
                            to,
                            filter,
                            channel,
                            onFilterChangeHandler,
                            onChannelChangeHandler,
                            onDateChangeHandler,
                            channels,
                            clearChannels,
                            getChannels,
                        }}
                    />
                    <TableComponent
                        items={events}
                        columns={["id", "status", "primaryChannel", "name", "publicationDate"]}
                        fns={{ push, remove, archive }}
                        buttons={buttons(events)}
                        decorators={decorators}
                        callbacks={callbacks}
                    />
                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this post?
                    </PromptDialog>
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {events.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getEventList: actionCreators.getListEvent.create,
    loadMoreEvents: actionCreators.loadMoreEvents.create,
    removeEvent: actionCreators.removeEvent.create,
    getChannels: autocompleteActionCreators.getChannels.create,
    clearChannels: autocompleteActionCreators.clearChannels.create,
    archiveEvent: actionCreators.archiveEvent.create,
};
const mapStateToProps = ({
    ChannelEvents: {
        events: { data, count, nextPage, queryParams },
    },
    AutoComplete: { channels },
}) => ({
    events: data.map(parseDates),
    count,
    nextPage,
    queryParams,
    channels,
});
export default connect(
    mapStateToProps,
    dispatchToProps
)(withQueryParams(ChannelEventsListContainer));
