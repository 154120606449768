import { spacing, colors, fontWeight } from "../../UIGlobals";

export const sidebarMenuStyle = {
    body: {
        margin: 0,
        padding: 0,
        listStyle: "none",
    },
    item: {
        margin: `0 0 ${spacing.space1}px`,
        padding: 0,
    },
    link: {
        display: "block",
        color: "rgba(0,0,0,0.87)",
        fontSize: 16,
        fontWeight: fontWeight.regular,
        letterSpacing: 0.2,
        paddingTop: spacing.space1,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space2,
        ":hover": {
            color: colors.secondaryAlt2,
        },
    },
    linkActive: {
        backgroundColor: colors.secondaryAlt2,
        color: colors.white,
        fontWeight: fontWeight.black,
        ":hover": {
            color: colors.white,
        },
    },
};
