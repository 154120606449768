import React, { FC, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import actionCreators from "../../../store/Notification/actionCreators";
import mainMenuActionCreators from "../../../store/MainMenu/actionCreators";
import { MainTitle } from "../../../common/components/MainTitle";
import formsCss from "../../../common/styles/forms.css";
import EmailTemplateForm from "../components/EmailTemplateForm";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import { CurrentFormType } from "src/types/generic";

interface MatchParams {
    id?: string;
}
const AddEditEmailTemplates: FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
    const dispatch = useDispatch();
    const template = useSelector((state: AppState) => state.Notification.template);
    const { error, errors } = useSelector((state: AppState) => state.Notification.errorRes);
    const { push } = history;

    useEffect(() => {
        dispatch(mainMenuActionCreators.setTitle.create("Notifications"));
        const id = match.params.id;

        if (id) dispatch(actionCreators.getTemplate.create(id));
        return () => {
            dispatch(mainMenuActionCreators.clearTitle.create());
        };
    }, []);

    const onSubmit = values => {
        if (template && template.id) dispatch(actionCreators.putTemplate.create(values));
        else dispatch(actionCreators.postTemplate.create(values));
    };

    return (
        <div>
            <MainTitle
                title={
                    template && template.id
                        ? "Edit Notification Template"
                        : "Add New Notification Template"
                }
            />
            <div css={formsCss.genericError}>{error}</div>
            <EmailTemplateForm
                {...{
                    formType: template && template.id ? CurrentFormType.EDIT : CurrentFormType.NEW,
                    initialValues: template,
                    onSubmit,
                    error,
                    errors,
                    push,
                }}
            />
        </div>
    );
};

export default AddEditEmailTemplates;
