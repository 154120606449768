import React from "react";
import BigCalendar from "react-big-calendar";
import moment from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./react-big-calendar.css";

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

const Calendar = props => {
    const newProps = {
        defaultView: "month",
        views: window.innerWidth < 768 ? ["month", "day", "agenda"] : ["month", "day"],
        defaultDate: new Date(),
        popup: true,
        step: 15,
        timeslots: 4,
        drilldownView: "day",
        formats: { timeGutterFormat: "h.mma" },
        ...props,
    };

    return <BigCalendar {...newProps} />;
};

export default Calendar;
