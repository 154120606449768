/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { FC, forwardRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import formsCss from "src/common/styles/forms.css";
import { useFormContext, Controller } from "react-hook-form";

interface OwnProps {
    name: string;
    placeholder?: string;
    label?: string;
    classes: any;
    errorRes?: string;
    myRef?: any;
}

const RHFTextField: FC<OwnProps & TextFieldProps> = forwardRef(
    ({ name, placeholder, label, classes, errorRes, myRef, ...other }, ref) => {
        const { control } = useFormContext();
        ref = myRef;
        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <TextField
                            fullWidth
                            margin="normal"
                            color="primary"
                            InputProps={{
                                classes: {
                                    root: classes.inputRoot,
                                    error: classes.inputError,
                                },
                            }}
                            error={!!error || !!errorRes}
                            helperText={
                                error && error.message ? error.message : errorRes ? errorRes : ""
                            }
                            placeholder={placeholder ? placeholder : label ? label : ""}
                            label={label}
                            {...other}
                            {...field}
                            value={field.value || ""}
                            inputRef={ref}
                        />
                    );
                }}
            />
        );
    }
);

const RenderTextField = withStyles(formsCss)(RHFTextField);

export default RenderTextField;
