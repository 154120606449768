import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/Channels/Groups/Members/actionCreators";
import { default as groupActionCreators } from "../../../../store/Channels/Groups/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { TableComponent, RedirectButton } from "../../../../common/components/Channels";
import { Button, Paper } from "@material-ui/core";
import { prepareFilterQuery } from "../../../../common/services/utils";
import filterListWithId from "../../../../common/services/FilterListWithId";

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import tableCss from "../../../../common/styles/table.css";

import {
    AutoRenewIcon,
    PreviewIcon,
    DeleteIcon,
    UpgradeIcon,
    DowngradeIcon,
    AcceptIcon,
    DenyIcon,
} from "../../../../common/components/icons";

import { decorateTableItemsJobTypes } from "../../../../common/services/decorateTableItems";

import ChannelMembersFilterRow from "../../common/ChannelMembersFilterRow";
import { groupStatusCallback } from "../../../../common/services/tableCallbacks";
import { MainTitle } from "../../../../common/components/MainTitle";
import { GetFormattedDate } from "../../../../common/components/Time";

import * as config from "../values";
import formsCss from "../../../../common/styles/forms.css";

const callbacks = {
    type: groupStatusCallback,
};

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            isBackendUser: "Is LSE Staff?",
            type: "Status",
        },
    },
];

const columns = ["id", "firstName", "lastName", "type", "isBackendUser"];

const buttons = items => index => {
    let buttons = [];

    if (
        items[index].type === config.USER_GROUP_STATUS_JOINREQUEST ||
        items[index].type === config.USER_GROUP_STATUS_INVITATION
    ) {
        buttons = [
            ...buttons,
            ...[
                {
                    path: `${items[index] ? items[index].id : null}`,
                    label:
                        items[index].type === config.USER_GROUP_STATUS_JOINREQUEST
                            ? "Accept request"
                            : "Add to group",
                    fnLabel: "accept",
                    icon: <AcceptIcon css={tableCss.actionsMenuItemIcon} />,
                },
                {
                    path: `${items[index] ? items[index].id : null}`,
                    label:
                        items[index].type === config.USER_GROUP_STATUS_JOINREQUEST
                            ? "Deny request"
                            : "Cancel invitation",
                    fnLabel: "deny",
                    icon: <DenyIcon css={tableCss.actionsMenuItemIcon} />,
                },
            ],
        ];
    }

    if (items[index].type === config.USER_TYPE_MEMBER) {
        buttons = [
            ...buttons,
            ...[
                {
                    path: `${items[index] ? items[index].id : null}`,
                    label: "Promote to owner",
                    fnLabel: "upgrade",
                    icon: <UpgradeIcon css={tableCss.actionsMenuItemIcon} />,
                },
            ],
        ];
    }

    if (items[index].type === config.USER_TYPE_OWNER && !items[index].type.isBackendUser) {
        buttons = [
            ...buttons,
            ...[
                {
                    path: `${items[index] ? items[index].id : null}`,
                    label: "Make member only",
                    fnLabel: "downgrade",
                    icon: <DowngradeIcon css={tableCss.actionsMenuItemIcon} />,
                },
            ],
        ];
    }

    if (
        items[index].type === config.USER_TYPE_MEMBER ||
        items[index].type === config.USER_TYPE_OWNER
    ) {
        buttons = [
            ...buttons,
            ...[
                {
                    path: `${items[index] ? items[index].id : null}`,
                    label: "Remove from group",
                    fnLabel: "remove",
                    icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
                },
            ],
        ];
    }

    return buttons;
};

class GroupChannelMembersListContainer extends Component {
    state = {
        groupId: null,
        filter: "",
        type: "",
        filter$: new Subject(),
    };

    remove = itemID => {
        const { removeGroupMember } = this.props;
        const { groupId } = this.state;
        removeGroupMember({ id: itemID, groupId });
    };

    downgrade = itemID => {
        const { changeMemberRole } = this.props;
        const { groupId } = this.state;
        changeMemberRole({ id: itemID, groupId, type: config.USER_TYPE_MEMBER });
    };

    upgrade = itemID => {
        const { changeMemberRole } = this.props;
        const { groupId } = this.state;
        changeMemberRole({ id: itemID, groupId, type: config.USER_TYPE_OWNER });
    };

    accept = itemID => {
        const { updatePendingMember } = this.props;
        const { groupId } = this.state;
        updatePendingMember({ id: itemID, groupId, status: config.ROLE_ACTION_APPROVE });
    };

    deny = itemID => {
        const { updatePendingMember } = this.props;
        const { groupId } = this.state;
        updatePendingMember({ id: itemID, groupId, status: config.ROLE_ACTION_DECLINE });
    };

    componentDidMount() {
        const {
            setTitle,
            getListMembers,
            getOneGroup,
            match: {
                params: { groupId },
            },
        } = this.props;
        const { filter$ } = this.state;
        this.setState({ groupId });
        setTitle("Channels \u203A Groups");
        getOneGroup({ id: groupId });
        getListMembers({ groupId });
        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            filterListWithId({
                getFn: getListMembers,
                filter: term,
                id: { name: "groupId", value: groupId },
            });
        });
    }

    componentWillUnmount() {
        const { clearListMembers } = this.props;
        clearListMembers();
    }

    handler = ({ field, value }) => {
        const { filter$ } = this.state;
        this.setState({ [field]: value }, () =>
            filter$.next({
                filter: this.state.filter,
                type: this.state.type,
            })
        );
    };

    onFilterChangeHandler = field => event => {
        this.handler({ field, value: event.target.value });
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMoreMembers } = this.props;
        const { groupId, filter, term } = this.state;
        loadMoreMembers({
            groupId,
            param: prepareFilterQuery({ filter, term, page }),
        });
    };

    render() {
        const {
            members,
            history: { push },
            nextPage,
            count,
            group,
        } = this.props;
        const { filter, type, groupId } = this.state;
        const {
            onFilterChangeHandler,
            onLoadMoreClickHandler,
            remove,
            accept,
            deny,
            upgrade,
            downgrade,
        } = this;

        return (
            <div>
                <MainTitle
                    title={group.name}
                    type="[Group]"
                    rightElement={
                        <Button onClick={() => push(`/channels/groups/${groupId}`)}>
                            <PreviewIcon css={formsCss.btnIcon} />
                            Preview channel
                        </Button>
                    }
                    subtitle={
                        group.lastActiveDate &&
                        `Last active date: ${GetFormattedDate(
                            group.lastActiveDate,
                            "dddd D MMMM YYYY"
                        )}`
                    }
                />

                <div css={globalsCss.actions}>
                    <RedirectButton
                        label="Add new member"
                        url={`/channels/groups/${groupId}/members/new`}
                        {...{ push }}
                    />
                </div>

                <Paper elevation={1}>
                    <ChannelMembersFilterRow
                        {...{
                            filter,
                            type,
                            onFilterChangeHandler,
                            context: "group",
                        }}
                    />
                    <TableComponent
                        items={members}
                        fns={{ push, remove, accept, deny, upgrade, downgrade }}
                        buttons={buttons(members)}
                        decorators={decorators}
                        columns={columns}
                        callbacks={callbacks}
                        wideContextMenu
                    />
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {members.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListMembers: actionCreators.getListMembers.create,
    getOneGroup: groupActionCreators.getOneGroup.create,
    loadMoreMembers: actionCreators.loadMoreMembers.create,
    removeGroupMember: actionCreators.removeGroupMember.create,
    changeMemberRole: actionCreators.changeMemberRole.create,
    updatePendingMember: actionCreators.updatePendingMember.create,
    clearListMembers: actionCreators.clearListMembers.create,
};
const mapStateToProps = ({
    ChannelGroupMember: {
        members: { data, count, nextPage },
    },
    ChannelGroup: { group },
}) => ({
    members: data.map(item => {
        return {
            id: item.user.id,
            type: item.type,
            firstName: item.user.firstName,
            lastName: item.user.lastName,
            isBackendUser: item.user.isBackendUser ? "Yes" : "No",
        };
    }),
    group,
    count,
    nextPage,
});
export default connect(mapStateToProps, dispatchToProps)(GroupChannelMembersListContainer);
