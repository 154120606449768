/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import { Subject } from "rxjs";
import { AppBar, Toolbar, Typography, IconButton, InputBase } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import toolbarCss from "../styles/toolbar.css";
import datepickerCss from "../styles/datepicker.css";
import { ClearIcon } from "./icons";
import AutoCompleteInline from "./Downshift/AutoCompleteInline";
import { Component } from "react";
// import { rawUpdateListOnFilterChange } from "../../common/services/FilterList";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

class ChannelDateFilterRow extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const { getChannels, clearChannels, onChannelChangeHandler } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            if (query !== "") {
                getChannels({ filter: query });
            } else {
                clearChannels();
                onChannelChangeHandler({ id: null, name: "" });
            }
        });
    }

    onChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () => searchText$.next(this.state.searchText));
    };

    render() {
        const {
            onFilterChangeHandler,
            onChannelChangeHandler,
            onDateChangeHandler,
            from,
            to,
            channel,
            filter,
            classes,
            channels,
            clearChannels,
            labelKeyword = "Filter by channel",
            labelDate = "Filter by Publication Date",
        } = this.props;

        return (
            <AppBar
                position="static"
                classes={{
                    root: classes.appBarRoot,
                }}
                elevation={0}
            >
                <Toolbar
                    classes={{
                        root: classes.toolbarRootBorder,
                    }}
                >
                    <div css={toolbarCss.toolbarGroup}>
                        <Typography>
                            <span css={toolbarCss.toolbarTitle}>
                                <span css={toolbarCss.labelShowOnDesktop}>
                                    Filter by keyword or ID:
                                </span>
                                <span css={toolbarCss.labelShowOnMobile}>Filter:</span>
                            </span>
                        </Typography>
                        <InputBase
                            placeholder="..."
                            value={filter || ""}
                            onChange={onFilterChangeHandler}
                            fullWidth
                            autoComplete="off"
                        />
                    </div>
                    <div css={toolbarCss.toolbarSeparator} />
                    <div css={[toolbarCss.toolbarGroup]}>
                        <Typography>
                            <span css={toolbarCss.toolbarTitle}>
                                <span css={toolbarCss.labelShowOnDesktop}>{labelKeyword}:</span>
                                <span css={toolbarCss.labelShowOnMobile}>Channel:</span>
                            </span>
                        </Typography>
                        <AutoCompleteInline
                            single={true}
                            selectedItems={channel}
                            dataSource={channels}
                            onUpdateInput={this.onChangeHandler}
                            onBlur={() => clearChannels()}
                            onChange={onChannelChangeHandler}
                            labelCallback={item => item.name}
                            multiline
                        />
                        <IconButton
                            onClick={() => this.onChangeHandler("")}
                            css={datepickerCss.datepickerBtn}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                </Toolbar>
                <Toolbar
                    classes={{
                        root: classes.toolbarRoot,
                    }}
                >
                    <div css={toolbarCss.toolbarGroup}>
                        <Typography>
                            <span css={toolbarCss.toolbarTitle}>
                                <span css={toolbarCss.labelShowOnDesktop}>{labelDate}:</span>
                                <span css={toolbarCss.labelShowOnMobile}>Date:</span>
                            </span>
                        </Typography>
                        <div css={datepickerCss.datepickerContainer}>
                            <DatePicker
                                placeholder="From..."
                                value={from !== "" ? from : null}
                                onChange={value =>
                                    onDateChangeHandler("from")(
                                        value.set({ hour: 0, minute: 0, second: 0 })
                                    )
                                }
                                fullWidth
                                autoOk={true}
                                format={"D MMM YYYY"}
                                color="primary"
                                css={toolbarCss.toolbarDatePicker}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                            <IconButton
                                onClick={onDateChangeHandler("from", null)}
                                css={datepickerCss.datepickerBtn}
                            >
                                <ClearIcon />
                            </IconButton>
                        </div>
                        <div css={datepickerCss.datepickerContainer}>
                            <DatePicker
                                placeholder="To..."
                                value={to !== "" ? to : null}
                                onChange={value =>
                                    onDateChangeHandler("to")(
                                        value.set({ hour: 23, minute: 59, second: 59 })
                                    )
                                }
                                fullWidth
                                autoOk={true}
                                format={"D MMM YYYY"}
                                color="primary"
                                css={toolbarCss.toolbarDatePicker}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                            <IconButton
                                onClick={onDateChangeHandler("to", null)}
                                css={datepickerCss.datepickerBtn}
                            >
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(toolbarCss)(ChannelDateFilterRow);
