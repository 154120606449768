import ActionCreator from "../actionCreator";

export default {
    validate: ActionCreator("AUTH_VALIDATE"),
    login: ActionCreator("LOGIN"),
    checkStorage: ActionCreator("CHECK_STORAGE"),
    restoreUser: ActionCreator("AUTH_RESTORE_USER"),
    getCurrentUser: ActionCreator("GET_CURRENT_USER"),
    updateCurrentUser: ActionCreator("UPDATE_CURRENT_USER"),
    setAuth: ActionCreator("AUTH_SET_AUTH"),
    setNotAuth: ActionCreator("SET_NOT_AUTH"),
    logout: ActionCreator("LOGOUT"),
    errorResponse: ActionCreator("ERROR_RESPONSE"),
    setInitiated: ActionCreator("AUTH_SET_INITIATED"),
};
