import React from "react";
import { Route, Switch } from "react-router-dom";
import {
    ROUTE_OFFICE_HOURS_REPORTS,
    ROUTE_OFFICE_HOURS_REPORTS_BOOKING,
    ROUTE_OFFICE_HOURS_REPORTS_STUDENT_HISTORY,
    ROUTE_OFFICE_HOURS_REPORTS_TEAM_HISTORY,
    ROUTE_OFFICE_HOURS_REPORTS_ACADEMIC_AVAILABILITY,
    ROUTE_OFFICE_HOURS_REPORTS_TEAM_AVAILABILITY,
    ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_STUDENT_BOOKING_HISTORY,
    ROUTE_OFFICE_HOURS_REPORTS_STUDENT_BOOKING_HISTORY_WITH_ME,
    ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_ACADEMIC_AVAILABILITY,
} from ".";
import OfficeHoursReportsContainer from "../containers/OfficeHoursReportsContainer";
import OfficeHoursBookingHistoryReportContainer from "../containers/OfficeHoursBookingHistoryReportContainer";
import OfficeHoursStudentHistoryReportContainer from "../containers/OfficeHoursStudentHistoryReportContainer";
import OfficeHoursTeamHistoryReportContainer from "../containers/OfficeHoursTeamHistoryReportContainer";
import OfficeHoursAcademicAvailability from "../containers/OfficeHoursAcademicAvailability";
import OfficeHoursTeamAvailabilityReportContainer from "../containers/OfficeHoursTeamAvailabilityReportContainer";
import OfficeHoursStudentBookingHistoryWithMeReportContainer from "../containers/OfficeHoursStudentBookingHistoryWithMeReportContainer";
import OfficeHoursGlobalStudentBookingHistoryReportContainer from "../containers/OfficeHoursGlobalStudentBookingHistoryReportContainer";
import OfficeHoursGlobalAcademicAvailabilityReportContainer from "../containers/OfficeHoursGlobalAcademicAvailabilityReportContainer";

const OfficeHoursReportRoutes = () => (
    <Switch>
        <Route
            path={ROUTE_OFFICE_HOURS_REPORTS_ACADEMIC_AVAILABILITY}
            component={OfficeHoursAcademicAvailability}
        />
        <Route
            path={ROUTE_OFFICE_HOURS_REPORTS_TEAM_AVAILABILITY}
            component={OfficeHoursTeamAvailabilityReportContainer}
        />
        <Route
            path={ROUTE_OFFICE_HOURS_REPORTS_BOOKING}
            component={OfficeHoursBookingHistoryReportContainer}
        />
        <Route
            path={ROUTE_OFFICE_HOURS_REPORTS_STUDENT_HISTORY}
            component={OfficeHoursStudentHistoryReportContainer}
        />
        <Route
            path={ROUTE_OFFICE_HOURS_REPORTS_TEAM_HISTORY}
            component={OfficeHoursTeamHistoryReportContainer}
        />
        <Route
            path={ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_STUDENT_BOOKING_HISTORY}
            component={OfficeHoursGlobalStudentBookingHistoryReportContainer}
        />
        <Route
            path={ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_ACADEMIC_AVAILABILITY}
            component={OfficeHoursGlobalAcademicAvailabilityReportContainer}
        />
        <Route
            path={ROUTE_OFFICE_HOURS_REPORTS_STUDENT_BOOKING_HISTORY_WITH_ME}
            component={OfficeHoursStudentBookingHistoryWithMeReportContainer}
        />

        <Route path={ROUTE_OFFICE_HOURS_REPORTS} component={OfficeHoursReportsContainer} />
    </Switch>
);

export default OfficeHoursReportRoutes;
