import ActionCreator from "../../actionCreator";

export default {
    getListPost: ActionCreator("CHANNEL_GET_LIST_POST"),
    loadMorePosts: ActionCreator("CHANNEL_LOAD_MORE_POSTS"),
    getOnePost: ActionCreator("CHANNEL_GET_ONE_POST"),
    postPost: ActionCreator("CHANNEL_POST_POST"),
    putPost: ActionCreator("CHANNEL_POST_PUT"),
    removePost: ActionCreator("CHANNEL_POST_REMOVE"),
    createListPost: ActionCreator("CHANNEL_CREATE_LIST_POST"),
    updateListPost: ActionCreator("CHANNEL_UPDATE_LIST_POST"),
    updateOnePost: ActionCreator("CHANNEL_UPDATE_ONE_POST"),
    setArticleStatus: ActionCreator("CHANNEL_POST_SET_ARTICLE_STATUS"),
    setCurrentFormType: ActionCreator("CHANNEL_POST_SET_CURRENT_FORM_TYPE_POST"),
    setDateFieldStatus: ActionCreator("CHANNEL_POST_SET_DATE_FIELD_STATUS_POST"),
    errorResponse: ActionCreator("CHANNEL_ERROR_RESPONSE_POST"),
    clearErrors: ActionCreator("CLEAR_ERROR_CHANNEL_POST"),
    clearQueryParams: ActionCreator("CLEAR_QUERY_PARAMS_CHANNEL_POST"),
    archivePost: ActionCreator("CHANNEL_ARCHIVE_POST"),
    updateArchivedPost: ActionCreator("CHANNEL_UPDATE_ARCHIVED_POST"),
};
