import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { TableComponent } from "../../../common/components/OfficeHours";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { prepareFilterQuery } from "../../../common/services/utils";
import { updateListOnFilterChange } from "../../../common/services/FilterList";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import tableCss from "../../../common/styles/table.css";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";

import FilterRow from "../../../common/components/FilterRow";
import TabsComponent from "../../../common/components/Tabs";
import formsCss from "../../../common/styles/forms.css";
import {
    EventIcon,
    AvailabilityIcon,
    AutoRenewIcon,
    SettingsIcon,
} from "../../../common/components/icons";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            name: "Name",
            upcomingSessions: "Upcoming sessions",
        },
    },
];

const buttons = items => index => [
    {
        path: `/bookings/availability/${items[index] ? items[index].id : ""}/manage`,
        label: "Availability",
        fnLabel: "push",
        icon: <AvailabilityIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/bookings/browse/${items[index] ? items[index].id : ""}`,
        label: "Browse sessions",
        fnLabel: "push",
        icon: <EventIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/bookings/availability/${items[index] ? items[index].id : ""}/template`,
        label: "Default settings",
        fnLabel: "push",
        icon: <SettingsIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

const columns = ["id", "name", "upcomingSessions"];

class OfficeHoursManageListContainer extends Component {
    state = {
        filter: "",
        filter$: new Subject(),
    };

    componentDidMount() {
        const {
            setTitle,
            getMyOfficeHoursAcademics,
            getMyOfficeHoursTeams,
            clearOneMyOfficeHoursAcademic,
            clearOneMyOfficeHoursTeam,
            clearMyOfficeHoursTeams,
            userId,
        } = this.props;
        const { filter$ } = this.state;

        clearOneMyOfficeHoursAcademic();
        clearOneMyOfficeHoursTeam();
        clearMyOfficeHoursTeams();

        setTitle("Bookings \u203A Manage Availability");
        getMyOfficeHoursAcademics(prepareFilterQuery(this.state.filter));

        getMyOfficeHoursTeams(userId);

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                updateListOnFilterChange({
                    getFn: getMyOfficeHoursAcademics,
                    filter: term,
                })
            );
        });
    }

    onFilterChangeHandler = ({ target: { value } }) => {
        const { filter$ } = this.state;
        this.setState({ filter: value }, () => filter$.next({ filter: this.state.filter }));
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMoreMyOfficeHoursAcademics } = this.props;
        const { filter } = this.state;
        loadMoreMyOfficeHoursAcademics(
            prepareFilterQuery({
                filter,
                page,
            })
        );
    };

    componentWillUnmount() {
        const { clearMyOfficeHoursAcademics, clearTitle } = this.props;
        clearTitle();
        clearMyOfficeHoursAcademics();
    }

    render() {
        const {
            academics,
            teams,
            history: { push },
            nextPage,
            count,
        } = this.props;
        const { filter } = this.state;
        const { onFilterChangeHandler, onLoadMoreClickHandler, remove } = this;

        const filterDuplicates = [];
        academics.forEach(a => {
            if (!filterDuplicates.find(d => d.id === a.id)) filterDuplicates.push(a);
        });

        return (
            <div>
                {teams && teams.length > 0 && (
                    <Paper elevation={0}>
                        <TabsComponent
                            activeIndex={0}
                            tabs={[
                                {
                                    label: "Individual",
                                    selected: true,
                                    fnLabel: "push",
                                    param: `/bookings/availability`,
                                },
                                {
                                    label: "Teams",
                                    selected: false,
                                    fnLabel: "push",
                                    param: `/bookings/availability/team`,
                                },
                            ]}
                            fns={{ push }}
                        />
                    </Paper>
                )}
                <Paper elevation={1}>
                    <FilterRow filter={filter} onFilterChangeHandler={onFilterChangeHandler} />
                    <TableComponent
                        items={filterDuplicates}
                        fns={{ push, remove }}
                        buttons={buttons(filterDuplicates)}
                        decorators={decorators}
                        columns={columns}
                    />
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {academics.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getMyOfficeHoursAcademics: actionCreators.getMyOfficeHoursAcademics.create,
    loadMoreMyOfficeHoursAcademics: actionCreators.loadMoreMyOfficeHoursAcademics.create,
    getMyOfficeHoursTeams: actionCreators.getMyOfficeHoursTeams.create,
    clearMyOfficeHoursAcademics: actionCreators.clearMyOfficeHoursAcademics.create,
    clearOneMyOfficeHoursAcademic: actionCreators.clearOneMyOfficeHoursAcademic.create,
    clearOneMyOfficeHoursTeam: actionCreators.clearOneMyOfficeHoursTeam.create,
    clearMyOfficeHoursTeams: actionCreators.clearMyOfficeHoursTeams.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities, Auth }) => ({
    academics: OfficeHoursAvailabilities.academics.data.map(item => {
        return {
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            upcomingSessions: item.upcomingOfficeHours,
        };
    }),
    count: OfficeHoursAvailabilities.academics.count,
    nextPage: OfficeHoursAvailabilities.academics.nextPage,
    teams: OfficeHoursAvailabilities.teams
        ? OfficeHoursAvailabilities.teams.filter(team => team.userType === "admin")
        : [],
    userId: Auth.id,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursManageListContainer);
