import { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change, registerField } from "redux-form";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import validate from "./validate";
import {
    RenderTextField,
    RenderAutocomplete,
    RenderCheckboxField,
    RenderSelectField,
    RenderRadioGroup,
    RenderRadio,
} from "../../../../common/components/FormFields";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";

import { TextField, MenuItem, Button, FormControl, FormLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";
// import globalsCss from "../../../../common/styles/globals.css";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";
import globalsCss from "../../../../common/styles/globals.css";
import { InfoTooltip } from "../../../../common/components/InfoTooltip";

const FORM_TYPE_FIELD = "Field";

class Form extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const { getUsers, clearUsers, addField } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            if (query !== "") {
                rawUpdateListOnFilterChange({
                    getFn: getUsers,
                    filter: { filter: query },
                });
            } else clearUsers();
        });

        addField("settings.timeInAdvanceValue", FORM_TYPE_FIELD);
        addField("settings.summaryAtTimeValue", FORM_TYPE_FIELD);

        addField("teamSummaryAtTimeValue", FORM_TYPE_FIELD);

        this.props.initialize(this.props.initialValues);
    }

    onChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () => searchText$.next(this.state.searchText));
    };

    render() {
        const {
            handleSubmit,
            push,
            admins,
            academics,
            currentFormType,
            clearUsers,
            errors,
            formError,
            formFields,
            updateForm,
            classes,
            formErrors,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Field name="isActive" errorRes={errors.status} component={RenderRadioGroup} row>
                    <RenderRadio
                        value={"true"}
                        label="Active"
                        className="col-lg-2 col-sm-3 col-xs-12"
                    />
                    <RenderRadio
                        value={"false"}
                        label="Inactive"
                        className="col-lg-4 col-sm-3 col-xs-12"
                    />
                </Field>
                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                        <Field
                            name="name"
                            errorRes={errors.name}
                            component={RenderTextField}
                            label="Name"
                            fullWidth
                        />
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <Field
                            name="enableGenericAvailabilities"
                            label=" Enable generic availabilities"
                            component={RenderCheckboxField}
                            errorRes={errors && errors.enableGenericAvailabilities}
                        />
                        <Field
                            name="isBookingSuggestion"
                            label=" List as recommended in Bookings section"
                            component={RenderCheckboxField}
                            errorRes={errors && errors.enableGenericAvailabilities}
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                        <label css={formsCss.labelBig}>Members</label>
                        <Field
                            name="users"
                            errorRes={errors.users}
                            handleInput={this.onChangeHandler}
                            dataSource={academics}
                            component={RenderAutocomplete}
                            onDroppedFocus={() => clearUsers()}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label css={formsCss.labelBig}>Team Administrators</label>
                        <Field
                            name="admins"
                            errorRes={errors.admins}
                            handleInput={this.onChangeHandler}
                            dataSource={admins}
                            component={RenderAutocomplete}
                            onDroppedFocus={() => clearUsers()}
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Default team availability template</label>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="officeHoursTitle"
                            errorRes={errors.officeHoursTitle}
                            component={RenderTextField}
                            label="Booking title"
                            fullWidth
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="settings.location"
                            errorRes={errors && errors.settings && errors.settings.location}
                            component={RenderTextField}
                            label="Location"
                            fullWidth
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="settings.appointmentForm"
                            errorRes={errors && errors.settings && errors.settings.appointmentForm}
                            component={RenderTextField}
                            label="Appointment type"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="settings.duration"
                            component={RenderSelectField}
                            label="Appointment duration"
                            errorRes={errors && errors.settings && errors.settings.duration}
                            options={[
                                { value: null, label: "" },
                                { value: 5, label: "5 min" },
                                { value: 10, label: "10 min" },
                                { value: 15, label: "15 min" },
                                { value: 20, label: "20 min" },
                                { value: 25, label: "25 min" },
                                { value: 30, label: "30 min" },
                                { value: 35, label: "35 min" },
                                { value: 40, label: "40 min" },
                                { value: 45, label: "45 min" },
                                { value: 50, label: "50 min" },
                                { value: 55, label: "55 min" },
                                { value: 60, label: "60 minutes" },
                                { value: 90, label: "90 minutes" },
                            ]}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="settings.maxNumberOfAttendees"
                                errorRes={
                                    errors &&
                                    errors.settings &&
                                    errors.settings.maxNumberOfAttendees
                                }
                                component={RenderTextField}
                                label="Number of attendees per appointment slot"
                                fullWidth
                                type="number"
                                inputProps={{ min: "1" }}
                                placeholder="Maximum number of attendees per slot"
                            />
                            <InfoTooltip text="Allows you to define how many students can book into the same appointment slot. Students will not be able to see who else has booked into a slot." />
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="settings.maxAppointmentsPerSession"
                                errorRes={
                                    errors &&
                                    errors.settings &&
                                    errors.settings.maxAppointmentsPerSession
                                }
                                component={RenderTextField}
                                label="Number of slots per student per session"
                                fullWidth
                                type="number"
                                inputProps={{ min: "1" }}
                                placeholder="Maximum number of bookings per student"
                            />
                            <InfoTooltip text="Allows you to define how many appointments a student can book in one session." />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="settings.earliestBooking"
                            component={RenderSelectField}
                            label="Earliest booking"
                            errorRes={errors && errors.settings && errors.settings.earliestBooking}
                            options={[
                                { value: 0, label: "No limit" },
                                { value: 24 * 60, label: "1 day in advance" },
                                { value: 48 * 60, label: "2 days in advance" },
                                { value: 72 * 60, label: "3 days in advance" },
                                { value: 96 * 60, label: "4 days in advance" },
                                { value: 120 * 60, label: "5 days in advance" },
                                { value: 144 * 60, label: "6 days in advance" },
                                { value: 168 * 60, label: "7 days in advance" },
                                { value: 192 * 60, label: "8 days in advance" },
                                { value: 216 * 60, label: "9 days in advance" },
                                { value: 240 * 60, label: "10 days in advance" },
                                { value: 264 * 60, label: "11 days in advance" },
                                { value: 288 * 60, label: "12 days in advance" },
                                { value: 312 * 60, label: "13 days in advance" },
                                { value: 336 * 60, label: "14 days in advance" },
                            ]}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="settings.latestBooking"
                            component={RenderSelectField}
                            label="Latest booking"
                            errorRes={errors && errors.settings && errors.settings.latestBooking}
                            options={[
                                { value: 0, label: "No limit" },
                                { value: 30, label: "30 minutes in advance" },
                                { value: 1 * 60, label: "1 hour in advance" },
                                { value: 2 * 60, label: "2 hours in advance" },
                                { value: 3 * 60, label: "3 hours in advance" },
                                { value: 4 * 60, label: "4 hours in advance" },
                                { value: 5 * 60, label: "5 hours in advance" },
                                { value: 6 * 60, label: "6 hours in advance" },
                                { value: 12 * 60, label: "12 hours in advance" },
                                { value: 24 * 60, label: "24 hours in advance" },
                                { value: 48 * 60, label: "48 hours in advance" },
                                { value: 72 * 60, label: "3 days in advance" },
                                { value: 96 * 60, label: "4 days in advance" },
                                { value: 120 * 60, label: "5 days in advance" },
                                { value: 144 * 60, label: "6 days in advance" },
                                { value: 168 * 60, label: "7 days in advance" },
                            ]}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={globalsCss.helpers.mt20}>
                            <Field
                                name="settings.allowFileUpload"
                                label="Allow students to upload files"
                                component={RenderCheckboxField}
                                errorRes={
                                    errors && errors.settings && errors.settings.allowFileUpload
                                }
                            />
                            <Field
                                name="settings.requireBookingNote"
                                label=" Require students to add a booking note"
                                component={RenderCheckboxField}
                                errorRes={
                                    errors && errors.settings && errors.settings.requireBookingNote
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8 col-xs-12">
                        <Field
                            name="settings.note"
                            error={errors && errors.settings && errors.settings.note}
                            component={RenderTextField}
                            label="Note for students"
                            fullWidth
                            multiline
                            rows={5}
                            rowsMax={5}
                            placeholder="Share any useful information about this availability with your students here"
                        />
                    </div>
                </div>

                <hr className="bold" />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Email notifications</label>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <FormControl margin="normal" component="fieldset">
                            <FormLabel component="div" css={formsCss.labelRadioGroup}>
                                Send individual summary emails to team members:
                            </FormLabel>
                            <div css={formsCss.checkboxBox}>
                                <div>
                                    <Field
                                        name="settings.atEightOnSessionDay"
                                        label="At 8.00am on the session day"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.settings
                                                        .receiveSummaryEmailAheadUpcomingAppointments ===
                                                    true
                                                )
                                                    updateForm(
                                                        "settings.receiveSummaryEmailAheadUpcomingAppointments",
                                                        false
                                                    );
                                            }
                                        }}
                                    />
                                </div>

                                <div>
                                    <Field
                                        name="settings.summaryAtTime"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.settings
                                                        .receiveSummaryEmailAheadUpcomingAppointments ===
                                                    true
                                                )
                                                    updateForm(
                                                        "settings.receiveSummaryEmailAheadUpcomingAppointments",
                                                        false
                                                    );
                                            }
                                        }}
                                        label={
                                            <div
                                                css={formsCss.inlineAlignTop}
                                                onClick={e => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <div css={formsCss.inlineItem}>At</div>
                                                <TextField
                                                    css={[
                                                        formsCss.inlineItem,
                                                        formsCss.inlineItemField,
                                                        { width: 100 },
                                                    ]}
                                                    disabled={
                                                        formFields &&
                                                        formFields.settings &&
                                                        formFields.settings
                                                            .receiveSummaryEmailAheadUpcomingAppointments ===
                                                            true
                                                    }
                                                    select
                                                    margin="none"
                                                    color="primary"
                                                    fullWidth={false}
                                                    error={
                                                        !!formErrors &&
                                                        !!formErrors.settings &&
                                                        !!formErrors.settings.summaryAtTimeValue
                                                    }
                                                    helperText={
                                                        formErrors &&
                                                        formErrors.settings &&
                                                        formErrors.settings.summaryAtTimeValue
                                                    }
                                                    onChange={({ target: { value } }) => {
                                                        updateForm(
                                                            "settings.summaryAtTime",
                                                            !!value
                                                        );
                                                        updateForm(
                                                            "settings.summaryAtTimeValue",
                                                            value
                                                        );
                                                    }}
                                                    value={
                                                        formFields &&
                                                        formFields.settings &&
                                                        formFields.settings.summaryAtTimeValue
                                                            ? formFields.settings.summaryAtTimeValue
                                                            : ""
                                                    }
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.inputRoot,
                                                            error: classes.inputError,
                                                        },
                                                    }}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            MenuListProps: {
                                                                disablePadding: true,
                                                                dense: true,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem key={`aots_0`} value={0}>
                                                        Choose time
                                                    </MenuItem>
                                                    <MenuItem key={`aots_1`} value={9}>
                                                        9.00am
                                                    </MenuItem>
                                                    <MenuItem key={`aots_2`} value={10}>
                                                        10.00am
                                                    </MenuItem>
                                                    <MenuItem key={`aots_3`} value={11}>
                                                        11.00am
                                                    </MenuItem>
                                                    <MenuItem key={`aots_4`} value={12}>
                                                        12.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_5`} value={13}>
                                                        1.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_6`} value={14}>
                                                        2.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_7`} value={15}>
                                                        3.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_8`} value={16}>
                                                        4.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_9`} value={17}>
                                                        5.00pm
                                                    </MenuItem>
                                                </TextField>
                                                <div css={formsCss.inlineItem}>
                                                    on the session day
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>

                                <div>
                                    <Field
                                        name="settings.timeInAdvance"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.settings
                                                        .receiveSummaryEmailAheadUpcomingAppointments ===
                                                    true
                                                )
                                                    updateForm(
                                                        "settings.receiveSummaryEmailAheadUpcomingAppointments",
                                                        false
                                                    );
                                            }
                                        }}
                                        label={
                                            <div
                                                css={formsCss.inlineAlignTop}
                                                onClick={e => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <TextField
                                                    css={[
                                                        formsCss.inlineItem,
                                                        formsCss.inlineItemField,
                                                        { width: 120 },
                                                    ]}
                                                    disabled={
                                                        formFields &&
                                                        formFields.settings &&
                                                        formFields.settings
                                                            .receiveSummaryEmailAheadUpcomingAppointments ===
                                                            true
                                                    }
                                                    select
                                                    margin="none"
                                                    color="primary"
                                                    fullWidth={false}
                                                    error={
                                                        !!formErrors &&
                                                        !!formErrors.settings &&
                                                        !!formErrors.settings.timeInAdvanceValue
                                                    }
                                                    helperText={
                                                        formErrors &&
                                                        formErrors.settings &&
                                                        formErrors.settings.timeInAdvanceValue
                                                    }
                                                    onChange={({ target: { value } }) => {
                                                        updateForm(
                                                            "settings.timeInAdvance",
                                                            !!value
                                                        );
                                                        updateForm(
                                                            "settings.timeInAdvanceValue",
                                                            value
                                                        );
                                                    }}
                                                    value={
                                                        formFields &&
                                                        formFields.settings &&
                                                        formFields.settings.timeInAdvanceValue
                                                            ? formFields.settings.timeInAdvanceValue
                                                            : ""
                                                    }
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.inputRoot,
                                                            error: classes.inputError,
                                                        },
                                                    }}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            MenuListProps: {
                                                                disablePadding: true,
                                                                dense: true,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem key={`tia_0`} value={0}>
                                                        Choose time
                                                    </MenuItem>
                                                    <MenuItem key={`tia_1`} value={30}>
                                                        30 minutes
                                                    </MenuItem>
                                                    <MenuItem key={`tia_2`} value={60 * 1}>
                                                        1 hour
                                                    </MenuItem>
                                                    <MenuItem key={`tia_3`} value={60 * 2}>
                                                        2 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_4`} value={60 * 3}>
                                                        3 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_5`} value={60 * 4}>
                                                        4 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_6`} value={60 * 5}>
                                                        5 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_7`} value={60 * 6}>
                                                        6 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_8`} value={60 * 12}>
                                                        12 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_9`} value={60 * 24}>
                                                        24 hours
                                                    </MenuItem>
                                                    <MenuItem key={`tia_10`} value={60 * 48}>
                                                        48 hours
                                                    </MenuItem>
                                                </TextField>
                                                <div css={formsCss.inlineItem}>
                                                    before the session
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div>
                                    <Field
                                        name="settings.atLatestBookingTime"
                                        label="Once students can no longer book"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.settings
                                                        .receiveSummaryEmailAheadUpcomingAppointments ===
                                                    true
                                                )
                                                    updateForm(
                                                        "settings.receiveSummaryEmailAheadUpcomingAppointments",
                                                        false
                                                    );
                                            }
                                        }}
                                    />
                                </div>
                                <div>Or</div>
                                <div>
                                    <Field
                                        name="settings.receiveSummaryEmailAheadUpcomingAppointments"
                                        label="No, don't send individual summary emails"
                                        component={RenderCheckboxField}
                                        errorRes={
                                            errors &&
                                            errors.receiveSummaryEmailAheadUpcomingAppointments
                                        }
                                        onChange={(e, value) => {
                                            if (value) {
                                                updateForm("settings.atEightOnSessionDay", false);
                                                updateForm("settings.timeInAdvance", false);
                                                updateForm("settings.summaryAtTime", false);
                                                updateForm("settings.atLatestBookingTime", false);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </FormControl>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <FormControl margin="normal" component="fieldset">
                            <FormLabel component="div" css={formsCss.labelRadioGroup}>
                                Send team summary emails to team mailbox:
                            </FormLabel>
                            <div css={formsCss.checkboxBox}>
                                <div>
                                    <Field
                                        name="teamSummaryAtEight"
                                        label="At 8.00am on the session day"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.dontReceiveTeamSummaryEmails === true
                                                )
                                                    updateForm(
                                                        "dontReceiveTeamSummaryEmails",
                                                        false
                                                    );
                                            }
                                        }}
                                    />
                                </div>

                                <div>
                                    <Field
                                        name="teamSummaryAtTime"
                                        component={RenderCheckboxField}
                                        onChange={(e, value) => {
                                            if (value) {
                                                if (
                                                    formFields.dontReceiveTeamSummaryEmails === true
                                                )
                                                    updateForm(
                                                        "dontReceiveTeamSummaryEmails",
                                                        false
                                                    );
                                            }
                                        }}
                                        label={
                                            <div
                                                css={formsCss.inlineAlignTop}
                                                onClick={e => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <div css={formsCss.inlineItem}>At</div>
                                                <TextField
                                                    css={[
                                                        formsCss.inlineItem,
                                                        formsCss.inlineItemField,
                                                        { width: 100 },
                                                    ]}
                                                    disabled={
                                                        formFields &&
                                                        formFields.dontReceiveTeamSummaryEmails ===
                                                            true
                                                    }
                                                    select
                                                    margin="none"
                                                    color="primary"
                                                    fullWidth={false}
                                                    error={
                                                        !!formErrors &&
                                                        !!formErrors.teamSummaryAtTime
                                                    }
                                                    helperText={
                                                        formErrors && formErrors.teamSummaryAtTime
                                                    }
                                                    onChange={({ target: { value } }) => {
                                                        updateForm("teamSummaryAtTime", !!value);
                                                        updateForm("teamSummaryAtTimeValue", value);
                                                    }}
                                                    value={
                                                        formFields &&
                                                        formFields.teamSummaryAtTimeValue
                                                            ? formFields.teamSummaryAtTimeValue
                                                            : ""
                                                    }
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.inputRoot,
                                                            error: classes.inputError,
                                                        },
                                                    }}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            MenuListProps: {
                                                                disablePadding: true,
                                                                dense: true,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem key={`aots_0`} value={0}>
                                                        Choose time
                                                    </MenuItem>
                                                    <MenuItem key={`aots_1`} value={9}>
                                                        9.00am
                                                    </MenuItem>
                                                    <MenuItem key={`aots_2`} value={10}>
                                                        10.00am
                                                    </MenuItem>
                                                    <MenuItem key={`aots_3`} value={11}>
                                                        11.00am
                                                    </MenuItem>
                                                    <MenuItem key={`aots_4`} value={12}>
                                                        12.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_5`} value={13}>
                                                        1.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_6`} value={14}>
                                                        2.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_7`} value={15}>
                                                        3.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_8`} value={16}>
                                                        4.00pm
                                                    </MenuItem>
                                                    <MenuItem key={`aots_9`} value={17}>
                                                        5.00pm
                                                    </MenuItem>
                                                </TextField>
                                                <div css={formsCss.inlineItem}>
                                                    on the session day
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>

                                <div>Or</div>
                                <div>
                                    <Field
                                        name="dontReceiveTeamSummaryEmails"
                                        label="No, don't send team summary emails"
                                        component={RenderCheckboxField}
                                        errorRes={errors && errors.dontReceiveTeamSummaryEmails}
                                        onChange={(e, value) => {
                                            if (value) {
                                                updateForm("teamSummaryAtEight", false);
                                                updateForm("teamSummaryAtTime", false);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </FormControl>

                        <Field
                            name="settings.email"
                            errorRes={errors && errors.settings && errors.settings.email}
                            component={RenderTextField}
                            label="Team email mailbox"
                            fullWidth
                        />
                    </div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Default calendar settings</label>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <FormControl margin="normal" component="fieldset">
                            <Field
                                name="settings.addSessionPlaceholders"
                                label="Add session placeholders to team members' calendars"
                                component={RenderCheckboxField}
                            />

                            <label css={formsCss.note}>
                                Team members will receive calendar emails for all sessions created
                                from this availability
                            </label>
                        </FormControl>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <FormControl margin="normal" component="fieldset">
                            <Field
                                name="settings.addBookedAppointments"
                                label="Add individual bookings to team members' calendars"
                                component={RenderCheckboxField}
                            />

                            <label css={formsCss.note}>
                                Team members will receive calendar emails when students make and
                                cancel bookings
                            </label>
                        </FormControl>
                    </div>
                    <div className="col-sm-4 col-xs-12"></div>
                </div>

                <hr className="bold" />

                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Team availability limits</label>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="maxAppointmentsPerUserPerDay"
                                error={errors && errors.maxAppointmentsPerUserPerDay}
                                component={RenderTextField}
                                label="Max. appointments per student per day"
                                placeholder="Max. appointments per student per day"
                                fullWidth
                                type="number"
                                inputProps={{ min: "1" }}
                            />
                            <InfoTooltip text="Allows you to define maximum number of appointments a student can make per day across a whole team" />
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="maxAppointmentsPerUserPerWeek"
                                errorRes={errors && errors.maxAppointmentsPerUserPerWeek}
                                component={RenderTextField}
                                fullWidth
                                type="number"
                                inputProps={{ min: "1" }}
                                label="Max. appointments per student per week"
                                placeholder="Max. appointments per student per week"
                            />
                            <InfoTooltip text="Allows you to define how many appointments a student can book across multiple sessions in one week (Sun - Sat)." />
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineAlignBottom}>
                            <Field
                                name="maxAppointmentsPerUserPerTerm"
                                errorRes={errors && errors.maxAppointmentsPerUserPerTerm}
                                component={RenderTextField}
                                fullWidth
                                type="number"
                                inputProps={{ min: "1" }}
                                label="Max. appointments per student per term"
                                placeholder="Max. appointments per student per term"
                            />
                            <InfoTooltip text="Allows you to define how many appointments a student can book across multiple sessions in one term." />
                        </div>
                    </div>
                </div>

                {formError && (
                    <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                        {formError}
                    </div>
                )}
                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        {currentFormType === currentFormTypes.NEW ? "Add New Team" : "Save Team"}
                    </Button>
                    <Button onClick={() => push("/bookings/manage-teams")} css={formsCss.btnBack}>
                        Back to the list
                    </Button>
                </div>
            </form>
        );
    }
}

const FORM_NAME = "OfficeHoursTeam";

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = ({ form }) => ({
    formFields: selector(
        { form },
        "settings.timeInAdvance",
        "settings.timeInAdvanceValue",
        "settings.summaryAtTime",
        "settings.summaryAtTimeValue",
        "settings.receiveSummaryEmailAheadUpcomingAppointments",
        "settings.addBookedAppointments",
        "settings.addSessionPlaceholders",
        "teamSummaryAtEight",
        "teamSummaryAtTime",
        "teamSummaryAtTimeValue",
        "dontReceiveTeamSummaryEmails"
    ),
    formErrors: form[FORM_NAME]
        ? form[FORM_NAME].syncErrors
            ? form[FORM_NAME].syncErrors
            : {}
        : {},
});

const dispatchToProps = {
    updateForm: (key, newValue) => change(FORM_NAME, key, newValue),
    addField: (key, newValue) => registerField(FORM_NAME, key, newValue),
};

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
})(withStyles(formsCss)(Form));

export default connect(mapStateToProps, dispatchToProps)(form);
