export const validate = values => {
    let errors = { settings: {} };

    if (values.settings) {
        if (!values.settings.receiveSummaryEmailAheadUpcomingAppointments) {
            if (
                !values.settings.atEightOnSessionDay &&
                !values.settings.timeInAdvance &&
                !values.settings.summaryAtTime &&
                !values.settings.atLatestBookingTime
            ) {
                errors.settings.receiveSummaryEmailAheadUpcomingAppointments =
                    "Please choose at least one option";
            } else if (!!values.settings.timeInAdvance && !values.settings.timeInAdvanceValue) {
                errors.settings.timeInAdvanceValue = "Required";
            } else if (!!values.settings.summaryAtTime && !values.settings.summaryAtTimeValue) {
                errors.settings.summaryAtTimeValue = "Required";
            }
        }
    }

    return errors;
};
