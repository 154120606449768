import React, { FC, forwardRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import formsCss from "src/common/styles/forms.css";
import { FileUploadIcon, InsertDriveFileIcon } from "src/common/components/icons";
import { ImageFile } from "src/types/generic";

/** @jsx jsx */
import { jsx } from "@emotion/core";

export enum FileType {
    IMAGE,
    DOCUMENT,
}

interface OwnProps {
    name: string;
    url?: string;
    remove?: string;
    fileType?: FileType;
    errorRes?: string;
    classes: any;
}

const RHFFileUpload: FC<OwnProps> = forwardRef(
    ({ name, url, remove, fileType = FileType.IMAGE, errorRes, classes, ...other }, ref) => {
        const { control, setValue, getValues, trigger } = useFormContext();
        const [removed, setRemoved] = useState(false);
        const urlValue = getValues(`${url}`);

        const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
            const reader = new FileReader();
            const file: File = (event.target.files as FileList)[0];
            if (file) {
                reader.readAsDataURL(file);
            }

            reader.onload = function() {
                if (fileType === FileType.IMAGE) {
                    // image
                    const img = new Image();
                    img.src = reader.result as string;
                    img.onload = function() {
                        const image: ImageFile = {
                            type: file.type,
                            name: file.name,
                            size: file.size.toString(),
                            base64: reader.result as string,
                        };

                        setValue(name, image);
                        if (remove) setValue(`${remove}`, undefined);
                    };
                } else {
                    // document
                    const uploadedFile: ImageFile = {
                        type: file.type,
                        name: file.name,
                        size: file.size.toString(),
                        base64: reader.result as string,
                    };

                    setValue(name, uploadedFile);
                    if (remove) setValue(`${remove}`, undefined);
                }
            };
        };

        const handleRemove = isFile => {
            if (isFile) setValue(name, null);
            if (urlValue) setRemoved(true);
            if (remove) setValue(`${remove}`, true);
            trigger(name);
        };

        const handleRestore = isFile => {
            if (isFile) setValue(name, null);
            if (urlValue) setRemoved(false);
            if (remove) setValue(`${remove}`, undefined);
            trigger(name);
        };

        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <div>
                            <label htmlFor={`file-upload-${field.name}`}>
                                <Button
                                    variant="contained"
                                    component="span"
                                    color="secondary"
                                    size="small"
                                >
                                    <FileUploadIcon css={formsCss.btnIcon} />
                                    Choose {fileType === FileType.IMAGE ? "an image" : "a file"}
                                </Button>
                            </label>

                            {fileType === FileType.IMAGE ? (
                                <div css={formsCss.hiddenInputFile}>
                                    <input
                                        accept="image/*"
                                        className={classes.input}
                                        id={`file-upload-${field.name}`}
                                        onChange={e => {
                                            field.onChange(e);
                                            handleFileUpload(e);
                                        }}
                                        type="file"
                                        value={""}
                                    />
                                </div>
                            ) : (
                                <div css={formsCss.hiddenInputFile}>
                                    <input
                                        className={classes.input}
                                        id={`file-upload-${field.name}`}
                                        onChange={e => {
                                            field.onChange(e);
                                            handleFileUpload(e);
                                        }}
                                        type="file"
                                        value={""}
                                    />
                                </div>
                            )}

                            {field.value ? (
                                <div>
                                    {fileType === FileType.IMAGE ? (
                                        <div css={formsCss.uploadImagePlaceholder}>
                                            {(field.value as any).base64 && (
                                                <img
                                                    className={classes.previewImage}
                                                    src={(field.value as any).base64}
                                                />
                                            )}

                                            {(field.value as any).url && (
                                                <img
                                                    className={classes.previewImage}
                                                    src={(field.value as any).url}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        field.value &&
                                        !isNaN((field.value as any).size) && (
                                            <div>
                                                <InsertDriveFileIcon />
                                                <Typography variant="h6">
                                                    {(field.value as any).name}
                                                </Typography>
                                                <Typography variant="caption" component="em">
                                                    {((field.value as any).size / 1024).toFixed(2)}
                                                    kb
                                                </Typography>
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {urlValue && !removed && (
                                        <div css={formsCss.uploadImagePlaceholder}>
                                            <img className={classes.previewImage} src={urlValue} />
                                        </div>
                                    )}
                                </div>
                            )}

                            {(field.value || urlValue) && (
                                <div css={formsCss.extraAction}>
                                    {!!removed || (field.value && urlValue) ? (
                                        <Button
                                            onClick={() => {
                                                handleRestore(field.value);
                                            }}
                                            className={classes.buttonTextExtraAction}
                                            size="small"
                                        >
                                            Restore {fileType === FileType.IMAGE ? "image" : "file"}
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => {
                                                handleRemove(field.value);
                                            }}
                                            className={classes.buttonTextExtraAction}
                                            size="small"
                                        >
                                            Remove {fileType === FileType.IMAGE ? "image" : "file"}
                                        </Button>
                                    )}
                                </div>
                            )}
                            {(error || errorRes) && (
                                <div css={formsCss.genericError}>
                                    {error && error.message
                                        ? error.message
                                        : errorRes
                                        ? errorRes
                                        : ""}
                                </div>
                            )}
                        </div>
                    );
                }}
            />
        );
    }
);

const RenderFileUpload = withStyles(formsCss)(RHFFileUpload);

export default RenderFileUpload;
