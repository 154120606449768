// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./Form";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import actionCreators from "../../../store/UsersAndAudiences/LseStudents/actionCreators";

import formsCss from "../../../common/styles/forms.css";
import { MainTitle } from "../../../common/components/MainTitle";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import TabsComponent from "../../../common/components/Tabs";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";
import dashboardCss from "../../../common/styles/dashboard.css";
import { PageIcon, GroupIcon } from "../../../common/components/icons";

class EditStudentContainer extends Component {
    componentDidMount() {
        const { setTitle, getOneStudent, match } = this.props;
        setTitle("LSE Students");
        getOneStudent({ id: match.params.id });
    }

    componentWillUnmount() {
        this.props.clearTitle();
        this.props.clearStudent();
    }

    onSubmit = values => {
        this.props.putStudent(values);
    };

    setTab = id => {
        const {
            history: { push },
            match,
        } = this.props;
        push({
            pathname: `/users-and-audiences/lse-students/edit/${match.params.id}`,
            search: `?t=${id}`,
        });
    };

    render() {
        const {
            initialValues,
            formObject,
            errorRes: { error, errors },
            history: { push },
            location,
        } = this.props;
        const { onSubmit, setTab } = this;

        return (
            <div>
                <MainTitle title="LSE Students" type="[Users &amp; Audiences]" />

                <Paper elevation={1}>
                    <TabsComponent
                        activeIndex={!location.search || location.search === "?t=0" ? 0 : 1}
                        tabs={[
                            {
                                label: "Profile",
                                fnLabel: "setTab",
                                param: 0,
                            },
                            {
                                label: "Permissions & Settings",
                                fnLabel: "setTab",
                                param: 1,
                            },
                        ]}
                        fns={{ setTab }}
                    />
                </Paper>

                {!location.search || location.search === "?t=0" ? (
                    <div>
                        <div css={formsCss.genericError}>{error}</div>
                        <Form {...{ initialValues, formObject, onSubmit, push, errors }} />
                    </div>
                ) : (
                    <div>
                        <Paper elevation={1}>
                            <div css={globalsCss.inner}>
                                {/* PAGES */}
                                {initialValues.userChannels &&
                                    initialValues.userChannels.filter(
                                        c => c.channelCategory === "page"
                                    ).length > 0 && (
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12 col-xs-12">
                                                    <div css={dashboardCss.section}>
                                                        <PageIcon css={dashboardCss.icon} />
                                                        <strong css={dashboardCss.title}>
                                                            Pages
                                                        </strong>
                                                    </div>
                                                </div>

                                                {initialValues.userChannels.filter(
                                                    c =>
                                                        c.type === "owner" &&
                                                        c.channelCategory === "page"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Owner
                                                        </h4>

                                                        {initialValues.userChannels
                                                            .filter(
                                                                c =>
                                                                    c.type === "owner" &&
                                                                    c.channelCategory === "page"
                                                            )
                                                            .map(page => (
                                                                <div
                                                                    css={dashboardCss.pageItem}
                                                                    key={page.channel.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {page.channel.name}
                                                                    </div>
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemBody
                                                                        }
                                                                    >
                                                                        <button
                                                                            css={[
                                                                                dashboardCss.link,
                                                                                dashboardCss.pageItemBodyLink,
                                                                            ]}
                                                                            onClick={() =>
                                                                                push({
                                                                                    pathname: `/channels/pages/edit/${page.channel.id}`,
                                                                                })
                                                                            }
                                                                        >
                                                                            edit page
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}

                                                {initialValues.userChannels.filter(
                                                    c =>
                                                        c.type === "follower" &&
                                                        c.channelCategory === "page"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Follower
                                                        </h4>

                                                        {initialValues.userChannels
                                                            .filter(
                                                                c =>
                                                                    c.type === "follower" &&
                                                                    c.channelCategory === "page"
                                                            )
                                                            .map(page => (
                                                                <div
                                                                    css={dashboardCss.pageItem}
                                                                    key={page.channel.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {page.channel.name}
                                                                    </div>
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemBody
                                                                        }
                                                                    >
                                                                        <button
                                                                            css={[
                                                                                dashboardCss.link,
                                                                                dashboardCss.pageItemBodyLink,
                                                                            ]}
                                                                            onClick={() =>
                                                                                push({
                                                                                    pathname: `/channels/pages/edit/${page.channel.id}`,
                                                                                })
                                                                            }
                                                                        >
                                                                            edit page
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                            <hr />
                                        </div>
                                    )}

                                {/* GROUPS */}
                                {initialValues.userChannels &&
                                    initialValues.userChannels.filter(
                                        c => c.channelCategory === "group"
                                    ).length > 0 && (
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12 col-xs-12">
                                                    <div css={dashboardCss.section}>
                                                        <GroupIcon css={dashboardCss.icon} />
                                                        <strong css={dashboardCss.title}>
                                                            Groups
                                                        </strong>
                                                    </div>
                                                </div>

                                                {initialValues.userChannels.filter(
                                                    c =>
                                                        c.type === "owner" &&
                                                        c.channelCategory === "group"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Owner
                                                        </h4>

                                                        {initialValues.userChannels
                                                            .filter(
                                                                c =>
                                                                    c.type === "owner" &&
                                                                    c.channelCategory === "group"
                                                            )
                                                            .map(group => (
                                                                <div
                                                                    css={dashboardCss.pageItem}
                                                                    key={group.channel.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {group.channel.name}
                                                                    </div>
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemBody
                                                                        }
                                                                    >
                                                                        <button
                                                                            css={[
                                                                                dashboardCss.link,
                                                                                dashboardCss.pageItemBodyLink,
                                                                            ]}
                                                                            onClick={() =>
                                                                                push({
                                                                                    pathname: `/channels/groups/edit/${group.channel.id}`,
                                                                                })
                                                                            }
                                                                        >
                                                                            edit group
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}

                                                {initialValues.userChannels.filter(
                                                    c =>
                                                        c.type === "follower" &&
                                                        c.channelCategory === "group"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Member
                                                        </h4>

                                                        {initialValues.userChannels
                                                            .filter(
                                                                c =>
                                                                    c.type === "follower" &&
                                                                    c.channelCategory === "group"
                                                            )
                                                            .map(group => (
                                                                <div
                                                                    css={dashboardCss.pageItem}
                                                                    key={group.channel.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {group.channel.name}
                                                                    </div>
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemBody
                                                                        }
                                                                    >
                                                                        <button
                                                                            css={[
                                                                                dashboardCss.link,
                                                                                dashboardCss.pageItemBodyLink,
                                                                            ]}
                                                                            onClick={() =>
                                                                                push({
                                                                                    pathname: `/channels/groups/edit/${group.channel.id}`,
                                                                                })
                                                                            }
                                                                        >
                                                                            edit group
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                            <hr />
                                        </div>
                                    )}
                            </div>
                        </Paper>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    putStudent: actionCreators.putStudent.create,
    getOneStudent: actionCreators.getOneStudent.create,
    clearStudent: actionCreators.clearStudent.create,
};

const mapStateToProps = state => ({
    errorRes: state.LseStudents.errorRes,
    initialValues: state.LseStudents.user,
    formObject: state.form.Student || { values: { ...state.LseStudents.user } },
    currentFormType: state.LseStudents.currentFormType,
});

export default connect(mapStateToProps, dispatchToProps)(EditStudentContainer);
