import React from "react";
import { Route, Switch } from "react-router-dom";
import "./index.css";

import Home from "./Home/Home";
import KnowledgeBase from "./KnowledgeBase";
import KnowledgeBasePage from "./KnowledgeBase/Page";

const AppSupport = () => {
    return (
        <Switch>
            <Route exact path="/support/knowledge-base" component={KnowledgeBase} />
            <Route
                exact
                path="/support/knowledge-base/:categoryId/:articleId?"
                component={KnowledgeBasePage}
            />
            <Route exact path="/support/:type?" component={Home} />
        </Switch>
    );
};

export default AppSupport;
