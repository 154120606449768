import { Component } from "react";
import { reduxForm, Field, Fields, formValueSelector, change } from "redux-form";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import validate from "./validate";
import { RenderAutocomplete, RenderFileInput } from "../../../../common/components/FormFields";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";
import globalsCss from "../../../../common/styles/globals.css";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";
import actionCreators from "../../../../store/OfficeHours/Administrators/actionCreators";

class Form extends Component {
    state = {
        forceReplace: null,
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const {
            getUsers,
            getAcademics,
            getAdmins,
            clearUsers,
            clearAcademics,
            clearAdmins,
        } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            if (query.filter !== "") {
                rawUpdateListOnFilterChange({
                    getFn:
                        query.type === "user"
                            ? getUsers
                            : query.type === "academic"
                            ? getAcademics
                            : getAdmins,
                    filter: { filter: query.filter },
                });
            } else
                query.type === "user"
                    ? clearUsers()
                    : query.type === "academic"
                    ? clearAcademics()
                    : clearAdmins();
        });
        this.props.initialize(this.props.initialValues);
    }

    onUserChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () =>
            searchText$.next({ filter: this.state.searchText, type: "user" })
        );
    };

    onAcademicChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () =>
            searchText$.next({ filter: this.state.searchText, type: "academic" })
        );
    };

    onAdminsChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () =>
            searchText$.next({ filter: this.state.searchText, type: "admin" })
        );
    };

    componentDidUpdate(prevProps) {
        const {
            importQueue,
            importResult,
            clearQueue,
            formAcademics,
            changeAcademics,
            copyAssociatedAcademics,
            clearCopyAssociatedAcademics,
            changeCopyFromAdmin,
            formAdministrator,
        } = this.props;
        const { forceReplace } = this.state;

        if (prevProps.importQueue !== importQueue && importQueue === 0) {
            let newValue = [...formAcademics];
            importResult.forEach(item => {
                if (!newValue.find(i => i.id === item.id)) newValue.push(item);
            });
            changeAcademics(newValue);
            clearQueue();
        }

        if (
            !prevProps.copyAssociatedAcademics &&
            !!copyAssociatedAcademics &&
            copyAssociatedAcademics.length > 0
        ) {
            let filteredCopy = formAdministrator
                ? copyAssociatedAcademics.filter(a => a.id !== formAdministrator.id)
                : copyAssociatedAcademics;
            changeCopyFromAdmin(null);
            if (forceReplace === 0) {
                // add
                let newValue = formAcademics ? [...formAcademics] : [];
                filteredCopy.forEach(item => {
                    if (!newValue || !newValue.find(n => n.id === item.id)) newValue.push(item);
                });
                changeAcademics(newValue);
            } else {
                // replace
                changeAcademics(filteredCopy);
            }
            this.setState({ forceReplace: null });
            clearCopyAssociatedAcademics();
        }
    }

    onFileUploaded = () => {
        const { formFile, changeFile, setQueue, importQueue, importAcademic } = this.props;
        if (!!formFile && !importQueue) {
            if (this.props.formFile.data) {
                const clearedData = this.props.formFile.data.filter(row => !!row && row.length > 0);
                // scheudule queue
                if (clearedData.length > 0) {
                    setQueue(clearedData.length);
                    clearedData.forEach(i => {
                        importAcademic({ filter: i[0] }); // do import
                    });
                }
            }

            changeFile(null);
        }
    };

    copyAssociatedAcademics = forceReplace => {
        const { copyFromAdmin, getCopyAssociatedAcademics } = this.props;
        if (copyFromAdmin) {
            this.setState({ forceReplace: forceReplace }, () =>
                getCopyAssociatedAcademics({ id: copyFromAdmin.id })
            );
        }
    };

    render() {
        const {
            handleSubmit,
            errors,
            formError,
            push,
            users,
            academics,
            currentFormType,
            clearUsers,
            clearAcademics,
            admins,
            clearAdmins,
            initialValues,
            copyFromAdmin,
        } = this.props;
        const { onFileUploaded, copyAssociatedAcademics } = this;

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="user"
                            errorRes={errors.user}
                            handleInput={this.onUserChangeHandler}
                            dataSource={users}
                            component={RenderAutocomplete}
                            single
                            onDroppedFocus={() => clearUsers()}
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                        <label css={formsCss.labelBig}>Associated academics</label>
                        <Field
                            name="academics"
                            errorRes={errors.academics}
                            handleInput={this.onAcademicChangeHandler}
                            dataSource={academics}
                            component={RenderAutocomplete}
                            onDroppedFocus={() => clearAcademics()}
                            blacklistedIds={[initialValues.user.id]}
                        />
                    </div>
                    <div className="col-sm-1 col-xs-12">
                        <div css={formsCss.dividerVertical} />
                    </div>
                    <div className="col-sm-5 col-xs-12">
                        <label css={formsCss.labelBig}>Copy list from another administrator</label>
                        <Field
                            name="copyFromAdmin"
                            handleInput={this.onAdminsChangeHandler}
                            dataSource={admins}
                            component={RenderAutocomplete}
                            onDroppedFocus={() => clearAdmins()}
                            single
                        />
                        {copyFromAdmin && (
                            <div css={[formsCss.actionsAdd, globalsCss.helpers.mt25]}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => copyAssociatedAcademics(0)}
                                    css={formsCss.btnBack}
                                >
                                    Add to list
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => copyAssociatedAcademics(1)}
                                    css={formsCss.btnBack}
                                >
                                    Replace current list
                                </Button>
                            </div>
                        )}
                        <div css={formsCss.orBox}>
                            <span>or</span>
                        </div>
                        <label
                            css={[formsCss.labelBig, formsCss.labelBigBottomMargin]}
                            htmlFor="file"
                        >
                            Import list of academics
                        </label>
                        <div css={formsCss.label}>
                            Upload a file of LSE SSO IDs or email addresses{" "}
                            <small css={formsCss.labelHelp}> (xls or csv)</small>
                        </div>
                        <Fields
                            names={["file"]}
                            errorRes={errors.file}
                            component={RenderFileInput}
                            onChange={onFileUploaded()}
                            parseSpreadsheetOnUpload
                        />
                    </div>
                </div>

                {formError && (
                    <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                        {formError}
                    </div>
                )}
                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        {currentFormType === currentFormTypes.NEW
                            ? "Add New Bookings Administrator"
                            : "Save Bookings Administrator"}
                    </Button>
                    <Button onClick={() => push("/bookings/administrators")} css={formsCss.btnBack}>
                        Back to the list
                    </Button>
                </div>
            </form>
        );
    }
}

const formName = "OfficeHoursAdministrator";
const form = reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
})(Form);

const selector = formValueSelector(formName);

const mapStateToProps = ({ OfficeHoursAdministrators, form, Auth }) => ({
    formFile: selector({ form }, "file"),
    formAcademics: selector({ form }, "academics"),
    formAdministrator: selector({ form }, "user"),
    copyFromAdmin: selector({ form }, "copyFromAdmin"),
    importQueue: OfficeHoursAdministrators.importQueue,
    importResult: OfficeHoursAdministrators.importResult,
    copyAssociatedAcademics: OfficeHoursAdministrators.copyAssociatedAcademics,
});

const dispatchToProps = {
    changeFile: newValue => change(formName, "file", newValue),
    changeAcademics: newValue => change(formName, "academics", newValue),
    changeCopyFromAdmin: newValue => change(formName, "copyFromAdmin", newValue),
    importAcademic: actionCreators.importOfficeHoursAdministratorAcademics.create,
    setQueue: actionCreators.importOfficeHoursAdministratorAcademicsSetQueue.create,
    reduceQueue: actionCreators.importOfficeHoursAdministratorAcademicsReduceQueue.create,
    clearQueue: actionCreators.importOfficeHoursAdministratorAcademicsComplete.create,
    getCopyAssociatedAcademics: actionCreators.getCopyAssociatedAcademics.create,
    clearCopyAssociatedAcademics: actionCreators.clearCopyAssociatedAcademics.create,
};

export default connect(mapStateToProps, dispatchToProps)(form);
