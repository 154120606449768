// eslint-disable-next-line no-unused-vars
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Sessions/actionCreators";
import { default as appointmentActionCreators } from "../../../store/OfficeHours/Appointments/actionCreators";
import { default as availabilitiesMenuActionCreators } from "../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { TableComponent, RedirectButton } from "../../../common/components/Channels";
import { Dialog, Paper, Typography, DialogContent, DialogActions } from "@material-ui/core";
import PromptDialog from "../../../common/components/PromptDialog";
import Button from "@material-ui/core/Button";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";
import formsCss from "../../../common/styles/forms.css";

import {
    LockIcon,
    LockOpenIcon,
    EventAvailableIcon,
    EventBusyIcon,
    EventNoteIcon,
    PersonIcon,
} from "../../../common/components/icons";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import { bookingStatusCallback } from "../../../common/services/tableCallbacks";

import { MainTitle } from "../../../common/components/MainTitle";
import { SubTitle } from "../../../common/components/SubTitle";
import TabsComponent from "../../../common/components/Tabs";

import BookingForm from "./BookingForm";
import MessageForm from "./MessageForm";
import { GetFormattedDate } from "../../../common/components/Time";
import { spacing } from "../../../common/styles/spacing";
import { ACADEMIC, GENERIC_AVAILABILITY, SESSION_BOOKABLE, SESSION_DROPIN } from "./types";
import ReassignForm from "../ManageOfficeHours/ReassignForm";
import moment from "moment-timezone";
import CommentForm from "../MyAppointments/CommentForm";
import AddDropInAttendeeForm from "./AddDropInAttendeeForm";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            status: "Status",
            time: "From / To",
            booking: "Student",
        },
    },
];

const buttons = (items, session) => index => {
    let buttons = [
        {
            path: `/bookings/appointments/${items[index] ? items[index].id : ""}`,
            label: "Details",
            fnLabel: "push",
            icon: <EventNoteIcon css={tableCss.actionsMenuItemIcon} />,
        },
    ];

    if (items[index].status === "available") {
        buttons.push({
            path: `${items[index] ? items[index].id : null}`,
            label: "Book",
            fnLabel: "bookSlot",
            icon: <EventAvailableIcon css={tableCss.actionsMenuItemIcon} />,
        });
        buttons.push({
            path: `${items[index] ? items[index].id : null}`,
            label: "Block",
            fnLabel: "blockSlot",
            icon: <LockIcon css={tableCss.actionsMenuItemIcon} />,
        });
    }

    if (items[index].status === "booked") {
        buttons.push({
            path: `${items[index] ? items[index].id : null}`,
            label: "Cancel booking",
            fnLabel: "cancelBookingSlot",
            icon: <EventBusyIcon css={tableCss.actionsMenuItemIcon} />,
        });

        if (moment().diff(session.end, "minutes") > 0) {
            buttons.push({
                path: `${items[index] ? items[index].id : null}`,
                label: "Mark attendance",
                fnLabel: "handleAttendanceOpen",
                icon: <EventAvailableIcon css={tableCss.actionsMenuItemIcon} />,
            });
        }
    }

    if (items[index].status === "blocked") {
        buttons.push({
            path: `${items[index] ? items[index].id : null}`,
            label: "Remove block",
            fnLabel: "cancelBlockSlot",
            icon: <LockOpenIcon css={tableCss.actionsMenuItemIcon} />,
        });
    }

    return buttons;
};

const dropInButtons = items => index => {
    let buttons = [
        {
            path: `${items[index] ? items[index].id : null}`,
            label: "Remove",
            fnLabel: "cancelBookedDropInSlot",
            icon: <EventBusyIcon css={tableCss.actionsMenuItemIcon} />,
        },
    ];

    return buttons;
};

const callbacks = {
    status: bookingStatusCallback,
};

const columns = ["id", "status", "time", "booking"];
const dropInColumns = ["id", "booking"];

class OfficeHoursSessionDetailsContainer extends Component {
    state = {
        sessionInitiated: false,
        slotsInitiated: false,
        isAcademicContext: null,
        memberDetails: null,
        blockId: null,
        bookId: null,
        deleteSessionDialog: false,
        removeDialogReason: false,
        meetingNotesDialog: false,
        meetingNotes: "",
        displayCancellationSlots: false,
        ressignDialog: false,
        attendanceBookingId: false,
        commentFormVisible: false,
    };

    componentDidMount() {
        const {
            setTitle,
            getOfficeHoursSessionDetails,
            clearOneMyOfficeHoursTeam,
            match,
        } = this.props;
        setTitle("Bookings \u203A Manage Availability");
        clearOneMyOfficeHoursTeam();
        getOfficeHoursSessionDetails(match.params.id);
    }

    componentDidUpdate(prevProps) {
        const {
            session,
            getOneMyOfficeHoursAcademic,
            getOneMyOfficeHoursTeam,
            academic,
            team,
        } = this.props;
        const { sessionInitiated, slotsInitiated, isAcademicContext } = this.state;
        if (
            !prevProps.session &&
            session &&
            session.id > 0 &&
            prevProps.session !== session &&
            !sessionInitiated
        ) {
            this.setState(
                {
                    sessionInitiated: true,
                    isAcademicContext: !!session.academicId,
                    slotsInitiated: false,
                },
                () => {
                    if (this.state.isAcademicContext && !academic) {
                        getOneMyOfficeHoursAcademic(session.academicId);
                    } else if (!this.state.isAcademicContext && !team) {
                        getOneMyOfficeHoursTeam(session.teamId);
                    }
                }
            );
        }

        if (
            ((isAcademicContext && academic && academic.id > 0) || (team && team.id > 0)) &&
            session &&
            session.id > 0 &&
            !slotsInitiated
        ) {
            this.initComponent();
        }

        if (
            prevProps.session &&
            session &&
            prevProps.session !== session &&
            prevProps.session.academicName !== session.academicName &&
            !!slotsInitiated
        ) {
            this.setState({
                memberDetails: session.academicName ? session.academicName : "",
            });
        }
    }

    initComponent() {
        const { getOfficeHoursSessionSlots, match, session } = this.props;

        this.setState(
            {
                slotsInitiated: true,
                memberDetails: session.academicName ? session.academicName : "",
            },
            () => {
                getOfficeHoursSessionSlots(match.params.id);
            }
        );
    }

    componentWillUnmount() {
        const {
            clearTitle,
            clearOfficeHoursSessionDetails,
            clearOfficeHoursSessionSlots,
        } = this.props;
        clearTitle();
        clearOfficeHoursSessionDetails();
        clearOfficeHoursSessionSlots();
    }

    bookSlot = id => {
        this.handleBookOpen(id);
    };

    handleBookOpen = itemId => {
        this.props.toggleOfficeHoursBookingDialog();
        this.setState({ bookId: itemId });
    };

    handleBookClose = () => {
        this.props.toggleOfficeHoursBookingDialog();
        this.setState({ bookId: null });
    };

    cancelBookingSlot = id => {
        this.handleCancelBookOpen(id);
    };

    handleCancelBookOpen = itemId => {
        this.props.toggleOfficeHoursCancelBookingDialog();
        this.setState({ bookId: itemId });
    };

    handleCancelBookClose = () => {
        this.props.toggleOfficeHoursCancelBookingDialog();
        this.setState({ bookId: null });
    };

    blockSlot = id => {
        this.handleBlockOpen(id);
    };

    handleBlockOpen = itemId => {
        this.props.toggleOfficeHoursBlockDialog();
        this.setState({ blockId: itemId });
    };

    handleBlockClose = () => {
        this.props.toggleOfficeHoursBlockDialog();
        this.setState({ blockId: null });
    };

    cancelBlockSlot = id => {
        this.handleUnblockOpen(id);
    };

    handleUnblockOpen = itemId => {
        this.props.toggleOfficeHoursUnblockDialog();
        this.setState({ blockId: itemId });
    };

    handleUnblockClose = confirmed => {
        const { removeBlockOfficeHoursSlot, toggleOfficeHoursUnblockDialog } = this.props;
        const { blockId } = this.state;

        if (confirmed) {
            removeBlockOfficeHoursSlot({
                slotId: blockId,
            });
        }
        toggleOfficeHoursUnblockDialog();
        this.setState({ blockId: null });
    };

    onBookingSubmit = values => {
        const { makeOfficeHoursBooking } = this.props;
        const { bookId } = this.state;

        if (values.attachmentFile && values.attachmentFile.file) delete values.attachmentFile.file;

        makeOfficeHoursBooking({
            userId: values.users.id,
            slotId: bookId,
            data: {
                message: values.note,
                file: values.attachmentFile,
            },
        });
    };

    onCancelBookingSubmit = values => {
        const { cancelOfficeHoursBooking } = this.props;
        const { bookId } = this.state;

        cancelOfficeHoursBooking({
            slotId: bookId,
            data: {
                message: values.message,
            },
        });
    };

    onBlockSlotSubmit = values => {
        const { blockOfficeHoursSlot } = this.props;
        const { blockId } = this.state;

        blockOfficeHoursSlot({
            slotId: blockId,
            data: {
                message: values.message,
            },
        });
    };

    handleDeleteSessionDialogClose = confirmed => {
        const { sessionSlots } = this.props;
        this.setState({ deleteSessionDialog: false });
        if (confirmed === true) {
            if (sessionSlots.find(item => item.status === "booked")) {
                // session has some bookings - ask for cancellation message
                this.setState({ removeDialogReason: true });
            } else {
                // no bokings - proceed with session removal
                this.onRemoveSession({ message: "" });
            }
        }
    };

    onRemoveSession = values => {
        const { deleteOfficeHoursSession, academic, session, team, userId } = this.props;
        const { isAcademicContext } = this.state;
        deleteOfficeHoursSession({
            sessionId: session.id,
            reason: values.message,
            redirectUrl: this.props.location.state.context
                ? this.props.location.state.context === ACADEMIC && academic
                    ? `/bookings/browse/${academic.id}`
                    : session.availabilityRule.type === GENERIC_AVAILABILITY &&
                      team.admins.find(a => a.id === userId)
                    ? `/bookings/browse/team/${session.teamId}/0`
                    : `/bookings/browse/team/${session.teamId}/${session.teamMemberId}`
                : isAcademicContext && academic
                ? `/bookings/browse/${academic.id}`
                : session.availabilityRule.type === GENERIC_AVAILABILITY &&
                  team.admins.find(a => a.id === userId)
                ? `/bookings/browse/team/${session.teamId}/0`
                : `/bookings/browse/team/${session.teamId}/${session.teamMemberId}`,
        });
        this.setState({ removeDialogReason: false, displayCancellationSlots: false });
    };

    urlify(text) {
        if (!text) return;

        const urlRegex = /(https?:\/\/[^\s]+)/g;
        let i = 0;
        return text.split("\n").map((item, key) => {
            return (
                <span key={key}>
                    {item.split(urlRegex).map(part => {
                        if (part.match(urlRegex)) {
                            i = i + 1;
                            return (
                                <a href={part} key={i} target="_blank" rel="noopener noreferrer">
                                    {part}
                                </a>
                            );
                        }
                        return part;
                    })}
                    <br />
                </span>
            );
        });
    }

    handleReassignOpen = () => {
        this.setState({ ressignDialog: true });
    };

    handleReassignClose = () => {
        this.setState({ ressignDialog: false });
    };

    onRessignAvailability = values => {
        const { assignOfficeHoursGenericAvailabilitySession, session } = this.props;
        assignOfficeHoursGenericAvailabilitySession({
            id: session.id,
            teamMemberId: values.teamMemberId,
        });
        this.handleReassignClose();
    };

    handleAttendanceOpen = id => {
        this.setState({ attendanceBookingId: id });
    };

    toggleStudentAttendance = () => {
        const {
            markOfficeHoursAppointmentAsStudentAttended,
            markOfficeHoursAppointmentAsStudentNotAttended,
            sessionSlots,
        } = this.props;
        const { attendanceBookingId } = this.state;

        const sessionSlot = sessionSlots
            ? sessionSlots.find(i => i.id === Number(attendanceBookingId))
            : null;
        if (sessionSlot) {
            if (sessionSlot.studentAttended === true)
                markOfficeHoursAppointmentAsStudentNotAttended(sessionSlot.id);
            else markOfficeHoursAppointmentAsStudentAttended(sessionSlot.id);
        }
    };

    onAttendanceCommentFormSubmit = values => {
        const { putOfficeHoursAppointmentAttendanceComment } = this.props;
        const { attendanceBookingId } = this.state;
        putOfficeHoursAppointmentAttendanceComment({
            slotId: Number(attendanceBookingId),
            data: values,
        });
        this.setState({ commentFormVisible: false });
    };

    onAddDropInAttendeeSubmit = values => {
        const { bookDropInSession, session } = this.props;

        bookDropInSession({
            id: session.id,
            users: values.users.map(u => {
                return { id: u.id };
            }),
        });
    };

    cancelBookedDropInSlot = id => {
        const { session, cancelBookingDropInSession } = this.props;
        cancelBookingDropInSession({
            slotId: id,
            id: session.id,
        });
    };

    render() {
        const {
            memberDetails,
            isAcademicContext,
            deleteSessionDialog,
            displayCancellationSlots,
            attendanceBookingId,
            commentFormVisible,
        } = this.state;
        const {
            academic,
            team,
            session,
            sessionSlots,
            getUsers,
            clearUsers,
            students,
            isBookingDialogOpen,
            isCancelBookingDialogOpen,
            isBlockDialogOpen,
            isUnblockDialogOpen,
            userId,
            history: { push, goBack },
            errorRes: { error, errors },
            markOfficeHoursAppointmentAsStudentNotAttended,
        } = this.props;
        const {
            blockSlot,
            cancelBlockSlot,
            bookSlot,
            cancelBookingSlot,
            onBookingSubmit,
            onCancelBookingSubmit,
            onBlockSlotSubmit,
            handleAttendanceOpen,
            toggleStudentAttendance,
            onAttendanceCommentFormSubmit,
            onAddDropInAttendeeSubmit,
            cancelBookedDropInSlot,
        } = this;

        const appointment = sessionSlots
            ? sessionSlots.find(i => i.id === Number(attendanceBookingId))
            : null;

        const commentDialogActons = [
            <Button
                key="clsoe"
                onClick={() =>
                    this.setState({ attendanceBookingId: false, commentFormVisible: false })
                }
            >
                Close
            </Button>,
        ];

        const earliestBooking = [
            { value: null, label: "" },
            { value: 0, label: "No limit" },
            { value: 24 * 60, label: "1 day in advance" },
            { value: 48 * 60, label: "2 days in advance" },
            { value: 72 * 60, label: "3 days in advance" },
            { value: 96 * 60, label: "4 days in advance" },
            { value: 120 * 60, label: "5 days in advance" },
            { value: 144 * 60, label: "6 days in advance" },
            { value: 168 * 60, label: "7 days in advance" },
            { value: 192 * 60, label: "8 days in advance" },
            { value: 216 * 60, label: "9 days in advance" },
            { value: 240 * 60, label: "10 days in advance" },
            { value: 264 * 60, label: "11 days in advance" },
            { value: 288 * 60, label: "12 days in advance" },
            { value: 312 * 60, label: "13 days in advance" },
            { value: 336 * 60, label: "14 days in advance" },
        ];

        const latestBooking = [
            { value: null, label: "" },
            { value: 0, label: "No limit" },
            { value: 30, label: "30 minutes in advance" },
            { value: 1 * 60, label: "1 hour in advance" },
            { value: 2 * 60, label: "2 hours in advance" },
            { value: 3 * 60, label: "3 hours in advance" },
            { value: 4 * 60, label: "4 hours in advance" },
            { value: 5 * 60, label: "5 hours in advance" },
            { value: 6 * 60, label: "6 hours in advance" },
            { value: 12 * 60, label: "12 hours in advance" },
            { value: 24 * 60, label: "24 hours in advance" },
            { value: 48 * 60, label: "48 hours in advance" },
            { value: 72 * 60, label: "3 days in advance" },
            { value: 96 * 60, label: "4 days in advance" },
            { value: 120 * 60, label: "5 days in advance" },
            { value: 144 * 60, label: "6 days in advance" },
            { value: 168 * 60, label: "7 days in advance" },
        ];

        const bookedSlots = sessionSlots.filter(item => item.status === "booked");

        const isAssigned =
            session && session.availabilityRule.type === GENERIC_AVAILABILITY && memberDetails;

        return (isAcademicContext && academic) ||
            (!isAcademicContext &&
                (memberDetails ||
                    (team &&
                        session &&
                        session.availabilityRule.type === GENERIC_AVAILABILITY))) ? (
            <div>
                <MainTitle
                    title={
                        isAcademicContext
                            ? `${academic.firstName} ${academic.lastName}`
                            : `${
                                  memberDetails
                                      ? memberDetails
                                      : session.availabilityRule.type === GENERIC_AVAILABILITY
                                      ? "Unassigned"
                                      : ""
                              }`
                    }
                    isItalic={
                        !isAcademicContext &&
                        !memberDetails &&
                        session.availabilityRule.type === GENERIC_AVAILABILITY
                    }
                    type={`[Session details]`}
                    rightElement={
                        <div>
                            {session.availabilityRule.type === GENERIC_AVAILABILITY &&
                                team.admins.find(a => a.id === userId) && (
                                    <RedirectButton
                                        label={isAssigned ? "Reassign" : "Assign"}
                                        onClick={() => this.handleReassignOpen()}
                                        variant="text"
                                        color="default"
                                        elementLeft={<PersonIcon css={formsCss.btnIcon} />}
                                        css={{ marginRight: spacing.space2 }}
                                    />
                                )}
                        </div>
                    }
                />

                <Paper elevation={1}>
                    <TabsComponent
                        activeIndex={false}
                        tabs={[
                            {
                                label: "Availability",
                                selected: false,
                                fnLabel: "push",
                                param:
                                    session.availabilityRule.type === GENERIC_AVAILABILITY
                                        ? team.admins.find(a => a.id === userId) // is current user team admin?
                                            ? `/bookings/availability/team/${session.teamId}/${GENERIC_AVAILABILITY}/manage`
                                            : `/bookings/availability/team?${session.teamId}`
                                        : isAcademicContext
                                        ? `/bookings/availability/${session.academicId}/manage`
                                        : `/bookings/availability/team/${session.teamId}/${session.teamMemberId}/manage`,
                            },
                            {
                                label: isAcademicContext
                                    ? "Browse Sessions"
                                    : "Browse Team Sessions",
                                selected: true,
                                fnLabel: "push",
                                param:
                                    session.availabilityRule.type === GENERIC_AVAILABILITY
                                        ? `/bookings/browse/team/${session.teamId}/0`
                                        : isAcademicContext
                                        ? `/bookings/browse/${academic.id}`
                                        : `/bookings/browse/team/${session.teamId}/${session.teamMemberId}`,
                            },
                        ]}
                        fns={{ push }}
                    />
                </Paper>

                {team && (
                    <SubTitle
                        title={team.name}
                        type={"[Team]"}
                        css={globalsCss.subTitleMarginMobile}
                    />
                )}

                {session && (
                    <Paper elevation={1} css={globalsCss.helpers.mb10}>
                        <div css={globalsCss.innerTop20}>
                            <div className="row">
                                <div className="col-sm-4 col-xs-12" css={globalsCss.helpers.mt15}>
                                    <strong>Title</strong>
                                    <div>{session.availabilityRule.title}</div>
                                </div>
                                <div className="col-sm-4 col-xs-12" css={globalsCss.helpers.mt15}>
                                    <strong>Date</strong>
                                    <div>{GetFormattedDate(session.start)}</div>
                                </div>

                                <div className="col-sm-4 col-xs-12" css={globalsCss.helpers.mt15}>
                                    <strong>Time</strong>
                                    <div>
                                        {`${GetFormattedDate(
                                            session.start,
                                            "h.mma"
                                        )} - ${GetFormattedDate(session.end, "h.mma")}`}
                                        {session.availabilityRule.mode === SESSION_BOOKABLE &&
                                            ` (${session.slotsCount} x ${session.availabilityRule.settings.duration} min)`}
                                    </div>
                                </div>

                                {session.availabilityRule.settings.location && (
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <strong>Location</strong>
                                        <div>{session.availabilityRule.settings.location}</div>
                                    </div>
                                )}
                                {session.availabilityRule.settings.appointmentForm && (
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <strong>Appointment type</strong>
                                        <div>
                                            {session.availabilityRule.settings.appointmentForm}
                                        </div>
                                    </div>
                                )}
                                {session.availabilityRule.settings.meetingUrl && (
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <strong>Online meeting details:</strong>
                                        <div>
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        meetingNotesDialog: true,
                                                        meetingNotes:
                                                            session.availabilityRule.settings
                                                                .meetingUrl,
                                                    })
                                                }
                                            >
                                                View Details
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                {session.zoomStartUrl && (
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <Button
                                            href={session.zoomStartUrl}
                                            size="small"
                                            variant="contained"
                                            color="secondary"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Join Zoom Meeting
                                        </Button>
                                    </div>
                                )}

                                {session.availabilityRule.settings.maxNumberOfAttendees &&
                                    session.availabilityRule.settings.maxNumberOfAttendees > 1 && (
                                        <div
                                            className="col-sm-4 col-xs-12"
                                            css={globalsCss.helpers.mt15}
                                        >
                                            <strong>Maximum number of attendees per slot</strong>
                                            <div>
                                                {
                                                    session.availabilityRule.settings
                                                        .maxNumberOfAttendees
                                                }
                                            </div>
                                        </div>
                                    )}
                                {session.availabilityRule.settings.maxAppointmentsPerSession &&
                                    session.availabilityRule.settings.maxAppointmentsPerSession >
                                        1 && (
                                        <div
                                            className="col-sm-4 col-xs-12"
                                            css={globalsCss.helpers.mt15}
                                        >
                                            <strong>Maximum number of bookings per student</strong>
                                            <div>
                                                {
                                                    session.availabilityRule.settings
                                                        .maxAppointmentsPerSession
                                                }
                                            </div>
                                        </div>
                                    )}

                                {!!session.availabilityRule.settings.earliestBooking && (
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <strong>Earliest booking</strong>
                                        <div>
                                            {
                                                earliestBooking.find(
                                                    item =>
                                                        item.value ===
                                                        session.availabilityRule.settings
                                                            .earliestBooking
                                                ).label
                                            }
                                        </div>
                                    </div>
                                )}
                                {!!session.availabilityRule.settings.latestBooking && (
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <strong>Latest booking</strong>
                                        <div>
                                            {
                                                latestBooking.find(
                                                    item =>
                                                        item.value ===
                                                        session.availabilityRule.settings
                                                            .latestBooking
                                                ).label
                                            }
                                        </div>
                                    </div>
                                )}
                                {session.availabilityRule.settings.note && (
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <strong>Note for students</strong>
                                        <div>{session.availabilityRule.settings.note}</div>
                                    </div>
                                )}
                                {session.availabilityRule.mode && (
                                    <div
                                        className="col-sm-4 col-xs-12"
                                        css={globalsCss.helpers.mt15}
                                    >
                                        <strong>Session type</strong>
                                        <div>
                                            {session.availabilityRule.mode === SESSION_BOOKABLE
                                                ? "Bookable"
                                                : "Drop-in"}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {(session.availabilityRule.type !== GENERIC_AVAILABILITY ||
                            (session.availabilityRule.type === GENERIC_AVAILABILITY &&
                                team.admins.find(a => a.id === userId))) && (
                            <div css={globalsCss.actionsSmall}>
                                <Button
                                    onClick={() => this.setState({ deleteSessionDialog: true })}
                                >
                                    Delete booking session
                                </Button>
                            </div>
                        )}
                    </Paper>
                )}

                {session.availabilityRule.mode === SESSION_DROPIN ? (
                    <Paper elevation={1}>
                        <div>
                            <div css={[globalsCss.actionsTableHeader]}>
                                <div>
                                    <SubTitle title="Session attendees" noMarginBottom />
                                    <div css={globalsCss.helpers.mb20}>
                                        <AddDropInAttendeeForm
                                            {...{
                                                onSubmit: onAddDropInAttendeeSubmit,
                                                errors,
                                                students,
                                                getUsers,
                                                clearUsers,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {sessionSlots && sessionSlots.length > 0 && (
                                <TableComponent
                                    items={sessionSlots}
                                    fns={{
                                        cancelBookedDropInSlot,
                                    }}
                                    buttons={dropInButtons(sessionSlots)}
                                    decorators={decorators}
                                    columns={dropInColumns}
                                />
                            )}
                        </div>
                    </Paper>
                ) : (
                    sessionSlots &&
                    sessionSlots.length > 0 && (
                        <Paper elevation={1}>
                            <TableComponent
                                items={sessionSlots}
                                fns={{
                                    push,
                                    blockSlot,
                                    cancelBlockSlot,
                                    bookSlot,
                                    cancelBookingSlot,
                                    handleAttendanceOpen,
                                }}
                                buttons={buttons(sessionSlots, session)}
                                decorators={decorators}
                                callbacks={callbacks}
                                columns={columns}
                            />
                        </Paper>
                    )
                )}

                <Dialog
                    open={isBookingDialogOpen}
                    onClose={this.handleBookClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>Book appointment</h2>
                        {error && <div css={formsCss.genericError}>{error}</div>}
                        <BookingForm
                            {...{
                                onSubmit: onBookingSubmit,
                                push,
                                errors,
                                students,
                                getUsers,
                                clearUsers,
                            }}
                        />
                    </div>
                </Dialog>

                <Dialog
                    open={isCancelBookingDialogOpen}
                    onClose={this.handleCancelBookClose}
                    maxWidth="xs"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>Cancel appointment</h2>
                        {error && <div css={formsCss.genericError}>{error}</div>}
                        <MessageForm
                            {...{
                                onSubmit: onCancelBookingSubmit,
                                push,
                                errors,
                                fieldLabel: "Reason for cancellation",
                                buttonLabel: "Cancel booking",
                            }}
                        />
                    </div>
                </Dialog>

                <Dialog
                    open={isBlockDialogOpen}
                    onClose={this.handleBlockClose}
                    maxWidth="xs"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>Block slot</h2>
                        {error && <div css={formsCss.genericError}>{error}</div>}
                        <MessageForm
                            {...{
                                onSubmit: onBlockSlotSubmit,
                                push,
                                errors,
                                fieldLabel: "Reason for blocking",
                                buttonLabel: "Block slot",
                            }}
                        />
                    </div>
                </Dialog>

                <PromptDialog open={isUnblockDialogOpen} handleClose={this.handleUnblockClose}>
                    Are you sure you want to unblock this slot?
                </PromptDialog>

                <PromptDialog
                    open={deleteSessionDialog}
                    handleClose={this.handleDeleteSessionDialogClose}
                >
                    Are you sure you want to delete this booking session?
                </PromptDialog>

                <Dialog
                    open={this.state.removeDialogReason}
                    onClose={() => {
                        this.setState({ removeDialogReason: false });
                    }}
                    maxWidth="sm"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>
                            Some of your existing appointments will be cancelled
                        </h2>
                        <p>
                            Saving this change will cancel <strong>{bookedSlots.length}</strong>{" "}
                            existing {`appointment${bookedSlots.length > 1 ? "s" : ""}`}. Are you
                            sure?
                        </p>
                        {displayCancellationSlots ? (
                            <div>
                                <Button
                                    color="secondary"
                                    onClick={() =>
                                        this.setState({ displayCancellationSlots: false })
                                    }
                                >
                                    Hide {`appointment${bookedSlots.length > 1 ? "s" : ""}`}
                                </Button>
                                <div style={globalsCss.table}>
                                    <div style={globalsCss.tableHeaderItem}>
                                        <Typography
                                            component="div"
                                            variant="subtitle1"
                                            style={globalsCss.tableItemText}
                                        >
                                            <strong>Date &amp; Time</strong>
                                        </Typography>
                                        <Typography
                                            component="div"
                                            variant="subtitle1"
                                            style={globalsCss.tableItemText}
                                        >
                                            <strong>Student</strong>
                                        </Typography>
                                    </div>
                                    {sessionSlots
                                        .filter(item => item.status === "booked")
                                        .map((slot, index) => (
                                            <div key={`slot-${index}`} style={globalsCss.tableItem}>
                                                <Typography
                                                    component="div"
                                                    variant="subtitle1"
                                                    style={globalsCss.tableItemText}
                                                >
                                                    {slot.startAt}
                                                </Typography>
                                                <Typography
                                                    component="div"
                                                    variant="subtitle1"
                                                    style={globalsCss.tableItemText}
                                                >
                                                    {slot.booking}
                                                </Typography>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ) : (
                            <Button
                                color="secondary"
                                onClick={() => this.setState({ displayCancellationSlots: true })}
                            >
                                Show {`appointment${bookedSlots.length > 1 ? "s" : ""}`}
                            </Button>
                        )}
                        <p>
                            Please add a note so that students know why their appointments have been
                            cancelled. They will receive an email with your explanation.
                        </p>
                        <p>
                            Alternatively, please contact{" "}
                            <a href="mailto:studenthub@lse.ac.uk">studenthub@lse.ac.uk</a> if you
                            require any support.
                        </p>
                        {error && <div css={formsCss.genericError}>{error}</div>}
                        <MessageForm
                            {...{
                                onSubmit: this.onRemoveSession,
                                push,
                                errors: {
                                    ...errors,
                                    ...(errors.reason ? { message: errors.reason } : {}),
                                },
                                fieldLabel: "Reason for cancellation",
                                buttonLabel: "Confirm",
                            }}
                        />
                    </div>
                </Dialog>

                <Dialog
                    open={this.state.meetingNotesDialog}
                    onClose={() => this.setState({ meetingNotesDialog: false, meetingNotes: "" })}
                    maxWidth="xs"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>Online Meeting Notes</h2>
                        {this.urlify(this.state.meetingNotes)}
                    </div>
                </Dialog>

                <Dialog
                    open={this.state.ressignDialog}
                    onClose={() => this.handleReassignClose()}
                    maxWidth="sm"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>
                            {isAssigned ? "Reassign" : "Assign"} this session
                        </h2>

                        <p>This will also move any existing bookings to the selected team member</p>
                        {team && (
                            <ReassignForm
                                {...{
                                    onSubmit: this.onRessignAvailability,
                                    users: [...team.users].map(i => {
                                        return {
                                            value: i.officeHoursTeamMembers.find(
                                                t => t.teamId === team.id && t.userType === "member"
                                            )?.id,
                                            label: `${i.firstName} ${i.lastName}`,
                                        };
                                    }),
                                    isAssigned,
                                    onCancel: () => this.handleReassignClose(),
                                }}
                            />
                        )}
                    </div>
                </Dialog>

                <Dialog
                    open={Boolean(attendanceBookingId)}
                    onClose={() =>
                        this.setState({ attendanceBookingId: false, commentFormVisible: false })
                    }
                    maxWidth="lg"
                    fullWidth
                    actions={commentDialogActons}
                >
                    <DialogContent>
                        <div css={globalsCss.innerDialog}>
                            <Fragment>
                                <div css={globalsCss.userCardBody}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Appointment with: <strong>{appointment?.booking}</strong>
                                    </Typography>
                                    <div className="row">
                                        <div
                                            className="col-sm-4 col-xs-12"
                                            css={globalsCss.helpers.mt15}
                                        >
                                            <Typography component="p" variant="subtitle1">
                                                <strong>Date: </strong> {appointment?.date}
                                            </Typography>
                                            <Typography component="p" variant="subtitle1">
                                                <strong>Time:</strong> {appointment?.time}
                                            </Typography>
                                        </div>
                                        {appointment && (
                                            <Fragment>
                                                <div
                                                    className="col-sm-4 col-xs-12"
                                                    css={globalsCss.helpers.mt15}
                                                >
                                                    <strong>Student attended?</strong>
                                                    <div>
                                                        {appointment.studentAttended === null
                                                            ? "n/a"
                                                            : appointment.studentAttended
                                                            ? "Yes"
                                                            : "No"}
                                                    </div>
                                                    <div css={globalsCss.actionsAttendance}>
                                                        <Button onClick={toggleStudentAttendance}>
                                                            {appointment.studentAttended === true
                                                                ? "Mark as NOT attended"
                                                                : "Mark as attended"}
                                                        </Button>

                                                        {appointment.studentAttended === null && (
                                                            <Button
                                                                onClick={() =>
                                                                    markOfficeHoursAppointmentAsStudentNotAttended(
                                                                        appointment.id
                                                                    )
                                                                }
                                                            >
                                                                Mark as NOT attended
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>

                                                {commentFormVisible ? (
                                                    <div
                                                        className="col-sm-4 col-xs-12"
                                                        css={globalsCss.helpers.mt15}
                                                    >
                                                        {error && (
                                                            <div css={formsCss.genericError}>
                                                                {error}
                                                            </div>
                                                        )}
                                                        <CommentForm
                                                            {...{
                                                                onSubmit: onAttendanceCommentFormSubmit,
                                                                push,
                                                                errors,
                                                                fieldLabel: "Comment",
                                                                buttonLabel: appointment.comment
                                                                    ? "Amend comment"
                                                                    : "Add comment",
                                                                initialValues: {
                                                                    comment: appointment.comment,
                                                                },
                                                                simpleLayout: true,
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="col-sm-4 col-xs-12"
                                                        css={globalsCss.helpers.mt15}
                                                    >
                                                        <strong>Comment:</strong>
                                                        <div>
                                                            {appointment?.comment
                                                                ? appointment?.comment
                                                                : "n/a"}
                                                        </div>
                                                        <div css={globalsCss.actionsAttendance}>
                                                            <Button
                                                                css={formsCss.btnSubmit}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        commentFormVisible: !commentFormVisible,
                                                                    })
                                                                }
                                                            >
                                                                {appointment.comment
                                                                    ? "Amend comment"
                                                                    : "Add comment"}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                    </DialogContent>
                    <DialogActions>{commentDialogActons}</DialogActions>
                </Dialog>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneMyOfficeHoursAcademic:
        availabilitiesMenuActionCreators.getOneMyOfficeHoursAcademic.create,
    getOneMyOfficeHoursTeam: availabilitiesMenuActionCreators.getOneMyOfficeHoursTeam.create,
    getOfficeHoursSessionDetails: actionCreators.getOfficeHoursSessionDetails.create,
    clearOfficeHoursSessionDetails: actionCreators.clearOfficeHoursSessionDetails.create,
    getOfficeHoursSessionSlots: actionCreators.getOfficeHoursSessionSlots.create,
    clearOfficeHoursSessionSlots: actionCreators.clearOfficeHoursSessionSlots.create,
    getUsers: AutoCompleteActionCreators.getStudentUsers.create,
    clearUsers: AutoCompleteActionCreators.clearStudentUsers.create,
    makeOfficeHoursBooking: actionCreators.makeOfficeHoursBooking.create,
    cancelOfficeHoursBooking: actionCreators.cancelOfficeHoursBooking.create,
    blockOfficeHoursSlot: actionCreators.blockOfficeHoursSlot.create,
    removeBlockOfficeHoursSlot: actionCreators.removeBlockOfficeHoursSlot.create,
    toggleOfficeHoursBookingDialog: actionCreators.toggleOfficeHoursBookingDialog.create,
    toggleOfficeHoursBlockDialog: actionCreators.toggleOfficeHoursBlockDialog.create,
    toggleOfficeHoursCancelBookingDialog:
        actionCreators.toggleOfficeHoursCancelBookingDialog.create,
    toggleOfficeHoursUnblockDialog: actionCreators.toggleOfficeHoursUnblockDialog.create,
    deleteOfficeHoursSession: actionCreators.deleteOfficeHoursSession.create,
    clearOneMyOfficeHoursTeam: availabilitiesMenuActionCreators.clearOneMyOfficeHoursTeam.create,
    assignOfficeHoursGenericAvailabilitySession:
        actionCreators.assignOfficeHoursGenericAvailabilitySession.create,
    markOfficeHoursAppointmentAsStudentAttended:
        appointmentActionCreators.markOfficeHoursAppointmentAsStudentAttended.create,
    markOfficeHoursAppointmentAsStudentNotAttended:
        appointmentActionCreators.markOfficeHoursAppointmentAsStudentNotAttended.create,
    putOfficeHoursAppointmentAttendanceComment:
        appointmentActionCreators.putOfficeHoursAppointmentAttendanceComment.create,
    bookDropInSession: actionCreators.bookDropInSession.create,
    cancelBookingDropInSession: actionCreators.cancelBookingDropInSession.create,
};

const mapStateToProps = ({
    OfficeHoursAvailabilities,
    OfficeHoursSessions,
    AutoComplete,
    Auth,
}) => ({
    academic: OfficeHoursAvailabilities.academic,
    team: OfficeHoursAvailabilities.team,
    session: OfficeHoursSessions.session,
    sessionSlots: OfficeHoursSessions.sessionSlots.map(item => {
        return {
            id: item.id,
            time: `${GetFormattedDate(item.start, "h.mma")} - ${GetFormattedDate(
                item.end,
                "h.mma"
            )}`,
            date: `${GetFormattedDate(item.start, "D MMMM YYYY")}`,
            startAt: `${GetFormattedDate(item.start, "h.mma")}`,
            status: item.apiUser ? "booked" : item.isBlocked ? "blocked" : "available",
            booking: item.apiUser ? `${item.apiUser.firstName} ${item.apiUser.lastName}` : "",
            studentAttended: item.studentAttended,
            comment: item.comment,
        };
    }),
    students: AutoComplete.students,
    errorRes: OfficeHoursSessions.errorRes,
    isBookingDialogOpen: OfficeHoursSessions.isBookingDialogOpen,
    isBlockDialogOpen: OfficeHoursSessions.isBlockDialogOpen,
    isCancelBookingDialogOpen: OfficeHoursSessions.isCancelBookingDialogOpen,
    isUnblockDialogOpen: OfficeHoursSessions.isUnblockDialogOpen,
    userId: Auth.id,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursSessionDetailsContainer);
