/** @jsx jsx */
import { jsx } from "@emotion/core";
import { contentContainer } from "./ContentContainerStyles";

const ContentContainer = ({ children }) => (
    <div css={contentContainer.container}>
        <div css={contentContainer.inner}>{children}</div>
    </div>
);

export { ContentContainer };
