export const validate = values => {
    let errors = {};

    const requiredFields = ["teamMemberId"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    return errors;
};
