import { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../common/styles/globals.css";
import formsCss from "../../common/styles/forms.css";
import { LogoutIcon } from "../../common/components/icons";
import history from "../../common/services/history";

class NotAuthorised extends Component {
    render() {
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    You are not authorised to view this page
                    <br />
                    <br />
                    <Button onClick={() => history.push("/")}>
                        <LogoutIcon css={formsCss.btnIcon} />
                        Back to home
                    </Button>
                </div>
            </Paper>
        );
    }
}

export default NotAuthorised;
