export default values => {
    const errors = {};
    const requiredFields = ["name"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    const supportedTypes = [
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (values.sourceFileName === null || values.file) {
        if (!values.file) {
            errors.file = "Required";
        } else if (supportedTypes.indexOf(values.file.type) === -1) {
            errors.file = "Unsupported file type";
        }
    }

    return errors;
};
