import actionCreators from "./actionCreators";
import article from "../../../../fixtures/HowToArticle";

export const initialState = {
    articles: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    article,
    categoryId: "",
    errorRes: { error: "", errors: {} },
    currentFormType: null,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListArticle.type:
            return {
                ...state,
                articles: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListArticle.type:
            payload.data = [
                ...state.articles.data,
                ...payload.data.filter(
                    article => !state.articles.data.map(item => item.id).includes(article.id)
                ),
            ];
            return {
                ...state,
                articles: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneArticle.type:
            return {
                ...state,
                article: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.setCurrentFormType.type:
            return {
                ...state,
                currentFormType: payload,
            };
        case actionCreators.setCategoryId.type:
            return {
                ...state,
                categoryId: payload,
            };
        case actionCreators.clearArticle.type:
            return {
                ...state,
                article: initialState.article,
            };
        default:
            return state;
    }
};
