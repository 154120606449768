import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/HowTo/Category/Articles/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { default as categoryActionCreators } from "../../../../store/HowTo/Category/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import globalsCss from "../../../../common/styles/globals.css";
import formsCss from "../../../../common/styles/forms.css";
import previewCss from "../../../../common/styles/preview.css";
import tableDataCss from "../../../../common/styles/tableData.css";
import { MainTitle } from "../../../../common/components/MainTitle";
import Wysiwyg from "../../../../common/components/Wysiwyg";

class PageChannelPreviewPostContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            getOneArticle,
            getOneCategory,
            match: {
                params: { categoryId, id },
            },
            setCategoryId,
        } = this.props;
        setCategoryId(categoryId);
        setTitle("Knowledge Base \u203A Articles");
        getOneArticle({ id, categoryId });
        getOneCategory({ id: categoryId });
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    render() {
        const {
            match: {
                params: { categoryId },
            },
            history: { push },
            article,
            category,
        } = this.props;
        if (!category || !article) {
            return null;
        }

        return (
            <div>
                <MainTitle title={category.name} type="[Category]" />
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Preview Article</h2>
                        <div>
                            <div css={previewCss.status}>
                                Status:
                                <span
                                    css={[
                                        tableDataCss.statusActive,
                                        article.status === "scheduled" &&
                                            tableDataCss.statusPending,
                                        article.status === "draft" && tableDataCss.statusBlocked,
                                    ]}
                                >
                                    {" "}
                                    {article.status}
                                </span>
                            </div>
                            <div css={previewCss.text}>
                                Title: <strong>{article.title}</strong>
                            </div>
                        </div>
                        <div css={previewCss.body}>
                            <div css={previewCss.paper}>
                                <div css={previewCss.inner}>
                                    {article.type === "html" ? (
                                        <div className="entryText">
                                            <Wysiwyg html={article.content} />
                                        </div>
                                    ) : (
                                        <div className="entryText">
                                            <strong>{article.content}</strong>
                                            <Button
                                                onClick={() =>
                                                    window.open(article.content, "_blank")
                                                }
                                                css={previewCss.previewButton}
                                            >
                                                Open in New Window
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div css={formsCss.actions}>
                            <Button
                                onClick={() =>
                                    push(
                                        `/knowledge-base/categories/${categoryId}/articles/edit/${article.id}`
                                    )
                                }
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Edit Article
                            </Button>
                            <Button
                                onClick={() =>
                                    push(`/knowledge-base/categories/${categoryId}/articles`)
                                }
                                css={formsCss.btnBack}
                            >
                                Back to the list
                            </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneCategory: categoryActionCreators.getOneCategory.create,
    setCategoryId: actionCreators.setCategoryId.create,
    getOneArticle: actionCreators.getOneArticle.create,
};
const mapStateToProps = ({
    HowToArticle: { article, categoryId },
    HowToCategory: { category },
}) => ({
    article,
    categoryId,
    category,
});
export default connect(mapStateToProps, dispatchToProps)(PageChannelPreviewPostContainer);
