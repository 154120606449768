import { colors } from "../../styles/colors";

export const avatar = {
    body: {
        width: 40,
        height: 40,
        borderRadius: 20,
        overflow: "hidden",
        position: "relative",
    },
    border: {
        borderWidth: 1,
        borderColor: colors.black10,
        borderStyle: "solid",
        borderRadius: 20,
        width: 40,
        height: 40,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 15,
    },
    img: {
        display: "block",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: 40,
        height: 40,
    },
    initials: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 20,
    },
    initialsText: {
        lineHeight: 1,
        fontSize: 16,
        color: colors.white,
        fontWeight: "bold",
        userSelect: "none",
    },
};

export const avatarSmallComments = {
    body: {
        width: 32,
        height: 32,
        borderRadius: 16,
    },
    border: {
        borderWidth: 1,
        borderColor: colors.white,
        borderStyle: "solid",
        borderRadius: 16,
        width: 32,
        height: 32,
    },
    img: {
        width: 32,
        height: 32,
        borderRadius: 16,
    },
    initialsText: {
        fontSize: 12,
        color: colors.white,
        fontWeight: "bold",
    },
};

export const avatarSmall = {
    body: {
        width: 24,
        height: 24,
        borderRadius: 12,
    },
    border: {
        borderWidth: 1,
        borderColor: colors.white,
        borderStyle: "solid",
        borderRadius: 12,
        width: 24,
        height: 24,
    },
    img: {
        width: 24,
        height: 24,
        borderRadius: 12,
    },
    initialsText: {
        fontSize: 10,
        color: colors.white,
        fontWeight: "bold",
    },
};

export const avatarSmaller = {
    body: {
        width: 16,
        height: 16,
        borderRadius: 8,
    },
    border: {
        borderWidth: 1,
        borderColor: colors.white,
        borderStyle: "solid",
        borderRadius: 8,
        width: 16,
        height: 16,
    },
    img: {
        width: 16,
        height: 16,
        borderRadius: 8,
    },
    initialsText: {
        fontSize: 8,
        color: colors.white,
        fontWeight: "bold",
    },
};

export const avatarBig = {
    body: {
        width: 80,
        height: 80,
        borderRadius: 40,
    },
    img: {
        width: 80,
        height: 80,
        borderRadius: 40,
    },
    border: {
        borderWidth: 2,
        borderColor: colors.black10,
        borderStyle: "solid",
        borderRadius: 40,
        width: 80,
        height: 80,
    },
    initialsText: {
        fontSize: 32,
        color: colors.white,
        fontWeight: "bold",
    },
};

export const avatarHuge = {
    body: {
        width: 160,
        height: 160,
        borderRadius: 80,
    },
    border: {
        borderWidth: 3,
        borderColor: colors.black10,
        borderRadius: 80,
        width: 160,
        height: 160,
    },
    img: {
        width: 160,
        height: 160,
        borderRadius: 80,
        position: "absolute",
        zIndex: 10,
    },
    initialsText: {
        fontSize: 64,
    },
};

export const avatarMedium = {
    body: {
        width: 64,
        height: 64,
        borderRadius: 32,
    },
    img: {
        width: 64,
        height: 64,
        borderRadius: 32,
    },
    border: {
        borderWidth: 2,
        borderColor: colors.black10,
        borderStyle: "solid",
        borderRadius: 32,
        width: 64,
        height: 64,
    },
    initialsText: {
        fontSize: 32,
        color: colors.white,
        fontWeight: "bold",
    },
};
