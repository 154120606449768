/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/OfficeHours/Appointments/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../../store/AutoComplete/actionCreators";
import Paper from "@material-ui/core/Paper";
import BookingHistoryForm from "./components/BookingHistoryForm";
import globalsCss from "../../../../common/styles/globals.css";

class OfficeHoursBookingHistoryReportContainer extends Component {
    componentDidMount() {
        const { setTitle, gettOfficeHoursSchoolYearsAndTerms } = this.props;
        setTitle("Bookings \u203A Reports");
        gettOfficeHoursSchoolYearsAndTerms();
    }
    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    onSubmit = values => {
        this.props.downloadOfficeHoursAppointmentsHistoryReport({
            userId: values.users.id,
            ext: "xlsx",
            from: values.from,
            to: values.to,
        });
    };

    render() {
        const {
            schoolYears,
            students,
            getUsers,
            clearUsers,
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;

        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Booking History Report</h2>
                        <BookingHistoryForm
                            {...{
                                onSubmit,
                                errors,
                                formError: error,
                                students,
                                getUsers,
                                clearUsers,
                                schoolYears,
                                initialValues: {
                                    from: null,
                                    to: null,
                                },
                            }}
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    gettOfficeHoursSchoolYearsAndTerms: actionCreators.gettOfficeHoursSchoolYearsAndTerms.create,
    downloadOfficeHoursAppointmentsHistoryReport:
        actionCreators.downloadOfficeHoursAppointmentsHistoryReport.create,
    getUsers: AutoCompleteActionCreators.getStudentUsers.create,
    clearUsers: AutoCompleteActionCreators.clearStudentUsers.create,
};

const mapStateToProps = ({ OfficeHoursAppointments, AutoComplete }) => ({
    schoolYears: OfficeHoursAppointments.schoolYears,
    errorRes: OfficeHoursAppointments.errorRes,
    students: AutoComplete.students,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursBookingHistoryReportContainer);
