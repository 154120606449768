export const TYPE_REPEATING = "yes";
export const TYPE_NON_REPEATING = "no";

export const REPEATING_DAILY = "daily";
export const REPEATING_WEEKLY = "weekly";
export const REPEATING_MONTHLY = "monthly";

export const REPEAT_IN_TERM = "term";
export const REPEAT_IN_ACADEMIC_YEAR = "year";
export const REPEAT_N_TIMES = "times";
export const REPEAT_UNTIL = "date";

export const ACADEMIC = "academic";
export const TEAM = "team";
export const NONE = "none";

export const MEETING_ZOOM = "zoom";
export const MEETING_CUSTOM = "custom";
export const MEETING_NONE = "none";

export const VALUE_YES = "yes";
export const VALUE_NO = "no";

export const GENERIC_AVAILABILITY = "generic";
export const GENERIC_AVAILABILITY_ALERT_YELLOW = 1;
export const GENERIC_AVAILABILITY_ALERT_RED = 2;

export const SESSION_BOOKABLE = "bookable";
export const SESSION_DROPIN = "drop-in";
