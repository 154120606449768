import actionCreators from "./actionCreators";
import event from "../../../fixtures/ChannelEvent";
import { removeProp } from "../../helpers";
import { prepareFilterQuery } from "../../../common/services/utils";

const initialState = {
    events: {
        data: [],
        count: 0,
        nextPage: 0,
        queryParams: "",
    },
    event,
    pageId: "",
    errorRes: { error: "", errors: {} },
    currentFormType: null,
    hasDate: false,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListEvent.type:
            return {
                ...state,
                events: { ...payload, queryParams: state.events.queryParams },
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListEvent.type:
            payload.data = [
                ...state.events.data,
                ...payload.data.filter(
                    event => !state.events.data.map(item => item.id).includes(event.id)
                ),
            ];
            return {
                ...state,
                events: { ...payload, queryParams: state.events.queryParams },
                errorRes: initialState.errorRes,
            };

        case actionCreators.getListEvent.type:
        case actionCreators.loadMoreEvents.type:
            return {
                ...state,
                events: { ...state.events, queryParams: prepareFilterQuery(payload) },
            };

        case actionCreators.updateOneEvent.type:
            return {
                ...state,
                event: payload,
                errorRes: initialState.errorRes,
                hasArticle: payload.article ? true : false,
                hasDate: payload.status === "scheduled" ? true : false,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.setCurrentFormType.type:
            return {
                ...state,
                currentFormType: payload,
            };
        case actionCreators.setDateFieldStatus.type:
            return {
                ...state,
                hasDate: payload,
            };
        case actionCreators.clearErrors.type:
            return {
                ...state,
                errorRes: state.errorRes.errors[payload]
                    ? {
                          ...state.errorRes,
                          errors: removeProp(state.errorRes.errors, payload),
                      }
                    : state.errorRes,
            };
        case actionCreators.clearQueryParams.type:
            return {
                ...state,
                events: {
                    ...state.events,
                    queryParams: initialState.events.queryParams,
                },
            };
        case actionCreators.updateArchivedEvent.type:
            return {
                ...state,
                events: {
                    ...state.events,
                    data: [
                        ...state.events.data.map(e =>
                            e.id === parseInt(payload)
                                ? {
                                      ...e,
                                      status: "archived",
                                  }
                                : e
                        ),
                    ],
                },
            };
        default:
            return state;
    }
};
