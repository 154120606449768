import actionCreators from "./actionCreators";

export const initialState = {
    overview: null,
    currentFormType: null,
    errorRes: { error: "", errors: {} },
    isLink: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateOverview.type:
            return {
                ...state,
                overview: payload,
                errorRes: initialState.errorRes,
                isLink: payload.linkType === "link" ? true : false,
            };
        case actionCreators.setCurrentFormType.type:
            return {
                ...state,
                currentFormType: payload,
                overview: initialState.overview,
            };
        case actionCreators.setLinkTypeStatus.type:
            return {
                ...state,
                isLink: payload,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
