import { colors, spacing } from "../common/UIGlobals";

export const home = {
    contentContainer: {
        maxWidth: 760,
        width: "100%",
        marginRight: "auto",
        marginLeft: "auto",
        position: "relative",
    },
    headline: {
        fontWeight: 400,
        lineHeight: 1.33,
        fontSize: 24,
    },
    paragraph: {
        paddingTop: 16,
        fontSize: 16,
        lineHeight: 1.5,
        marginBottom: 16,
        paddingBottom: 30,
    },
    boxContainer: {
        display: "flex",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
            flexDirection: "column",
        },
    },
};

export const modalDefaultStyle = {
    overlay: {
        backgroundColor: "rgba(230,230,230,0.9)",
        zIndex: 9999,
    },
    content: {
        width: "100%",
        maxWidth: 768,
        maxHeight: "100vh",
        overflow: "auto",
        background: colors.white,
        boxShadow: "2px 4px 8px 0 rgba(0,0,0,0.13)",
        border: "0 none",
        borderRadius: 0,
        paddingTop: spacing.space6,
        paddingRight: spacing.space4,
        paddingBottom: spacing.space4,
        paddingLeft: spacing.space4,
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

export const modalStyle = {
    inner: {},
    btnClose: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        padding: 0,
        margin: 0,
        backgroundColor: colors.transparent,
        border: "0 none",
        outline: "0 none",
        position: "absolute",
        top: spacing.space3,
        right: spacing.space3,
    },
};

export const formStyle = {
    body: {
        paddingTop: spacing.space3,
    },
    group: {
        marginBottom: spacing.space2,
    },
    label: {
        display: "block",
        fontSize: 16,
        fontWeight: "700",
        lineHeight: 1.18,
        marginBottom: spacing.space0,
    },
    select: {
        position: "relative",
    },
    selectIcon: {
        pointerEvents: "none",
        position: "absolute",
        top: spacing.space1comma5,
        right: spacing.space1,
        zIndex: 10,
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
    },
    row: {
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: -spacing.space2,
        marginRight: -spacing.space2,
    },
    col: {
        flexBasis: "50%",
        maxWidth: "50%",
        flex: 1,
        paddingLeft: spacing.space2,
        paddingRight: spacing.space2,
        "@media (max-width: 768px)": {
            flexBasis: "100%",
            maxWidth: "100%",
        },
    },
};
