import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";

const groupStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListGroup.type,
            actionCreators.loadMoreGroups.type,
            actionCreators.getOneGroup.type,
            actionCreators.postGroup.type,
            actionCreators.putGroup.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const groupClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListGroup.type,
            actionCreators.updateListGroup.type,
            actionCreators.updateOneGroup.type,
            actionCreators.pushListGroups.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListGroupsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListGroup.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/groups/?${action.payload}`)).pipe(
                mergeMap(({ response }) => of(actionCreators.createListGroup.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreGroupsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreGroups.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/groups/?${action.payload}`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateListGroup.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneGroup.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/groups/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOneGroup.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postGroup.type),
        switchMap(action =>
            ajax
                .post(ajax.apiUrl("staff/groups/"), action.payload)
                .pipe(
                    mapTo(actionCreators.pushListGroups.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const editGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putGroup.type),
        switchMap(action =>
            ajax.put(ajax.apiUrl(`staff/groups/${action.payload.id}/`), action.payload).pipe(
                mergeMap(({ response }) => of(actionCreators.pushListGroups.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );
const removeGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeGroup.type),
        mergeMap(action =>
            ajax.remove(ajax.apiUrl(`staff/groups/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.pushListGroups.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );
const pushListGroupsEpic = action$ =>
    action$.pipe(ofType(actionCreators.pushListGroups.type), mapTo(push(`/channels/groups`)));

export const epics = combineEpics(
    groupStartLoadingEpic,
    groupClearLoadingEpic,
    getListGroupsEpic,
    loadMoreGroupsEpic,
    getOneGroupEpic,
    newGroupEpic,
    editGroupEpic,
    pushListGroupsEpic,
    removeGroupEpic
);
