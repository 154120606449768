import { Component } from "react";
import { mainBox } from "./MainBoxStyles";
import SvgElement from "../SvgElements/SvgElements.js";
import { colors } from "../../UIGlobals";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class MainBox extends Component {
    render() {
        const { title, href, onClick, linkText, icon, disabled, target } = this.props;
        return (
            <div css={[mainBox.wrap, disabled && mainBox.wrapDisabled]}>
                <div css={mainBox.inner}>
                    {/* <div css={mainBox.topShape} /> */}
                    <h5 css={mainBox.title}>{title}</h5>
                    <a href={href} onClick={onClick} css={mainBox.link} target={target}>
                        {icon && (
                            <SvgElement
                                name={icon}
                                css={mainBox.icon}
                                width="18"
                                height="16"
                                fill={colors.secondary}
                            />
                        )}
                        <span css={mainBox.linkText}>{linkText}</span>
                    </a>
                </div>
            </div>
        );
    }
}

export { MainBox };
