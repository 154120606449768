import ActionCreator from "../actionCreator";

export default {
    getNotificationBanners: ActionCreator("GET_NOTIFICATION_BANNERS"),
    getNotificationBanner: ActionCreator("GET_NOTIFICATION_BANNER"),
    loadMoreNotificationBanners: ActionCreator("LOAD_MORE_NOTIFICATION_BANNER"),
    updateNotificationBanners: ActionCreator("UPDATE_NOTIFICATION_BANNERS"),
    updateMoreNotificationBanners: ActionCreator("UPDATE_MORE_NOTIFICATION_BANNERS"),
    setNotificationBannner: ActionCreator("UPDATE_NOTIFICATION_BANNER"),
    deleteNotificationBanner: ActionCreator("DELETE_NOTIFICATION_BANNER"),
    updateDeletedNotificationBanner: ActionCreator("UPDATE_DELETED_NOTIFICATION_BANNERS"),

    errorResponse: ActionCreator("ERROR_RESPONSE_NOTIFICATION"),
    clearNotification: ActionCreator("CLEAR_NOTIFICATION"),

    postNotificationBanner: ActionCreator("POST_NOTIFICATION_BANNER"),
    putNotificationBanner: ActionCreator("PUT_NOTIFICATION_BANNER"),
    // getNotificationEmailPreview: ActionCreator("NOTIFICATION_GET_EMAIL_PREVIEW"),
    // setNotificationEmailPreview: ActionCreator("NOTIFICATION_SET_EMAIL_PREVIEW"),
    // getLseModuleGroups: ActionCreator("NOTIFICATION_GET_LSE_GROUPS"),
    // setLseModuleGroups: ActionCreator("NOTIFICATION_SET_LSE_GROUPS"),
};
