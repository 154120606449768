export default {
    id: null,
    status: "",
    name: "",
    pageType: "",
    description: "",
    email: "",
    topic: "",
    imageFile: {
        name: "",
        size: "",
        type: "",
        base64: "",
    },
    imageUrl: null,
    iconFile: {
        name: "",
        size: "",
        type: "",
        base64: "",
    },
    iconUrl: null,
    postTypes: [],
    owners: [],
    hasActivePosts: false,
    commentEmails: [],
    cannotUnfollowAudiences: [],
    canUnfollowAudiences: [],
};
