import actionCreators from "./actionCreators";
import { Dictionary } from "../../types/generic";
import { clarity } from "react-microsoft-clarity";

export interface AuthState {
    errorRes: { error: string; errors: Dictionary<string> };
    isLogged: boolean;
    token: string;
    firstName: string;
    lastName: string;
    lseId: string;
    email: string;
    permissionLevel: string;
    id: number;
    hasBookings: boolean;
    manageBookings: boolean;
}

export const initialState = {
    errorRes: { error: "", errors: {} },
    isLogged: false,
    token: null,
    firstName: null,
    lastName: null,
    lseId: null,
    email: null,
    permissionLevel: null,
    id: null,
    hasBookings: null,
    manageBookings: null,
    initiated: false,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.login.type:
            return {
                ...state,
                isLogged: true,
                errorRes: initialState.errorRes,
                token: localStorage.getItem("token"),
            };
        case actionCreators.setAuth.type:
            return {
                ...state,
                isLogged: true,
                errorRes: initialState.errorRes,
                token: payload.token,
            };
        case actionCreators.setNotAuth.type:
            localStorage.removeItem("token");
            return {
                ...state,
                isLogged: false,
                errorRes: initialState.errorRes,
                token: null,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.updateCurrentUser.type:
            if (process.env.REACT_APP_MSFT_CLARITY_ID) {
                clarity.identify(payload.id, {
                    firstName: payload.firstName,
                    lastName: payload.lastName,
                    email: payload.email,
                    lseId: payload.lseId,
                    permissionLevel: payload.permissionLevel,
                });
            }
            return {
                ...state,
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                lseId: payload.lseId,
                permissionLevel: payload.permissionLevel,
                errorRes: initialState.errorRes,
                id: payload.id,
                hasBookings: payload.hasBookings,
                manageBookings: payload.manageBookings,
            };
        case actionCreators.setInitiated.type:
            return {
                ...state,
                initiated: true,
            };

        default:
            return state;
    }
};
