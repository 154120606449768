import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/HowTo/Category/Articles/actionCreators";
import { default as categoryActionCreators } from "../../../../store/HowTo/Category/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { TableComponent, RedirectButton } from "../../../../common/components/HowTo";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { prepareFilterQuery } from "../../../../common/services/utils";
import filterListWithId from "../../../../common/services/FilterListWithId";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import tableCss from "../../../../common/styles/table.css";

import PromptDialog from "../../../../common/components/PromptDialog";

import { decorateTableItemsJobTypes } from "../../../../common/services/decorateTableItems";

import FilterRow from "../../../../common/components/FilterRow";
import {
    statusCallback,
    postContentCallback,
    howtoArticleTypeCallback,
} from "../../../../common/services/tableCallbacks";
import { MainTitle } from "../../../../common/components/MainTitle";
import formsCss from "../../../../common/styles/forms.css";
import {
    PreviewIcon,
    EditIcon,
    DeleteIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    AutoRenewIcon,
} from "../../../../common/components/icons";

const callbacks = {
    status: statusCallback,
    cleanedDescription: postContentCallback,
    type: howtoArticleTypeCallback,
};

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            publicationDate: "Publication Date",
            cleanedDescription: "Description",
        },
    },
];

const buttons = items => index => {
    const buttons = [
        {
            path: `articles/preview/${items[index] ? items[index].id : ""}`,
            label: "Preview",
            fnLabel: "push",
            icon: <PreviewIcon css={tableCss.actionsMenuItemIcon} />,
        },
        {
            path: `articles/edit/${items[index] ? items[index].id : ""}`,
            label: "Edit",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
        },
        {
            path: `${items[index] ? items[index].id : null}`,
            label: "Delete",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        },
        {
            path: `${items[index] ? items[index].id : null}`,
            label: "Up",
            fnLabel: "moveup",
            icon: <ArrowUpIcon css={tableCss.actionsMenuItemIcon} />,
        },
        {
            path: `${items[index] ? items[index].id : null}`,
            label: "Down",
            fnLabel: "movedn",
            icon: <ArrowDownIcon css={tableCss.actionsMenuItemIcon} />,
        },
    ];

    return buttons;
};

class HowToArticleListContainer extends Component {
    state = {
        filter: "",
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true)
            this.props.removeArticle({
                id: this.state.removeId,
                categoryId: this.props.categoryId,
            });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    remove = itemID => {
        this.handleOpen(itemID);
    };

    componentDidMount() {
        const {
            setTitle,
            getListArticle,
            getOneCategory,
            match: {
                params: { categoryId },
            },
            setCategoryId,
        } = this.props;
        const { filter$ } = this.state;
        setCategoryId(categoryId);
        setTitle("Knowledge Base \u203A Articles");
        getOneCategory({ id: categoryId });
        getListArticle({ categoryId });

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                filterListWithId({
                    getFn: getListArticle,
                    filter: term,
                    id: { name: "categoryId", value: categoryId },
                })
            );
        });
    }

    onFilterChangeHandler = ({ target: { value } }) => {
        const { filter$ } = this.state;
        this.setState({ filter: value }, () => filter$.next({ filter: this.state.filter }));
    };

    onLoadMoreClickHandler = page => () => {
        const { categoryId, loadMoreArticles } = this.props;
        const { filter } = this.state;
        loadMoreArticles({
            categoryId,
            param: prepareFilterQuery({ filter, page }),
        });
    };

    moveDown = itemID => {
        const { moveDownArticle, categoryId } = this.props;
        moveDownArticle({
            id: itemID,
            categoryId,
        });
    };

    moveUp = itemID => {
        const { moveUpArticle, categoryId } = this.props;
        moveUpArticle({
            id: itemID,
            categoryId,
        });
    };

    render() {
        const {
            articles,
            history: { push },
            nextPage,
            count,
            categoryId,
            category,
        } = this.props;
        const { filter } = this.state;
        const { remove, moveUp, moveDown } = this;

        return (
            <div>
                <MainTitle title={category.name} type="[Category]" />

                <div css={globalsCss.actions}>
                    <RedirectButton
                        label="Add new article"
                        url={`/knowledge-base/categories/${categoryId}/articles/new`}
                        {...{ push }}
                    />
                </div>
                <Paper elevation={1}>
                    <FilterRow filter={filter} onFilterChangeHandler={this.onFilterChangeHandler} />
                    <TableComponent
                        items={articles}
                        fns={{ push, remove, moveup: moveUp, movedn: moveDown }}
                        buttons={buttons(articles)}
                        decorators={decorators}
                        callbacks={callbacks}
                    />

                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this article?
                    </PromptDialog>
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {articles.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListArticle: actionCreators.getListArticle.create,
    loadMoreArticles: actionCreators.loadMoreArticles.create,
    setCategoryId: actionCreators.setCategoryId.create,
    removeArticle: actionCreators.removeArticle.create,
    getOneCategory: categoryActionCreators.getOneCategory.create,
    moveUpArticle: actionCreators.moveUpArticle.create,
    moveDownArticle: actionCreators.moveDownArticle.create,
};

const mapStateToProps = ({
    HowToArticle: {
        articles: { data, count, nextPage },
        categoryId,
    },
    HowToCategory: { category },
}) => ({
    articles: data,
    count,
    nextPage,
    category,
    categoryId,
});

export default connect(mapStateToProps, dispatchToProps)(HowToArticleListContainer);
