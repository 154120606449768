import actionCreators from "./actionCreators";

export const initialState = {
    pages: {
        data: [],
        count: 0,
        nextPage: 1,
    },
    groups: {
        data: [],
        count: 0,
        nextPage: 1,
    },
    appointments: {
        data: [],
        count: 0,
        nextPage: 1,
    },
    errorRes: { error: "", errors: {} },
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createPages.type:
            return {
                ...state,
                pages: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updatePages.type:
            payload.data = [
                ...state.pages.data,
                ...payload.data.filter(
                    page => !state.pages.data.map(item => item.id).includes(page.id)
                ),
            ];
            return {
                ...state,
                pages: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearPages.type:
            return {
                ...state,
                pages: initialState.pages,
            };
        case actionCreators.createGroups.type:
            return {
                ...state,
                groups: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateGroups.type:
            payload.data = [
                ...state.groups.data,
                ...payload.data.filter(
                    group => !state.groups.data.map(item => item.id).includes(group.id)
                ),
            ];
            return {
                ...state,
                groups: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearGroups.type:
            return {
                ...state,
                groups: initialState.groups,
            };

        case actionCreators.createAppointments.type:
            return {
                ...state,
                appointments: payload,
                errorRes: initialState.errorRes,
            };

        case actionCreators.updateAppointments.type:
            payload.data = [
                ...state.appointments.data,
                ...payload.data.filter(
                    appointment =>
                        !state.appointments.data.map(item => item.id).includes(appointment.id)
                ),
            ];
            return {
                ...state,
                appointments: payload,
                errorRes: initialState.errorRes,
            };

        case actionCreators.clearAppointments.type:
            return {
                ...state,
                appointments: initialState.appointments,
            };

        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
