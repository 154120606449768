import { colors } from "./colors";
import { spacing } from "./spacing";
import { fontSize, fontWeight } from "./typography";

export default {
    // body: {
    //     padding: spacing.space2,
    // },
    body: {
        backgroundColor: colors.white,
        display: "flex",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    bodyLvl: {
        marginLeft: 64,
        paddingTop: spacing.space1,
        paddingLeft: 0,
        paddingBottom: spacing.space1,
    },
    avatar: {
        marginRight: spacing.space2,
    },
    avatarLvl: {
        marginRight: spacing.space1,
    },
    inner: {
        flex: 1,
    },
    user: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.bold,
        marginBottom: spacing.space0,
    },
    text: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: 1.42,
    },
    textItalic: {
        fontStyle: "italic",
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: spacing.space0,
    },
    date: {
        color: colors.black50,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
    },
    actions: {
        display: "flex",
    },
    actionsInline: {
        display: "flex",
    },
    btnItem: {
        marginLeft: spacing.space2,
    },
    btn: {
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
        color: colors.black50,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        textDecoration: "none",
    },
    loadMore: {
        padding: spacing.space2,
    },
};
