import actionCreators from "./actionCreators";
import category from "../../../fixtures/HowToCategory";

export const initialState = {
    categories: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    category,
    errorRes: { error: "", errors: {} },
    currentFormType: null,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListCategory.type:
            return {
                ...state,
                categories: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListCategory.type:
            payload.data = [
                ...state.categories.data,
                ...payload.data.filter(
                    category => !state.categories.data.map(item => item.id).includes(category.id)
                ),
            ];
            return {
                ...state,
                categories: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneCategory.type:
            return {
                ...state,
                category: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.setCurrentFormType.type:
            return {
                ...state,
                currentFormType: payload,
            };
        default:
            return state;
    }
};
