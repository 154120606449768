import { Component } from "react";
import { connect } from "react-redux";
import Form from "./Form/";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import actionCreators from "../../../store/HowTo/Category/actionCreators";
import currentFormTypes from "../../../common/components/CurrentFormTypes";
import HowToCategoryValues from "../../../fixtures/HowToCategory";
import formsCss from "../../../common/styles/forms.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class NewHowToCategoryContainer extends Component {
    componentDidMount() {
        const { setTitle, match, setCurrentFormType } = this.props;
        const type = match.params.id ? currentFormTypes.EDIT : currentFormTypes.NEW;
        setCurrentFormType(type);
        setTitle("Knowledge Base Categories");
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    onSubmit = values => {
        this.props.postCategory(values);
    };

    render() {
        const {
            currentFormType,
            initialValues,
            history: { push },
            updateForm,
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;

        return (
            <div>
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    {...{
                        initialValues,
                        onSubmit,
                        currentFormType,
                        push,
                        updateForm,
                        errors,
                    }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneCategory: actionCreators.getOneCategory.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
    updateForm: actionCreators.updateForm.create,
    postCategory: actionCreators.postCategory.create,
};

const mapStateToProps = ({ HowToCategory }) => ({
    initialValues: HowToCategoryValues,
    errorRes: HowToCategory.errorRes,
    currentFormType: HowToCategory.currentFormType,
});

export default connect(mapStateToProps, dispatchToProps)(NewHowToCategoryContainer);
