import actionCreators from "./actionCreators";
import { removeProp } from "../../helpers";
import { prepareFilterQuery } from "../../../common/services/utils";

export const initialState = {
    list: {
        data: [],
        count: null,
        nextPage: null,
        queryParams: "",
    },
    audience: {
        id: null,
        name: "",
        audienceType: "draft",
        numStudents: 0,
        unmatched: 0,
        sourceFileName: null,
    },
    customAudience: {
        id: null,
        lseDepartmentId: null,
        lseProgrammes: [],
        graduation: null,
        yearGroup: null,
    },
    customAudienceLiveParams: null,
    customAudienceLiveParamsStudents: {
        data: [],
        count: null,
        nextPage: 0,
    },
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListAudience.type:
            return {
                ...state,
                list: { ...payload, queryParams: state.list.queryParams },
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListAudience.type: {
            const ids = state.list.data.map(audience => audience.id);
            payload.data = [
                ...state.list.data,
                ...payload.data.filter(audience => ids.indexOf(audience.id) === -1),
            ];
            return {
                ...state,
                list: { ...payload, queryParams: state.list.queryParams },
                errorRes: initialState.errorRes,
            };
        }
        case actionCreators.getListAudience.type:
        case actionCreators.loadMoreAudience.type:
            return {
                ...state,
                list: { ...state.list, queryParams: prepareFilterQuery(payload) },
            };
        case actionCreators.updateOneAudience.type:
            return {
                ...state,
                audience: payload,
            };
        case actionCreators.clearOneAudience.type:
            return {
                ...state,
                audience: initialState.audience,
            };
        case actionCreators.getCustomAudienceLiveParams.type:
            return {
                ...state,
                customAudienceLiveParams: {},
            };
        case actionCreators.updateCustomAudienceLiveParams.type:
            return {
                ...state,
                customAudienceLiveParams: payload,
            };

        case actionCreators.clearCustomAudienceLiveParams.type:
            return {
                ...state,
                customAudienceLiveParams: initialState.customAudienceLiveParams,
            };
        case actionCreators.updateCustomAudienceLiveParamsStudents.type:
            payload.data = [
                ...state.customAudienceLiveParamsStudents.data,
                ...payload.data.filter(
                    row =>
                        !state.customAudienceLiveParamsStudents.data
                            .map(item => item.playRef)
                            .includes(row.playRef)
                ),
            ];
            return {
                ...state,
                customAudienceLiveParamsStudents: payload,
            };
        case actionCreators.clearCustomAudienceLiveParamsStudents.type:
            return {
                ...state,
                customAudienceLiveParamsStudents: initialState.customAudienceLiveParamsStudents,
            };
        case actionCreators.updateOneCustomAudience.type:
            return {
                ...state,
                customAudience: payload,
            };
        case actionCreators.clearOneCustomAudience.type:
            return {
                ...state,
                customAudience: initialState.customAudience,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.clearErrors.type:
            return {
                ...state,
                errorRes: state.errorRes.errors[payload]
                    ? {
                          ...state.errorRes,
                          errors: removeProp(state.errorRes.errors, payload),
                      }
                    : state.errorRes,
            };
        case actionCreators.clearQueryParams.type:
            return {
                ...state,
                list: {
                    ...state.list,
                    queryParams: initialState.list.queryParams,
                },
            };

        default:
            return state;
    }
};
