/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    Typography,
    InputBase,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import toolbarCss from "../styles/toolbar.css";
import { useState } from "react";

const ChannelFilterRow = withStyles(toolbarCss)(
    ({ onFilterChangeHandler, onRecommendedChangeHandler, filter, recommended, classes }) => {
        return (
            <AppBar
                position="static"
                classes={{
                    root: classes.appBarRoot,
                }}
                elevation={0}
            >
                <Toolbar
                    classes={{
                        root: classes.toolbarRoot,
                    }}
                >
                    <div css={toolbarCss.toolbarGroup}>
                        <Typography>
                            <span css={toolbarCss.toolbarTitle}>
                                <span css={toolbarCss.labelShowOnDesktop}>
                                    Filter by keyword or ID:
                                </span>
                                <span css={toolbarCss.labelShowOnMobile}>Filter:</span>
                            </span>
                        </Typography>
                        <InputBase
                            placeholder="..."
                            value={filter || ""}
                            onChange={onFilterChangeHandler}
                            fullWidth
                            autoComplete="off"
                        />
                    </div>
                    <div css={toolbarCss.toolbarSeparator} />
                    <div css={toolbarCss.toolbarGroup}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    classes={{
                                        root: classes.checkbox,
                                        checked: classes.checkboxChecked,
                                    }}
                                    checked={recommended || false}
                                />
                            }
                            classes={{
                                label: classes.checkboxLabel,
                            }}
                            label="Is Recommended?"
                            onChange={() => {
                                onRecommendedChangeHandler(!recommended);
                            }}
                        />
                        {/* 
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Is Recommended?</span>
                            <span css={toolbarCss.labelShowOnMobile}>Is Recommended?</span>
                        </span>
                    </Typography>
                    */}
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
);

export default ChannelFilterRow;
