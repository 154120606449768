import { of, empty } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../../MainMenu/actionCreators";
import errorHandler from "../../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../../common/services/utils";
import { push } from "connected-react-router";

const membersStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListFollowers.type,
            actionCreators.loadMoreFollowers.type,
            actionCreators.removePageFollower.type,
            actionCreators.postPageFollower.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const membersClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListFollowers.type,
            actionCreators.updateListFollowers.type,
            actionCreators.errorResponse.type,
            actionCreators.redirectToFollowersList.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListFollowersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListFollowers.type),
        switchMap(({ payload: { pageId, param } }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/pages/${pageId}/members/?type=follower&${param ? param : ""}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createListFollowers.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreFollowersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreFollowers.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/pages/${action.payload.pageId}/members/?type=follower&${action.payload.param}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListFollowers.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const postPageFollowerEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postPageFollower.type),
        switchMap(({ payload: { userId, pageId, isLast } }) =>
            ajax.post(ajax.apiUrl(`staff/pages/${pageId}/member/${userId}/`)).pipe(
                mergeMap(() =>
                    isLast
                        ? of(
                              actionCreators.redirectToFollowersList.create({
                                  id: pageId,
                              })
                          )
                        : empty()
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const removePageFollowerEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removePageFollower.type),
        switchMap(({ payload: { id, pageId } }) =>
            ajax
                .remove(ajax.apiUrl(`staff/pages/${pageId}/remove-user/${id}/`))
                .pipe(
                    mapTo(actionCreators.getListFollowers.create({ pageId })),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const redirectToFollowersListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.redirectToFollowersList.type),
        mergeMap(({ payload: { id } }) => of(push(`/channels/pages/${id}/followers`)))
    );

export const epics = combineEpics(
    membersStartLoadingEpic,
    membersClearLoadingEpic,
    getListFollowersEpic,
    loadMoreFollowersEpic,
    removePageFollowerEpic,
    postPageFollowerEpic,
    redirectToFollowersListEpic
);
