import React from "react";
import { Route, Switch } from "react-router-dom";
import { isManager } from "../../../common/services/Auth";
import { ROUTE_SIDELINKS } from ".";
import { SideLinksContainer } from "../containers/SideLinksContainer";

const SideLinksRoutes = () => (
    <Switch>
        <Route exact path={ROUTE_SIDELINKS} component={isManager(SideLinksContainer)} />
    </Switch>
);

export default SideLinksRoutes;
