import ActionCreator from "../actionCreator";

export default {
    sendFeedback: ActionCreator("POST_FEEDBACK"),
    updateFeedback: ActionCreator("UPDATE_FEEDBACK"),
    clearFeedback: ActionCreator("CLEAR_FEEDBACK"),
    getSubjects: ActionCreator("FEEDBACK_SUBJECT"),
    updateSubjects: ActionCreator("UPDATE_FEEDBACK_SUBJECT"),
    errorResponse: ActionCreator("ERROR_RESPONSE_FEEDBACK"),
};
