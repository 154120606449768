// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import validate from "../validate";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";
import { switchCase } from "../../../../common/services/utils";
import {
    RenderTextField,
    RenderRadioGroup,
    RenderRadio,
    RenderCheckboxField,
} from "../../../../common/components/FormFields";

import { Button, Paper } from "@material-ui/core";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import formsCss from "../../../../common/styles/forms.css";
import { USER_TYPE_STANDARD, USER_TYPE_MANAGER, USER_TYPE_ADMIN } from "../values";

import RenderLseId from "../RenderLSEId";

const LseIdItem = ({ currentFormType, lseId, status, errors }) =>
    switchCase({
        // eslint-disable-next-line react/display-name
        [currentFormTypes.EDIT]: () => (
            <div className="col-xs-12" css={[formsCss.firstInputField, globalsCss.helpers.mb15]}>
                <Field
                    name="lseId"
                    type="lseId"
                    component={RenderTextField}
                    errorRes={errors.lseId}
                    label="LSE SSO ID"
                    fullWidth
                />
            </div>
        ),
        // eslint-disable-next-line react/display-name
        [currentFormTypes.NEW]: () => <RenderLseId {...{ errors }} />,
    })(() => <RenderLseId {...{ errors }} />)(currentFormType)();

const StatusItem = ({ currentFormType, status }) =>
    switchCase({
        // eslint-disable-next-line react/display-name
        [currentFormTypes.EDIT]: () => {
            return (
                <div className="col-xs-12" css={formsCss.blockedRadioButtons}>
                    <Field name="status" component={RenderRadioGroup} row>
                        <RenderRadio
                            disabled={status === "pending" || status === "inactive"}
                            value="active"
                            label="Active"
                            className="col-lg-2 col-sm-3 col-xs-12"
                        />
                        <RenderRadio
                            disabled={status === "pending" || status === "inactive"}
                            value="blocked"
                            label="Blocked"
                            className="col-lg-2 col-sm-3 col-xs-12"
                        />
                        <RenderRadio
                            disabled={true}
                            value="pending"
                            label="Pending"
                            className="col-lg-2 col-sm-3 col-xs-12"
                        />
                        <RenderRadio
                            disabled={true}
                            value="inactive"
                            label="Inactive"
                            className="col-lg-2 col-sm-3 col-xs-12"
                        />
                    </Field>
                    <hr />
                </div>
            );
        },
        [currentFormTypes.NEW]: () => null,
    })(() => null)(currentFormType)();

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues); // here add this line to initialize the form
    }

    render() {
        const {
            error,
            submitting,
            handleSubmit,
            currentFormType,
            initialValues,
            push,
            errors,
        } = this.props;

        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>
                        {currentFormType === "NEW" ? "Add new LSE Staff" : "Edit LSE Staff"}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <LseIdItem
                                lseId={initialValues.lseId}
                                status={initialValues.status}
                                {...{ currentFormType, errors }}
                            />
                            <StatusItem
                                status={initialValues.status}
                                {...{ currentFormType, initialValues }}
                            />
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="firstName"
                                    errorRes={errors.firstName}
                                    type="firstName"
                                    component={RenderTextField}
                                    label="First Name"
                                    fullWidth
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="lastName"
                                    errorRes={errors.lastName}
                                    type="lastName"
                                    component={RenderTextField}
                                    label="Last Name"
                                    fullWidth
                                />
                                <Field
                                    name="lastNameLocked"
                                    errorRes={errors.lastNameLocked}
                                    label="Locked"
                                    component={RenderCheckboxField}
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="email"
                                    errorRes={errors.email}
                                    type="email"
                                    component={RenderTextField}
                                    label="Email"
                                    fullWidth
                                    readOnly={initialValues.status === "pending" ? true : false}
                                />
                            </div>
                            {initialValues.playRef && (
                                <div className="col-sm-4 col-xs-12">
                                    <div css={formsCss.item}>
                                        <div css={formsCss.itemLabel}>Playref</div>
                                        <div css={formsCss.itemValue}>{initialValues.playRef}</div>
                                    </div>
                                </div>
                            )}
                            {initialValues.lseStaff && initialValues.lseStaff.jobTitle && (
                                <div className="col-sm-4 col-xs-12">
                                    <div css={formsCss.item}>
                                        <div css={formsCss.itemLabel}>Job Title</div>
                                        <div css={formsCss.itemValue}>
                                            {initialValues.lseStaff.jobTitle}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {initialValues.lseStaff && initialValues.lseStaff.departmentCode && (
                                <div className="col-sm-4 col-xs-12">
                                    <div css={formsCss.item}>
                                        <div css={formsCss.itemLabel}>Department</div>
                                        <div css={formsCss.itemValue}>
                                            {initialValues.lseStaff.departmentName
                                                ? `${initialValues.lseStaff.departmentName} (${initialValues.lseStaff.departmentCode})`
                                                : `${initialValues.lseStaff.departmentCode}`}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <hr />
                        <label
                            css={[formsCss.label, globalsCss.helpers.mt20]}
                            htmlFor="permissionLevel"
                        >
                            Permission Level
                        </label>
                        <div>
                            <Field
                                name="permissionLevel"
                                errorRes={errors.permissionLevel}
                                component={RenderRadioGroup}
                                row
                            >
                                <RenderRadio
                                    value={USER_TYPE_STANDARD}
                                    label="Standard"
                                    className="col-lg-2 col-sm-3 col-xs-12"
                                />
                                <RenderRadio
                                    value={USER_TYPE_MANAGER}
                                    label="Manager"
                                    className="col-lg-2 col-sm-3 col-xs-12"
                                />
                                <RenderRadio
                                    value={USER_TYPE_ADMIN}
                                    label="Admin"
                                    className="col-lg-2 col-sm-3 col-xs-12"
                                />
                            </Field>
                        </div>

                        <hr />
                        <label
                            css={[formsCss.label, globalsCss.helpers.mt20]}
                            htmlFor="permissionLevel"
                        >
                            Can hold office hours?
                        </label>
                        <div>
                            <Field
                                name="hasBookings"
                                errorRes={errors.hasBookings}
                                component={RenderRadioGroup}
                                row
                            >
                                <RenderRadio
                                    value={"Y"}
                                    label="Yes"
                                    className="col-lg-2 col-sm-3 col-xs-12"
                                />
                                <RenderRadio
                                    value={"N"}
                                    label="No"
                                    className="col-lg-2 col-sm-3 col-xs-12"
                                />
                            </Field>
                        </div>

                        {error && <strong>{error}</strong>}
                        <div css={formsCss.actions}>
                            <Button
                                disabled={submitting}
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Submit
                            </Button>
                            <Button
                                disabled={submitting}
                                onClick={() => push("/users-and-audiences/lse-staff")}
                                css={formsCss.btnBack}
                            >
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}

const form = reduxForm({
    form: "Staff",
    validate,
    enableReinitialize: true,
})(Form);

export default form;
