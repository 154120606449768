import ActionCreator from "../../actionCreator";

export default {
    getOfficeReportDepartmentList: ActionCreator("OFFICE_REPORT_GET_DEPARTMENT_LIST"),
    setOfficeReportDepartmentList: ActionCreator("OFFICE_REPORT_SET_DEPARTMENT_LIST"),
    getOfficeReportProgrammeList: ActionCreator("OFFICE_REPORT_GET_PROGRAMME_LIST"),
    setOfficeReportProgrammeList: ActionCreator("OFFICE_REPORT_SET_PROGRAMME_LIST"),
    getOfficeReportYearGroupList: ActionCreator("OFFICE_REPORT_GET_YEAR_GROUP_LIST"),
    setOfficeReportYearGroupList: ActionCreator("OFFICE_REPORT_SET_YEAR_GROUP_LIST"),
    createOfficeStudentByProgrammeReport: ActionCreator(
        "OFFICE_REPORT_CREATE_STUDENT_BY_PROGRAMME"
    ),
    createOfficeTeamReport: ActionCreator("OFFICE_REPORT_CREATE_TEAM"),
    createAcademicAvailabilityReport: ActionCreator("OFFICE_REPORT_CREATE_ACADEMIC_AVAILABILITY"),
    createGlobalStudentBookingHistoryReport: ActionCreator(
        "OFFICE_REPORT_CREATE_GLOBAL_STUDENT_BOOKING_HISTORY"
    ),
    createGlobalAcademicAvailabilityReport: ActionCreator(
        "OFFICE_REPORT_CREATE_GLOBAL_ACADEMIC_AVAILABILITY"
    ),
    createStudentBookingHistoryWithMeReport: ActionCreator(
        "OFFICE_REPORT_CREATE_STUDENT_BOOKING_HISTORY_WITH_ME"
    ),
    createTeamAvailabilityReport: ActionCreator("OFFICE_REPORT_CREATE_TEAM_AVAILABILITY"),
    errorResponse: ActionCreator("OFFICE_HOURS_REPORTS_ERROR"),
    clearErrorResponse: ActionCreator("OFFICE_HOURS_REPORTS_CLEAR_ERROR"),
};
