import actionCreators from "./actionCreators";

export const initialState = {
    sessions: {
        data: [],
        count: 0,
        nextPage: 0,
        queryParams: "",
    },
    session: null,
    sessionSlots: [],
    isBookingDialogOpen: false,
    isCancelBookingDialogOpen: false,
    isBlockDialogOpen: false,
    isUnblockDialogOpen: false,
    errorRes: { error: "", errors: {} },
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.createOfficeHoursSessions.type:
            return {
                ...state,
                sessions: { ...payload, queryParams: state.sessions.queryParams },
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOfficeHoursSessions.type:
            payload.data = [
                ...state.sessions.data,
                ...payload.data.filter(
                    session => !state.sessions.data.map(item => item.id).includes(session.id)
                ),
            ];
            return {
                ...state,
                sessions: { ...payload, queryParams: state.sessions.queryParams },
                errorRes: initialState.errorRes,
            };
        case actionCreators.getOfficeHoursSessions.type:
        case actionCreators.getOfficeHoursTeamSessions.type:
        case actionCreators.loadMoreOfficeHoursSessions.type:
        case actionCreators.loadMoreOfficeHoursTeamSessions.type:
            return {
                ...state,
                sessions: { ...state.sessions, queryParams: payload.query },
            };
        case actionCreators.clearOfficeHoursSessions.type:
            return {
                ...state,
                sessions: initialState.sessions,
            };
        case actionCreators.createOfficeHoursSessionDetails.type:
            return {
                ...state,
                session: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOfficeHoursSessionDetails.type:
            return {
                ...state,
                session: initialState.session,
            };

        case actionCreators.createOfficeHoursSessionSlots.type:
            return {
                ...state,
                sessionSlots: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOfficeHoursSessionSlots.type:
            return {
                ...state,
                sessionSlots: initialState.sessionSlots,
            };
        case actionCreators.updateAlteredOfficeHoursSlot.type:
            return {
                ...state,
                isBookingDialogOpen: false,
                isCancelBookingDialogOpen: false,
                isBlockDialogOpen: false,
                isUnblockDialogOpen: false,
                sessionSlots: state.sessionSlots
                    ? state.sessionSlots.map(slot =>
                          slot.id === Number(payload.slotId) ? payload.slot : slot
                      )
                    : initialState.sessionSlots,
            };
        case actionCreators.toggleOfficeHoursBookingDialog.type:
            return {
                ...state,
                isBookingDialogOpen: !state.isBookingDialogOpen,
            };
        case actionCreators.toggleOfficeHoursBlockDialog.type:
            return {
                ...state,
                isBlockDialogOpen: !state.isBlockDialogOpen,
            };
        case actionCreators.toggleOfficeHoursCancelBookingDialog.type:
            return {
                ...state,
                isCancelBookingDialogOpen: !state.isCancelBookingDialogOpen,
            };
        case actionCreators.toggleOfficeHoursUnblockDialog.type:
            return {
                ...state,
                isUnblockDialogOpen: !state.isUnblockDialogOpen,
            };

        case actionCreators.updateAssignedOfficeHoursGenericAvailabilitySession.type:
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    data: state.sessions.data
                        ? state.sessions.data.map(i => (i.id === payload.id ? payload : i))
                        : [],
                },
                session: payload,
            };

        case actionCreators.updateOfficeHoursSessionSlotAttendance.type:
            return {
                ...state,
                sessionSlots: state.sessionSlots
                    ? state.sessionSlots.map(i =>
                          i.id === payload.id
                              ? {
                                    ...i,
                                    comment: payload.comment,
                                    studentAttended: payload.studentAttended,
                                }
                              : i
                      )
                    : [],
            };
        default:
            return state;
    }
};
