import ActionCreator from "../actionCreator";

export default {
    getPages: ActionCreator("DASHBOARD_GET_PAGES"),
    createPages: ActionCreator("DASHBOARD_CREATE_PAGES"),
    loadMorePages: ActionCreator("DASHBOARD_LOAD_MORE_PAGES"),
    updatePages: ActionCreator("DASHBOARD_UPDATE_PAGES"),
    getGroups: ActionCreator("DASHBOARD_GET_GROUPS"),
    createGroups: ActionCreator("DASHBOARD_CREATE_GROUPS"),
    loadMoreGroups: ActionCreator("DASHBOARD_LOAD_MORE_GROUPS"),
    updateGroups: ActionCreator("DASHBOARD_UPDATE_GROUPS"),
    clearPages: ActionCreator("DASHBOARD_CLEAR_PAGES"),
    clearGroups: ActionCreator("DASHBOARD_CLEAR_GROUPS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_DASHBOARD"),

    getAppointments: ActionCreator("DASHBOARD_GET_APPOINTMENTS"),
    createAppointments: ActionCreator("DASHBOARD_CREATE_APPOINTMENTS"),
    loadMoreAppointments: ActionCreator("DASHBOARD_LOAD_MORE_APPOINTMENTS"),
    updateAppointments: ActionCreator("DASHBOARD_UPDATE_APPOINTMENTS"),
    clearAppointments: ActionCreator("DASHBOARD_CLEAR_APPOINTMENTS"),
};
