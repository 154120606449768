import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { store } from "../../store";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return this.props.children;
    }
}

export const initBugsnag = () => {
    if (process.env.REACT_APP_BUGSNAG_KEY && process.env.REACT_APP_BUGSNAG_KEY.length === 32) {
        Bugsnag.start({
            apiKey: process.env.REACT_APP_BUGSNAG_KEY || "",
            plugins: [new BugsnagPluginReact()],
            appVersion: `${process.env.REACT_APP_VERSION}` || "",
            releaseStage: process.env.REACT_APP_RELEASE_STAGE || "",
            onError: event => {
                try {
                    const { Auth } = store.getState();
                    event.addMetadata("user", {
                        id: Auth.id,
                        level: Auth.permissionLevel,
                    });
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            },
        });

        return Bugsnag.getPlugin("react")?.createErrorBoundary(React);
    }

    return ErrorBoundary;
};

const bugsnagClient = () => {
    return process.env.REACT_APP_BUGSNAG_KEY && process.env.REACT_APP_BUGSNAG_KEY.length < 32
        ? {
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              notify: e => {
                  console.log("[Bugsnag] ", e);
              },
              app: "",
              environment: "",
          }
        : Bugsnag;
};

export default bugsnagClient();
