import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Appointments/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { TableComponent } from "../../../common/components/OfficeHours";
import { Dialog, DialogContent, DialogActions, Paper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { prepareFilterQuery } from "../../../common/services/utils";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import tableCss from "../../../common/styles/table.css";
import { MainTitle } from "../../../common/components/MainTitle";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import formsCss from "../../../common/styles/forms.css";
import RowDialogField from "../../../common/components/RowDialogField";
import {
    HistoryIcon,
    AutoRenewIcon,
    PostIcon,
    EventAvailableIcon,
    DeleteIcon,
} from "../../../common/components/icons";

import globalsCss from "../../../common/styles/globals.css";
import MessageForm from "../ManageOfficeHours/MessageForm";
import TabsComponent from "../../../common/components/Tabs";
import { GetFormattedDate, GetFormattedTime } from "../../../common/components/Time";
import DateFilterRow from "../../../common/components/DateFilterRow";
import moment from "moment-timezone";
import CommentForm from "./CommentForm";
import { withQueryParams } from "../../../common/components/withQueryParams";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            date: "Date & time",
            session: "Session",
            location: "Location",
            booking: "Student",
            message: "Note / File",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            message: tableCss.img,
        },
    },
];

const buttons = items => index => {
    return [
        {
            path: `/bookings/appointments/${items[index] ? items[index].id : ""}`,
            label: "Details",
            fnLabel: "push",
            icon: <HistoryIcon css={tableCss.actionsMenuItemIcon} />,
        },
        {
            path: `${items[index] ? items[index].id : null}`,
            label: "Mark attendance",
            fnLabel: "handleAttendanceOpen",
            icon: <EventAvailableIcon css={tableCss.actionsMenuItemIcon} />,
        },
        {
            path: `${items[index] ? items[index].id : null}`,
            label: "Cancel booking",
            fnLabel: "handleCancelBookOpen",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        },
    ];
};

const callbacks = {
    // eslint-disable-next-line react/display-name
    message: item =>
        item.message || item.attachment ? (
            <RowDialogField
                iconImage={<PostIcon />}
                dialogContent={
                    <div>
                        {item.message && (
                            <div>
                                <span css={formsCss.labelNoMargin}>Note:</span> {item.message}
                            </div>
                        )}
                        {item.attachment && (
                            <div css={item.message && globalsCss.helpers.mt15}>
                                <span css={formsCss.labelNoMargin}>Attachment:</span>
                                <div css={globalsCss.helpers.mt5}>
                                    <Button
                                        href={item.attachment}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color="secondary"
                                        variant="contained"
                                        size="small"
                                    >
                                        Download
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                }
                maxWidth="xs"
                fullWidth
            />
        ) : (
            ""
        ),
};

const columns = ["date", "location", "session", "booking", "message"];

const BookingType = {
    CURRENT: 0,
    PAST: 1,
};

class OfficeHoursAppointmentsListContainer extends Component {
    state = {
        cancelBookingId: false,
        isFromError: null,
        isToError: null,
        attendanceBookingId: false,
        commentFormVisible: false,
    };

    componentDidMount() {
        const {
            user,
            setTitle,
            getListOfficeHoursAppointments,
            getListPastOfficeHoursAppointments,
            queryParams,
        } = this.props;
        setTitle("Bookings \u203A View Bookings");

        const params = this.props.initWithQueryParams({
            type: BookingType.CURRENT,
            from: "",
            to: "",
            page: 1,
        });

        const { type } = params;

        if (queryParams !== prepareFilterQuery(params)) {
            if (type === BookingType.CURRENT) {
                getListOfficeHoursAppointments({
                    userId: user.id,
                    query: prepareFilterQuery({ ...params, page: 1 }),
                });
            } else if (type === BookingType.PAST) {
                getListPastOfficeHoursAppointments({
                    userId: user.id,
                    query: prepareFilterQuery({ ...params, page: 1 }),
                });
            }
        }
    }

    onTabChange = type => {
        const {
            user,
            getListOfficeHoursAppointments,
            getListPastOfficeHoursAppointments,
        } = this.props;

        const params = this.props.setQueryParams({ type, from: "", to: "", page: 1 });
        this.setState({ isFromError: null, isToError: null });
        if (type === BookingType.CURRENT) {
            getListOfficeHoursAppointments({
                userId: user.id,
                query: prepareFilterQuery(params),
            });
        } else if (type === BookingType.PAST) {
            getListPastOfficeHoursAppointments({
                userId: user.id,
                query: prepareFilterQuery(params),
            });
        }
    };

    onLoadMoreClickHandler = page => () => {
        const {
            user,
            loadMoreOfficeHoursAppointments,
            loadMorePastOfficeHoursAppointments,
        } = this.props;

        const params = this.props.setQueryParams({ page });
        const { type } = params;
        if (type === BookingType.CURRENT) {
            loadMoreOfficeHoursAppointments({
                userId: user.id,
                query: prepareFilterQuery(params),
            });
        } else if (type === BookingType.PAST) {
            loadMorePastOfficeHoursAppointments({
                userId: user.id,
                query: prepareFilterQuery(params),
            });
        }
    };

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    handleCancelBookOpen = id => {
        this.setState({ cancelBookingId: id });
    };

    handleCancelBookClose = () => {
        this.setState({ cancelBookingId: false });
    };

    onCancelBookingSubmit = values => {
        const { cancelOfficeHoursAppointment } = this.props;
        const { cancelBookingId } = this.state;

        cancelOfficeHoursAppointment({
            slotId: cancelBookingId,
            data: {
                message: values.message,
            },
        });
    };

    handleAttendanceOpen = id => {
        this.setState({ attendanceBookingId: id });
    };

    toggleStudentAttendance = () => {
        const {
            markOfficeHoursAppointmentAsStudentAttended,
            markOfficeHoursAppointmentAsStudentNotAttended,
            appointments,
        } = this.props;
        const { attendanceBookingId } = this.state;

        const appointment = appointments
            ? appointments.find(i => i.id === Number(attendanceBookingId))
            : null;
        if (appointment) {
            if (appointment.studentAttended === true)
                markOfficeHoursAppointmentAsStudentNotAttended(appointment.id);
            else markOfficeHoursAppointmentAsStudentAttended(appointment.id);
        }
    };

    onAttendanceCommentFormSubmit = values => {
        const { putOfficeHoursAppointmentAttendanceComment } = this.props;
        const { attendanceBookingId } = this.state;
        putOfficeHoursAppointmentAttendanceComment({
            slotId: Number(attendanceBookingId),
            data: values,
        });
        this.setState({ commentFormVisible: false });
    };

    onDateChangeHandler = field => value => {
        const {
            user,
            getListOfficeHoursAppointments,
            getListPastOfficeHoursAppointments,
        } = this.props;

        const newParams = {
            ...this.props.getQueryParams(),
            [field]: value ? value.toISOString() : "",
        };
        const { type, from, to } = newParams;

        let isFromError;
        let isToError;

        if (type === BookingType.CURRENT) {
            isFromError =
                moment(from).diff(
                    moment().set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        milliseconds: 0,
                    })
                ) < 0
                    ? "From date must not be in the past"
                    : null;
            isToError =
                moment(to).diff(
                    moment().set({ hour: 23, minute: 59, second: 59, milliseconds: 0 })
                ) < 0
                    ? "To date must not be in the past"
                    : null;

            if (
                !isFromError &&
                !isToError &&
                moment(from)
                    .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        milliseconds: 0,
                    })
                    .diff(
                        moment(to).set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                    ) > 0
            ) {
                isToError = "To date must not be before From date";
            }

            this.setState({ isFromError, isToError });
            if (!isFromError && !isToError) {
                const finalParams = this.props.setQueryParams({
                    ...newParams,
                    page: 1,
                });

                getListOfficeHoursAppointments({
                    userId: user.id,
                    query: prepareFilterQuery(finalParams),
                });
            }
        } else if (type === BookingType.PAST) {
            isFromError =
                moment(from).diff(
                    moment({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        milliseconds: 0,
                    })
                ) > 0
                    ? "From date must not be in the future"
                    : null;
            isToError =
                moment(to).diff(
                    moment().set({ hour: 23, minute: 59, second: 59, milliseconds: 0 })
                ) > 0
                    ? "To date must not be in the future"
                    : null;

            if (
                !isFromError &&
                !isToError &&
                moment(from)
                    .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        milliseconds: 0,
                    })
                    .diff(
                        moment(to).set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                    ) > 0
            ) {
                isToError = "To date must not be before From date";
            }

            this.setState({ isFromError, isToError });
            if (!isFromError && !isToError) {
                const finalParams = this.props.setQueryParams({
                    ...newParams,
                    page: 1,
                });

                getListPastOfficeHoursAppointments({
                    userId: user.id,
                    query: prepareFilterQuery(finalParams),
                });
            }
        }
    };

    render() {
        const {
            user,
            appointments,
            history: { push },
            nextPage,
            count,
            errorRes: { error, errors },
            markOfficeHoursAppointmentAsStudentNotAttended,
        } = this.props;
        const {
            onLoadMoreClickHandler,
            handleCancelBookOpen,
            handleCancelBookClose,
            onCancelBookingSubmit,
            onTabChange,
            markAsAttended,
            markAsNotAttended,
            onDateChangeHandler,
            handleAttendanceOpen,
            toggleStudentAttendance,
            onAttendanceCommentFormSubmit,
        } = this;
        const {
            cancelBookingId,
            isFromError,
            isToError,
            attendanceBookingId,
            commentFormVisible,
        } = this.state;
        const { type, from, to } = this.props.getQueryParams();

        const appointment = appointments
            ? appointments.find(i => i.id === Number(attendanceBookingId))
            : null;

        const commentDialogActons = [
            <Button
                key="clsoe"
                onClick={() =>
                    this.setState({ attendanceBookingId: false, commentFormVisible: false })
                }
            >
                Close
            </Button>,
        ];

        return (
            <div>
                <MainTitle title={`${user.firstName} ${user.lastName}`} type={"[View Bookings]"} />

                <Paper elevation={1}>
                    {type !== undefined && (
                        <TabsComponent
                            activeIndex={type}
                            tabs={[
                                {
                                    label: "Upcoming bookings",
                                    selected: type === BookingType.CURRENT,
                                    fnLabel: "onTabChange",
                                    param: BookingType.CURRENT,
                                },
                                {
                                    label: "Past bookings",
                                    selected: type === BookingType.PAST,
                                    fnLabel: "onTabChange",
                                    param: BookingType.PAST,
                                },
                            ]}
                            fns={{ onTabChange }}
                        />
                    )}
                </Paper>

                <Paper elevation={1}>
                    <DateFilterRow
                        {...{
                            from,
                            to,
                            isFromError,
                            isToError,
                            labelDate: "Filter by appointment date",
                            onDateChangeHandler,
                        }}
                    />
                    <TableComponent
                        items={appointments}
                        fns={{
                            push,
                            handleCancelBookOpen,
                            markAsAttended,
                            markAsNotAttended,
                            handleAttendanceOpen,
                        }}
                        buttons={buttons(appointments)}
                        decorators={decorators}
                        callbacks={callbacks}
                        columns={columns}
                    />
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {appointments.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}

                <Dialog
                    open={Boolean(cancelBookingId)}
                    onClose={handleCancelBookClose}
                    maxWidth="xs"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>Cancel appointment</h2>
                        {error && <div css={formsCss.genericError}>{error}</div>}
                        <MessageForm
                            {...{
                                onSubmit: onCancelBookingSubmit,
                                push,
                                errors,
                                fieldLabel: "Reason for cancellation",
                                buttonLabel: "Cancel booking",
                            }}
                        />
                    </div>
                </Dialog>

                <Dialog
                    open={Boolean(attendanceBookingId)}
                    onClose={() =>
                        this.setState({ attendanceBookingId: false, commentFormVisible: false })
                    }
                    maxWidth="lg"
                    fullWidth
                    actions={commentDialogActons}
                >
                    <DialogContent>
                        <div css={globalsCss.innerDialog}>
                            <Fragment>
                                <div css={globalsCss.userCardBody}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Appointment with: <strong>{appointment?.user}</strong>
                                    </Typography>
                                    <div className="row">
                                        <div
                                            className="col-sm-4 col-xs-12"
                                            css={globalsCss.helpers.mt15}
                                        >
                                            <Typography component="p" variant="subtitle1">
                                                <strong>Date: </strong> {appointment?.date}
                                            </Typography>
                                            <Typography component="p" variant="subtitle1">
                                                <strong>Time:</strong> {appointment?.time}
                                            </Typography>
                                        </div>
                                        {appointment && (
                                            <Fragment>
                                                <div
                                                    className="col-sm-4 col-xs-12"
                                                    css={globalsCss.helpers.mt15}
                                                >
                                                    <strong>Student attended?</strong>
                                                    <div>
                                                        {appointment.studentAttended === null
                                                            ? "n/a"
                                                            : appointment.studentAttended
                                                            ? "Yes"
                                                            : "No"}
                                                    </div>
                                                    <div css={globalsCss.actionsAttendance}>
                                                        <Button onClick={toggleStudentAttendance}>
                                                            {appointment.studentAttended === true
                                                                ? "Mark as NOT attended"
                                                                : "Mark as attended"}
                                                        </Button>

                                                        {appointment.studentAttended === null && (
                                                            <Button
                                                                onClick={() =>
                                                                    markOfficeHoursAppointmentAsStudentNotAttended(
                                                                        appointment.id
                                                                    )
                                                                }
                                                            >
                                                                Mark as NOT attended
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>

                                                {commentFormVisible ? (
                                                    <div
                                                        className="col-sm-4 col-xs-12"
                                                        css={globalsCss.helpers.mt15}
                                                    >
                                                        {error && (
                                                            <div css={formsCss.genericError}>
                                                                {error}
                                                            </div>
                                                        )}
                                                        <CommentForm
                                                            {...{
                                                                onSubmit: onAttendanceCommentFormSubmit,
                                                                push,
                                                                errors,
                                                                fieldLabel: "Comment",
                                                                buttonLabel: appointment.comment
                                                                    ? "Amend comment"
                                                                    : "Add comment",
                                                                initialValues: {
                                                                    comment: appointment.comment,
                                                                },
                                                                simpleLayout: true,
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="col-sm-4 col-xs-12"
                                                        css={globalsCss.helpers.mt15}
                                                    >
                                                        <strong>Comment:</strong>
                                                        <div>
                                                            {appointment?.comment
                                                                ? appointment?.comment
                                                                : "n/a"}
                                                        </div>
                                                        <div css={globalsCss.actionsAttendance}>
                                                            <Button
                                                                css={formsCss.btnSubmit}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        commentFormVisible: !commentFormVisible,
                                                                    })
                                                                }
                                                            >
                                                                {appointment.comment
                                                                    ? "Amend comment"
                                                                    : "Add comment"}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                    </DialogContent>
                    <DialogActions>{commentDialogActons}</DialogActions>
                </Dialog>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListOfficeHoursAppointments: actionCreators.getListOfficeHoursAppointments.create,
    getListPastOfficeHoursAppointments: actionCreators.getListPastOfficeHoursAppointments.create,
    loadMoreOfficeHoursAppointments: actionCreators.loadMoreOfficeHoursAppointments.create,
    loadMorePastOfficeHoursAppointments: actionCreators.loadMorePastOfficeHoursAppointments.create,
    cancelOfficeHoursAppointment: actionCreators.cancelOfficeHoursAppointment.create,
    markOfficeHoursAppointmentAsStudentAttended:
        actionCreators.markOfficeHoursAppointmentAsStudentAttended.create,
    markOfficeHoursAppointmentAsStudentNotAttended:
        actionCreators.markOfficeHoursAppointmentAsStudentNotAttended.create,
    putOfficeHoursAppointmentAttendanceComment:
        actionCreators.putOfficeHoursAppointmentAttendanceComment.create,
};

const mapStateToProps = ({ OfficeHoursAppointments, OfficeHoursSessions, Auth }) => ({
    appointments: OfficeHoursAppointments.appointments.data.map(item => {
        const userEmail = item.apiUser.email ? ` ${item.apiUser.email}` : "";

        const userElement = (
            <div>
                <span>
                    {item.apiUser.firstName} {item.apiUser.lastName}
                </span>
                {item.apiUser.email && (
                    <>
                        <br />
                        {item.apiUser.email && <em> {item.apiUser.email}</em>}
                    </>
                )}
                {item.apiUser.playRef && (
                    <>
                        <br />
                        playRef: {item.apiUser.playRef && <em> {item.apiUser.playRef}</em>}
                    </>
                )}
            </div>
        );

        return {
            id: item.id,
            date: (
                <div>
                    {GetFormattedDate(item.start, "ddd D MMMM YYYY")}
                    <br />
                    {GetFormattedTime(item.start, "h.mma")} - {GetFormattedTime(item.end, "h.mma")}
                </div>
            ),
            location: item.officeHoursSession.location
                ? item.officeHoursSession.appointmentForm
                    ? `${item.officeHoursSession.location} (${item.officeHoursSession.appointmentForm})`
                    : item.officeHoursSession.location
                : "",
            session: item.officeHoursSession.teamName
                ? `${item.officeHoursSession.title} (${item.officeHoursSession.teamName})`
                : item.officeHoursSession.title,
            booking: userElement ? userElement : "",
            message: item.message ? item.message : null,
            attachment: item.sourceFileName,
            comment: item.comment,
        };
    }),
    count: OfficeHoursAppointments.appointments.count,
    nextPage: OfficeHoursAppointments.appointments.nextPage,
    queryParams: OfficeHoursAppointments.appointments.queryParams,
    user: Auth,
    errorRes: OfficeHoursSessions.errorRes,
});

export default connect(
    mapStateToProps,
    dispatchToProps
)(withQueryParams(OfficeHoursAppointmentsListContainer));
