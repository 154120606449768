import { Component } from "react";
import { connect } from "react-redux";

/** @jsx jsx */
import { jsx } from "@emotion/core";

import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as audienceActionCreators } from "../../../store/UsersAndAudiences/Audiences/actionCreators";

import { MainTitle } from "../../../common/components/MainTitle";
import FormCustom, { ALL_DEPARTAMENTS } from "./components/FormCustom";

class NewCustomAudienceContainer extends Component {
    componentDidMount() {
        this.props.setTitle("Audiences");
    }

    componentWillUnmount() {
        const {
            clearTitle,
            clearOneCustomAudience,
            clearCustomAudienceLiveParamsStudents,
            clearCustomAudienceLiveParams,
        } = this.props;
        clearTitle();
        clearOneCustomAudience();
        clearCustomAudienceLiveParamsStudents();
        clearCustomAudienceLiveParams();
    }

    onSubmit = values => {
        this.props.postCustomAudience({
            lseDepartmentId:
                values.lseDepartmentId === ALL_DEPARTAMENTS ? null : values.lseDepartmentId,
            lseProgrammes: values.lseProgrammes
                ? values.lseProgrammes.map(i => {
                      return { id: i };
                  })
                : null,
            graduation: values.graduation,
            yearGroup:
                values.yearGroup !== "ALL"
                    ? Number(values.yearGroup) > 0
                        ? Number(values.yearGroup)
                        : null
                    : null,
            numStudents: values.numStudents,
            lseNumStudents: values.lseNumStudents,
        });
    };

    render() {
        const {
            initialValues,
            history: { goBack },
        } = this.props;

        return (
            <div>
                <MainTitle title="Audiences" type="[Users &amp; Audiences]" />
                <FormCustom
                    currentFormType="NEW"
                    onSubmit={this.onSubmit}
                    {...{ initialValues, goBack }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    clearOneCustomAudience: audienceActionCreators.clearOneCustomAudience.create,
    postCustomAudience: audienceActionCreators.postCustomAudience.create,
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    clearCustomAudienceLiveParams: audienceActionCreators.clearCustomAudienceLiveParams.create,
    clearCustomAudienceLiveParamsStudents:
        audienceActionCreators.clearCustomAudienceLiveParamsStudents.create,
};

const mapStateToProps = ({ Audience }) => ({
    initialValues: Audience.customAudience,
});

export default connect(mapStateToProps, dispatchToProps)(NewCustomAudienceContainer);
