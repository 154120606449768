import ActionCreator from "../../actionCreator";

export default {
    getListStudents: ActionCreator("GET_LIST_STUDENTS"),
    createListStudents: ActionCreator("CREATE_LIST_STUDENTS"),
    updateListStudents: ActionCreator("UPDATE_LIST_STUDENTS"),
    getOneStudent: ActionCreator("GET_ONE_STUDENT"),
    updateOneStudent: ActionCreator("UPDATE_ONE_STUDENT"),
    putStudent: ActionCreator("PUT_STUDENT"),
    errorResponse: ActionCreator("ERROR_RESPONSE_STUDENT"),
    loadMoreStudents: ActionCreator("LOAD_MORE_STUDENTS"),
    clearListStudents: ActionCreator("CLEAR_LIST_STUDENTS"),
    pushListStudents: ActionCreator("PUSH_LIST_STUDENTS"),
    clearStudent: ActionCreator("CLEAR_STUDENT"),
    removeStudent: ActionCreator("REMOVE_STUDENT"),
    upgradeToStaff: ActionCreator("UPGRADE_STUDENT"),
};
