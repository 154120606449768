import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import actionCreators from "../../../store/SideLinks/actionCreators";
import { RedirectButton } from "../../../common/components/RedirectButton";
import { Paper, Dialog } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideLinksSectionForm from "../components/SectionForm";
import SideLinksLinkForm from "../components/LinkForm";
import { TableComponent } from "../../../common/components/OfficeHours";
import {
    EditIcon,
    AddIcon,
    DeleteIcon,
    ArrowUpIcon,
    ArrowDownIcon,
} from "../../../common/components/icons";
import tableCss from "../../../common/styles/table.css";
import PromptDialog from "../../../common/components/PromptDialog";
import SideLinkRow from "../components/SideLinkRow";

/** @jsx jsx */
import { jsx } from "@emotion/core";

const callbacks = {
    // eslint-disable-next-line react/display-name
    name: (item, prop) => (item[prop] ? <SideLinkRow {...item} /> : ""),
};

const buttons = items => index => {
    const getparentIndex = item => {
        const itemIndex = items.findIndex(i => i.id === item.id);
        let parents = [];
        items.forEach((i, pIndex) => {
            if (i.lvl === item.lvl - 1) parents.push(pIndex);
        });
        let res = null;
        for (const [pI, p] of parents.entries()) {
            if (p > itemIndex) {
                res = parents[parseInt(pI) - 1];
                break;
            }
        }
        if (res === null) res = parents[parents.length - 1];
        return res;
    };

    const getChildren = parentIndex => {
        if (parentIndex >= 0) {
            let res = [];
            const parentLvl = items[parentIndex].lvl;
            const childLvl = items[parentIndex + 1].lvl;
            for (let i = parentIndex + 1; i < items.length; i++) {
                if (items[i].lvl === parentLvl) break;
                if (i > parentIndex && items[i].lvl === childLvl) res.push(items[i]);
            }
            return res;
        } else {
            return items.filter(i => i.lvl === 1);
        }
    };

    const showMoveUp = item => {
        const thisLevel = getChildren(getparentIndex(item));
        const itemIndex = thisLevel.findIndex(i => i.id === item.id);
        return itemIndex > 0 && thisLevel[itemIndex - 1].lvl === item.lvl;
    };

    const showMoveDown = item => {
        const thisLevel = getChildren(getparentIndex(item));
        const itemIndex = thisLevel.findIndex(i => i.id === item.id);
        return itemIndex < thisLevel.length - 1 && thisLevel[itemIndex + 1].lvl === item.lvl;
    };

    const results = [
        {
            path: items[index] ? items[index] : null,
            label: "Edit",
            fnLabel: "edit",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
        },
        {
            path: items[index] ? items[index] : null,
            label: "Delete",
            fnLabel: "deleteItem",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        },
    ];

    if (showMoveUp(items[index])) {
        results.push({
            path: items[index] ? items[index] : null,
            label: "Move Up",
            fnLabel: "moveUp",
            icon: <ArrowUpIcon css={tableCss.actionsMenuItemIcon} />,
        });
    }

    if (showMoveDown(items[index])) {
        results.push({
            path: items[index] ? items[index] : null,
            label: "Move Down",
            fnLabel: "moveDown",
            icon: <ArrowDownIcon css={tableCss.actionsMenuItemIcon} />,
        });
    }

    if (!items[index].url) {
        results.push({
            path: items[index] ? items[index] : null,
            label: "Add Child Link",
            fnLabel: "addLink",
            icon: <AddIcon css={tableCss.actionsMenuItemIcon} />,
        });

        if (items[index].lvl === 1) {
            results.push({
                path: items[index] ? items[index] : null,
                label: "Add Child Section",
                fnLabel: "addSection",
                icon: <AddIcon css={tableCss.actionsMenuItemIcon} />,
            });
        }
    }

    return results;
};

export const SideLinksContainer = () => {
    const dispatch = useDispatch();
    const { sideLinks, errorRes } = useSelector(state => state.SideLinks);
    const [formSectionPopup, setFormSectionPopup] = useState(false);
    const [isEditSectionPopup, setIsEditSectionPopup] = useState(false);
    const [formLinkPopup, setFormLinkPopup] = useState(false);
    const [isEditLinkPopup, setIsEditLinkPopup] = useState(false);
    const [currentItem, setCurrentItem] = useState(undefined);
    const [removeId, setRemoveId] = useState(null);

    useEffect(() => {
        dispatch(mainMenuActionCreators.setTitle.create("Side Links"));
        dispatch(actionCreators.getSidelinks.create());
        return () => {
            dispatch(mainMenuActionCreators.clearTitle.create());
            dispatch(actionCreators.clearSideLinks.create());
        };
    }, []);

    useEffect(() => {
        if (sideLinks.length > 0) {
            setFormSectionPopup(false);
            setIsEditSectionPopup(false);
            setFormLinkPopup(false);
            setIsEditLinkPopup(false);
            setCurrentItem(undefined);
        }
    }, [sideLinks]);

    const onSectionFormSubmit = values => {
        if (isEditSectionPopup) dispatch(actionCreators.editSideLinkSection.create(values));
        else
            dispatch(
                actionCreators.addSideLinkSection.create({
                    ...values,
                    ...(currentItem ? { parentId: currentItem.id } : {}),
                })
            );
    };

    const onLinkFormSubmit = values => {
        if (isEditLinkPopup) dispatch(actionCreators.editSideLinkItem.create(values));
        else
            dispatch(
                actionCreators.addSideLinkItem.create({
                    ...values,
                    ...(currentItem ? { parentId: currentItem.id } : {}),
                })
            );
    };

    const edit = item => {
        setCurrentItem(item);

        if (item.url) {
            // link
            setIsEditLinkPopup(true);
            setFormLinkPopup(true);
        } else {
            // section
            setIsEditSectionPopup(true);
            setFormSectionPopup(true);
        }
    };

    const addLink = item => {
        if (item) setCurrentItem(item);
        setIsEditLinkPopup(false);
        setFormLinkPopup(true);
    };

    const addSection = item => {
        if (item) setCurrentItem(item);
        setIsEditSectionPopup(false);
        setFormSectionPopup(true);
    };

    const deleteItem = item => {
        setRemoveId(item.id);
    };

    const confirmDelete = () => {
        setRemoveId(null);
        dispatch(actionCreators.deleteSidelink.create({ id: removeId }));
    };

    const moveUp = item => {
        dispatch(actionCreators.moveUpSideLink.create({ id: item.id }));
    };

    const moveDown = item => {
        dispatch(actionCreators.moveDownSideLink.create({ id: item.id }));
    };

    return (
        <div>
            <div>
                <div css={globalsCss.actions}>
                    <RedirectButton label="Add section" onClick={() => addSection(null)} />
                </div>

                <Paper elevation={1}>
                    <TableComponent
                        items={sideLinks}
                        fns={{ edit, addLink, addSection, deleteItem, moveUp, moveDown }}
                        buttons={buttons(sideLinks)}
                        columns={["id", "name"]}
                        callbacks={callbacks}
                    />
                </Paper>
            </div>

            <Dialog
                open={formSectionPopup}
                onClose={() => {
                    setIsEditSectionPopup(false);
                    setFormSectionPopup(false);
                }}
                maxWidth="xs"
                fullWidth
            >
                <div css={globalsCss.innerDialog}>
                    <h2 css={globalsCss.subtitleTitle}>
                        {isEditSectionPopup ? "Edit" : "Add"} section
                    </h2>
                    <SideLinksSectionForm
                        isEdit={isEditSectionPopup}
                        initialValues={isEditSectionPopup ? currentItem : {}}
                        {...{
                            onSubmit: onSectionFormSubmit,
                            errors: errorRes.errors,
                        }}
                    />
                </div>
            </Dialog>

            <Dialog
                open={formLinkPopup}
                onClose={() => {
                    setIsEditLinkPopup(false);
                    setFormLinkPopup(false);
                }}
                maxWidth="xs"
                fullWidth
            >
                <div css={globalsCss.innerDialog}>
                    <h2 css={globalsCss.subtitleTitle}>{isEditLinkPopup ? "Edit" : "Add"} link</h2>
                    <SideLinksLinkForm
                        isEdit={isEditLinkPopup}
                        initialValues={isEditLinkPopup ? currentItem : {}}
                        {...{
                            onSubmit: onLinkFormSubmit,
                            errors: errorRes.errors,
                        }}
                    />
                </div>
            </Dialog>

            <PromptDialog
                open={!!removeId}
                handleClose={confirmed => (confirmed ? confirmDelete() : setRemoveId(null))}
            >
                Are you sure you want to delete this item?
            </PromptDialog>
        </div>
    );
};
