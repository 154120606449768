import { Component } from "react";
import { connect } from "react-redux";
import { TableComponent, RedirectButton } from "../../../common/components/Channels";
import actionCreators from "../../../store/Channels/Pages/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as channelsActionCreators } from "../../../store/Channels/actionCreators";
import { prepareFilterQuery } from "../../../common/services/utils";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PromptDialog from "../../../common/components/PromptDialog";
import ChannelFilterRow from "../../../common/components/ChannelFilterRow";
import FilterRow from "../../../common/components/FilterRow";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";
import { hasAdminRole } from "../../../common/services/Auth";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import {
    statusCallback,
    permissionCallback,
    boolCallback,
} from "../../../common/services/tableCallbacks";
import formsCss from "../../../common/styles/forms.css";
import {
    PreviewIcon,
    EditIcon,
    PostIcon,
    EventIcon,
    DeleteIcon,
    AutoRenewIcon,
    MemberIcon,
    UpgradeIcon,
    DowngradeIcon,
    PersonIcon,
} from "../../../common/components/icons";
import { withQueryParams } from "../../../common/components/withQueryParams";
import { MainTitle } from "../../../common/components/MainTitle";

const decorators = [
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            numFollowers: tableCss.columnWidth150,
            isRecommended: tableCss.columnWidth150,
        },
    },
];

const callbacks = {
    permissionLevel: permissionCallback,
    status: statusCallback,
    isRecommended: boolCallback,
};

const buttons = (items, isAdmin) => index => [
    {
        path: `/channels/pages/${items[index] ? items[index].id : ""}`,
        label: "Preview",
        fnLabel: "push",
        icon: <PreviewIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/channels/pages/edit/${items[index] ? items[index].id : ""}`,
        label: "Edit",
        fnLabel: "push",
        icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/channels/posts`,
        label: "Posts",
        fnLabel: "push",
        state: { id: items[index].id, name: items[index].name },
        icon: <PostIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/channels/events`,
        label: "Events",
        fnLabel: "push",
        state: { id: items[index].id, name: items[index].name },
        icon: <EventIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `/channels/pages/${items[index] ? items[index].id : ""}/followers`,
        label: "Followers",
        fnLabel: "push",
        icon: <MemberIcon css={tableCss.actionsMenuItemIcon} />,
    },
    ...(isAdmin
        ? [
              !items[index].isRecommended
                  ? {
                        path: `${items[index] ? items[index].id : null}`,
                        label: "Set as recommended",
                        fnLabel: "setAsRecommended",
                        icon: <UpgradeIcon css={tableCss.actionsMenuItemIcon} />,
                    }
                  : {
                        path: `${items[index] ? items[index].id : null}`,
                        label: "Remove from recommended",
                        fnLabel: "removeFromRecommended",
                        icon: <DowngradeIcon css={tableCss.actionsMenuItemIcon} />,
                    },
          ]
        : []),
    ...(items[index].status === "live"
        ? [
              {
                  path: `${items[index] ? items[index].id : null}`,
                  label: "Recalculate followers",
                  fnLabel: "recalculateFollowers",
                  icon: <PersonIcon css={tableCss.actionsMenuItemIcon} />,
              },
          ]
        : []),
    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

class PageChannelListContainer extends Component {
    state = {
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removePage({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    componentDidMount() {
        const { setTitle, getListPage, queryParams } = this.props;

        const { filter$ } = this.state;
        setTitle("Channels \u203A Pages");

        const params = this.props.initWithQueryParams({
            filter: "",
            page: 1,
            recommended: "all",
            pageId: "",
        });

        if (queryParams !== prepareFilterQuery(params)) {
            getListPage(prepareFilterQuery({ ...params, page: 1 }));
        }

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(filter => {
            const params = this.props.setQueryParams({ ...filter, page: 1 });
            getListPage(prepareFilterQuery({ ...params, page: 1 }));
        });
    }

    onFilterChangeHandler = ({ target: { value } }) => {
        const { filter$ } = this.state;
        const params = this.props.setQueryParams({
            filter: value ? value : "",
            page: 1,
        });
        filter$.next(params);
    };

    onRecommendedChangeHandler = value => {
        const { filter$ } = this.state;
        const params = this.props.setQueryParams({
            recommended: value ? "yes" : "all",
            page: 1,
        });
        filter$.next(params);
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMorePages } = this.props;
        const params = this.props.setQueryParams({ page });
        loadMorePages(prepareFilterQuery(params));
    };

    componentWillUnmount() {
        this.props.clearTitle();
    }

    remove = id => {
        this.handleOpen(id);
    };

    recalculateFollowers = id => {
        const { refreshFollowers, pages } = this.props;
        refreshFollowers({
            pageId: id,
            pageName: pages.find(p => p.id === parseInt(id))?.name,
        });
    };

    render() {
        const {
            pages,
            history: { push },
            nextPage,
            count,
            setChannelAsRecommended,
            removeChannelFromRecommended,
            permissionLevel,
        } = this.props;
        const { filter, recommended, pageId } = this.props.getQueryParams();
        const { remove, recalculateFollowers } = this;

        let columns = ["id", "status", "name", "numFollowers"];
        if (hasAdminRole(permissionLevel)) columns = [...columns, "isRecommended"];

        return (
            <div>
                <div>
                    <MainTitle title="Pages" type="[Channels]" />
                    <div css={[globalsCss.actions, globalsCss.actionsSpaceBetween]}>
                        <RedirectButton
                            label="Add new page"
                            url="/channels/pages/new"
                            {...{ push }}
                        />
                    </div>
                </div>
                <Paper elevation={1}>
                    {hasAdminRole(permissionLevel) ? (
                        <ChannelFilterRow
                            filter={filter}
                            recommended={recommended === "yes"}
                            onFilterChangeHandler={this.onFilterChangeHandler}
                            onRecommendedChangeHandler={this.onRecommendedChangeHandler}
                        />
                    ) : (
                        <FilterRow
                            filter={filter}
                            onFilterChangeHandler={this.onFilterChangeHandler}
                        />
                    )}
                    <TableComponent
                        items={pageId ? pages.filter(({ id }) => id === pageId) : pages}
                        fns={{
                            push,
                            remove,
                            setAsRecommended: id => setChannelAsRecommended({ id, type: "pages" }),
                            removeFromRecommended: id =>
                                removeChannelFromRecommended({ id, type: "pages" }),
                            recalculateFollowers,
                        }}
                        buttons={buttons(
                            pageId ? pages.filter(({ id }) => id === pageId) : pages,
                            hasAdminRole(permissionLevel)
                        )}
                        callbacks={callbacks}
                        decorators={decorators}
                        columns={columns}
                    />

                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this page? All posts, events &amp; page
                        content will be lost!
                    </PromptDialog>
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {pages.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListPage: actionCreators.getListPage.create,
    loadMorePages: actionCreators.loadMorePages.create,
    removePage: actionCreators.removePage.create,
    setChannelAsRecommended: channelsActionCreators.setChannelAsRecommended.create,
    removeChannelFromRecommended: channelsActionCreators.removeChannelFromRecommended.create,
    refreshFollowers: actionCreators.refreshFollowers.create,
};

const mapStateToProps = ({ ChannelPage, Auth }) => ({
    pages: ChannelPage.pages.data,
    count: ChannelPage.pages.count,
    nextPage: ChannelPage.pages.nextPage,
    permissionLevel: Auth.permissionLevel,
    queryParams: ChannelPage.pages.queryParams,
});
export default connect(mapStateToProps, dispatchToProps)(withQueryParams(PageChannelListContainer));
