import { Component } from "react";
import { reduxForm, Field, Fields } from "redux-form";
import validate from "./validate";
import {
    RenderBasicEditor,
    RenderFileInput,
    RenderTextField,
    RenderRadioGroup,
    RenderRadio,
} from "../../common/components/FormFields";

import { Paper, Button } from "@material-ui/core";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../common/styles/globals.css";
import formsCss from "../../common/styles/forms.css";

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const { error, submitting, handleSubmit, errors, isLink, onTypeChange } = this.props;

        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>Edit overview</h2>
                    <form onSubmit={handleSubmit}>
                        <hr />
                        <div className="row">
                            <div className="col-xs-12">
                                <div css={globalsCss.helpers.mt35}>
                                    <label css={formsCss.label}>Homepage Teaser Text</label>
                                    <Field
                                        name="homepageTeaser"
                                        errorRes={errors.homepageTeaser}
                                        label="Homepage Teaser Text"
                                        component={RenderBasicEditor}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <Field
                                name="linkType"
                                errorRes={errors.linkType}
                                component={RenderRadioGroup}
                                onChange={onTypeChange}
                                css={globalsCss.helpers.mt30}
                                row
                            >
                                <RenderRadio
                                    value="link"
                                    label="Link to a document (URL)"
                                    className="col-lg-3 col-sm-3 col-xs-12"
                                />
                                <RenderRadio
                                    value="download"
                                    label="Click to upload a file"
                                    className="col-lg-3 col-sm-3 col-xs-12"
                                />
                            </Field>
                        </div>
                        {isLink ? (
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <Field
                                        name="userGuideLink"
                                        errorRes={errors.userGuideLink}
                                        type="url"
                                        component={RenderTextField}
                                        label="User Guide link"
                                        fullWidth
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-xs-12">
                                    <label
                                        css={[formsCss.label, globalsCss.helpers.mt20]}
                                        htmlFor="userGuideFile"
                                    >
                                        User guide
                                        <small css={formsCss.labelHelp}> (pdf)</small>
                                    </label>
                                    <Fields
                                        names={["userGuideFile", "userGuideUrl"]}
                                        errorRes={errors.userGuideFile}
                                        component={RenderFileInput}
                                    />
                                </div>
                            </div>
                        )}
                        <hr css={globalsCss.helpers.mb20} />
                        <div className="row">
                            <div className="col-xs-12">
                                <label css={formsCss.label} htmlFor="editorGuideFile">
                                    Editor guide
                                    <small css={formsCss.labelHelp}> (pdf)</small>
                                </label>
                                <Fields
                                    names={["editorGuideFile", "editorGuideUrl"]}
                                    errorRes={errors.editorGuideFile}
                                    component={RenderFileInput}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <div css={globalsCss.helpers.mt35}>
                                    <label css={formsCss.label}>Knowledge Base Teaser Text</label>
                                    <Field
                                        name="howToTeaser"
                                        errorRes={errors.howToTeaser}
                                        label="Knowledge Base Teaser Text"
                                        component={RenderBasicEditor}
                                    />
                                </div>
                            </div>
                        </div>
                        {error && <strong>{error}</strong>}
                        <div css={formsCss.actions}>
                            <Button
                                disabled={submitting}
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Save overview
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}

const form = reduxForm({
    form: "SupportPage",
    validate,
    enableReinitialize: true,
})(Form);
export default form;
