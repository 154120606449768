import * as CSS from "csstype";
import { StyleRules } from "@material-ui/core/styles";

const style: StyleRules<string> = {
    appBarRoot: {
        backgroundColor: "#fff",
        borderBottom: "1px solid #e0e0e0",
    },
    toolbarRoot: {
        // justifyContent: "space-between",
        "@media (max-width: 767px)": {
            flexDirection: "column",
        },
    },
    toolbarRootBorder: {
        borderBottom: "1px solid #e0e0e0",
        "@media (max-width: 767px)": {
            borderBottom: "0 none",
            flexDirection: "column",
            position: "relative",
            "&:before": {
                content: "''",
                backgroundColor: "rgba(66, 66, 66, 0.176)",
                height: 1,
                opacity: ".5",
                position: "absolute",
                bottom: 0,
                right: 16,
                left: 16,
            },
        },
    },
    toolbar: {
        borderBottom: "1px solid #e0e0e0",
        width: "100%",
        height: 57,
    },
    toolbarFilters: {
        borderBottom: "1px solid #e0e0e0",
        width: "100%",
        height: 57,
        justifyContent: "flex-start",
        alignItems: "center",

        "@media (max-width: 767px)": {
            padding: "0 15px",
            display: "block",
            height: 112,
        },
    },
    toolbarGroup: {
        display: "flex",
        // alignItems: "center",
        padding: 0,

        "@media (max-width: 767px)": {
            width: "100%",
            minHeight: 56,
        },
    },
    toolbarGroupStretch: {
        flex: 1,
    },
    toolbarGroupAlignCenter: {
        alignItems: "center",
    },
    toolbarSeparator: {
        flex: "0 0 auto",
        backgroundColor: "rgba(66, 66, 66, 0.176)",
        display: "block",
        height: 32,
        marginLeft: 24,
        marginRight: 24,
        width: 1,

        "@media (max-width: 767px)": {
            height: 1,
            width: "100%",
            marginRight: 0,
            marginLeft: 0,
            opacity: ".5",
        },
    },
    toolbarTitle: {
        whiteSpace: "nowrap",
        flexShrink: 0,
        fontSize: 16,
        minWidth: 58,
        minHeight: 56,
        paddingRight: 16,
        display: "flex",
        alignItems: "center",
    },
    toolbarInput: {
        height: 56,
        flex: "1 1 auto",
    },
    toolbarDatePicker: {
        display: "flex",
        justifyContent: "center",
        height: 56,
        flex: "1 1 auto",
        "& p": {
            position: "absolute",
            bottom: 4,
            left: 0,
            whiteSpace: "nowrap",
        },
    },
    toolbarSelect: {
        minWidth: 250,
        height: 56,
        // flex: "1 1 auto",
        "@media (max-width: 767px)": {
            minWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "strech",
        },
    },
    toolbarHintTextInner: {
        height: 56,
    },
    toolbarHintText: {
        bottom: 16,
    },
    labelShowOnMobile: {
        display: "none",

        "@media (max-width: 767px)": {
            display: "inline",
        },
        color: "rgba(33, 33, 33, 0.6)",
    },
    labelShowOnDesktop: {
        "@media (max-width: 767px)": {
            display: "none",
        },
        color: "rgba(33, 33, 33, 0.6)",
    },
    inputRoot: {
        backgroundColor: "#fff",
        // minWidth: 200,
        paddingTop: 13,
        "&:before": {
            borderBottom: "0 solid transparent",
        },
        "&:hover:before": {
            borderBottomColor: "transparent !important",
        },
        "&:after": {
            borderBottomColor: "transparent",
        },
    },
    inputError: {
        "&:after": {
            borderBottom: `0 solid transparent !important`,
        },
        "&:before": {
            borderBottom: `0 solid transparent !important`,
        },
    },
    switchIcon: {
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
    switchBar: {
        backgroundColor: "##6c6c6c",
    },
    checkbox: {
        color: "rgb(66, 66, 66) !important",
    },
    checkboxChecked: {
        color: "#E0112B !important",
    },
    checkboxLabel: {
        fontSize: 16,
        color: "rgba(33, 33, 33, 0.6)",
    },
};

export default style;
