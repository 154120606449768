import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import actionCreators from "./actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import { push } from "connected-react-router";

const calendarStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListEvents.type,
            actionCreators.getCalendarSubject.type,
            actionCreators.getEventDetails.type,
            actionCreators.getCreatedEventDetails.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const calendarClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListEvents.type,
            actionCreators.receiveCalendarSubject.type,
            actionCreators.errorResponse.type,
            actionCreators.receiveEventDetails.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const calendarGetListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListEvents.type),
        switchMap(action => {
            return ajax
                .get(
                    ajax.apiUrl(`manager/calendar/user/${action.payload.id}/`),
                    action.payload.query
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createListEvents.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                );
        })
    );

const calendarGetSubjectEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getCalendarSubject.type),
        switchMap(action => {
            const query = { type: "student", filter: action.payload };
            return ajax.get(ajax.apiUrl(`staff/autocomplete/users`), query).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.receiveCalendarSubject.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            );
        })
    );

const calendarReceiveSubjectEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.receiveCalendarSubject.type),
        mergeMap(({ payload }) =>
            of(
                payload.length === 1
                    ? actionCreators.selectCalendarSubject.create(payload[0])
                    : push("/calendar/browse-events")
            )
        )
    );

const calendarGetEventDetailsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getEventDetails.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `manager/calendar/user/${action.payload.id}/${action.payload.type}/${action.payload.uid}/`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.receiveEventDetails.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const calendarGetCreatedEventDetailsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getCreatedEventDetails.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`manager/calendar/event/${action.payload.uid}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.receiveEventDetails.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const calendarPostEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postEvent.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl("manager/calendar/event/"), action.payload).pipe(
                mergeMap(({ response }) =>
                    of(
                        push(
                            `/calendar/browse-events/created/${response.calendarType}/${response.uid}`
                        )
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const calendarDeleteEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteEvent.type),
        switchMap(action =>
            ajax.remove(ajax.apiUrl(`manager/calendar/event/${action.payload.id}/`)).pipe(
                mergeMap(() =>
                    of(
                        action.payload.userId
                            ? push(`/calendar/browse-events/${action.payload.userId}`)
                            : push("/calendar/browse-events")
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    calendarStartLoadingEpic,
    calendarClearLoadingEpic,
    calendarGetListEpic,
    calendarGetSubjectEpic,
    calendarReceiveSubjectEpic,
    calendarGetEventDetailsEpic,
    calendarGetCreatedEventDetailsEpic,
    calendarPostEventEpic,
    calendarDeleteEventEpic
);
