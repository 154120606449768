import ActionCreator from "../actionCreator";

export default {
    addFroalaImage: ActionCreator("FROALA_ADD_IMAGE"),
    deleteFroalaImage: ActionCreator("FROALA_DELETE_IMAGE"),
    commitFroalaChanges: ActionCreator("FROALA_COMMIT_CHANGES"),
    rollbackFroalaChanges: ActionCreator("FROALA_ROLLBACK_CHANGES"),
    removeFroalaImages: ActionCreator("FROALA_REMOVE_IMAGES"),
    cleanFroalaImages: ActionCreator("FROALA_CLEAN_IMAGES"),
    logFroalaError: ActionCreator("FROALA_LOG_ERROR"),
    errorResponse: ActionCreator("ERROR_RESPONSE_FROALA"),
};
