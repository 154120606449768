import ActionCreator from "../actionCreator";

export default {
    getChannel: ActionCreator("GET_CHANNEL"),
    createChannel: ActionCreator("CREATE_CHANNEL"),
    clearChannel: ActionCreator("CLEAR_CHANNEL"),

    getChannelPosts: ActionCreator("GET_CHANNEL_POSTS"),
    updateChannelPosts: ActionCreator("CREATE_CHANNEL_POSTS"),

    errorResponse: ActionCreator("ERROR_RESPONSE_CHANNEL"),

    setChannelAsRecommended: ActionCreator("SET_CHANNEL_AS_RECOMMENDED"),
    confirmSetChannelAsRecommended: ActionCreator("CONFIRM_SET_CHANNEL_AS_RECOMMENDED"),
    removeChannelFromRecommended: ActionCreator("REMOVE_CHANNEL_FROM_RECOMMENDED"),
    confirmChannelRemoveFromRecommended: ActionCreator("CONFIRM_REMOVE_CHANNEL_FROM_RECOMMENDED"),
};
