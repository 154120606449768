import { Component, Fragment } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import { StickyContainer, Sticky } from "react-sticky";
import actionCreators from "../../store/CmsSupport/actionCreators";
import authActionCreators from "../../store/Auth/actionCreators";
import feedbackActionCreators from "../../store/Feedback/actionCreators";
import {
    ContentContainer,
    Header,
    Breadcrumbs,
    BreadcrumbsItem,
    BreadcrumbsDivider,
    Footer,
    SidebarMenu,
    SidebarMenuItem,
    Icon,
    Button,
} from "../common/UIElements";
import { knowledgeBaseStyle } from "./KnowledgeBaseStyles";
import Modal from "react-modal";
import { modalDefaultStyle, modalStyle, formStyle } from "../Home//HomeStyles";
import Form from "../Home/Form";
import { startSubmit, stopSubmit } from "redux-form";
import bugsnagClient from "../../common/services/bugsnag";
import Wysiwyg from "../../common/components/Wysiwyg";
import { Date } from "../../common/components/Time";
import browser from "browser-detect";

class KnowledgeBasePage extends Component {
    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
            isStickyColumnLeft: window.innerWidth < 768 ? false : true,
            isStickyColumnRight: window.innerWidth < 1122 ? false : true,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    onSubmit = values => {
        const { sendFeedback } = this.props;
        const userAgent = browser();
        const capitalize = s => {
            if (typeof s !== "string") return "";
            return s.charAt(0).toUpperCase() + s.slice(1);
        };

        sendFeedback({
            subject: values.subject,
            requestType: Number(values.requestType),
            body: values.body,
            name: values.name,
            email: values.email,
            environment: {
                app: bugsnagClient.app,
                device: bugsnagClient.device,
            },
            system: capitalize(userAgent.os),
            platform: `${userAgent.mobile ? "Mobile web" : "Desktop web"} v.${
                process.env.REACT_APP_VERSION
            }`,
            browser: capitalize(`${userAgent.name} ${userAgent.version}`),
        });
    };

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    closeModal = () => {
        const { clearFeedback } = this.props;
        this.setState({ modalIsOpen: false });
        clearFeedback();
    };

    updateDimensions = () => {
        this.setState({
            isStickyColumnLeft: window.innerWidth < 768 ? false : true,
            isStickyColumnRight: window.innerWidth < 1122 ? false : true,
        });
    };

    componentDidMount() {
        const {
            knowledgebaseArticles,
            getKnowledgeBaseArticles,
            getOneKnowledgeBaseArticle,
            getIntroKnowledgeBaseArticle,
            restoreUser,
            getSubjects,
            match: {
                params: { categoryId, articleId, type },
            },
        } = this.props;
        if (!knowledgebaseArticles) getKnowledgeBaseArticles({ categoryId });
        articleId && articleId > 0
            ? getOneKnowledgeBaseArticle({ categoryId, articleId })
            : getIntroKnowledgeBaseArticle({ categoryId });

        restoreUser();
        getSubjects();

        if (type === "form") {
            this.setState({ modalIsOpen: true });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            getOneKnowledgeBaseArticle,
            match: {
                params: { categoryId, articleId },
            },
        } = this.props;
        const {
            match: {
                params: { articleId: prevArticleId },
            },
        } = prevProps;

        window.addEventListener("resize", this.updateDimensions);

        if (articleId > 0 && articleId !== prevArticleId)
            getOneKnowledgeBaseArticle({ categoryId, articleId });
    }

    componentWillUnmount() {
        const { clearKnowledgeBaseArticle } = this.props;
        clearKnowledgeBaseArticle();
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        const {
            subjects,
            feedbackSent,
            initialValues,
            errorRes: { errors },
            knowledgebaseArticles,
            knowledgebaseArticle,
            match: {
                params: { categoryId, articleId },
            },
        } = this.props;

        const { isStickyColumnLeft, isStickyColumnRight } = this.state;

        const { openModal, closeModal, onSubmit } = this;

        return (
            <Fragment>
                <Header />
                {knowledgebaseArticle && Object.keys(knowledgebaseArticle).length > 0 && (
                    <div>
                        <ContentContainer>
                            <div css={knowledgeBaseStyle.contentHeader}>
                                <Breadcrumbs>
                                    <BreadcrumbsItem to="/support">Home</BreadcrumbsItem>
                                    <BreadcrumbsDivider />
                                    <BreadcrumbsItem to="/support/knowledge-base">
                                        Knowledge Base
                                    </BreadcrumbsItem>
                                    <BreadcrumbsDivider />
                                    <BreadcrumbsItem>
                                        {knowledgebaseArticle.category.name}
                                    </BreadcrumbsItem>
                                </Breadcrumbs>
                                <h1 css={knowledgeBaseStyle.title}>{knowledgebaseArticle.title}</h1>
                                <time css={knowledgeBaseStyle.date}>
                                    Updated{" "}
                                    <Date
                                        date={knowledgebaseArticle.updatedAt}
                                        format={"Do MMMM YYYY"}
                                    />
                                </time>
                            </div>
                            <StickyContainer>
                                <div css={knowledgeBaseStyle.columnContainer}>
                                    <div css={knowledgeBaseStyle.columnCenter}>
                                        <div css={knowledgeBaseStyle.content}>
                                            <Wysiwyg html={knowledgebaseArticle.content} />
                                        </div>
                                        <div css={knowledgeBaseStyle.showOnMobile}>
                                            <div css={knowledgeBaseStyle.helpAction}>
                                                <span css={knowledgeBaseStyle.helpAction.icon}>
                                                    <Icon
                                                        name="help"
                                                        width="18"
                                                        height="18"
                                                        fill="white"
                                                    />
                                                    <span
                                                        css={
                                                            knowledgeBaseStyle.helpAction.iconShape
                                                        }
                                                    />
                                                </span>
                                                <div css={knowledgeBaseStyle.helpAction.title}>
                                                    Has this article answered your question?
                                                </div>
                                                <div
                                                    onClick={() => openModal()}
                                                    css={knowledgeBaseStyle.helpAction.link}
                                                >
                                                    If not, get in touch and we’ll do our best to
                                                    help.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div css={knowledgeBaseStyle.columnRight}>
                                        <Sticky disableCompensation>
                                            {({ style }) => (
                                                <div css={isStickyColumnRight ? style : {}}>
                                                    <div css={knowledgeBaseStyle.helpAction}>
                                                        <span
                                                            css={knowledgeBaseStyle.helpAction.icon}
                                                        >
                                                            <Icon
                                                                name="help"
                                                                width="18"
                                                                height="18"
                                                                fill="white"
                                                            />
                                                            <span
                                                                css={
                                                                    knowledgeBaseStyle.helpAction
                                                                        .iconShape
                                                                }
                                                            />
                                                        </span>
                                                        <div
                                                            css={
                                                                knowledgeBaseStyle.helpAction.title
                                                            }
                                                        >
                                                            Has this article answered your question?
                                                        </div>
                                                        <div
                                                            onClick={() => openModal()}
                                                            css={knowledgeBaseStyle.helpAction.link}
                                                        >
                                                            If not, get in touch and we’ll do our
                                                            best to help.
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Sticky>
                                    </div>
                                    <div css={knowledgeBaseStyle.columnLeft}>
                                        <Sticky disableCompensation>
                                            {({ style }) => (
                                                <div css={isStickyColumnLeft ? style : {}}>
                                                    <div css={knowledgeBaseStyle.sidebarMenu}>
                                                        {knowledgebaseArticles && (
                                                            <SidebarMenu>
                                                                {knowledgebaseArticles.map(
                                                                    (item, index) => (
                                                                        <SidebarMenuItem
                                                                            key={item.id}
                                                                            to={
                                                                                item.type === "url"
                                                                                    ? item.content
                                                                                    : `/support/knowledge-base/${categoryId}/${item.id}`
                                                                            }
                                                                            isExternal={
                                                                                item.type === "url"
                                                                            }
                                                                            isActive={
                                                                                Number(
                                                                                    articleId
                                                                                ) ===
                                                                                    Number(
                                                                                        item.id
                                                                                    ) ||
                                                                                (!articleId &&
                                                                                    index === 0)
                                                                            }
                                                                        >
                                                                            {item.title}
                                                                        </SidebarMenuItem>
                                                                    )
                                                                )}
                                                            </SidebarMenu>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Sticky>
                                    </div>
                                </div>
                            </StickyContainer>
                            <Footer />
                        </ContentContainer>

                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={modalDefaultStyle}
                            ariaHideApp={false}
                            shouldCloseOnOverlayClick={false}
                        >
                            <button
                                type="button"
                                css={modalStyle.btnClose}
                                onClick={() => closeModal()}
                            >
                                <Icon name="times" width="18" height="18" />
                            </button>

                            {feedbackSent === false ? (
                                <Fragment>
                                    <h2>Send us feedback</h2>
                                    <p>
                                        If you need additional help or have ideas about how to
                                        improve the Hub, please share your feedback with us.
                                        We&apos;d love to hear your suggestions.{" "}
                                    </p>
                                    <Form {...{ onSubmit, subjects, errors, initialValues }} />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <h2>Thank you</h2>
                                    <p>
                                        Thank you for your message! We&apos;ll get back to you with
                                        a response within 5 working days.
                                    </p>
                                    <div css={formStyle.actions}>
                                        <Button type="button" onClick={() => closeModal()}>
                                            Close
                                        </Button>
                                    </div>
                                </Fragment>
                            )}
                        </Modal>
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    knowledgebaseArticles: state.CmsSupport.knowledgebaseArticles,
    knowledgebaseArticle: state.CmsSupport.knowledgebaseArticle,
    subjects: state.Feedback.subjects,
    errorRes: state.Feedback.errorRes,
    feedbackSent: state.Feedback.feedbackSent,
    initialValues: state.Auth.isLogged
        ? {
              name: `${state.Auth.firstName} ${state.Auth.lastName}`,
              email: state.Auth.email,
          }
        : {},
});

const dispatchToProps = {
    getHomepage: actionCreators.getHomepage.create,
    getKnowledgeBaseArticles: actionCreators.getKnowledgeBaseArticles.create,
    getOneKnowledgeBaseArticle: actionCreators.getOneKnowledgeBaseArticle.create,
    getIntroKnowledgeBaseArticle: actionCreators.getIntroKnowledgeBaseArticle.create,
    clearKnowledgeBaseArticle: actionCreators.clearKnowledgeBaseArticle.create,
    restoreUser: authActionCreators.restoreUser.create,
    sendFeedback: feedbackActionCreators.sendFeedback.create,
    clearFeedback: feedbackActionCreators.clearFeedback.create,
    getSubjects: feedbackActionCreators.getSubjects.create,
    startSubmit: startSubmit,
    stopSubmit: stopSubmit,
};

export default connect(mapStateToProps, dispatchToProps)(KnowledgeBasePage);
