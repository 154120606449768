// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { TableComponent, RedirectButton } from "../../../../common/components/Channels";
import { Dialog, Paper, Button, Typography } from "@material-ui/core";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import tableCss from "../../../../common/styles/table.css";

import {
    EditIcon,
    DeleteIcon,
    ArrowBackIcon,
    WarningIcon,
} from "../../../../common/components/icons";
import PromptDialog from "../../../../common/components/PromptDialog";

import { decorateTableItemsJobTypes } from "../../../../common/services/decorateTableItems";

import { MainTitle } from "../../../../common/components/MainTitle";
import { SubTitle } from "../../../../common/components/SubTitle";
import TabsComponent from "../../../../common/components/Tabs";

import { GetFormattedDate, GetFormattedTime } from "../../../../common/components/Time";

import {
    REPEATING_DAILY,
    REPEATING_WEEKLY,
    REPEAT_IN_TERM,
    REPEAT_IN_ACADEMIC_YEAR,
    REPEAT_N_TIMES,
    SESSION_BOOKABLE,
} from "../types";
import formsCss from "../../../../common/styles/forms.css";
import { spacing } from "../../../../common/styles/spacing";
import {
    GENERIC_AVAILABILITY,
    GENERIC_AVAILABILITY_ALERT_YELLOW,
    GENERIC_AVAILABILITY_ALERT_RED,
} from "../types";

import MessageForm from "../MessageForm";
import { colors } from "../../../../common/styles/colors";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            title: "Title",
            date: "Date",
            time: "Time",
            repeating: "Repeating configuration",
            sessions: "Sessions",
        },
    },
];

const buttons = (items, teamId) => index => [
    {
        path: `/bookings/availability/team/${teamId}/${GENERIC_AVAILABILITY}/edit/${
            items[index] ? items[index].id : ""
        }`,
        label: "Edit",
        fnLabel: "push",
        icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

const callbacks = {
    sessions: (item, prop) => {
        return (
            <div css={{ display: "flex", alignItems: "center" }}>
                <span>{item[prop]}</span>
                {item.sessionsAlert === GENERIC_AVAILABILITY_ALERT_YELLOW && (
                    <WarningIcon css={{ color: colors.accentC, marginLeft: 10, fontSize: 19 }} />
                )}
                {item.sessionsAlert === GENERIC_AVAILABILITY_ALERT_RED && (
                    <WarningIcon css={{ color: colors.accentLSE, marginLeft: 10, fontSize: 19 }} />
                )}
            </div>
        );
    },
};

const columns = ["id", "title", "date", "time", "repeating", "sessions"];

class OfficeHoursManageTeamGenericAvailabilityContainer extends Component {
    state = {
        removeDialog: false,
        removeDialogReason: false,
        removeId: null,
        initiated: false,
        displayCancellationSlots: false,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        this.setState({ removeDialog: false });
        if (confirmed === true) {
            this.props.getSlotsForAvailability(this.state.removeId);
        } else {
            this.setState({ removeId: null });
        }
    };

    onRemoveAvailability = values => {
        const { match } = this.props;
        this.props.removeMyOfficeHoursAvailability({
            id: this.state.removeId,
            teamId: this.props.team.id,
            reason: values.message,
            isGeneric: true,
        });
    };

    remove = itemID => {
        this.handleOpen(itemID);
    };

    componentDidMount() {
        const { setTitle, getOneMyOfficeHoursTeam, match, team } = this.props;
        setTitle("Bookings \u203A Manage Availability");
        if (!team) {
            getOneMyOfficeHoursTeam(match.params.teamId);
        } else {
            this.initComponent();
        }
    }

    initComponent() {
        const { team, getTeamGenericAvailabilities } = this.props;

        this.setState({ initiated: true }, () => {
            getTeamGenericAvailabilities({
                teamId: team.id,
            });
        });
    }

    componentDidUpdate(prevProps) {
        const { team, slotsForCancellation } = this.props;
        const { initiated } = this.state;
        if (!prevProps.team && team && team.id > 0 && prevProps.team !== team && !initiated) {
            this.initComponent();
        }

        if (
            slotsForCancellation !== prevProps.slotsForCancellation &&
            prevProps.slotsForCancellation === null
        ) {
            if (slotsForCancellation.length > 0) {
                // show cancellation popup
                this.props.clearLoading();
                this.setState({ removeDialogReason: true });
            } else {
                // hide popup - hide popup with empty cancellation msg
                this.onRemoveAvailability({ message: "" });
            }
        }
    }

    componentWillUnmount() {
        const { clearAvailabilities, clearTitle } = this.props;
        clearTitle();
        clearAvailabilities();
    }

    render() {
        const {
            availabilities,
            history: { push },
            team,
            match,
            slotsForCancellation,
            errorRes: { error, errors },
        } = this.props;
        const { remove } = this;
        const { displayCancellationSlots } = this.state;

        return team ? (
            <div>
                <MainTitle
                    title={team.name}
                    type={"[Availability]"}
                    rightElement={
                        <div>
                            <RedirectButton
                                label="Back to team"
                                url={`/bookings/availability/team?${team.id}`}
                                {...{ push }}
                                variant="text"
                                color="default"
                                elementLeft={<ArrowBackIcon css={formsCss.btnIcon} />}
                                css={{ marginRight: spacing.space2 }}
                            />
                        </div>
                    }
                />

                <Paper elevation={1}>
                    <TabsComponent
                        activeIndex={0}
                        tabs={[
                            {
                                label: "Availability",
                                selected: true,
                                fnLabel: "push",
                                param: `/bookings/availability/team/${team.id}/${GENERIC_AVAILABILITY}/manage`,
                            },
                            {
                                label: "Browse Team Sessions",
                                selected: false,
                                fnLabel: "push",
                                param: `/bookings/browse/team/${team.id}/${GENERIC_AVAILABILITY}`,
                            },
                        ]}
                        fns={{ push }}
                    />
                </Paper>

                <SubTitle title={"Generic Availabilities"} css={globalsCss.subTitleMarginMobile} />

                <div css={globalsCss.actions}>
                    <div css={globalsCss.actionsItem}>
                        <RedirectButton
                            label="Add new generic availability"
                            url={`/bookings/availability/team/${team.id}/${GENERIC_AVAILABILITY}/new`}
                            {...{ push }}
                        />
                    </div>
                </div>

                {availabilities &&
                    (availabilities.length > 0 ? (
                        <Paper elevation={1}>
                            <TableComponent
                                items={availabilities}
                                fns={{ push, remove }}
                                buttons={buttons(availabilities, team.id)}
                                decorators={decorators}
                                columns={columns}
                                callbacks={callbacks}
                            />
                        </Paper>
                    ) : (
                        <Paper elevation={1}>
                            <div css={globalsCss.innerSmall}>
                                You don’t have any generic availability set up for this team
                            </div>
                        </Paper>
                    ))}

                <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                    Are you sure you want to delete this availability rule?
                </PromptDialog>

                <Dialog
                    open={this.state.removeDialogReason}
                    onClose={() => {
                        this.setState({ removeDialogReason: false, removeId: null }, () =>
                            this.props.clearSlotsForCancellation()
                        );
                    }}
                    maxWidth="sm"
                    fullWidth
                >
                    {!!slotsForCancellation && (
                        <div css={globalsCss.innerDialog}>
                            <h2 css={globalsCss.subtitleTitle}>
                                Some of your existing appointments will be cancelled
                            </h2>

                            <p>
                                Saving this change will cancel{" "}
                                <strong>{slotsForCancellation.length} </strong> existing{" "}
                                {`appointment${slotsForCancellation.length > 1 ? "s" : ""}`}. Are
                                you sure?
                            </p>

                            {displayCancellationSlots ? (
                                <div>
                                    <Button
                                        color="secondary"
                                        onClick={() =>
                                            this.setState({ displayCancellationSlots: false })
                                        }
                                    >
                                        Hide{" "}
                                        {`appointment${slotsForCancellation.length > 1 ? "s" : ""}`}
                                    </Button>
                                    <div style={globalsCss.table}>
                                        <div style={globalsCss.tableHeaderItem}>
                                            <Typography
                                                component="div"
                                                variant="subtitle1"
                                                style={globalsCss.tableItemText}
                                            >
                                                <strong>Date &amp; Time</strong>
                                            </Typography>
                                            <Typography
                                                component="div"
                                                variant="subtitle1"
                                                style={globalsCss.tableItemText}
                                            >
                                                <strong>Student</strong>
                                            </Typography>
                                        </div>
                                        {slotsForCancellation.map((slot, index) => (
                                            <div key={`slot-${index}`} style={globalsCss.tableItem}>
                                                <Typography
                                                    component="div"
                                                    variant="subtitle1"
                                                    style={globalsCss.tableItemText}
                                                >
                                                    {`${GetFormattedDate(
                                                        slot.start
                                                    )} ${GetFormattedDate(slot.start, "h.mma")}`}
                                                </Typography>
                                                <Typography
                                                    component="div"
                                                    variant="subtitle1"
                                                    style={globalsCss.tableItemText}
                                                >
                                                    {slot.student}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <Button
                                    color="secondary"
                                    onClick={() =>
                                        this.setState({ displayCancellationSlots: true })
                                    }
                                >
                                    Show{" "}
                                    {`appointment${slotsForCancellation.length > 1 ? "s" : ""}`}
                                </Button>
                            )}
                            <p>
                                Please add a note so that students know why their appointments have
                                been cancelled. They will receive an email with your explanation.
                            </p>
                            <p>
                                Alternatively, please contact{" "}
                                <a href="mailto:studenthub@lse.ac.uk">studenthub@lse.ac.uk</a> if
                                you require any support.
                            </p>
                            {error && <div css={formsCss.genericError}>{error}</div>}
                            <MessageForm
                                {...{
                                    onSubmit: this.onRemoveAvailability,
                                    push,
                                    errors: {
                                        ...errors,
                                        ...(errors.reason ? { message: errors.reason } : {}),
                                    },
                                    fieldLabel: "Reason for cancellation",
                                    buttonLabel: "Confirm",
                                }}
                            />
                        </div>
                    )}
                </Dialog>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneMyOfficeHoursTeam: actionCreators.getOneMyOfficeHoursTeam.create,
    getTeamGenericAvailabilities: actionCreators.getTeamGenericAvailabilities.create,
    clearAvailabilities: actionCreators.clearAvailabilities.create,
    removeMyOfficeHoursAvailability: actionCreators.removeMyOfficeHoursAvailability.create,
    getSlotsForAvailability: actionCreators.getSlotsForAvailability.create,
    clearSlotsForCancellation: actionCreators.clearSlotsForCancellation.create,
    clearLoading: mainMenuActionCreators.clearLoading.create,
};

const getRepeatingDescription = item => {
    return item.repeatingRule
        ? item.repeatingRule.repeatEvery === 1
            ? item.repeatingRule.type === REPEATING_DAILY
                ? // daily- repeating x1
                  item.repeatingRule.finishType === REPEAT_IN_TERM
                    ? "daily in current term time"
                    : item.repeatingRule.finishType === REPEAT_IN_ACADEMIC_YEAR
                    ? "daily in term time until end of academic year"
                    : item.repeatingRule.finishType === REPEAT_N_TIMES
                    ? `daily ${item.repeatingRule.repeatTimes} times`
                    : `daily until ${GetFormattedDate(
                          item.repeatingRule.repeatDate,
                          "ddd D MMM YYYY"
                      )}`
                : item.repeatingRule.type === REPEATING_WEEKLY
                ? // weekly - repeating x1
                  item.repeatingRule.finishType === REPEAT_IN_TERM
                    ? "weekly in current term time"
                    : item.repeatingRule.finishType === REPEAT_IN_ACADEMIC_YEAR
                    ? "weekly in term time until end of academic year"
                    : item.repeatingRule.finishType === REPEAT_N_TIMES
                    ? `weekly ${item.repeatingRule.repeatTimes} times`
                    : `weekly until ${GetFormattedDate(
                          item.repeatingRule.repeatDate,
                          "ddd D MMM YYYY"
                      )}`
                : // monhtly - repeating x1
                item.repeatingRule.finishType === REPEAT_IN_TERM
                ? "monthly in current term time"
                : item.repeatingRule.finishType === REPEAT_IN_ACADEMIC_YEAR
                ? "monthly in term time until end of academic year"
                : item.repeatingRule.finishType === REPEAT_N_TIMES
                ? `monthly ${item.repeatingRule.repeatTimes} times`
                : `monthly until ${GetFormattedDate(
                      item.repeatingRule.repeatDate,
                      "ddd D MMM YYYY"
                  )}`
            : item.repeatingRule.repeatEvery > 1
            ? item.repeatingRule.type === REPEATING_DAILY
                ? // daily
                  item.repeatingRule.finishType === REPEAT_IN_TERM
                    ? `every ${item.repeatingRule.repeatEvery} days in current term time`
                    : item.repeatingRule.finishType === REPEAT_IN_ACADEMIC_YEAR
                    ? `every ${item.repeatingRule.repeatEvery} days in term time until end of academic year`
                    : item.repeatingRule.finishType === REPEAT_N_TIMES
                    ? `every ${item.repeatingRule.repeatEvery} days ${item.repeatingRule.repeatTimes} times`
                    : `every ${item.repeatingRule.repeatEvery} days until ${GetFormattedDate(
                          item.repeatingRule.repeatDate,
                          "ddd D MMM YYYY"
                      )}`
                : item.repeatingRule.type === REPEATING_WEEKLY
                ? // weekly
                  item.repeatingRule.finishType === REPEAT_IN_TERM
                    ? `every ${item.repeatingRule.repeatEvery} weeks in current term time`
                    : item.repeatingRule.finishType === REPEAT_IN_ACADEMIC_YEAR
                    ? `every ${item.repeatingRule.repeatEvery} weeks in term time until end of academic year`
                    : item.repeatingRule.finishType === REPEAT_N_TIMES
                    ? `every ${item.repeatingRule.repeatEvery} weeks ${item.repeatingRule.repeatTimes} times`
                    : `every ${item.repeatingRule.repeatEvery} weeks until ${GetFormattedDate(
                          item.repeatingRule.repeatDate,
                          "ddd D MMM YYYY"
                      )}`
                : // monhtly
                item.repeatingRule.finishType === REPEAT_IN_TERM
                ? `every ${item.repeatingRule.repeatEvery} months in current term time`
                : item.repeatingRule.finishType === REPEAT_IN_ACADEMIC_YEAR
                ? `every ${item.repeatingRule.repeatEvery} months in term time until end of academic year`
                : item.repeatingRule.finishType === REPEAT_N_TIMES
                ? `every ${item.repeatingRule.repeatEvery} months ${item.repeatingRule.repeatTimes} times`
                : `every ${item.repeatingRule.repeatEvery} months until ${GetFormattedDate(
                      item.repeatingRule.repeatDate,
                      "ddd D MMM YYYY"
                  )}`
            : "-"
        : "-";
};

const mapStateToProps = ({ OfficeHoursAvailabilities }) => ({
    team: OfficeHoursAvailabilities.team,
    availabilities: OfficeHoursAvailabilities.availabilities
        ? OfficeHoursAvailabilities.availabilities.map(item => {
              return {
                  id: item.id,
                  title: item.title,
                  date: GetFormattedDate(item.start, "ddd D MMM YYYY"),
                  time:
                      item.mode === SESSION_BOOKABLE
                          ? `${GetFormattedTime(item.start, "h.mma")} - ${GetFormattedTime(
                                item.end,
                                "h.mma"
                            )} (${item.slotsCount} x ${item.settings.duration} min)`
                          : `${GetFormattedTime(item.start, "h.mma")} - ${GetFormattedTime(
                                item.end,
                                "h.mma"
                            )} (drop-in)`,

                  repeating: getRepeatingDescription(item),
                  teamId: item.teamId,
                  sessions: item.numberOfSessions,
                  sessionsAlert:
                      item.numberOfSessionsWithoutAcademicWithBooking > 0
                          ? GENERIC_AVAILABILITY_ALERT_RED
                          : item.numberOfSessionsWithoutAcademic > 0
                          ? GENERIC_AVAILABILITY_ALERT_YELLOW
                          : null,
              };
          })
        : OfficeHoursAvailabilities.availabilities,
    errorRes: OfficeHoursAvailabilities.errorRes,
    slotsForCancellation: OfficeHoursAvailabilities.slotsForCancellation,
});

export default connect(
    mapStateToProps,
    dispatchToProps
)(OfficeHoursManageTeamGenericAvailabilityContainer);
