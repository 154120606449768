/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { FC, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import formsCss from "src/common/styles/forms.css";
import { useFormContext, Controller } from "react-hook-form";
import ItemListDownshift from "../Downshift/ItemListDownshift";

interface OwnProps {
    name: string;
    placeholder?: string;
    label?: string;
    classes: any;
    errorRes?: string;
    validateItem?: any;
    validateError?: any;
}

const RHItemsList: FC<OwnProps> = (
    { name, placeholder, label, classes, errorRes, validateItem, validateError, ...other },
    ref
) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <Fragment>
                        <ItemListDownshift
                            label={label}
                            placeholder={placeholder}
                            selectedItems={field.value || []}
                            onChange={field.onChange}
                            validateItem={validateItem}
                            validateError={validateError}
                        />
                        {(error || errorRes) && (
                            <div css={formsCss.genericError}>
                                {error && error.message ? error.message : errorRes ? errorRes : ""}
                            </div>
                        )}
                    </Fragment>
                );
            }}
        />
    );
};

const ItemsListField = withStyles(formsCss)(RHItemsList);

export default ItemsListField;
