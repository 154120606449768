import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, Fields, formValueSelector, change } from "redux-form";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import validate from "./validate";
import {
    RenderTextField,
    RenderFileInput,
    RenderTextDescription,
    RenderAutocomplete,
    RenderRadioGroup,
    RenderRadio,
} from "../../../../common/components/FormFields";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";

import Button from "@material-ui/core/Button";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";
import globalsCss from "../../../../common/styles/globals.css";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";
import * as config from "../values";

import { InfoTooltip } from "../../../../common/components/InfoTooltip";
import actionCreators from "../../../../store/Channels/Groups/actionCreators";

class Form extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const { getOwners } = this.props;
        getOwners(this.state.searchText);
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                rawUpdateListOnFilterChange({
                    getFn: getOwners,
                    filter: term,
                })
            );
        });
        this.props.initialize(this.props.initialValues);
    }

    componentDidUpdate({ groupType }) {
        if (groupType && groupType !== this.props.groupType) {
            if (this.props.groupType !== config.GROUP_TYPE_OPEN && !this.props.groupManagedBy) {
                this.props.dispatch(
                    change(this.props.form, "groupManagedBy", config.MANAGED_BY_OWNERS)
                );
            } else if (this.props.groupType === config.GROUP_TYPE_OPEN) {
                this.props.dispatch(change(this.props.form, "groupManagedBy", null));
            }
        }
    }

    updateSearchText(value) {
        const { searchText$ } = this.state;
        this.setState({ searchText: value }, () =>
            searchText$.next({ filter: this.state.searchText })
        );
    }

    onFilterChangeHandler = value => {
        this.updateSearchText(value);
    };

    removeOwner = id => () => {
        this.setState(
            {
                selectedOwners: this.state.selectedOwners.filter(owner => owner.id !== id),
            },
            () => this.props.updateOwners(this.state.selectedOwners)
        );
    };

    handleSelectOwner = e => {
        if (!this.state.selectedOwners.map(el => el.id).includes(e.id)) {
            this.setState(
                {
                    selectedOwners: [...this.state.selectedOwners, e],
                },
                () => this.props.updateOwners(this.state.selectedOwners)
            );
        }
        this.updateSearchText("");
    };

    render() {
        const { handleSubmit, push, owners, currentFormType, errors, groupType } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="name"
                            errorRes={errors.name}
                            type="name"
                            component={RenderTextField}
                            label="Name"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            name="description"
                            errorRes={errors.description}
                            type="text"
                            component={RenderTextDescription}
                            fullWidth
                            label="Description"
                        />
                    </div>
                </div>
                <hr />
                <h4 css={[globalsCss.helpers.mt35, globalsCss.helpers.mb20]}>Select group type</h4>
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            name="groupType"
                            errorRes={errors.calendarType}
                            component={RenderRadioGroup}
                            className="row"
                            row
                        >
                            <RenderRadio
                                value={config.GROUP_TYPE_OPEN}
                                label={
                                    <Fragment>
                                        <span css={formsCss.labelTitle}>Open group</span>
                                        <span css={formsCss.labelDesc}>
                                            Default group type. <br />
                                            Anyone can find the group, and see its content.
                                        </span>
                                    </Fragment>
                                }
                                className="col-lg-4 col-sm-3 col-xs-12"
                            />
                            <RenderRadio
                                value={config.GROUP_TYPE_CLOSED}
                                label={
                                    <Fragment>
                                        <span css={formsCss.labelTitle}>Closed Group</span>
                                        <span css={formsCss.labelDesc}>
                                            Anyone can find the group. <br />
                                            Users need to be invited or request access before they
                                            become members and can see content.
                                        </span>
                                    </Fragment>
                                }
                                className="col-lg-4 col-sm-3 col-xs-12"
                            />
                            <RenderRadio
                                value={config.GROUP_TYPE_PRIVATE}
                                label={
                                    <Fragment>
                                        <span css={formsCss.labelTitle}>Private Group</span>
                                        <span css={formsCss.labelDesc}>
                                            Only members can find the group. <br />
                                            Owners or members can invite users to join and become
                                            members.
                                        </span>
                                    </Fragment>
                                }
                                className="col-lg-4 col-sm-3 col-xs-12"
                            />
                        </Field>
                    </div>
                </div>
                {!groupType ||
                    (groupType !== config.GROUP_TYPE_OPEN && (
                        <div className="row">
                            <div className="col-xs-12">
                                <label>
                                    {groupType === config.GROUP_TYPE_PRIVATE && (
                                        <h4
                                            css={[globalsCss.helpers.mt35, globalsCss.helpers.mb20]}
                                        >
                                            Who can invite members:
                                        </h4>
                                    )}
                                    {groupType === config.GROUP_TYPE_CLOSED && (
                                        <h4
                                            css={[globalsCss.helpers.mt35, globalsCss.helpers.mb20]}
                                        >
                                            Who can accept or invite new members:
                                        </h4>
                                    )}
                                </label>
                                <Field
                                    name="groupManagedBy"
                                    errorRes={errors.calendarType}
                                    component={RenderRadioGroup}
                                    className="row"
                                    row
                                >
                                    <RenderRadio
                                        value={config.MANAGED_BY_OWNERS}
                                        label="Owners"
                                        className="col-lg-3 col-sm-3 col-xs-12"
                                    />
                                    <RenderRadio
                                        value={config.MANAGED_BY_MEMBERS}
                                        label="All members"
                                        className="col-lg-3 col-sm-3 col-xs-12"
                                    />
                                </Field>
                            </div>
                        </div>
                    ))}
                <hr />
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.uploadImage}>
                            <label css={formsCss.label}>
                                Cover Image <span css={formsCss.labelHelp}>(min. 747px/420px)</span>
                            </label>
                            <Fields
                                names={["imageFile", "imageUrl"]}
                                errorRes={errors.imageFile}
                                component={RenderFileInput}
                                onChangeCallback={() => this.props.clearErrors("imageFile")}
                                onClearCallback={() => this.props.clearErrors("imageFile")}
                            />
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.uploadImage}>
                            <label css={formsCss.label}>
                                Channel Picture (Icon){" "}
                                <span css={formsCss.labelHelp}>(min. 160px/160px)</span>
                            </label>
                            <Fields
                                names={["iconFile", "iconUrl"]}
                                errorRes={errors.iconFile}
                                component={RenderFileInput}
                                onChangeCallback={() => this.props.clearErrors("iconFile")}
                                onClearCallback={() => this.props.clearErrors("iconFile")}
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <h4 css={globalsCss.helpers.mt35}>Group Owners</h4>
                <div className="row">
                    <div className="col-sm-8 col-xs-12">
                        <div css={formsCss.inlineAlignTop}>
                            <Field
                                name="owners"
                                errorRes={errors.owners}
                                handleInput={this.onFilterChangeHandler}
                                dataSource={owners}
                                component={RenderAutocomplete}
                                onChangeCallback={() => this.props.clearErrors("owners")}
                            />
                            <InfoTooltip
                                mt={44}
                                text="LSE Staff or Students who can edit this group's settings and approve new members"
                            />
                        </div>
                    </div>
                </div>
                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        {currentFormType === currentFormTypes.NEW ? "Add New Group" : "Save Group"}
                    </Button>
                    <Button onClick={() => push("/channels/groups")} css={formsCss.btnBack}>
                        Back to the list
                    </Button>
                </div>
            </form>
        );
    }
}

const selector = formValueSelector("ChannelGroup");

const mapStateToProps = ({ form }) => ({
    groupType: selector({ form }, "groupType"),
});

const dispatchToProps = {
    clearErrors: actionCreators.clearErrors.create,
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(
    reduxForm({
        form: "ChannelGroup",
        enableReinitialize: true,
        validate,
    })(Form)
);
