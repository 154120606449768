import { Component, Fragment } from "react";
import { connect } from "react-redux";

import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as audienceActionCreators } from "../../../store/UsersAndAudiences/Audiences/actionCreators";
import { MainTitle } from "../../../common/components/MainTitle";
import { Card, CardHeader, IconButton, Tooltip } from "@material-ui/core";

import Form from "./components/Form";
import globalsCss from "../../../common/styles/globals.css";
import { DownloadIcon } from "../../../common/components/icons";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class EditAudienceContainer extends Component {
    state = {};

    componentDidMount() {
        const { getOneAudience, match } = this.props;
        this.props.setTitle("Audiences");
        getOneAudience({ id: match.params.id });
    }

    componentWillUnmount() {
        this.props.clearTitle();
        this.props.clearOneAudience();
    }

    onSubmit = values => {
        this.props.putAudience(values);
    };

    handleReportDownload = () => {
        this.props.downloadReport({ id: this.props.initialValues.id, extension: "xlsx" });
    };

    render() {
        const {
            initialValues,
            errorRes: { error, errors },
            history: { goBack },
        } = this.props;
        return (
            <div>
                <MainTitle title="Audiences" type="[Users &amp; Audiences]" />
                <Form
                    currentFormType="EDIT"
                    onSubmit={this.onSubmit}
                    {...{ initialValues, error, errors, goBack }}
                >
                    {initialValues.sourceFileName && (
                        <div className="row">
                            <div className="col-sm-4 col-xs-12" css={globalsCss.helpers.mt25}>
                                <Card>
                                    <CardHeader
                                        action={
                                            <Tooltip
                                                title="Download report"
                                                aria-label="Download report"
                                            >
                                                <IconButton onClick={this.handleReportDownload}>
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        title={initialValues.sourceFileName}
                                        subheader={
                                            <Fragment>
                                                <div>
                                                    <strong>{initialValues.numStudents}</strong> LSE
                                                    students
                                                </div>
                                                <div>
                                                    <strong>{initialValues.unmatched}</strong>{" "}
                                                    unmatched
                                                </div>
                                            </Fragment>
                                        }
                                    />
                                </Card>
                            </div>
                        </div>
                        // <div className="row">
                        //     <div className="col-xs-8">
                        //         {initialValues.sourceFileName} <strong>[TODO: UI styling]</strong>
                        //         <br />
                        //         <small>{initialValues.numStudents} LSE students</small>
                        //         <br />
                        //         <small>{initialValues.unmatched} unmatched</small>
                        //     </div>
                        //     <div className="col-xs-4">
                        //         <Button
                        //             onClick={this.handleReportDownload}
                        //             // variant="contained"
                        //             color="secondary"
                        //         >
                        //             <DownloadIcon
                        //                 css={{ ...formsCss.btnIcon }}
                        //             />
                        //             Download report
                        //         </Button>
                        //     </div>
                        // </div>
                    )}
                </Form>
            </div>
        );
    }
}

const dispatchToProps = {
    getOneAudience: audienceActionCreators.getOneAudience.create,
    clearOneAudience: audienceActionCreators.clearOneAudience.create,
    putAudience: audienceActionCreators.putAudience.create,
    downloadReport: audienceActionCreators.downloadAudienceReport.create,
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
};
const mapStateToProps = ({ Audience }) => ({
    errorRes: Audience.errorRes,
    initialValues: Audience.audience,
});
export default connect(mapStateToProps, dispatchToProps)(EditAudienceContainer);
