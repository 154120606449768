import { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import actionCreators from "../../../../store/PersistentEvents/actionCreators";
import mainMenuActionCreators from "../../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../../store/AutoComplete/actionCreators";
import { MainTitle } from "../../../../common/components/MainTitle";
import formsCss from "../../../../common/styles/forms.css";
import PersistentEventsForm from "../components/PersistentEventsForm";
import { GetFormattedDate } from "../../../../common/components/Time";
import { initialState } from "../../../../store/PersistentEvents/reducer";

/** @jsx jsx */
import { jsx } from "@emotion/core";

class PersistentEventsNewContainer extends Component {
    componentDidMount() {
        const { setTitle } = this.props;
        setTitle("LSE Calendar Events");
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    formSubmitHandler = values => {
        this.props.postPersistentEvents({
            ...values,
            startAt: values.isAllDay
                ? GetFormattedDate(values.startAt, "YYYY-MM-DD")
                : values.startAt,
            endAt: values.isAllDay ? GetFormattedDate(values.endAt, "YYYY-MM-DD") : values.endAt,
        });
    };

    render() {
        const {
            event,
            errorRes: { error, errors },
            changeEndAt,
            changeStartAt,
            getPages,
            getAudiences,
            updateAudience,
            formState,
            audiences,
            pages,
            getOwners,
            owners,
            currentUser,
        } = this.props;

        return (
            <div>
                <MainTitle title="Add LSE Calendar Event" />
                <div css={formsCss.genericError}>{error}</div>
                <PersistentEventsForm
                    submitText="Add Event"
                    subtitle="Add Calendar Event"
                    initialValues={{
                        ...initialState.event,
                        status: "draft",
                        owners: [
                            {
                                firstName: currentUser.firstName,
                                id: currentUser.id,
                                lastName: currentUser.lastName,
                            },
                        ],
                    }}
                    onSubmit={this.formSubmitHandler}
                    {...{
                        changeEndAt,
                        changeStartAt,
                        getPages,
                        getAudiences,
                        updateAudience,
                        formState,
                        audiences,
                        pages,
                        errors,
                        getOwners,
                        owners,
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ Auth, AutoComplete, PersistentEvents: { errorRes }, form }) => ({
    errorRes,
    formState: form.NewLseCalendarEvent,
    pages: AutoComplete.pages,
    audiences: AutoComplete.audiences,
    owners: AutoComplete.staff.map(owner =>
        Object.assign({ fullName: `${owner.firstName} ${owner.lastName}` }, owner)
    ),
    currentUser: {
        firstName: Auth.firstName,
        lastName: Auth.lastName,
        id: Auth.id,
    },
});

const dispatchToProps = {
    postPersistentEvents: actionCreators.postPersistentEvents.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    setTitle: mainMenuActionCreators.setTitle.create,
    getPages: AutoCompleteActionCreators.getPages.create,
    clearPages: AutoCompleteActionCreators.clearPages.create,
    getAudiences: AutoCompleteActionCreators.getAudiences.create,
    clearAudiences: AutoCompleteActionCreators.clearAudiences.create,
    changeEndAt: newValue => change("NewLseCalendarEvent", "endAt", newValue), // reset endAt value
    changeStartAt: newValue => change("NewLseCalendarEvent", "startAt", newValue), // reset startAt value
    updateAudience: (key, audience) => change("NewLseCalendarEvent", "audience." + key, audience),
    getOwners: AutoCompleteActionCreators.getStaffUsers.create,
};

export default connect(mapStateToProps, dispatchToProps)(PersistentEventsNewContainer);
