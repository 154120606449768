import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "./../../../store/UsersAndAudiences/LseStaff/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { prepareFilterQuery } from "../../../common/services/utils";
import { TableComponent, RedirectButton } from "../../../common/components/UsersAndAudiences";
import { updateListOnFilterChange } from "../../../common/services/FilterList";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { Button, Dialog, Paper } from "@material-ui/core";
import StaffFilterRow from "./components/StaffFilterRow";

import PromptDialog from "../../../common/components/PromptDialog";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import {
    permissionCallback,
    userStatusCallback,
    booleanTypeCallback,
} from "../../../common/services/tableCallbacks";
import { MainTitle } from "../../../common/components/MainTitle";
import formsCss from "../../../common/styles/forms.css";
import {
    EditIcon,
    DeleteIcon,
    AutoRenewIcon,
    DownloadIcon,
    FileUploadIcon,
    ErrorRoundedIcon,
} from "../../../common/components/icons";
import { hasAdminRole } from "../../../common/services/Auth";
import UploadForm from "./UploadForm";

const callbacks = {
    permissionLevel: permissionCallback,
    status: userStatusCallback,
    hasBookings: booleanTypeCallback,
};

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            permissionLevel: "User Type",
            hasBookings: "Can hold office hours?",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            lseId: tableCss.columnWidth250,
            hasBookings: tableCss.columnWidth200,
            firstName: tableCss.columnWidth250,
            lastName: tableCss.columnWidth250,
        },
    },
];

const buttons = items => index => {
    const results = [
        {
            path: `/users-and-audiences/lse-staff/edit/${items[index].id}`,
            label: "Edit",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
            hide: !items[index].id || items[index].status !== "pending",
        },
    ];

    if (items[index].status === "pending") {
        results.push({
            path: `${items[index].id}`,
            label: "Delete",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
            hide: !items[index].id || items[index].status !== "pending",
        });
    }

    return results;
};

class LseStaffListContainer extends Component {
    state = {
        filter: "",
        permission: "",
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
    };

    componentDidMount() {
        const { setTitle, getListStaff } = this.props;
        setTitle("LSE Staff");
        getListStaff(prepareFilterQuery(this.state.filter));
        const { filter$ } = this.state;
        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            updateListOnFilterChange({
                getFn: getListStaff,
                filter: term,
            });
        });
    }

    handler = ({ field, value }) => {
        const { filter$ } = this.state;
        this.setState({ [field]: value }, () =>
            filter$.next({
                filter: this.state.filter,
                permission: this.state.permission,
            })
        );
    };

    onFilterChangeHandler = field => event => {
        this.handler({ field, value: event.target.value });
    };

    onLoadMoreClickHandler = page => () => {
        const { filter } = this.state;
        this.props.loadMoreStaff(prepareFilterQuery({ filter, page }));
    };

    componentWillUnmount() {
        this.props.clearListStaff();
        this.props.clearTitle();
    }

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removeStaff({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    remove = itemID => {
        this.handleOpen(itemID);
    };

    onBulkUploadSubmit = values => {
        this.props.uploadStaffListXml({
            file: values.file,
        });
    };

    render() {
        const {
            users,
            count,
            nextPage,
            permissionLevel,
            toggleBulkUploadDialog,
            isBulkUploadDialogOpen,
            history: { push },
            errorRes,
        } = this.props;
        const { remove, onBulkUploadSubmit } = this;
        const { filter, permission } = this.state;

        users.forEach(i => {
            delete i.email;
            return i;
        });

        const downloadTemplateComponent = (
            <RedirectButton
                label="Download XLS template"
                onClick={() => this.props.downloadStaffListXmlTemplate()}
                {...{ push }}
                variant="text"
                color="default"
            />
        );

        return (
            <div>
                <MainTitle title="LSE Staff" type="[Users &amp; Audiences]" />
                <div css={[globalsCss.actions, globalsCss.actionsSpaceBetween]}>
                    <RedirectButton
                        label="Add new"
                        url="/users-and-audiences/lse-staff/new"
                        {...{ push }}
                    />

                    {hasAdminRole(permissionLevel) && (
                        <div>
                            <RedirectButton
                                label="Download as XLS"
                                onClick={() => this.props.downloadStaffList()}
                                {...{ push }}
                                variant="text"
                                color="default"
                                elementLeft={<DownloadIcon css={formsCss.btnIcon} />}
                            />

                            <RedirectButton
                                css={globalsCss.helpers.ml20}
                                label="Bulk Upload"
                                onClick={() => toggleBulkUploadDialog()}
                                {...{ push }}
                                variant="text"
                                color="default"
                                elementLeft={<FileUploadIcon css={formsCss.btnIcon} />}
                            />
                        </div>
                    )}
                </div>
                <Paper elevation={1}>
                    <StaffFilterRow
                        {...{
                            filter,
                            permission,
                        }}
                        onFilterChangeHandler={this.onFilterChangeHandler}
                    />
                    <TableComponent
                        items={users}
                        fns={{ push, remove }}
                        buttons={buttons(users)}
                        decorators={decorators}
                        callbacks={callbacks}
                    />
                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this entry?
                    </PromptDialog>
                </Paper>
                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {users.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}

                <Dialog
                    open={isBulkUploadDialogOpen}
                    onClose={toggleBulkUploadDialog}
                    maxWidth="sm"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>Bulk Upload Staff</h2>

                        {errorRes.error && (
                            <div css={formsCss.errorBox}>
                                <ErrorRoundedIcon css={formsCss.errorBoxIcon} />
                                <div>
                                    <div css={formsCss.errorBoxTitle}>{errorRes.error}</div>

                                    {errorRes.fileLine &&
                                        errorRes.propertyPath &&
                                        errorRes.invalidValue && (
                                            <div>
                                                <div>
                                                    <span>Row number: </span>
                                                    <strong>{errorRes.fileLine}</strong>
                                                </div>
                                                <div>
                                                    <span>Property: </span>
                                                    <strong>{errorRes.propertyPath}</strong>
                                                </div>
                                                <div>
                                                    <span>Invalid Value: </span>
                                                    <strong>{errorRes.invalidValue}</strong>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                        <UploadForm
                            {...{
                                onSubmit: onBulkUploadSubmit,
                                downloadTemplate: downloadTemplateComponent,
                                errors: {
                                    ...errorRes.errors,
                                },
                            }}
                        />
                    </div>
                </Dialog>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListStaff: actionCreators.getListStaff.create,
    loadMoreStaff: actionCreators.loadMoreStaff.create,
    clearListStaff: actionCreators.clearListStaff.create,
    removeStaff: actionCreators.removeStaff.create,
    downloadStaffList: actionCreators.downloadStaffList.create,
    downloadStaffListXmlTemplate: actionCreators.downloadStaffListXmlTemplate.create,
    uploadStaffListXml: actionCreators.uploadStaffListXml.create,
    toggleBulkUploadDialog: actionCreators.toggleBulkUploadDialog.create,
};
const mapStateToProps = state => ({
    users: state.LseStaff.users.data.map(d => {
        const { isOfficeHoursAdministrator, ...filtered } = d;
        return {
            ...filtered,
        };
    }),
    count: state.LseStaff.users.count,
    nextPage: state.LseStaff.users.nextPage,
    permissionLevel: state.Auth.permissionLevel,
    errorRes: state.LseStaff.errorRes,
    isBulkUploadDialogOpen: state.LseStaff.isBulkUploadDialogOpen,
});
export default connect(mapStateToProps, dispatchToProps)(LseStaffListContainer);
