import { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { RenderTextField } from "../../../../common/components/FormFields";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button } from "@material-ui/core";
import formsCss from "../../../../common/styles/forms.css";

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const { handleSubmit, errors, fieldLabel, buttonLabel } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            name="message"
                            errorRes={errors.message}
                            component={RenderTextField}
                            label={fieldLabel}
                            fullWidth
                            multiline
                            rows={5}
                            rowsMax={5}
                        />
                    </div>
                </div>

                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: "OfficeHoursMessage",
    enableReinitialize: true,
})(Form);

export default form;
