import { connect } from "react-redux";
import { default as froalaActionCreators } from "../../../store/Froala/actionCreators";
import { apiUrl } from "../../services/utils";
/** @jsx jsx */
import { jsx } from "@emotion/core";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
import "font-awesome/css/font-awesome.css";

import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins/emoticons.min.js";

import emojiConfig from "./helpers/FroalaEmoji";

import formsCss from "../../../common/styles/forms.css";
import { store } from "../../../store";

const getFroalaKey = () => {
    switch (window.location.hostname) {
        // UAT
        case "admin.studenthubdev.lse.ac.uk":
            return "jH2G2C2B4D-17D2E2B1B2E4G1B2B8C7B6nrpxJ-7yB3jnnxxpkhte1gasH4kF-7lD8joF5H-9d==";

        // LIVE
        case "admin.studenthub.lse.ac.uk":
            return "jH2G2C2B4D-17D2E2B1B2E4G1B2B8C7B6nrpxJ-7yB3jnnxxpkhte1E4hvcD3ijD8D-13g==";

        // DEV
        case "studenthub-backend-dev.dev123.eu":
            return "TB14A10A5E7kF4A3F3J4C6B7D6A3B3B2C-8pppbbfD-11bC-8nH4lnszwA-9pA1rrnA2E-11E-11jD3G3J3C7dw==";

        // TEST
        case "studenthub-backend-test.dev123.eu":
            return "TB14A10A5E7kF4A3F3J4C6B7D6A3B3B2C-8pppbbfD-11bC-8nH4lnszwA-9pA1brkmF3F-11ykA2A2B5C5gv==";

        default:
            return "wcegwqH-8bC-21mA-13C2yA-9C-8uzneC4gasH4cmC-21C2C2E1F5kz==";
    }
};

// ####################################
// 1. SIMPLE EDITOR (events posts)
// ####################################
const simpleConfigTpl = {
    toolbarButtons: [
        "undo",
        "redo",
        "sep",
        "bold",
        "italic",
        "underline",
        "insertLink",
        "paragraphFormat",
        "formatOL",
        "formatUL",
        "emoticons",
    ],
    paragraphFormat: {
        N: "Normal",
        // H1: "Heading 1",
        H2: "Heading 1",
        H3: "Heading 2",
        // H4: "Heading 4",
        // PRE: "Code",
    },
    height: 300,
    key: getFroalaKey(),
    quickInsertTags: [],
    quickInsertButtons: [],
    pastePlain: true, // remove style & formating on paste (e.g. word)
    pasteDeniedAttrs: ["class", "id", "style"],
    wordPasteModal: false,
    toolbarSticky: false,
    emoticonsUseImage: false,
    ...emojiConfig,
};

const simpleConfig = (updated = {}) => ({ ...simpleConfigTpl, ...updated });

export const RenderBasicEditor = ({ meta: { touched, error }, input, errorRes, ...custom }) => (
    <div>
        <FroalaEditor
            language="en_gb"
            onModelChange={input.onChange}
            model={input.value}
            config={{
                ...simpleConfig(),
                events: {
                    "froalaEditor.contentChanged": (e, editor) => {
                        const count = editor.charCounter.count();
                        if (custom.setCharCount) custom.setCharCount(count);
                    },
                },
            }}
            {...input}
            {...custom}
        />
        <span css={formsCss.genericError}>{touched && (error || errorRes)}</span>
    </div>
);

// ####################################
// 2. POST EDITOR (short posts)
// ####################################
const postConfigTpl = {
    videoInsertButtons: ["videoByURL"],
    toolbarButtons: [
        "undo",
        "redo",
        "sep",
        "bold",
        "italic",
        "underline",
        "insertLink",
        "paragraphFormat",
        "formatOL",
        "formatUL",
        "emoticons",
        "insertVideo",
    ],
    paragraphFormat: {
        N: "Normal",
        // H1: "Heading 1",
        H2: "Heading 1",
        H3: "Heading 2",
        // H4: "Heading 4",
        // PRE: "Code",
    },
    height: 300,
    key: getFroalaKey(),
    quickInsertTags: [],
    quickInsertButtons: [],
    pastePlain: true, // remove style & formating on paste (e.g. word)
    pasteDeniedAttrs: ["class", "id", "style"],
    wordPasteModal: false,
    toolbarSticky: false,
    emoticonsUseImage: false,
    ...emojiConfig,
};

const postConfig = (updated = {}) => ({ ...postConfigTpl, ...updated });

export const RenderPostEditor = ({ meta: { touched, error }, input, errorRes, ...custom }) => (
    <div>
        <FroalaEditor
            language="en_gb"
            onModelChange={input.onChange}
            model={input.value}
            config={{
                ...postConfig(),
                events: {
                    "froalaEditor.contentChanged": (e, editor) => {
                        const count = editor.charCounter.count();
                        if (custom.setCharCount) custom.setCharCount(count);
                    },
                },
            }}
            {...input}
            {...custom}
        />
        <span css={formsCss.genericError}>{touched && (error || errorRes)}</span>
    </div>
);

// ####################################
// 3. ARTICLE EDITOR (long-form posts)
// ####################################

const configArticle = (insertedCallback, removedCallback, knowledgeBaseEditor) => ({
    imageUploadURL: apiUrl("misc/upload"),
    imageUploadParams: {
        token: store.getState().Auth.token,
        ...(knowledgeBaseEditor
            ? {
                  resolution: "747xAuto",
              }
            : {}),
    },
    imageInsertButtons: ["imageUpload"],
    imageManagerDeleteURL: apiUrl("misc/upload"),
    imageManagerDeleteMethod: "DELETE",
    videoInsertButtons: ["videoByURL"],
    imageEditButtons: [
        // "imageReplace",
        // "imageAlign",
        // "imageRemove",
        // "|",
        // "imageLink",
        // "linkOpen",
        // "linkEdit",
        // "linkRemove",
        // "-",
        // "imageDisplay",
        // "imageStyle",
        "imageAlt",
        // "imageSize",
    ],
    videoEditButtons: [
        // "videoReplace",
        // "videoRemove",
        // "|",
        // "videoDisplay",
        // "videoAlign",
        // "videoSize",
    ],
    paragraphFormat: {
        N: "Normal",
        // H1: "Heading 1",
        H2: "Heading 1",
        H3: "Heading 2",
        // H4: "Heading 4",
        // PRE: "Code",
    },
    events: {
        "froalaEditor.image.inserted": (e, editor, $img) => {
            insertedCallback($img.attr("src"));
        },
        "froalaEditor.image.replaced": (e, editor, $img) => {
            insertedCallback($img.attr("src"));
        },
        "froalaEditor.image.removed": (e, editor, $img) => {
            removedCallback($img.attr("src"));
        },

        "froalaEditor.image.error": (e, editor, response, serverResponse) => {
            if (serverResponse) {
                const res = JSON.parse(serverResponse);
                if (res.errors.file) {
                    const $popup = editor.popups.get("image.insert");
                    const $layer = $popup.find(".fr-image-progress-bar-layer");
                    $layer.find("h3").text(res.errors.file);
                }
            }
        },
    },
    toolbarButtons: [
        // "fullscreen",
        "bold",
        "italic",
        "underline",
        // "strikeThrough",
        // "subscript",
        // "superscript",
        // "|",
        // "fontFamily",
        // "fontSize",
        // "color",
        // "inlineStyle",
        // "paragraphStyle",
        // "|",
        "paragraphFormat",
        // "align",
        "formatOL",
        "formatUL",
        "outdent",
        "indent",
        // "quote",
        "-",
        "insertLink",
        "insertImage",
        "insertVideo",
        "embedly",
        // "insertFile",
        // "insertTable",
        "|",
        "emoticons",
        "specialCharacters",
        "insertHR",
        // "selectAll",
        "clearFormatting",
        "|",
        // "print",
        "spellChecker",
        // "help",
        ...(knowledgeBaseEditor ? ["html"] : []),
        // "html",
        "|",
        "undo",
        "redo",
    ],
    height: 500,
    key: getFroalaKey(),
    quickInsertTags: [],
    quickInsertButtons: [],
    pastePlain: true,
    pasteDeniedAttrs: ["class", "id", "style"],
    wordPasteModal: false,
    toolbarSticky: false,
    emoticonsUseImage: false,
    ...(knowledgeBaseEditor ? { imageDefaultWidth: 0 } : {}),
    ...emojiConfig,
});

const dispatchToProps = {
    addFroalaImage: froalaActionCreators.addFroalaImage.create,
    deleteFroalaImage: froalaActionCreators.deleteFroalaImage.create,
};

export const RenderFroalaEditor = connect(
    null,
    dispatchToProps
)(
    ({
        meta: { touched, error },
        initModel,
        input,
        errorRes,
        addFroalaImage,
        deleteFroalaImage,
        ...custom
    }) => {
        return (
            <div>
                <FroalaEditor
                    language="en_gb"
                    onModelChange={input.onChange}
                    model={input.value !== "" ? input.value : initModel}
                    {...input}
                    {...custom}
                    config={{
                        ...configArticle(
                            addFroalaImage,
                            deleteFroalaImage,
                            !!custom.knowledgeBaseEditor
                        ),
                        events: {
                            "froalaEditor.contentChanged": (e, editor) => {
                                const count = editor.charCounter.count();
                                if (custom.setCharCount) custom.setCharCount(count);
                            },
                        },
                    }}
                />
                <span css={formsCss.genericError}>{touched && (error || errorRes)}</span>
            </div>
        );
    }
);

// ####################################
// 3. BASE EDITOR (events posts)
// ####################################
const baseConfigTpl = {
    toolbarButtons: ["insertLink"],
    height: 300,
    key: getFroalaKey(),
    quickInsertTags: [],
    quickInsertButtons: [],
    pastePlain: true, // remove style & formating on paste (e.g. word)
    pasteDeniedAttrs: ["class", "id", "style"],
    wordPasteModal: false,
    toolbarSticky: false,
    emoticonsUseImage: false,
    ...emojiConfig,
};

const baseConfig = (updated = {}) => ({ ...baseConfigTpl, ...updated });

export const RenderBaseEditor = ({ meta: { touched, error }, input, errorRes, ...custom }) => (
    <div>
        <FroalaEditor
            language="en_gb"
            onModelChange={input.onChange}
            model={input.value}
            config={{
                ...baseConfig(),
                events: {
                    "froalaEditor.contentChanged": (e, editor) => {
                        const count = editor.charCounter.count();
                        if (custom.setCharCount) custom.setCharCount(count);
                    },
                },
            }}
            {...input}
            {...custom}
        />
        <span css={formsCss.genericError}>{touched && (error || errorRes)}</span>
    </div>
);
