/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import actionCreators from "../../../../../store/OfficeHours/Reports/actionCreators";
import adminActionCreators from "../../../../../store/OfficeHours/Administrators/actionCreators";
import appointmentsActionCreators from "../../../../../store/OfficeHours/Appointments/actionCreators";
import {
    RenderSelectField,
    RenderDatepicker,
    RenderCheckboxField,
    RenderAutocomplete,
} from "../../../../../common/components/FormFields";
import { withStyles, Button } from "@material-ui/core";
import formsCss from "../../../../../common/styles/forms.css";
import globalsCss from "../../../../../common/styles/globals.css";
import { processSchoolYears } from "../../helpers/schoolYear";
import { validateRequiredFields } from "../../../../../common/services/formHelpers";

class AcademicAvailabilityForm extends Component {
    state = { filter: "" };

    componentDidMount() {
        const {
            schoolYears,
            gettOfficeHoursSchoolYearsAndTerms,
            getOneOfficeHoursAdministratorAcademics,
            clearErrorResponse,
            currentUser,
            academicsOptions,
        } = this.props;

        clearErrorResponse();

        if (schoolYears === null) {
            gettOfficeHoursSchoolYearsAndTerms();
        }

        if (currentUser && !academicsOptions) {
            getOneOfficeHoursAdministratorAcademics({ id: currentUser.id });
        }
        this.props.initialize(this.props.initialValues);
    }

    clearSchoolYearSelect = () => {
        this.props.updateForm("schoolyear", null);
    };

    handleChangeSchoolYear = (e, value) => {
        const dates = value.split("#");
        this.props.updateForm("from", dates[0]);
        this.props.updateForm("to", dates[1]);
    };

    handleChangeAcademic = (e, formAcademics) => {
        const { updateForm, currentUser } = this.props;
        const hasAdmin = (formAcademics || []).find(a => a.id === currentUser.id);
        if (!hasAdmin) updateForm("includeMyself", false);
    };

    handleIncludeMyself = (e, value) => {
        const { formAcademics, updateForm } = this.props;
        if (value === true) {
            updateForm("academics", (formAcademics || []).concat(this.getCurrentUserObject()));
        } else {
            updateForm(
                "academics",
                (formAcademics || []).filter(a => a.id !== this.getCurrentUserObject().id)
            );
        }
    };

    handleIncludeAll = () => {
        const { updateForm, formAcademics, currentUser } = this.props;
        const hasAdmin = (formAcademics || []).find(a => a.id === currentUser.id);
        const academics = this.getAcademicsOptions();
        if (hasAdmin) academics.push(this.getCurrentUserObject());
        updateForm("academics", academics);
    };

    onSubmit = values => {
        const academics = values.academics.map(a => a.id);
        this.props.createAcademicAvailabilityReport({ ...values, "academics[]": academics });
    };

    getCurrentUserObject = () => {
        const { currentUser } = this.props;
        return {
            id: currentUser.id,
            name: `${currentUser.firstName} ${currentUser.lastName}`,
        };
    };

    getAcademicsOptions = () => {
        const { academicsOptions } = this.props;
        if (!academicsOptions) return [];

        return academicsOptions.map(item => ({
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            email: item.email,
        }));
    };

    render() {
        const {
            handleSubmit,
            schoolYears,
            academicsOptions,
            errorRes: { error, errors },
        } = this.props;

        if (!academicsOptions) return null;

        if (schoolYears === null) return null;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Select Academic</label>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <Field
                            name="academics"
                            handleInput={filter => this.setState({ filter })}
                            dataSource={this.getAcademicsOptions().filter(
                                a => a.name.toLowerCase().search(this.state.filter) > -1
                            )}
                            component={RenderAutocomplete}
                            onChange={this.handleChangeAcademic}
                            labelCallback={obj => obj.name}
                        />
                        <div css={globalsCss.helpers.mt15}>
                            <Button onClick={this.handleIncludeAll}>Add All</Button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div css={globalsCss.helpers.mt20}>
                            <Field
                                name="includeMyself"
                                label="Include myself"
                                onChange={this.handleIncludeMyself}
                                component={RenderCheckboxField}
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div css={globalsCss.helpers.mt20}>
                    <h4>Time period</h4>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.labelBig}>Select year, term ... </div>
                        <Field
                            name="schoolyear"
                            component={RenderSelectField}
                            label="Select value"
                            errorRes={errors.schoolyear}
                            options={processSchoolYears(schoolYears)}
                            onChange={this.handleChangeSchoolYear}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div css={formsCss.labelBig}>...or time period</div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="from"
                            errorRes={errors.from}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                            css={formsCss.field}
                            onChange={this.clearSchoolYearSelect}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="to"
                            errorRes={errors.to}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                            css={formsCss.field}
                        />
                    </div>
                </div>
                {error && (
                    <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>{error}</div>
                )}
                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        Generate report
                    </Button>
                </div>
            </form>
        );
    }
}

const FORM_NAME = "OfficeHoursAcademicAvailability";

const validate = values => {
    const requiredFields = {
        from: "Required",
        to: "Required",
        academics: "Required",
    };

    if (!!values.from && !!values.to && values.from > values.to)
        return { to: "End date must be greater than start date" };

    return validateRequiredFields(requiredFields, values);
};

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
})(withStyles(formsCss)(AcademicAvailabilityForm));

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = ({
    form,
    Auth,
    OfficeHoursReport: { errorRes },
    OfficeHoursAppointments: { schoolYears },
    OfficeHoursAdministrators: { administrator },
}) => {
    return {
        formAcademics: selector({ form }, "academics"),
        schoolYears,
        academicsOptions: administrator.academics,
        currentUser: {
            firstName: Auth.firstName,
            lastName: Auth.lastName,
            id: Auth.id,
        },
        errorRes,
    };
};

const dispatchToProps = {
    updateForm: (key, newValue) => change(FORM_NAME, key, newValue),
    gettOfficeHoursSchoolYearsAndTerms:
        appointmentsActionCreators.gettOfficeHoursSchoolYearsAndTerms.create,
    getOneOfficeHoursAdministratorAcademics:
        adminActionCreators.getOneOfficeHoursAdministratorAcademics.create,
    createAcademicAvailabilityReport: actionCreators.createAcademicAvailabilityReport.create,
    clearErrorResponse: actionCreators.clearErrorResponse.create,
};

export default connect(mapStateToProps, dispatchToProps)(form);
