/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { FC, forwardRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";
import formsCss from "src/common/styles/forms.css";
import { useFormContext, Controller } from "react-hook-form";
import moment from "moment-timezone";
import { DatePicker } from "material-ui-pickers";
import { convertUtc2London, convertLondon2Utc } from "../../../common/services/parseDates";

interface OwnProps {
    name: string;
    placeholder?: string;
    label?: string;
    classes: any;
    errorRes?: string;
    myRef?: any;
}

const RHFDatepicker: FC<OwnProps & TextFieldProps> = forwardRef(
    ({ name, placeholder, label, classes, errorRes, myRef, ...other }, ref) => {
        const { control } = useFormContext();
        ref = myRef;

        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => {
                    const d = moment.tz("Europe/London").set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                    });
                    const now = moment(field.value ? field.value : d);

                    const londonOffset = now.tz("Europe/London").utcOffset();
                    const diff = londonOffset;

                    const roundTo5 = value => (value % 5 !== 0 ? Math.floor(value / 5) * 5 : value);

                    return (
                        <DatePicker
                            {...other}
                            {...field}
                            value={
                                field.value === null
                                    ? null
                                    : convertUtc2London(field.value).toDate()
                            }
                            onChange={value => {
                                let newValue;
                                const newTime = moment(value);

                                if (field.value) {
                                    // existng value deetcted - update only date
                                    newValue = moment(field.value);
                                    newValue.set("year", newTime.get("year"));
                                    newValue.set("month", newTime.get("month"));
                                    newValue.set("date", newTime.get("date"));
                                    newValue.set("minute", roundTo5(newTime.get("minute")));
                                } else {
                                    // no initial value - reset to now()
                                    newValue = convertLondon2Utc(
                                        field.value === null
                                            ? newTime
                                                  // .set("minute", roundTo5(newTime.get("minute")))
                                                  .utc()
                                                  .add(diff, "minutes")
                                                  .set({
                                                      hour: 0,
                                                      minute: 0,
                                                      second: 0,
                                                  })
                                                  .format()
                                            : newTime
                                                  // .set("minute", roundTo5(newTime.get("minute")))
                                                  .add(diff, "minutes")
                                                  .set({
                                                      hour: 0,
                                                      minute: 0,
                                                      second: 0,
                                                  })
                                                  .format()
                                    );
                                }
                                field.onChange(newValue && newValue.format());
                            }}
                            onDismiss={() => {
                                field.onBlur();
                            }}
                            format={"dddd D MMMM YYYY"}
                            onBlur={() => {
                                return;
                            }}
                            autoOk={true}
                            placeholder={placeholder ? placeholder : label ? label : ""}
                            label={label}
                            error={!!error || !!errorRes}
                            helperText={
                                error && error.message ? error.message : errorRes ? errorRes : ""
                            }
                            color="primary"
                            InputProps={{
                                classes: {
                                    root: classes.inputRoot,
                                    error: classes.inputError,
                                },
                            }}
                            inputRef={ref}
                        />
                    );
                }}
            />
        );
    }
);

const RenderDatepicker = withStyles(formsCss)(RHFDatepicker);

export default RenderDatepicker;
