import React, { FC, Fragment } from "react";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import globalsCss from "../../../common/styles/globals.css";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { StandardNotification, MessageType } from "../types/Notification";

interface OwnProps {
    notification: any;
    notificationEmailPreview?: any;
    notificationsEmails?: any;
}

export const NotificationPreview: FC<OwnProps> = ({
    notification: { type, subject, message, mobileMessage, sendAs, template, link, deeplinkType },
    notificationEmailPreview,
    notificationsEmails,
}) => {
    const identity = sendAs ? notificationsEmails.find(email => email.key === sendAs) : null;

    return (
        <Fragment>
            {/* EMAIL */}
            {(type === MessageType.EMAIL || type === MessageType.EMAIL_AND_MOBILE) && (
                <div>
                    <p>
                        1. <strong>E-mail</strong>
                    </p>

                    {identity && (
                        <p>
                            Sent as: <strong>{identity.name}</strong>
                            <em>{` <${identity.email}>`}</em>
                        </p>
                    )}
                    {subject && (
                        <p>
                            Subject:{" "}
                            <strong>
                                {notificationEmailPreview && notificationEmailPreview.subject
                                    ? notificationEmailPreview.subject
                                    : subject}
                            </strong>
                        </p>
                    )}

                    <Paper>
                        <div
                            css={globalsCss.innerPre}
                            dangerouslySetInnerHTML={{
                                __html:
                                    notificationEmailPreview && notificationEmailPreview.message
                                        ? notificationEmailPreview.message
                                        : message,
                            }}
                        />
                    </Paper>

                    {template && template.name && (
                        <p>
                            Template: <strong>{template.name}</strong>
                        </p>
                    )}
                </div>
            )}

            {/* MOBILE */}
            {(type === MessageType.MOBILE || type === MessageType.EMAIL_AND_MOBILE) && (
                <div>
                    <hr />
                    <p>
                        2. <strong>Mobile</strong>
                    </p>

                    <Paper>
                        <div
                            css={globalsCss.innerPre}
                            dangerouslySetInnerHTML={{
                                __html: mobileMessage ? mobileMessage : message,
                            }}
                        />
                    </Paper>

                    {deeplinkType && link && (
                        <p>
                            Link:{" "}
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to={
                                    deeplinkType === "page"
                                        ? `/channels/pages?filter=${link}`
                                        : deeplinkType === "post"
                                        ? `/channels/posts?filter=${link}`
                                        : deeplinkType === "event"
                                        ? `/channels/events?filter=${link}`
                                        : deeplinkType
                                }
                            >
                                {["page", "post", "event"].includes(deeplinkType)
                                    ? "show linked object"
                                    : deeplinkType}
                            </Link>
                        </p>
                    )}
                </div>
            )}
        </Fragment>
    );
};
