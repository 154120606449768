import { Component } from "react";
import { reduxForm, Field } from "redux-form";

import validate from "./validate";
import {
    RenderTextField,
    RenderRadioGroup,
    RenderFroalaEditor,
    RenderRadio,
} from "../../../../../common/components/FormFields";
import currentFormTypes from "../../../../../common/components/CurrentFormTypes";

import { Button, Paper } from "@material-ui/core";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../../common/styles/globals.css";
import formsCss from "../../../../../common/styles/forms.css";

const contentTypes = ["html", "url"];

class Form extends Component {
    state = {
        contentType: null,
        initialized: false,
    };

    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    componentDidUpdate() {
        const { initialValues } = this.props;
        const { contentType, initialized } = this.state;
        if (initialValues.type && initialValues.type !== contentType && !initialized)
            this.setState({ contentType: initialValues.type, initialized: true });
    }

    render() {
        const { handleSubmit, push, categoryId, currentFormType, errors } = this.props;
        const { contentType } = this.state;
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>
                        {currentFormType === currentFormTypes.NEW
                            ? "Add New Article"
                            : "Edit Article"}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Field
                            name="status"
                            errorRes={errors.status}
                            component={RenderRadioGroup}
                            row
                        >
                            <RenderRadio
                                value="live"
                                label="Publish live"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />

                            <RenderRadio
                                value="draft"
                                label="Save as draft"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                        </Field>
                        <hr />
                        <div className="row">
                            <div className="col-xs-12">
                                <Field
                                    name="title"
                                    errorRes={errors.title}
                                    type="text"
                                    component={RenderTextField}
                                    label="Title"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <hr />

                        <Field
                            name="type"
                            errorRes={errors.status}
                            component={RenderRadioGroup}
                            row
                            css={globalsCss.helpers.mt35}
                            onChange={() =>
                                this.setState(state => ({
                                    contentType:
                                        contentTypes[
                                            Number(
                                                !contentTypes.findIndex(
                                                    el => el === state.contentType
                                                )
                                            )
                                        ],
                                }))
                            }
                        >
                            <RenderRadio
                                value="html"
                                label="HTML Article"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                            <RenderRadio
                                value="url"
                                label="External URL"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                        </Field>
                        <div className="row">
                            <div className="col-xs-12">
                                {contentType === contentTypes[0] && (
                                    <div css={globalsCss.helpers.mt35}>
                                        <Field
                                            name="contentHtml"
                                            errorRes={errors.content}
                                            label="Content"
                                            component={RenderFroalaEditor}
                                            knowledgeBaseEditor
                                        />
                                    </div>
                                )}
                                {contentType === contentTypes[1] && (
                                    <div>
                                        <Field
                                            name="contentUrl"
                                            errorRes={errors.content}
                                            label="Link"
                                            component={RenderTextField}
                                            fullWidth
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div css={formsCss.actions}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                {currentFormType === currentFormTypes.NEW
                                    ? "Add New Article"
                                    : "Save Article"}
                            </Button>
                            <Button
                                onClick={() =>
                                    push(`/knowledge-base/categories/${categoryId}/articles`)
                                }
                                css={formsCss.btnBack}
                            >
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}
const form = reduxForm({
    form: "Article",
    enableReinitialize: true,
    validate,
})(Form);
export default form;
