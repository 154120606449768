import { spacing, fontWeight, colors } from "../common/UIGlobals";

export const knowledgeBaseStyle = {
    intro: {
        marginBottom: spacing.space4,
    },
    contentHeader: {
        // maxWidth: 520,
        width: "100%",
        paddingRight: 288,
        paddingLeft: 288,
        position: "relative",
        "@media (max-width: 1122px)": {
            maxWidth: "none",
            marginRight: 0,
            marginLeft: 0,
            paddingRight: 0,
            paddingLeft: 288,
        },
        "@media (max-width: 768px)": {
            paddingLeft: 0,
        },
    },
    contentContainer: {
        maxWidth: 520,
        width: "100%",
        marginRight: "auto",
        marginLeft: "auto",
        position: "relative",
        "@media (max-width: 1122px)": {
            maxWidth: "none",
            marginRight: 0,
            marginLeft: 0,
            paddingLeft: 288,
        },
        "@media (max-width: 768px)": {
            paddingLeft: 0,
        },
    },
    contentWiderContainer: {
        maxWidth: 800,
        width: "100%",
        marginRight: "auto",
        marginLeft: "auto",
        position: "relative",
    },
    columnContainer: {
        display: "flex",
        flexWrap: "nowrap",
        "@media (max-width: 768px)": {
            flexWrap: "wrap",
        },
    },
    columnLeft: {
        width: 288,
        order: 1,
        "@media (max-width: 768px)": {
            order: 3,
            width: "100%",
        },
    },
    columnCenter: {
        order: 2,
        width: "100%",
        flex: 1,
        marginRight: "auto",
        marginLeft: "auto",
        position: "relative",
        "@media (max-width: 1122px)": {
            marginRight: 0,
            marginLeft: 0,
        },
        "@media (max-width: 768px)": {
            width: "100%",
            paddingLeft: 0,
            order: 1,
        },
    },
    columnRight: {
        width: 288,
        paddingLeft: spacing.space4,
        order: 3,
        "@media (max-width: 1122px)": {
            display: "none",
            width: "100%",
            paddingLeft: 288,
        },
    },
    sidebarMenu: {
        width: 256,
        // position: "absolute",
        // top: 136,
        // right: "100%",
        "@media (max-width: 1122px)": {
            marginRight: 0,
            left: 0,
            right: "auto",
        },
        "@media (max-width: 768px)": {
            borderTop: "1px solid rgba(0,0,0,.12)",
            paddingTop: spacing.space3,
            width: "100%",
            marginTop: spacing.space3,
            position: "relative",
            left: "auto",
            top: "auto",
        },
    },
    helpAction: {
        backgroundColor: "rgba(93,205,192,0.12)",
        width: 256,
        paddingTop: spacing.space2comma5,
        paddingRight: spacing.space6,
        paddingBottom: spacing.space2comma5,
        paddingLeft: spacing.space2,
        position: "relative",
        // marginLeft: 40,
        // position: "absolute",
        // top: 136,
        // left: "100%",
        "@media (max-width: 1122px)": {
            marginTop: spacing.space3,
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
            top: "auto",
            left: "auto",
        },
        icon: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: colors.secondaryAlt2,
            width: 32,
            height: 32,
            paddingTop: spacing.space1,
            position: "absolute",
            top: -spacing.space0,
            right: spacing.space1,
        },
        iconShape: {
            position: "absolute",
            top: "100%",
            left: 0,
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "8px 32px 0 0",
            borderColor: "#30c0ad transparent transparent transparent",
        },
        title: {
            color: colors.darkSecondary,
            fontSize: 16,
            fontWeight: fontWeight.black,
            lineHeight: 1.56,
            letterSpacing: 0.2,
            marginBottom: spacing.space1comma5,
        },
        link: {
            color: colors.secondaryAlt,
            fontSize: 16,
            lineHeight: 1.56,
            letterSpacing: 0.2,
        },
    },
    title: {
        color: "rgba(0,0,0,0.8)",
        fontSize: 36,
        fontWeight: fontWeight.black,
        letterSpacing: 0.3,
        lineHeight: 1.2,
        margin: `0 0 ${spacing.space1comma5}px`,
    },
    date: {
        display: "block",
        color: "rgba(0,0,0,0.8)",
        fontSize: 12,
        letterSpacing: 0.1,
        lineHeight: 1.16,
        margin: `0 0 ${spacing.space3}px`,
    },
    showOnMobile: {
        display: "none",
        "@media (max-width: 1122px)": {
            display: "block",
        },
    },
};
