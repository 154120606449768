export const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    container: {
        flexGrow: 1,
        position: "relative",
    },
    paper: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        // right: 0,
        width: "auto",
        maxWith: "100%",
        "@media (max-width: 767px)": {
            width: "100%",
            right: 0,
        },
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    inputRoot: {
        flexWrap: "wrap",
        "&:before": {
            borderBottom: "2px solid rgba(0,0,0,.1)",
        },
        "&:hover:before": {
            borderBottomColor: "rgba(0,0,0,.2) !important",
        },
        "&:after": {
            borderBottomColor: "#E0112B",
        },
    },
    inputInput: {
        width: "auto",
        flexGrow: 1,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});
