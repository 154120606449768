import { Component } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { RenderSelectField } from "../../../../common/components/FormFields";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button } from "@material-ui/core";
import formsCss from "../../../../common/styles/forms.css";
import { validate } from "./validate";
import { connect } from "react-redux";

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const { handleSubmit, users, isAssigned, teamMemberId, onCancel } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            name="teamMemberId"
                            component={RenderSelectField}
                            label={teamMemberId ? "Team member" : "Select team member"}
                            options={users}
                        />
                    </div>
                </div>

                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        {isAssigned ? "Reassign" : "Assign"}
                    </Button>

                    <Button key="cancel" onClick={onCancel}>
                        Cancel
                    </Button>
                </div>
            </form>
        );
    }
}

const FORM_NAME = "OfficeHoursReassign";

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
})(Form);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = ({ form }) => ({
    teamMemberId: selector({ form }, "teamMemberId"),
});

export default connect(mapStateToProps, null)(form);
