import { of, empty } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../../MainMenu/actionCreators";
import errorHandler from "../../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../../common/services/utils";

const membersStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListMembers.type,
            actionCreators.loadMoreMembers.type,
            actionCreators.postGroupMembers.type,
            actionCreators.removeGroupMember.type,
            actionCreators.changeMemberRole.type,
            actionCreators.updatePendingMember.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const membersClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListMembers.type,
            actionCreators.updateListMembers.type,
            actionCreators.errorResponse.type,
            actionCreators.redirectToMembersList.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListMembersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListMembers.type),
        switchMap(({ payload: { groupId, param } }) =>
            ajax.get(ajax.apiUrl(`staff/groups/${groupId}/members/?${param ? param : ""}`)).pipe(
                mergeMap(({ response }) => of(actionCreators.createListMembers.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreMembersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreMembers.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/groups/${action.payload.groupId}/members/?${action.payload.param}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListMembers.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removeGroupMemberEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeGroupMember.type),
        switchMap(({ payload: { id, groupId } }) =>
            ajax
                .remove(ajax.apiUrl(`staff/groups/${groupId}/remove-user/${id}/`))
                .pipe(
                    mapTo(actionCreators.getListMembers.create({ groupId })),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const changeMemberRoleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.changeMemberRole.type),
        switchMap(({ payload: { id, groupId, type } }) =>
            ajax
                .post(ajax.apiUrl(`staff/groups/${groupId}/change-role/${id}/${type}/`))
                .pipe(
                    mapTo(actionCreators.getListMembers.create({ groupId })),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const updatePendingMemberEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updatePendingMember.type),
        switchMap(({ payload: { id, groupId, status } }) =>
            ajax
                .post(ajax.apiUrl(`staff/groups/${groupId}/pending-user/${id}/${status}/`))
                .pipe(
                    mapTo(actionCreators.getListMembers.create({ groupId })),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const postGroupMembersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postGroupMembers.type),
        switchMap(({ payload: { userId, groupId, isLast } }) =>
            ajax.post(ajax.apiUrl(`staff/groups/${groupId}/member/${userId}/`)).pipe(
                mergeMap(() =>
                    isLast
                        ? of(
                              actionCreators.redirectToMembersList.create({
                                  id: groupId,
                              })
                          )
                        : empty()
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const redirectToMembersListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.redirectToMembersList.type),
        mergeMap(({ payload: { id } }) => of(push(`/channels/groups/${id}/members`)))
    );

export const epics = combineEpics(
    membersStartLoadingEpic,
    membersClearLoadingEpic,
    getListMembersEpic,
    loadMoreMembersEpic,
    removeGroupMemberEpic,
    changeMemberRoleEpic,
    updatePendingMemberEpic,
    postGroupMembersEpic,
    redirectToMembersListEpic
);
