export default {
    statusActive: {
        fontWeight: "bold",
        color: "#007e19",
    },
    statusPending: {
        fontWeight: "bold",
        color: "#8e6600",
    },
    statusBlocked: {
        fontWeight: "bold",
        color: "#54595E",
    },
    statusFailed: {
        fontWeight: "bold",
        color: "#E0112B",
    },
};
