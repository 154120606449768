import actionCreators from "./actionCreators";
import { mergeLists, removeFromList } from "../helpers";
import { removeProp } from "../helpers";
import { PagedResponse, Dictionary } from "src/types/generic";
import { EmailTemplate } from "src/app/Notifications/types/EmailTemplate";
import { NotificationEmail } from "src/app/Notifications/types/NotificationEmail";
import { TimetableEmailPreview } from "src/app/Notifications/types/TimetableEmailPreview";
import { LseGroup } from "src/app/Notifications/types/LseGroups";
import { TeachingDetails } from "src/app/Notifications/types/TeachingDetails";
import {
    Notification,
    StandardNotification,
    TimetableNotification,
} from "src/app/Notifications/types/Notification";

export interface NotificationState {
    errorRes: { error: string; errors: Dictionary<string> };
    templates: PagedResponse<EmailTemplate[]>;
    template: EmailTemplate;
    notifications: PagedResponse<Notification[]>;
    notification: StandardNotification | TimetableNotification;
    notificationsEmails: null | NotificationEmail[];
    allStudents: boolean;
    notificationEmailPreview: null | TimetableEmailPreview;
    lseGroups: LseGroup[];
    teachingsDetails: PagedResponse<TeachingDetails[]>;
}

export const initialState = {
    notifications: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    notificationsEmails: null,
    notification: {
        type: "email",
        audience: {
            "students-broadcast": false,
            pages: [],
            students: [],
            audiences: [],
        },
        status: "draft",
        message: "",
        subject: "",
        scheduledAt: null,
        link: "",
        deeplinkType: "",
        sendAs: "",
        group: "*",
        teachings: ["*"],
        additionalEmailText: "",
    },
    notificationEmailPreview: null,
    allStudents: false,
    templates: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    template: {
        id: null,
    },
    lseGroups: [],
    teachingsDetails: {
        data: [],
        count: null,
        nextPage: 0,
    },
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateNotifications.type:
            return {
                ...state,
                notifications: payload,
                notification: initialState.notification,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateMoreNotifications.type:
            return {
                ...state,
                notifications: {
                    ...payload,
                    data: mergeLists(state.notifications.data, payload.data),
                },
                notification: initialState.notification,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateDeletedNotification.type:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    data: state.notifications.data.filter(({ id }) => id !== payload),
                },
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateNotificationEmails.type:
            return {
                ...state,
                notificationsEmails: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.setNotification.type:
            return {
                ...state,
                notification: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.clearNotification.type:
            return {
                ...state,
                notification: initialState.notification,
            };
        case actionCreators.setTemplateList.type:
            return { ...state, templates: payload };
        case actionCreators.updateTemplateList.type:
            return {
                ...state,
                templates: {
                    ...payload,
                    data: mergeLists(state.templates.data, payload.data),
                },
            };
        case actionCreators.setTemplate.type:
            return {
                ...state,
                template: payload,
            };
        case actionCreators.postRemoveTemplate.type:
            return {
                ...state,
                templates: removeFromList(state.templates, payload),
            };
        case actionCreators.setNotificationEmailPreview.type:
            return {
                ...state,
                notificationEmailPreview: payload,
            };
        case actionCreators.setLseModuleGroups.type:
            return {
                ...state,
                lseGroups: payload,
            };
        case actionCreators.updateSelectedTeachingsStudents.type:
            payload.data = [
                ...state.teachingsDetails.data,
                ...payload.data.filter(
                    row =>
                        !state.teachingsDetails.data
                            .map((item: TeachingDetails) => item.playRef)
                            .includes(row.playRef)
                ),
            ];
            return {
                ...state,
                teachingsDetails: payload,
            };
        case actionCreators.clearSelectedTeachingsStudents.type:
            return {
                ...state,
                teachingsDetails: initialState.teachingsDetails,
            };
        case actionCreators.clearErrors.type:
            return {
                ...state,
                errorRes: state.errorRes.errors[payload]
                    ? {
                          ...state.errorRes,
                          errors: removeProp(state.errorRes.errors, payload),
                      }
                    : state.errorRes,
            };
        default:
            return state;
    }
};
