import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "./../../../store/UsersAndAudiences/LseStudents/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { prepareFilterQuery } from "../../../common/services/utils";
import { TableComponent } from "../../../common/components/UsersAndAudiences";
import { updateListOnFilterChange } from "../../../common/services/FilterList";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PromptDialog from "../../../common/components/PromptDialog";
import FilterRow from "../../../common/components/FilterRow";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import tableCss from "../../../common/styles/table.css";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import {
    permissionCallback,
    userStatusCallback,
    boolCallback,
} from "../../../common/services/tableCallbacks";
import { MainTitle } from "../../../common/components/MainTitle";
import formsCss from "../../../common/styles/forms.css";
import { EditIcon, DeleteIcon, AutoRenewIcon, MemberIcon } from "../../../common/components/icons";

const callbacks = {
    permissionLevel: permissionCallback,
    status: userStatusCallback,
    isBackendUser: boolCallback,
};

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            isBackendUser: "Is staff",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            photoUrl: tableCss.img,
            lseId: tableCss.columnWidth150,
            isBackendUser: tableCss.columnWidth150,
            firstName: tableCss.columnWidth200,
            lastName: tableCss.columnWidth200,
        },
    },
];

const buttons = items => index => {
    const results = [
        {
            path: `/users-and-audiences/lse-students/edit/${items[index] ? items[index].id : ""}`,
            label: "Edit student profile",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
        },

        {
            path: `${items[index] ? items[index].id : null}`,
            label: "Make staff",
            fnLabel: "upgradeToStaff",
            icon: <MemberIcon css={tableCss.actionsMenuItemIcon} />,
            hide: items[index].isBackendUser,
        },
    ];

    if (items[index].isBackendUser) {
        results.push({
            path: `/users-and-audiences/lse-staff/edit/${items[index] ? items[index].id : ""}`,
            label: "Edit staff profile",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
            hide: !items[index].isBackendUser,
        });
    }

    if (!!items[index].playRef && items[index].status === "deleted") {
        results.push({
            path: `${items[index] ? items[index].id : null}`,
            label: "Remove playref ",
            fnLabel: "removePlayref",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        });
    } else if (items[index].status !== "deleted") {
        results.push({
            path: `${items[index] ? items[index].id : null}`,
            label: "Delete (archive)",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        });
    }

    return results;
};

const columns = [
    "id",
    "lseId",
    "firstName",
    "lastName",
    "email",
    "photoUrl",
    "status",
    "isBackendUser",
];

class LseStudentsListContainer extends Component {
    state = {
        filter: "",
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
    };

    componentDidMount() {
        const { setTitle, getListStudents } = this.props;
        const { filter$ } = this.state;
        setTitle("LSE Students");
        getListStudents(prepareFilterQuery(this.state.filter));
        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                updateListOnFilterChange({
                    getFn: getListStudents,
                    filter: term,
                })
            );
        });
    }

    onFilterChangeHandler = ({ target: { value } }) => {
        const { filter$ } = this.state;
        this.setState({ filter: value }, () => filter$.next({ filter: this.state.filter }));
    };

    onLoadMoreClickHandler = page => () => {
        const { filter } = this.state;
        this.props.loadMoreStudents(prepareFilterQuery({ filter, page }));
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removeStudent({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    remove = itemId => {
        this.handleOpen(itemId);
    };

    removePlayref = itemId => {
        this.props.removeStudent({ id: itemId });
    };

    componentWillUnmount() {
        this.props.clearListStudents();
        this.props.clearTitle();
    }

    render() {
        const {
            users,
            count,
            nextPage,
            error,
            history: { push },
            upgradeToStaff,
        } = this.props;
        const { remove, removePlayref } = this;

        return (
            <div>
                <MainTitle title="LSE Students" type="[Users &amp; Audiences]" />

                {error && <div css={formsCss.genericError}>{error}</div>}
                <Paper elevation={1}>
                    <FilterRow
                        filter={this.state.filter}
                        onFilterChangeHandler={this.onFilterChangeHandler}
                    />
                    <TableComponent
                        items={users}
                        fns={{ push, remove, removePlayref, upgradeToStaff }}
                        buttons={buttons(users)}
                        decorators={decorators}
                        callbacks={callbacks}
                        columns={columns}
                    />
                </Paper>

                <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                    Are you sure you want to delete user?
                </PromptDialog>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {users.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}
const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListStudents: actionCreators.getListStudents.create,
    loadMoreStudents: actionCreators.loadMoreStudents.create,
    clearListStudents: actionCreators.clearListStudents.create,
    removeStudent: actionCreators.removeStudent.create,
    upgradeToStaff: actionCreators.upgradeToStaff.create,
};
const mapStateToProps = state => ({
    users: state.LseStudents.users.data.map(i => {
        const {
            id,
            lseId,
            firstName,
            lastName,
            email,
            photoUrl,
            status,
            isBackendUser,
            playRef,
        } = i;
        return {
            id,
            lseId,
            firstName,
            lastName,
            email,
            photoUrl,
            status,
            isBackendUser,
            playRef,
        };
    }),
    count: state.LseStudents.users.count,
    nextPage: state.LseStudents.users.nextPage,
    error: state.LseStudents.errorRes.error,
});
export default connect(mapStateToProps, dispatchToProps)(LseStudentsListContainer);
