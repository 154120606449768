import React, { useState } from "react";
import * as queryString from "query-string";

export const withQueryParams = WrappedComponent => ({ ...props }) => {
    const [stateParams, setStatePatams] = useState({});
    const { location } = props;

    // ¯\_(ツ)_/¯  history.replace doesn't work
    const publishWindowParams = params => {
        window.history.replaceState(
            null,
            null,
            `${location.pathname}?${queryString.stringify(params)}`
        );
    };
    const init = params => {
        const queryParams = queryString.parse(location.search);

        let newParams = {};
        let forceRefresh = false;
        Object.entries(params).forEach(([key, value]) => {
            if (!queryParams[key] || queryParams[key] !== value) forceRefresh = true;
            newParams[key] = queryParams[key]
                ? typeof value === "number"
                    ? parseInt(queryParams[key])
                    : typeof value === "boolean"
                    ? queryParams[key] === "true"
                        ? true
                        : false
                    : queryParams[key]
                : value;
        });

        if (forceRefresh) {
            publishWindowParams(newParams);
        }
        setStatePatams(newParams);

        return newParams;
    };

    const set = params => {
        let newParams = { ...stateParams };
        Object.entries(params).forEach(([key, value]) => {
            newParams[key] = value;
        });
        setStatePatams(newParams);
        publishWindowParams(newParams);

        return newParams;
    };

    const get = () => stateParams;

    return (
        <WrappedComponent
            {...props}
            initWithQueryParams={init}
            setQueryParams={set}
            getQueryParams={get}
        />
    );
};
