import emojiConfig from "./WyswiygEmojis";
import { apiUrl } from "src/common/services/utils";

const getFroalaKey = () => {
    switch (window.location.hostname) {
        // UAT
        case "admin.studenthubdev.lse.ac.uk":
            return "jH2G2C2B4D-17D2E2B1B2E4G1B2B8C7B6nrpxJ-7yB3jnnxxpkhte1gasH4kF-7lD8joF5H-9d==";

        // LIVE
        case "admin.studenthub.lse.ac.uk":
            return "jH2G2C2B4D-17D2E2B1B2E4G1B2B8C7B6nrpxJ-7yB3jnnxxpkhte1E4hvcD3ijD8D-13g==";

        // DEV
        case "backend.lse.dev123.eu":
            return "TB14A10A5E7kF4A3F3J4C6B7D6A3B3B2C-8pppbbfD-11bC-8nH4lnszwA-9pA1rrnA2E-11E-11jD3G3J3C7dw==";

        // TEST
        case "studenthub-backend-test.dev123.eu":
            return "TB14A10A5E7kF4A3F3J4C6B7D6A3B3B2C-8pppbbfD-11bC-8nH4lnszwA-9pA1brkmF3F-11ykA2A2B5C5gv==";

        default:
            return "wcegwqH-8bC-21mA-13C2yA-9C-8uzneC4gasH4cmC-21C2C2E1F5kz==";
    }
};

export const simpleConfig = () => {
    return {
        toolbarButtons: [
            "undo",
            "redo",
            "sep",
            "bold",
            "italic",
            "underline",
            "insertLink",
            "paragraphFormat",
            "formatOL",
            "formatUL",
            "emoticons",
        ],
        paragraphFormat: {
            N: "Normal",
            // H1: "Heading 1",
            H2: "Heading 1",
            H3: "Heading 2",
            // H4: "Heading 4",
            // PRE: "Code",
        },
        height: 300,
        key: getFroalaKey(),
        quickInsertTags: [],
        quickInsertButtons: [],
        pastePlain: true, // remove style & formating on paste (e.g. word)
        pasteDeniedAttrs: ["class", "id", "style"],
        wordPasteModal: false,
        toolbarSticky: false,
        emoticonsUseImage: false,
        ...emojiConfig,
    };
};

export const postConfig = () => {
    return {
        videoInsertButtons: ["videoByURL"],
        toolbarButtons: [
            "undo",
            "redo",
            "sep",
            "bold",
            "italic",
            "underline",
            "insertLink",
            "paragraphFormat",
            "formatOL",
            "formatUL",
            "emoticons",
            "insertVideo",
        ],
        paragraphFormat: {
            N: "Normal",
            // H1: "Heading 1",
            H2: "Heading 1",
            H3: "Heading 2",
            // H4: "Heading 4",
            // PRE: "Code",
        },
        height: 300,
        key: getFroalaKey(),
        quickInsertTags: [],
        quickInsertButtons: [],
        pastePlain: true, // remove style & formating on paste (e.g. word)
        pasteDeniedAttrs: ["class", "id", "style"],
        wordPasteModal: false,
        toolbarSticky: false,
        emoticonsUseImage: false,
        ...emojiConfig,
    };
};

export const articleConfig = (insertedCallback, removedCallback, apiToken) => ({
    imageUploadURL: apiUrl("misc/upload"),
    imageUploadParams: { token: apiToken },
    imageInsertButtons: ["imageUpload"],
    imageManagerDeleteURL: apiUrl("misc/upload"),
    imageManagerDeleteMethod: "DELETE",
    videoInsertButtons: ["videoByURL"],
    imageEditButtons: [
        // "imageReplace",
        // "imageAlign",
        // "imageRemove",
        // "|",
        // "imageLink",
        // "linkOpen",
        // "linkEdit",
        // "linkRemove",
        // "-",
        // "imageDisplay",
        // "imageStyle",
        "imageAlt",
        // "imageSize",
    ],
    videoEditButtons: [
        // "videoReplace",
        // "videoRemove",
        // "|",
        // "videoDisplay",
        // "videoAlign",
        // "videoSize",
    ],
    paragraphFormat: {
        N: "Normal",
        // H1: "Heading 1",
        H2: "Heading 1",
        H3: "Heading 2",
        // H4: "Heading 4",
        // PRE: "Code",
    },
    events: {
        "froalaEditor.image.inserted": (e, editor, $img) => {
            insertedCallback($img.attr("src/"));
        },
        "froalaEditor.image.replaced": (e, editor, $img) => {
            insertedCallback($img.attr("src/"));
        },
        "froalaEditor.image.removed": (e, editor, $img) => {
            removedCallback($img.attr("src/"));
        },
    },
    toolbarButtons: [
        // "fullscreen",
        "bold",
        "italic",
        "underline",
        // "strikeThrough",
        // "subscript",
        // "superscript",
        // "|",
        // "fontFamily",
        // "fontSize",
        // "color",
        // "inlineStyle",
        // "paragraphStyle",
        // "|",
        "paragraphFormat",
        // "align",
        "formatOL",
        "formatUL",
        "outdent",
        "indent",
        // "quote",
        "-",
        "insertLink",
        "insertImage",
        "insertVideo",
        "embedly",
        // "insertFile",
        // "insertTable",
        "|",
        "emoticons",
        "specialCharacters",
        "insertHR",
        // "selectAll",
        "clearFormatting",
        "|",
        // "print",
        "spellChecker",
        // "help",
        // "html",
        "|",
        "undo",
        "redo",
    ],
    height: 500,
    key: getFroalaKey(),
    quickInsertTags: [],
    quickInsertButtons: [],
    pastePlain: true,
    pasteDeniedAttrs: ["class", "id", "style"],
    wordPasteModal: false,
    toolbarSticky: false,
    emoticonsUseImage: false,
    ...emojiConfig,
});

export const baseConfig = () => {
    return {
        toolbarButtons: ["insertLink"],
        height: 300,
        key: getFroalaKey(),
        quickInsertTags: [],
        quickInsertButtons: [],
        pastePlain: true, // remove style & formating on paste (e.g. word)
        pasteDeniedAttrs: ["class", "id", "style"],
        wordPasteModal: false,
        toolbarSticky: false,
        emoticonsUseImage: false,
        ...emojiConfig,
    };
};
