/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change } from "redux-form";
import actionCreators from "../../../../../store/OfficeHours/Reports/actionCreators";
import autoCompleteActionCreators from "../../../../../store/AutoComplete/actionCreators";
import appointmentsActionCreators from "../../../../../store/OfficeHours/Appointments/actionCreators";
import {
    RenderSelectField,
    RenderDatepicker,
    RenderAutocomplete,
} from "../../../../../common/components/FormFields";
import { withStyles, Button } from "@material-ui/core";
import formsCss from "../../../../../common/styles/forms.css";
import globalsCss from "../../../../../common/styles/globals.css";
import { processSchoolYears } from "../../helpers/schoolYear";
import { validateRequiredFields } from "../../../../../common/services/formHelpers";
import { Subject } from "rxjs";
import { rawUpdateListOnFilterChange } from "../../../../../common/services/FilterList";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";

class TeamHistoryForm extends Component {
    filter$ = new Subject();

    componentDidMount() {
        const {
            schoolYears,
            gettOfficeHoursSchoolYearsAndTerms,
            getOfficeHoursTeams,
            clearErrorResponse,
        } = this.props;
        clearErrorResponse();
        if (schoolYears === null) {
            gettOfficeHoursSchoolYearsAndTerms();
        }
        this.subscription = this.filter$
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(filter => {
                rawUpdateListOnFilterChange({
                    getFn: getOfficeHoursTeams,
                    filter: { filter },
                });
            });

        this.props.initialize(this.props.initialValues);
    }

    componentWillUnmount() {
        this.props.setOfficeHoursTeams([]);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    clearSchoolYearSelect = () => {
        this.props.updateForm("schoolyear", null);
    };

    handleModuleAutoComplete = filter => {
        this.filter$.next(filter);
    };

    handleChangeSchoolYear = (e, value) => {
        const dates = value.split("#");
        this.props.updateForm("from", dates[0]);
        this.props.updateForm("to", dates[1]);
    };

    onSubmit = values => {
        const { schoolyear, teamId, ...rest } = values;
        this.props.createOfficeTeamReport({ teamId: teamId.id, ...rest });
    };

    render() {
        const {
            handleSubmit,
            schoolYears,
            officeHoursTeams,
            errorRes: { error, errors },
        } = this.props;

        if (schoolYears === null) return null;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelBig}>Select Team</label>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <Field
                            name="teamId"
                            handleInput={this.handleModuleAutoComplete}
                            dataSource={officeHoursTeams}
                            component={RenderAutocomplete}
                            single
                            floatingLabelText="Select Team"
                            labelCallback={obj => obj.name}
                        />
                    </div>
                </div>
                <div css={globalsCss.helpers.mt20}>
                    <h4>Time period</h4>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.labelBig}>Select year, term ... </div>
                        <Field
                            name="schoolyear"
                            component={RenderSelectField}
                            label="Select value"
                            errorRes={errors.schoolyear}
                            options={processSchoolYears(schoolYears)}
                            onChange={this.handleChangeSchoolYear}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <div css={formsCss.labelBig}>...or time period</div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="from"
                            errorRes={errors.from}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                            css={formsCss.field}
                            onChange={this.clearSchoolYearSelect}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="to"
                            errorRes={errors.to}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                            css={formsCss.field}
                        />
                    </div>
                </div>

                {error && (
                    <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>{error}</div>
                )}

                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        Generate report
                    </Button>
                </div>
            </form>
        );
    }
}

const FORM_NAME = "OfficeHoursReportTeamHistory";

const validate = values => {
    const requiredFields = {
        teamId: "Select team",
        from: "Required",
        to: "Required",
    };

    if (!!values.from && !!values.to && values.from > values.to)
        return { to: "End date must be greater than start date" };

    return validateRequiredFields(requiredFields, values);
};

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
})(withStyles(formsCss)(TeamHistoryForm));

const mapStateToProps = ({
    AutoComplete: { officeHoursTeams },
    OfficeHoursReport: { errorRes },
    OfficeHoursAppointments: { schoolYears },
}) => ({
    schoolYears,
    officeHoursTeams,
    errorRes,
});

const dispatchToProps = {
    updateForm: (key, newValue) => change(FORM_NAME, key, newValue),
    gettOfficeHoursSchoolYearsAndTerms:
        appointmentsActionCreators.gettOfficeHoursSchoolYearsAndTerms.create,
    getOfficeHoursTeams: autoCompleteActionCreators.getOfficeHoursTeams.create,
    setOfficeHoursTeams: autoCompleteActionCreators.setOfficeHoursTeams.create,
    clearErrorResponse: actionCreators.clearErrorResponse.create,
    createOfficeTeamReport: actionCreators.createOfficeTeamReport.create,
};

export default connect(mapStateToProps, dispatchToProps)(form);
