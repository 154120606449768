import actionCreators from "./actionCreators";
import { mergeLists } from "../helpers";
import { prepareFilterQuery } from "../../common/services/utils";

export const initialState = {
    events: {
        data: [],
        count: 0,
        nextPage: 0,
        queryParams: "",
    },
    event: {
        id: null,
        name: null,
        location: null,
        notes: null,
        startAt: null,
        endAt: null,
        isAllDay: false,
        customAudiences: [],
        channels: [],
    },
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updatePersistentEvents.type:
            return {
                ...state,
                events: { ...payload, queryParams: state.events.queryParams },
                event: initialState.event,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateMorePersistentEvents.type:
            return {
                ...state,
                events: {
                    ...payload,
                    queryParams: state.events.queryParams,
                    data: mergeLists(state.events.data, payload.data),
                },
                event: initialState.event,
                errorRes: initialState.errorRes,
            };
        case actionCreators.getPersistentEvents.type:
        case actionCreators.loadMorePersistentEvents.type:
            return {
                ...state,
                events: { ...state.events, queryParams: prepareFilterQuery(payload) },
            };

        case actionCreators.updateDeletedPersistentEvents.type:
            return {
                ...state,
                events: {
                    ...state.events,
                    queryParams: state.events.queryParams,
                    data: state.events.data.filter(({ id }) => id !== payload),
                },
                errorRes: initialState.errorRes,
            };
        case actionCreators.setPersistentEvent.type:
            return {
                ...state,
                event: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearQueryParams.type:
            return {
                ...state,
                events: {
                    ...state.events,
                    queryParams: initialState.events.queryParams,
                },
            };
        case actionCreators.updateArchivedPersistentEvent.type:
            return {
                ...state,
                events: {
                    ...state.events,
                    data: [
                        ...state.events.data.map(e =>
                            e.id === parseInt(payload.id) ? payload : e
                        ),
                    ],
                },
            };
        default:
            return state;
    }
};
