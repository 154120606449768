import { fontFamily } from "../../../../common/styles/typography";
import { colors, spacing } from "../../UIGlobals";

export const buttonStyle = {
    body: {
        display: "inline-flex",
        width: 338,
        height: 48,
        backgroundColor: colors.transparent,
        padding: 0,
        margin: 0,
        border: "0 none",
        outline: "0 none",
        textDecoration: "none",
        position: "relative",
        fontFamily: fontFamily.primary,
        zIndex: 1,
    },
    bodySmall: {
        height: 42,
    },
    shape: {
        position: "absolute",
        zIndex: -1,
    },
    text: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: colors.white,
        fontFamily: fontFamily.primary,
        fontSize: 16,
        fontWeight: "700",
        lineHeight: 1.18,
        textTransform: "uppercase",
        height: 48,
        paddingTop: spacing.space0,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        position: "relative",
        zIndex: 2,
    },
    textSmall: {
        height: 42,
    },
};
