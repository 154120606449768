// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { reduxForm, Field, Fields } from "redux-form";
import validate from "./validate";
import {
    RenderTextField,
    RenderRadioGroup,
    RenderCheckboxField,
    RenderFileInput,
    RenderRadio,
} from "../../../common/components/FormFields";

import { Button, Paper } from "@material-ui/core";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import formsCss from "../../../common/styles/forms.css";

export const staffUserStatus = {
    ACTIVE: "active",
    BLOCKED: "blocked",
    DELETED: "deleted",
};

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const { error, submitting, handleSubmit, initialValues, push, errors } = this.props;
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>Edit LSE Student</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-xs-12" css={globalsCss.helpers.mb15}>
                                <Field
                                    name="lseId"
                                    type="lseId"
                                    component={RenderTextField}
                                    errorRes={errors.lseId}
                                    label="LSE SSO ID"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <Field
                                    name="status"
                                    errorRes={errors.status}
                                    component={RenderRadioGroup}
                                    row
                                >
                                    <RenderRadio
                                        value={staffUserStatus.ACTIVE}
                                        label="Active"
                                        className="col-lg-2 col-sm-3 col-xs-12"
                                        disabled={initialValues.status === staffUserStatus.DELETED}
                                    />
                                    <RenderRadio
                                        value={staffUserStatus.BLOCKED}
                                        label="Blocked"
                                        className="col-lg-2 col-sm-3 col-xs-12"
                                        disabled={initialValues.status === staffUserStatus.DELETED}
                                    />
                                    {initialValues.status === staffUserStatus.DELETED && (
                                        <RenderRadio
                                            value={staffUserStatus.DELETED}
                                            label="Deleted"
                                            className="col-lg-2 col-sm-3 col-xs-12"
                                            disabled={true}
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="firstName"
                                    errorRes={errors.firstName}
                                    label="First name"
                                    type="text"
                                    component={RenderTextField}
                                />
                                <Field
                                    name="firstNameLocked"
                                    errorRes={errors.firstNameLocked}
                                    label="Locked"
                                    component={RenderCheckboxField}
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="lastName"
                                    errorRes={errors.lastName}
                                    label="Last name"
                                    type="text"
                                    component={RenderTextField}
                                />
                                <Field
                                    name="lastNameLocked"
                                    errorRes={errors.lastNameLocked}
                                    label="Locked"
                                    component={RenderCheckboxField}
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="email"
                                    errorRes={errors.email}
                                    label="E-mail"
                                    type="email"
                                    component={RenderTextField}
                                />
                                <Field
                                    name="emailLocked"
                                    errorRes={errors.emailLocked}
                                    label="Locked"
                                    component={RenderCheckboxField}
                                />
                            </div>

                            {initialValues.lseEmail && (
                                <div className="col-sm-4 col-xs-12">
                                    <div css={formsCss.item}>
                                        <div css={formsCss.itemLabel}>LSE email address</div>
                                        <div css={formsCss.itemValue}>{initialValues.lseEmail}</div>
                                    </div>
                                </div>
                            )}

                            {initialValues.playRef && (
                                <div className="col-sm-4 col-xs-12">
                                    <div css={formsCss.item}>
                                        <div css={formsCss.itemLabel}>Playref</div>
                                        <div css={formsCss.itemValue}>{initialValues.playRef}</div>
                                    </div>
                                </div>
                            )}

                            <div className="col-sm-4 col-xs-12">
                                <div css={formsCss.item}>
                                    <div css={formsCss.itemLabel}>Use biometrics?</div>
                                    <div css={formsCss.itemValue}>
                                        {initialValues.hasBiometricKey ? "Yes" : "No"}
                                    </div>
                                </div>
                            </div>

                            {initialValues.lseProgrammes && initialValues.lseProgrammes.length > 0 && (
                                <div className="col-sm-8 col-xs-12">
                                    <div css={formsCss.item}>
                                        <div css={formsCss.itemLabel}>Programme / Department</div>
                                        <div css={formsCss.itemValue}>
                                            {initialValues.lseProgrammes.map(i => (
                                                <div key={`pd${i.programmeId}`}>
                                                    {i.programmeName} ({i.departmentName})
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-xs-12">
                                <label
                                    css={[formsCss.label, globalsCss.helpers.mt20]}
                                    htmlFor="profilePhoto"
                                >
                                    Profile Picture{" "}
                                    <small css={formsCss.labelHelp}>(min. 160px/160px)</small>
                                </label>
                                <Fields
                                    names={["photoFile", "photoUrl", "photoRemove"]}
                                    errorRes={errors.photoFile}
                                    component={RenderFileInput}
                                />
                            </div>
                        </div>

                        {error && <strong>{error}</strong>}
                        <div css={formsCss.actions}>
                            <Button
                                disabled={submitting}
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Save LSE Student
                            </Button>
                            <Button
                                disabled={submitting}
                                onClick={() => push("/users-and-audiences/lse-students")}
                                css={formsCss.btnBack}
                            >
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}

const form = reduxForm({
    form: "Student",
    enableReinitialize: true,
    validate,
})(Form);
export default form;
