/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../../common/styles/globals.css";
import AcademicAvailabilityForm from "./components/AcademicAvailabilityForm";

class OfficeHoursAcademicAvailability extends Component {
    componentDidMount() {
        const { setTitle } = this.props;
        setTitle("Bookings \u203A Reports");
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    render() {
        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Academic availability report</h2>
                        <AcademicAvailabilityForm
                            initialValues={{
                                from: null,
                                to: null,
                            }}
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
};

export default connect(null, dispatchToProps)(OfficeHoursAcademicAvailability);
