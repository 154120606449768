import ActionCreator from "../../actionCreator";

export default {
    getOfficeHoursSessions: ActionCreator("GET_OFFICE_HOURS_SESSIONS"),
    createOfficeHoursSessions: ActionCreator("CREATE_OFFICE_HOURS_SESSIONS"),
    loadMoreOfficeHoursSessions: ActionCreator("LOAD_MORE_OFFICE_HOURS_SESSIONS"),
    updateOfficeHoursSessions: ActionCreator("UPDATE_OFFICE_HOURS_SESSIONS"),
    clearOfficeHoursSessions: ActionCreator("CLEAR_OFFICE_HOURS_SESSIONS"),
    getOfficeHoursTeamSessions: ActionCreator("GET_OFFICE_HOURS_TEAM_SESSIONS"),
    loadMoreOfficeHoursTeamSessions: ActionCreator("LOAD_MORE_OFFICE_HOURS_TEAM_SESSIONS"),
    getOfficeHoursSessionDetails: ActionCreator("GET_OFFICE_HOURS_SESSION_DETAILS"),
    createOfficeHoursSessionDetails: ActionCreator("CREATE_OFFICE_HOURS_SESSION_DETAILS"),
    clearOfficeHoursSessionDetails: ActionCreator("CLEAR_OFFICE_HOURS_SESSION_DETAILS"),
    getOfficeHoursSessionSlots: ActionCreator("GET_OFFICE_HOURS_SESSION_SLOTS"),
    createOfficeHoursSessionSlots: ActionCreator("CREATE_OFFICE_HOURS_SESSION_SLOTS"),
    clearOfficeHoursSessionSlots: ActionCreator("CLEAR_OFFICE_HOURS_SESSION_SLOTS"),
    makeOfficeHoursBooking: ActionCreator("MAKE_OFFICE_HOURS_BOOKING"),
    cancelOfficeHoursBooking: ActionCreator("CANCEL_OFFICE_HOURS_BOOKING"),
    blockOfficeHoursSlot: ActionCreator("BLOCK_OFFICE_HOURS_SLOT"),
    removeBlockOfficeHoursSlot: ActionCreator("REMOVE_BLOCK_OFFICE_HOURS_SLOT"),
    updateAlteredOfficeHoursSlot: ActionCreator("UPDATE_ALTERED_OFFICE_HOURS_SLOT"),
    toggleOfficeHoursBookingDialog: ActionCreator("TOGGLE_OFFICE_HOURS_BOOKING_DIALOG"),
    toggleOfficeHoursCancelBookingDialog: ActionCreator(
        "TOGGLE_OFFICE_HOURS_CANCEL_BOOKING_DIALOG"
    ),
    toggleOfficeHoursBlockDialog: ActionCreator("TOGGLE_OFFICE_HOURS_BLOCK_DIALOG"),
    toggleOfficeHoursUnblockDialog: ActionCreator("TOGGLE_OFFICE_HOURS_UNBLOCK_DIALOG"),
    deleteOfficeHoursSession: ActionCreator("DELETE_OFFICE_HOURS_SESSION"),
    assignOfficeHoursGenericAvailabilitySession: ActionCreator(
        "ASSIGN_OFFICE_HOURS_GENERIC_AVAILABILITY_SESSION"
    ),
    updateAssignedOfficeHoursGenericAvailabilitySession: ActionCreator(
        "UPDATE_ASSIGNED_OFFICE_HOURS_GENERIC_AVAILABILITY_SESSION"
    ),
    updateOfficeHoursSessionSlotAttendance: ActionCreator(
        "UPDATE_OFFICE_HOURS_SESSION_SLOT_ATTENDANCE_COMMENT"
    ),
    bookDropInSession: ActionCreator("BOOK_DROP_IN_SLOT_OFFICE_HOURS_SESSIONS"),
    cancelBookingDropInSession: ActionCreator("CANCEL_DROP_IN_SLOT_OFFICE_HOURS_SESSIONS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_OFFICE_HOURS_SESSIONS"),
};
