import { colors, spacing } from "../../UIGlobals";
/** @jsx jsx */
import { jsx } from "@emotion/core";

export const RenderTextDescription = ({
    input,
    label,
    meta: { touched, error },
    errorRes,
    rows = 5,
    ...custom
}) => (
    <div>
        <textarea {...input} rows={rows} placeholder={label} {...custom} />
        {touched && (error || errorRes) && (
            <span
                css={{
                    display: "block",
                    color: colors.red,
                    fontSize: 14,
                    paddingTop: spacing.space0,
                }}
            >
                {error || errorRes}
            </span>
        )}
    </div>
);
