import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../store/CampusMapping/actionCreators";
import { default as mainMenuActionCreators } from "../../store/MainMenu/actionCreators";
import { TableComponent, RedirectButton } from "../../common/components/CampusMapping";
import Paper from "@material-ui/core/Paper";
import PromptDialog from "../../common/components/PromptDialog";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../common/styles/globals.css";
import tableCss from "../../common/styles/table.css";
import { decorateTableItemsJobTypes } from "../../common/services/decorateTableItems";
import { EditIcon, DeleteIcon } from "../../common/components/icons";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            name: "Name",
            code: "Code",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            code: tableCss.columnWidth150,
            postcode: tableCss.columnWidth200,
            city: tableCss.columnWidth200,
        },
    },
];

const buttons = items => index => [
    {
        path: `/campus-mapping/edit/${items[index] ? items[index].id : ""}`,
        label: "Edit",
        fnLabel: "push",
        icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

const columns = ["id", "name", "code", "address", "postcode", "city"];

class CampusMappingListContainer extends Component {
    state = {
        removeDialog: false,
        removeId: null,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removeBuilding({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    componentDidMount() {
        const { setTitle, getListBuildings } = this.props;
        setTitle("Campus Mapping");
        getListBuildings();
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    remove = itemID => {
        this.handleOpen(itemID);
    };

    render() {
        const {
            buildings,
            history: { push },
        } = this.props;
        const { remove } = this;

        return (
            <div>
                <div css={globalsCss.actions}>
                    <RedirectButton
                        label="Add new location"
                        url="/campus-mapping/new"
                        {...{ push }}
                    />
                </div>

                <Paper elevation={1}>
                    <TableComponent
                        items={buildings}
                        fns={{ push, remove }}
                        buttons={buttons(buildings)}
                        decorators={decorators}
                        columns={columns}
                    />

                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this entry?
                    </PromptDialog>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListBuildings: actionCreators.getListBuildings.create,
    removeBuilding: actionCreators.removeBuilding.create,
};
const mapStateToProps = state => ({
    buildings: state.CampusMapping.buildings,
});
export default connect(mapStateToProps, dispatchToProps)(CampusMappingListContainer);
