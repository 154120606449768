import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, CircularProgress } from "@material-ui/core";
import globalsCss from "../../common/styles/globals.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { redirectUrl } from "../../store/Auth/sso";

class NotLoggedIn extends Component {
    redirectOnInited = () => {
        if (this.props.initiated === true) {
            window.location.href = redirectUrl;
        }
    };

    componentDidMount() {
        this.redirectOnInited();
    }

    componentDidUpdate(prevProps) {
        if (this.props.initiated !== prevProps.initiated) this.redirectOnInited();
    }

    render() {
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <CircularProgress color="inherit" size={20} thickness={2} />
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = state => ({
    initiated: state.Auth.initiated,
});

export default connect(mapStateToProps, null)(NotLoggedIn);
