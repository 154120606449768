import { Component } from "react";
import { reduxForm, Field, Fields, formValueSelector, change } from "redux-form";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import validate from "./validate";
import {
    RenderTextField,
    RenderRadioGroup,
    RenderFileInput,
    RenderTextDescription,
    RenderAutocomplete,
    RenderRadio,
    RenderSelectField,
} from "../../../../common/components/FormFields";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";

import { Button, Paper, Chip } from "@material-ui/core";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import formsCss from "../../../../common/styles/forms.css";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";
import { connect } from "react-redux";
import { InfoTooltip } from "../../../../common/components/InfoTooltip";
import actionCreators from "../../../../store/Channels/Pages/actionCreators";
import { RenderTextFieldSimple } from "../../../../common/components/FormFields/TextFieldSimple";
import { ChannelType } from "../../types";
import { hasAdminRole } from "../../../../common/services/Auth";
import { AvatarChip } from "../../../../common/components/AvatarChip";

class Form extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
        searchAudences: "",
        searchAudences$: new Subject(),
        emailsValue: "",
        emailValueError: false,
    };

    componentDidMount() {
        const { getOwners, getAudiences } = this.props;
        getOwners(this.state.searchText);
        const { searchText$, searchAudences$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                rawUpdateListOnFilterChange({
                    getFn: getOwners,
                    filter: term,
                })
            );
        });
        searchAudences$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                rawUpdateListOnFilterChange({
                    getFn: getAudiences,
                    filter: term,
                })
            );
        });
        this.props.initialize(this.props.initialValues);
    }

    updateSearchText(value) {
        const { searchText$ } = this.state;
        this.setState({ searchText: value }, () =>
            searchText$.next({ filter: this.state.searchText })
        );
    }

    updateSearchAudiences(value) {
        const { searchAudences$ } = this.state;
        this.setState({ searchAudences: value }, () =>
            searchAudences$.next({ filter: this.state.searchAudences })
        );
    }

    onFilterChangeHandler = value => {
        this.updateSearchText(value);
    };

    onAudenceChangeHandler = value => {
        this.updateSearchAudiences(value);
    };

    validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    render() {
        const {
            handleSubmit,
            goBack,
            owners,
            audiences,
            currentFormType,
            errors,
            hasActivePosts,
            officeHoursTeams,
            updateCommentEmails,
            commentEmails,
            permissionLevel,
            initialValues,
        } = this.props;

        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>
                        {currentFormType === currentFormTypes.NEW ? "Add New Page" : "Edit Page"}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Field
                            name="status"
                            errorRes={errors.status}
                            component={RenderRadioGroup}
                            row
                        >
                            <RenderRadio
                                value="live"
                                label="Publish live"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                            <RenderRadio
                                value="draft"
                                label="Save as draft"
                                disabled={hasActivePosts}
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                        </Field>
                        <hr />
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="name"
                                    errorRes={errors.name}
                                    type="name"
                                    component={RenderTextField}
                                    label="Name"
                                    fullWidth
                                />
                            </div>

                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="topic"
                                    component={RenderSelectField}
                                    errorRes={errors.topic}
                                    label="Type"
                                    options={[
                                        {
                                            value: ChannelType.School,
                                            label: "School News",
                                        },
                                        {
                                            value: ChannelType.Department,
                                            label: "Department",
                                        },
                                        {
                                            value: ChannelType.Service,
                                            label: "Services",
                                        },
                                    ]}
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="email"
                                    errorRes={errors.email}
                                    component={RenderTextField}
                                    label="E-mail"
                                    fullWidth
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                {officeHoursTeams && officeHoursTeams.length > 0 && (
                                    <Field
                                        name="officeHoursTeam"
                                        component={RenderSelectField}
                                        label="Enable bookings? (optional)"
                                        errorRes={errors.officeHoursTeam}
                                        options={[
                                            { value: null, label: "" },
                                            ...(officeHoursTeams &&
                                                officeHoursTeams.map(item => {
                                                    return { value: item.id, label: item.name };
                                                })),
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <Field
                                    name="description"
                                    errorRes={errors.description}
                                    type="text"
                                    component={RenderTextDescription}
                                    fullWidth
                                    label="Description"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <div css={formsCss.uploadImage}>
                                    <label css={formsCss.label}>
                                        Cover Image{" "}
                                        <span css={formsCss.labelHelp}>(min. 747px/420pxx)</span>
                                    </label>
                                    <Fields
                                        names={["imageFile", "imageUrl"]}
                                        errorRes={errors.imageFile}
                                        component={RenderFileInput}
                                        onChangeCallback={() => this.props.clearErrors("imageFile")}
                                        onClearCallback={() => this.props.clearErrors("imageFile")}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <div css={formsCss.uploadImage}>
                                    <label css={formsCss.label}>
                                        Channel Picture (Icon){" "}
                                        <span css={formsCss.labelHelp}>(min. 160px/160px)</span>
                                    </label>
                                    <Fields
                                        names={["iconFile", "iconUrl"]}
                                        errorRes={errors.iconFile}
                                        component={RenderFileInput}
                                        onChangeCallback={() => this.props.clearErrors("iconFile")}
                                        onClearCallback={() => this.props.clearErrors("iconFile")}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <h4 css={globalsCss.helpers.mt25}>Automatic followers</h4>
                            </div>

                            <div className="col-sm-12 col-xs-12">
                                <p css={globalsCss.helpers.mb0}>
                                    Audiences that automatically follow this page. Students can
                                    unfollow.
                                </p>
                                <div css={formsCss.inlineAlignTop}>
                                    <Field
                                        floatingLabelText="Audience name"
                                        name="canUnfollowAudiences"
                                        errorRes={errors.canUnfollowAudiences}
                                        handleInput={this.onAudenceChangeHandler}
                                        dataSource={audiences}
                                        component={RenderAutocomplete}
                                        labelCallback={item => item.name}
                                        fullWidth
                                        onChangeCallback={() => this.props.clearErrors("audiences")}
                                        noAvatar
                                    />
                                    <InfoTooltip
                                        mt={44}
                                        text="Students in these audiences will automatically be followers, and will be able to unfollow the page"
                                    />
                                </div>
                            </div>
                            {hasAdminRole(permissionLevel) ? (
                                <div className="col-sm-12 col-xs-12" css={globalsCss.helpers.mt25}>
                                    <p css={globalsCss.helpers.mb0}>
                                        Audiences that automatically follow this page. Students
                                        cannot unfollow.
                                        <br />
                                        <em>
                                            Restricted: Contact the Student Hub team to make changes
                                            to this selection
                                        </em>
                                    </p>
                                    <div css={formsCss.inlineAlignTop}>
                                        <Field
                                            floatingLabelText="Audience name"
                                            name="cannotUnfollowAudiences"
                                            errorRes={errors.cannotUnfollowAudiences}
                                            handleInput={this.onAudenceChangeHandler}
                                            dataSource={audiences}
                                            component={RenderAutocomplete}
                                            labelCallback={item => item.name}
                                            fullWidth
                                            onChangeCallback={() =>
                                                this.props.clearErrors("audiences")
                                            }
                                            noAvatar
                                            disabled={!hasAdminRole(permissionLevel)}
                                            subtitleCallback={item =>
                                                `${
                                                    item && item.audienceType
                                                        ? item.audienceType
                                                        : ""
                                                }`
                                            }
                                        />
                                        <InfoTooltip
                                            mt={44}
                                            text="Students in these audiences will automatically be followers, and will NOT be able to unfollow the page"
                                        />
                                    </div>
                                </div>
                            ) : (
                                initialValues.cannotUnfollowAudiences &&
                                initialValues.cannotUnfollowAudiences.length > 0 && (
                                    <div
                                        className="col-sm-12 col-xs-12"
                                        css={globalsCss.helpers.mt25}
                                    >
                                        <p css={globalsCss.helpers.mb0}>
                                            Audiences that automatically follow this page. Students
                                            cannot unfollow.
                                            <br />
                                            <em>
                                                Restricted: Contact the Student Hub team to make
                                                changes to this selection
                                            </em>
                                        </p>
                                        <div css={formsCss.inlineAlignTop}>
                                            <div css={globalsCss.chipsContainer}>
                                                {initialValues.cannotUnfollowAudiences.map(item => (
                                                    <AvatarChip
                                                        key={item.id}
                                                        name={item.name}
                                                        noAvatar={true}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <hr />
                        <h4 css={globalsCss.helpers.mt35}>Page owners</h4>
                        <div className="row">
                            <div className="col-sm-8 col-xs-12">
                                <div css={formsCss.inlineAlignTop}>
                                    <Field
                                        name="owners"
                                        errorRes={errors.owners}
                                        handleInput={this.onFilterChangeHandler}
                                        dataSource={owners}
                                        component={RenderAutocomplete}
                                        fullWidth
                                        onChangeCallback={() => this.props.clearErrors("owners")}
                                    />
                                    <InfoTooltip
                                        mt={44}
                                        text="LSE Staff who can edit this page and its content (posts &amp; events)"
                                    />
                                </div>
                            </div>
                        </div>

                        <hr />
                        <h4 css={globalsCss.helpers.mt35}>Comment notifications</h4>
                        <div className="row">
                            <div className="col-sm-8 col-xs-12">
                                <div css={formsCss.inlineAlignTop}>
                                    <div css={formsCss.autoCompleteFullWidth}>
                                        <RenderTextFieldSimple
                                            label="Enter email address"
                                            fullWidth
                                            margin="normal"
                                            color="primary"
                                            value={this.state.emailsValue}
                                            onChange={e =>
                                                this.setState({ emailsValue: e.target.value })
                                            }
                                            helperText={
                                                this.state.emailValueError
                                                    ? "Please enter a valid email address"
                                                    : ""
                                            }
                                            error={this.state.emailValueError}
                                        />
                                    </div>
                                    <InfoTooltip
                                        mt={44}
                                        text="An email address (or multiple) that will receive notifications when someone comments on a post of that Page"
                                    />
                                </div>
                                <div css={formsCss.actionsAdd}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            if (this.validateEmail(this.state.emailsValue)) {
                                                updateCommentEmails(
                                                    commentEmails
                                                        ? [...commentEmails, this.state.emailsValue]
                                                        : [this.state.emailsValue]
                                                );
                                                this.setState({
                                                    emailsValue: "",
                                                    emailValueError: false,
                                                });
                                            } else {
                                                this.setState({ emailValueError: true });
                                            }
                                        }}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {commentEmails && (
                            <div css={globalsCss.chipsContainer}>
                                {commentEmails.map(email => (
                                    <Chip
                                        key={email}
                                        label={email}
                                        onDelete={() =>
                                            updateCommentEmails(
                                                commentEmails.filter(e => e !== email)
                                            )
                                        }
                                        css={globalsCss.chipsItem}
                                    />
                                ))}
                            </div>
                        )}

                        <div css={formsCss.actions}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                {currentFormType === currentFormTypes.NEW
                                    ? "Add New Page"
                                    : "Save Page"}
                            </Button>
                            <Button onClick={() => goBack()} css={formsCss.btnBack}>
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}

const formName = "Page";
const form = reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
})(Form);

const selector = formValueSelector(formName);

const mapStateToProps = ({ Auth, form }) => ({
    commentEmails: selector({ form }, "commentEmails"),
    permissionLevel: Auth.permissionLevel,
});

const dispatchToProps = {
    clearErrors: actionCreators.clearErrors.create,
    updateCommentEmails: newValue => change(formName, "commentEmails", newValue),
};

export default connect(mapStateToProps, dispatchToProps)(form);
