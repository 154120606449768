export const colors = {
    white: "rgb(255,255,255)",
    white95: "rgba(255,255,255,0.95)",
    white90: "rgba(255,255,255,0.9)",
    white80: "rgba(255,255,255,0.8)",
    white70: "rgb(a255,255,255,0.7)",
    black: "rgb(0,0,0)",
    black87: "rgba(0,0,0,0.87)",
    black54: "rgba(0,0,0,0.54)",
    black50: "rgba(0,0,0,0.5)",
    black42: "rgba(0,0,0,0.42)",
    black20: "rgba(0,0,0,0.2)",
    black10: "rgba(0,0,0,0.1)",
    black5: "rgba(0,0,0,0.05)",
    black2: "rgba(0,0,0,0.02)",
    blue2: "rgb(236,239,241)", // ECEFF1
    grey: "rgb(240,241,243)", // F0F1F3
    accentLSE: "#E0112B",
    accentA: "rgb(255,45,85)", // FF2D55
    accentB: "rgb(170,72,210)", // AA48D2
    accentC: "rgb(255,149,0)", // FF9500
    accentD: "rgb(119,136,248)", // 7788F8
    accentE: "rgb(249,223,76)", // F9DF4C
    accentF: "rgb(124,230,216)", // 7CE6D8
};

export const gradients = {
    black: "linear-gradient(0deg, rgba(0,0,0,0.30) 0%, rgba(0,0,0,0.00) 100%)",
};
