/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, IconButton, TextField, MenuItem } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import { calendarCategoryMap } from "../../../../common/services/tableCallbacks";
import toolbarCss from "../../../../common/styles/toolbar.css";
import datepickerCss from "../../../../common/styles/datepicker.css";
import { ClearIcon } from "../../../../common/components/icons";

export default withStyles(toolbarCss)(
    ({ onFilterChangeHandler, onDateChangeHandler, filter, from, to, classes }) => (
        <AppBar
            position="static"
            classes={{
                root: classes.appBarRoot,
            }}
            elevation={0}
        >
            <Toolbar
                classes={{
                    root: classes.toolbarRoot,
                }}
            >
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Filter by calendar:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Filter:</span>
                        </span>
                    </Typography>
                    <TextField
                        select
                        margin="none"
                        color="primary"
                        // fullWidth
                        onChange={onFilterChangeHandler("filter")}
                        value={filter}
                        InputProps={{
                            classes: {
                                root: classes.inputRoot,
                                error: classes.inputError,
                            },
                        }}
                        SelectProps={{
                            displayEmpty: true,
                            MenuProps: {
                                MenuListProps: {
                                    disablePadding: true,
                                    dense: true,
                                },
                            },
                        }}
                        css={{ minWidth: 200 }}
                    >
                        <MenuItem value="">All calendars</MenuItem>
                        {Object.entries(calendarCategoryMap).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextField>
                    {/* <SelectField
                        value={filter}
                        onChange={onFilterChangeHandler("filter")}
                        fullWidth={true}
                        css={{ minWidth: 200 }}
                    >
                        <MenuItem primaryText="All" />
                        {Object.entries(calendarCategoryMap).map(([key, value]) => (
                            <MenuItem key={key} value={key} primaryText={value} />
                        ))}
                    </SelectField> */}
                </div>
                <div css={toolbarCss.toolbarSeparator} />
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Filter by Start Date:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Date:</span>
                        </span>
                    </Typography>
                    <div css={datepickerCss.datepickerContainer}>
                        <DatePicker
                            placeholder="From..."
                            value={from !== "" ? from : null}
                            onChange={value =>
                                onDateChangeHandler("from")(
                                    value.set({ hour: 0, minute: 0, second: 0 })
                                )
                            }
                            css={toolbarCss.toolbarDatePicker}
                            fullWidth={true}
                            autoOk={true}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            format={"D MMM YYYY"}
                        />
                        <IconButton
                            onClick={onDateChangeHandler("from", null)}
                            css={datepickerCss.datepickerBtn}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                    <div css={datepickerCss.datepickerContainer}>
                        <DatePicker
                            placeholder="To..."
                            value={to !== "" ? to : null}
                            onChange={value =>
                                onDateChangeHandler("to")(
                                    value.set({ hour: 23, minute: 59, second: 59 })
                                )
                            }
                            css={toolbarCss.toolbarDatePicker}
                            fullWidth={true}
                            autoOk={true}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            format={"D MMM YYYY"}
                        />
                        <IconButton
                            onClick={onDateChangeHandler("to", null)}
                            css={datepickerCss.datepickerBtn}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
);
