export interface Dictionary<TValue> {
    [id: string]: TValue;
}

export enum CurrentFormType {
    NEW = "NEW",
    EDIT = "EDIT",
}

export interface ImageFile {
    base64: string;
    type: string;
    name: string;
    size: string;
}

export interface PagedResponse<T> {
    data: T;
    count: number;
    nextPage: number;
}
