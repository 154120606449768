import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../store/Discussion/actionCreators";
import commentsCss from "../../common/styles/comments.css";
import Avatar from "../../common/components/Avatar";
import { TimeFromNow } from "../../common/components/Time";

class Comment extends Component {
    render() {
        const {
            comment: {
                id,
                commentary,
                lvl,
                createdAt,
                isDeleted,
                isEdited,
                user: { firstName, lastName, photoUrl },
            },
            deleteComment,
        } = this.props;
        return (
            <div css={[commentsCss.body, lvl !== 0 && commentsCss.bodyLvl]}>
                <div css={[commentsCss.avatar, lvl !== 0 && commentsCss.avatarLvl]}>
                    <Avatar
                        smallComments={lvl === 0}
                        smaller={lvl !== 0}
                        src={photoUrl}
                        initials={firstName.charAt(0) + lastName.charAt(0)}
                    />
                </div>
                <div css={commentsCss.inner}>
                    <div css={commentsCss.user}>
                        {firstName} {lastName}
                    </div>
                    <div css={[commentsCss.text, isDeleted && commentsCss.textItalic]}>
                        {isDeleted ? (
                            "Comment removed"
                        ) : (
                            <span>
                                {commentary} {isEdited && <span>(edited)</span>}
                            </span>
                        )}
                    </div>
                    <div css={commentsCss.footer}>
                        <div css={commentsCss.date}>
                            <TimeFromNow date={createdAt} />
                        </div>
                        <div css={commentsCss.actions}>
                            {!isDeleted && (
                                <div css={commentsCss.btnItem}>
                                    <button
                                        type="button"
                                        css={commentsCss.btn}
                                        onClick={() => deleteComment({ id })}
                                    >
                                        Delete
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    deleteComment: actionCreators.deleteComment.create,
};

export default connect(null, mapDispatchToProps)(Comment);
