// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/Channels/Pages/Followers/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { default as pageActionCreators } from "../../../../store/Channels/Pages/actionCreators";
import Form from "./Form";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";
import { MainTitle } from "../../../../common/components/MainTitle";

class PageChannelNewFollowersContainer extends Component {
    state = {
        pageId: null,
    };

    componentDidMount() {
        const {
            setTitle,
            getOnePage,
            match: {
                params: { id },
            },
        } = this.props;
        this.setState({ pageId: id });
        setTitle("Channels \u203A Pages");
        getOnePage({ id });
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    onSubmit = values => {
        const { postPageFollower } = this.props;
        const { pageId } = this.state;

        if (values && values.students) {
            const lastStudent = values.students.slice(-1)[0];
            for (const student of values.students) {
                postPageFollower({
                    userId: student.id,
                    pageId,
                    isLast: lastStudent.id === student.id,
                });
            }
        }
    };

    render() {
        const {
            page,
            errorRes: { error, errors },
            history: { push },
        } = this.props;
        const { pageId } = this.state;
        const { onSubmit } = this;

        return page ? (
            <div>
                <MainTitle title={page.name} type="[Page]" />
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    {...{
                        onSubmit,
                        errors,
                        push,
                        pageId,
                    }}
                />
            </div>
        ) : (
            <div></div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    postPageFollower: actionCreators.postPageFollower.create,
    getOnePage: pageActionCreators.getOnePage.create,
};
const mapStateToProps = ({ ChannelPageFollowers: { errorRes }, ChannelPage: { page } }) => ({
    errorRes,
    page,
});

export default connect(mapStateToProps, dispatchToProps)(PageChannelNewFollowersContainer);
