import { Component } from "react";
import { connect } from "react-redux";
import { TableComponent, RedirectButton } from "../../../common/components/Settings";
import actionCreators from "../../../store/Settings/SchoolYears/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { prepareFilterQuery } from "../../../common/services/utils";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PromptDialog from "../../../common/components/PromptDialog";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import formsCss from "../../../common/styles/forms.css";
import moment from "moment-timezone";
import { EditIcon, DeleteIcon, HolidayIcon, AutoRenewIcon } from "../../../common/components/icons";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            name: "Academic Year",
        },
    },
];

const buttons = items => index => {
    let buttons = [
        {
            path: `/settings/school-years/edit/${items[index] ? items[index].id : ""}`,
            label: "Edit",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
        },
        {
            path: `/settings/school-years/holidays/${items[index] ? items[index].id : ""}`,
            label: "Holidays",
            fnLabel: "push",
            icon: <HolidayIcon css={tableCss.actionsMenuItemIcon} />,
        },
    ];

    if (moment(items[index].startFirstTerm).isAfter()) {
        buttons.push({
            path: `${items[index] ? items[index].id : null}`,
            label: "Delete",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        });
    }

    return buttons;
};

const columns = ["name"];

class ListSchoolYearsContainer extends Component {
    state = {
        removeDialog: false,
        removeId: null,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removeSchoolYear({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    componentDidMount() {
        const { setTitle, getListSchoolYears } = this.props;
        setTitle("Settings \u203A Academic Years");
        getListSchoolYears(prepareFilterQuery({}));
    }

    onLoadMoreClickHandler = page => () => {
        this.props.loadMoreSchoolYears(prepareFilterQuery({ page }));
    };

    componentWillUnmount() {
        this.props.clearTitle();
    }

    remove = itemID => {
        this.handleOpen(itemID);
    };

    render() {
        const {
            schoolYears,
            history: { push },
            nextPage,
            count,
        } = this.props;
        const { remove } = this;

        return (
            <div>
                <div css={globalsCss.actions}>
                    <RedirectButton
                        label="Add new academic year"
                        url="/settings/school-years/new"
                        {...{ push }}
                    />
                </div>
                <Paper elevation={1}>
                    <TableComponent
                        items={schoolYears}
                        fns={{ push, remove }}
                        buttons={buttons(schoolYears)}
                        decorators={decorators}
                        columns={columns}
                    />
                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this academic year?
                    </PromptDialog>
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {schoolYears.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListSchoolYears: actionCreators.getListSchoolYears.create,
    loadMoreSchoolYears: actionCreators.loadMoreSchoolYears.create,
    removeSchoolYear: actionCreators.removeSchoolYear.create,
};
const mapStateToProps = ({ SettingsSchoolYears }) => ({
    schoolYears: SettingsSchoolYears.schoolYears.data,
    count: SettingsSchoolYears.schoolYears.count,
    nextPage: SettingsSchoolYears.schoolYears.nextPage,
});
export default connect(mapStateToProps, dispatchToProps)(ListSchoolYearsContainer);
