import React, { Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { dateFormatCallback } from "../../../common/services/tableCallbacks";
import IconButton from "@material-ui/core/IconButton";
import { DeleteIcon } from "../../../common/components/icons";

export const HolidayCard = ({ schoolYearId, removeSchoolYearHoliday, holiday }) => {
    return (
        <Card>
            <CardHeader
                action={
                    <IconButton
                        onClick={() =>
                            removeSchoolYearHoliday({ id: schoolYearId, holidayId: holiday.id })
                        }
                    >
                        <DeleteIcon />
                    </IconButton>
                }
                title={holiday.name}
                subheader={
                    <Fragment>
                        <div>
                            <strong>From:</strong> {dateFormatCallback(holiday, "startDate")}
                        </div>
                        <div>
                            <strong>To:</strong> {dateFormatCallback(holiday, "endDate")}
                        </div>
                    </Fragment>
                }
            />
        </Card>
    );
};
