// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { LinkAltIcon, SubdirectoryArrowRightIcon } from "../../../../common/components/icons";
import { sideLinkCss } from "../../../../common/styles/table.css";

const SideLinkRow = item => {
    return (
        <div
            css={[
                sideLinkCss.body,
                {
                    paddingLeft: item.lvl * 28 - 28,
                },
            ]}
        >
            {item.url ? (
                // link
                <div>
                    <span css={sideLinkCss.sectionIcon}>
                        <LinkAltIcon fontSize="inherit" color="inherit" />
                    </span>{" "}
                    <span>{item.name}</span>
                </div>
            ) : (
                // section
                <div>
                    <span css={sideLinkCss.linkIcon}>
                        <SubdirectoryArrowRightIcon fontSize="inherit" color="inherit" />
                    </span>
                    <strong>{item.name}</strong>
                </div>
            )}
        </div>
    );
};

export default SideLinkRow;
