import ActionCreator from "../actionCreator";

export default {
    setTitle: ActionCreator("UI_SET_TITLE"),
    clearTitle: ActionCreator("UI_CLEAR_TITLE"),
    setLoading: ActionCreator("UI_SET_LOADING"),
    clearLoading: ActionCreator("UI_CLEAR_LOADING"),
    errorPageRedirect: ActionCreator("ERROR_PAGE_REDIRECT"),
    errorResponse: ActionCreator("ERROR_RESPONSE"),
};
