import { colors } from "./colors";
import { spacing } from "./spacing";
import { fontSize } from "./typography";

export default {
    subtitle: {
        fontSize: fontSize.textSmall,
        fontStyle: "italic",
        marginBottom: spacing.space2,
        marginTop: spacing.space1,
    },
    inner: {
        marginTop: spacing.space2,
    },
    icon: {
        opacity: "0.7",
        marginRight: spacing.space2,
        verticalAlign: "middle",
    },
    section: {
        display: "flex",
        alignItems: "center",
        marginBottom: spacing.space2,
        marginTop: spacing.space2,
    },
    link: {
        fontSize: fontSize.text,
        color: colors.black54,
        textDecoration: "none",
        margin: 0,
        marginBottom: spacing.space1,
        display: "block",
    },
    title: {
        fontSize: fontSize.title,
    },
    button: {
        marginTop: spacing.space2,
    },
    buttonIcon: {
        marginRight: spacing.space1,
    },
    newButton: {
        marginBottom: spacing.space4,
        marginTop: spacing.space1,
    },
    pageItem: {
        marginBottom: spacing.space2,
    },
    pageItemTitle: {},
    pageItemDateTime: {
        fontSize: fontSize.textSmall,
    },
    pageItemLocation: {
        fontSize: fontSize.textSmall,
    },
    pageItemBody: {
        fontSize: fontSize.textSmall,
        span: {
            opacity: 0.2,
            margin: "0 8px",
        },
    },
    pageItemBodyLink: {
        display: "inline",
        fontStyle: "normal",
        fontSize: fontSize.textSmall,
        margin: 0,
        padding: 0,
        background: "none",
        border: "0 none",
        outline: "0 none",
    },
    bookingItem: {
        marginBottom: spacing.space2,
    },
    bookingItemTitle: {},
    bookingItemTitleLink: {
        marginBottom: spacing.space0comma5,
    },
    bookingItemDateTime: {
        fontSize: fontSize.textSmall,
    },
    bookingItemLocation: {
        fontSize: fontSize.textSmall,
    },
    bookingItemBookedBy: {
        fontSize: fontSize.textSmall,
        fontStyle: "italic",
        opacity: 0.5,
    },
};
