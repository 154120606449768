export default values => {
    const errors = {};
    const requiredFields = ["name", "imageFile", "iconFile", "owners"];
    requiredFields.forEach(field => {
        if (
            !values[field] ||
            (values[field].hasOwnProperty("base64") && values[field].base64 === "")
        ) {
            if (field === "imageFile") {
                if (!values["imageUrl"]) {
                    errors[field] = "Required";
                }
            } else if (field === "iconFile") {
                if (!values["iconUrl"]) {
                    errors[field] = "Required";
                }
            } else {
                errors[field] = "Required";
            }
        }
        if (Array.isArray(values[field])) {
            if (values[field].length === 0) {
                errors[field] = "Required";
            }
        }
    });
    return errors;
};
