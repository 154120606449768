/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, InputBase } from "@material-ui/core";
import toolbarCss from "../styles/toolbar.css";

const FilterRow = withStyles(toolbarCss)(({ onFilterChangeHandler, filter, classes }) => (
    <AppBar
        position="static"
        classes={{
            root: classes.appBarRoot,
        }}
        elevation={0}
    >
        <Toolbar
            classes={{
                root: classes.toolbarRoot,
            }}
        >
            <div css={toolbarCss.toolbarGroup}>
                <Typography>
                    <span css={toolbarCss.toolbarTitle}>
                        <span css={toolbarCss.labelShowOnDesktop}>Filter by keyword or ID:</span>
                        <span css={toolbarCss.labelShowOnMobile}>Filter:</span>
                    </span>
                </Typography>

                <InputBase
                    placeholder="..."
                    value={filter || ""}
                    onChange={onFilterChangeHandler}
                    fullWidth
                    autoComplete="off"
                />
            </div>
        </Toolbar>
    </AppBar>
));

export default FilterRow;
