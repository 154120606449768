import ActionCreator from "../../actionCreator";

export default {
    getMyOfficeHoursAcademics: ActionCreator("GET_MY_OFFICE_HOURS_ACADEMICS"),
    createMyOfficeHoursAcademics: ActionCreator("CREATE_MY_OFFICE_HOURS_ACADEMICS"),
    loadMoreMyOfficeHoursAcademics: ActionCreator("LOAD_MORE_MY_OFFICE_HOURS_ACADEMICS"),
    updateListMyOfficeHoursAcademics: ActionCreator("UPDATE_MY_OFFICE_HOURS_ACADEMICS"),
    clearMyOfficeHoursAcademics: ActionCreator("CLEAR_MY_OFFICE_HOURS_ACADEMICS"),
    getMyOfficeHoursTeams: ActionCreator("GET_MY_OFFICE_HOURS_TEAMS"),
    createMyOfficeHoursTeams: ActionCreator("CREATE_MY_OFFICE_HOURS_TEAMS"),
    clearMyOfficeHoursTeams: ActionCreator("CLEAR_MY_OFFICE_HOURS_TEAMS"),
    getMyOfficeHoursTeamAcademics: ActionCreator("GET_MY_OFFICE_HOURS_TEAM_ACADEMICS"),
    createMyOfficeHoursTeamAcademics: ActionCreator("CREATE_MY_OFFICE_HOURS_TEAM_ACADEMICS"),
    loadMoreMyOfficeHoursTeamAcademics: ActionCreator("LOAD_MORE_MY_OFFICE_HOURS_TEAM_ACADEMICS"),
    updateListMyOfficeHoursTeamAcademics: ActionCreator(
        "UPDATE_LIST_MY_OFFICE_HOURS_TEAM_ACADEMICS"
    ),
    clearMyOfficeHoursTeamAcademics: ActionCreator("CLEAR_MY_OFFICE_HOURS_TEAM_ACADEMICS"),
    getUserAvailabilities: ActionCreator("GET_USER_AVAILABILITIES"),
    getTeamUserAvailabilities: ActionCreator("GET_TEAM_USER_AVAILABILITIES"),
    getTeamGenericAvailabilities: ActionCreator("GET_TEAM_GENERIC_AVAILABILITIES"),
    createAvailabilities: ActionCreator("CREATE_USER_AVAILABILITIES"),
    clearAvailabilities: ActionCreator("CLEAR_USER_AVAILABILITIES"),
    getOneMyOfficeHoursAcademic: ActionCreator("GET_ONE_MY_OFFICE_HOURS_ACADEMIC"),
    createOneMyOfficeHoursAcademic: ActionCreator("CREATE_ONE_MY_OFFICE_HOURS_ACADEMIC"),
    clearOneMyOfficeHoursAcademic: ActionCreator("CLEAR_ONE_MY_OFFICE_HOURS_ACADEMIC"),
    getOneMyOfficeHoursTeam: ActionCreator("GET_ONE_MY_OFFICE_HOURS_TEAM"),
    createOneMyOfficeHoursTeam: ActionCreator("CREATE_ONE_MY_OFFICE_HOURS_TEAM"),
    clearOneMyOfficeHoursTeam: ActionCreator("CLEAR_ONE_MY_OFFICE_HOURS_TEAM"),
    pushMyOfficeHoursAvailability: ActionCreator("PUSH_MY_OFFICE_HOURS_AVAILABILITY"),
    putMyOfficeHoursAvailability: ActionCreator("PUT_MY_OFFICE_HOURS_AVAILABILITY"),
    safePutMyOfficeHoursAvailability: ActionCreator("SAFE_PUT_MY_OFFICE_HOURS_AVAILABILITY"),
    pushListMyOfficeHoursAvailabilities: ActionCreator("PUSH_LIST_MY_OFFICE_HOURS_AVAILABILITIES"),
    pushListMyOfficeHoursTeamAvailabilities: ActionCreator(
        "PUSH_LIST_MY_OFFICE_HOURS_TEAM_AVAILABILITIES"
    ),
    showConfirmForceRequest: ActionCreator(
        "SHOW_FORCE_CONFIRM_PROMPT_MY_OFFICE_HOURS_AVAILABILITY"
    ),
    hideConfirmForceRequest: ActionCreator(
        "HIDE_FORCE_CONFIRM_PROMPT_MY_OFFICE_HOURS_AVAILABILITY"
    ),
    removeMyOfficeHoursAvailability: ActionCreator("REMOVE_MY_OFFICE_HOURS_AVAILABILITY"),
    getOneMyOfficeHoursAvailability: ActionCreator("GET_ONE_MY_OFFICE_HOURS_AVAILABILITY"),
    createOneMyOfficeHoursAvailability: ActionCreator("CREATE_ONE_MY_OFFICE_HOURS_AVAILABILITY"),
    clearOneMyOfficeHoursAvailability: ActionCreator("CLEAR_ONE_MY_OFFICE_HOURS_AVAILABILITY"),
    getOfficeCalendarSlots: ActionCreator("GET_MY_OFFICE_HOURS_CALENDAR_PREVIEW_SLOTS"),
    createOfficeCalendarSlots: ActionCreator("CREATE_MY_OFFICE_HOURS_CALENDAR_PREVIEW_SLOTS"),
    clearOfficeCalendarSlots: ActionCreator("CLEAR_MY_OFFICE_HOURS_CALENDAR_PREVIEW_SLOTS"),
    getOfficeHoursDefaultAvailability: ActionCreator("GET_OFFICE_HOURS_DEFAULT_AVAILABILITY"),
    createOfficeHoursDefaultAvailability: ActionCreator("CREATE_OFFICE_HOURS_DEFAULT_AVAILABILITY"),
    putOfficeHoursDefaultAvailability: ActionCreator("PUT_OFFICE_HOURS_DEFAULT_AVAILABILITY"),
    clearOfficeHoursDefaultAvailability: ActionCreator("CLEAR_OFFICE_HOURS_DEFAULT_AVAILABILITY"),
    errorResponse: ActionCreator("ERROR_RESPONSE_OFFICE_HOURS_AVAILABILITIES"),
    getSlotsForCancellation: ActionCreator("GET_OFFICE_HOURS_AVAILABILITY_SLOTS_FOR_CANCELLATION"),
    getSlotsForGenericCancellation: ActionCreator(
        "GET_OFFICE_HOURS_GENERIC_AVAILABILITY_SLOTS_FOR_CANCELLATION"
    ),
    getSlotsForAvailability: ActionCreator("GET_OFFICE_HOURS_AVAILABILITY_SLOTS_FOR_AVAILABILITY"),
    createSlotsForCancellation: ActionCreator(
        "CREATE_OFFICE_HOURS_AVAILABILITY_SLOTS_FOR_CANCELLATION"
    ),
    clearSlotsForCancellation: ActionCreator(
        "CLEAR_OFFICE_HOURS_AVAILABILITY_SLOTS_FOR_CANCELLATION"
    ),

    pushMyOfficeHoursGenericAvailability: ActionCreator(
        "PUSH_MY_OFFICE_HOURS_GENERIC_AVAILABILITY"
    ),
    putMyOfficeHoursGenericAvailability: ActionCreator("PUT_MY_OFFICE_HOURS_GENERIC_AVAILABILITY"),
    safePutMyOfficeHoursGenericAvailability: ActionCreator(
        "SAFE_PUT_MY_OFFICE_HOURS_GENERIC_AVAILABILITY"
    ),
    pushListMyOfficeHoursGenericAvailabilities: ActionCreator(
        "PUSH_LIST_MY_OFFICE_HOURS_GENERIC_AVAILABILITIES"
    ),
    getOneMyOfficeHoursGenericAvailability: ActionCreator(
        "GET_ONE_MY_OFFICE_HOURS_GENERIC_AVAILABILITY"
    ),
};
