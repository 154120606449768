import ActionCreator from "../../actionCreator";

export default {
    getListOfficeHoursAcademics: ActionCreator("GET_LIST_OFFICE_HOURS_ACADEMICS"),
    createListOfficeHoursAcademics: ActionCreator("CREATE_LIST_OFFICE_HOURS_ACADEMICS"),
    loadMoreOfficeHoursAcademics: ActionCreator("LOAD_MORE_OFFICE_HOURS_ACADEMICS"),
    updateListOfficeHoursAcademics: ActionCreator("UPDATE_LIST_OFFICE_HOURS_ACADEMICS"),
    getOneOfficeHoursAcademic: ActionCreator("GET_ONE_OFFICE_HOURS_ACADEMIC"),
    updateOneOfficeHoursAcademic: ActionCreator("UPDATE_ONE_OFFICE_HOURS_ACADEMIC"),
    clearOfficeHoursAcademic: ActionCreator("CLEAR_OFFICE_HOURS_ACADEMIC"),
    pushListOfficeHoursAcademics: ActionCreator("PUSH_LIST_OFFICE_HOURS_ACADEMICS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_OFFICE_HOURS_ACADEMICS"),
};
