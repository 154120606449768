export default values => {
    const errors = {};
    const requiredFields = ["status", "title"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    if (values["type"] === "html" && !values["contentHtml"]) errors["content"] = "Required";
    else if (values["type"] === "url" && !values["contentUrl"]) errors["content"] = "Required";

    return errors;
};
