export default {
    tabs: {
        backgroundColor: "#fff",
        borderRadius: 0,
        margin: "0 0 35px",

        "@media (max-width: 767px)": {
            margin: "0 0 15px",
        },
    },
    activeTab: {
        background: "#fff",
        color: "#000",
        // borderBottom: "2px solid #000",
        borderBottom: "2px solid transaprent",
    },
    inactiveTab: {
        // background: "rgba(253, 250, 253, 1)",
        background: "#fff",
        color: "#424242",
        // borderBottom: "1px solid #000",
        borderBottom: "2px solid #d9d9d9",
    },
    postTabs: {
        marginTop: 32,
    },
};
