import React from "react";
import { Button, Dialog, DialogContent, DialogActions } from "@material-ui/core";

const PromptDialog = ({
    open,
    handleClose,
    children,
    confirmLabel = "Yes, Delete",
    cancelLabel = "No, Cancel",
    ...props
}) => {
    const actions = [
        cancelLabel && (
            <Button key="cancel" onClick={() => handleClose(false)}>
                {cancelLabel}
            </Button>
        ),
        confirmLabel && (
            <Button key="confirm" onClick={() => handleClose(true)}>
                {confirmLabel}
            </Button>
        ),
    ];

    return (
        <Dialog actions={actions} open={open} onClose={() => handleClose(false)} {...props}>
            <DialogContent>{children}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
};

export default PromptDialog;
