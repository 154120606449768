import { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Button, Paper } from "@material-ui/core";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../../common/styles/globals.css";
import formsCss from "../../../../../common/styles/forms.css";
import {
    RenderTextField,
    RenderBaseEditor,
    RenderRadioGroup,
    RenderRadio,
    RenderDatepicker,
    RenderTimepicker,
} from "../../../../../common/components/FormFields";
import validate from "./validate";
import moment from "moment-timezone";

class NotificationBannerForm extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    normalizeDate = (value, previousValue, allValues) => {
        if (!previousValue) {
            const myValue = moment(value).tz("Europe/London");
            myValue.set({ hour: 0, minute: 0, second: 0 });
            return myValue.toISOString();
        } else return value;
    };

    render() {
        const { handleSubmit, push, errors, submitText } = this.props;
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-xs-12">
                                <Field
                                    name="isDisplayedContinuously"
                                    component={RenderRadioGroup}
                                    row
                                >
                                    <RenderRadio
                                        value={"true"}
                                        label="Display continuously"
                                        className="col-lg-3 col-sm-3 col-xs-12"
                                    />
                                    <RenderRadio
                                        value={"false"}
                                        label="Display until user acknowledges"
                                        className="col-lg-6 col-sm-6 col-xs-12"
                                    />
                                </Field>
                            </div>

                            <div className="col-xs-12" style={{ marginBottom: 24 }}>
                                <Field
                                    name="teaser"
                                    errorRes={errors && errors.teaser}
                                    component={RenderTextField}
                                    label="Teaser"
                                />
                            </div>
                            <div className="col-xs-12">
                                <label css={formsCss.label}>Content</label>
                                <Field
                                    name="content"
                                    errorRes={errors.content}
                                    label="Content"
                                    component={RenderBaseEditor}
                                />
                            </div>
                            <div className="col-xs-12">
                                <hr />
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <Field
                                    name="startAt"
                                    errorRes={errors && errors.startAt}
                                    component={RenderDatepicker}
                                    label="Start at"
                                    format={null}
                                    hintText=" "
                                    fullWidth
                                    css={formsCss.field}
                                    normalize={this.normalizeDate}
                                />
                                <Field
                                    name="startAt"
                                    component={RenderTimepicker}
                                    format={null}
                                    hintText="HH:MM"
                                    fullWidth
                                />
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <Field
                                    name="endAt"
                                    errorRes={errors && errors.endAt}
                                    component={RenderDatepicker}
                                    label="Finish at"
                                    format={null}
                                    hintText=" "
                                    fullWidth
                                    css={formsCss.field}
                                    normalize={this.normalizeDate}
                                />
                                <Field
                                    name="endAt"
                                    component={RenderTimepicker}
                                    format={null}
                                    hintText="HH:MM"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div css={formsCss.actions}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                {submitText}
                            </Button>
                            <Button
                                onClick={() => push("/calendar/notification-banners/")}
                                css={formsCss.btnBack}
                            >
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}

export default connect(
    null,
    null
)(
    reduxForm({
        form: "NotificationBannerTemplate",
        enableReinitialize: true,
        validate,
    })(NotificationBannerForm)
);
