import { Component } from "react";
import { connect } from "react-redux";
import Form from "./Form/";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import actionCreators from "../../../store/HowTo/Category/actionCreators";
import currentFormTypes from "../../../common/components/CurrentFormTypes";
import HowToCategoryValues from "../../../fixtures/HowToCategory";
import formsCss from "../../../common/styles/forms.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class EditHowToCategoryContainer extends Component {
    componentDidMount() {
        const { setTitle, getOneCategory, match, setCurrentFormType } = this.props;
        const type = match.params.id ? currentFormTypes.EDIT : currentFormTypes.NEW;
        setCurrentFormType(type);
        setTitle("Knowledge Base Categories");
        getOneCategory({ id: match.params.id });
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    onSubmit = values => {
        this.props.putCategory(values);
    };

    render() {
        const {
            currentFormType,
            initialValues,
            history: { push },
            formObject,
            updateForm,
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;

        return (
            <div>
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    {...{
                        initialValues,
                        onSubmit,
                        currentFormType,
                        push,
                        formObject,
                        updateForm,
                        errors,
                    }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneCategory: actionCreators.getOneCategory.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
    updateForm: actionCreators.updateForm.create,
    putCategory: actionCreators.putCategory.create,
};

const mapStateToProps = ({ HowToCategory, form }) => ({
    initialValues: HowToCategory.category,
    errorRes: HowToCategory.errorRes,
    currentFormType: HowToCategory.currentFormType,
    formObject: form.Category ? form.Category : { values: HowToCategoryValues },
});

export default connect(mapStateToProps, dispatchToProps)(EditHowToCategoryContainer);
