import { Fragment } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { header } from "./HeaderStyles";
import { Button } from "../Button";

const Header = ({ children }) => (
    <Fragment>
        <div css={header.background}>
            <div css={header.container}>
                <div css={header.inner}>
                    <div css={header.actions}>
                        <Button href="/" target="_blank" isSmall primary width="200">
                            Sign into the CMS
                        </Button>
                    </div>
                    <h1 css={header.headline}>Knowledge Base</h1>
                    <h2 css={header.subHeadline}>Student Hub support</h2>
                    {children}
                </div>
            </div>
        </div>
    </Fragment>
);

export { Header };
