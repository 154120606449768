import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Paper from "@material-ui/core/Paper";

import { default as CalendarEventsActions } from "../../../store/CalendarAndEvents/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";

import FormByName from "./CalendarSelect/FormByName";
import FormByPlayref from "./CalendarSelect/FormByPlayref";
import globalsCss from "../../../common/styles/globals.css";

class CalendarSelectContainer extends Component {
    componentDidMount() {
        this.props.setTitle("Calendar & Events \u203A Student Calendar Lookup");
        this.props.clearStudentUsers();
    }

    componentWillUnmount() {
        const { clearTitle, clearStudentUsers } = this.props;
        clearTitle();
        clearStudentUsers();
    }

    onSubmit = values => {
        const { selectCalendarSubject, history } = this.props;
        const { searchBy, ...rest } = values;
        const { student } = rest;
        selectCalendarSubject(student);
        history.push("/calendar/browse-events/" + student.id);
    };

    render() {
        const {
            initialValues,
            students,
            getStudentUsers,
            clearStudentUsers,
            searchBy,
        } = this.props;
        const { onSubmit } = this;

        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <FormByName
                            {...{
                                initialValues:
                                    searchBy === "name" ? initialValues : { student: undefined },
                                onSubmit,
                                students,
                                getStudentUsers,
                                clearStudentUsers,
                            }}
                        />

                        <hr />

                        <FormByPlayref
                            {...{
                                initialValues:
                                    searchBy === "playref" ? initialValues : { student: undefined },
                                onSubmit,
                                students,
                                getStudentUsers,
                                clearStudentUsers,
                            }}
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getStudentUsers: AutoCompleteActionCreators.getStudentUsers.create,
    clearStudentUsers: AutoCompleteActionCreators.clearStudentUsers.create,
    selectCalendarSubject: CalendarEventsActions.selectCalendarSubject.create,
};

const mapStateToProps = ({ CalendarEvents, AutoComplete }) => ({
    initialValues: { student: undefined },
    students: AutoComplete.students,
    searchBy: CalendarEvents.searchBy,
});

export default connect(mapStateToProps, dispatchToProps)(CalendarSelectContainer);
