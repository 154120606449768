import React from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTE_OFFICE_HOURS } from ".";
import { isUser, isManager } from "../../../common/services/Auth";
import OfficeHoursAcademicsNewContainer from "../Academics/OfficeHoursAcademicsNewContainer";
import OfficeHoursAcademicsEditContainer from "../Academics/OfficeHoursAcademicsEditContainer";
import OfficeHoursAdministratorsListContainer from "../Administrators/OfficeHoursAdministratorsListContainer";
import OfficeHoursAdministratorsNewContainer from "../Administrators/OfficeHoursAdministratorsNewContainer";
import OfficeHoursAdministratorsEditContainer from "../Administrators/OfficeHoursAdministratorsEditContainer";
import OfficeHoursManageListContainer from "../ManageOfficeHours/OfficeHoursManageListContainer";
import OfficeHoursManageAvailabilityContainer from "../ManageOfficeHours/OfficeHoursManageAvailabilityContainer";
import OfficeHoursPreviewAvailabilityContainer from "../ManageOfficeHours/OfficeHoursPreviewAvailabilityContainer";
import OfficeHoursNewAvailabilityContainer from "../ManageOfficeHours/OfficeHoursNewAvailabilityContainer";
import OfficeHoursEditAvailabilityContainer from "../ManageOfficeHours/OfficeHoursEditAvailabilityContainer";
import OfficeHoursManageTeamListContainer from "../ManageOfficeHours/Team/OfficeHoursManageTeamListContainer";
import OfficeHoursManageTeamAvailabilityContainer from "../ManageOfficeHours/Team/OfficeHoursManageTeamAvailabilityContainer";
import OfficeHoursManageTeamGenericAvailabilityContainer from "../ManageOfficeHours/Team/OfficeHoursManageTeamGenericAvailabilityContainer";
import OfficeHoursNewTeamGenericAvailabilityContainer from "../ManageOfficeHours/Team/OfficeHoursNewTeamGenericAvailabilityContainer";
import OfficeHoursEditTeamGenericAvailabilityContainer from "../ManageOfficeHours/Team/OfficeHoursEditTeamGenericAvailabilityContainer";
import OfficeHoursNewTeamAvailabilityContainer from "../ManageOfficeHours/Team/OfficeHoursNewTeamAvailabilityContainer";
import OfficeHoursEditTeamAvailabilityContainer from "../ManageOfficeHours/Team/OfficeHoursEditTeamAvailabilityContainer";
import OfficeHoursBrowseContainer from "../ManageOfficeHours/OfficeHoursBrowseContainer";
import OfficeHoursBrowseTeamContainer from "../ManageOfficeHours/Team/OfficeHoursBrowseTeamContainer";
import OfficeHoursDefaultAvailabilityContainer from "../ManageOfficeHours/OfficeHoursDefaultAvailabilityContainer";
import OfficeHoursSessionDetailsContainer from "../ManageOfficeHours/OfficeHoursSessionDetailsContainer";
import OfficeHoursAppointmentsListContainer from "../MyAppointments/OfficeHoursAppointmentsListContainer";
import OfficeHoursAppointmentsDetailsContainer from "../MyAppointments/OfficeHoursAppointmentsDetailsContainer";
import OfficeHoursManageTeamsListContainer from "../ManageTeams/OfficeHoursManageTeamsListContainer";
import OfficeHoursManageTeamsNewContainer from "../ManageTeams/OfficeHoursManageTeamsNewContainer";
import OfficeHoursManageTeamsEditContainer from "../ManageTeams/OfficeHoursManageTeamsEditContainer";
import OfficeHoursAcademicsListContainer from "../Academics/OfficeHoursAcademicsListContainer";
import { ROUTE_OFFICE_HOURS_REPORTS } from "../Reports/routes";
import OfficeHoursReportRoutes from "../Reports/routes/OfficeHoursReportRoutes";
import { GENERIC_AVAILABILITY } from "../ManageOfficeHours/types";

const OfficeReportsRoutes = () => (
    <Switch>
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability`}
            component={isUser(OfficeHoursManageListContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/:userId/manage`}
            component={isUser(OfficeHoursManageAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/:userId/preview`}
            component={isUser(OfficeHoursPreviewAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/:userId/new`}
            component={isUser(OfficeHoursNewAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/:userId/edit/:id`}
            component={isUser(OfficeHoursEditAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/:userId/template`}
            component={isUser(OfficeHoursDefaultAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/team`}
            component={isUser(OfficeHoursManageTeamListContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/team/:teamId/${GENERIC_AVAILABILITY}/manage`}
            component={isUser(OfficeHoursManageTeamGenericAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/team/:teamId/:teamMemberId/manage`}
            component={isUser(OfficeHoursManageTeamAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/team/:teamId/${GENERIC_AVAILABILITY}/new`}
            component={isUser(OfficeHoursNewTeamGenericAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/team/:teamId/:teamMemberId/new`}
            component={isUser(OfficeHoursNewTeamAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/team/:teamId/${GENERIC_AVAILABILITY}/edit/:id`}
            component={isUser(OfficeHoursEditTeamGenericAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/team/:teamId/:teamMemberId/edit/:id`}
            component={isUser(OfficeHoursEditTeamAvailabilityContainer)}
        />

        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/availability/team/:teamId/:teamMemberId/preview`}
            component={isUser(OfficeHoursPreviewAvailabilityContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/browse/:userId`}
            component={isUser(OfficeHoursBrowseContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/browse/team/:teamId/:teamMemberId`}
            component={isUser(OfficeHoursBrowseTeamContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/browse/session/:id`}
            component={isUser(OfficeHoursSessionDetailsContainer)}
        />

        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/appointments`}
            component={isUser(OfficeHoursAppointmentsListContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/appointments/:id`}
            component={isUser(OfficeHoursAppointmentsDetailsContainer)}
        />

        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/manage-teams`}
            component={isManager(OfficeHoursManageTeamsListContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/manage-teams/new`}
            component={isManager(OfficeHoursManageTeamsNewContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/manage-teams/edit/:id`}
            component={isManager(OfficeHoursManageTeamsEditContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/administrators`}
            component={isManager(OfficeHoursAdministratorsListContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/administrators/new`}
            component={isManager(OfficeHoursAdministratorsNewContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/administrators/edit/:id`}
            component={isManager(OfficeHoursAdministratorsEditContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/administrators/academics`}
            component={isManager(OfficeHoursAcademicsListContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/administrators/academics/new`}
            component={isManager(OfficeHoursAcademicsNewContainer)}
        />
        <Route
            exact
            path={`${ROUTE_OFFICE_HOURS}/administrators/academics/edit/:id`}
            component={isManager(OfficeHoursAcademicsEditContainer)}
        />
        <Route path={ROUTE_OFFICE_HOURS_REPORTS} component={isUser(OfficeHoursReportRoutes)} />
    </Switch>
);

export default OfficeReportsRoutes;
