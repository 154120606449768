import ActionCreator from "../actionCreator";

export default {
    setTitle: ActionCreator("CMS_SUPPORT_UI_SET_TITLE"),
    clearTitle: ActionCreator("CMS_SUPPORT_UI_CLEAR_TITLE"),
    getHomepage: ActionCreator("CMS_SUPPORT_GET_HOMEPAGE"),
    createHomepage: ActionCreator("CMS_SUPPORT_CREATE_HOMEPAGE"),
    downloadGuide: ActionCreator("CMS_SUPPORT__DOWNLOAD_GUIDE"),
    getKnowledgeBaseCategories: ActionCreator("CMS_SUPPORT_GET_KB_CATEGORIES"),
    updateKnowledgeBaseCategories: ActionCreator("CMS_SUPPORT_UPDATE_KB_CATEGORIES"),
    getKnowledgeBaseArticles: ActionCreator("CMS_SUPPORT_GET_KB_ARTICLES"),
    updateKnowledgeBaseArticles: ActionCreator("CMS_SUPPORT_UPDATE_KB_ARTICLES"),
    getOneKnowledgeBaseArticle: ActionCreator("CMS_SUPPORT_GET_ONE_KB_ARTICLE"),
    getIntroKnowledgeBaseArticle: ActionCreator("CMS_SUPPORT_GET_INTRO_KB_ARTICLE"),
    updateOneOneKnowledgeBaseArticle: ActionCreator("CMS_SUPPORT_UPDATE_ONE_KB_ARTICLE"),
    clearKnowledgeBaseArticle: ActionCreator("CMS_SUPPORT_CLEAR_KB_ARTICLE"),
    errorResponse: ActionCreator("CMS_SUPPORT_ERROR_RESPONSE"),
};
