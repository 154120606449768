import { Component } from "react";
import { reduxForm, Fields } from "redux-form";
import { RenderFileInput } from "../../../../common/components/FormFields";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button } from "@material-ui/core";
import formsCss from "../../../../common/styles/forms.css";
import globalsCss from "../../../../common/styles/globals.css";

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const { handleSubmit, errors, downloadTemplate } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <hr css={globalsCss.hrDialog} />
                <br />
                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                        <label css={formsCss.label}>
                            <strong>1</strong>. Download template file
                        </label>
                        {downloadTemplate}
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                        <div>
                            <label css={formsCss.label}>
                                <strong>2</strong>. Select file to upload{" "}
                                <span css={formsCss.labelHelp}>(.xlsx)</span>
                            </label>
                            <Fields
                                names={["file"]}
                                errorRes={errors.file}
                                component={RenderFileInput}
                            />
                        </div>
                    </div>
                </div>

                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        Upload Staff
                    </Button>
                </div>
            </form>
        );
    }
}

export const validate = values => {
    let errors = {};

    if (!values.file) {
        errors.file = "Required";
    }

    return errors;
};

const form = reduxForm({
    form: "LseStaffUploadForm",
    enableReinitialize: true,
    validate,
})(Form);

export default form;
