import React, { FC, useEffect } from "react";
import { BuildingDetails } from "src/app/CampusMapping/types/Building";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    FormProvider,
    RHFTextField,
    RHFWysiwyg,
    RHFWysiwygTypes,
    RHFFileUpload,
} from "src/common/components/RHF-Fields";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { CurrentFormType } from "src/types/generic";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "src/common/styles/globals.css";
import formsCss from "src/common/styles/forms.css";

export type CampusMappingFormValues = BuildingDetails;

const CampusMappingSchema = () =>
    yup.object().shape({
        name: yup
            .string()
            .typeError("Required")
            .required("Required"),
        address: yup
            .string()
            .typeError("Required")
            .required("Required"),
        city: yup
            .string()
            .typeError("Required")
            .required("Required"),
        postcode: yup
            .string()
            .typeError("Required")
            .required("Required"),
        latitude: yup
            .string()
            .typeError("Required")
            .required("Required"),
        imageFile: yup.mixed().test("hasImage", "Required", function(value) {
            return !(!value && (this.parent.imageRemove || !this.parent.imageUrl));
        }),
    });

interface OwnProps {
    initialValues: BuildingDetails;
    formType: CurrentFormType;
    error?: string;
    errors?: { [key: string]: string };
    onSubmit: (data: CampusMappingFormValues) => void;
    push: (pathname: string) => void;
}

const CampusMappingForm: FC<OwnProps> = ({
    initialValues,
    formType,
    error,
    errors,
    onSubmit,
    push,
}) => {
    const methods = useForm<BuildingDetails>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(CampusMappingSchema()),
        defaultValues: initialValues,
    });
    const { handleSubmit, formState, reset } = methods;

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    return (
        <Paper elevation={1}>
            <div css={globalsCss.inner}>
                <h2 css={globalsCss.subtitleTitle}>
                    {formType === CurrentFormType.NEW ? "Add new location" : "Edit location"}
                </h2>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <RHFTextField
                                name="name"
                                label={"Name"}
                                errorRes={errors && errors.name}
                            />
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <RHFTextField
                                name="code"
                                label={"LSE Code"}
                                errorRes={errors && errors.code}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <RHFTextField
                                name="address"
                                label={"Address"}
                                errorRes={errors && errors.address}
                            />
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <RHFTextField
                                name="city"
                                label={"City"}
                                errorRes={errors && errors.city}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <RHFTextField
                                name="postcode"
                                label={"Postcode"}
                                errorRes={errors && errors.postcode}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <RHFTextField
                                name="latitude"
                                label={"Latitude"}
                                errorRes={errors && errors.latitude}
                            />
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <RHFTextField
                                name="longitude"
                                label={"Longitude"}
                                errorRes={errors && errors.longitude}
                            />
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="col-xs-12">
                            <div css={globalsCss.helpers.mt35}>
                                <RHFWysiwyg
                                    name="description"
                                    label={"Description"}
                                    type={RHFWysiwygTypes.Simple}
                                    errorRes={errors && errors.description}
                                />
                            </div>
                        </div>
                    </div>

                    <hr />
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <label css={formsCss.label} htmlFor="profilePhoto">
                                Image <small css={formsCss.labelHelp}>(min. 747px/420px)</small>
                            </label>
                            <RHFFileUpload
                                name={"imageFile"}
                                url={"imageUrl"}
                                remove={"imageRemove"}
                                errorRes={errors && errors.imageFile}
                            />
                        </div>
                    </div>

                    {error && <strong>{error}</strong>}
                    <div css={formsCss.actions}>
                        <Button
                            disabled={formState.isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            css={formsCss.btnSubmit}
                        >
                            {formType === CurrentFormType.NEW
                                ? "Add New Building"
                                : "Save Building"}
                        </Button>
                        <Button
                            disabled={formState.isSubmitting}
                            onClick={() => push("/campus-mapping")}
                            css={formsCss.btnBack}
                        >
                            Back to the list
                        </Button>
                    </div>
                </FormProvider>
            </div>
        </Paper>
    );
};

export default CampusMappingForm;
