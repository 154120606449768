import ActionCreator from "../actionCreator";

export default {
    getPersistentEvents: ActionCreator("GET_PERSISTENT_EVENTS"),
    updatePersistentEvents: ActionCreator("UPDATE_PERSISTENT_EVENTS"),
    loadMorePersistentEvents: ActionCreator("LOAD_MORE_PERSISTENT_EVENT"),
    updateMorePersistentEvents: ActionCreator("UPDATE_MORE_PERSISTENT_EVENTS"),
    getPersistentEvent: ActionCreator("GET_PERSISTENT_EVENT"),
    setPersistentEvent: ActionCreator("UPDATE_PERSISTENT_EVENT"),
    deletePersistentEvents: ActionCreator("DELETE_PERSISTENT_EVENT"),
    updateDeletedPersistentEvents: ActionCreator("UPDATE_DELETED_PERSISTENT_EVENTS"),
    postPersistentEvents: ActionCreator("POST_PERSISTENT_EVENT"),
    putPersistentEvents: ActionCreator("PUT_PERSISTENT_EVENT"),
    errorResponse: ActionCreator("ERROR_RESPONSE_PERSISTENT_EVENT"),
    clearQueryParams: ActionCreator("CLEAR_QUERY_PARAMS_PERSISTENT_EVENT"),
    archivePersistentEvent: ActionCreator("ARCHIVE_PERSISTENT_EVENT"),
    updateArchivedPersistentEvent: ActionCreator("ARCHIVE_UPDATE_ARCHIVED_PERSISTENT_EVENT"),
};
