import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Academics/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as administratorsActionCreators } from "../../../store/OfficeHours/Administrators/actionCreators";
import { TableComponent, RedirectButton } from "../../../common/components/OfficeHours";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { prepareFilterQuery } from "../../../common/services/utils";
import { updateListOnFilterChange } from "../../../common/services/FilterList";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";

import PromptDialog from "../../../common/components/PromptDialog";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";

import FilterRow from "../../../common/components/FilterRow";
import TabsComponent from "../../../common/components/Tabs";
import formsCss from "../../../common/styles/forms.css";
import { EditIcon, DeleteIcon, AutoRenewIcon } from "../../../common/components/icons";
import { booleanTypeCallback } from "../../../common/services/tableCallbacks";

const callbacks = {
    hasBookings: booleanTypeCallback,
};

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            academicName: "Academic Name",
            administrators: "Bookings Administrator(s)",
            hasBookings: "Can hold office hours?",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            hasBookings: tableCss.columnWidth200,
            academicName: tableCss.columnWidth300,
        },
    },
];

const buttons = items => index => [
    {
        path: `/bookings/administrators/academics/edit/${items[index] ? items[index].id : ""}`,
        label: "Edit",
        fnLabel: "push",
        icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
    },
    {
        path: `${items[index] ? items[index].id : null}`,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

class OfficeHoursAcademicsListContainer extends Component {
    state = {
        filter: "",
        filter$: new Subject(),
        removeDialog: false,
        removeId: null,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) {
            this.deleteAcademic(this.state.removeId);
        }
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    deleteAcademic = id => {
        const { rawAcademics, removeOfficeHoursAcademicFromAdmin } = this.props;
        const details = rawAcademics.filter(item => Number(item.id) === Number(id))[0];

        const lastToRemove = details.administrators.slice(-1)[0];
        for (let item of details.administrators) {
            removeOfficeHoursAcademicFromAdmin({
                academicId: details.id,
                id: item.id,
                redirectToListAcademics: lastToRemove.id === item.id,
            });
        }
    };

    componentDidMount() {
        const { setTitle, getListOfficeHoursAcademics } = this.props;
        const { filter$ } = this.state;

        setTitle("Bookings \u203A Associated Academics");
        getListOfficeHoursAcademics(prepareFilterQuery(this.state.filter));
        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            this.setState(
                updateListOnFilterChange({
                    getFn: getListOfficeHoursAcademics,
                    filter: term,
                })
            );
        });
    }

    onFilterChangeHandler = ({ target: { value } }) => {
        const { filter$ } = this.state;
        this.setState({ filter: value }, () => filter$.next({ filter: this.state.filter }));
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMoreOfficeHoursAcademics } = this.props;
        const { filter } = this.state;
        loadMoreOfficeHoursAcademics(
            prepareFilterQuery({
                filter,
                page,
            })
        );
    };

    componentWillUnmount() {
        this.props.clearTitle();
    }

    remove = itemID => {
        this.handleOpen(itemID);
    };

    render() {
        const {
            academics,
            history: { push },
            nextPage,
            count,
        } = this.props;
        const { filter } = this.state;
        const { onFilterChangeHandler, onLoadMoreClickHandler, remove } = this;

        return (
            <div>
                <Paper elevation={1}>
                    <TabsComponent
                        activeIndex={1}
                        tabs={[
                            {
                                label: "Administrators",
                                selected: false,
                                fnLabel: "push",
                                param: `/bookings/administrators`,
                            },
                            {
                                label: "Associated academics",
                                selected: true,
                                fnLabel: "push",
                                param: `bookings/administrators/academics`,
                            },
                        ]}
                        fns={{ push }}
                    />
                </Paper>

                <div css={globalsCss.actions}>
                    <RedirectButton
                        label="Add new managed academic"
                        url={`/bookings/administrators/academics/new`}
                        {...{ push }}
                    />
                </div>
                <Paper elevation={1}>
                    <FilterRow filter={filter} onFilterChangeHandler={onFilterChangeHandler} />
                    <TableComponent
                        items={academics}
                        fns={{ push, remove }}
                        buttons={buttons(academics)}
                        decorators={decorators}
                        callbacks={callbacks}
                    />

                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this academic?
                    </PromptDialog>
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {academics.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListOfficeHoursAcademics: actionCreators.getListOfficeHoursAcademics.create,
    loadMoreOfficeHoursAcademics: actionCreators.loadMoreOfficeHoursAcademics.create,
    removeOfficeHoursAcademicFromAdmin:
        administratorsActionCreators.removeOfficeHoursAcademicFromAdmin.create,
};

const mapStateToProps = ({ OfficeHoursAcademics }) => ({
    academics: OfficeHoursAcademics.academics.data.map(item => {
        return {
            id: item.id,
            academicName: `${item.firstName} ${item.lastName}`,
            hasBookings: item.hasBookings,
            administrators: item.administrators
                .map(admin => `${admin.firstName} ${admin.lastName}`)
                .join(", "),
        };
    }),
    rawAcademics: OfficeHoursAcademics.academics.data,
    count: OfficeHoursAcademics.academics.count,
    nextPage: OfficeHoursAcademics.academics.nextPage,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursAcademicsListContainer);
