import { of } from "rxjs";
import { mergeMap, switchMap, catchError, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";
import { push } from "connected-react-router";

const buildingStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListBuildings.type,
            actionCreators.removeBuilding.type,
            actionCreators.putBuilding.type,
            actionCreators.getOneBuilding.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const buildingClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListBuildings.type,
            actionCreators.updateOneBuilding.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListBuildingsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListBuildings.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl(`manager/campus-mapping/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.createListBuildings.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const removeBuildingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeBuilding.type),
        switchMap(action =>
            ajax.remove(ajax.apiUrl(`manager/campus-mapping/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.getListBuildings.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneBuildingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneBuilding.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`manager/campus-mapping/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOneBuilding.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newBuildingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postBuilding.type),
        switchMap(action =>
            ajax
                .post(ajax.apiUrl("manager/campus-mapping/"), action.payload)
                .pipe(
                    mapTo(actionCreators.loadListBuildings.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const putBuildingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putBuilding.type),
        switchMap(action =>
            ajax
                .put(ajax.apiUrl(`manager/campus-mapping/${action.payload.id}/`), action.payload)
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.loadListBuildings.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadListBuildingsEpic = action$ =>
    action$.pipe(ofType(actionCreators.loadListBuildings.type), mapTo(push("/campus-mapping")));

export const epics = combineEpics(
    getListBuildingsEpic,
    removeBuildingEpic,
    buildingStartLoadingEpic,
    buildingClearLoadingEpic,
    newBuildingEpic,
    loadListBuildingsEpic,
    putBuildingEpic,
    getOneBuildingEpic
);
