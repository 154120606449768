import { ROUTE_OFFICE_HOURS } from "../../routes";

export const ROUTE_OFFICE_HOURS_REPORTS = `${ROUTE_OFFICE_HOURS}/reports`;
export const ROUTE_OFFICE_HOURS_REPORTS_BOOKING = `${ROUTE_OFFICE_HOURS_REPORTS}/booking-report`;
export const ROUTE_OFFICE_HOURS_REPORTS_STUDENT_HISTORY = `${ROUTE_OFFICE_HOURS_REPORTS}/student-history-report`;
export const ROUTE_OFFICE_HOURS_REPORTS_TEAM_HISTORY = `${ROUTE_OFFICE_HOURS_REPORTS}/team-history-report`;
export const ROUTE_OFFICE_HOURS_REPORTS_ACADEMIC_AVAILABILITY = `${ROUTE_OFFICE_HOURS_REPORTS}/academic-availability`;
export const ROUTE_OFFICE_HOURS_REPORTS_TEAM_AVAILABILITY = `${ROUTE_OFFICE_HOURS_REPORTS}/team-availability`;
export const ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_STUDENT_BOOKING_HISTORY = `${ROUTE_OFFICE_HOURS_REPORTS}/global-student-booking-history`;
export const ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_ACADEMIC_AVAILABILITY = `${ROUTE_OFFICE_HOURS_REPORTS}/global-academic-availability`;
export const ROUTE_OFFICE_HOURS_REPORTS_STUDENT_BOOKING_HISTORY_WITH_ME = `${ROUTE_OFFICE_HOURS_REPORTS}/student-bookings-history-with-me`;
