import { Component } from "react";
import { reduxForm, Field, Fields } from "redux-form";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import validate from "./validate";
import {
    RenderTextField,
    RenderAutocomplete,
    RenderFileInput,
} from "../../../../common/components/FormFields";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button } from "@material-ui/core";
import formsCss from "../../../../common/styles/forms.css";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";
import globalsCss from "../../../../common/styles/globals.css";

class Form extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const { getUsers, clearUsers } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            if (query !== "") {
                rawUpdateListOnFilterChange({
                    getFn: getUsers,
                    filter: { filter: query },
                });
            } else clearUsers();
        });
        this.props.initialize(this.props.initialValues);
    }

    onChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () => searchText$.next(this.state.searchText));
    };

    render() {
        const { handleSubmit, students, clearUsers, errors } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-xs-12">
                        <label css={formsCss.labelNoMargin}>Student name</label>
                    </div>
                    <div className="col-xs-12">
                        <Field
                            name="users"
                            errorRes={errors.users}
                            handleInput={this.onChangeHandler}
                            dataSource={students}
                            component={RenderAutocomplete}
                            single
                            onDroppedFocus={() => clearUsers()}
                            labelCallback={item => `${item.firstName} ${item.lastName}`}
                            floatingLabelText={"Full name or email address"}
                        />
                    </div>
                    <div className="col-xs-12">
                        <Field
                            name="note"
                            error={errors.note}
                            component={RenderTextField}
                            label="Note"
                            fullWidth
                            multiline
                            rows={5}
                            rowsMax={5}
                        />
                    </div>
                    <div className="col-xs-12">
                        <div css={globalsCss.helpers.mt15}>
                            <Fields
                                names={["attachmentFile", "attachmentUrl"]}
                                errorRes={errors.attachmentFile}
                                component={RenderFileInput}
                            />
                        </div>
                    </div>
                </div>

                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        Book now
                    </Button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: "OfficeHoursBooking",
    enableReinitialize: true,
    validate,
})(Form);

export default form;
