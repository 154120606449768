import ActionCreator from "../../../actionCreator";

export default {
    getListFollowers: ActionCreator("CHANNEL_PAGE_GET_LIST_FOLLOWERS"),
    createListFollowers: ActionCreator("CHANNEL_PAGE_CREATE_LIST_FOLLOWERS"),
    loadMoreFollowers: ActionCreator("CHANNEL_PAGE_LOAD_MORE_FOLLOWERS"),
    updateListFollowers: ActionCreator("CHANNEL_PAGE_UPDATE_LIST_FOLLOWERS"),
    clearListFollowers: ActionCreator("CHANNEL_PAGE_CLEAR_LIST_FOLLOWERS"),
    postPageFollower: ActionCreator("CHANNEL_PAGE_POST_FOLLOWER"),
    removePageFollower: ActionCreator("CHANNEL_PAGE_REMOVE_FOLLOWER"),
    redirectToFollowersList: ActionCreator("CHANNEL_PAGE_REDIRECT_TO_FOLLOWERS_LIST"),
    errorResponse: ActionCreator("CHANNEL_PAGE_ERROR_RESPONSE_FOLLOWER"),
};
