import actionCreators from "./actionCreators";
import { mergeByIds } from "./reducer.helper";

export const initialState = {
    comments: [],
    comment: {},
    page: 1,
    postId: null,
    hasMore: false,
    listParentId: null,
    errorRes: { error: "", errors: {} },
};

const removeComment = (comments, removedId) =>
    comments.map(comment => {
        return {
            ...comment,
            isDeleted: !comment.isDeleted ? comment.id === removedId : comment.isDeleted,
        };
    });

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateComments.type:
            return {
                ...state,
                page: state.page + 1,
                comments: mergeByIds(state.comments, payload.data),
                hasMore: payload.nextPage > 0,
                listParentId: payload.parentId,
            };
        case actionCreators.removeComment.type:
            return {
                ...state,
                comments: removeComment(state.comments, payload),
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.clearComments.type:
            return initialState;
        default:
            return state;
    }
};
