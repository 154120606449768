export default values => {
    const errors = {};
    const requiredFields = ["name", "startDate", "endDate"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    if (values.startDate && values.endDate && values.startDate > values.endDate) {
        errors.endDate = "End date must be later than start date";
    }

    return errors;
};
