import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Sessions/actionCreators";
import { default as availabilitiesActionCreators } from "../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { TableComponent } from "../../../common/components/Channels";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { prepareFilterQuery } from "../../../common/services/utils";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import tableCss from "../../../common/styles/table.css";
import formsCss from "../../../common/styles/forms.css";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import OfficeHoursBrowseFilterRow from "../components/OfficeHoursBrowseFilterRow";
import { MainTitle } from "../../../common/components/MainTitle";
import TabsComponent from "../../../common/components/Tabs";
import { AssignmentIcon, AutoRenewIcon } from "../../../common/components/icons";
import { ACADEMIC, SESSION_BOOKABLE } from "./types";
import moment from "moment-timezone";
import { withQueryParams } from "../../../common/components/withQueryParams";
import { GetFormattedDate, GetFormattedTime } from "../../../common/components/Time";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            title: "Title",
            earliestLatest: "Earliest / Latest Booking",
            availability: "Available?",
            team: "Team",
            date: "Date",
            time: "Time",
        },
    },
];

const buttons = items => index => [
    {
        path: {
            pathname: `/bookings/browse/session/${items[index] ? items[index].id : ""}`,
            state: { context: ACADEMIC },
        },
        label: "Session details",
        fnLabel: "push",
        icon: <AssignmentIcon css={tableCss.actionsMenuItemIcon} />,
    },
];

const earliestBooking = [
    { value: null, label: "" },
    { value: 0, label: "No limit" },
    { value: 24 * 60, label: "1 day in advance" },
    { value: 48 * 60, label: "2 days in advance" },
    { value: 72 * 60, label: "3 days in advance" },
    { value: 96 * 60, label: "4 days in advance" },
    { value: 120 * 60, label: "5 days in advance" },
    { value: 144 * 60, label: "6 days in advance" },
    { value: 168 * 60, label: "7 days in advance" },
    { value: 192 * 60, label: "8 days in advance" },
    { value: 216 * 60, label: "9 days in advance" },
    { value: 240 * 60, label: "10 days in advance" },
    { value: 264 * 60, label: "11 days in advance" },
    { value: 288 * 60, label: "12 days in advance" },
    { value: 312 * 60, label: "13 days in advance" },
    { value: 336 * 60, label: "14 days in advance" },
];

const latestBooking = [
    { value: null, label: "" },
    { value: 0, label: "No limit" },
    { value: 30, label: "30 minutes in advance" },
    { value: 1 * 60, label: "1 hour in advance" },
    { value: 2 * 60, label: "2 hours in advance" },
    { value: 3 * 60, label: "3 hours in advance" },
    { value: 4 * 60, label: "4 hours in advance" },
    { value: 5 * 60, label: "5 hours in advance" },
    { value: 6 * 60, label: "6 hours in advance" },
    { value: 12 * 60, label: "12 hours in advance" },
    { value: 24 * 60, label: "24 hours in advance" },
    { value: 48 * 60, label: "48 hours in advance" },
    { value: 72 * 60, label: "3 days in advance" },
    { value: 96 * 60, label: "4 days in advance" },
    { value: 120 * 60, label: "5 days in advance" },
    { value: 144 * 60, label: "6 days in advance" },
    { value: 168 * 60, label: "7 days in advance" },
];

class OfficeHoursBrowseContainer extends Component {
    state = {
        filter$: new Subject(),
        initiated: false,
        isFromError: null,
        isToError: null,
    };

    componentDidMount() {
        const {
            setTitle,
            getOneMyOfficeHoursAcademic,
            match,
            academic,
            teams,
            getMyOfficeHoursTeams,
        } = this.props;
        setTitle("Bookings \u203A Manage Availability");

        if (!academic || !teams) {
            if (!academic) getOneMyOfficeHoursAcademic(match.params.userId);
            if (!teams) getMyOfficeHoursTeams(match.params.userId);
        } else {
            this.initComponent();
        }
    }

    componentDidUpdate(prevProps) {
        const { academic, teams } = this.props;
        const { initiated } = this.state;
        if (
            (teams,
            !prevProps.academic &&
                academic &&
                academic.id > 0 &&
                prevProps.academic !== academic &&
                !initiated)
        ) {
            this.initComponent();
        }
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    initComponent() {
        const { getOfficeHoursSessions, academic, queryParams } = this.props;
        const { filter$ } = this.state;

        this.setState({ initiated: true }, () => {
            const params = this.props.initWithQueryParams({
                filter: "",
                from: "",
                to: "",
                showPast: false,
                page: 1,
                academicId: academic.id,
            });

            if (queryParams !== prepareFilterQuery(params)) {
                getOfficeHoursSessions({
                    academicId: academic.id,
                    query: prepareFilterQuery({ ...params, page: 1 }),
                    showPast: params.showPast,
                });
            }
            filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
                const { showPast, ...term } = value;
                const params = this.props.setQueryParams({ ...term, page: 1 });
                getOfficeHoursSessions({
                    academicId: academic.id,
                    showPast,
                    query: prepareFilterQuery(params),
                });
            });
        });
    }

    handler = ({ field, value }) => {
        const { filter$ } = this.state;

        if (field === "from" || field === "to") {
            const newParams = {
                ...this.props.getQueryParams(),
                [field]: value ? value.toISOString() : "",
            };
            const { showPast, from, to } = newParams;

            let isFromError;
            let isToError;

            if (!showPast) {
                isFromError =
                    moment(from).diff(
                        moment().set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                    ) < 0
                        ? "From date must not be in the past"
                        : null;
                isToError =
                    moment(to).diff(
                        moment().set({ hour: 23, minute: 59, second: 59, milliseconds: 0 })
                    ) < 0
                        ? "To date must not be in the past"
                        : null;

                if (
                    !isFromError &&
                    !isToError &&
                    moment(from)
                        .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                        .diff(
                            moment(to).set({
                                hour: 0,
                                minute: 0,
                                second: 0,
                                milliseconds: 0,
                            })
                        ) > 0
                ) {
                    isToError = "To date must not be before From date";
                }

                this.setState({ isFromError, isToError });
                if (!isFromError && !isToError) {
                    const finalParams = this.props.setQueryParams({
                        ...newParams,
                        page: 1,
                    });
                    filter$.next(finalParams);
                }
            } else {
                isFromError =
                    moment(from).diff(
                        moment({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                    ) > 0
                        ? "From date must not be in the future"
                        : null;
                isToError =
                    moment(to).diff(
                        moment().set({ hour: 23, minute: 59, second: 59, milliseconds: 0 })
                    ) > 0
                        ? "To date must not be in the future"
                        : null;

                if (
                    !isFromError &&
                    !isToError &&
                    moment(from)
                        .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                        .diff(
                            moment(to).set({
                                hour: 0,
                                minute: 0,
                                second: 0,
                                milliseconds: 0,
                            })
                        ) > 0
                ) {
                    isToError = "To date must not be before From date";
                }

                this.setState({ isFromError, isToError });
                if (!isFromError && !isToError) {
                    const finalParams = this.props.setQueryParams({
                        ...newParams,
                        page: 1,
                    });
                    filter$.next(finalParams);
                }
            }
        } else {
            const params = this.props.setQueryParams({
                [field]: value,
            });
            filter$.next(params);
        }
    };

    onFilterChangeHandler = field => ({ target: { value } }) => {
        this.handler({ field, value });
    };

    onChangePeriod = value => {
        const { getOfficeHoursSessions, academic } = this.props;

        this.setState({ isFromError: null, isToError: null }, () => {
            const params = this.props.setQueryParams({
                filter: "",
                from: "",
                to: "",
                page: 1,
                showPast: value,
            });
            getOfficeHoursSessions({
                academicId: academic.id,
                showPast: value,
                query: prepareFilterQuery(params),
            });
        });
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMoreOfficeHoursSessions, academic } = this.props;
        const params = this.props.setQueryParams({ page });
        const { showPast } = params;
        loadMoreOfficeHoursSessions({
            academicId: academic.id,
            showPast,
            query: prepareFilterQuery(params),
        });
    };

    onDateChangeHandler = field => value => {
        this.handler({ field, value });
    };

    render() {
        const {
            sessions,
            nextPage,
            count,
            history: { push },
            academic,
            teams,
        } = this.props;
        const { isFromError, isToError } = this.state;
        const { filter, showPast, from, to } = this.props.getQueryParams();
        const {
            onFilterChangeHandler,
            onLoadMoreClickHandler,
            onChangePeriod,
            onDateChangeHandler,
        } = this;

        let columns = ["title", "date", "time", "earliestLatest", "availability"];
        if (teams && teams.length > 0) columns = ["team", ...columns];

        return academic && sessions ? (
            <div>
                <MainTitle
                    title={`${academic.firstName} ${academic.lastName}`}
                    type={"[Browse sessions]"}
                />

                <Paper elevation={1}>
                    <TabsComponent
                        activeIndex={1}
                        tabs={[
                            {
                                label: "Availability",
                                selected: false,
                                fnLabel: "push",
                                param: `/bookings/availability/${academic.id}/manage`,
                            },
                            {
                                label: "Browse sessions",
                                selected: true,
                                fnLabel: "push",
                                param: `/bookings/browse/${academic.id}`,
                            },
                        ]}
                        fns={{ push }}
                    />
                </Paper>

                <div>
                    <Paper elevation={1}>
                        <OfficeHoursBrowseFilterRow
                            {...{
                                filter,
                                onFilterChangeHandler,
                                showPast,
                                onChangePeriod,
                                from,
                                to,
                                isFromError,
                                isToError,
                                labelDate: "Filter by session date",
                                onDateChangeHandler,
                            }}
                        />
                        {sessions.length > 0 && (
                            <TableComponent
                                items={sessions}
                                fns={{ push }}
                                buttons={buttons(sessions)}
                                decorators={decorators}
                                columns={["id", ...columns]}
                            />
                        )}
                    </Paper>

                    {sessions.length > 0 && nextPage > 0 && (
                        <div css={tableCss.loadMore}>
                            <Button
                                onClick={onLoadMoreClickHandler(nextPage)}
                                variant="contained"
                                color="secondary"
                            >
                                <AutoRenewIcon css={formsCss.btnIcon} />
                                Load more
                            </Button>
                            <span css={tableCss.loadMoreLabel}>
                                <small>
                                    (showing {sessions.length} out of {count})
                                </small>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneMyOfficeHoursAcademic: availabilitiesActionCreators.getOneMyOfficeHoursAcademic.create,
    getOfficeHoursSessions: actionCreators.getOfficeHoursSessions.create,
    loadMoreOfficeHoursSessions: actionCreators.loadMoreOfficeHoursSessions.create,
    getMyOfficeHoursTeams: availabilitiesActionCreators.getMyOfficeHoursTeams.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities, OfficeHoursSessions }) => {
    return {
        academic: OfficeHoursAvailabilities.academic,
        sessions: OfficeHoursSessions.sessions.data.map(item => {
            return {
                id: item.id,
                team: item.teamName ? item.teamName : "-",
                title: item.availabilityRule.title,
                date: GetFormattedDate(item.start, "ddd D MMM YYYY"),
                time: `${GetFormattedTime(item.start, "h.mma")} - ${GetFormattedTime(
                    item.end,
                    "h.mma"
                )}`,
                earliestLatest:
                    item.availabilityRule.mode === SESSION_BOOKABLE
                        ? `${
                              item.availabilityRule.settings.earliestBooking
                                  ? earliestBooking.find(
                                        entry =>
                                            entry.value ===
                                            Number(item.availabilityRule.settings.earliestBooking)
                                    ).label
                                  : "no limit"
                          } / ${
                              item.availabilityRule.settings.latestBooking
                                  ? latestBooking.find(
                                        entry =>
                                            entry.value ===
                                            Number(item.availabilityRule.settings.latestBooking)
                                    ).label
                                  : "no limit"
                          }`
                        : "n/a",
                availability:
                    item.availabilityRule.mode === SESSION_BOOKABLE
                        ? `${item.freeSlots > 0 ? `Yes (${item.freeSlots})` : "No"}`
                        : "Drop-in session",
            };
        }),
        count: OfficeHoursSessions.sessions.count,
        nextPage: OfficeHoursSessions.sessions.nextPage,
        queryParams: OfficeHoursSessions.sessions.queryParams,
        teams: OfficeHoursAvailabilities.teams,
    };
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(withQueryParams(OfficeHoursBrowseContainer));
