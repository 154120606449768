import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/OfficeHours/Sessions/actionCreators";
import { default as availabilitiesActionCreators } from "../../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { TableComponent } from "../../../../common/components/Channels";
import { Dialog, Paper, Button } from "@material-ui/core";
import { prepareFilterQuery } from "../../../../common/services/utils";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import tableCss from "../../../../common/styles/table.css";
import formsCss from "../../../../common/styles/forms.css";

import {
    AssignmentIcon,
    AutoRenewIcon,
    PersonIcon,
    WarningIcon,
} from "../../../../common/components/icons";

import { decorateTableItemsJobTypes } from "../../../../common/services/decorateTableItems";

import OfficeHoursBrowseFilterRow from "../../components/OfficeHoursBrowseFilterRow";
import { MainTitle } from "../../../../common/components/MainTitle";
import TabsComponent from "../../../../common/components/Tabs";

import { GetFormattedDate, GetFormattedTime } from "../../../../common/components/Time";
import {
    GENERIC_AVAILABILITY,
    TEAM,
    GENERIC_AVAILABILITY_ALERT_YELLOW,
    GENERIC_AVAILABILITY_ALERT_RED,
    SESSION_BOOKABLE,
} from "../types";
import moment from "moment-timezone";
import { withQueryParams } from "../../../../common/components/withQueryParams";
import ReassignForm from "../ReassignForm";
import globalsCss from "../../../../common/styles/globals.css";
import { colors } from "../../../../common/styles/colors";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            title: "Title",
            date: "Date",
            time: "Time",
            availability: "Available?",
            academic: "Academic",
        },
    },
];

const callbacks = {
    academic: (item, prop) => {
        return (
            <div>
                {item[prop] ? (
                    <div>
                        {item.isGeneric ? (
                            <div>
                                {item[prop]} <em>(assigned)</em>
                            </div>
                        ) : (
                            item[prop]
                        )}
                    </div>
                ) : (
                    item.isGeneric && (
                        <div>
                            {item.sessionsAlert === GENERIC_AVAILABILITY_ALERT_YELLOW && (
                                <div css={{ display: "flex", alignItems: "center" }}>
                                    <WarningIcon
                                        css={{
                                            color: colors.accentC,
                                            fontSize: 19,
                                            marginRight: 5,
                                        }}
                                    />{" "}
                                    <em>UNASSIGNED</em>
                                </div>
                            )}
                            {item.sessionsAlert === GENERIC_AVAILABILITY_ALERT_RED && (
                                <div css={{ display: "flex", alignItems: "center" }}>
                                    <WarningIcon
                                        css={{
                                            color: colors.accentLSE,
                                            fontSize: 19,
                                            marginRight: 5,
                                        }}
                                    />
                                    <em>UNASSIGNED</em>
                                </div>
                            )}
                        </div>
                    )
                )}
            </div>
        );
    },
};

const buttons = (items, team, userId) => index => [
    {
        path: {
            pathname: `/bookings/browse/session/${items[index] ? items[index].id : ""}`,
            state: { context: TEAM },
        },
        label: "Session details",
        fnLabel: "push",
        icon: <AssignmentIcon css={tableCss.actionsMenuItemIcon} />,
    },
    ...(items[index].isGeneric && team.admins.find(a => a.id === userId)
        ? [
              {
                  path: `${items[index] ? items[index].id : null}`,
                  label: items[index].academic ? "Reassign" : "Assign",
                  fnLabel: "reassign",
                  icon: <PersonIcon css={tableCss.actionsMenuItemIcon} />,
              },
          ]
        : []),
];

const columns = ["id", "academic", "title", "date", "time", "availability"];

const earliestBooking = [
    { value: null, label: "" },
    { value: 0, label: "No limit" },
    { value: 24 * 60, label: "1 day in advance" },
    { value: 48 * 60, label: "2 days in advance" },
    { value: 72 * 60, label: "3 days in advance" },
    { value: 96 * 60, label: "4 days in advance" },
    { value: 120 * 60, label: "5 days in advance" },
    { value: 144 * 60, label: "6 days in advance" },
    { value: 168 * 60, label: "7 days in advance" },
    { value: 192 * 60, label: "8 days in advance" },
    { value: 216 * 60, label: "9 days in advance" },
    { value: 240 * 60, label: "10 days in advance" },
    { value: 264 * 60, label: "11 days in advance" },
    { value: 288 * 60, label: "12 days in advance" },
    { value: 312 * 60, label: "13 days in advance" },
    { value: 336 * 60, label: "14 days in advance" },
];

const latestBooking = [
    { value: null, label: "" },
    { value: 0, label: "No limit" },
    { value: 30, label: "30 minutes in advance" },
    { value: 1 * 60, label: "1 hour in advance" },
    { value: 2 * 60, label: "2 hours in advance" },
    { value: 3 * 60, label: "3 hours in advance" },
    { value: 4 * 60, label: "4 hours in advance" },
    { value: 5 * 60, label: "5 hours in advance" },
    { value: 6 * 60, label: "6 hours in advance" },
    { value: 12 * 60, label: "12 hours in advance" },
    { value: 24 * 60, label: "24 hours in advance" },
    { value: 48 * 60, label: "48 hours in advance" },
    { value: 72 * 60, label: "3 days in advance" },
    { value: 96 * 60, label: "4 days in advance" },
    { value: 120 * 60, label: "5 days in advance" },
    { value: 144 * 60, label: "6 days in advance" },
    { value: 168 * 60, label: "7 days in advance" },
];

class OfficeHoursBrowseTeamContainer extends Component {
    state = {
        filter$: new Subject(),
        initiated: false,
        isFromError: null,
        isToError: null,
        ressignDialog: false,
        ressignId: null,
    };

    componentDidMount() {
        const { setTitle, getOneMyOfficeHoursTeam, match, team } = this.props;
        setTitle("Bookings \u203A Manage Availability");
        if (!team) {
            getOneMyOfficeHoursTeam(match.params.teamId);
        } else {
            this.initComponent();
        }
    }

    componentDidUpdate(prevProps) {
        const { team } = this.props;
        const { initiated } = this.state;
        if (!prevProps.team && team && team.id > 0 && prevProps.team !== team && !initiated) {
            this.initComponent();
        }
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    initComponent() {
        const { getOfficeHoursTeamSessions, team, queryParams } = this.props;
        const { filter$ } = this.state;

        this.setState({ initiated: true }, () => {
            const params = this.props.initWithQueryParams({
                filter: "",
                from: "",
                to: "",
                showPast: false,
                page: 1,
                teamId: team.id,
            });

            if (queryParams !== prepareFilterQuery(params)) {
                getOfficeHoursTeamSessions({
                    teamId: team.id,
                    query: prepareFilterQuery({ ...params, page: 1 }),
                    showPast: params.showPast,
                });
            }

            filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
                const { showPast, ...term } = value;
                const params = this.props.setQueryParams({ ...term, page: 1 });
                getOfficeHoursTeamSessions({
                    teamId: team.id,
                    showPast,
                    query: prepareFilterQuery(params),
                });
            });
        });
    }

    handler = ({ field, value }) => {
        const { filter$ } = this.state;

        if (field === "from" || field === "to") {
            const newParams = {
                ...this.props.getQueryParams(),
                [field]: value ? value.toISOString() : "",
            };
            const { showPast, from, to } = newParams;

            let isFromError;
            let isToError;

            if (!showPast) {
                isFromError =
                    moment(from).diff(
                        moment().set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                    ) < 0
                        ? "From date must not be in the past"
                        : null;
                isToError =
                    moment(to).diff(
                        moment().set({ hour: 23, minute: 59, second: 59, milliseconds: 0 })
                    ) < 0
                        ? "To date must not be in the past"
                        : null;

                if (
                    !isFromError &&
                    !isToError &&
                    moment(from)
                        .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                        .diff(
                            moment(to).set({
                                hour: 0,
                                minute: 0,
                                second: 0,
                                milliseconds: 0,
                            })
                        ) > 0
                ) {
                    isToError = "To date must not be before From date";
                }

                this.setState({ isFromError, isToError });
                if (!isFromError && !isToError) {
                    const finalParams = this.props.setQueryParams({
                        ...newParams,
                        page: 1,
                    });
                    filter$.next(finalParams);
                }
            } else {
                isFromError =
                    moment(from).diff(
                        moment({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                    ) > 0
                        ? "From date must not be in the future"
                        : null;
                isToError =
                    moment(to).diff(
                        moment().set({ hour: 23, minute: 59, second: 59, milliseconds: 0 })
                    ) > 0
                        ? "To date must not be in the future"
                        : null;

                if (
                    !isFromError &&
                    !isToError &&
                    moment(from)
                        .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            milliseconds: 0,
                        })
                        .diff(
                            moment(to).set({
                                hour: 0,
                                minute: 0,
                                second: 0,
                                milliseconds: 0,
                            })
                        ) > 0
                ) {
                    isToError = "To date must not be before From date";
                }

                this.setState({ isFromError, isToError });
                if (!isFromError && !isToError) {
                    const finalParams = this.props.setQueryParams({
                        ...newParams,
                        page: 1,
                    });
                    filter$.next(finalParams);
                }
            }
        } else {
            const params = this.props.setQueryParams({
                [field]: value,
            });
            filter$.next(params);
        }
    };

    onFilterChangeHandler = field => ({ target: { value } }) => {
        this.handler({ field, value });
    };

    onChangePeriod = value => {
        const { getOfficeHoursTeamSessions, team } = this.props;

        this.setState({ isFromError: null, isToError: null }, () => {
            const params = this.props.setQueryParams({
                filter: "",
                from: "",
                to: "",
                page: 1,
                showPast: value,
            });

            getOfficeHoursTeamSessions({
                teamId: team.id,
                query: prepareFilterQuery(params),
                showPast: value,
            });
        });
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMoreOfficeHoursTeamSessions, team } = this.props;
        const params = this.props.setQueryParams({ page });
        const { showPast } = params;
        loadMoreOfficeHoursTeamSessions({
            teamId: team.id,
            showPast,
            query: prepareFilterQuery(params),
        });
    };

    onDateChangeHandler = field => value => {
        this.handler({ field, value });
    };

    reassign = itemID => {
        this.handleOpen(itemID);
    };

    handleOpen = itemId => {
        this.setState({ ressignDialog: true });
        this.setState({ ressignId: itemId });
    };

    handleClose = () => {
        this.setState({ ressignDialog: false });
        this.setState({ ressignId: null });
    };

    onRessignAvailability = values => {
        const { assignOfficeHoursGenericAvailabilitySession } = this.props;
        assignOfficeHoursGenericAvailabilitySession({
            id: this.state.ressignId,
            teamMemberId: values.teamMemberId,
        });
        this.handleClose();
    };

    render() {
        const {
            sessions,
            nextPage,
            count,
            history: { push },
            team,
            match,
            userId,
        } = this.props;
        const { isFromError, isToError, ressignId } = this.state;
        const { filter, showPast, from, to } = this.props.getQueryParams();

        const {
            onFilterChangeHandler,
            onLoadMoreClickHandler,
            onChangePeriod,
            onDateChangeHandler,
            reassign,
        } = this;

        return team && sessions ? (
            <div>
                <MainTitle title={team.name} type={"[Browse Team Sessions]"} />

                <Paper elevation={1}>
                    <TabsComponent
                        activeIndex={1}
                        tabs={[
                            {
                                label: "Availability",
                                selected: false,
                                fnLabel: "push",
                                param:
                                    match.params.teamMemberId > 0
                                        ? `/bookings/availability/team/${team.id}/${match.params.teamMemberId}/manage`
                                        : `/bookings/availability/team/?${team.id}`,
                            },
                            {
                                label: "Browse Team Sessions",
                                selected: true,
                                fnLabel: "push",
                                param: `/bookings/browse/team/${team.id}/${match.params.teamMemberId}`,
                            },
                        ]}
                        fns={{ push }}
                    />
                </Paper>

                <div>
                    <Paper elevation={1}>
                        <OfficeHoursBrowseFilterRow
                            {...{
                                filter,
                                onFilterChangeHandler,
                                showPast,
                                onChangePeriod,
                                from,
                                to,
                                isFromError,
                                isToError,
                                labelDate: "Filter by session date",
                                onDateChangeHandler,
                            }}
                        />
                        {sessions.length > 0 && (
                            <TableComponent
                                items={sessions}
                                fns={{ push, reassign }}
                                buttons={buttons(sessions, team, userId)}
                                decorators={decorators}
                                columns={columns}
                                callbacks={callbacks}
                            />
                        )}
                    </Paper>

                    {sessions.length > 0 && nextPage > 0 && (
                        <div css={tableCss.loadMore}>
                            <Button
                                onClick={onLoadMoreClickHandler(nextPage)}
                                variant="contained"
                                color="secondary"
                            >
                                <AutoRenewIcon css={formsCss.btnIcon} />
                                Load more
                            </Button>
                            <span css={tableCss.loadMoreLabel}>
                                <small>
                                    (showing {sessions.length} out of {count})
                                </small>
                            </span>
                        </div>
                    )}
                </div>

                <Dialog
                    open={this.state.ressignDialog}
                    onClose={() => this.handleClose()}
                    maxWidth="sm"
                    fullWidth
                >
                    <div css={globalsCss.innerDialog}>
                        <h2 css={globalsCss.subtitleTitle}>
                            {sessions.find(s => s.id === parseInt(ressignId))?.academic
                                ? "Reassign"
                                : "Assign"}{" "}
                            this session
                        </h2>

                        <p>This will also move any existing bookings to the selected team member</p>

                        <ReassignForm
                            {...{
                                onSubmit: this.onRessignAvailability,
                                users: [...team.users].map(i => {
                                    return {
                                        value: i.officeHoursTeamMembers.find(
                                            t => t.teamId === team.id && t.userType === "member"
                                        )?.id,
                                        label: `${i.firstName} ${i.lastName}`,
                                    };
                                }),
                                isAssigned: sessions.find(s => s.id === parseInt(ressignId))
                                    ?.academic,
                                onCancel: () => this.handleClose(),
                            }}
                        />
                    </div>
                </Dialog>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneMyOfficeHoursTeam: availabilitiesActionCreators.getOneMyOfficeHoursTeam.create,
    getOfficeHoursTeamSessions: actionCreators.getOfficeHoursTeamSessions.create,
    loadMoreOfficeHoursTeamSessions: actionCreators.loadMoreOfficeHoursTeamSessions.create,
    assignOfficeHoursGenericAvailabilitySession:
        actionCreators.assignOfficeHoursGenericAvailabilitySession.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities, OfficeHoursSessions, Auth }) => ({
    team: OfficeHoursAvailabilities.team,
    sessions: OfficeHoursSessions.sessions.data.map(item => {
        return {
            id: item.id,
            academic: item.academicName ? item.academicName : "",
            title: item.availabilityRule.title,
            date: GetFormattedDate(item.start, "ddd D MMM YYYY"),
            time: `${GetFormattedTime(item.start, "h.mma")} - ${GetFormattedTime(
                item.end,
                "h.mma"
            )}`,
            availability:
                item.availabilityRule.mode === SESSION_BOOKABLE ? (
                    `${item.freeSlots > 0 ? `Yes (${item.freeSlots})` : "No"}`
                ) : (
                    <em>Drop-in Session</em>
                ),
            isGeneric: item.availabilityRule.type === GENERIC_AVAILABILITY,
            sessionsAlert:
                item.availabilityRule.type === GENERIC_AVAILABILITY
                    ? item.slotsCount === item.freeSlots
                        ? GENERIC_AVAILABILITY_ALERT_YELLOW
                        : item.slotsCount > item.freeSlots
                        ? GENERIC_AVAILABILITY_ALERT_RED
                        : undefined
                    : undefined,
        };
    }),
    count: OfficeHoursSessions.sessions.count,
    nextPage: OfficeHoursSessions.sessions.nextPage,
    queryParams: OfficeHoursSessions.sessions.queryParams,
    userId: Auth.id,
});

export default connect(
    mapStateToProps,
    dispatchToProps
)(withQueryParams(OfficeHoursBrowseTeamContainer));
