export default values => {
    const errors = {};

    if (!values["user"] || (values["user"] && Object.keys(values["user"]).length === 0)) {
        errors["user"] = "Required";
    }

    if (!values["academics"] || (values["academics"] && values["academics"].length === 0)) {
        errors["academics"] = "Required";
    }

    return errors;
};
