// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./Form";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import actionCreators from "../../../store/UsersAndAudiences/LseStaff/actionCreators";
import currentFormTypes from "../../../common/components/CurrentFormTypes";
import formsCss from "../../../common/styles/forms.css";
import { MainTitle } from "../../../common/components/MainTitle";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import TabsComponent from "../../../common/components/Tabs";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";
import dashboardCss from "../../../common/styles/dashboard.css";
import { PageIcon, GroupIcon, TimelapseIcon, ScheduleIcon } from "../../../common/components/icons";

class EditStaffContainer extends Component {
    componentDidMount() {
        const { setTitle, getOneStaff, match, setCurrentFormType } = this.props;
        setTitle("LSE Staff");
        getOneStaff({ id: match.params.id });
        setCurrentFormType(currentFormTypes.EDIT);
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    onSubmit = values => {
        if (values.status === "pending") delete values.status;

        this.props.putStaff({ ...values, hasBookings: values.hasBookings === "Y" ? true : false });
        this.props.setLoading();
    };

    setTab = id => {
        const {
            history: { push },
            match,
        } = this.props;
        push({
            pathname: `/users-and-audiences/lse-staff/edit/${match.params.id}`,
            search: `?t=${id}`,
        });
    };

    render() {
        const {
            initialValues,
            errorRes: { error, errors },
            currentFormType,
            history: { push },
            location,
        } = this.props;
        const { onSubmit, setTab } = this;

        return (
            <div>
                <MainTitle title="LSE Staff" type="[Users &amp; Audiences]" />

                <Paper elevation={1}>
                    <TabsComponent
                        activeIndex={!location.search || location.search === "?t=0" ? 0 : 1}
                        tabs={[
                            {
                                label: "Profile",
                                fnLabel: "setTab",
                                param: 0,
                            },
                            {
                                label: "Permissions & Settings",
                                fnLabel: "setTab",
                                param: 1,
                            },
                        ]}
                        fns={{ setTab }}
                    />
                </Paper>

                {!location.search || location.search === "?t=0" ? (
                    <div>
                        <div css={formsCss.genericError}>{error}</div>
                        {initialValues.id && (
                            <Form
                                {...{
                                    initialValues: {
                                        ...initialValues,
                                        hasBookings: initialValues.hasBookings ? "Y" : "N",
                                    },
                                    onSubmit,
                                    currentFormType,
                                    push,
                                    errors,
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <div>
                        <Paper elevation={1}>
                            <div css={globalsCss.inner}>
                                {/* PAGES */}
                                {initialValues.userChannels &&
                                    initialValues.userChannels.filter(
                                        c => c.channelCategory === "page"
                                    ).length > 0 && (
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12 col-xs-12">
                                                    <div css={dashboardCss.section}>
                                                        <PageIcon css={dashboardCss.icon} />
                                                        <strong css={dashboardCss.title}>
                                                            Pages
                                                        </strong>
                                                    </div>
                                                </div>

                                                {initialValues.userChannels.filter(
                                                    c =>
                                                        c.type === "owner" &&
                                                        c.channelCategory === "page"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Owner
                                                        </h4>

                                                        {initialValues.userChannels
                                                            .filter(
                                                                c =>
                                                                    c.type === "owner" &&
                                                                    c.channelCategory === "page"
                                                            )
                                                            .map(page => (
                                                                <div
                                                                    css={dashboardCss.pageItem}
                                                                    key={page.channel.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {page.channel.name}
                                                                    </div>
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemBody
                                                                        }
                                                                    >
                                                                        <button
                                                                            css={[
                                                                                dashboardCss.link,
                                                                                dashboardCss.pageItemBodyLink,
                                                                            ]}
                                                                            onClick={() =>
                                                                                push({
                                                                                    pathname: `/channels/pages/edit/${page.channel.id}`,
                                                                                })
                                                                            }
                                                                        >
                                                                            edit page
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}

                                                {initialValues.userChannels.filter(
                                                    c =>
                                                        c.type === "follower" &&
                                                        c.channelCategory === "page"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Follower
                                                        </h4>

                                                        {initialValues.userChannels
                                                            .filter(
                                                                c =>
                                                                    c.type === "follower" &&
                                                                    c.channelCategory === "page"
                                                            )
                                                            .map(page => (
                                                                <div
                                                                    css={dashboardCss.pageItem}
                                                                    key={page.channel.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {page.channel.name}
                                                                    </div>
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemBody
                                                                        }
                                                                    >
                                                                        <button
                                                                            css={[
                                                                                dashboardCss.link,
                                                                                dashboardCss.pageItemBodyLink,
                                                                            ]}
                                                                            onClick={() =>
                                                                                push({
                                                                                    pathname: `/channels/pages/edit/${page.channel.id}`,
                                                                                })
                                                                            }
                                                                        >
                                                                            edit page
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                            <hr />
                                        </div>
                                    )}

                                {/* GROUPS */}
                                {initialValues.userChannels &&
                                    initialValues.userChannels.filter(
                                        c => c.channelCategory === "group"
                                    ).length > 0 && (
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12 col-xs-12">
                                                    <div css={dashboardCss.section}>
                                                        <GroupIcon css={dashboardCss.icon} />
                                                        <strong css={dashboardCss.title}>
                                                            Groups
                                                        </strong>
                                                    </div>
                                                </div>

                                                {initialValues.userChannels.filter(
                                                    c =>
                                                        c.type === "owner" &&
                                                        c.channelCategory === "group"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Owner
                                                        </h4>

                                                        {initialValues.userChannels
                                                            .filter(
                                                                c =>
                                                                    c.type === "owner" &&
                                                                    c.channelCategory === "group"
                                                            )
                                                            .map(group => (
                                                                <div
                                                                    css={dashboardCss.pageItem}
                                                                    key={group.channel.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {group.channel.name}
                                                                    </div>
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemBody
                                                                        }
                                                                    >
                                                                        <button
                                                                            css={[
                                                                                dashboardCss.link,
                                                                                dashboardCss.pageItemBodyLink,
                                                                            ]}
                                                                            onClick={() =>
                                                                                push({
                                                                                    pathname: `/channels/groups/edit/${group.channel.id}`,
                                                                                })
                                                                            }
                                                                        >
                                                                            edit group
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}

                                                {initialValues.userChannels.filter(
                                                    c =>
                                                        c.type === "follower" &&
                                                        c.channelCategory === "group"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Member
                                                        </h4>

                                                        {initialValues.userChannels
                                                            .filter(
                                                                c =>
                                                                    c.type === "follower" &&
                                                                    c.channelCategory === "group"
                                                            )
                                                            .map(group => (
                                                                <div
                                                                    css={dashboardCss.pageItem}
                                                                    key={group.channel.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {group.channel.name}
                                                                    </div>
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemBody
                                                                        }
                                                                    >
                                                                        <button
                                                                            css={[
                                                                                dashboardCss.link,
                                                                                dashboardCss.pageItemBodyLink,
                                                                            ]}
                                                                            onClick={() =>
                                                                                push({
                                                                                    pathname: `/channels/groups/edit/${group.channel.id}`,
                                                                                })
                                                                            }
                                                                        >
                                                                            edit group
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                            <hr />
                                        </div>
                                    )}

                                {/* TEAMS */}
                                {initialValues.officeHoursTeamMembers &&
                                    initialValues.officeHoursTeamMembers.length > 0 && (
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12 col-xs-12">
                                                    <div css={dashboardCss.section}>
                                                        <TimelapseIcon css={dashboardCss.icon} />
                                                        <strong css={dashboardCss.title}>
                                                            Teams
                                                        </strong>
                                                    </div>
                                                </div>

                                                {initialValues.officeHoursTeamMembers.filter(
                                                    c => c.userType === "member"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Member
                                                        </h4>
                                                        {initialValues.officeHoursTeamMembers
                                                            .filter(c => c.userType === "member")
                                                            .map(team => (
                                                                <div
                                                                    css={globalsCss.helpers.mb10}
                                                                    key={team.team.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {team.team.name}
                                                                    </div>

                                                                    <button
                                                                        css={[
                                                                            dashboardCss.link,
                                                                            dashboardCss.pageItemBodyLink,
                                                                        ]}
                                                                        onClick={() =>
                                                                            push(
                                                                                `/bookings/manage-teams/edit/${team.team.id}`
                                                                            )
                                                                        }
                                                                    >
                                                                        edit team
                                                                    </button>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}

                                                {initialValues.officeHoursTeamMembers.filter(
                                                    c => c.userType === "admin"
                                                ).length > 0 && (
                                                    <div className="col-sm-6 col-xs-12">
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Administrator
                                                        </h4>
                                                        {initialValues.officeHoursTeamMembers
                                                            .filter(c => c.userType === "admin")
                                                            .map(team => (
                                                                <div
                                                                    css={globalsCss.helpers.mb10}
                                                                    key={team.team.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {team.team.name}
                                                                    </div>

                                                                    <button
                                                                        css={[
                                                                            dashboardCss.link,
                                                                            dashboardCss.pageItemBodyLink,
                                                                        ]}
                                                                        onClick={() =>
                                                                            push(
                                                                                `/bookings/manage-teams/edit/${team.team.id}`
                                                                            )
                                                                        }
                                                                    >
                                                                        edit team
                                                                    </button>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                            <hr />
                                        </div>
                                    )}

                                {/* BOOKINGS */}
                                {(initialValues.hasBookings ||
                                    (initialValues.managesBookingFor &&
                                        initialValues.managesBookingFor.length > 0) ||
                                    (initialValues.bookingsManagedBy &&
                                        initialValues.bookingsManagedBy.length > 0)) && (
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12">
                                                <div css={dashboardCss.section}>
                                                    <ScheduleIcon css={dashboardCss.icon} />
                                                    <strong css={dashboardCss.title}>
                                                        Bookings
                                                    </strong>
                                                </div>
                                            </div>

                                            {initialValues.hasBookings && (
                                                <div
                                                    className={`col-sm-${
                                                        initialValues.hasBookings ? 4 : 6
                                                    } col-xs-12`}
                                                >
                                                    <h4
                                                        css={[
                                                            globalsCss.helpers.mt15,
                                                            globalsCss.helpers.mb10,
                                                        ]}
                                                    >
                                                        Individual bookings
                                                    </h4>
                                                    <div css={globalsCss.helpers.mb10}>
                                                        <div css={dashboardCss.pageItemTitle}>
                                                            Upcoming sessions:{" "}
                                                            {initialValues.upcomingOfficeHours}
                                                        </div>
                                                    </div>
                                                    <button
                                                        css={[
                                                            dashboardCss.link,
                                                            dashboardCss.pageItemBodyLink,
                                                        ]}
                                                        onClick={() =>
                                                            push(
                                                                `/bookings/availability/${initialValues.id}/manage`
                                                            )
                                                        }
                                                    >
                                                        view availability
                                                    </button>
                                                </div>
                                            )}

                                            {initialValues.managesBookingFor &&
                                                initialValues.managesBookingFor.length > 0 && (
                                                    <div
                                                        className={`col-sm-${
                                                            initialValues.hasBookings ? 4 : 6
                                                        } col-xs-12`}
                                                    >
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Manages bookings for
                                                        </h4>
                                                        {initialValues.managesBookingFor.map(
                                                            user => (
                                                                <div
                                                                    css={globalsCss.helpers.mb10}
                                                                    key={user.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {user.firstName}{" "}
                                                                        {user.lastName}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}

                                                        <button
                                                            css={[
                                                                dashboardCss.link,
                                                                dashboardCss.pageItemBodyLink,
                                                            ]}
                                                            onClick={() =>
                                                                push(
                                                                    `/bookings/administrators/edit/${initialValues.id}`
                                                                )
                                                            }
                                                        >
                                                            edit associated academics
                                                        </button>
                                                    </div>
                                                )}

                                            {initialValues.bookingsManagedBy &&
                                                initialValues.bookingsManagedBy.length > 0 && (
                                                    <div
                                                        className={`col-sm-${
                                                            initialValues.hasBookings ? 4 : 6
                                                        } col-xs-12`}
                                                    >
                                                        <h4
                                                            css={[
                                                                globalsCss.helpers.mt15,
                                                                globalsCss.helpers.mb10,
                                                            ]}
                                                        >
                                                            Bookings managed by
                                                        </h4>
                                                        {initialValues.bookingsManagedBy.map(
                                                            user => (
                                                                <div
                                                                    css={globalsCss.helpers.mb10}
                                                                    key={user.id}
                                                                >
                                                                    <div
                                                                        css={
                                                                            dashboardCss.pageItemTitle
                                                                        }
                                                                    >
                                                                        {user.firstName}{" "}
                                                                        {user.lastName}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}

                                                        <button
                                                            css={[
                                                                dashboardCss.link,
                                                                dashboardCss.pageItemBodyLink,
                                                            ]}
                                                            onClick={() =>
                                                                push(
                                                                    `/bookings/administrators/academics/edit/${initialValues.id}`
                                                                )
                                                            }
                                                        >
                                                            edit booking administrators
                                                        </button>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                )}

                                {initialValues.userChannels &&
                                    initialValues.userChannels.length === 0 &&
                                    initialValues.officeHoursTeamMembers &&
                                    initialValues.officeHoursTeamMembers.length === 0 &&
                                    initialValues.managesBookingFor &&
                                    initialValues.managesBookingFor.length === 0 &&
                                    initialValues.bookingsManagedBy &&
                                    initialValues.bookingsManagedBy.length === 0 &&
                                    !initialValues.hasBookings && (
                                        <em>
                                            This user does not have any additional Student Hub
                                            permissions or settings.
                                        </em>
                                    )}
                            </div>
                        </Paper>
                    </div>
                )}
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    setLoading: mainMenuActionCreators.setLoading.create,
    putStaff: actionCreators.putStaff.create,
    getOneStaff: actionCreators.getOneStaff.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
};
const mapStateToProps = state => ({
    errorRes: state.LseStaff.errorRes,
    initialValues: state.LseStaff.user,
    currentFormType: state.LseStaff.currentFormType,
});
export default connect(mapStateToProps, dispatchToProps)(EditStaffContainer);
