/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import { breadcrumbsStyle } from "./BreadcrumbsStyles.js";

const Breadcrumbs = ({ children }) => <div css={breadcrumbsStyle.body}>{children}</div>;

const BreadcrumbsItem = ({ to, children }) =>
    to ? (
        <Link to={to} css={breadcrumbsStyle.item}>
            {children}
        </Link>
    ) : (
        <strong css={breadcrumbsStyle.itemActive}>{children}</strong>
    );
const BreadcrumbsDivider = () => <span css={breadcrumbsStyle.divider}>&rsaquo;</span>;

export { Breadcrumbs, BreadcrumbsItem, BreadcrumbsDivider };
