import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";

import { HeaderRowItem } from "./HeaderRowItem";
import { RowItem } from "./RowItem";

import tableCss from "../styles/table.css";

export const TableComponent = withStyles(tableCss)(
    ({
        items,
        fns,
        buttons,
        decorators,
        columns,
        callbacks = {},
        config = { key: "id" },
        classes,
    }) => {
        const cols =
            items.length > 0 ? (columns !== undefined ? columns : Object.keys(items[0])) : [];

        return (
            <Table className={classes.rootTable}>
                <TableHead>
                    <TableRow>
                        <HeaderRowItem
                            item={items.length > 0 ? items[0] : {}}
                            decorators={decorators}
                            columns={cols}
                            config={config}
                        />
                        <TableCell className={classes.actions} />
                    </TableRow>
                </TableHead>
                <TableBody className={classes.rootTableBody}>
                    {items.map((item, index) => (
                        <RowItem
                            key={item[config.key]}
                            buttons={buttons ? buttons(index) : null}
                            columns={cols}
                            {...{ item, fns, index, decorators, callbacks, config }}
                        />
                    ))}
                </TableBody>
            </Table>
        );
    }
);
