import React from "react";
import { Field } from "redux-form";
import { RenderTextField } from "../../../common/components/FormFields";

const RenderLSEId = ({ errors }) => (
    <div className="col-sm-4 col-xs-12">
        <Field
            name="lseId"
            type="lseId"
            component={RenderTextField}
            errorRes={errors.lseId}
            label="LSE SSO ID"
            fullWidth
        />
    </div>
);

export default RenderLSEId;
