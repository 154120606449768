import React from "react";
import { connect } from "react-redux";
import NotAuthorised from "../../app/Auth/NotAuthorised";

const CheckPermission = allowedRoles => WrappedComponent => {
    class WithPermissions extends React.Component {
        render() {
            const { permissionLevel } = this.props;
            if (allowedRoles.includes(permissionLevel)) {
                return <WrappedComponent {...this.props} />;
            } else {
                return <NotAuthorised />;
            }
        }
    }

    const mapStateToProps = state => ({
        permissionLevel: state.Auth.permissionLevel,
    });

    return connect(mapStateToProps, null)(WithPermissions);
};

export default CheckPermission;
