export const emailRegex = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
export const isValidEmail = value => value && emailRegex.test(value);
export const validateRequiredFields = (requiredMap, values) => {
    const errors = {};
    Object.entries(requiredMap).forEach(([field, message]) => {
        if (
            !values[field] ||
            (values[field].hasOwnProperty("base64") && values[field].base64 === "")
        ) {
            errors[field] = message || "Required";
        }
    });

    return errors;
};
