import { of } from "rxjs";
import { switchMap, mergeMap, catchError, mapTo, concatMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";

const eventStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListEvent.type,
            actionCreators.loadMoreEvents.type,
            actionCreators.getOneEvent.type,
            actionCreators.postEvent.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const eventClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListEvent.type,
            actionCreators.updateListEvent.type,
            actionCreators.updateOneEvent.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListEvent.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/events/"), payload).pipe(
                mergeMap(({ response }) => of(actionCreators.createListEvent.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreEvents.type),
        switchMap(({ payload }) =>
            ajax.get(ajax.apiUrl("staff/events/"), payload).pipe(
                mergeMap(({ response }) => of(actionCreators.updateListEvent.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneEvent.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/events/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOneEvent.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postEvent.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl(`staff/events/`), action.payload.event).pipe(
                concatMap(() =>
                    of(
                        actionCreators.clearQueryParams.create(),
                        UIActionCreators.clearLoading.create(),
                        push(
                            `/channels/events?channelId=${action.payload.event.channel.id}&channelName=${action.payload.event.channel.name}`
                        )
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const editEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putEvent.type),
        switchMap(action =>
            ajax
                .put(ajax.apiUrl(`staff/events/${action.payload.event.id}/`), action.payload.event)
                .pipe(
                    concatMap(() =>
                        of(
                            actionCreators.clearQueryParams.create(),
                            UIActionCreators.clearLoading.create(),
                            push(
                                `/channels/events?channelId=${action.payload.event.channel.id}&channelName=${action.payload.event.channel.name}`
                            )
                        )
                    ),

                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removeEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeEvent.type),
        switchMap(action =>
            ajax
                .remove(ajax.apiUrl(`staff/events/${action.payload.id}/`))
                .pipe(
                    mapTo(actionCreators.getListEvent.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const archiveEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.archiveEvent.type),
        switchMap(action =>
            ajax.put(ajax.apiUrl(`staff/events/${action.payload}/archive/`)).pipe(
                concatMap(() => of(actionCreators.updateArchivedEvent.create(action.payload))),

                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    eventStartLoadingEpic,
    eventClearLoadingEpic,
    getListEventsEpic,
    loadMoreEventsEpic,
    getOneEventEpic,
    newEventEpic,
    editEventEpic,
    removeEventEpic,
    archiveEventEpic
);
