import { spacing } from "../../styles/spacing";
import { colors } from "../../styles/colors";
import { fontWeight, fontSize, lineHeight } from "../../styles/typography";

export const channelTitle = {
    body: {
        display: "flex",
        alignItems: "center",
        padding: spacing.space2,
        maxWidth: "100%",
    },
    bodyLeft: {
        paddingRight: spacing.space2,
    },
    bodyCenter: {
        flex: 1,
        maxWidth: "100%",
    },
    bodyRight: {
        paddingLeft: spacing.space2,
    },
    name: {},
    nameLink: {
        display: "block",
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        lineHeight: lineHeight.textSmall,
        textDecoration: "none",
    },
    date: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: lineHeight.textSmall,
    },
};
