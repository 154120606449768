// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./Form";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import actionCreators from "../../../store/OfficeHours/Teams/actionCreators";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import { Dialog, Paper, Button, Typography } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";
import { GetFormattedDate } from "../../../common/components/Time";
import formsCss from "../../../common/styles/forms.css";
import MessageForm from "../ManageOfficeHours/MessageForm";

class OfficeHoursManageTeamsEditContainer extends Component {
    state = {
        request: false,
        removeDialogReason: false,
        displayCancellationSlots: false,
    };

    componentDidMount() {
        const { setTitle, getOneOfficeHoursTeam, match } = this.props;
        setTitle("Bookings \u203A Manage Teams");
        getOneOfficeHoursTeam({ id: match.params.id });
    }

    componentDidUpdate(prevProps) {
        const { slotsForCancellation } = this.props;

        if (
            slotsForCancellation !== prevProps.slotsForCancellation &&
            prevProps.slotsForCancellation === null
        ) {
            if (slotsForCancellation.length > 0) {
                // show cancellation popup
                this.props.clearLoading();
                this.setState({ removeDialogReason: true });
            } else {
                // hide popup - hide popup with empty cancellation msg
                this.onCancellationMessageProvided({ message: "" });
            }
        }
    }

    componentWillUnmount() {
        const { clearTitle, clearOfficeHoursTeam } = this.props;
        clearTitle();
        clearOfficeHoursTeam();
    }

    onSubmit = values => {
        let request = { ...values, submit: 1 };

        if (values.settings.maxAppointmentsPerSession)
            request.settings.maxAppointmentsPerSession = Number(
                values.settings.maxAppointmentsPerSession
            );
        else request.settings.maxAppointmentsPerSession = null;

        if (values.settings.maxNumberOfAttendees)
            request.settings.maxNumberOfAttendees = Number(values.settings.maxNumberOfAttendees);
        else request.settings.maxNumberOfAttendees = null;

        if (values.maxAppointmentsPerUserPerTerm)
            request.maxAppointmentsPerUserPerTerm = Number(values.maxAppointmentsPerUserPerTerm);
        else request.maxAppointmentsPerUserPerTerm = null;

        if (values.maxAppointmentsPerUserPerDay)
            request.maxAppointmentsPerUserPerDay = Number(values.maxAppointmentsPerUserPerDay);
        else request.maxAppointmentsPerUserPerDay = null;

        if (values.maxAppointmentsPerUserPerWeek)
            request.maxAppointmentsPerUserPerWeek = Number(values.maxAppointmentsPerUserPerWeek);
        else request.maxAppointmentsPerUserPerWeek = null;

        request.settings.receiveSummaryEmailAheadUpcomingAppointments = !values.settings
            .receiveSummaryEmailAheadUpcomingAppointments;

        if (values.settings.receiveSummaryEmailAheadUpcomingAppointments) {
            request.settings.atEightOnSessionDay = !!values.settings.atEightOnSessionDay;
            request.settings.timeInAdvance =
                !!values.settings.timeInAdvance && !!values.settings.timeInAdvanceValue
                    ? values.settings.timeInAdvanceValue
                    : null;
            request.settings.summaryAtTime =
                !!values.settings.summaryAtTime && !!values.settings.summaryAtTimeValue
                    ? values.settings.summaryAtTimeValue
                    : null;
            request.settings.atLatestBookingTime = !!values.settings.atLatestBookingTime;
        } else {
            request.settings.atEightOnSessionDay = false;
            request.settings.timeInAdvance = null;
            request.settings.summaryAtTime = null;
            request.settings.atLatestBookingTime = false;
        }

        if (!values.dontReceiveTeamSummaryEmails) {
            request.teamSummaryAtEight = !!values.teamSummaryAtEight;
            request.teamSummaryAtTime =
                !!values.teamSummaryAtTime && !!values.teamSummaryAtTimeValue
                    ? values.teamSummaryAtTimeValue
                    : null;
        } else {
            request.teamSummaryAtEight = false;
            request.teamSummaryAtTime = null;
        }

        request.isActive = values.isActive === "true" ? true : false;

        const oldMembers = this.props.initialValues.users.map(u => u.id);
        const newMembers = values.users.map(u => u.id);

        const removedMembers = newMembers
            ? oldMembers.filter(x => !newMembers.find(n => n === x))
            : oldMembers;

        if (removedMembers) {
            // some members were removed -  cache request and check if we should dispay cancellation prompt
            this.props.getTeamSlotsForCancellation({
                teamId: request.id,
                users: removedMembers.map(u => {
                    return { id: u };
                }),
            });
            this.setState({ request: request });
        } else {
            // mmebers are not changes - proceed with updating the team
            this.onCancellationMessageProvided({ message: "" });
        }
    };

    onCancellationMessageProvided = values => {
        const { putOfficeHoursTeam } = this.props;
        const { request } = this.state;

        this.setState(
            {
                removeDialogReason: false,
                displayCancellationSlots: false,
            },
            () => {
                putOfficeHoursTeam({
                    ...request,
                    reason: values.message,
                });
            }
        );
    };

    render() {
        const {
            initialValues,
            admins,
            academics,
            getUsers,
            clearUsers,
            slotsForCancellation,
            history: { push },
            errorRes: { error, errors },
        } = this.props;
        const { displayCancellationSlots } = this.state;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.EDIT;

        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Edit Team</h2>
                        <Form
                            {...{
                                initialValues: {
                                    ...initialValues,
                                    isActive: initialValues.isActive === true ? "true" : "false",
                                    dontReceiveTeamSummaryEmails:
                                        !initialValues.teamSummaryAtEight &&
                                        !initialValues.teamSummaryAtTime,
                                    ...(initialValues.teamSummaryAtTime
                                        ? {
                                              teamSummaryAtTime: true,
                                              teamSummaryAtTimeValue:
                                                  initialValues.teamSummaryAtTime,
                                          }
                                        : {}),
                                    settings: {
                                        ...initialValues.settings,
                                        receiveSummaryEmailAheadUpcomingAppointments: !initialValues
                                            .settings.receiveSummaryEmailAheadUpcomingAppointments,

                                        ...(initialValues.settings.atEightOnSessionDay
                                            ? { atEightOnSessionDay: true }
                                            : {}),
                                        ...(initialValues.settings.timeInAdvance
                                            ? {
                                                  timeInAdvance: true,
                                                  timeInAdvanceValue:
                                                      initialValues.settings.timeInAdvance,
                                              }
                                            : { timeInAdvanceValue: 0 }),
                                        ...(initialValues.settings.summaryAtTime
                                            ? {
                                                  summaryAtTime: true,
                                                  summaryAtTimeValue:
                                                      initialValues.settings.summaryAtTime,
                                              }
                                            : { summaryAtTimeValue: 0 }),
                                        ...(initialValues.settings.atLatestBookingTime
                                            ? { atLatestBookingTime: true }
                                            : {}),
                                    },
                                },
                                currentFormType,
                                onSubmit,
                                push,
                                errors,
                                formError: error,
                                admins,
                                academics,
                                getUsers,
                                clearUsers,
                            }}
                        />
                    </div>
                </Paper>
                <Dialog
                    open={this.state.removeDialogReason}
                    onClose={() => {
                        this.setState({ removeDialogReason: false }, () =>
                            this.props.clearTeamSlotsForCancellation()
                        );
                    }}
                    maxWidth="sm"
                    fullWidth
                >
                    {!!slotsForCancellation && (
                        <div css={globalsCss.innerDialog}>
                            <h2 css={globalsCss.subtitleTitle}>
                                Some of your existing appointments will be cancelled
                            </h2>
                            <p>
                                Saving this change will cancel{" "}
                                <strong>{slotsForCancellation.length} </strong> existing{" "}
                                {`appointment${slotsForCancellation.length > 1 ? "s" : ""}`}. Are
                                you sure?
                            </p>

                            {displayCancellationSlots ? (
                                <div>
                                    <Button
                                        color="secondary"
                                        onClick={() =>
                                            this.setState({ displayCancellationSlots: false })
                                        }
                                    >
                                        Hide{" "}
                                        {`appointment${slotsForCancellation.length > 1 ? "s" : ""}`}
                                    </Button>
                                    <div style={globalsCss.table}>
                                        <div style={globalsCss.tableHeaderItem}>
                                            <Typography
                                                component="div"
                                                variant="subtitle1"
                                                style={globalsCss.tableItemText}
                                            >
                                                <strong>Date &amp; Time</strong>
                                            </Typography>
                                            <Typography
                                                component="div"
                                                variant="subtitle1"
                                                style={globalsCss.tableItemText}
                                            >
                                                <strong>Student</strong>
                                            </Typography>
                                        </div>
                                        {slotsForCancellation.map((slot, index) => (
                                            <div key={`slot-${index}`} style={globalsCss.tableItem}>
                                                <Typography
                                                    component="div"
                                                    variant="subtitle1"
                                                    style={globalsCss.tableItemText}
                                                >
                                                    {`${GetFormattedDate(
                                                        slot.start
                                                    )} ${GetFormattedDate(slot.start, "h.mma")}`}
                                                </Typography>
                                                <Typography
                                                    component="div"
                                                    variant="subtitle1"
                                                    style={globalsCss.tableItemText}
                                                >
                                                    {slot.student}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <Button
                                    color="secondary"
                                    onClick={() =>
                                        this.setState({ displayCancellationSlots: true })
                                    }
                                >
                                    Show{" "}
                                    {`appointment${slotsForCancellation.length > 1 ? "s" : ""}`}
                                </Button>
                            )}

                            <p>
                                Please add a note so that students know why their appointments have
                                been cancelled. They will receive an email with your explanation.
                            </p>
                            <p>
                                Alternatively, please contact{" "}
                                <a href="mailto:studenthub@lse.ac.uk">studenthub@lse.ac.uk</a> if
                                you require any support.
                            </p>
                            {error && <div css={formsCss.genericError}>{error}</div>}
                            <MessageForm
                                {...{
                                    onSubmit: this.onCancellationMessageProvided,
                                    push,
                                    errors: {
                                        ...errors,
                                        ...(errors.reason ? { message: errors.reason } : {}),
                                    },
                                    fieldLabel: "Reason for cancellation",
                                    buttonLabel: "Confirm",
                                }}
                            />
                        </div>
                    )}
                </Dialog>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneOfficeHoursTeam: actionCreators.getOneOfficeHoursTeam.create,
    putOfficeHoursTeam: actionCreators.putOfficeHoursTeam.create,
    clearOfficeHoursTeam: actionCreators.clearOfficeHoursTeam.create,
    getUsers: AutoCompleteActionCreators.getStaffUsers.create,
    clearUsers: AutoCompleteActionCreators.clearStaffUsers.create,
    getTeamSlotsForCancellation: actionCreators.getTeamSlotsForCancellation.create,
    clearTeamSlotsForCancellation: actionCreators.clearTeamSlotsForCancellation.create,
    clearLoading: mainMenuActionCreators.clearLoading.create,
};
const mapStateToProps = ({ AutoComplete, OfficeHoursTeams }) => ({
    initialValues: OfficeHoursTeams.team,
    errorRes: OfficeHoursTeams.errorRes,
    admins: AutoComplete.staff,
    academics: AutoComplete.staff,
    slotsForCancellation: OfficeHoursTeams.slotsForCancellation,
});
export default connect(mapStateToProps, dispatchToProps)(OfficeHoursManageTeamsEditContainer);
