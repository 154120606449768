export const USER_TYPE_PENDING = "pending";
export const USER_TYPE_MEMBER = "follower";
export const USER_TYPE_OWNER = "owner";

export const ROLE_ACTION_APPROVE = "approve";
export const ROLE_ACTION_DECLINE = "decline";

export const GROUP_TYPE_OPEN = "open";
export const GROUP_TYPE_CLOSED = "closed";
export const GROUP_TYPE_PRIVATE = "private";

export const USER_GROUP_STATUS_JOINREQUEST = "pending";
export const USER_GROUP_STATUS_INVITATION = "invited";

export const MANAGED_BY_OWNERS = "owners";
export const MANAGED_BY_MEMBERS = "all";
