import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";
import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo, concatMap } from "rxjs/operators";
import { default as notificationActions } from "../../Snackbar/actionCreators";

const pageStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListPage.type,
            actionCreators.loadMorePages.type,
            actionCreators.getOnePage.type,
            actionCreators.postPage.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const pageClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListPage.type,
            actionCreators.updateListPage.type,
            actionCreators.updateOnePage.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListPagesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListPage.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/pages/?${action.payload}`)).pipe(
                mergeMap(({ response }) => of(actionCreators.createListPage.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMorePagesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMorePages.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/pages/?${action.payload}`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateListPage.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOnePageEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOnePage.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/pages/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOnePage.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newPageEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postPage.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl("staff/pages/"), action.payload).pipe(
                concatMap(() =>
                    of(
                        actionCreators.clearQueryParams.create(),
                        UIActionCreators.clearLoading.create(),
                        push(`/channels/pages`)
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const editPageEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putPage.type),
        switchMap(action =>
            ajax.put(ajax.apiUrl(`staff/pages/${action.payload.id}/`), action.payload).pipe(
                concatMap(() =>
                    of(
                        actionCreators.clearQueryParams.create(),
                        UIActionCreators.clearLoading.create(),
                        push(`/channels/pages`)
                    )
                ),

                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const removePageEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removePage.type),
        switchMap(action =>
            ajax.remove(ajax.apiUrl(`staff/pages/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.getListPage.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const refreshFollowersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.refreshFollowers.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`staff/pages/${action.payload.pageId}/refresh-followers/`)).pipe(
                mergeMap(() =>
                    of(
                        notificationActions.enqueueSnackbar.create({
                            message: `Successfully scheduled followers recalculation for "${action.payload.pageName}"`,
                        })
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const refreshAllFollowersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.refreshAllFollowers.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`admin/refresh-channels-followers/`)).pipe(
                mergeMap(() =>
                    of(
                        notificationActions.enqueueSnackbar.create({
                            message: `Successfully scheduled followers recalculation for all pages`,
                        })
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    pageStartLoadingEpic,
    pageClearLoadingEpic,
    getListPagesEpic,
    getOnePageEpic,
    newPageEpic,
    editPageEpic,
    loadMorePagesEpic,
    removePageEpic,
    refreshFollowersEpic,
    refreshAllFollowersEpic
);
