import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/Channels/Events/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import globalsCss from "../../../common/styles/globals.css";
import formsCss from "../../../common/styles/forms.css";
import previewCss from "../../../common/styles/preview.css";
import tableDataCss from "../../../common/styles/tableData.css";
import CommentListContainer from "../../Discussion/";
import { spacing } from "../../../common/styles/spacing";
import { DateTime, FormatTime } from "../../../common/components/Time";
import Wysiwyg from "../../../common/components/Wysiwyg";
import {
    FavoriteIcon,
    CommentIcon,
    CheckIcon,
    OpenIcon,
    LinkIcon,
} from "../../../common/components/icons";

class ChannelPreviewEventContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            getOneEvent,
            match: {
                params: { id },
            },
        } = this.props;
        setTitle("Channels \u203A Pages");
        getOneEvent({ id });
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    render() {
        const {
            history: { push },
            event,
            comments,
        } = this.props;
        if (!event) {
            return null;
        }

        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Preview Post</h2>
                        <div>
                            <div css={previewCss.status}>
                                Status:
                                <span
                                    css={[
                                        tableDataCss.statusActive,
                                        event.status === "scheduled" && tableDataCss.statusPending,
                                        event.status === "draft" && tableDataCss.statusBlocked,
                                    ]}
                                >
                                    {" "}
                                    {event.status}
                                </span>
                            </div>
                            {event.publicationDate && (
                                <div css={previewCss.publicationDate}>
                                    Publication date:{" "}
                                    <strong>
                                        <DateTime date={event.publicationDate} />
                                    </strong>
                                </div>
                            )}
                        </div>
                        <div css={previewCss.body}>
                            <div css={previewCss.paper}>
                                <div css={previewCss.mainImg}>
                                    <img src={event.imageUrl} alt="" css={previewCss.mainImg.img} />
                                </div>
                                <div css={previewCss.inner}>
                                    <div css={previewCss.text}>
                                        <div css={previewCss.title}>{event.name}</div>
                                        <div css={previewCss.eventSummary}>
                                            <div css={previewCss.details}>
                                                <div css={previewCss.detailsDate}>
                                                    <FormatTime
                                                        date={event.startAt}
                                                        format="dddd D MMMM YYYY h.mma"
                                                    />
                                                </div>
                                                <div css={previewCss.detailsLocation}>
                                                    {event.location}
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                css={[previewCss.btn, previewCss.btnGray]}
                                            >
                                                <LinkIcon css={previewCss.btnIconGray} />
                                                Find out more
                                            </button>
                                        </div>
                                        <div className="entryText">
                                            <Wysiwyg html={event.description} />
                                        </div>
                                        <div css={previewCss.summary}>
                                            <FavoriteIcon css={previewCss.favoriteIcon} />
                                            <div css={previewCss.btnComments}>
                                                <CommentIcon css={previewCss.commentIcon} />
                                                <span css={previewCss.btnCommentsText}>
                                                    {comments.length > 0
                                                        ? `${comments.length} comments`
                                                        : "No comments"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div css={{ marginTop: spacing.space3 }}>
                                <div css={previewCss.paper}>
                                    <div css={previewCss.mainImg}>
                                        <img
                                            src={event.imageUrl}
                                            alt=""
                                            css={previewCss.mainImg.img}
                                        />
                                    </div>
                                    <div css={previewCss.inner}>
                                        <div css={previewCss.text}>
                                            <div css={previewCss.title}>{event.name}</div>
                                            <div css={previewCss.details}>
                                                <div css={previewCss.detailsDate}>
                                                    <FormatTime
                                                        date={event.startAt}
                                                        format="dddd D MMMM YYYY h.mma"
                                                    />{" "}
                                                    -{" "}
                                                    <FormatTime
                                                        date={event.endAt}
                                                        format="dddd D MMMM YYYY h.mma"
                                                    />
                                                </div>
                                                <div css={previewCss.detailsLocation}>
                                                    {event.location}
                                                </div>
                                            </div>
                                            <div css={previewCss.actions}>
                                                <div css={previewCss.actionsInner}>
                                                    <button
                                                        type="button"
                                                        css={[previewCss.btn, previewCss.btnAccent]}
                                                    >
                                                        <CheckIcon css={previewCss.btnIconAccent} />
                                                        Attending
                                                    </button>
                                                    {event.signpostUrl && (
                                                        <a
                                                            href={event.signpostUrl}
                                                            css={[
                                                                previewCss.btn,
                                                                { marginLeft: spacing.space1 },
                                                            ]}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <OpenIcon css={previewCss.btnIcon} />
                                                            Booking info
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="entryText">
                                                <Wysiwyg html={event.description} />
                                            </div>
                                            <div css={previewCss.summary}>
                                                <FavoriteIcon css={previewCss.favoriteIcon} />
                                                <div css={previewCss.btnComments}>
                                                    <CommentIcon css={previewCss.commentIcon} />
                                                    <span css={previewCss.btnCommentsText}>
                                                        {comments.length > 0
                                                            ? `${comments.length} comments`
                                                            : "No comments"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CommentListContainer {...this.props} />
                        </div>
                        <div css={formsCss.actions}>
                            {event.status !== "archived" && (
                                <Button
                                    onClick={() => push(`/channels/events/edit/${event.id}`)}
                                    variant="contained"
                                    color="primary"
                                    css={formsCss.btnSubmit}
                                >
                                    Edit Event
                                </Button>
                            )}
                            <Button onClick={() => push(`/channels/events`)} css={formsCss.btnBack}>
                                Back to the list
                            </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneEvent: actionCreators.getOneEvent.create,
};
const mapStateToProps = ({ ChannelEvents: { event }, Discussion: { comments } }) => ({
    event,
    comments,
});
export default connect(mapStateToProps, dispatchToProps)(ChannelPreviewEventContainer);
