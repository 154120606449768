import { Component } from "react";
import { reduxForm, Field } from "redux-form";
import validate from "./validate";
import { RenderTextField, RenderDatepicker } from "../../../../common/components/FormFields";

import Button from "@material-ui/core/Button";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";
import globalsCss from "../../../../common/styles/globals.css";

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const { handleSubmit, errors } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <label
                            css={[
                                formsCss.labelDateTime,
                                globalsCss.showOnDesktop,
                                { marginTop: 16 },
                            ]}
                        />
                        <Field
                            name="name"
                            errorRes={errors.name}
                            type="name"
                            component={RenderTextField}
                            label="Name"
                            fullWidth
                        />
                    </div>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <label css={formsCss.labelDateTime}>From</label>
                        <Field
                            name="startDate"
                            errorRes={errors.startDate}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                        />
                    </div>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <label css={formsCss.labelDateTime}>To</label>
                        <Field
                            name="endDate"
                            errorRes={errors.endDate}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                        />
                    </div>
                </div>
                <div css={formsCss.extraAction}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        Add Holiday
                    </Button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: "SettingsSchoolYearHoliday",
    enableReinitialize: true,
    validate,
})(Form);

export default form;
