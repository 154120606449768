const colors = {
    white: "#ffffff",
    almostWhite: "#f2f2f2",
    lightGrey: "#f4f3f3",
    black: "#000000",
    primary: "#ff2d55",
    // darkPrimary: "#d3577a",
    secondary: "#34ccb8",
    secondaryAlt: "#23A493",
    secondaryAlt2: "#30C0AD",
    darkSecondary: "#00565f",
    transparent: "rgba(0,0,0,0)",
    red: "rgb(209, 2, 27)",
};
export { colors };
