import { Fragment } from "react";
import moment from "moment-timezone";
import tableDataCss from "../styles/tableData.css";
import * as groupConfig from "../../app/Channels/Groups/values";
import { CheckIcon, CloseIcon } from "../components/icons";
/** @jsx jsx */
import { jsx } from "@emotion/core";

const getStyled = (item, style) => {
    return <span css={style}>{item}</span>;
};

const statusMap = {
    live: getStyled("Live", tableDataCss.statusActive),
    sent: getStyled("Sent", tableDataCss.statusActive),
    sending: getStyled("Sending", tableDataCss.statusBlocked),
    processing: getStyled("Sending", tableDataCss.statusBlocked),
    scheduled: getStyled("Scheduled", tableDataCss.statusPending),
    draft: getStyled("Draft", tableDataCss.statusBlocked),
    failed: getStyled("Failed", tableDataCss.statusFailed),
    archived: getStyled("Archived", tableDataCss.statusBlocked),
};

const groupStatusMap = {
    [groupConfig.USER_TYPE_MEMBER]: getStyled("Member", tableDataCss.statusPending),
    [groupConfig.USER_TYPE_OWNER]: getStyled("Owner", tableDataCss.statusActive),
    [groupConfig.USER_GROUP_STATUS_JOINREQUEST]: getStyled(
        "Pending [req. access]",
        tableDataCss.statusBlocked
    ),
    [groupConfig.USER_GROUP_STATUS_INVITATION]: getStyled(
        "Pending [invited]",
        tableDataCss.statusBlocked
    ),
};

const userStatusMap = {
    active: getStyled("active", tableDataCss.statusActive),
    pending: getStyled("pending", tableDataCss.statusPending),
    blocked: getStyled("blocked", tableDataCss.statusBlocked),
    deleted: getStyled("deleted", tableDataCss.statusBlocked),
    inactive: getStyled("inactive", tableDataCss.statusBlocked),
};

const audienceTypeMap = {
    draft: getStyled("Draft", tableDataCss.statusBlocked),
    published: getStyled("Uploaded", tableDataCss.statusPending),
    department: getStyled("Automatic", tableDataCss.statusActive),
    custom: getStyled("Custom", tableDataCss.statusPending),
    timetables: getStyled("Timetables", tableDataCss.statusActive),
};

const bookingStatusMap = {
    available: getStyled("Available", tableDataCss.statusActive),
    blocked: getStyled("Blocked", tableDataCss.statusPending),
    booked: getStyled("Booked", tableDataCss.statusBlocked),
    cancelled: getStyled("Cancelled", tableDataCss.statusBlocked),
};

const roleMap = {
    admin: "Admin",
    manager: "Manager",
    regular: "Regular User",
};

export const calendarCategoryMap = {
    timetables: "Timetables & exams",
    "my-events": "My events",
    appointments: "Appointments",
    "key-academic-dates": "Moodle dates",
    "lse-events": "LSE events",
};

export const notificationCategoryMap = {
    backend: "Standard",
    timetable: "Timetable",
};

export const notificationSenderMap = {
    student_hub: "Student Hub",
    timetable: "Timetables team",
    language_centre: "Language Centre",
};

export const recurringMap = {
    weekly: "Every week",
    biweekly: "Every 2 weeks",
    monthly: "Every month",
};

const howtoArticleTypeMap = {
    html: "HTML Content",
    url: "External URL",
};

const booleanTypeMap = {
    true: "Yes",
    false: "No",
};

const statusBooleanMap = {
    true: getStyled("Yes", tableDataCss.statusActive),
    false: getStyled("No", tableDataCss.statusBlocked),
};

export const statusBooleanCallback = (item, prop) => statusBooleanMap[item[prop]];
export const statusCallback = (item, prop) => statusMap[item[prop]];
export const groupStatusCallback = (item, prop) => groupStatusMap[item[prop]];
export const userStatusCallback = (item, prop) => userStatusMap[item[prop]];
export const bookingStatusCallback = (item, prop) => bookingStatusMap[item[prop]];
export const audienceTypeCallback = (item, prop) => audienceTypeMap[item[prop]];
export const permissionCallback = (item, prop) => roleMap[item[prop]];
export const booleanTypeCallback = (item, prop) => booleanTypeMap[item[prop]];
export const calendarCategoryCallback = (item, prop) => calendarCategoryMap[item[prop]];
export const recurringRuleCallback = (item, prop) =>
    item[prop] ? recurringMap[item[prop]] : "n/a";
export const dateFormatCallbackWrapper = (format, empty) => (item, prop) =>
    item[prop]
        ? moment(item[prop])
              .tz("Europe/London")
              .format(format)
        : empty;
export const dateFormatCallback = dateFormatCallbackWrapper("dddd D MMM YYYY", "n/a");
export const dateTimeFormatCallback = dateFormatCallbackWrapper("dddd D MMM YYYY h.mma", "n/a");
export const postContentCallback = (item, prop) => <Fragment>{item[prop]}</Fragment>;
export const howtoArticleTypeCallback = (item, prop) => howtoArticleTypeMap[item[prop]];
export const boolCallback = (item, prop) =>
    item[prop] ? <CheckIcon /> : <CloseIcon css={{ opacity: 0.05 }} />;
