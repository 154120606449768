import React from "react";

export default {
    //viewBox="0 0 40 40"
    lse: {
        svg: (
            <path
                d="M32.528 10.357h-4.02v7.902h3.758v2.875h-3.757v8.455h4.02v2.724h-6.653V7.67h6.652v2.687zm-9.477 16.81c-.568 3.59-3.325 5.184-5.88 5.137h-.824V29.61l.825-.004c1.082-.006 2.914-.76 3.184-2.465.262-1.652.069-2.847-2.055-6.475l-.028-.06c-1.478-2.411-2.88-4.516-2.357-7.82.57-3.59 3.377-5.172 5.931-5.125h.825V10.355l-.825.003c-1.082.006-2.965.748-3.235 2.453-.262 1.652-.081 2.72 1.987 6.3l.055.092c1.537 2.473 2.92 4.66 2.397 7.963zm-8.997 5.146H7.515V7.667h2.632v21.925h3.907v2.721zM0 40h40V0H0v40z"
                fill="#d0021b"
            />
        ),
    },
    //viewBox="0 0 338 48"
    buttonShape: {
        svg: <path d="M0 0l338 8v40H0z" />,
    },
    // viewBox="0 0 1024 200"
    backgroundHeader: {
        svg: (
            <g>
                <defs>
                    <linearGradient
                        x1="50%"
                        y1="96.3521466%"
                        x2="50%"
                        y2="25.0823975%"
                        id="linearGradient-1"
                    >
                        <stop stopColor="#106C76" offset="0%" />
                        <stop stopColor="#00565F" offset="100%" />
                    </linearGradient>
                </defs>
                <g id="CMS-Login" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="CMS">
                        <rect fill="#FFFFFF" x="0" y="0" width="1024" height="1024" />
                        <rect
                            id="Rectangle-5"
                            fill="url(#linearGradient-1)"
                            x="-6"
                            y="0"
                            width="1038"
                            height="200"
                        />
                        <polygon
                            id="Rectangle-5-Copy"
                            fill="#30C0AD"
                            points="-6 0 1032 0 1032 179 -6 189"
                        />
                        <polygon
                            id="Rectangle-5-Copy-3"
                            fill="#23A493"
                            opacity="0.5"
                            points="-6 -71 1032 -71 1032 117.820312 -6 181.113281"
                        />
                    </g>
                </g>
            </g>
        ),
    },
    cloud: {
        svg: (
            <path
                d="M9 11.475c-.29 0-.6-.014-.945-.041l-.545-.044-.371.402a18.11 18.11 0 0 1-2.414 2.158v-3.298l-.6-.316c-1.906-1.004-3-2.475-3-4.036 0-2.805 3.606-5.175 7.875-5.175s7.875 2.37 7.875 5.175c0 2.805-3.606 5.175-7.875 5.175M9 0C4.03 0 0 2.82 0 6.3c0 2.06 1.417 3.882 3.6 5.031v3.064c0 .53.438.905.905.905a.864.864 0 0 0 .51-.167 19.417 19.417 0 0 0 2.95-2.578c.34.027.684.045 1.035.045 4.97 0 9-2.82 9-6.3S13.97 0 9 0"
                fillRule="evenodd"
            />
        ),
    },
    file: {
        svg: (
            <path
                d="M16.75 7H1.25V2.006c0-.417.339-.756.756-.756h13.988c.417 0 .756.339.756.756V7zm0 3h-4.083a.667.667 0 0 0-.667.667v4.083H2.006a.757.757 0 0 1-.756-.756V6.999h15.5V10zm-3.5 3.982V11.25h2.732l-2.732 2.732zM13.625 0H2.006A2.006 2.006 0 0 0 0 2.006v11.988C0 15.102.898 16 2.006 16h10.53c.297 0 .582-.118.792-.328l4.344-4.344c.21-.21.328-.495.328-.792v-8.53A2.006 2.006 0 0 0 15.994 0h-2.369z"
                fillRule="evenodd"
            />
        ),
    },
    // viewBox="0 0 800 44"
    footerShape: {
        svg: (
            <g>
                <path fill="#00565F" d="M800 8v36H0V7z" />
                <path fill="#34CCB8" d="M800 8.03v32L0 16.52V0z" />
            </g>
        ),
    },
};
