import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import actionCreators from "../../../store/Notification/actionCreators";
import mainMenuActionCreators from "../../../store/MainMenu/actionCreators";
import { MainTitle } from "../../../common/components/MainTitle";
import formsCss from "../../../common/styles/forms.css";
import StandardNotificationForm from "../components/StandardNotificationForm";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import { CurrentFormType } from "src/types/generic";
import { StandardNotification } from "../types/Notification";

interface MatchParams {
    id?: string;
}

const AddEditStandardNotification: FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
    const dispatch = useDispatch();
    const notification = useSelector((state: AppState) => state.Notification.notification);
    const { error, errors } = useSelector((state: AppState) => state.Notification.errorRes);
    const { push } = history;
    const id = match.params.id;
    const [preparedNotification, setPreparedNotification] = useState<any>(
        id ? undefined : notification
    );

    useEffect(() => {
        dispatch(mainMenuActionCreators.setTitle.create("Notifications"));
        const id = match.params.id;

        if (id) dispatch(actionCreators.getStandardNotification.create(id));
        return () => {
            dispatch(mainMenuActionCreators.clearTitle.create());
        };
    }, []);

    useEffect(() => {
        if (notification && notification.id) {
            setPreparedNotification({
                ...(notification as StandardNotification),
                link: (notification as StandardNotification).linkObj
                    ? (notification as StandardNotification).linkObj
                    : undefined,
            });
        }
    }, [notification]);

    const onSubmit = values => {
        const data = {
            ...values,
            audience: {
                ...(values.audience["students-broadcast"] === true
                    ? {
                          pages: [],
                          students: [],
                          audiences: [],
                          "students-broadcast": true,
                      }
                    : values.audience),
            },
            link: values.link ? `${values.link.id}` : undefined, // just ID
            linkObj: values.link ? values.link : undefined, // full link object
        };

        if (notification && notification.id)
            dispatch(actionCreators.putStandardNotification.create(data));
        else dispatch(actionCreators.postStandardNotification.create(data));
    };

    return (
        <div>
            <MainTitle
                title={
                    notification && notification.id
                        ? "Edit Standard Notification"
                        : "Add Standard Notification"
                }
            />
            <div css={formsCss.genericError}>{error}</div>
            {preparedNotification && (
                <StandardNotificationForm
                    {...{
                        formType:
                            notification && notification.id
                                ? CurrentFormType.EDIT
                                : CurrentFormType.NEW,
                        initialValues: preparedNotification as StandardNotification,
                        onSubmit,
                        error,
                        errors,
                        push,
                    }}
                />
            )}
        </div>
    );
};

export default AddEditStandardNotification;
