import { of } from "rxjs";
import { mergeMap, catchError, switchMap, tap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";
import FileSaver from "file-saver";

const officeHoursReportsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getOfficeReportDepartmentList.type,
            actionCreators.getOfficeReportProgrammeList.type,
            actionCreators.getOfficeReportYearGroupList.type,
            actionCreators.createOfficeStudentByProgrammeReport.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const officeHoursReportsClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.setOfficeReportDepartmentList.type,
            actionCreators.setOfficeReportProgrammeList.type,
            actionCreators.setOfficeReportYearGroupList.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getOfficeReportDepartmentListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeReportDepartmentList.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl("staff/office-hours-report/deparment/list")).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.setOfficeReportDepartmentList.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOfficeReportYearGroupListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeReportYearGroupList.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl("staff/common/year-group/")).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.setOfficeReportYearGroupList.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOfficeReportProgrammeListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOfficeReportProgrammeList.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours-report/deparment/${payload.id}/programmes${
                            payload.type ? `?type=${payload.type}` : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.setOfficeReportProgrammeList.create({
                                department: payload,
                                programmes: response,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const createOfficeStudentByProgrammeReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.createOfficeStudentByProgrammeReport.type),
        switchMap(({ payload }) =>
            ajax
                .blob(
                    ajax.apiUrl(
                        "staff/office-hours-report/booking-history-by-programme-and-year-group/xlsx/"
                    ),
                    payload
                )
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(
                            response,
                            `StudentBookingHistory-${payload.departmentId}.xlsx`
                        )
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const createOfficeTeamReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.createOfficeTeamReport.type),
        switchMap(({ payload }) =>
            ajax
                .blob(
                    ajax.apiUrl("staff/office-hours-report/booking-history-for-team/xlsx/"),
                    payload
                )
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(
                            response,
                            `StudentBookingHistoryForTeam-${payload.from}.xlsx`
                        )
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const createAcademicAvailabilityReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.createAcademicAvailabilityReport.type),
        switchMap(({ payload }) =>
            ajax
                .blob(ajax.apiUrl("staff/office-hours-report/academic-availability/xlsx/"), payload)
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(
                            response,
                            `AcademicAvailability-${payload.from}-${payload.to}.xlsx`
                        )
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const createTeamAvailabilityReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.createTeamAvailabilityReport.type),
        switchMap(({ payload }) =>
            ajax
                .blob(ajax.apiUrl("staff/office-hours-report/team-availability/xlsx/"), payload)
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(
                            response,
                            `TeamAvailability-${payload.from}-${payload.to}.xlsx`
                        )
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const createGlobalStudentBookingHistoryReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.createGlobalStudentBookingHistoryReport.type),
        switchMap(({ payload }) =>
            ajax
                .blob(
                    ajax.apiUrl("staff/office-hours-report/global-student-booking-history/xlsx/"),
                    payload
                )
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(
                            response,
                            `GlobalStudentBookingHistory-${payload.from}-${payload.to}.xlsx`
                        )
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const createGlobalAcademicAvailabilityReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.createGlobalAcademicAvailabilityReport.type),
        switchMap(({ payload }) =>
            ajax
                .blob(
                    ajax.apiUrl("staff/office-hours-report/global-academic-availability/xlsx/"),
                    payload
                )
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(
                            response,
                            `GlobalAcademicAvailability-${payload.from}-${payload.to}.xlsx`
                        )
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const createStudentBookingHistoryWithMeReportEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.createStudentBookingHistoryWithMeReport.type),
        switchMap(({ payload }) =>
            ajax
                .blob(
                    ajax.apiUrl("staff/office-hours-report/academic-booking-report/xlsx/"),
                    payload
                )
                .pipe(
                    tap(({ response }) =>
                        FileSaver.saveAs(
                            response,
                            `AcademicBookings-${payload.from}-${payload.to}.xlsx`
                        )
                    ),
                    mapTo(UIActionCreators.clearLoading.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const officeHoursReportEpics = combineEpics(
    getOfficeReportDepartmentListEpic,
    getOfficeReportProgrammeListEpic,
    getOfficeReportYearGroupListEpic,
    createOfficeStudentByProgrammeReportEpic,
    createOfficeTeamReportEpic,
    officeHoursReportsStartLoadingEpic,
    officeHoursReportsClearLoadingEpic,
    createAcademicAvailabilityReportEpic,
    createGlobalStudentBookingHistoryReportEpic,
    createGlobalAcademicAvailabilityReportEpic,
    createStudentBookingHistoryWithMeReportEpic,
    createTeamAvailabilityReportEpic
);
