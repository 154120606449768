import globalsCss from "../styles/globals.css";
import { Chip, Avatar } from "@material-ui/core";
/** @jsx jsx */
import { jsx } from "@emotion/core";

export const AvatarChip = ({ name, numFollowers, noAvatar, ...rest }) => {
    return (
        <Chip
            label={`${name} ${numFollowers === undefined ? "" : `(${numFollowers} students)`}`}
            avatar={
                !noAvatar ? (
                    <Avatar>
                        {name
                            .split(" ", 3)
                            .map(part => part.charAt(0).toUpperCase())
                            .join("")}
                    </Avatar>
                ) : null
            }
            css={globalsCss.chipsItem}
            {...rest}
        />
    );
};
