import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";
import { of } from "rxjs";
import { mapTo, switchMap, mergeMap, catchError } from "rxjs/operators";

const overviewStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putOverview.type, actionCreators.getOverview.type),
        mapTo(UIActionCreators.setLoading.create())
    );

const overviewClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updateOverview.type, actionCreators.errorResponse.type),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getOverviewEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOverview.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl(`admin/support-page/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOverview.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const putOverviewEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putOverview.type),
        mergeMap(action =>
            ajax.put(ajax.apiUrl(`admin/support-page/${action.payload.id}/`), action.payload).pipe(
                mergeMap(({ response }) => of(actionCreators.getOverview.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    getOverviewEpic,
    putOverviewEpic,
    overviewStartLoadingEpic,
    overviewClearLoadingEpic
);
