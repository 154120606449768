import { SESSION_BOOKABLE } from "../app/OfficeHours/ManageOfficeHours/types";

export default {
    id: null,
    title: "",
    mode: SESSION_BOOKABLE,
    date: null,
    startAt: null,
    endAt: null,
    settings: {},
    earliestBooking: 0,
    latestBooking: 0,
    maxNumberOfAttendees: 1,
    maxAppointmentsPerSession: 1,
    addSessionPlaceholders: true,
    addBookedAppointments: false,
    receiveSummaryEmailAheadUpcomingAppointments: false,
    atEightOnSessionDay: true,
};
