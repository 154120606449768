import ActionCreator from "../../actionCreator";

export default {
    createListCategory: ActionCreator("CREATE_LIST_HOW_TO_CATEGORY"),
    updateListCategory: ActionCreator("UPDATE_LIST_HOW_TO_CATEGORY"),
    updateOneCategory: ActionCreator("UPDATE_ONE_HOW_TO_CATEGORY"),
    getListCategory: ActionCreator("GET_LIST_HOW_TO_CATEGORY"),
    getOneCategory: ActionCreator("GET_ONE_HOW_TO_CATEGORY"),
    pushListCategories: ActionCreator("PUSH_LIST_HOW_TO_CATEGORIES)"),
    postCategory: ActionCreator("POST_HOW_TO_CATEGORY"),
    setCurrentFormType: ActionCreator("SET_CURRENT_FORM_TYPE_HOW_TO_CATEGORY"),
    putCategory: ActionCreator("PUT_HOW_TO_CATEGORY"),
    updateForm: ActionCreator("@@redux-form/CHANGE"),
    loadMoreCategories: ActionCreator("LOAD_MORE_HOW_TO_CATEGORIES"),
    errorResponse: ActionCreator("ERROR_RESPONSE_HOW_TO_CATEGORY"),
    removeCategory: ActionCreator("REMOVE_HOW_TO_CATEGORY"),
    moveUpCategory: ActionCreator("MOVE_UP_HOW_TO_CATEGORY"),
    moveDownCategory: ActionCreator("MOVE_DOWN_HOW_TO_CATEGORY"),
};
