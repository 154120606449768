export default {
    emoticonsStep: 20,
    emoticonsSet: [
        { code: "1F600", desc: "" },
        { code: "1F603", desc: "" },
        { code: "1F604", desc: "" },
        { code: "1F601", desc: "" },
        { code: "1F606", desc: "" },
        { code: "1F605", desc: "" },
        { code: "1F923", desc: "" },
        { code: "1F602", desc: "" },
        { code: "1F642", desc: "" },
        { code: "1F643", desc: "" },
        { code: "1F609", desc: "" },
        { code: "1F60A", desc: "" },
        { code: "1F607", desc: "" },
        { code: "1F970", desc: "" },
        { code: "1F60D", desc: "" },
        { code: "1F929", desc: "" },
        { code: "1F618", desc: "" },
        { code: "1F617", desc: "" },
        { code: "1F61A", desc: "" },
        { code: "1F619", desc: "" },
        { code: "1F60B", desc: "" },
        { code: "1F61B", desc: "" },
        { code: "1F61C", desc: "" },
        { code: "1F92A", desc: "" },
        { code: "1F61D", desc: "" },
        { code: "1F911", desc: "" },
        { code: "1F917", desc: "" },
        { code: "1F92D", desc: "" },
        { code: "1F92B", desc: "" },
        { code: "1F914", desc: "" },
        { code: "1F910", desc: "" },
        { code: "1F928", desc: "" },
        { code: "1F610", desc: "" },
        { code: "1F611", desc: "" },
        { code: "1F636", desc: "" },
        { code: "1F60F", desc: "" },
        { code: "1F612", desc: "" },
        { code: "1F644", desc: "" },
        { code: "1F62C", desc: "" },
        { code: "1F925", desc: "" },
        { code: "1F60C", desc: "" },
        { code: "1F614", desc: "" },
        { code: "1F62A", desc: "" },
        { code: "1F924", desc: "" },
        { code: "1F634", desc: "" },
        { code: "1F637", desc: "" },
        { code: "1F912", desc: "" },
        { code: "1F915", desc: "" },
        { code: "1F922", desc: "" },
        { code: "1F92E", desc: "" },
        { code: "1F927", desc: "" },
        { code: "1F975", desc: "" },
        { code: "1F976", desc: "" },
        { code: "1F974", desc: "" },
        { code: "1F635", desc: "" },
        { code: "1F92F", desc: "" },
        { code: "1F920", desc: "" },
        { code: "1F973", desc: "" },
        { code: "1F60E", desc: "" },
        { code: "1F913", desc: "" },
        { code: "1F9D0", desc: "" },
        { code: "1F615", desc: "" },
        { code: "1F61F", desc: "" },
        { code: "1F641", desc: "" },
        { code: "2639", desc: "" },
        { code: "2639", desc: "" },
        { code: "1F62E", desc: "" },
        { code: "1F62F", desc: "" },
        { code: "1F632", desc: "" },
        { code: "1F633", desc: "" },
        { code: "1F97A", desc: "" },
        { code: "1F626", desc: "" },
        { code: "1F627", desc: "" },
        { code: "1F628", desc: "" },
        { code: "1F630", desc: "" },
        { code: "1F625", desc: "" },
        { code: "1F622", desc: "" },
        { code: "1F62D", desc: "" },
        { code: "1F631", desc: "" },
        { code: "1F616", desc: "" },
        { code: "1F623", desc: "" },
        { code: "1F61E", desc: "" },
        { code: "1F613", desc: "" },
        { code: "1F629", desc: "" },
        { code: "1F62B", desc: "" },
        { code: "1F971", desc: "" },
        { code: "1F624", desc: "" },
        { code: "1F621", desc: "" },
        { code: "1F620", desc: "" },
        { code: "1F92C", desc: "" },
        { code: "1F608", desc: "" },
        { code: "1F47F", desc: "" },
        { code: "1F480", desc: "" },
        { code: "2620", desc: "" },
        { code: "2620", desc: "" },
        { code: "1F4A9", desc: "" },
        { code: "1F921", desc: "" },
        { code: "1F479", desc: "" },
        { code: "1F47A", desc: "" },
        { code: "1F47B", desc: "" },
        { code: "1F47D", desc: "" },
        { code: "1F47E", desc: "" },
        { code: "1F916", desc: "" },
        { code: "1F63A", desc: "" },
        { code: "1F638", desc: "" },
        { code: "1F639", desc: "" },
        { code: "1F63B", desc: "" },
        { code: "1F63C", desc: "" },
        { code: "1F63D", desc: "" },
        { code: "1F640", desc: "" },
        { code: "1F63F", desc: "" },
        { code: "1F63E", desc: "" },
        { code: "1F648", desc: "" },
        { code: "1F649", desc: "" },
        { code: "1F64A", desc: "" },
        { code: "1F48B", desc: "" },
        { code: "1F48C", desc: "" },
        { code: "1F498", desc: "" },
        { code: "1F49D", desc: "" },
        { code: "1F496", desc: "" },
        { code: "1F497", desc: "" },
        { code: "1F493", desc: "" },
        { code: "1F49E", desc: "" },
        { code: "1F495", desc: "" },
        { code: "1F49F", desc: "" },
        { code: "2763", desc: "" },
        { code: "2763", desc: "" },
        { code: "1F494", desc: "" },
        { code: "2764", desc: "" },
        { code: "2764", desc: "" },
        { code: "1F9E1", desc: "" },
        { code: "1F49B", desc: "" },
        { code: "1F49A", desc: "" },
        { code: "1F499", desc: "" },
        { code: "1F49C", desc: "" },
        { code: "1F90E", desc: "" },
        { code: "1F5A4", desc: "" },
        { code: "1F90D", desc: "" },
        { code: "1F4AF", desc: "" },
        { code: "1F4A2", desc: "" },
        { code: "1F4A5", desc: "" },
        { code: "1F4AB", desc: "" },
        { code: "1F4A6", desc: "" },
        { code: "1F4A8", desc: "" },
        { code: "1F573", desc: "" },
        { code: "1F573", desc: "" },
        { code: "1F4A3", desc: "" },
        { code: "1F4AC", desc: "" },
        { code: "1F441", desc: "" },
        { code: "1F441", desc: "" },
        { code: "1F441", desc: "" },
        { code: "1F441", desc: "" },
        { code: "1F5E8", desc: "" },
        { code: "1F5E8", desc: "" },
        { code: "1F5EF", desc: "" },
        { code: "1F5EF", desc: "" },
        { code: "1F4AD", desc: "" },
        { code: "1F4A4", desc: "" },
    ],
};
