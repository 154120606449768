/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { Paper, Card, CardContent, Typography, CardActions, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import globalsCss from "../../../../common/styles/globals.css";
import {
    ROUTE_OFFICE_HOURS_REPORTS_BOOKING,
    ROUTE_OFFICE_HOURS_REPORTS_STUDENT_HISTORY,
    ROUTE_OFFICE_HOURS_REPORTS_TEAM_HISTORY,
    ROUTE_OFFICE_HOURS_REPORTS_ACADEMIC_AVAILABILITY,
    ROUTE_OFFICE_HOURS_REPORTS_TEAM_AVAILABILITY,
    ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_STUDENT_BOOKING_HISTORY,
    ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_ACADEMIC_AVAILABILITY,
    ROUTE_OFFICE_HOURS_REPORTS_STUDENT_BOOKING_HISTORY_WITH_ME,
} from "../routes";
import { hasAdminRole, hasManagerRole } from "../../../../common/services/Auth";
import { default as administratorActionCreators } from "../../../../store/OfficeHours/Administrators/actionCreators";
import { default as availabilityActionCreators } from "../../../../store/OfficeHours/Availabilities/actionCreators";

class OfficeHoursReportsContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            getOneOfficeHoursAdministrator,
            userId,
            getMyOfficeHoursTeams,
        } = this.props;
        setTitle("Bookings \u203A Reports");
        getOneOfficeHoursAdministrator({ id: userId });
        getMyOfficeHoursTeams(userId);
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    render() {
        const { permissionLevel, academics, teams } = this.props;
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>Booking Reports</h2>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <Card css={globalsCss.card}>
                                <CardContent>
                                    <Typography component="h3" css={globalsCss.subtitleCardTitle}>
                                        Booking History (by student)
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        component={Link}
                                        to={ROUTE_OFFICE_HOURS_REPORTS_BOOKING}
                                        size="small"
                                    >
                                        Show report
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <Card css={globalsCss.card}>
                                <CardContent>
                                    <Typography component="h3" css={globalsCss.subtitleCardTitle}>
                                        Student Booking History (by programme, year group)
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        component={Link}
                                        to={ROUTE_OFFICE_HOURS_REPORTS_STUDENT_HISTORY}
                                        size="small"
                                    >
                                        Show report
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                            <Card css={globalsCss.card}>
                                <CardContent>
                                    <Typography component="h3" css={globalsCss.subtitleCardTitle}>
                                        Student Booking History for Teams
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        component={Link}
                                        to={ROUTE_OFFICE_HOURS_REPORTS_TEAM_HISTORY}
                                        size="small"
                                    >
                                        Show report
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>

                        {((academics && academics.length > 0) ||
                            hasManagerRole(permissionLevel)) && (
                            <div className="col-sm-6 col-xs-12">
                                <Card style={globalsCss.card}>
                                    <CardContent>
                                        <Typography
                                            component="h3"
                                            style={globalsCss.subtitleCardTitle}
                                        >
                                            Academic Availability Report
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            component={Link}
                                            to={ROUTE_OFFICE_HOURS_REPORTS_ACADEMIC_AVAILABILITY}
                                            size="small"
                                        >
                                            Show report
                                        </Button>
                                    </CardActions>
                                </Card>
                            </div>
                        )}

                        {teams && teams.length > 0 && (
                            <div className="col-sm-6 col-xs-12">
                                <Card style={globalsCss.card}>
                                    <CardContent>
                                        <Typography
                                            component="h3"
                                            style={globalsCss.subtitleCardTitle}
                                        >
                                            Team Availability Report
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            component={Link}
                                            to={ROUTE_OFFICE_HOURS_REPORTS_TEAM_AVAILABILITY}
                                            size="small"
                                        >
                                            Show report
                                        </Button>
                                    </CardActions>
                                </Card>
                            </div>
                        )}

                        {hasAdminRole(permissionLevel) && (
                            <div className="col-sm-6 col-xs-12">
                                <Card style={globalsCss.card}>
                                    <CardContent>
                                        <Typography
                                            component="h3"
                                            style={globalsCss.subtitleCardTitle}
                                        >
                                            Global Student Booking History
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            component={Link}
                                            to={
                                                ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_STUDENT_BOOKING_HISTORY
                                            }
                                            size="small"
                                        >
                                            Show report
                                        </Button>
                                    </CardActions>
                                </Card>
                            </div>
                        )}

                        {hasAdminRole(permissionLevel) && (
                            <div className="col-sm-6 col-xs-12">
                                <Card style={globalsCss.card}>
                                    <CardContent>
                                        <Typography
                                            component="h3"
                                            style={globalsCss.subtitleCardTitle}
                                        >
                                            Global Academic Availability
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            component={Link}
                                            to={
                                                ROUTE_OFFICE_HOURS_REPORTS_GLOBAL_ACADEMIC_AVAILABILITY
                                            }
                                            size="small"
                                        >
                                            Show report
                                        </Button>
                                    </CardActions>
                                </Card>
                            </div>
                        )}

                        <div className="col-sm-6 col-xs-12">
                            <Card style={globalsCss.card}>
                                <CardContent>
                                    <Typography component="h3" style={globalsCss.subtitleCardTitle}>
                                        Student Booking History with me
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        component={Link}
                                        to={
                                            ROUTE_OFFICE_HOURS_REPORTS_STUDENT_BOOKING_HISTORY_WITH_ME
                                        }
                                        size="small"
                                    >
                                        Show report
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneOfficeHoursAdministrator:
        administratorActionCreators.getOneOfficeHoursAdministrator.create,
    getMyOfficeHoursTeams: availabilityActionCreators.getMyOfficeHoursTeams.create,
};

const mapStateToProps = state => ({
    permissionLevel: state.Auth.permissionLevel,
    userId: state.Auth.id,
    academics:
        state.OfficeHoursAdministrators.administrator &&
        state.OfficeHoursAdministrators.administrator.academics
            ? state.OfficeHoursAdministrators.administrator.academics
            : null,
    teams: state.OfficeHoursAvailabilities.teams
        ? state.OfficeHoursAvailabilities.teams.filter(team => team.userType === "admin")
        : [],
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursReportsContainer);
