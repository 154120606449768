import { validateRequiredFields } from "../../../../../common/services/formHelpers";

export default values => {
    const requiredFields = {
        teaser: "Required",
        content: "Required",
        startAt: "Required",
        endAt: "Required",
    };

    return validateRequiredFields(requiredFields, values);
};
