import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/NotificationBanner/actionCreators";
import mainMenuActionCreators from "../../../../store/MainMenu/actionCreators";
import { MainTitle } from "../../../../common/components/MainTitle";
import formsCss from "../../../../common/styles/forms.css";
import NotificationBannerForm from "../components/NotificationBannerForm";

/** @jsx jsx */
import { jsx } from "@emotion/core";

class NotificationBannerEditTemplateContainer extends Component {
    componentDidMount() {
        const {
            getNotification,
            setTitle,
            match: {
                params: { id },
            },
        } = this.props;
        setTitle("Notifications");
        getNotification(id);
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    formSubmitHandler = values => {
        this.props.putNotification({
            ...values,
            isDisplayedContinuously: values.isDisplayedContinuously === "true" ? true : false,
        });
    };

    render() {
        const {
            notification,
            history: { push },
            errorRes: { error, errors },
        } = this.props;

        return (
            <div>
                <MainTitle title="Edit Calendar Banner" />
                <div css={formsCss.genericError}>{error}</div>
                <NotificationBannerForm
                    submitText="Edit Calendar Banner"
                    initialValues={{
                        ...notification,
                        isDisplayedContinuously: notification.isDisplayedContinuously
                            ? "true"
                            : "false",
                    }}
                    onSubmit={this.formSubmitHandler}
                    {...{ errors, push }}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ NotificationBanner: { errorRes, notification } }) => ({
    errorRes,
    notification,
});

const dispatchToProps = {
    getNotification: actionCreators.getNotificationBanner.create,
    putNotification: actionCreators.putNotificationBanner.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    setTitle: mainMenuActionCreators.setTitle.create,
};

export default connect(mapStateToProps, dispatchToProps)(NotificationBannerEditTemplateContainer);
