import ActionCreator from "../../actionCreator";

export default {
    getListStaff: ActionCreator("GET_LIST_STAFF"),
    createListStaff: ActionCreator("CREATE_LIST_STAFF"),
    updateListStaff: ActionCreator("UPDATE_LIST_STAFF"),
    getOneStaff: ActionCreator("GET_ONE_STAFF"),
    updateOneStaff: ActionCreator("UPDATE_ONE_STAFF"),
    putStaff: ActionCreator("PUT_STAFF"),
    loadListStaff: ActionCreator("LOAD_LIST_STAFF"),
    setCurrentFormType: ActionCreator("SET_CURRENT_FORM_TYPE_STAFF"),
    errorResponse: ActionCreator("ERROR_RESPONSE_STAFF"),
    loadMoreStaff: ActionCreator("LOAD_MORE_STAFF"),
    clearListStaff: ActionCreator("CLEAR_LIST_STAFF"),
    postStaff: ActionCreator("POST_STAFF"),
    removeStaff: ActionCreator("REMOVE_STAFF"),
    downloadStaffList: ActionCreator("DOWNLOAD_XLS_STAFF"),
    downloadStaffListXmlTemplate: ActionCreator("DOWNLOAD_XLS_STAFF_UPLOAD_TEMPLATE"),
    uploadStaffListXml: ActionCreator("UPLOAD_XLS_STAFF_LIST"),
    toggleBulkUploadDialog: ActionCreator("TOGGLE_LSE_STAFF_BULK_UPLOAD_DIALOG"),
};
