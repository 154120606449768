import React from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import theme from "../common/styles/theme.json";
import { SnackbarProvider } from "notistack";
import AppContent from "./AppContent";
import { colors } from "../common/styles/colors";
import { WarningIcon } from "../common/components/icons";
import Notifier from "../common/components/Snackbar";

const lseTheme = createMuiTheme(theme);

const styles = {
    warning: {
        backgroundColor: "#fff",
        boxShadow: "rgba(204, 204, 204, 0.5) 0px 10px 30px, rgba(204, 204, 204, 0.6) 0px 6px 10px",
        color: colors.black87,
        borderLeft: "8px solid #FCA600",
        borderRadius: 2,
        paddingBottom: 12,
    },
    warningIcon: {
        color: "#FCA600",
        width: 56,
        height: 56,
        marginRight: 20,
    },
};

const AppWrapper = ({ classes }) => {
    return (
        <MuiThemeProvider theme={lseTheme}>
            <SnackbarProvider
                classes={{
                    variantWarning: classes.warning,
                }}
                iconVariant={{
                    warning: (
                        <WarningIcon
                            classes={{
                                root: classes.warningIcon,
                            }}
                        />
                    ),
                }}
            >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Notifier />
                    <AppContent />
                </MuiPickersUtilsProvider>
            </SnackbarProvider>
        </MuiThemeProvider>
    );
};

export default withStyles(styles)(AppWrapper);
