import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";

const dashboardStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getPages.type,
            actionCreators.getGroups.type,
            actionCreators.getAppointments.type,
            actionCreators.loadMorePages.type,
            actionCreators.loadMoreGroups.type,
            actionCreators.loadMoreAppointments.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const dashboardClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updatePages.type,
            actionCreators.updateGroups.type,
            actionCreators.updateAppointments.type,
            actionCreators.createPages.type,
            actionCreators.createGroups.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getPagesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getPages.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`api/dashboard/pages/`), { page: action.payload }).pipe(
                mergeMap(({ response }) => of(actionCreators.createPages.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMorePagesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMorePages.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`api/dashboard/pages/`), { page: action.payload }).pipe(
                mergeMap(({ response }) => of(actionCreators.updatePages.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getGroupsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getGroups.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`api/dashboard/groups/`), { page: action.payload }).pipe(
                mergeMap(({ response }) => of(actionCreators.createGroups.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreGroupsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreGroups.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`api/dashboard/groups/`), { page: action.payload }).pipe(
                mergeMap(({ response }) => of(actionCreators.updateGroups.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getAppointmentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getAppointments.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/${payload.userId}/appointments/?${
                            payload.query ? payload.query : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createAppointments.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreAppointmentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreAppointments.type),
        switchMap(({ payload }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `staff/office-hours/${payload.userId}/appointments/?${
                            payload.query ? payload.query : ""
                        }`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateAppointments.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    dashboardStartLoadingEpic,
    dashboardClearLoadingEpic,
    getPagesEpic,
    loadMorePagesEpic,
    getGroupsEpic,
    loadMoreGroupsEpic,
    getAppointmentsEpic,
    loadMoreAppointmentsEpic
);
