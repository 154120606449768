// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/Channels/Pages/Followers/actionCreators";
import { default as pageActionCreators } from "../../../../store/Channels/Pages/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { TableComponent, RedirectButton } from "../../../../common/components/Channels";
import { Chip, Button, Paper } from "@material-ui/core";
import { prepareFilterQuery } from "../../../../common/services/utils";
import filterListWithId from "../../../../common/services/FilterListWithId";

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import tableCss from "../../../../common/styles/table.css";

import { AutoRenewIcon, PreviewIcon, DeleteIcon } from "../../../../common/components/icons";

import { decorateTableItemsJobTypes } from "../../../../common/services/decorateTableItems";

import ChannelMembersFilterRow from "../../common/ChannelMembersFilterRow";
import { MainTitle } from "../../../../common/components/MainTitle";

import formsCss from "../../../../common/styles/forms.css";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
    },
];

const columns = ["id", "firstName", "lastName"];

const buttons = items => index => {
    return items[index] && items[index].fromAudience === true
        ? [null] // empty single button cell
        : [
              {
                  path: `${items[index] ? items[index].id : null}`,
                  label: "Unfollow",
                  fnLabel: "remove",
                  icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
              },
          ];
};

class PageChannelFollowersListContainer extends Component {
    state = {
        pageId: null,
        filter: "",
        type: "",
        filter$: new Subject(),
    };

    remove = itemID => {
        const { removePageFollower } = this.props;
        const { pageId } = this.state;
        removePageFollower({ id: itemID, pageId });
    };

    componentDidMount() {
        const {
            setTitle,
            getListFollowers,
            getOnePage,
            match: {
                params: { id },
            },
        } = this.props;
        const { filter$ } = this.state;
        this.setState({ pageId: id });
        setTitle("Channels \u203A Pages");
        getOnePage({ id: id });
        getListFollowers({ pageId: id });
        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            filterListWithId({
                getFn: getListFollowers,
                filter: term,
                id: { name: "pageId", value: id },
            });
        });
    }

    componentWillUnmount() {
        const { createListFollowers } = this.props;
        createListFollowers();
    }

    handler = ({ field, value }) => {
        const { filter$ } = this.state;
        this.setState({ [field]: value }, () =>
            filter$.next({
                filter: this.state.filter,
                type: this.state.type,
            })
        );
    };

    onFilterChangeHandler = field => event => {
        this.handler({ field, value: event.target.value });
    };

    onLoadMoreClickHandler = page => () => {
        const { loadMoreFollowers } = this.props;
        const { pageId, filter, term } = this.state;
        loadMoreFollowers({
            pageId,
            param: prepareFilterQuery({ filter, term, page }),
        });
    };

    render() {
        const {
            members,
            history: { push },
            nextPage,
            count,
            page,
        } = this.props;
        const { filter, type, pageId } = this.state;
        const {
            onFilterChangeHandler,
            onLoadMoreClickHandler,
            remove,
            accept,
            deny,
            upgrade,
            downgrade,
        } = this;

        return page ? (
            <div>
                <MainTitle
                    title={page.name}
                    type="[Page]"
                    rightElement={
                        <Button onClick={() => push(`/channels/pages/${pageId}`)}>
                            <PreviewIcon css={formsCss.btnIcon} />
                            Preview channel
                        </Button>
                    }
                />

                {!page.isSchoolChannel && (
                    <div css={globalsCss.actions}>
                        <RedirectButton
                            label="Add new follower"
                            url={`/channels/pages/${pageId}/followers/new`}
                            {...{ push }}
                        />
                    </div>
                )}

                <Paper elevation={1}>
                    {page.audiences && page.audiences.length > 0 && (
                        <div css={globalsCss.subtitleTableInner}>
                            <h3
                                css={[
                                    globalsCss.subtitleTitleAddOn,
                                    globalsCss.subtitleTitleType,
                                    globalsCss.subtitleTableItem,
                                ]}
                            >
                                Audiences
                            </h3>
                            {page.audiences.map(audience => (
                                <div css={globalsCss.subtitleTableItem} key={`c${audience.id}`}>
                                    <Chip
                                        key={audience.id}
                                        className={"confirmed"}
                                        label={audience.name}
                                    />
                                </div>
                            ))}

                            <hr css={globalsCss.subtitleTableHr} />
                        </div>
                    )}
                    <ChannelMembersFilterRow
                        {...{
                            filter,
                            type,
                            onFilterChangeHandler,
                            context: "page",
                        }}
                    />
                    <TableComponent
                        items={members}
                        fns={{ push, remove, accept, deny, upgrade, downgrade }}
                        buttons={page.isSchoolChannel ? undefined : buttons(members)}
                        decorators={decorators}
                        columns={columns}
                        wideContextMenu
                    />
                </Paper>

                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {members.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        ) : (
            <div></div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOnePage: pageActionCreators.getOnePage.create,

    getListFollowers: actionCreators.getListFollowers.create,
    loadMoreFollowers: actionCreators.loadMoreFollowers.create,
    createListFollowers: actionCreators.createListFollowers.create,

    removePageFollower: actionCreators.removePageFollower.create,
};
const mapStateToProps = ({
    ChannelPageFollowers: {
        members: { data, count, nextPage },
    },
    ChannelPage: { page },
}) => ({
    members: data.map(item => {
        return {
            id: item.user.id,
            type: item.type,
            fromAudience: item.fromAudience,
            firstName: item.user.firstName,
            lastName: item.user.lastName,
            isBackendUser: item.user.isBackendUser ? "Yes" : "No",
        };
    }),
    page,
    count,
    nextPage,
});
export default connect(mapStateToProps, dispatchToProps)(PageChannelFollowersListContainer);
