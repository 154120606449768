export default values => {
    const errors = {};
    const requiredFields = ["name", "startAt", "endAt", "location", "owners"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }

        if (Array.isArray(values[field])) {
            if (values[field].length === 0) {
                errors[field] = "Required";
            }
        }
    });

    if (values.startAt && values.endAt && values.startAt > values.endAt) {
        errors.endAt = "End date must be later than start date";
    }

    if (
        values &&
        values.channels &&
        values.channels.length === 0 &&
        values.customAudiences &&
        values.customAudiences.length === 0
    ) {
        errors.channels = "At least one page or audience must be selected.";
    }

    return errors;
};
