import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/Channels/Posts/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import globalsCss from "../../../common/styles/globals.css";
import formsCss from "../../../common/styles/forms.css";
import previewCss from "../../../common/styles/preview.css";
import tableDataCss from "../../../common/styles/tableData.css";
import { spacing } from "../../../common/styles/spacing";
import CommentListContainer from "../../Discussion/";
import { DateTime } from "../../../common/components/Time";
import Wysiwyg from "../../../common/components/Wysiwyg";
import { FavoriteIcon, CommentIcon } from "../../../common/components/icons";

class ChannelPreviewPostContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            getOnePost,
            match: {
                params: { id },
            },
        } = this.props;
        setTitle("Channels \u203A Pages");
        getOnePost({ id });
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    render() {
        const {
            history: { push },
            post,
            comments,
        } = this.props;
        if (!post) {
            return null;
        }

        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Preview Post</h2>
                        <div>
                            <div css={previewCss.status}>
                                Status:
                                <span
                                    css={[
                                        tableDataCss.statusActive,
                                        post.status === "scheduled" && tableDataCss.statusPending,
                                        post.status === "draft" && tableDataCss.statusBlocked,
                                    ]}
                                >
                                    {" "}
                                    {post.status}
                                </span>
                            </div>
                            {post.publicationDate && (
                                <div css={previewCss.publicationDate}>
                                    Publication date:{" "}
                                    <strong>
                                        <DateTime date={post.publicationDate} />
                                    </strong>
                                </div>
                            )}
                        </div>
                        <div css={previewCss.body}>
                            <div css={previewCss.paper}>
                                {post.imageUrl && (
                                    <div css={previewCss.mainImg}>
                                        <img
                                            src={post.imageUrl}
                                            alt=""
                                            css={previewCss.mainImg.img}
                                        />
                                    </div>
                                )}
                                <div css={previewCss.inner}>
                                    {!!post.name && post.name !== "" && post.name !== " " && (
                                        <div css={previewCss.title}>{post.name}</div>
                                    )}
                                    <div className="entryText">
                                        <Wysiwyg html={post.description} />
                                    </div>
                                    <div css={previewCss.summary}>
                                        <FavoriteIcon css={previewCss.favoriteIcon} />
                                        <div css={previewCss.btnComments}>
                                            <CommentIcon css={previewCss.commentIcon} />
                                            <span css={previewCss.btnCommentsText}>
                                                {comments.length > 0
                                                    ? `${comments.length} comments`
                                                    : "No comments"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {post.article && (
                                <div css={{ marginTop: spacing.space3 }}>
                                    <div css={previewCss.paper}>
                                        {post.imageUrl && (
                                            <div css={previewCss.mainImg}>
                                                <img
                                                    src={post.imageUrl}
                                                    alt=""
                                                    css={previewCss.mainImg.img}
                                                />
                                            </div>
                                        )}
                                        <div css={previewCss.inner}>
                                            <div className="entryText">
                                                <Wysiwyg html={post.article} />
                                            </div>
                                            <div css={previewCss.summary}>
                                                <FavoriteIcon css={previewCss.favoriteIcon} />
                                                <div css={previewCss.btnComments}>
                                                    <CommentIcon css={previewCss.commentIcon} />
                                                    <span css={previewCss.btnCommentsText}>
                                                        {comments.length > 0
                                                            ? `${comments.length} comments`
                                                            : "No comments"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <CommentListContainer {...this.props} />
                        </div>
                        <div css={formsCss.actions}>
                            {post.status !== "archived" && (
                                <Button
                                    onClick={() => push(`/channels/posts/edit/${post.id}`)}
                                    variant="contained"
                                    color="primary"
                                    css={formsCss.btnSubmit}
                                >
                                    Edit Post
                                </Button>
                            )}
                            <Button onClick={() => push(`/channels/posts`)} css={formsCss.btnBack}>
                                Back to the list
                            </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOnePost: actionCreators.getOnePost.create,
};
const mapStateToProps = ({ ChannelPosts: { post, pageId }, Discussion: { comments } }) => ({
    post,
    pageId,
    comments,
});
export default connect(mapStateToProps, dispatchToProps)(ChannelPreviewPostContainer);
