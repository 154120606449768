import { of } from "rxjs";
import { mergeMap, catchError, mapTo, switchMap, concatMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";
import { reset } from "redux-form";

const schoolYearsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListSchoolYears.type,
            actionCreators.loadMoreSchoolYears.type,
            actionCreators.getOneSchoolYear.type,
            actionCreators.postSchoolYear.type,
            actionCreators.putSchoolYear.type,
            actionCreators.addSchoolYearHoliday.type,
            actionCreators.removeSchoolYearHoliday.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const schoolYearsClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListSchoolYears.type,
            actionCreators.updateListSchoolYears.type,
            actionCreators.updateOneSchoolYear.type,
            actionCreators.pushListSchoolYears.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListSchoolYearsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListSchoolYears.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`admin/settings/school-year/?${action.payload}`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createListSchoolYears.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreSchoolYearsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreSchoolYears.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`admin/settings/school-year/?${action.payload}`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateListSchoolYears.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneSchoolYearEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneSchoolYear.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`admin/settings/school-year/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOneSchoolYear.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newSchoolYearEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postSchoolYear.type),
        mergeMap(action =>
            ajax
                .post(ajax.apiUrl("admin/settings/school-year/"), action.payload)
                .pipe(
                    mapTo(actionCreators.pushListSchoolYears.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const editSchoolYearEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putSchoolYear.type),
        mergeMap(action =>
            ajax
                .put(
                    ajax.apiUrl(`admin/settings/school-year/${action.payload.id}/`),
                    action.payload
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.pushListSchoolYears.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removeSchoolYearEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeSchoolYear.type),
        mergeMap(action =>
            ajax.remove(ajax.apiUrl(`admin/settings/school-year/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.pushListSchoolYears.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const pushListSchoolYearsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListSchoolYears.type),
        mapTo(push(`/settings/school-years`))
    );

const addSchoolYearHolidayEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.addSchoolYearHoliday.type),
        mergeMap(action =>
            ajax
                .post(
                    ajax.apiUrl(`admin/settings/school-year/${action.payload.id}/holiday/`),
                    action.payload
                )
                .pipe(
                    concatMap(() =>
                        of(
                            reset("SettingsSchoolYearHoliday"),
                            actionCreators.getOneSchoolYear.create({ id: action.payload.id })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removeSchoolYearHolidayEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeSchoolYearHoliday.type),
        mergeMap(action =>
            ajax
                .remove(
                    ajax.apiUrl(
                        `admin/settings/school-year/${action.payload.id}/holiday/${action.payload.holidayId}/`
                    )
                )
                .pipe(
                    mergeMap(() =>
                        of(actionCreators.getOneSchoolYear.create({ id: action.payload.id }))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    schoolYearsStartLoadingEpic,
    schoolYearsClearLoadingEpic,
    getListSchoolYearsEpic,
    newSchoolYearEpic,
    pushListSchoolYearsEpic,
    getOneSchoolYearEpic,
    editSchoolYearEpic,
    removeSchoolYearEpic,
    addSchoolYearHolidayEpic,
    removeSchoolYearHolidayEpic,
    loadMoreSchoolYearsEpic
);
