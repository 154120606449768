import actionCreators from "./actionCreators";

const initialState = {
    members: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListFollowers.type:
            return {
                ...state,
                members: payload ? payload : state.members,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListFollowers.type:
            payload.data = [
                ...state.members.data,
                ...payload.data.filter(
                    member => !state.members.data.map(item => item.id).includes(member.id)
                ),
            ];
            return {
                ...state,
                members: payload ? payload : state.members,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearListFollowers.type:
            return {
                ...state,
                members: initialState.members,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
