import { Component } from "react";
import { connect } from "react-redux";
import Form from "./Form/";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { default as officeHoursTeamsActionCreators } from "../../../store/OfficeHours/Teams/actionCreators";
import actionCreators from "../../../store/Channels/Pages/actionCreators";
import currentFormTypes from "../../../common/components/CurrentFormTypes";
import ChannelPageValues from "../../../fixtures/ChannelPage";
import formsCss from "../../../common/styles/forms.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class NewPageChannelContainer extends Component {
    componentDidMount() {
        const { setTitle, setCurrentFormType, getListOfficeHoursTeams, match } = this.props;
        setTitle("Channels \u203A Pages");
        const type = match.params.id ? currentFormTypes.EDIT : currentFormTypes.NEW;
        setCurrentFormType(type);
        getListOfficeHoursTeams("mode=available");
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    onSubmit = values => {
        this.props.postPage({
            ...values,
            officeHoursTeam: values.officeHoursTeam ? { id: values.officeHoursTeam } : null,
        });
    };

    render() {
        const {
            currentFormType,
            initialValues,
            history: { goBack },
            updateForm,
            owners,
            audiences,
            currentUser,
            errorRes: { error, errors },
            hasActivePosts,
            getOwners,
            getAudiences,
            officeHoursTeams,
        } = this.props;
        const { onSubmit } = this;

        initialValues.owners = [
            {
                firstName: currentUser.firstName,
                id: currentUser.id,
                lastName: currentUser.lastName,
            },
        ];

        return (
            <div>
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    initialValues={{ ...initialValues, status: "draft" }}
                    {...{
                        onSubmit,
                        currentFormType,
                        goBack,
                        updateForm,
                        owners,
                        audiences,
                        errors,
                        hasActivePosts,
                        getOwners,
                        getAudiences,
                        officeHoursTeams,
                    }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOnePage: actionCreators.getOnePage.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
    updateForm: actionCreators.updateForm.create,
    getOwners: AutoCompleteActionCreators.getStaffUsers.create,
    getAudiences: AutoCompleteActionCreators.getAudiences.create,
    postPage: actionCreators.postPage.create,
    getListOfficeHoursTeams: officeHoursTeamsActionCreators.getListOfficeHoursTeams.create,
};
const mapStateToProps = ({ ChannelPage, Auth, AutoComplete, OfficeHoursTeams }) => ({
    hasActivePosts: ChannelPage.page.hasActivePosts,
    initialValues: ChannelPageValues,
    errorRes: ChannelPage.errorRes,
    currentFormType: ChannelPage.currentFormType,
    owners: AutoComplete.staff.map(owner =>
        Object.assign({ fullName: `${owner.firstName} ${owner.lastName}` }, owner)
    ),
    audiences: AutoComplete.audiences,
    currentUser: {
        firstName: Auth.firstName,
        lastName: Auth.lastName,
        id: Auth.id,
    },
    officeHoursTeams: OfficeHoursTeams.teams,
});
export default connect(mapStateToProps, dispatchToProps)(NewPageChannelContainer);
