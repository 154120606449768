import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/Channels/Posts/actionCreators";
import tabsCSS from "../../../../common/styles/tabs.css";
import TabsComponent from "../../../../common/components/Tabs";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class ChannelPostTabs extends Component {
    render() {
        const { hasArticle, setArticleStatus } = this.props;
        return (
            <TabsComponent
                activeIndex={hasArticle ? 1 : 0}
                tabs={[
                    {
                        label: "Short (Default)",
                        selected: !hasArticle,
                        fnLabel: "setArticleStatus",
                        param: false,
                    },
                    {
                        label: "Longform Post",
                        selected: hasArticle,
                        fnLabel: "setArticleStatus",
                        param: true,
                    },
                ]}
                fns={{ setArticleStatus }}
                tabscss={tabsCSS.postTabs}
            />
        );
    }
}

const dispatchToProps = {
    setArticleStatus: actionCreators.setArticleStatus.create,
};
const mapStateToProps = ({ ChannelPosts: { hasArticle } }) => ({
    hasArticle,
});
export default connect(mapStateToProps, dispatchToProps)(ChannelPostTabs);
