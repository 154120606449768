import actionCreators from "./actionCreators";

export const initialState = {
    homepage: null,
    knowledgebaseCategories: null,
    knowledgebaseArticles: null,
    knowledgebaseArticle: null,
    title: null,
    isLoading: false,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.setTitle.type:
            return {
                ...state,
                title: payload,
            };
        case actionCreators.clearTitle.type:
            return {
                ...state,
                title: null,
            };
        case actionCreators.createHomepage.type:
            return {
                ...state,
                homepage: payload,
            };
        case actionCreators.updateKnowledgeBaseCategories.type:
            return {
                ...state,
                knowledgebaseCategories: payload,
            };
        case actionCreators.updateKnowledgeBaseArticles.type:
            return {
                ...state,
                knowledgebaseArticles: payload,
            };
        case actionCreators.updateOneOneKnowledgeBaseArticle.type:
            return {
                ...state,
                knowledgebaseArticle: payload,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.clearKnowledgeBaseArticle.type:
            return {
                ...state,
                knowledgebaseArticles: initialState.knowledgebaseArticles,
                knowledgebaseArticle: initialState.knowledgebaseArticle,
            };
        default:
            return state;
    }
};
