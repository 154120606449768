import actionCreators from "./actionCreators";
import availability from "../../../fixtures/OfficeHoursAvailability";

import {
    REPEATING_DAILY,
    REPEATING_WEEKLY,
    REPEATING_MONTHLY,
    REPEAT_N_TIMES,
    REPEAT_UNTIL,
    TYPE_REPEATING,
    TYPE_NON_REPEATING,
    MEETING_ZOOM,
    MEETING_CUSTOM,
    MEETING_NONE,
} from "../../../app/OfficeHours/ManageOfficeHours/types";

let repeatingConfig = {};

export const initialState = {
    academics: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    teams: null,
    teamMembers: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    availabilities: null,
    availability,
    team: null,
    academic: null,
    availabilityTemplate: null,
    confirmForceRequest: false,
    calendar: [],
    errorRes: { error: "", errors: {} },
    slotsForCancellation: null,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.createMyOfficeHoursAcademics.type:
            return {
                ...state,
                academics: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListMyOfficeHoursAcademics.type:
            payload.data = [
                ...state.academics.data,
                ...payload.data.filter(
                    academic => !state.academics.data.map(item => item.id).includes(academic.id)
                ),
            ];
            return {
                ...state,
                academics: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearMyOfficeHoursAcademics.type:
            return {
                ...state,
                academics: initialState.academics,
            };
        case actionCreators.createMyOfficeHoursTeams.type:
            return {
                ...state,
                teams: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearMyOfficeHoursTeams.type:
            return {
                ...state,
                teams: initialState.teams,
            };
        case actionCreators.createMyOfficeHoursTeamAcademics.type:
            return {
                ...state,
                teamMembers: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListMyOfficeHoursTeamAcademics.type:
            payload.data = [
                ...state.teamMembers.data,
                ...payload.data.filter(
                    academic => !state.teamMembers.data.map(item => item.id).includes(academic.id)
                ),
            ];
            return {
                ...state,
                teamMembers: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearMyOfficeHoursTeamAcademics.type:
            return {
                ...state,
                teamMembers: initialState.teamMembers,
            };
        case actionCreators.createAvailabilities.type:
            return {
                ...state,
                availabilities: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearAvailabilities.type:
            return {
                ...state,
                availabilities: initialState.availabilities,
            };
        case actionCreators.createOneMyOfficeHoursAcademic.type:
            return {
                ...state,
                academic: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOneMyOfficeHoursAcademic.type:
            return {
                ...state,
                academic: initialState.academic,
            };
        case actionCreators.createOneMyOfficeHoursTeam.type:
            return {
                ...state,
                team: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOneMyOfficeHoursTeam.type:
            return {
                ...state,
                team: initialState.team,
            };
        case actionCreators.showConfirmForceRequest.type:
            return {
                ...state,
                confirmForceRequest: payload,
            };
        case actionCreators.hideConfirmForceRequest.type:
            return {
                ...state,
                confirmForceRequest: false,
            };
        case actionCreators.createOneMyOfficeHoursAvailability.type:
            repeatingConfig = {};

            if (payload.repeatingRule && payload.repeatingRule.type) {
                if (payload.repeatingRule.type === REPEATING_DAILY) {
                    repeatingConfig.daily = {
                        every: payload.repeatingRule.repeatEvery,
                        config: payload.repeatingRule.finishType,
                        ntimes:
                            payload.repeatingRule.finishType === REPEAT_N_TIMES
                                ? payload.repeatingRule.repeatTimes
                                : null,
                        until:
                            payload.repeatingRule.finishType === REPEAT_UNTIL
                                ? payload.repeatingRule.repeatDate
                                : null,
                    };
                } else if (payload.repeatingRule.type === REPEATING_WEEKLY) {
                    repeatingConfig.weekly = {
                        every: payload.repeatingRule.repeatEvery,
                        config: payload.repeatingRule.finishType,
                        ntimes:
                            payload.repeatingRule.finishType === REPEAT_N_TIMES
                                ? payload.repeatingRule.repeatTimes
                                : null,
                        until:
                            payload.repeatingRule.finishType === REPEAT_UNTIL
                                ? payload.repeatingRule.repeatDate
                                : null,
                        repeatMon:
                            payload.repeatingRule.weekdays &&
                            payload.repeatingRule.weekdays.includes(1),
                        repeatTue:
                            payload.repeatingRule.weekdays &&
                            payload.repeatingRule.weekdays.includes(2),
                        repeatWed:
                            payload.repeatingRule.weekdays &&
                            payload.repeatingRule.weekdays.includes(3),
                        repeatThu:
                            payload.repeatingRule.weekdays &&
                            payload.repeatingRule.weekdays.includes(4),
                        repeatFri:
                            payload.repeatingRule.weekdays &&
                            payload.repeatingRule.weekdays.includes(5),
                        repeatSat:
                            payload.repeatingRule.weekdays &&
                            payload.repeatingRule.weekdays.includes(6),
                        repeatSun:
                            payload.repeatingRule.weekdays &&
                            payload.repeatingRule.weekdays.includes(7),
                    };
                } else if (payload.repeatingRule.type === REPEATING_MONTHLY) {
                    repeatingConfig.monthly = {
                        every: payload.repeatingRule.repeatEvery,
                        config: payload.repeatingRule.finishType,
                        ntimes:
                            payload.repeatingRule.finishType === REPEAT_N_TIMES
                                ? payload.repeatingRule.repeatTimes
                                : null,
                        until:
                            payload.repeatingRule.finishType === REPEAT_UNTIL
                                ? payload.repeatingRule.repeatDate
                                : null,
                    };
                }
            }

            return {
                ...state,
                availability: {
                    ...payload,
                    date: payload.start,
                    startAt: payload.start,
                    endAt: payload.end,
                    repeating: payload.repeatingRule ? TYPE_REPEATING : TYPE_NON_REPEATING,
                    ...repeatingConfig,
                    location: payload.settings.location,
                    appointmentForm: payload.settings.appointmentForm,
                    note: payload.settings.note,
                    email: payload.settings.email,
                    maxNumberOfAttendees:
                        payload.settings.maxNumberOfAttendees > 1
                            ? payload.settings.maxNumberOfAttendees
                            : 1,
                    maxAppointmentsPerSession:
                        payload.settings.maxAppointmentsPerSession > 1
                            ? payload.settings.maxAppointmentsPerSession
                            : 1,
                    duration: payload.settings.duration,
                    earliestBooking: payload.settings.earliestBooking
                        ? payload.settings.earliestBooking
                        : 0,
                    latestBooking: payload.settings.latestBooking
                        ? payload.settings.latestBooking
                        : 0,
                    allowFileUpload: payload.settings.allowFileUpload,
                    requireBookingNote: payload.settings.requireBookingNote,
                    settings: null,
                    repeatingRule: null,
                    meetingUrl: payload.settings.meetingUrl,
                    integrateWithZoom: payload.settings.integrateWithZoom,

                    addSessionPlaceholders: payload.settings.addSessionPlaceholders,
                    addBookedAppointments: payload.settings.addBookedAppointments,
                    receiveSummaryEmailAheadUpcomingAppointments: !payload.settings
                        .receiveSummaryEmailAheadUpcomingAppointments,

                    ...(payload.settings.atEightOnSessionDay ? { atEightOnSessionDay: true } : {}),
                    ...(payload.settings.timeInAdvance
                        ? {
                              timeInAdvance: true,
                              timeInAdvanceValue: payload.settings.timeInAdvance,
                          }
                        : { timeInAdvanceValue: 0 }),
                    ...(payload.settings.summaryAtTime
                        ? {
                              summaryAtTime: true,
                              summaryAtTimeValue: payload.settings.summaryAtTime,
                          }
                        : { summaryAtTimeValue: 0 }),
                    ...(payload.settings.atLatestBookingTime ? { atLatestBookingTime: true } : {}),
                    onlineMeetings:
                        !payload.settings.meetingUrl && !!payload.settings.integrateWithZoom
                            ? MEETING_ZOOM
                            : !!payload.settings.meetingUrl && !payload.settings.integrateWithZoom
                            ? MEETING_CUSTOM
                            : MEETING_NONE,
                },
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOneMyOfficeHoursAvailability.type:
            return {
                ...state,
                availability: initialState.availability,
            };
        case actionCreators.createOfficeCalendarSlots.type:
            return {
                ...state,
                calendar: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOfficeCalendarSlots.type:
            return {
                ...state,
                calendar: [...initialState.calendar],
            };
        case actionCreators.createOfficeHoursDefaultAvailability.type:
            return {
                ...state,
                availabilityTemplate: {
                    ...payload,
                    settings: {
                        ...payload.settings,
                        maxNumberOfAttendees:
                            payload.settings.maxNumberOfAttendees > 1
                                ? payload.settings.maxNumberOfAttendees
                                : 1,
                        maxAppointmentsPerSession:
                            payload.settings.maxAppointmentsPerSession > 1
                                ? payload.settings.maxAppointmentsPerSession
                                : 1,
                        earliestBooking: payload.settings.earliestBooking
                            ? payload.settings.earliestBooking
                            : 0,
                        latestBooking: payload.settings.latestBooking
                            ? payload.settings.latestBooking
                            : 0,
                        addSessionPlaceholders: payload.settings.addSessionPlaceholders,
                        addBookedAppointments: payload.settings.addBookedAppointments,
                        receiveSummaryEmailAheadUpcomingAppointments: !payload.settings
                            .receiveSummaryEmailAheadUpcomingAppointments,
                        ...(payload.settings.atEightOnSessionDay
                            ? { atEightOnSessionDay: true }
                            : {}),
                        ...(payload.settings.timeInAdvance
                            ? {
                                  timeInAdvanceValue: payload.settings.timeInAdvance,
                              }
                            : { timeInAdvanceValue: 0 }),
                        ...(payload.settings.summaryAtTime
                            ? {
                                  summaryAtTimeValue: payload.settings.summaryAtTime,
                              }
                            : { summaryAtTimeValue: 0 }),
                        ...(payload.settings.atLatestBookingTime
                            ? { atLatestBookingTime: true }
                            : {}),
                    },
                    maxAppointmentsPerUserPerTerm:
                        payload.maxAppointmentsPerUserPerTerm > 0
                            ? payload.maxAppointmentsPerUserPerTerm
                            : null,
                    timeInAdvance: payload.settings.timeInAdvance ? true : undefined,
                    summaryAtTime: payload.settings.summaryAtTime ? true : undefined,
                },
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOfficeHoursDefaultAvailability.type:
            return {
                ...state,
                availabilityTemplate: initialState.availabilityTemplate,
            };
        case actionCreators.createSlotsForCancellation.type:
            return {
                ...state,
                slotsForCancellation: payload,
            };
        case actionCreators.clearSlotsForCancellation.type:
        case actionCreators.getSlotsForCancellation.type:
            return {
                ...state,
                slotsForCancellation: initialState.slotsForCancellation,
            };

        default:
            return state;
    }
};
