import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";

const officeHoursAcademicsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListOfficeHoursAcademics.type,
            actionCreators.loadMoreOfficeHoursAcademics.type,
            actionCreators.getOneOfficeHoursAcademic.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const officeHoursAcademicsClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListOfficeHoursAcademics.type,
            actionCreators.updateListOfficeHoursAcademics.type,
            actionCreators.updateOneOfficeHoursAcademic.type,
            actionCreators.pushListOfficeHoursAcademics.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListOfficeHoursAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListOfficeHoursAcademics.type),
        switchMap(action =>
            ajax
                .get(ajax.apiUrl(`staff/office-hours/associated-academics/?${action.payload}`))
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createListOfficeHoursAcademics.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreOfficeHoursAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreOfficeHoursAcademics.type),
        switchMap(action =>
            ajax
                .get(ajax.apiUrl(`staff/office-hours/associated-academics/?${action.payload}`))
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListOfficeHoursAcademics.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneOfficeHoursAcademicEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneOfficeHoursAcademic.type),
        switchMap(action =>
            ajax
                .get(ajax.apiUrl(`staff/office-hours/associated-academics/`), {
                    filter: action.payload.id,
                })
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateOneOfficeHoursAcademic.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const pushListOfficeHoursAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListOfficeHoursAcademics.type),
        mapTo(push(`/bookings/administrators/academics`))
    );

export const epics = combineEpics(
    officeHoursAcademicsStartLoadingEpic,
    officeHoursAcademicsClearLoadingEpic,
    getListOfficeHoursAcademicsEpic,
    pushListOfficeHoursAcademicsEpic,
    getOneOfficeHoursAcademicEpic,
    loadMoreOfficeHoursAcademicsEpic
);
