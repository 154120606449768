import { spacing, fontWeight } from "../../UIGlobals";

export const mainCategoryStyle = {
    body: {
        marginBottom: spacing.space4,
    },
    title: {
        color: "#23A493",
        fontSize: 24,
        fontWeight: fontWeight.black,
        textDecoration: "none",
        margin: 0,
    },
    text: {
        color: "rgba(0,0,0,.48)",
        fontSize: 16,
        lineHeight: 1.5,
        margin: `${spacing.space0}px 0 0`,
    },
};
