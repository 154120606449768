export default values => {
    const errors = {};
    const requiredFields = ["status", "name", "imageFile", "iconFile", "owners", "topic"];
    requiredFields.forEach(field => {
        if (
            !values[field] ||
            (values[field].hasOwnProperty("base64") && values[field].base64 === "")
        ) {
            if (field === "imageFile") {
                if (!values["imageUrl"]) {
                    errors[field] = "Required";
                }
            } else if (field === "iconFile") {
                if (!values["iconUrl"]) {
                    errors[field] = "Required";
                }
            } else {
                errors[field] = "Required";
            }
        }
        if (Array.isArray(values[field])) {
            if (values[field].length === 0) {
                errors[field] = "Required";
            }
        }
    });

    if (values && values.cannotUnfollowAudiences && values.cannotUnfollowAudiences.length > 0) {
        values.cannotUnfollowAudiences.forEach(v => {
            if (values.canUnfollowAudiences.find(n => n.id === v.id))
                errors.cannotUnfollowAudiences = "Same audience used twice in both lists";
        });
    }

    if (values && values.canUnfollowAudiences && values.canUnfollowAudiences.length > 0) {
        values.canUnfollowAudiences.forEach(v => {
            if (values.cannotUnfollowAudiences.find(n => n.id === v.id))
                errors.canUnfollowAudiences = "Same audience used twice in both lists";
        });
    }

    return errors;
};
