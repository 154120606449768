export default {
    id: 0,
    category: "",
    name: "",
    imageUrl: "",
    iconUrl: "",
    description: "",
    numFollowers: 0,
    email: "",
};
