import { colors } from "./colors";
import { spacing } from "./spacing";
import { fontSize, fontWeight, lineHeight, fontFamily } from "./typography";

const defaultBorder = `1px solid ${colors.black10}`;

export default {
    dateTime: {
        fontSize: 16,
        fontWeight: 500,
        color: colors.black47,
        marginTop: spacing.space1,
        marginBottom: 0,

        "@media (max-width: 767px)": {
            fontSize: 14,
        },
    },
    label: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        lineHeight: lineHeight.text,
        padding: 0,
        fontWeight: fontWeight.bold,
        color: colors.black54,
    },
    value: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        lineHeight: lineHeight.text,
        fontWeight: fontWeight.normal,
        color: colors.black,
        marginBottom: spacing.space1,
    },
    source: {
        display: "flex",
        fontSize: fontSize.textSmaller,
        padding: 0,
        marginTop: spacing.space0,
        color: colors.black42,
    },

    actions: {
        borderTop: defaultBorder,
        borderBottom: defaultBorder,
        paddingTop: spacing.space2,
        paddingBottom: spacing.space2,
        marginTop: spacing.space3,
        marginBottom: spacing.space3,
    },
    actionsInner: {
        display: "flex",
        marginRight: spacing.space0 * -1,
        marginLeft: spacing.space0 * -1,
    },
    btn: {
        display: "inline-flex",
        flex: 1,
        width: "100%",
        border: "0 none",
        borderRadius: 8,
        backgroundColor: colors.grey,
        color: colors.black87,
        fontSize: fontSize.text,
        fontWeight: fontWeight.medium,
        lineHeight: 1,
        textDecoration: "none",
        minHeight: 48,
        // paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        // paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        marginRight: spacing.space0,
        marginLeft: spacing.space0,
        justifyContent: "center",
        alignItems: "center",
    },
    btnDisabled: {
        opacity: 0.4,
    },
    btnText: {
        marginRight: spacing.space0,
    },
    btnIcon: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 18,
        lineHeight: "1",
        width: 18,
        height: 18,
        transform: "rotate(135deg)",
    },
};
