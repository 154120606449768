import { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import validate from "./validate";
import { RenderAutocomplete } from "../../../../common/components/FormFields";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";

import { Button } from "@material-ui/core";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";

class Form extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const { getAcademics, getAdmins, clearAcademics, clearAdmins } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            if (query.filter !== "") {
                rawUpdateListOnFilterChange({
                    getFn: query.type === "academic" ? getAcademics : getAdmins,
                    filter: { filter: query.filter },
                });
            } else query.type === "academic" ? clearAcademics() : clearAdmins();
        });
        this.props.initialize(this.props.initialValues);
    }

    onAcademicChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () =>
            searchText$.next({ filter: this.state.searchText, type: "academic" })
        );
    };

    onAdminsChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () =>
            searchText$.next({ filter: this.state.searchText, type: "admin" })
        );
    };

    render() {
        const {
            handleSubmit,
            errors,
            formError,
            push,
            admins,
            academic,
            currentFormType,
            clearAcademics,
            clearAdmins,
            initialValues,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    {initialValues.academic.id > 0 && initialValues.academic.hasBookings === false && (
                        <div className="col-xs-12" css={formsCss.userDetails}>
                            <b>Please note:</b> this academic cannot hold office hours!
                        </div>
                    )}

                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="academic"
                            errorRes={errors.academic}
                            handleInput={this.onAcademicChangeHandler}
                            dataSource={academic}
                            component={RenderAutocomplete}
                            single
                            onDroppedFocus={() => clearAcademics()}
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <label css={formsCss.labelBig}>Bookings Administrator(s)</label>
                        <Field
                            name="admins"
                            errorRes={errors.admins}
                            handleInput={this.onAdminsChangeHandler}
                            dataSource={admins}
                            component={RenderAutocomplete}
                            onDroppedFocus={() => clearAdmins()}
                            blacklistedIds={[initialValues.academic.id]}
                        />
                    </div>
                </div>
                {formError && (
                    <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                        {formError}
                    </div>
                )}
                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        {currentFormType === currentFormTypes.NEW
                            ? "Add Managed Academic"
                            : "Save Managed Academic"}
                    </Button>
                    <Button
                        onClick={() => push("/bookings/administrators/academics")}
                        css={formsCss.btnBack}
                    >
                        Back to the list
                    </Button>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: "OfficeHoursAcademics",
    enableReinitialize: true,
    validate,
})(Form);
