import ActionCreator from "../../actionCreator";

export default {
    getListPage: ActionCreator("GET_LIST_CHANNEL_PAGE"),
    createListPage: ActionCreator("CREATE_LIST_CHANNEL_PAGE"),
    updateListPage: ActionCreator("UPDATE_LIST_CHANNEL_PAGE"),
    getOnePage: ActionCreator("GET_ONE_CHANNEL_PAGE"),
    updateOnePage: ActionCreator("UPDATE_ONE_CHANNEL_PAGE"),
    postPage: ActionCreator("POST_CHANNEL_PAGE"),
    putPage: ActionCreator("PUT_CHANNEL_PAGE"),
    setCurrentFormType: ActionCreator("SET_CURRENT_FORM_TYPE_CHANNEL_PAGE"),
    updateForm: ActionCreator("@@redux-form/CHANGE"),
    loadMorePages: ActionCreator("LOAD_MORE_CHANNEL_PAGES"),
    errorResponse: ActionCreator("ERROR_RESPONSE_CHANNEL_PAGE"),
    removePage: ActionCreator("REMOVE_CHANNEL_PAGE"),
    clearErrors: ActionCreator("CLEAR_ERROR_CHANNEL_PAGE"),
    clearQueryParams: ActionCreator("CLEAR_QUERY_PARAMS_CHANNEL_PAGE"),
    refreshFollowers: ActionCreator("REFRESH_PAGE_FOLLOWERS"),
    refreshAllFollowers: ActionCreator("REFRESH_ALL_PAGES_FOLLOWERS"),
};
