import actionCreators from "./actionCreators";
// import appointment from "../../../fixtures/OfficeHoursAdministrator";

export const initialState = {
    appointments: {
        data: [],
        count: 0,
        nextPage: 0,
        queryParams: "",
    },
    appointment: null,
    slotHistory: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    syncDetails: null,
    schoolYears: null,
    errorRes: { error: "", errors: {} },
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListOfficeHoursAppointments.type:
            return {
                ...state,
                appointments: { ...payload, queryParams: state.appointments.queryParams },
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListOfficeHoursAppointments.type:
            payload.data = [
                ...state.appointments.data,
                ...payload.data.filter(
                    appointment =>
                        !state.appointments.data.map(item => item.id).includes(appointment.id)
                ),
            ];
            return {
                ...state,
                appointments: { ...payload, queryParams: state.appointments.queryParams },
                errorRes: initialState.errorRes,
            };

        case actionCreators.getListOfficeHoursAppointments.type:
        case actionCreators.getListPastOfficeHoursAppointments.type:
        case actionCreators.loadMoreOfficeHoursAppointments.type:
        case actionCreators.loadMorePastOfficeHoursAppointments.type:
            return {
                ...state,
                appointments: { ...state.appointments, queryParams: payload.query },
            };

        case actionCreators.createOneOfficeHoursAppointmentDetails.type:
            return {
                ...state,
                appointment: payload,
            };
        case actionCreators.createOneOfficeHoursAppointmentSession.type:
            return {
                ...state,
                appointment: {
                    ...state.appointment,
                    session: payload,
                },
            };
        case actionCreators.clearOneOfficeHoursAppointmentDetails.type:
            return {
                ...state,
                appointment: initialState.appointment,
            };
        case actionCreators.createListOfficeHoursAppointmentHistory.type:
            return {
                ...state,
                slotHistory: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListOfficeHoursAppointmentHistory.type:
            payload.data = [
                ...state.slotHistory.data,
                ...payload.data.filter(
                    entry => !state.slotHistory.data.map(item => item.id).includes(entry.id)
                ),
            ];
            return {
                ...state,
                slotHistory: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOfficeHoursAppointmentHistory.type:
            return {
                ...state,
                slotHistory: initialState.slotHistory,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.updateSyncDetails.type: {
            let processed = {};
            for (var key in payload) {
                processed[key.split("-").join("_")] = payload[key];
            }
            return {
                ...state,
                syncDetails: processed,
            };
        }
        case actionCreators.createOfficeHoursSchoolYearsAndTerms.type:
            return {
                ...state,
                schoolYears: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOfficeHoursAppointmentStudentAttendance.type:
            return {
                ...state,
                appointment: state.appointment
                    ? {
                          ...state.appointment,
                          studentAttended: payload.studentAttended,
                      }
                    : state.appointment,
                appointments:
                    state.appointments &&
                    state.appointments.data &&
                    state.appointments.data.length > 0
                        ? {
                              data: state.appointments.data.map(a =>
                                  a.id === payload.id
                                      ? { ...a, studentAttended: payload.studentAttended }
                                      : a
                              ),
                              count: state.appointments.count,
                              nextPage: state.appointments.nextPage,
                          }
                        : state.appointments,
            };
        case actionCreators.updateOfficeHoursAppointmentAttendanceComment.type:
            return {
                ...state,
                appointment: {
                    ...state.appointment,
                    comment: payload.comment,
                },
                appointments:
                    state.appointments &&
                    state.appointments.data &&
                    state.appointments.data.length > 0
                        ? {
                              data: state.appointments.data.map(a =>
                                  a.id === payload.id ? { ...a, comment: payload.comment } : a
                              ),
                              count: state.appointments.count,
                              nextPage: state.appointments.nextPage,
                          }
                        : state.appointments,
            };
        default:
            return state;
    }
};
