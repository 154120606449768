import { Component } from "react";
import { reduxForm, Field } from "redux-form";
import validate from "./validate";
import { RenderTextField, RenderDatepicker } from "../../../../common/components/FormFields";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";

import Button from "@material-ui/core/Button";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";
import globalsCss from "../../../../common/styles/globals.css";

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const { handleSubmit, push, currentFormType, errors } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="name"
                            errorRes={errors.name}
                            type="name"
                            component={RenderTextField}
                            label="Name"
                            fullWidth
                        />
                    </div>
                </div>

                <div className="row" css={globalsCss.helpers.mt15}>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <label css={formsCss.labelDateTime}>Start of Autumn Term</label>
                        <Field
                            name="startFirstTerm"
                            errorRes={errors.startFirstTerm}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                        />
                    </div>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <label css={formsCss.labelDateTime}>End of Autumn Term</label>
                        <Field
                            name="endFirstTerm"
                            errorRes={errors.endFirstTerm}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                        />
                    </div>
                </div>

                <div className="row" css={globalsCss.helpers.mt15}>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <label css={formsCss.labelDateTime}>Start of Winter Term</label>
                        <Field
                            name="startSecondTerm"
                            errorRes={errors.startSecondTerm}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                        />
                    </div>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <label css={formsCss.labelDateTime}>End of Winter Term</label>
                        <Field
                            name="endSecondTerm"
                            errorRes={errors.endSecondTerm}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                        />
                    </div>
                </div>

                <div className="row" css={globalsCss.helpers.mt15}>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <label css={formsCss.labelDateTime}>Start of Spring Term</label>
                        <Field
                            name="startThirdTerm"
                            errorRes={errors.startThirdTerm}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                        />
                    </div>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <label css={formsCss.labelDateTime}>End of Spring Term</label>
                        <Field
                            name="endThirdTerm"
                            errorRes={errors.endThirdTerm}
                            component={RenderDatepicker}
                            format={null}
                            hintText="Enter Date"
                            fullWidth
                        />
                    </div>
                </div>

                <div css={formsCss.actions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        {currentFormType === currentFormTypes.NEW
                            ? "Add New Academic Year"
                            : "Edit Academic Year"}
                    </Button>
                    <Button onClick={() => push("/settings/school-years")} css={formsCss.btnBack}>
                        Back to the list
                    </Button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: "SettingsSchoolYear",
    enableReinitialize: true,
    validate,
})(Form);

export default form;
