import { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../common/styles/globals.css";
import formsCss from "../../common/styles/forms.css";
import { LogoutIcon } from "../../common/components/icons";
import { logoutUrl } from "../../store/Auth/sso";

class UserNotFound extends Component {
    render() {
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h3>You don’t have access to the Student Hub Admin Panel.</h3>
                    <p>
                        To have your account enabled, please email{" "}
                        <a href="mailto:studenthub@lse.ac.uk">studenthub@lse.ac.uk</a>
                    </p>
                    <br />
                    <br />
                    <Button
                        onClick={() => {
                            window.location.href = logoutUrl;
                        }}
                    >
                        <LogoutIcon css={formsCss.btnIcon} />
                        Logout
                    </Button>
                </div>
            </Paper>
        );
    }
}

export default UserNotFound;
