import CheckPermission from "../components/CheckPermission";
import {
    USER_TYPE_STANDARD,
    USER_TYPE_MANAGER,
    USER_TYPE_ADMIN,
} from "../../app/UsersAndAudiences/LseStaff/values";

const requiredUserRoles = [USER_TYPE_STANDARD, USER_TYPE_MANAGER, USER_TYPE_ADMIN];
const requiredManagerRoles = [USER_TYPE_MANAGER, USER_TYPE_ADMIN];
const requiredAdminRoles = [USER_TYPE_ADMIN];

export const isUser = CheckPermission(requiredUserRoles);
export const isManager = CheckPermission(requiredManagerRoles);
export const isAdmin = CheckPermission(requiredAdminRoles);

export const hasUserRole = userRoles => requiredUserRoles.includes(userRoles);
export const hasManagerRole = userRoles => requiredManagerRoles.includes(userRoles);
export const hasAdminRole = userRoles => requiredAdminRoles.includes(userRoles);
