import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";
import { push } from "connected-react-router";

const apiGetNotificationDetails = id => ajax.get(ajax.apiUrl(`manager/notification-banner/${id}/`));

const startLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getNotificationBanners.type,
            actionCreators.deleteNotificationBanner.type,
            actionCreators.postNotificationBanner.type,
            actionCreators.putNotificationBanner.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const clearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updateNotificationBanners.type,
            actionCreators.updateMoreNotificationBanners.type,
            actionCreators.updateDeletedNotificationBanner.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getNotificationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getNotificationBanners.type),
        switchMap(({ payload: params }) =>
            ajax.get(ajax.apiUrl(`manager/notification-banner/`), params).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateNotificationBanners.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );
const getNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getNotificationBanner.type),
        switchMap(({ payload: id }) =>
            apiGetNotificationDetails(id).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.setNotificationBannner.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );
const loadMoreNotificationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreNotificationBanners.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`manager/notification-banner/?${action.payload}`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateMoreNotificationBanners.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const deleteNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteNotificationBanner.type),
        switchMap(({ payload }) =>
            ajax
                .remove(ajax.apiUrl(`manager/notification-banner/${payload}/`))
                .pipe(
                    mapTo(
                        actionCreators.updateDeletedNotificationBanner.create(JSON.parse(payload))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const postNotificationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postNotificationBanner.type),
        switchMap(({ payload }) =>
            ajax
                .post(ajax.apiUrl(`manager/notification-banner/`), payload)
                .pipe(
                    mapTo(push("/calendar/notification-banners")),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const putNotificationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putNotificationBanner.type),
        switchMap(({ payload }) =>
            ajax
                .put(ajax.apiUrl(`manager/notification-banner/${payload.id}/`), payload)
                .pipe(
                    mapTo(push("/calendar/notification-banners")),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    startLoadingEpic,
    clearLoadingEpic,
    getNotificationsEpic,
    loadMoreNotificationsEpic,
    getNotificationEpic,
    deleteNotificationEpic,
    postNotificationsEpic,
    putNotificationsEpic
);
