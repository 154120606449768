export const globalDictionary = {
    id: "ID",
    lseId: "[SSO] Username",
    firstName: "First Name",
    lastName: "Last Name",
    status: "Status",
    email: "E-mail",
    photoUrl: "Photo",
    numFollowers: "# Followers",
    PublicationDate: "Publication date",
    name: "Name",
    live: "Live",
    draft: "Draft",
    scheduled: "Scheduled",
    description: "Description",
    location: "Location",
    isRecommended: "Is Recommended?",
};
