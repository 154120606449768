import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import {
    avatar,
    avatarSmall,
    avatarBig,
    avatarHuge,
    avatarSmallComments,
    avatarSmaller,
    avatarMedium,
} from "./AvatarStyles";
import defaultAvatar from "../../images/default-avatar.png";

class Avatar extends Component {
    render() {
        const {
            src,
            style,
            smallComments,
            small,
            smaller,
            medium,
            big,
            huge,
            borderStyle,
            imgStyle,
            initials,
        } = this.props;

        return (
            <div
                css={[
                    avatar.body,
                    smallComments && avatarSmallComments.body,
                    small && avatarSmall.body,
                    smaller && avatarSmaller.body,
                    medium && avatarMedium.body,
                    big && avatarBig.body,
                    huge && avatarHuge.body,
                    style && style,
                ]}
            >
                <span
                    css={[
                        avatar.border,
                        smallComments && avatarSmallComments.border,
                        small && avatarSmall.border,
                        smaller && avatarSmaller.border,
                        medium && avatarMedium.border,
                        big && avatarBig.border,
                        huge && avatarHuge.border,
                        borderStyle && borderStyle,
                    ]}
                />
                {!src && (
                    <span css={avatar.initials}>
                        <span
                            css={[
                                avatar.initialsText,
                                smallComments && avatarSmallComments.initialsText,
                                small && avatarSmall.initialsText,
                                smaller && avatarSmaller.initialsText,
                                medium && avatarMedium.initialsText,
                                big && avatarBig.initialsText,
                                huge && avatarHuge.initialsText,
                            ]}
                        >
                            {initials}
                        </span>
                    </span>
                )}
                <span
                    src={src ? src : defaultAvatar}
                    css={[
                        avatar.img,
                        { backgroundImage: `url(${src || defaultAvatar})` },
                        smallComments && avatarSmallComments.img,
                        small && avatarSmall.img,
                        smaller && avatarSmaller.img,
                        medium && avatarMedium.img,
                        big && avatarBig.img,
                        huge && avatarHuge.img,
                        imgStyle && imgStyle,
                    ]}
                />
            </div>
        );
    }
}

export default Avatar;
