import ActionCreator from "../actionCreator";

export default {
    getListBuildings: ActionCreator("GET_LIST_BUILDINGS"),
    createListBuildings: ActionCreator("CREATE_LIST_BUILDINGS"),
    getOneBuilding: ActionCreator("GET_ONE_BUILDING"),
    updateOneBuilding: ActionCreator("UPDATE_ONE_BUILDING"),
    putBuilding: ActionCreator("PUT_BUILDING"),
    loadListBuildings: ActionCreator("LOAD_LIST_BUILDINGS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_BUILDING"),
    clearListBuildings: ActionCreator("CLEAR_LIST_BUILDINGS"),
    postBuilding: ActionCreator("POST_BUILDING"),
    removeBuilding: ActionCreator("REMOVE_BUILDING"),
};
