/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { FC, forwardRef, useState } from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import formsCss from "src/common/styles/forms.css";
import { useFormContext, Controller } from "react-hook-form";
import moment from "moment-timezone";
import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";

interface OwnProps {
    name: string;
    placeholder?: string;
    label?: string;
    classes: any;
    errorRes?: string;
    myRef?: any;
}

const RHFTimepicker: FC<OwnProps & TextFieldProps> = forwardRef(
    ({ name, placeholder, label, classes, errorRes, myRef, ...other }, ref) => {
        const { control } = useFormContext();
        ref = myRef;
        const hoursValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const minutesValues = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => {
                    const forceUpdate = useState()[1].bind(null, undefined);

                    const d = moment.tz("Europe/London").set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                    });

                    const now = moment(field.value ? field.value : d);
                    const londonOffset = now.tz("Europe/London").utcOffset();
                    const diff = londonOffset;

                    const roundTo5 = value => (value % 5 !== 0 ? Math.floor(value / 5) * 5 : value);

                    const setHours = value => {
                        const offset =
                            field.value !== null
                                ? now.tz("Europe/London").format("a") === "pm"
                                    ? 12
                                    : 0
                                : 0;
                        const savedDate = now.get("date");
                        const savedMonth = now.get("month");
                        const newValue = now
                            .utc()
                            .subtract(diff, "minutes")
                            .set({
                                hour: Number(value < 12 ? value : 0) + Number(offset) - diff / 60,
                                date: savedDate,
                                month: savedMonth,
                            });
                        field.onChange(
                            newValue.set("minute", roundTo5(newValue.get("minute"))).format()
                        );
                        forceUpdate();
                    };

                    const setMinutes = value => {
                        field.onChange(
                            now
                                .utc()
                                .set({
                                    minute: value,
                                })
                                .format()
                        );
                        forceUpdate();
                    };

                    const setAmPm = value => {
                        if (!(value === "am" && !field.value)) {
                            if (!field.value) {
                                setHours(0);
                            }
                            const newValue = now.utc().set({
                                hour: now.utc().get("hour") + (value === "am" ? -12 : +12),
                            });

                            field.onChange(newValue.format());
                            forceUpdate();
                        }
                    };

                    const getHours = () =>
                        field.value !== null ? now.tz("Europe/London").format("h") : 12;

                    const getMinutes = () =>
                        field.value !== null ? now.tz("Europe/London").format("m") : 0;

                    const getAmPm = () =>
                        field.value !== null ? now.tz("Europe/London").format("a") : "am";

                    return (
                        <div css={formsCss.timepicker}>
                            <div css={formsCss.timepickerBody}>
                                <TextField
                                    fullWidth={false}
                                    select
                                    placeholder={"HH"}
                                    margin="normal"
                                    color="primary"
                                    InputProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                            error: classes.inputError,
                                        },
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            MenuListProps: {
                                                disablePadding: true,
                                                dense: true,
                                            },
                                        },
                                    }}
                                    value={getHours()}
                                    onChange={e => setHours(e.target.value)}
                                    {...other}
                                >
                                    {hoursValues.map(item => (
                                        <MenuItem key={`m.${item}`} value={item}>
                                            {item < 10 ? `0${item}` : item}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <div css={formsCss.timepickerSeparator}>{"."}</div>
                                <TextField
                                    fullWidth={false}
                                    select
                                    placeholder={"MM"}
                                    margin="normal"
                                    color="primary"
                                    InputProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                            error: classes.inputError,
                                        },
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            MenuListProps: {
                                                disablePadding: true,
                                                dense: true,
                                            },
                                        },
                                    }}
                                    value={roundTo5(getMinutes())}
                                    onChange={e => setMinutes(e.target.value)}
                                    {...other}
                                    label={label ? " " : null}
                                >
                                    {minutesValues.map(item => (
                                        <MenuItem key={`m.${item}`} value={item}>
                                            {item < 10 ? `0${item}` : item}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    fullWidth={false}
                                    css={formsCss.timepickerTimeAmPm}
                                    select
                                    margin="normal"
                                    color="primary"
                                    InputProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                            error: classes.inputError,
                                        },
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            MenuListProps: {
                                                disablePadding: true,
                                                dense: true,
                                            },
                                        },
                                    }}
                                    value={getAmPm()}
                                    onChange={e => setAmPm(e.target.value)}
                                    {...other}
                                    label={label ? " " : null}
                                >
                                    <MenuItem key={`am`} value={"am"}>
                                        {"am"}
                                    </MenuItem>
                                    <MenuItem key={`pm`} value={"pm"}>
                                        {"pm"}
                                    </MenuItem>
                                </TextField>
                            </div>
                            {(!!error || !!errorRes) && (
                                <div css={formsCss.timepickerError}>
                                    {error && error.message
                                        ? error.message
                                        : errorRes
                                        ? errorRes
                                        : ""}
                                </div>
                            )}
                        </div>
                    );
                }}
            />
        );
    }
);

const RenderTimepicker = withStyles(formsCss)(RHFTimepicker);

export default RenderTimepicker;
