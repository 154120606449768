import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./Form/";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import actionCreators from "../../../store/Settings/SchoolYears/actionCreators";
import formsCss from "../../../common/styles/forms.css";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";

class EditSchoolYearsContainer extends Component {
    componentDidMount() {
        const { setTitle, getOneSchoolYear, match } = this.props;
        setTitle("Settings \u203A Academic Years");
        getOneSchoolYear({ id: match.params.id });
    }

    componentWillUnmount() {
        const { clearTitle, clearSchoolYear } = this.props;
        clearSchoolYear();
        clearTitle();
    }

    onSubmit = values => {
        this.props.putSchoolYear(values);
    };

    render() {
        const {
            initialValues,
            history: { push },
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.EDIT;

        return (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Edit Academic Year</h2>
                        {error && <div css={formsCss.genericError}>{error}</div>}
                        {initialValues.id > 0 && (
                            <Form
                                {...{
                                    initialValues,
                                    currentFormType,
                                    onSubmit,
                                    push,
                                    errors,
                                }}
                            />
                        )}
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneSchoolYear: actionCreators.getOneSchoolYear.create,
    putSchoolYear: actionCreators.putSchoolYear.create,
    clearSchoolYear: actionCreators.clearSchoolYear.create,
};
const mapStateToProps = ({ SettingsSchoolYears }) => ({
    initialValues: SettingsSchoolYears.schoolYear,
    errorRes: SettingsSchoolYears.errorRes,
});
export default connect(mapStateToProps, dispatchToProps)(EditSchoolYearsContainer);
