import { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import dialogCss from "./../styles/dialog.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class DialogField extends Component {
    state = {
        open: false,
    };

    // polyfil implemenation for IE compatibility
    createNewEvent = eventName => {
        var event;
        if (typeof Event === "function") {
            event = new Event(eventName);
        } else {
            event = document.createEvent("Event");
            event.initEvent(eventName, true, true);
        }
        return event;
    };

    handleOpen = () => {
        this.setState({ open: true });
        setTimeout(() => {
            window.dispatchEvent(this.createNewEvent("resize"));
        }, 1500);
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes, iconImage, dialogContent, maxWidth, fullWidth } = this.props;
        return (
            <Fragment>
                <IconButton onClick={this.handleOpen}>{iconImage}</IconButton>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    maxWidth={maxWidth}
                    fullWidth={fullWidth}
                >
                    <span onClick={this.handleClose} css={dialogCss.dialogImgCloseBtn}>
                        <span>&times;</span>
                    </span>
                    <DialogContent
                        classes={{
                            root: classes.dialogContentRoot,
                        }}
                    >
                        {dialogContent}
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}
const RowDialogField = withStyles(dialogCss)(DialogField);

export default RowDialogField;
