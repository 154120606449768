import { Component } from "react";
import { connect } from "react-redux";
import Form from "./Form";
import { default as mainMenuActionCreators } from "../../store/MainMenu/actionCreators";
import actionCreators from "../../store/SupportPage/actionCreators";
import currentFormTypes from "../../common/components/CurrentFormTypes";
import formsCss from "../../common/styles/forms.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class EditSupportPageContainer extends Component {
    componentDidMount() {
        const { setTitle, getOverview, setCurrentFormType } = this.props;
        setTitle("Overview");
        getOverview();
        setCurrentFormType(currentFormTypes.EDIT);
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    onSubmit = values => {
        this.props.putOverview(values);
    };

    onTypeChange = (event, curr) => {
        this.props.setLinkTypeStatus(curr === "link" ? true : false);
    };

    render() {
        const {
            initialValues,
            errorRes: { error, errors },
            currentFormType,
            history: { push },
            isLink,
        } = this.props;
        const { onSubmit, onTypeChange } = this;
        return (
            <div>
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    {...{
                        initialValues,
                        onSubmit,
                        currentFormType,
                        push,
                        errors,
                        isLink,
                        onTypeChange,
                    }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    putOverview: actionCreators.putOverview.create,
    getOverview: actionCreators.getOverview.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
    setLinkTypeStatus: actionCreators.setLinkTypeStatus.create,
};
const mapStateToProps = state => ({
    errorRes: state.SupportPage.errorRes,
    initialValues: state.SupportPage.overview,
    currentFormType: state.SupportPage.currentFormType,
    isLink: state.SupportPage.isLink,
});
export default connect(mapStateToProps, dispatchToProps)(EditSupportPageContainer);
