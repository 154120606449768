/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { FC } from "react";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, IconButton, InputBase } from "@material-ui/core";
import toolbarCss from "../../../common/styles/toolbar.css";
import { DatePicker } from "material-ui-pickers";
import datepickerCss from "../../../common/styles/datepicker.css";
import { ClearIcon } from "../../../common/components/icons";

interface OwnProps {
    from?: Date;
    to?: Date;
    filter?: string;
    onFilterChangeHandler: any;
    onDateChangeHandler: any;
    classes: any;
}

const StandardNotificationsFilterRow: FC<OwnProps> = ({
    from,
    to,
    filter,
    onFilterChangeHandler,
    onDateChangeHandler,
    classes,
}) => {
    return (
        <AppBar
            position="static"
            classes={{
                root: classes.appBarRoot,
            }}
            elevation={0}
        >
            <Toolbar
                classes={{
                    root: classes.toolbarRoot,
                }}
            >
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Keyword or ID:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Filter:</span>
                        </span>
                    </Typography>
                    <InputBase
                        placeholder="..."
                        value={filter || ""}
                        onChange={onFilterChangeHandler("filter")}
                        fullWidth
                        autoComplete="off"
                    />
                </div>
                <div css={toolbarCss.toolbarSeparator} />
                <div css={toolbarCss.toolbarGroup}>
                    <Typography>
                        <span css={toolbarCss.toolbarTitle}>
                            <span css={toolbarCss.labelShowOnDesktop}>Date:</span>
                            <span css={toolbarCss.labelShowOnMobile}>Date:</span>
                        </span>
                    </Typography>
                    <div css={datepickerCss.datepickerContainer}>
                        <DatePicker
                            placeholder="From..."
                            value={from ? from : null}
                            onChange={value =>
                                onDateChangeHandler("from")(
                                    value.set({ hour: 0, minute: 0, second: 0 })
                                )
                            }
                            css={toolbarCss.toolbarDatePicker}
                            fullWidth={true}
                            autoOk={true}
                            format={"D MMM YYYY"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <IconButton
                            onClick={onDateChangeHandler("from", null)}
                            css={datepickerCss.datepickerBtn}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                    <div css={datepickerCss.datepickerContainer}>
                        <DatePicker
                            placeholder="To..."
                            value={to ? to : null}
                            onChange={value =>
                                onDateChangeHandler("to")(
                                    value.set({ hour: 23, minute: 59, second: 59 })
                                )
                            }
                            css={toolbarCss.toolbarDatePicker}
                            fullWidth={true}
                            autoOk={true}
                            format={"D MMM YYYY"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <IconButton
                            onClick={onDateChangeHandler("to", null)}
                            css={datepickerCss.datepickerBtn}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default withStyles(toolbarCss)(StandardNotificationsFilterRow);
