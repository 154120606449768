import React, { FC, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFTextField, RHFSelect } from "src/common/components/RHF-Fields";
import { CurrentFormType } from "src/types/generic";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "src/common/styles/globals.css";
import formsCss from "src/common/styles/forms.css";
import { EmailTemplate } from "../types/EmailTemplate";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import actionCreators from "../../../store/Notification/actionCreators";
import { Button, Card, CardActionArea, CardContent, Paper } from "@material-ui/core";

export type EmailTemplateFormValues = EmailTemplate;

export enum FieldType {
    SUBJECT = "subject",
    CONTENT = "content",
}

const EmailTemplateSchema = () =>
    yup.object().shape({
        name: yup
            .string()
            .typeError("Required")
            .required("Required"),
        content: yup
            .string()
            .typeError("Required")
            .required("Required"),
    });

interface OwnProps {
    initialValues: EmailTemplate;
    formType: CurrentFormType;
    error?: string;
    errors?: { [key: string]: string };
    onSubmit: (data: EmailTemplateFormValues) => void;
    push: (pathname: string) => void;
}

const EmailTemplateForm: FC<OwnProps> = ({
    initialValues,
    formType,
    error,
    errors,
    onSubmit,
    push,
}) => {
    const dispatch = useDispatch();
    const [currentRef, setCurrentRef] = useState<any>(null);
    const notificationsEmails = useSelector(
        (state: AppState) => state.Notification.notificationsEmails
    );
    const subjectRef: any = useRef();
    const contentRef: any = useRef();

    const methods = useForm<EmailTemplate>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(EmailTemplateSchema()),
        defaultValues: initialValues,
    });
    const { handleSubmit, formState, reset, setValue } = methods;

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    useEffect(() => {
        if (!notificationsEmails) dispatch(actionCreators.getNotificationEmails.create());
    }, [notificationsEmails]);

    const handleInsertPlaceholder = placeholder => {
        const myRef = currentRef === FieldType.SUBJECT ? subjectRef.current : contentRef.current;

        const value = myRef.value;

        // save selection start and end position
        const start = myRef.selectionStart;
        const end = myRef.selectionEnd;

        // update the value with our text inserted

        setValue(currentRef, value.slice(0, start) + placeholder + value.slice(end));

        // update cursor to be at the end of insertion
        myRef.selectionStart = myRef.selectionEnd = start + placeholder.length;
    };

    const placeholders = {
        "%additional-text%": "optional text added to the template when sending a notification.",
        "%day-of-week%": "meeting day",
        "%start-time%": "time when meeting starts",
        "%end-time%": "time when meeting ends",
        "%room-code%": "room ID",
        "%meeting-dates%": "calendar dates for all the meetings in a teaching.",
        "%mailto:replace-with-email%": "clickable email link",
        "%course-code%": "course code - can be used in template content or email subject",
        "%course-name%": "course name - can be used in template content or email subject",
    };

    return (
        <Paper elevation={1}>
            <div css={globalsCss.inner}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <RHFTextField
                                name="name"
                                label="Template Name"
                                errorRes={errors && errors.name}
                            />

                            <RHFSelect
                                name="sendAs"
                                label="Send as"
                                errorRes={errors && errors.sendAs}
                                options={
                                    notificationsEmails
                                        ? notificationsEmails.map(item => {
                                              return {
                                                  value: item.key,
                                                  label: `${item.name} <${item.email}>`,
                                              };
                                          })
                                        : []
                                }
                            />
                        </div>
                        <div className="col-sm-8 col-xs-12"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 col-xs-12">
                            <RHFTextField
                                name={FieldType.SUBJECT}
                                label="Email Subject"
                                myRef={subjectRef}
                                onFocus={() => setCurrentRef(FieldType.SUBJECT)}
                                onBlur={() => setCurrentRef(null)}
                                errorRes={errors && errors.subject}
                            />
                        </div>
                        <div className="col-sm-4 col-xs-12"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 col-xs-12">
                            <RHFTextField
                                name={FieldType.CONTENT}
                                label="Template Content"
                                fullWidth
                                multiline
                                rows={8}
                                rowsMax={25}
                                myRef={contentRef}
                                onFocus={() => setCurrentRef(FieldType.CONTENT)}
                                onBlur={() => setCurrentRef(null)}
                                errorRes={errors && errors.content}
                            />
                        </div>
                        <div className="col-sm-4 col-xs-12"></div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-xs-12">
                            <label css={formsCss.labelBig}>Available placeholders:</label>
                        </div>
                        {Object.keys(placeholders).map(key => (
                            <div
                                className="col-sm-3 col-xs-12"
                                css={globalsCss.helpers.mt25}
                                key={key}
                            >
                                <Card>
                                    <CardActionArea
                                        disabled={!currentRef}
                                        onClick={e => {
                                            e.preventDefault();
                                            handleInsertPlaceholder(key);
                                        }}
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        <CardContent css={!currentRef && { opacity: 0.5 }}>
                                            <strong>{key}</strong> - {placeholders[key]}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        ))}
                    </div>

                    {error && <strong>{error}</strong>}
                    <div css={formsCss.actions}>
                        <Button
                            disabled={formState.isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            css={formsCss.btnSubmit}
                        >
                            {formType === CurrentFormType.NEW ? "Add template" : "Save template"}
                        </Button>
                        <Button
                            disabled={formState.isSubmitting}
                            onClick={() => push("/email-templates/list")}
                            css={formsCss.btnBack}
                        >
                            Back to the list
                        </Button>
                    </div>
                </FormProvider>
            </div>
        </Paper>
    );
};

export default EmailTemplateForm;
