import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/Channels/Groups/Members/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { default as groupActionCreators } from "../../../../store/Channels/Groups/actionCreators";
import Form from "./Form";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";
import { MainTitle } from "../../../../common/components/MainTitle";
import { GetFormattedDate } from "../../../../common/components/Time";

class GroupChannelNewMembersContainer extends Component {
    state = {
        groupId: null,
    };

    componentDidMount() {
        const {
            setTitle,
            getOneGroup,
            match: {
                params: { groupId },
            },
        } = this.props;
        this.setState({ groupId });
        setTitle("Channels \u203A Groups");
        getOneGroup({ id: groupId });
    }

    componentWillUnmount() {
        const { clearTitle, clearGroup } = this.props;
        clearTitle();
        clearGroup();
    }

    onSubmit = values => {
        const { postGroupMembers } = this.props;
        const { groupId } = this.state;

        if (values && values.students) {
            const lastStudent = values.students.slice(-1)[0];
            for (const student of values.students) {
                postGroupMembers({
                    userId: student.id,
                    groupId,
                    isLast: lastStudent.id === student.id,
                });
            }
        }
    };

    render() {
        const {
            group,
            errorRes: { error, errors },
            history: { push },
        } = this.props;
        const { groupId } = this.state;
        const { onSubmit } = this;

        return (
            <div>
                <MainTitle
                    title={group.name}
                    type="[Group]"
                    subtitle={
                        group.lastActiveDate &&
                        `Last active date: ${GetFormattedDate(
                            group.lastActiveDate,
                            "dddd D MMMM YYYY"
                        )}`
                    }
                />
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    {...{
                        onSubmit,
                        errors,
                        push,
                        groupId,
                    }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    postGroupMembers: actionCreators.postGroupMembers.create,
    getOneGroup: groupActionCreators.getOneGroup.create,
    clearGroup: groupActionCreators.clearGroup.create,
};
const mapStateToProps = ({ ChannelGroupMember: { errorRes }, ChannelGroup: { group } }) => ({
    errorRes,
    group,
});

export default connect(mapStateToProps, dispatchToProps)(GroupChannelNewMembersContainer);
