type NotificationPage = {
    id: number;
    name: string;
    category: string;
    numFollowers: 3;
};

type NotificationStudent = {
    id: number;
    firstName: string;
    lastName: string;
};

type NotificationAudience = {
    id: number;
    name: string;
    numStudents: number;
    audienceType: string;
};

export interface Notification {
    id: number;
    status: string;
    type: string;
    numRecipients: number;
    audience: any;
    date: string;
}

export interface StandardNotification extends Notification {
    audience: {
        pages?: NotificationPage[];
        students?: NotificationStudent[];
        audiences?: NotificationAudience[];
        "students-broadcast": boolean;
    };
    message: string;
    subject: string;
    scheduledAt: string;
    startAt: string;
    finishedAt: string;
    link: any;
    linkObj: any;
    deeplinkType: string;
    sendAs: string;
}

export interface TimetableNotification extends Notification {
    audience: {
        lseModules: any[];
    };
    message: string;
    subject: string;
    scheduledAt: string;
    startAt: string;
    finishedAt: string;
    sendAs: string;
    template: any;
    additionalEmailText: string;
    additionalEmail: string[];
    mobileMessage: string;
    module?: any;
    group?: any;
}

export enum MobileDeeplinkType {
    PAGE = "page",
    POST = "post",
    EVENT = "event",
}

export enum MessageType {
    EMAIL = "email",
    EMAIL_AND_MOBILE = "mobile-email",
    MOBILE = "mobile",
}

export enum NotificationDelivery {
    DRAFT = "draft",
    IMMEDIATELLY = "sending",
    SCHEDULE = "scheduled",
    SENT = "sent",
}
