import { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";

import EventForm from "./AddEvent/Form";
import { default as CalendarEventsActions } from "../../../store/CalendarAndEvents/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";

import formsCss from "../../../common/styles/forms.css";
import CalendarEvent from "../../../fixtures/CalendarEvent";
import PromptDialog from "../../../common/components/PromptDialog";
import { GetFormattedDate } from "../../../common/components/Time";

class AddCalendarEventContainer extends Component {
    state = {
        confirmDialogOpen: false,
        values: {},
    };

    componentDidMount() {
        const { setTitle, clearPages, clearAudiences, clearStudentUsers } = this.props;
        setTitle("Calendar & Events \u203A Add Key Academic Date");
        clearPages();
        clearAudiences();
        clearStudentUsers();
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    handleClose = confirmed => {
        const { values } = this.state;
        this.setState({
            confirmDialogOpen: false,
            values: {},
        });

        if (confirmed) {
            this.props.postEvent({
                ...values,
                startAt: values.isAllDay
                    ? GetFormattedDate(values.startAt, "YYYY-MM-DD")
                    : values.startAt,
                endAt: values.isAllDay
                    ? GetFormattedDate(values.endAt, "YYYY-MM-DD")
                    : values.endAt,
            });
        }
    };

    onSubmit = values => {
        this.setState({
            confirmDialogOpen: true,
            values: values,
        });
    };

    render() {
        const {
            changeStartAt,
            changeEndAt,
            getStudentUsers,
            getPages,
            getAudiences,
            formState,
            updateAudience,
            students,
            audiences,
            pages,
            errorRes: { error, errors },
        } = this.props;

        return (
            <div>
                {error && <div css={formsCss.genericError}>{error}</div>}
                <EventForm
                    initialValues={CalendarEvent}
                    onSubmit={this.onSubmit}
                    {...{
                        changeEndAt,
                        changeStartAt,
                        getStudentUsers,
                        getPages,
                        getAudiences,
                        updateAudience,
                        formState,
                        audiences,
                        students,
                        pages,
                        errors,
                    }}
                />
                <PromptDialog
                    open={this.state.confirmDialogOpen}
                    handleClose={this.handleClose}
                    confirmLabel="Yes, Submit"
                >
                    Are you sure you want to add this event? You won&apos;t be able to modify it in
                    the future
                </PromptDialog>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getStudentUsers: AutoCompleteActionCreators.getStudentUsers.create,
    clearStudentUsers: AutoCompleteActionCreators.clearStudentUsers.create,
    getPages: AutoCompleteActionCreators.getPages.create,
    clearPages: AutoCompleteActionCreators.clearPages.create,
    getAudiences: AutoCompleteActionCreators.getAudiences.create,
    clearAudiences: AutoCompleteActionCreators.clearAudiences.create,
    postEvent: CalendarEventsActions.postEvent.create,
    changeEndAt: newValue => change("NewCalendarEvent", "endAt", newValue), // reset endAt value
    changeStartAt: newValue => change("NewCalendarEvent", "startAt", newValue), // reset startAt value
    updateAudience: (key, audience) => change("NewCalendarEvent", "audience." + key, audience),
};

const mapStateToProps = ({ AutoComplete, CalendarEvents, form }) => ({
    errorRes: CalendarEvents.errorRes,
    students: AutoComplete.students.map(user =>
        Object.assign({ fullName: `${user.firstName} ${user.lastName}` }, user)
    ),
    pages: AutoComplete.pages,
    audiences: AutoComplete.audiences,
    formState: form.NewCalendarEvent,
});

export default connect(mapStateToProps, dispatchToProps)(AddCalendarEventContainer);
