/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useFormContext, Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import React, { FC, forwardRef } from "react";
import { FormControlLabelProps } from "@material-ui/core/FormControlLabel";
import formsCss from "src/common/styles/forms.css";

interface RHFCheckboxProps extends Omit<FormControlLabelProps, "control"> {
    name: string;
    errorRes?: string;
}

export const RHFCheckbox: FC<RHFCheckboxProps> = forwardRef(
    ({ name, disabled, errorRes, ...other }, ref) => {
        const { control } = useFormContext();
        return (
            <FormControlLabel
                control={
                    <Controller
                        name={name}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            return (
                                <div>
                                    <Checkbox
                                        {...field}
                                        disabled={disabled}
                                        checked={field.value}
                                    />
                                    {(error || errorRes) && (
                                        <div css={formsCss.genericError}>
                                            {error && error.message
                                                ? error.message
                                                : errorRes
                                                ? errorRes
                                                : ""}
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    />
                }
                label={other.label}
                inputRef={ref}
            />
        );
    }
);

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, "control" | "label"> {
    name: string;
    options: string[];
    getOptionLabel?: any;
    label?: string;
    errorRes?: string;
    handleControlledSelection?: any;
}

export function RHFMultiCheckbox({
    name,
    label,
    options,
    getOptionLabel,
    errorRes,
    handleControlledSelection,
    ...other
}: RHFMultiCheckboxProps) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                const onSelected = (option: string) =>
                    field.value && option && field.value.includes(option)
                        ? field.value.filter((value: string) => value !== option)
                        : [...field.value, option];

                return (
                    <FormGroup>
                        <label css={formsCss.labelBig}>{label}</label>
                        {options.map((option, index) => (
                            <FormControlLabel
                                key={option}
                                control={
                                    <Checkbox
                                        checked={
                                            field.value && option
                                                ? field.value.includes(option)
                                                : false
                                        }
                                        onChange={() => {
                                            if (handleControlledSelection)
                                                handleControlledSelection(option);
                                            else field.onChange(onSelected(option));
                                        }}
                                    />
                                }
                                label={getOptionLabel?.length ? getOptionLabel[index] : option}
                                {...other}
                            />
                        ))}
                        {(error || errorRes) && (
                            <div css={formsCss.genericError}>
                                {error && error.message ? error.message : errorRes ? errorRes : ""}
                            </div>
                        )}
                    </FormGroup>
                );
            }}
        />
    );
}
