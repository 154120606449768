import { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./Form/";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import actionCreators from "../../../store/Channels/Groups/actionCreators";
import ChannelGroupValues from "../../../fixtures/ChannelGroup";
import formsCss from "../../../common/styles/forms.css";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";
import * as config from "./values";

class NewGroupChannelContainer extends Component {
    componentDidMount() {
        const { setTitle } = this.props;
        setTitle("Channels \u203A Groups");
    }

    componentWillUnmount() {
        const { clearTitle } = this.props;
        clearTitle();
    }

    onSubmit = values => {
        this.props.postGroup(values);
    };

    render() {
        const {
            initialValues,
            history: { push },
            owners,
            audiences,
            currentUser,
            errorRes: { error, errors },
            updateOwners,
            getAudiences,
            getOwners,
        } = this.props;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.NEW;

        return (
            <div>
                <div css={formsCss.genericError}>{error}</div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Add New Group</h2>
                        <Form
                            {...{
                                initialValues: {
                                    ...initialValues,
                                    groupType: config.GROUP_TYPE_OPEN,
                                    groupManagedBy: config.MANAGED_BY_OWNERS,
                                    owners: [
                                        {
                                            firstName: currentUser.firstName,
                                            id: currentUser.id,
                                            lastName: currentUser.lastName,
                                        },
                                    ],
                                },
                                onSubmit,
                                currentFormType,
                                push,
                                owners,
                                audiences,
                                errors,
                                getAudiences,
                                getOwners,
                                updateOwners,
                            }}
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOwners: AutoCompleteActionCreators.getAllUsers.create,
    getAudiences: AutoCompleteActionCreators.getAudiences.create,
    postGroup: actionCreators.postGroup.create,
    updateOwners: newValue => change("ChannelGroup", "owners", newValue), // change owners
};
const mapStateToProps = ({ ChannelGroup, Auth, AutoComplete }) => ({
    initialValues: ChannelGroupValues,
    errorRes: ChannelGroup.errorRes,
    owners: AutoComplete.users.map(owner =>
        Object.assign({ fullName: `${owner.firstName} ${owner.lastName}` }, owner)
    ),
    audiences: AutoComplete.audiences,
    currentUser: {
        firstName: Auth.firstName,
        lastName: Auth.lastName,
        id: Auth.id,
    },
});
export default connect(mapStateToProps, dispatchToProps)(NewGroupChannelContainer);
