import ActionCreator from "../../actionCreator";

export default {
    getListOfficeHoursAppointments: ActionCreator("GET_LIST_OFFICE_HOURS_APPOINTMENTS"),
    getListPastOfficeHoursAppointments: ActionCreator("GET_LIST_PAST_OFFICE_HOURS_APPOINTMENTS"),
    createListOfficeHoursAppointments: ActionCreator("CREATE_LIST_OFFICE_HOURS_APPOINTMENTS"),
    loadMoreOfficeHoursAppointments: ActionCreator("LOAD_MORE_OFFICE_HOURS_APPOINTMENTS"),
    loadMorePastOfficeHoursAppointments: ActionCreator("LOAD_MORE_PAST_OFFICE_HOURS_APPOINTMENTS"),
    updateListOfficeHoursAppointments: ActionCreator("UPDATE_LIST_OFFICE_HOURS_APPOINTMENTS"),

    getOneOfficeHoursAppointmentDetails: ActionCreator("GET_ONE_OFFICE_HOURS_APPOINTMENT_DETAILS"),
    createOneOfficeHoursAppointmentDetails: ActionCreator(
        "CREATE_ONE_OFFICE_HOURS_APPOINTMENT_DETAILS"
    ),
    getOneOfficeHoursAppointmentSession: ActionCreator("GET_ONE_OFFICE_HOURS_APPOINTMENT_SESSION"),
    createOneOfficeHoursAppointmentSession: ActionCreator(
        "CREATE_ONE_OFFICE_HOURS_APPOINTMENT_SESSION"
    ),
    clearOneOfficeHoursAppointmentDetails: ActionCreator(
        "CLEAR_ONE_OFFICE_HOURS_APPOINTMENT_DETAILS"
    ),

    getListOfficeHoursAppointmentHistory: ActionCreator(
        "GET_LIST_OFFICE_HOURS_APPOINTMENT_HISTORY"
    ),
    createListOfficeHoursAppointmentHistory: ActionCreator(
        "CREATE_LIST_OFFICE_HOURS_APPOINTMENT_HISTORY"
    ),
    loadMoreOfficeHoursAppointmentHistory: ActionCreator(
        "LOAD_MORE_OFFICE_HOURS_APPOINTMENT_HISTORY"
    ),
    updateListOfficeHoursAppointmentHistory: ActionCreator(
        "UPDATE_LIST_OFFICE_HOURS_APPOINTMENT_HISTORY"
    ),
    clearOfficeHoursAppointmentHistory: ActionCreator("CLEAR_OFFICE_HOURS_APPOINTMENT_HISTORY"),
    getSyncDetails: ActionCreator("GET_SYNC_DETAILS"),
    updateSyncDetails: ActionCreator("UPDATE_SYNC_DETAILS"),
    gettOfficeHoursSchoolYearsAndTerms: ActionCreator("GET_OFFICE_HOURS_SCHOOL_YEARS_AND_TERMS"),
    createOfficeHoursSchoolYearsAndTerms: ActionCreator(
        "CREATE_OFFICE_HOURS_SCHOOL_YEARS_AND_TERMS"
    ),
    downloadOfficeHoursAppointmentsHistoryReport: ActionCreator(
        "DOWNLOAD_OFFICE_HOURS_SCHOOL_APPOINTMENTS_HISTORY_REPORT"
    ),
    cancelOfficeHoursAppointment: ActionCreator("CANCEL_OFFICE_HOURS_APPOINTMENT"),
    pushListAppointments: ActionCreator("PUSH_LIST_OFFICE_HOURS_APPOINTMENTS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_OFFICE_HOURS_APPOINTMENTS"),
    markOfficeHoursAppointmentAsStudentAttended: ActionCreator(
        "MARK_OFFICE_HOURS_APPOINTMENT_AS_ATTENDED"
    ),
    markOfficeHoursAppointmentAsStudentNotAttended: ActionCreator(
        "MARK_OFFICE_HOURS_APPOINTMENT_AS_NOT_ATTENDED"
    ),
    updateOfficeHoursAppointmentStudentAttendance: ActionCreator(
        "UPDATE_OFFICE_HOURS_APPOINTMENT_ATTENDANCE"
    ),
    putOfficeHoursAppointmentAttendanceComment: ActionCreator(
        "PUT_OFFICE_HOURS_APPOINTMENT_ATTENDANCE_COMMENT"
    ),
    updateOfficeHoursAppointmentAttendanceComment: ActionCreator(
        "UPDATE_OFFICE_HOURS_APPOINTMENT_ATTENDANCE_COMMENT"
    ),
};
