import { colors } from "../../UIGlobals";

export const mainBox = {
    wrap: {
        position: "relative",
        backgroundColor: colors.darkSecondary,
        width: 360,
        minHeight: 128,
        borderBottomWidth: 8,
        borderBottomStyle: "solid",
        borderBottomColor: "#30c0ad",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        marginBottom: 32,
        "@media (max-width: 768px)": {
            width: "100%",
            marginBottom: 16,
        },
    },
    wrapDisabled: {
        pointerEvent: "none",
        opacity: 0.4,
    },
    inner: {
        paddingLeft: 16,
        paddingBottom: 21,
    },
    topShape: {
        position: "absolute",
        top: 0,
        display: "block",
        left: 0,
        right: 0,
        // width: "100%",
        borderBottomWidth: 8,
        borderBottomStyle: "solid",
        borderBottomColor: "transparent",
        borderRightWidth: 360,
        borderRightStyle: "solid",
        borderRightColor: "white",
        height: 0,
        width: 0,
    },
    title: {
        fontSize: 24,
        lineHeight: 1,
        color: colors.white,
        fontWeight: 400,
    },
    link: {
        fontSize: 16,
        textDecoration: "none",
        color: colors.secondary,
        display: "flex",
        alignItems: "center",
    },
    icon: {
        marginRight: 8,
    },
    linkText: {
        fontSize: 16,
        textDecoration: "none",
        color: colors.secondary,
    },
};
