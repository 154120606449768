export default {
    id: null,
    name: "",
    description: "",
    imageFile: {
        name: "",
        size: "",
        type: "",
        base64: "",
    },
    imageUrl: null,
    iconFile: {
        name: "",
        size: "",
        type: "",
        base64: "",
    },
    iconUrl: null,
    owners: [],
    hasActivePosts: false,
    groupManagedBy: null,
    groupType: null,
    lastActiveDate: null,
};
