import actionCreators from "./actionCreators";
import team from "../../../fixtures/OfficeHoursTeam";

export const initialState = {
    teams: [],
    team,
    errorRes: { error: "", errors: {} },
    currentFormType: null,
    slotsForCancellation: null,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListOfficeHoursTeams.type:
            return {
                ...state,
                teams: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneOfficeHoursTeam.type:
            return {
                ...state,
                team: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearOfficeHoursTeam.type:
            return {
                ...state,
                team: initialState.team,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.createTeamSlotsForCancellation.type:
            return {
                ...state,
                slotsForCancellation: payload,
            };
        case actionCreators.clearTeamSlotsForCancellation.type:
        case actionCreators.getTeamSlotsForCancellation.type:
            return {
                ...state,
                slotsForCancellation: initialState.slotsForCancellation,
            };
        default:
            return state;
    }
};
