/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { FC, forwardRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import formsCss from "src/common/styles/forms.css";
import { useFormContext, Controller } from "react-hook-form";
import { MenuItem } from "@material-ui/core";

interface OwnProps {
    name: string;
    placeholder?: string;
    label?: string;
    classes: any;
    errorRes?: string;
    options: { value: any; label: string }[];
    multiple?: boolean;
    onChange?: any;
}

const RHFSelect: FC<OwnProps & TextFieldProps> = forwardRef(
    (
        { name, placeholder, label, classes, errorRes, options, multiple, onChange, ...other },
        ref
    ) => {
        const { control } = useFormContext();
        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <TextField
                            select
                            fullWidth
                            margin="normal"
                            color="primary"
                            error={!!error || !!errorRes}
                            helperText={
                                error && error.message ? error.message : errorRes ? errorRes : ""
                            }
                            placeholder={placeholder ? placeholder : label ? label : ""}
                            label={label}
                            {...other}
                            {...field}
                            onChange={e => {
                                field.onChange(e);
                                if (onChange) onChange(e.target.value);
                            }}
                            value={field.value || ""}
                            inputRef={ref}
                            InputProps={{
                                classes: {
                                    root: classes.inputRoot,
                                    error: classes.inputError,
                                },
                            }}
                            SelectProps={{
                                MenuProps: {
                                    MenuListProps: {
                                        disablePadding: true,
                                        dense: true,
                                    },
                                },
                            }}
                        >
                            {options &&
                                options.map((o, index) => (
                                    <MenuItem key={`${index}-${o}`} value={o.value}>
                                        {o.label}
                                    </MenuItem>
                                ))}
                        </TextField>
                    );
                }}
            />
        );
    }
);

const RenderTextField = withStyles(formsCss)(RHFSelect);

export default RenderTextField;
