import { Component } from "react";
import Downshift from "downshift";
import { Chip, withStyles } from "@material-ui/core";
import { styles } from "./styles";
import globalsCss from "../../styles/globals.css";
import formsCss from "../../styles/forms.css";
import { RenderInput } from "./helpers";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class ItemListDownshift extends Component {
    state = { inputValue: "", error: false };

    handleInputChange = event => {
        this.setState({ inputValue: event.target.value });
    };

    handleAddItem = item => {
        const { validateItem, selectedItems, onChange } = this.props;
        if (validateItem && !validateItem(item)) {
            this.setState({ error: true });
        } else {
            this.setState({ inputValue: "", error: false }, () => {
                if (!selectedItems.includes(item)) {
                    onChange([...selectedItems, item]);
                }
            });
        }
    };

    handleDelete = item => () => {
        const { selectedItems, onChange } = this.props;
        onChange(selectedItems.filter(obj => obj !== item));
    };

    render() {
        const {
            label,
            placeholder,
            selectedItems,
            classes,
            onBlur,
            validateError = "Invalid item",
        } = this.props;

        return (
            <Downshift inputValue={this.state.inputValue} onChange={this.handleChange}>
                {({ getInputProps }) => (
                    <div className={classes.container}>
                        <RenderInput
                            fullWidth={true}
                            autoComplete="off"
                            label={label}
                            placeholder={placeholder}
                            margin="normal"
                            InputProps={{
                                ...getInputProps({
                                    onChange: this.handleInputChange,
                                    onBlur,
                                }),
                                onKeyDown: event => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        this.handleAddItem(event.target.value);
                                        return false;
                                    }
                                    return true;
                                },
                            }}
                        />
                        {this.state.error && <span css={formsCss.errorSmall}>{validateError}</span>}
                        {selectedItems.length > 0 && (
                            <div css={globalsCss.chipsContainer}>
                                {selectedItems.map(item => (
                                    <Chip
                                        key={item}
                                        css={globalsCss.chipsItem}
                                        label={item}
                                        onDelete={this.handleDelete(item)}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </Downshift>
        );
    }
}

export default withStyles(styles)(ItemListDownshift);
