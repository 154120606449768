import { colors, gradients } from "./colors";
import { spacing } from "./spacing";
import { fontSize, fontWeight, lineHeight, fontFamily } from "./typography";
import channelShapeDefault from "../../common/images/channel-shape-default.svg";
import channelShape from "../../common/images/channel-shape.svg";

export default {
    body: {
        maxWidth: 560,
        margin: "35px auto 0",

        "@media (max-width: 767px)": {
            margin: "15px 0 0",
        },
    },
    paper: {
        backgroundColor: colors.white,
        marginTop: spacing.space2,
        marginBottom: spacing.space2,
        boxShadow: "0 0 2px 0 rgba(0,0,0,0.2)",
        borderRadius: spacing.space0,
        overflow: "hidden",
        position: "relative",
    },
    inner: {
        padding: spacing.space2,
    },
    status: {},
    publicationDate: {
        margin: "8px 0 0",
    },
    mainImg: {
        position: "relative",
        display: "block",
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        padding: 0,
        margin: 0,
        // margin: "0 0 24px",
        // "@media (max-width: 767px)": {
        //     margin: "-25px -15px 24px",
        // },
        imgShape: {
            fontSize: 1,
            lineHeight: 1,
            height: 8,
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            zIndex: 10,
        },
        img: {
            width: "100%",
            height: "auto",
            margin: 0,
            display: "block",
        },
    },
    text: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        lineHeight: lineHeight.text,
        padding: 0,
    },
    title: {
        fontSize: fontSize.text + 1,
        fontWeight: fontWeight.medium,
        lineHeight: "1.17",
        color: colors.black87,
        marginBottom: spacing.space2,
    },
    details: {
        marginBottom: spacing.space2,
    },
    detailsLocation: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: "1.42",
    },
    detailsDate: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        lineHeight: "1.42",
    },
    link: {
        color: colors.black,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        textDecoration: "none",
    },
    actions: {
        marginBottom: spacing.space3,
    },
    actionsInner: {
        display: "flex",
        // marginRight: spacing.space0 * -1,
        // marginLeft: spacing.space0 * -1,
    },
    actionsReadMore: {
        marginTop: spacing.space2,
    },
    btn: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: 1,
        letterSpacing: "0.4px",
        color: colors.black87,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 32,
        borderRadius: spacing.space0,
        paddingTop: 8,
        paddingRight: 16,
        paddingBottom: 8,
        paddingLeft: 16,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.2)",
        borderStyle: "solid",
        textDecoration: "none",
        outline: "none",
    },
    btnAccent: {
        backgroundColor: "transparent",
        borderWidth: 0,
        borderColor: "transparent",
        borderStyle: "none",
        color: colors.accentA,
        paddingRight: 0,
        paddingLeft: 0,
    },
    btnText: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: 1,
        letterSpacing: "0.4px",
        color: colors.black87,
    },
    btnIcon: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 14,
        lineHeight: "1",
        width: 14,
        height: 14,
        marginRight: spacing.space1,
    },
    btnIconAccent: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 14,
        lineHeight: "1",
        width: 14,
        height: 14,
        marginRight: spacing.space1,
        color: colors.accentA,
    },
    btnGray: {
        backgroundColor: colors.black10,
        // border: 0,
        borderWidth: 0,
        borderColor: "transparent",
        borderStyle: "none",
    },
    btnIconGray: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 14,
        lineHeight: "1",
        width: 14,
        height: 14,
        marginRight: spacing.space1,
        color: colors.black87,
    },
    btnComments: {
        display: "inline-flex",
        color: colors.black54,
        backgroundColor: "transparent",
        border: "0 none",
        outline: "0 none",
        margin: 0,
        padding: 0,
        fontFamily: fontFamily.primary,
    },
    btnCommentsText: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        paddingTop: spacing.space0comma5,
        paddingLeft: spacing.space1,
    },
    summary: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: spacing.space2,
    },
    eventSummary: {
        display: "flex",
        justifyContent: "space-between",
    },
    previewButton: {
        display: "block",
        marginTop: spacing.space2,
    },
    favoriteIcon: {},
    commentIcon: {
        color: colors.black54,
    },
};

export const channelCover = {
    body: {
        display: "flex",
        flexDirection: "column",
        // alignItems: "flex-end",
        justifyContent: "center",
        backgroundColor: "#34CCB8",
        backgroundPosition: "50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        minHeight: 250,
    },
    bodyBig: {
        display: "flex",
        flexDirection: "column",
        // alignItems: "flex-end",
        justifyContent: "center",
        backgroundColor: "#34CCB8",
        backgroundPosition: "50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        minHeight: 300,
    },
    inner: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "100%",
        paddingTop: spacing.space9,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        position: "relative",
        zIndex: 10,
    },
    shape: {
        backgroundPosition: "0 0",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 176px",
        width: "100%",
        height: 176,
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 8,
    },
    shapeDefault: {
        backgroundImage: `url(${channelShapeDefault})`,
    },
    shapeImg: {
        backgroundImage: `url(${channelShape})`,
    },
    gradient: {
        // display: "none",
        backgroundImage: gradients.black,
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 5,
    },
    actions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: 36,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        position: "absolute",
        top: spacing.space2,
        right: 0,
        left: 0,
        zIndex: 20,
    },
    avatar: {
        marginBottom: spacing.space2comma5,
    },
    title: {
        color: colors.white,
        fontSize: 20,
        fontWeight: fontWeight.black,
        lineHeight: 1.2,
        paddingRight: "50%",
        "@media (max-width: 1023px)": {
            paddingRight: 0,
        },
    },
    numFollowers: {
        display: "flex",
        alignItems: "center",
        height: 48,
        color: colors.white,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.bold,
        marginRight: spacing.space2,
        marginLeft: spacing.space2,
        position: "relative",
        zIndex: 15,
    },
};

export const channelFilterBar = {
    body: {
        display: "flex",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        height: 56,
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
    },
    btn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "inherit",
        flex: 1,
        cursor: "pointer",
        background: "none",
        border: "0 none",
        outline: "0 none",
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: "1",
        textDecoration: "none",
        margin: 0,
        padding: "0 30px",
        position: "relative",
        "@media (max-width: 767px)": {
            padding: "0 10px",
        },
    },
    btnBorder: {
        fontSize: 1,
        lineHeight: 1,
        opacity: 0,
        width: "100%",
        height: 4,
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
        "@media (max-width: 1023px)": {
            // backgroundColor: "transparent",2
        },
    },
    btnActive: {
        fontWeight: fontWeight.bold,
    },
    btnBorderActive: {
        opacity: 1,
    },
};

export const channelInfo = {
    body: {
        paddingTop: spacing.space1comma5,
        paddingBottom: spacing.space1comma5,
        backgroundColor: colors.white,
    },
    btn: {
        display: "flex",
        alignItems: "center",
        paddingTop: spacing.space1,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space2,
    },
    btnText: {
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.h4,
        fontWeight: fontWeight.medium,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    btnIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 24,
        width: 24,
        lineHeight: 1,
        marginRight: spacing.space1,
    },
    text: {
        padding: spacing.space2,
    },
    disclaimer: {
        color: colors.black54,
        fontSize: fontSize.textSmaller,
        fontStyle: "italic",
        padding: spacing.space2,
        borderTop: `1px solid ${colors.black5}`,
    },
    disclaimerLink: {
        color: colors.black54,
        textDecoration: "underline",
    },
};
