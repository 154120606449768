import ActionCreator from "../../../actionCreator";

export default {
    getListMembers: ActionCreator("CHANNEL_GROUP_GET_LIST_MEMBERS"),
    createListMembers: ActionCreator("CHANNEL_GROUP_CREATE_LIST_MEMBERS"),
    loadMoreMembers: ActionCreator("CHANNEL_GROUP_LOAD_MORE_MEMBERS"),
    updateListMembers: ActionCreator("CHANNEL_GROUP_UPDATE_LIST_MEMBERS"),
    clearListMembers: ActionCreator("CHANNEL_GROUP_CLEAR_LIST_MEMBERS"),
    postGroupMembers: ActionCreator("CHANNEL_GROUP_POST_MEMBERS"),
    removeGroupMember: ActionCreator("CHANNEL_GROUP_REMOVE_MEMBER"),
    changeMemberRole: ActionCreator("CHANNEL_GROUP_CHANGE_MEMBER_ROLE"),
    updatePendingMember: ActionCreator("CHANNEL_GROUP_UPDATE_PENDING_MEMBER"),
    redirectToMembersList: ActionCreator("CHANNEL_GROUP_REDIRECT_TO_MEMBERS_LIST"),
    errorResponse: ActionCreator("CHANNEL_GROUP_ERROR_RESPONSE_MEMBER"),
};
