import { colors } from "./colors";
import { spacing } from "./spacing";
import { fontWeight } from "./typography";
import * as CSS from "csstype";
import { StyleRules } from "@material-ui/core/styles";

const style: StyleRules<string> = {
    item: {
        marginTop: 16,
        marginBottom: 8,
    },
    itemLabel: {
        fontSize: 12,
        color: colors.black50,
        marginBottom: 4,
    },
    itemValue: {
        fontSize: "1rem",
    },
    label: {
        display: "block",
        marginBottom: 15,
    },
    labelBig: {
        display: "block",
        marginTop: 20,
        // marginBottom: -10,
        fontWeight: fontWeight.medium as CSS.FontWeightProperty,
    },
    labelBigBottomMargin: {
        marginBottom: 16,
    },
    labelNoMargin: {
        display: "block",
        fontWeight: fontWeight.medium as CSS.FontWeightProperty,
    },
    labelSmall: {
        display: "block",
        marginBottom: 4,
    },
    labelDateTime: {
        display: "block",
        marginTop: 8,
        marginBottom: 8,
    },
    labelTitle: {
        display: "block",
    },
    labelHelp: {
        fontSize: "80%",
        opacity: ".6",
    },
    labelSecondary: {
        fontSize: "100%",
        opacity: ".5",
    },
    labelDesc: {
        display: "block",
        fontSize: "80%",
        opacity: ".6",
        lineHeight: "18px",
        marginTop: spacing.space0,
    },
    labelScheduled: {
        display: "none",
        opacity: ".5",

        "@media (max-width: 767px)": {
            display: "block",
        },
    },
    labelRadioGroup: {
        color: "rgba(0,0,0,.87) !important",
        lineHeight: 1.5,
    },
    fullWidthFlex: {
        flex: 1,
    },
    field: {
        marginTop: spacing.space2,
        marginBottom: spacing.space1,
    },
    inline: {
        display: "flex",
        alignItems: "center",
    },
    inlineAlignTop: {
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
    },
    inlineAlignTopColumn: {
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        flexDirection: "column",
    },
    inlineAlignTopWrap: {
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
    },
    inlineAlignBottom: {
        display: "flex",
        alignItems: "flex-end",
    },
    inlineItem: {
        marginRight: spacing.space1,
    },
    inlineItemDoubleGap: {
        marginRight: spacing.space3,
    },
    inlineItemField: {
        transform: "translateY(-4px)",
    },
    scheduledAt: {
        width: 235,
        marginLeft: 279,

        "@media (max-width: 767px)": {
            marginLeft: 0,
            marginTop: 25,
        },
    },
    uploadImage: {
        marginTop: 25,
    },
    uploadImagePlaceholder: {
        marginTop: 25,
        minWidth: 50,
        maxWidth: 250,
        height: "auto",
        padding: 10,
        border: "dashed 1px rgb(224, 224, 224)",
        width: "auto",
        display: "table",
    },
    uploadImageImg: {
        maxWidth: "100%",
        display: "block",
    },
    fileLabel: {
        height: 36,
        display: "flex",
        alignItems: "center",
        marginTop: 5,
    },
    fileLabelText: {
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    hiddenInputFile: {
        display: "none",
    },
    actions: {
        borderTop: "1px solid #efefef",
        paddingTop: 35,
        paddingRight: 35,
        paddingLeft: 35,
        marginTop: 35,
        marginRight: -35,
        marginLeft: -35,
        "@media (max-width: 767px)": {
            paddingTop: 25,
            paddingRight: 15,
            paddingLeft: 15,
            marginTop: 25,
            marginRight: -15,
            marginLeft: -15,
        },
    },
    actionsSmall: {
        borderTop: "1px solid #efefef",
        paddingTop: 10,
        paddingRight: 28,
        paddingLeft: 28,
        marginTop: 35,
        marginRight: -35,
        marginLeft: -35,
        "@media (max-width: 767px)": {
            paddingTop: 10,
            paddingRight: 8,
            paddingLeft: 8,
            marginTop: 25,
            marginRight: -15,
            marginLeft: -15,
        },
    },
    actionsAdd: {
        marginTop: 12,
        marginBottom: 6,
    },
    extraAction: {
        marginTop: 20,
    },
    inlineBtn: {
        paddingTop: 28,
    },
    btnSubmit: {
        marginRight: 5,
    },
    btnBack: {
        marginRight: 5,
    },
    btnIcon: {
        marginRight: spacing.space1,
    },
    btnFullWidth: {
        width: "100%",
    },
    btnLabel: {
        display: "inline-flex",
    },
    genericError: {
        position: "relative",
        top: 5,
        fontSize: 16,
        color: "#E0112B",
        transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
        display: "block",
        fontWeight: fontWeight.medium as CSS.FontWeightProperty,
        marginBottom: 32,
        marginTop: 2,
    },
    genericErrorMarginTop: {
        marginTop: 24,
    },
    genericErrorMarginNone: {
        margin: 0,
    },
    error: {
        position: "relative",
        top: 5,
        fontSize: 12,
        lineHeight: 1.3,
        color: "#E0112B",
        transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
        display: "block",
        marginBottom: 32,
    },
    errorSmall: {
        display: "block",
        color: "#E0112B",
        margin: 0,
        fontSize: "0.75rem",
        textAlign: "left",
        minMeight: "1em",
        lineHeight: "1em",
    },
    errorSmallMT: {
        margin: "8px 0 0",
    },
    flatButton: {
        border: "solid 1px rgb(224, 224, 224)",
        display: "block",
        marginTop: 20,
    },
    ownersLabel: {
        display: "block",
        marginTop: 20,
        marginBottom: -10,
    },
    userDetails: {
        marginBottom: 24,
        background: "rgba(240, 240, 240, 1)",
        padding: 10,
        border: "dashed 1px rgb(204, 204, 204)",
    },
    blockedRadioButtons: {
        marginTop: 20,
    },
    firstInputField: {
        marginTop: -20,
    },
    inputRoot: {
        "&:before": {
            borderBottom: "2px solid #949494",
        },
        "&:hover:before": {
            borderBottomColor: "#808080 !important",
        },
        "&:after": {
            borderBottomColor: "#E0112B",
        },
    },
    inputError: {
        "&:after": {
            borderBottom: `2px solid #E0112B !important`,
        },
        "&:before": {
            borderBottom: `2px solid #E0112B !important`,
        },
    },
    labelRoot: {
        color: "rgba(0, 0, 0, 0.5) !important",
    },
    labelFocused: {
        color: "rgba(0, 0, 0, 0.5) !important",
        // color: "#E0112B !important",
    },
    labelError: {
        // color: "blue !important",
        color: "rgba(0, 0, 0, 0.5) !important",
    },
    buttonPDF: {
        marginLeft: 10,
        verticalAlign: "top",
    },
    buttonTextExtraAction: {
        border: "solid 1px rgb(224, 224, 224)",
        marginRight: 8,
    },
    formControlRoot: {
        alignItems: "flex-start",
        marginRight: 0,
    },
    radioLabel: {
        fontSize: 16,
        paddingTop: 12,
        paddingBottom: 12,
    },
    checkboxBox: {
        backgroundColor: "#f9f9f9",
        marginTop: 16,
        paddingTop: 8,
        paddingRight: 20,
        paddingBottom: 8,
        paddingLeft: 20,
    },
    checkboxRoot: {
        color: "rgb(66, 66, 66) !important",
    },
    checkboxChecked: {
        color: "#E0112B !important",
    },
    checkboxIndent: {
        paddingLeft: 34,
    },
    checkboxLabel: {
        fontSize: 16,
        color: "rgba(33, 33, 33, 0.6)",
    },
    selectRoot: {
        padding: 0,
        width: 250,
        "@media (max-width: 767px)": {
            width: "100%",
        },
    },
    selectSelect: {
        paddingTop: 18,
        paddingRight: 40,
        paddingBottom: 19,
        width: "calc(100% - 40px)",
        "&:focus": {
            backgroundColor: "transparent",
        },
    },
    timepicker: {
        display: "block",
    },
    timepickerBody: {
        display: "flex",
        width: 192,
    },
    timepickerSeparator: {
        display: "inline-flex",
        alignSelf: "flex-end",
        marginTop: 16,
        marginBottom: 8,
        paddingTop: 3,
        paddingRight: 6,
        paddingBottom: 5,
        paddingLeft: 6,
    },
    timepickerTimeAmPm: {
        marginLeft: 8,
    },
    timepickerError: {
        display: "block",
        color: "#E0112B",
        margin: 0,
        fontSize: "0.75rem",
        lineHeight: "1em",
    },
    autoCompleteFullWidth: {
        flex: 1,
        inputRoot: {
            "&:before": {
                borderBottom: "2px solid #949494",
            },
            "&:hover:before": {
                borderBottomColor: "#808080 !important",
            },
            "&:after": {
                borderBottomColor: "#E0112B",
            },
        },
        inputError: {
            "&:after": {
                borderBottom: `2px solid #E0112B !important`,
            },
            "&:before": {
                borderBottom: `2px solid #E0112B !important`,
            },
        },
    },
    time: {
        paddingTop: 36,
        "@media (max-width: 1023px)": {
            paddingTop: 10,
            width: "100%",
        },
    },
    note: {
        color: "rgba(0, 0, 0, 0.5) !important",
        fontSize: "0.85rem",
        fontStyle: "italic",
        marginTop: -5,
    },
    loading: {
        padding: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    errorBox: {
        color: "rgb(97, 26, 21)",
        backgroundColor: "rgb(253, 236, 234)",
        display: "flex",
        padding: "16px",
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.43,
    },
    errorBoxIcon: {
        width: 30,
        height: 30,
        marginTop: spacing.space0comma5,
        marginRight: spacing.space2,
    },
    errorBoxTitle: {
        fontSize: "1rem",
        fontWeight: "bold",
        paddingTop: spacing.space1,
        paddingBottom: spacing.space1,
    },
    dividerVertical: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        height: "100%",
        "&:before": {
            content: "''",
            backgroundColor: "#efefef",
            width: 1,
            position: "absolute",
            top: -10,
            bottom: -35,
        },
        "@media (max-width: 1023px)": {
            height: "auto",
            margin: "30px 0 40px",
            "&:before": {
                content: "''",
                backgroundColor: "#efefef",
                width: "100%",
                height: 1,
                top: "auto",
                bottom: "auto",
                left: -16,
                right: -16,
            },
        },
    },
    orBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        margin: "40px 0 24px",
        zIndex: 0,
        pointerEvents: "none",
        "& span": {
            fontSize: 12,
            color: colors.black42,
            backgroundColor: "#fff",
            padding: "0 48px",
            position: "relative",
            zIndex: 2,
        },
        "&:before": {
            content: "''",
            backgroundColor: colors.black10,
            height: 1,
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
            zIndex: 1,
        },
    },
    greyBox: {
        backgroundColor: "#f9f9f9",
        paddingTop: 8,
        paddingRight: 20,
        paddingBottom: 8,
        paddingLeft: 20,
    },
};

export default style;
