export default {
    uid: null,
    name: null,
    location: null,
    notes: null,
    startAt: null,
    endAt: null,
    isAllDay: false,
    calendarType: "key-academic-dates",
    audience: {
        pages: [],
        students: [],
        audiences: [],
    },
};
