import { Component } from "react";
import { reduxForm, Field } from "redux-form";
import validate from "./validate";
import {
    RenderTextField,
    RenderRadioGroup,
    RenderTextDescription,
    RenderRadio,
} from "../../../../common/components/FormFields";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";

import { Button, Paper } from "@material-ui/core";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import formsCss from "../../../../common/styles/forms.css";

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    updateSearchText(value) {
        const { searchText$ } = this.state;
        this.setState({ searchText: value }, () =>
            searchText$.next({ filter: this.state.searchText })
        );
    }

    onFilterChangeHandler = value => {
        this.updateSearchText(value);
    };

    render() {
        const { handleSubmit, push, currentFormType, errors } = this.props;
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>
                        {currentFormType === currentFormTypes.NEW
                            ? "Add New Category"
                            : "Edit Category"}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Field
                            name="status"
                            errorRes={errors.status}
                            component={RenderRadioGroup}
                            row
                        >
                            <RenderRadio
                                value="live"
                                label="live"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                            <RenderRadio
                                value="draft"
                                label="draft"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                        </Field>
                        <hr />
                        <div className="row">
                            <div className="col-xs-12">
                                <Field
                                    name="name"
                                    errorRes={errors.name}
                                    type="text"
                                    component={RenderTextField}
                                    label="Name"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <Field
                                    name="description"
                                    errorRes={errors.description}
                                    type="text"
                                    component={RenderTextDescription}
                                    fullWidth
                                    label="Description"
                                />
                            </div>
                        </div>

                        <div css={formsCss.actions}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                {currentFormType === currentFormTypes.NEW
                                    ? "Add New Category"
                                    : "Save Category"}
                            </Button>
                            <Button
                                onClick={() => push("/knowledge-base/categories/")}
                                css={formsCss.btnBack}
                            >
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}
export default reduxForm({
    form: "Category",
    enableReinitialize: true,
    validate,
})(Form);
