import { globalDictionary } from "../services/dictionary";

import tableCss from "../styles/table.css";

export const decorateTableItemsJobTypes = {
    TRANSLATE: "TRANSLATE",
    STYLE_ROW: "STYLE_ROW",
};

export const decorateTableHeader = (item, rules) => {
    Object.keys(rules).forEach(ruleIndex => {
        const rule = rules[ruleIndex];
        switch (rule.type) {
            case decorateTableItemsJobTypes.TRANSLATE: {
                const fullDict = Object.assign({}, globalDictionary, rule.payload);
                item = fullDict[item] ? fullDict[item] : item;
                break;
            }

            default:
                break;
        }
    });

    return item;
};

export const decorateTableRow = (item, rules) => {
    let res = null;
    Object.keys(rules).forEach(ruleIndex => {
        const rule = rules[ruleIndex];
        switch (rule.type) {
            case decorateTableItemsJobTypes.STYLE_ROW: {
                let elements = rule.payload;
                res = elements[item] ? elements[item] : tableCss.tableRow;
                break;
            }
            default:
                break;
        }
    });
    return res;
};
