/** @jsx jsx */
import { jsx } from "@emotion/core";
import moment from "moment-timezone";
import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import formsCss from "../../styles/forms.css";
import React, { useState } from "react";

// ---------------------------
// Standard Time Picker
// ---------------------------

const hoursValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minutesValues = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

const RenderTimepickerField = ({
    input,
    classes,
    meta: { touched, error },
    errorRes,
    hintText,
    ...rest
}) => {
    const forceUpdate = useState()[1].bind(null, {});

    const d = moment.tz("Europe/London").set({
        hour: 0,
        minute: 0,
        second: 0,
    });

    const now = moment(input.value ? input.value : d);
    const londonOffset = now.tz("Europe/London").utcOffset();
    const diff = londonOffset;

    const roundTo5 = value => (value % 5 !== 0 ? Math.floor(value / 5) * 5 : value);

    const setHours = value => {
        const offset =
            input.value !== null ? (now.tz("Europe/London").format("a") === "pm" ? 12 : 0) : 0;
        const savedDate = now.get("date");
        const savedMonth = now.get("month");
        const newValue = now
            .utc()
            .subtract(diff, "minutes")
            .set({
                hour: Number(value < 12 ? value : 0) + Number(offset) - diff / 60,
                date: savedDate,
                month: savedMonth,
            });
        input.onChange(newValue.set("minute", roundTo5(newValue.get("minute"))).format());
        forceUpdate();
    };

    const setMinutes = value => {
        input.onChange(
            now
                .utc()
                .set({
                    minute: value,
                })
                .format()
        );
        forceUpdate();
    };

    const setAmPm = value => {
        if (!(value === "am" && !input.value)) {
            if (!input.value) {
                setHours(0);
            }
            const newValue = now.utc().set({
                hour: now.utc().get("hour") + (value === "am" ? -12 : +12),
            });

            input.onChange(newValue.format());
            forceUpdate();
        }
    };

    const getHours = () => (input.value !== null ? now.tz("Europe/London").format("h") : 12);

    const getMinutes = () => (input.value !== null ? now.tz("Europe/London").format("m") : 0);

    const getAmPm = () => (input.value !== null ? now.tz("Europe/London").format("a") : "am");

    const errorText = touched && (error || errorRes);

    return (
        <div css={formsCss.timepicker}>
            <div css={formsCss.timepickerBody}>
                <TextField
                    fullWidth={false}
                    select
                    placeholder={"HH"}
                    margin="normal"
                    color="primary"
                    InputProps={{
                        classes: {
                            root: classes.inputRoot,
                            error: classes.inputError,
                        },
                    }}
                    SelectProps={{
                        MenuProps: {
                            MenuListProps: {
                                disablePadding: true,
                                dense: true,
                            },
                        },
                    }}
                    value={getHours()}
                    onChange={e => setHours(e.target.value)}
                    {...rest}
                >
                    {hoursValues.map(item => (
                        <MenuItem key={`m.${item}`} value={item}>
                            {item < 10 ? `0${item}` : item}
                        </MenuItem>
                    ))}
                </TextField>
                <div css={formsCss.timepickerSeparator}>{"."}</div>
                <TextField
                    fullWidth={false}
                    select
                    placeholder={"MM"}
                    margin="normal"
                    color="primary"
                    InputProps={{
                        classes: {
                            root: classes.inputRoot,
                            error: classes.inputError,
                        },
                    }}
                    SelectProps={{
                        MenuProps: {
                            MenuListProps: {
                                disablePadding: true,
                                dense: true,
                            },
                        },
                    }}
                    value={roundTo5(getMinutes())}
                    onChange={e => setMinutes(e.target.value)}
                    {...rest}
                    label={rest.label ? " " : null}
                >
                    {minutesValues.map(item => (
                        <MenuItem key={`m.${item}`} value={item}>
                            {item < 10 ? `0${item}` : item}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    fullWidth={false}
                    css={formsCss.timepickerTimeAmPm}
                    select
                    margin="normal"
                    color="primary"
                    InputProps={{
                        classes: {
                            root: classes.inputRoot,
                            error: classes.inputError,
                        },
                    }}
                    SelectProps={{
                        MenuProps: {
                            MenuListProps: {
                                disablePadding: true,
                                dense: true,
                            },
                        },
                    }}
                    value={getAmPm()}
                    onChange={e => setAmPm(e.target.value)}
                    {...rest}
                    label={rest.label ? " " : null}
                >
                    <MenuItem key={`am`} value={"am"}>
                        {"am"}
                    </MenuItem>
                    <MenuItem key={`pm`} value={"pm"}>
                        {"pm"}
                    </MenuItem>
                </TextField>
            </div>
            {errorText && <div css={formsCss.timepickerError}>{errorText}</div>}
        </div>
    );
};

export const RenderTimepicker = withStyles(formsCss)(RenderTimepickerField);
