import { of, empty } from "rxjs";
import { mergeMap, mapTo, catchError, concatMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import { default as academicsActionCreators } from "../Academics/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";

const officeHoursAdministratorsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListOfficeHoursAdministrators.type,
            actionCreators.loadMoreOfficeHoursAdministrators.type,
            actionCreators.getOneOfficeHoursAdministrator.type,
            actionCreators.postOfficeHoursAdministrator.type,
            actionCreators.removeOfficeHoursAdministrator.type,
            actionCreators.addOfficeHoursAcademicToAdmin.type,
            actionCreators.removeOfficeHoursAcademicFromAdmin.type,
            actionCreators.importOfficeHoursAdministratorAcademicsSetQueue.type,
            actionCreators.getCopyAssociatedAcademics.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const officeHoursAdministratorsClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListOfficeHoursAdministrators.type,
            actionCreators.updateListOfficeHoursAdministrators.type,
            actionCreators.updateOneOfficeHoursAdministrator.type,
            actionCreators.pushListOfficeHoursAdministrators.type,
            actionCreators.importOfficeHoursAdministratorAcademicsComplete.type,
            actionCreators.clearCopyAssociatedAcademics.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListOfficeHoursAdministratorsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListOfficeHoursAdministrators.type),
        mergeMap(action =>
            ajax.get(ajax.apiUrl(`staff/office-hours/admin/?${action.payload}`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.createListOfficeHoursAdministrators.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreOfficeHoursAdministratorsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreOfficeHoursAdministrators.type),
        mergeMap(action =>
            ajax.get(ajax.apiUrl(`staff/office-hours/admin/?${action.payload}`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateListOfficeHoursAdministrators.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneOfficeHoursAdministratorEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneOfficeHoursAdministrator.type),
        mergeMap(action =>
            ajax.get(ajax.apiUrl(`staff/office-hours/admin/`), { filter: action.payload.id }).pipe(
                concatMap(({ response }) =>
                    of(
                        actionCreators.updateOneOfficeHoursAdministrator.create(response),
                        actionCreators.getOneOfficeHoursAdministratorAcademics.create({
                            id: action.payload.id,
                        })
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneOfficeHoursAdministratorAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneOfficeHoursAdministratorAcademics.type),
        mergeMap(action =>
            ajax.get(ajax.apiUrl(`staff/office-hours/admin/${action.payload.id}/academics/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateOneOfficeHoursAdministratorAcademics.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const newOfficeHoursAdministratorEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postOfficeHoursAdministrator.type),
        mergeMap(action =>
            ajax
                .post(ajax.apiUrl(`staff/office-hours/admin/${action.payload.user.id}/`), {
                    ohAcademicIds: Array.from(action.payload.academics.map(item => item.id)),
                })
                .pipe(
                    mapTo(actionCreators.pushListOfficeHoursAdministrators.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const replaceOfficeHoursAdministratorEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.replaceOfficeHoursAdministrator.type),
        mergeMap(action =>
            ajax
                .post(ajax.apiUrl(`staff/office-hours/admin/${action.payload.new.user.id}/`), {
                    ohAcademicIds: Array.from(action.payload.new.academics.map(item => item.id)),
                })
                .pipe(
                    mapTo(
                        actionCreators.removeOfficeHoursAdministrator.create({
                            id: action.payload.removeId,
                        })
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removeOfficeHoursAdministratorEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeOfficeHoursAdministrator.type),
        mergeMap(action =>
            ajax
                .remove(ajax.apiUrl(`staff/office-hours/admin/${action.payload.id}/`))
                .pipe(
                    mapTo(actionCreators.pushListOfficeHoursAdministrators.create()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const pushListOfficeHoursAdministratorsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListOfficeHoursAdministrators.type),
        mapTo(push(`/bookings/administrators`))
    );

const addOfficeHoursAcademicToAdminEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.addOfficeHoursAcademicToAdmin.type),
        mergeMap(action =>
            ajax
                .post(
                    ajax.apiUrl(
                        `staff/office-hours/admin/${action.payload.id}/academics/${action.payload.academicId}/`
                    )
                )
                .pipe(
                    mergeMap(() =>
                        action.payload.redirectToListAdministrators &&
                        action.payload.redirectToListAdministrators === true
                            ? of(actionCreators.pushListOfficeHoursAdministrators.create())
                            : action.payload.redirectToListAcademics &&
                              action.payload.redirectToListAcademics === true
                            ? of(academicsActionCreators.pushListOfficeHoursAcademics.create())
                            : empty()
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removeOfficeHoursAcademicFromAdminEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeOfficeHoursAcademicFromAdmin.type),
        mergeMap(action =>
            ajax
                .remove(
                    ajax.apiUrl(
                        `staff/office-hours/admin/${action.payload.id}/academics/${action.payload.academicId}/`
                    )
                )
                .pipe(
                    mergeMap(() =>
                        action.payload.redirectToListAdministrators &&
                        action.payload.redirectToListAdministrators === true
                            ? of(actionCreators.pushListOfficeHoursAdministrators.create())
                            : action.payload.redirectToListAcademics &&
                              action.payload.redirectToListAcademics === true
                            ? of(academicsActionCreators.pushListOfficeHoursAcademics.create())
                            : empty()
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const importOfficeHoursAdministratorAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.importOfficeHoursAdministratorAcademics.type),
        mergeMap(action =>
            ajax
                .get(ajax.apiUrl(`staff/autocomplete/academics/`), {
                    filter: action.payload.filter,
                })
                .pipe(
                    mergeMap(({ response }) =>
                        of(
                            actionCreators.importOfficeHoursAdministratorAcademicsReduceQueue.create(
                                response[0] && !!response[0] ? response[0] : null
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getCopyAssociatedAcademicsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getCopyAssociatedAcademics.type),
        mergeMap(action =>
            ajax.get(ajax.apiUrl(`staff/office-hours/admin/${action.payload.id}/academics/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.addCopyAssociatedAcademics.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    officeHoursAdministratorsStartLoadingEpic,
    officeHoursAdministratorsClearLoadingEpic,
    getListOfficeHoursAdministratorsEpic,
    newOfficeHoursAdministratorEpic,
    pushListOfficeHoursAdministratorsEpic,
    getOneOfficeHoursAdministratorEpic,
    getOneOfficeHoursAdministratorAcademicsEpic,
    removeOfficeHoursAdministratorEpic,
    addOfficeHoursAcademicToAdminEpic,
    removeOfficeHoursAcademicFromAdminEpic,
    loadMoreOfficeHoursAdministratorsEpic,
    replaceOfficeHoursAdministratorEpic,
    importOfficeHoursAdministratorAcademicsEpic,
    getCopyAssociatedAcademicsEpic
);
