import React from "react";
import ServerErrorPage from "./ServerError";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
// import lseTheme from "../styles/lseTheme";
import theme from "../../common/styles/theme.json";

const lseTheme = createMuiTheme(theme);

const ErrorFallback = () => (
    <MuiThemeProvider theme={lseTheme}>
        <div>
            <div className="container">
                <ServerErrorPage />
            </div>
        </div>
    </MuiThemeProvider>
);

export default ErrorFallback;
