/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import { mainCategoryStyle } from "./MainCategoryStyles";

const MainCategory = ({ to, title, text }) => (
    <div css={mainCategoryStyle.body}>
        <Link to={to} css={mainCategoryStyle.title}>
            {title}
        </Link>
        <p css={mainCategoryStyle.text}>{text}</p>
    </div>
);

export { MainCategory };
