import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../../store/HowTo/Category/Articles/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";
import { default as categoryActionCreators } from "../../../../store/HowTo/Category/actionCreators";
import { default as froalaActionCreators } from "../../../../store/Froala/actionCreators";
import Form from "./Form";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";
import initialArticleValues from "../../../../fixtures/HowToArticle";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import formsCss from "../../../../common/styles/forms.css";
import { MainTitle } from "../../../../common/components/MainTitle";

class HowToArticlesNewContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            getOneCategory,
            match: {
                params: { categoryId, id },
            },
            setCurrentFormType,
            setCategoryId,
            cleanFroalaImages,
        } = this.props;
        setCategoryId(categoryId);
        const type = id ? currentFormTypes.EDIT : currentFormTypes.NEW;
        setCurrentFormType(type);
        cleanFroalaImages();
        setTitle("Knowledge Base \u203A Articles");
        getOneCategory({ id: categoryId });
    }

    componentWillUnmount() {
        const { clearTitle, clearArticle, rollbackFroalaChanges } = this.props;
        clearTitle();
        clearArticle();
        rollbackFroalaChanges();
    }

    onSubmit = values => {
        const {
            match: {
                params: { categoryId },
            },
            postArticle,
        } = this.props;
        postArticle({
            categoryId,
            article: {
                ...values,
                content: values.type === "html" ? values.contentHtml : values.contentUrl,
            },
        });
    };

    render() {
        const {
            currentFormType,
            initialValues,
            history: { push },
            channelPostTypes,
            categoryId,
            category,
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;
        return (
            <div>
                <MainTitle title={category.name} type="[Category]" />
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    {...{
                        initialValues: { ...initialValues, type: "html" },
                        onSubmit,
                        currentFormType,
                        push,
                        channelPostTypes,
                        categoryId,
                        errors,
                        category,
                    }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneArticle: actionCreators.getOneArticle.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
    postArticle: actionCreators.postArticle.create,
    setCategoryId: actionCreators.setCategoryId.create,
    getOneCategory: categoryActionCreators.getOneCategory.create,
    cleanFroalaImages: froalaActionCreators.cleanFroalaImages.create,
    rollbackFroalaChanges: froalaActionCreators.rollbackFroalaChanges.create,
    clearArticle: actionCreators.clearArticle.create,
};
const mapStateToProps = ({
    HowToArticle: { errorRes, currentFormType, categoryId },
    HowToCategory: { category },
}) => ({
    initialValues: initialArticleValues,
    errorRes,
    currentFormType,
    categoryId,
    category,
});

export default connect(mapStateToProps, dispatchToProps)(HowToArticlesNewContainer);
