export default values => {
    const errors = {};
    const requiredFields = ["firstName", "lastName", "email"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });
    return errors;
};
