import actionCreators from "./actionCreators";
import post from "../../../fixtures/ChannelPost";
import { removeProp } from "../../helpers";
import { prepareFilterQuery } from "../../../common/services/utils";

const initialState = {
    posts: {
        data: [],
        count: 0,
        nextPage: 0,
        queryParams: "",
    },
    post,
    errorRes: { error: "", errors: {} },
    currentFormType: null,
    hasArticle: false,
    hasDate: false,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListPost.type:
            return {
                ...state,
                posts: { ...payload, queryParams: state.posts.queryParams },
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListPost.type:
            payload.data = [
                ...state.posts.data,
                ...payload.data.filter(
                    post => !state.posts.data.map(item => item.id).includes(post.id)
                ),
            ];
            return {
                ...state,
                posts: { ...payload, queryParams: state.posts.queryParams },
                errorRes: initialState.errorRes,
            };

        case actionCreators.getListPost.type:
        case actionCreators.loadMorePosts.type:
            return {
                ...state,
                posts: { ...state.posts, queryParams: prepareFilterQuery(payload) },
            };

        case actionCreators.updateOnePost.type:
            return {
                ...state,
                post: payload,
                errorRes: initialState.errorRes,
                hasArticle: payload.article ? true : false,
                hasDate: payload.status === "scheduled" ? true : false,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.setArticleStatus.type:
            return {
                ...state,
                hasArticle: payload,
            };
        case actionCreators.setCurrentFormType.type:
            return {
                ...state,
                currentFormType: payload,
            };
        case actionCreators.setDateFieldStatus.type:
            return {
                ...state,
                hasDate: payload,
            };
        case actionCreators.clearErrors.type:
            return {
                ...state,
                errorRes: state.errorRes.errors[payload]
                    ? {
                          ...state.errorRes,
                          errors: removeProp(state.errorRes.errors, payload),
                      }
                    : state.errorRes,
            };
        case actionCreators.clearQueryParams.type:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    queryParams: initialState.posts.queryParams,
                },
            };
        case actionCreators.updateArchivedPost.type:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    data: [
                        ...state.posts.data.map(e =>
                            e.id === parseInt(payload)
                                ? {
                                      ...e,
                                      status: "archived",
                                  }
                                : e
                        ),
                    ],
                },
            };
        default:
            return state;
    }
};
