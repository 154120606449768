import actionCreators from "./actionCreators";
import { mergeLists } from "../helpers";

export const initialState = {
    notifications: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    notificationsEmails: null,
    notification: {
        isDisplayedContinuously: false,
        teaser: "",
        content: "",
        startAt: null,
        endAt: null,
    },
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateNotificationBanners.type:
            return {
                ...state,
                notifications: payload,
                notification: initialState.notification,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateMoreNotificationBanners.type:
            return {
                ...state,
                notifications: {
                    ...payload,
                    data: mergeLists(state.notifications.data, payload.data),
                },
                notification: initialState.notification,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateDeletedNotificationBanner.type:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    data: state.notifications.data.filter(({ id }) => id !== payload),
                },
                errorRes: initialState.errorRes,
            };
        case actionCreators.setNotificationBannner.type:
            return {
                ...state,
                notification: payload,
                errorRes: initialState.errorRes,
            };
        default:
            return state;
    }
};
