import actionCreators from "./actionCreators";

export const initialState = [];

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.enqueueSnackbar.type:
            return [
                ...state,
                {
                    ...payload,
                    key: payload.key || new Date().getTime() + Math.random(),
                },
            ];

        case actionCreators.removeSnackbar.type:
            return state.filter(notification => notification.key !== payload);

        case actionCreators.dismissSnackbar.type:
            return state.map(notification =>
                notification.key === payload
                    ? { ...notification, dismissed: true }
                    : { ...notification }
            );
        default:
            return state;
    }
};
