import React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import IconsList from "./SvgElementsList";
import { colors } from "../../UIGlobals";
export const icon = {
    body: {
        userSelect: "none",
        // fontSize: 24,
        // width: "1em",
        // height: "1em",
        display: "inline-block",
        // flexShrink: 0,
    },
};

const SvgElement = props => {
    const { name, fill, viewBox, style, ...other } = props;

    const svgName = IconsList[name];
    const svgEl = svgName.svg;

    return (
        <svg
            focusable="false"
            viewBox={viewBox}
            fill={fill || colors.black87}
            css={[icon.body, style]}
            {...other}
        >
            {React.cloneElement(svgEl)}
        </svg>
    );
};

SvgElement.defaultProps = {
    color: "inherit",
    // viewBox: "0 0 24 24",
};

export default SvgElement;
