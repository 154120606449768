/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    Typography,
    InputBase,
    Switch,
    FormControlLabel,
    IconButton,
} from "@material-ui/core";
import toolbarCss from "../../../common/styles/toolbar.css";
import datepickerCss from "../../../common/styles/datepicker.css";
import { DatePicker } from "material-ui-pickers";
import { ClearIcon } from "../../../common/components/icons";

export default withStyles(toolbarCss)(
    ({
        onFilterChangeHandler,
        filter,
        showPast,
        onChangePeriod,
        classes,
        onDateChangeHandler,
        from,
        to,
        isFromError,
        isToError,
        labelKeyword = "Filter by keyword or ID",
        labelDate = "Filter by Date",
    }) => {
        return (
            <AppBar
                position="static"
                classes={{
                    root: classes.appBarRoot,
                }}
                elevation={0}
            >
                <Toolbar
                    classes={{
                        root: classes.toolbarRootBorder,
                    }}
                >
                    <div css={toolbarCss.toolbarGroup}>
                        <Typography>
                            <span css={toolbarCss.toolbarTitle}>
                                <span css={toolbarCss.labelShowOnDesktop}>{labelKeyword}:</span>
                                <span css={toolbarCss.labelShowOnMobile}>Filter:</span>
                            </span>
                        </Typography>
                        {filter !== undefined && (
                            <InputBase
                                name="filter"
                                placeholder="..."
                                value={filter || ""}
                                onChange={onFilterChangeHandler("filter")}
                                fullWidth
                                autoComplete="off"
                            />
                        )}
                    </div>
                    <div css={toolbarCss.toolbarSeparator} />
                    <div css={toolbarCss.toolbarGroup}>
                        <Typography>
                            <span css={toolbarCss.toolbarTitle}>
                                <span css={toolbarCss.labelShowOnDesktop}>Upcoming sessions</span>
                                <span css={toolbarCss.labelShowOnMobile}>Upcoming</span>
                            </span>
                        </Typography>

                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={(e, v) => onChangePeriod(e.target.checked)}
                                    // value={showPast}
                                    checked={!!showPast}
                                    color="default"
                                    classes={{
                                        icon: classes.switchIcon,
                                        iconChecked: classes.switchIconChecked,
                                        bar: classes.switchBar,
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            }
                        ></FormControlLabel>

                        <Typography>
                            <span css={toolbarCss.toolbarTitle}>
                                <span css={toolbarCss.labelShowOnDesktop}>Past sessions</span>
                                <span css={toolbarCss.labelShowOnMobile}>Past</span>
                            </span>
                        </Typography>
                    </div>
                </Toolbar>

                <Toolbar
                    classes={{
                        root: classes.toolbarRoot,
                    }}
                >
                    <div css={toolbarCss.toolbarGroup}>
                        <Typography>
                            <span css={toolbarCss.toolbarTitle}>
                                <span css={toolbarCss.labelShowOnDesktop}>{labelDate}:</span>
                                <span css={toolbarCss.labelShowOnMobile}>Date:</span>
                            </span>
                        </Typography>
                        <div css={datepickerCss.datepickerRow}>
                            <div css={datepickerCss.datepickerContainer}>
                                <DatePicker
                                    placeholder="From..."
                                    value={from !== "" ? from : null}
                                    onChange={value =>
                                        onDateChangeHandler("from")(
                                            value.set({ hour: 0, minute: 0, second: 0 })
                                        )
                                    }
                                    fullWidth
                                    autoOk={true}
                                    format={"D MMM YYYY"}
                                    color="primary"
                                    css={toolbarCss.toolbarDatePicker}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    error={!!isFromError}
                                    helperText={isFromError ? isFromError : ""}
                                />
                                <IconButton
                                    onClick={() => onDateChangeHandler("from")(null)}
                                    css={datepickerCss.datepickerBtn}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </div>
                            <div css={datepickerCss.datepickerContainer}>
                                <DatePicker
                                    placeholder="To..."
                                    value={to !== "" ? to : null}
                                    onChange={value =>
                                        onDateChangeHandler("to")(
                                            value.set({ hour: 23, minute: 59, second: 59 })
                                        )
                                    }
                                    fullWidth
                                    autoOk={true}
                                    format={"D MMM YYYY"}
                                    color="primary"
                                    css={toolbarCss.toolbarDatePicker}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    error={!!isToError}
                                    helperText={isToError ? isToError : ""}
                                />
                                <IconButton
                                    onClick={() => onDateChangeHandler("to")(null)}
                                    css={datepickerCss.datepickerBtn}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
);
