import { TextField, MenuItem, InputBase } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { colors } from "../../styles/colors";
import { fontSize } from "../../styles/typography";
import formsCss from "../../styles/forms.css";

export const RenderInput = withStyles(formsCss)(({ InputProps, classes, ref, ...other }) => (
    <TextField
        InputProps={{
            inputRef: ref,
            classes: {
                root: classes.inputRoot,
                input: classes.inputInput,
            },
            ...InputProps,
        }}
        {...other}
    />
));

export const renderRawInput = props => <InputBase {...props} />;

export const renderSuggestion = ({
    id,
    label,
    subtitle,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    multiline,
}) => {
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem && selectedItem.id === id;

    return subtitle ? (
        // NEW - title & subtitle/note
        <MenuItem
            {...itemProps}
            key={id}
            selected={isHighlighted}
            component="div"
            css={{ flexDirection: "column", alignItems: "flex-start", lineHeight: 1.2, height: 35 }}
        >
            <span
                css={{
                    display: "block",
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {label}
            </span>
            <span
                css={{
                    display: "block",
                    fontSize: fontSize.textSmaller,
                    color: colors.black54,
                    marginTop: 4,
                }}
            >
                {subtitle}
            </span>
            {/* <ListItemText primary={label} secondary={subtitle} /> */}
        </MenuItem>
    ) : (
        // OLD - just single label
        <MenuItem
            {...itemProps}
            key={id}
            selected={isHighlighted}
            component="div"
            css={[
                {
                    fontWeight: isSelected ? 500 : 400,
                },
                multiline && {
                    whiteSpace: "normal",
                    height: "auto",
                },
            ]}
        >
            {label}
        </MenuItem>
    );
};
