import { reduxForm, Field } from "redux-form";
import { RenderTextField } from "../../../../common/components/FormFields";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button } from "@material-ui/core";
import formsCss from "../../../../common/styles/forms.css";
import validate from "./validate";
// eslint-disable-next-line no-unused-vars
import React from "react";

const SideLinksLinkForm = props => {
    const { isEdit, handleSubmit, errors } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-xs-12">
                    <Field
                        name="name"
                        errorRes={errors && errors.name}
                        component={RenderTextField}
                        label={"Name"}
                        fullWidth
                        rows={5}
                        rowsMax={5}
                    />
                </div>
                <div className="col-xs-12">
                    <Field
                        name="url"
                        errorRes={errors && errors.url}
                        component={RenderTextField}
                        label={"URL"}
                        fullWidth
                        rows={5}
                        rowsMax={5}
                    />
                </div>
            </div>

            <div css={formsCss.actions}>
                <Button type="submit" variant="contained" color="primary" css={formsCss.btnSubmit}>
                    {isEdit ? "Edit" : "Add"} link
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "SideLinksLinkForm",
    enableReinitialize: true,
    validate,
})(SideLinksLinkForm);
