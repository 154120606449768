import { Component, Fragment } from "react";
import { connect } from "react-redux";
import actionCreators from "../../store/Channels/actionCreators";
import { default as mainMenuActionCreators } from "../../store/MainMenu/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Truncate from "react-truncate";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import globalsCss from "../../common/styles/globals.css";
import formsCss from "../../common/styles/forms.css";
import previewCss, {
    channelCover,
    channelFilterBar,
    channelInfo,
} from "../../common/styles/preview.css";
import ChannelTitle from "../../common/components/ChannelTitle";
import { MainTitle } from "../../common/components/MainTitle";
import Avatar from "../../common/components/Avatar";
import { colors } from "../../common/styles/colors";
import { FormatTime } from "../../common/components/Time";
import Wysiwyg from "../../common/components/Wysiwyg";
import { MailIcon, LinkIcon, FavoriteIcon, CommentIcon } from "../../common/components/icons";

class ChannelPreviewContainer extends Component {
    state = {
        activeTab: "feed",
    };

    componentDidMount() {
        const {
            getChannel,
            match: {
                params: { id },
            },
        } = this.props;
        const { onLoadMore } = this;

        getChannel({ id });
        onLoadMore();
    }

    componentWillUnmount() {
        const { clearTitle, clearChannel } = this.props;

        clearTitle();
        clearChannel();
    }

    onTabClick = name => {
        this.setState({ activeTab: name });
    };

    onLoadMore = () => {
        const {
            getChannelPosts,
            page,
            match: {
                params: { id },
            },
        } = this.props;

        getChannelPosts({ id, param: `page=${page}` });
    };

    getChannelType = type => {
        switch (type) {
            case "page":
                return "Page";
            case "group":
                return "Group";
            default:
                return "";
        }
    };

    render() {
        const {
            channel: { id, iconUrl, imageUrl, name, category, email, description, numFollowers },
            hasMore,
            posts,
            history: {
                location: { pathname },
                push,
            },
        } = this.props;
        const { activeTab } = this.state;
        const { onTabClick, onLoadMore, getChannelType } = this;

        return (
            <div>
                <MainTitle title={name} type={`[${getChannelType(category)}]`} />
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Preview Channel</h2>
                        {!!name && (
                            <div css={previewCss.body}>
                                <div css={previewCss.paper}>
                                    <div
                                        css={[
                                            channelCover.body,
                                            !!imageUrl && {
                                                backgroundImage: `url(${imageUrl})`,
                                            },
                                        ]}
                                    >
                                        <div
                                            css={[
                                                channelCover.shape,
                                                imageUrl
                                                    ? channelCover.shapeImg
                                                    : channelCover.shapeDefault,
                                            ]}
                                        />
                                        <div css={channelCover.gradient} />
                                        <div css={channelCover.inner}>
                                            {category !== "page" && (
                                                <div css={channelCover.avatar}>
                                                    {!!iconUrl && <Avatar medium src={iconUrl} />}
                                                </div>
                                            )}
                                            <div css={channelCover.title}>
                                                <div>
                                                    <Truncate lines={3} ellipsis={<span>...</span>}>
                                                        {name}
                                                    </Truncate>
                                                </div>
                                            </div>
                                        </div>

                                        <div css={channelCover.numFollowers}>
                                            <b>{numFollowers}</b>
                                            &nbsp;Follower
                                            {numFollowers > 1 ? "s" : ""}
                                        </div>
                                    </div>
                                    <div css={channelFilterBar.body}>
                                        <button
                                            css={[
                                                channelFilterBar.btn,
                                                activeTab === "feed" && channelFilterBar.btnActive,
                                            ]}
                                            onClick={() => onTabClick("feed")}
                                        >
                                            Feed
                                            <span
                                                css={[
                                                    channelFilterBar.btnBorder,
                                                    activeTab === "feed" &&
                                                        channelFilterBar.btnBorderActive,
                                                ]}
                                            >
                                                <svg
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 375 4"
                                                    width="100%"
                                                    height="4"
                                                >
                                                    <path
                                                        d="M375 0L0 4h375V0z"
                                                        fill={colors.black87}
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <button
                                            css={[
                                                channelFilterBar.btn,
                                                activeTab === "about" && channelFilterBar.btnActive,
                                            ]}
                                            onClick={() => onTabClick("about")}
                                        >
                                            About
                                            <span
                                                css={[
                                                    channelFilterBar.btnBorder,
                                                    activeTab === "about" &&
                                                        channelFilterBar.btnBorderActive,
                                                ]}
                                            >
                                                <svg
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 375 4"
                                                    width="100%"
                                                    height="4"
                                                >
                                                    <path
                                                        d="M375 0L0 4h375V0z"
                                                        fill={colors.black87}
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                {activeTab === "about" && (
                                    <div>
                                        <div css={previewCss.paper}>
                                            {email && (
                                                <div css={channelInfo.body}>
                                                    {email && (
                                                        <div css={channelInfo.btn}>
                                                            <span css={channelInfo.btnIcon}>
                                                                <MailIcon />
                                                            </span>
                                                            <span css={channelInfo.btnText}>
                                                                {email}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {!!description && (
                                                <div css={channelInfo.text}>{description}</div>
                                            )}

                                            {category === "group" && (
                                                <div css={channelInfo.disclaimer}>
                                                    The views expressed in this group do not reflect
                                                    those of LSE &amp; LSESU. Users have full
                                                    responsibility for the content published in this
                                                    group. Members of the group have accepted
                                                    Student Hub community guidelines which can be
                                                    viewed{" "}
                                                    <span css={channelInfo.disclaimerLink}>
                                                        here
                                                    </span>
                                                    .
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {activeTab === "feed" && (
                                    <div>
                                        {posts.length > 0 &&
                                            posts.map((post, index) => (
                                                <div css={previewCss.paper} key={index}>
                                                    <ChannelTitle
                                                        channel={{ name, iconUrl }}
                                                        publicationDate={post.publicationDate}
                                                    />
                                                    <div css={previewCss.mainImg}>
                                                        <span css={previewCss.mainImg.imgShape}>
                                                            <svg
                                                                preserveAspectRatio="none"
                                                                viewBox="0 0 375 8"
                                                                width="100%"
                                                                height="8"
                                                            >
                                                                <path
                                                                    d="M375 0H0v8L375 0z"
                                                                    fill="#fff"
                                                                />
                                                            </svg>
                                                        </span>
                                                        {!!post.imageUrl && (
                                                            <button
                                                                css={previewCss.mainImg}
                                                                type="button"
                                                                onClick={() =>
                                                                    push(
                                                                        `${pathname}/${
                                                                            post.kind === "text"
                                                                                ? "posts"
                                                                                : "events"
                                                                        }/${post.id}`
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={post.imageUrl}
                                                                    css={previewCss.mainImg.img}
                                                                />
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div css={previewCss.inner}>
                                                        {post.kind === "event" && (
                                                            <Fragment>
                                                                <div css={previewCss.title}>
                                                                    {post.name}
                                                                </div>
                                                                <div css={previewCss.eventSummary}>
                                                                    <div css={previewCss.details}>
                                                                        <div
                                                                            css={
                                                                                previewCss.detailsDate
                                                                            }
                                                                        >
                                                                            <FormatTime
                                                                                date={post.startAt}
                                                                                format="dddd D MMMM YYYY h.mma"
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            css={
                                                                                previewCss.detailsLocation
                                                                            }
                                                                        >
                                                                            {post.location}
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        css={[
                                                                            previewCss.btn,
                                                                            previewCss.btnGray,
                                                                        ]}
                                                                        onClick={() =>
                                                                            push(
                                                                                `${pathname}/${
                                                                                    post.kind ===
                                                                                    "text"
                                                                                        ? "posts"
                                                                                        : "events"
                                                                                }/${post.id}`
                                                                            )
                                                                        }
                                                                    >
                                                                        <LinkIcon
                                                                            css={
                                                                                previewCss.btnIconGray
                                                                            }
                                                                        />
                                                                        Find out more
                                                                    </button>
                                                                </div>
                                                            </Fragment>
                                                        )}
                                                        <div className="entryText">
                                                            <Wysiwyg html={post.description} />
                                                        </div>

                                                        {!!post.article && (
                                                            <div css={previewCss.actionsReadMore}>
                                                                <button
                                                                    type="button"
                                                                    css={[
                                                                        previewCss.btn,
                                                                        previewCss.btnGray,
                                                                    ]}
                                                                    onClick={() =>
                                                                        push(
                                                                            `${pathname}/${
                                                                                post.kind === "text"
                                                                                    ? "posts"
                                                                                    : "events"
                                                                            }/${post.id}`
                                                                        )
                                                                    }
                                                                >
                                                                    <LinkIcon
                                                                        css={previewCss.btnIconGray}
                                                                    />
                                                                    Continue reading
                                                                </button>
                                                            </div>
                                                        )}
                                                        <div css={previewCss.summary}>
                                                            <FavoriteIcon
                                                                css={previewCss.favoriteIcon}
                                                            />
                                                            <button
                                                                css={previewCss.btnComments}
                                                                onClick={() =>
                                                                    push(
                                                                        `${pathname}/${
                                                                            post.kind === "text"
                                                                                ? "posts"
                                                                                : "events"
                                                                        }/${post.id}`
                                                                    )
                                                                }
                                                            >
                                                                <CommentIcon
                                                                    css={previewCss.commentIcon}
                                                                />
                                                                <span
                                                                    css={previewCss.btnCommentsText}
                                                                >
                                                                    {post.numComments
                                                                        ? `${post.numComments} comments`
                                                                        : "No comments"}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        {hasMore && (
                                            <Button
                                                onClick={onLoadMore}
                                                css={formsCss.btnFullWidth}
                                            >
                                                Load more
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        <div css={formsCss.actions}>
                            <Button
                                onClick={() => push(`edit/${id}`)}
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Edit Channel
                            </Button>
                            <Button onClick={() => push(`./`)} css={formsCss.btnBack}>
                                Back to the list
                            </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getChannel: actionCreators.getChannel.create,
    getChannelPosts: actionCreators.getChannelPosts.create,
    clearChannel: actionCreators.clearChannel.create,
};

const mapStateToProps = ({ ChannelPreview: { page, hasMore, posts, ...rest } }) => ({
    channel: rest.channel,
    page,
    hasMore,
    posts,
});

export default connect(mapStateToProps, dispatchToProps)(ChannelPreviewContainer);
