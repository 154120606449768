/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useFormContext, Controller } from "react-hook-form";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import React, { FC, forwardRef } from "react";
import formsCss from "src/common/styles/forms.css";

interface OwnProps {
    name: string;
    options: string[];
    getOptionLabel?: any[];
    errorRes?: string;
    onChange?: any;
}

const RHFRadioGroup: FC<OwnProps> = forwardRef(
    ({ name, options, getOptionLabel, errorRes, onChange, ...other }, ref) => {
        const { control } = useFormContext();
        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <div>
                            <RadioGroup
                                {...field}
                                row
                                {...other}
                                value={field.value ? field.value : null}
                                onChange={(e, v) => {
                                    field.onChange(e);
                                    if (onChange) onChange(v);
                                }}
                            >
                                {options.map((option, index) => (
                                    <FormControlLabel
                                        key={option}
                                        value={option}
                                        control={<Radio />}
                                        label={
                                            getOptionLabel?.length ? getOptionLabel[index] : option
                                        }
                                    />
                                ))}
                            </RadioGroup>

                            {(error || errorRes) && (
                                <div css={formsCss.genericError}>
                                    {error && error.message
                                        ? error.message
                                        : errorRes
                                        ? errorRes
                                        : ""}
                                </div>
                            )}
                        </div>
                    );
                }}
            />
        );
    }
);

export default RHFRadioGroup;
