/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../styles/globals.css";
import { FC } from "react";

interface OwnProps {
    title: string;
    subtitle?: string;
    type?: string;
    rightElement?: any;
    isItalic?: boolean;
}

export const MainTitle: FC<OwnProps> = ({ title, subtitle, type, rightElement, isItalic }) => (
    <div css={[globalsCss.mainTitle, globalsCss.mainTitleInner]}>
        <div css={globalsCss.mainTitleLeftElement}>
            <h1 css={globalsCss.mainTitleText}>
                {isItalic ? <em>{title}</em> : title}
                {type && <span css={globalsCss.mainTitleType}>{type}</span>}
            </h1>
            {subtitle && <div css={globalsCss.mainSubtitleText}>{subtitle}</div>}
        </div>
        <div css={globalsCss.mainTitleRightElement}>{rightElement}</div>
    </div>
);
