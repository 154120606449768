// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import actionCreators from "../../store/MainMenu/actionCreators";
import dashboardActionCreators from "../../store/Dashboard/actionCreators";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import globalsCss from "../../common/styles/globals.css";
import dashboardCss from "../../common/styles/dashboard.css";
import Button from "@material-ui/core/Button";
import { RedirectButton } from "../../common/components/Channels";
import { PageIcon, AutoRenewIcon, GroupIcon, ScheduleIcon } from "../../common/components/icons";
import { prepareFilterQuery } from "../../common/services/utils";
import { GetFormattedDate } from "../../common/components/Time";
import {
    USER_TYPE_STANDARD,
    USER_TYPE_MANAGER,
    USER_TYPE_ADMIN,
} from "../../app/UsersAndAudiences/LseStaff/values";

class DashboardContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            getPages,
            getGroups,
            pages,
            groups,
            appointments,
            user,
            getAppointments,
        } = this.props;
        setTitle("LSE Student Hub");
        if (pages.nextPage > 0) getPages(pages.nextPage);
        if (groups.nextPage > 0) getGroups(groups.nextPage);
        if (appointments.nextPage > 0)
            getAppointments({
                userId: user.id,
                query: prepareFilterQuery({ page: 1 }),
            });
    }

    componentWillUnmount() {
        const { clearPages, clearGroups, clearTitle } = this.props;
        clearTitle();
        clearPages();
        clearGroups();
    }

    render() {
        const {
            loadMorePages,
            loadMoreGroups,
            pages,
            groups,
            appointments,
            user,
            history: { push },
        } = this.props;

        const isUser = [USER_TYPE_STANDARD, USER_TYPE_MANAGER, USER_TYPE_ADMIN].includes(
            user.permissionLevel
        );

        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h3>Welcome to LSE Student Hub Admin Panel</h3>
                    <div css={dashboardCss.subtitle}>
                        Please see below list of all Channels that you&apos;ve been assigned to ...
                    </div>
                    <hr />
                    <div className="row" css={dashboardCss.inner}>
                        <div className="col-sm-4 col-xs-12">
                            <div css={dashboardCss.section}>
                                <PageIcon css={dashboardCss.icon} />
                                <strong css={dashboardCss.title}>Pages</strong>
                            </div>
                            <RedirectButton
                                label="Add new page"
                                url="/channels/pages/new"
                                css={dashboardCss.newButton}
                                {...{ push }}
                            />

                            {pages && (
                                <div>
                                    {pages.data.length > 0 &&
                                        pages.data.map(page => (
                                            <div css={dashboardCss.pageItem} key={page.id}>
                                                <div css={dashboardCss.pageItemTitle}>
                                                    {page.name}
                                                </div>
                                                <div css={dashboardCss.pageItemBody}>
                                                    <button
                                                        css={[
                                                            dashboardCss.link,
                                                            dashboardCss.pageItemBodyLink,
                                                        ]}
                                                        onClick={() =>
                                                            push({
                                                                pathname: "/channels/posts",
                                                                state: {
                                                                    id: page.id,
                                                                    name: page.name,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        posts
                                                    </button>
                                                    <span>|</span>
                                                    <button
                                                        css={[
                                                            dashboardCss.link,
                                                            dashboardCss.pageItemBodyLink,
                                                        ]}
                                                        onClick={() =>
                                                            push({
                                                                pathname: "/channels/events",
                                                                state: {
                                                                    id: page.id,
                                                                    name: page.name,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        events
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                            {pages.nextPage > 1 && (
                                <Button
                                    onClick={() => loadMorePages(pages.nextPage)}
                                    variant="contained"
                                    color="secondary"
                                    css={dashboardCss.button}
                                >
                                    <AutoRenewIcon css={dashboardCss.buttonIcon} />
                                    Load more
                                </Button>
                            )}
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div css={dashboardCss.section}>
                                <GroupIcon css={dashboardCss.icon} />
                                <strong css={dashboardCss.title}>Groups</strong>
                            </div>
                            <RedirectButton
                                label="Add new group"
                                url="/channels/groups/new"
                                css={dashboardCss.newButton}
                                {...{ push }}
                            />

                            {groups && (
                                <div>
                                    {groups.data.length > 0 &&
                                        groups.data.map(group => (
                                            <div css={dashboardCss.pageItem} key={group.id}>
                                                <div css={dashboardCss.pageItemTitle}>
                                                    {group.name}
                                                </div>
                                                <div css={dashboardCss.pageItemBody}>
                                                    <button
                                                        css={[
                                                            dashboardCss.link,
                                                            dashboardCss.pageItemBodyLink,
                                                        ]}
                                                        onClick={() =>
                                                            push({
                                                                pathname: "/channels/posts",
                                                                state: {
                                                                    id: group.id,
                                                                    name: group.name,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        posts
                                                    </button>
                                                    <span>|</span>
                                                    <button
                                                        css={[
                                                            dashboardCss.link,
                                                            dashboardCss.pageItemBodyLink,
                                                        ]}
                                                        onClick={() =>
                                                            push({
                                                                pathname: "/channels/events",
                                                                state: {
                                                                    id: group.id,
                                                                    name: group.name,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        events
                                                    </button>
                                                    <span>|</span>
                                                    <button
                                                        css={[
                                                            dashboardCss.link,
                                                            dashboardCss.pageItemBodyLink,
                                                        ]}
                                                        onClick={() =>
                                                            push(
                                                                `/channels/groups/${group.id}/members`
                                                            )
                                                        }
                                                    >
                                                        members
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                            {groups.nextPage > 1 && (
                                <Button
                                    onClick={() => loadMoreGroups(groups.nextPage)}
                                    variant="contained"
                                    color="secondary"
                                    css={dashboardCss.button}
                                >
                                    <AutoRenewIcon css={dashboardCss.buttonIcon} />
                                    Load more
                                </Button>
                            )}
                        </div>

                        {isUser && !!user.hasBookings && (
                            <div className="col-sm-4 col-xs-12">
                                <div css={dashboardCss.section}>
                                    <ScheduleIcon css={dashboardCss.icon} />
                                    <strong css={dashboardCss.title}>Bookings</strong>
                                </div>
                                <RedirectButton
                                    label="Manage Your Availability"
                                    url={`/bookings/availability/${user.id}/manage`}
                                    css={dashboardCss.newButton}
                                    {...{ push }}
                                />
                                {appointments.data && (
                                    <div>
                                        {appointments.data.length > 0 &&
                                            appointments.data.map(appointment => (
                                                <div
                                                    key={appointment.id}
                                                    css={dashboardCss.bookingItem}
                                                >
                                                    <div css={dashboardCss.bookingItemTitle}>
                                                        <Link
                                                            css={[
                                                                dashboardCss.link,
                                                                dashboardCss.bookingItemTitleLink,
                                                            ]}
                                                            to={`/bookings/appointments/${appointment.id}`}
                                                        >
                                                            {appointment.session}
                                                        </Link>
                                                    </div>
                                                    <div css={dashboardCss.bookingItemDateTime}>
                                                        {appointment.dateTime}
                                                    </div>
                                                    <div css={dashboardCss.bookingItemLocation}>
                                                        {appointment.location}
                                                    </div>
                                                    {appointment.booking && (
                                                        <div css={dashboardCss.bookingItemBookedBy}>
                                                            Booked by: {appointment.booking}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                )}
                                {groups.nextPage > 1 && (
                                    <Button
                                        onClick={() => loadMoreGroups(groups.nextPage)}
                                        variant="contained"
                                        color="secondary"
                                        css={dashboardCss.button}
                                    >
                                        <AutoRenewIcon css={dashboardCss.buttonIcon} />
                                        Load more
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Paper>
        );
    }
}

const dispatchToProps = {
    setTitle: actionCreators.setTitle.create,
    clearTitle: actionCreators.clearTitle.create,
    getPages: dashboardActionCreators.getPages.create,
    getGroups: dashboardActionCreators.getGroups.create,
    loadMorePages: dashboardActionCreators.loadMorePages.create,
    loadMoreGroups: dashboardActionCreators.loadMoreGroups.create,
    getAppointments: dashboardActionCreators.getAppointments.create,
    loadMoreAppointments: dashboardActionCreators.loadMoreAppointments.create,
    clearPages: dashboardActionCreators.clearPages.create,
    clearGroups: dashboardActionCreators.clearGroups.create,
};

const mapStateToProps = ({ Dashboard, Auth }) => ({
    pages: Dashboard.pages,
    groups: Dashboard.groups,
    appointments: {
        data: Dashboard.appointments.data.map(item => {
            let user = item.apiUser ? `${item.apiUser.firstName} ${item.apiUser.lastName}` : "";

            return {
                id: item.id,
                dateTime: `${GetFormattedDate(item.start)} ${GetFormattedDate(
                    item.start,
                    "h.mma"
                )} - ${GetFormattedDate(item.end, "h.mma")}`,
                location: item.officeHoursSession.appointmentForm
                    ? `${item.officeHoursSession.location} (${item.officeHoursSession.appointmentForm})`
                    : item.officeHoursSession.location,
                session: item.officeHoursSession.teamName
                    ? `${item.officeHoursSession.title} (${item.officeHoursSession.teamName})`
                    : item.officeHoursSession.title,
                booking: user ? user : "",
            };
        }),
        count: Dashboard.appointments.count,
        nextPage: Dashboard.appointments.nextPage,
    },
    user: Auth,
});

export default connect(mapStateToProps, dispatchToProps)(DashboardContainer);
