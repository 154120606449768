import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { MoreVertIcon } from "./icons";
import tableCss from "../styles/table.css";

class ContextMenu extends Component {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { buttons, fns, classes } = this.props;
        const { anchorEl } = this.state;

        return (
            <Fragment>
                <IconButton onClick={this.handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    transformOrigin={{ horizontal: "right", vertical: "center" }}
                    elevation={1}
                    MenuListProps={{
                        disablePadding: true,
                    }}
                    disableAutoFocusItem
                >
                    {buttons
                        .filter(({ hide }) => !hide)
                        .map(({ label, path, search, fnLabel, icon, state }, buttonIndex) => (
                            <MenuItem
                                key={buttonIndex}
                                onClick={() => {
                                    if (fnLabel === "push" && typeof path === "string")
                                        fns[fnLabel]({
                                            pathname: path,
                                            search: search ? search : undefined,
                                            state: state ? state : undefined,
                                        });
                                    else fns[fnLabel](path);
                                    this.setState({ anchorEl: null });
                                }}
                                classes={{
                                    root: classes.contextMenuItem,
                                }}
                            >
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText
                                    classes={{
                                        root: classes.contextMenuItemText,
                                    }}
                                    inset
                                    disableTypography
                                    primary={label}
                                />
                            </MenuItem>
                        ))}
                </Menu>
            </Fragment>
        );
    }
}

export default withStyles(tableCss)(ContextMenu);
