import { GetFormattedDate } from "../../../../common/components/Time";

export const processSchoolYears = schoolYears => {
    let output = [];

    for (let item of schoolYears) {
        output.push({
            value: `${item.start}#${item.end}`,
            label: `Academic Year: ${GetFormattedDate(item.start, "YYYY")} - ${GetFormattedDate(
                item.end,
                "YYYY"
            )}`,
        });

        if (item.terms && item.terms.length > 0) {
            for (let term of item.terms) {
                output.push({
                    value: `${term.start}#${term.end}`,
                    label: `> ${GetFormattedDate(item.start, "YYYY")}/${GetFormattedDate(
                        item.end,
                        "YYYY"
                    )} - ${term.name}`,
                });
            }
        }
    }

    return output;
};
