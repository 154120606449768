import actionCreators from "./actionCreators";

export const initialState = {
    addedImages: [],
    removedImages: [],
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.addFroalaImage.type:
            return {
                ...state,
                addedImages: [...state.addedImages, payload],
            };
        case actionCreators.deleteFroalaImage.type:
            return {
                ...state,
                removedImages: [...state.removedImages, payload],
            };
        case actionCreators.cleanFroalaImages.type:
            return {
                ...state,
                addedImages: [],
                removedImages: [],
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.logFroalaError.type:
            // todo: rename to noop
            // console.log(payload.message);
            return state;
        default:
            return state;
    }
};
