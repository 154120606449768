import { spacing, colors, fontWeight } from "../../UIGlobals";

export const breadcrumbsStyle = {
    body: {
        marginBottom: spacing.space2comma5,
    },
    item: {
        color: colors.secondaryAlt,
        fontSize: 12,
        textDecoration: "none",
    },
    itemActive: {
        color: colors.secondaryAlt,
        fontSize: 12,
        fontWeight: fontWeight.regular,
    },
    divider: {
        color: "#D2D2D2",
        fontSize: 16,
        marginRight: spacing.space0,
        marginLeft: spacing.space0,
    },
};
