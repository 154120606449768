import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { ListItem, ListItemText } from "@material-ui/core";
import sidebarCss from "../../styles/sidebar.css";

const MainMenuSubItem = ({ to, title, classes, onClick }) => (
    <ListItem
        button
        classes={{
            root: classes.rootMenuSubItem,
        }}
        component={to && Link}
        to={to}
        onClick={onClick}
    >
        <ListItemText
            // inset
            primary={title}
            classes={{
                primary: classes.rootMenuSubItemText,
            }}
        />
    </ListItem>
);

export default withStyles(sidebarCss)(MainMenuSubItem);
