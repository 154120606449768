import { Component } from "react";
import { reduxForm, Field, formValueSelector, change, registerField } from "redux-form";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import formsCss from "../../../../common/styles/forms.css";

import {
    Button,
    Paper,
    Checkbox,
    FormControlLabel,
    TextField,
    MenuItem,
    Chip,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import {
    RenderSelectField,
    RenderRadioGroup,
    RenderRadio,
} from "../../../../common/components/FormFields";
import validate from "./validateCustom";
import { connect } from "react-redux";
import actionCreators from "../../../../store/OfficeHours/Reports/actionCreators";
import { default as audienceActionCreators } from "../../../../store/UsersAndAudiences/Audiences/actionCreators";
import { withStyles } from "@material-ui/core/styles";

import { TableComponent } from "../../../../common/components/DefaultTable";
import { decorateTableItemsJobTypes } from "../../../../common/services/decorateTableItems";
import { AutoRenewIcon } from "../../../../common/components/icons";
import tableCss from "../../../../common/styles/table.css";

const FORM_TYPE_FIELD = "Field";

const STUDENT_TYPE_UG = "UG";
const STUDENT_TYPE_PGT = "PGT";
const STUDENT_TYPE_PGR = "PGR";

export const ALL_DEPARTAMENTS = "ALL";

class FormCustom extends Component {
    // live params
    state = {
        lseDepartmentId: null,
        graduation: null,
        yearGroup: null,
        lseProgrammes: [],
        studentsModalIsOpen: false,
    };

    componentDidMount() {
        const {
            departments,
            getOfficeReportDepartmentList,
            addField,
            getOfficeReportYearGroupList,
            yearGroups,
            initialValues,
        } = this.props;

        addField("graduation", FORM_TYPE_FIELD);
        addField("lseProgrammes", FORM_TYPE_FIELD);
        addField("numStudents", FORM_TYPE_FIELD);
        addField("lseNumStudents", FORM_TYPE_FIELD);

        if (departments.length === 0) {
            getOfficeReportDepartmentList();
        }

        if (yearGroups.length === 0) {
            getOfficeReportYearGroupList();
        }

        this.props.initialize(initialValues);
    }

    componentDidUpdate(prevProps) {
        const {
            initialValues,
            getOfficeReportProgrammeList,
            updateForm,
            customAudienceLiveParams,
        } = this.props;
        const { handleRecalculateLiveParams } = this;
        if (!prevProps.initialValues.id && initialValues.id) {
            if (initialValues.lseDepartmentId !== ALL_DEPARTAMENTS)
                getOfficeReportProgrammeList({
                    id: initialValues.lseDepartmentId,
                    type: "aggregated",
                });
            this.setState(
                {
                    lseDepartmentId: initialValues.lseDepartmentId,
                    graduation: initialValues.graduation ? initialValues.graduation : null,
                    yearGroup: initialValues.yearGroup ? initialValues.yearGroup : null,
                    lseProgrammes: initialValues.lseProgrammes
                        ? initialValues.lseProgrammes.map(i => {
                              return { id: i };
                          })
                        : [],
                },
                () => handleRecalculateLiveParams()
            );
            updateForm(
                "audienceType",
                initialValues.graduation
                    ? "byStatus"
                    : initialValues.lseProgrammes && initialValues.lseProgrammes.length > 0
                    ? "byProgramme"
                    : null
            );
        }

        if (
            customAudienceLiveParams &&
            prevProps.customAudienceLiveParams &&
            customAudienceLiveParams.numStudents >= 0 &&
            customAudienceLiveParams.numStudents !== prevProps.customAudienceLiveParams.numStudents
        ) {
            updateForm("numStudents", customAudienceLiveParams.numStudents);
        }

        if (
            customAudienceLiveParams &&
            prevProps.customAudienceLiveParams &&
            customAudienceLiveParams.lseNumStudents >= 0 &&
            customAudienceLiveParams.lseNumStudents !==
                prevProps.customAudienceLiveParams.lseNumStudents
        ) {
            updateForm("lseNumStudents", customAudienceLiveParams.lseNumStudents);
        }
    }

    handleChangeDepartmentId = value => {
        const { handleRecalculateLiveParams } = this;
        const { getOfficeReportProgrammeList, updateForm } = this.props;
        if (value === ALL_DEPARTAMENTS) updateForm("audienceType", null);
        else getOfficeReportProgrammeList({ id: value, type: "aggregated" });
        updateForm("lseProgrammes", []);
        this.setState(
            { lseDepartmentId: value == ALL_DEPARTAMENTS ? null : value, lseProgrammes: [] },
            () => handleRecalculateLiveParams()
        );
    };

    handleRecalculateLiveParams = () => {
        const { getCustomAudienceLiveParams } = this.props;
        const { lseDepartmentId, graduation, yearGroup, lseProgrammes } = this.state;
        getCustomAudienceLiveParams({
            lseDepartmentId,
            graduation,
            yearGroup,
            lseProgrammes,
        });
    };

    openStudentListModal = () => {
        this.setState({ studentsModalIsOpen: true });
    };

    closeStudentListModal = () => {
        this.setState({ studentsModalIsOpen: false });
    };

    renderStudentListModal = () => {
        const {
            studentsModalIsOpen,
            lseDepartmentId,
            graduation,
            yearGroup,
            lseProgrammes,
        } = this.state;
        const { closeStudentListModal } = this;
        const {
            customAudienceLiveParams,
            customAudienceLiveParamsStudents,
            clearCustomAudienceLiveParamsStudents,
            getCustomAudienceLiveParamsStudents,
        } = this.props;
        return (
            <Dialog
                // fullScreen
                open={studentsModalIsOpen}
                fullWidth
                maxWidth="md"
                disableBackdropClick
                onEntered={() => {
                    getCustomAudienceLiveParamsStudents({
                        page: 1,
                        lseDepartmentId,
                        graduation,
                        yearGroup,
                        lseProgrammes,
                    });
                }}
            >
                <DialogTitle>
                    {customAudienceLiveParams && customAudienceLiveParams.name
                        ? customAudienceLiveParams.name
                        : ""}
                </DialogTitle>
                <DialogContent>
                    {customAudienceLiveParamsStudents &&
                    customAudienceLiveParamsStudents.data &&
                    customAudienceLiveParamsStudents.data.length > 0 ? (
                        <div>
                            <TableComponent
                                items={customAudienceLiveParamsStudents.data.map((i, index) => {
                                    return { ...i, id: index };
                                })}
                                decorators={[
                                    {
                                        type: decorateTableItemsJobTypes.TRANSLATE,
                                        payload: {
                                            knownAs: "First name",
                                            lastName: "Last name",
                                            playRef: "Playref",
                                            email: "Email address",
                                        },
                                    },
                                ]}
                                columns={["knownAs", "lastName", "playRef", "email"]}
                            />
                            {customAudienceLiveParamsStudents.nextPage > 0 && (
                                <div css={tableCss.loadMore}>
                                    <Button
                                        onClick={() => {
                                            getCustomAudienceLiveParamsStudents({
                                                page: customAudienceLiveParamsStudents.nextPage,
                                                lseDepartmentId,
                                                graduation,
                                                yearGroup,
                                                lseProgrammes,
                                            });
                                        }}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        <AutoRenewIcon css={formsCss.btnIcon} />
                                        Load more
                                    </Button>
                                    <span css={tableCss.loadMoreLabel}>
                                        <small>
                                            (showing {customAudienceLiveParamsStudents.data.length}{" "}
                                            out of {customAudienceLiveParamsStudents.count})
                                        </small>
                                    </span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <CircularProgress color="inherit" size={20} thickness={2} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            closeStudentListModal();
                            clearCustomAudienceLiveParamsStudents();
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    render() {
        const {
            submitting,
            handleSubmit,
            currentFormType,
            goBack,
            errorRes: { error, errors },
            departments,
            programmes,
            formFields,
            formErrors,
            classes,
            updateForm,
            submitFailed,
            yearGroups,
            customAudienceLiveParams,
            permissionLevel,
        } = this.props;

        const {
            handleRecalculateLiveParams,
            handleChangeDepartmentId,
            openStudentListModal,
            renderStudentListModal,
        } = this;

        if (departments.length === 0) return null;

        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>
                        {currentFormType === "NEW"
                            ? "Create new custom audience"
                            : "Edit custom audience"}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-5 col-xs-12">
                                <div css={formsCss.label}>Select department</div>
                                <Field
                                    name="lseDepartmentId"
                                    component={RenderSelectField}
                                    label="Select value"
                                    errorRes={errors.lseDepartmentId}
                                    options={[
                                        ...(permissionLevel === "admin"
                                            ? [
                                                  {
                                                      value: ALL_DEPARTAMENTS,
                                                      label: "All Departments",
                                                  },
                                              ]
                                            : []),
                                        ...departments.map(d => ({
                                            value: d.id,
                                            label: d.name,
                                        })),
                                    ]}
                                    onChange={(e, value) => {
                                        handleChangeDepartmentId(value);
                                    }}
                                />
                            </div>
                            {customAudienceLiveParams && (
                                <div className="col-sm-7 col-xs-12">
                                    <div css={formsCss.greyBox}>
                                        <div className="row align-center">
                                            <div
                                                className="col-xs-12"
                                                css={globalsCss.helpers.mt15}
                                            >
                                                <span>Audience: </span>
                                                <br />
                                                {customAudienceLiveParams.name ? (
                                                    <strong>{customAudienceLiveParams.name}</strong>
                                                ) : (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                        thickness={2}
                                                    />
                                                )}
                                            </div>
                                            <div
                                                className="col-sm-4 col-xs-12"
                                                css={globalsCss.helpers.mt15}
                                            >
                                                <span>SH students: </span>
                                                <strong>
                                                    {customAudienceLiveParams &&
                                                    Object.keys(customAudienceLiveParams).length >
                                                        0 &&
                                                    customAudienceLiveParams.numStudents >= 0 ? (
                                                        customAudienceLiveParams.numStudents
                                                    ) : (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                            thickness={2}
                                                        />
                                                    )}
                                                </strong>
                                            </div>
                                            <div
                                                className="col-sm-4 col-xs-6"
                                                css={globalsCss.helpers.mt15}
                                            >
                                                <span>LSE students: </span>
                                                {customAudienceLiveParams &&
                                                Object.keys(customAudienceLiveParams).length > 0 &&
                                                customAudienceLiveParams.lseNumStudents >= 0 ? (
                                                    <span
                                                        style={{
                                                            display: "inline-flex",
                                                            justifyConent: "space-between",
                                                        }}
                                                    >
                                                        <strong>
                                                            {
                                                                customAudienceLiveParams.lseNumStudents
                                                            }
                                                        </strong>
                                                    </span>
                                                ) : (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                        thickness={2}
                                                    />
                                                )}
                                            </div>
                                            {customAudienceLiveParams &&
                                                Object.keys(customAudienceLiveParams).length > 0 &&
                                                customAudienceLiveParams.lseNumStudents >= 0 && (
                                                    <div
                                                        className="col-sm-4 col-xs-6 col-end-right"
                                                        css={globalsCss.helpers.mt15}
                                                    >
                                                        <Button
                                                            onClick={() => openStudentListModal()}
                                                            css={{ marginTop: -3, marginLeft: 8 }}
                                                        >
                                                            Show students
                                                        </Button>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {formFields.lseDepartmentId && (
                            <div>
                                <hr />

                                <div css={globalsCss.helpers.mt35} className="row">
                                    <div className="col-lg-5 col-sm-12 col-xs-12">
                                        <div css={formsCss.label}>
                                            Filter by programme or student status{" "}
                                            <em>(optional)</em>
                                        </div>

                                        <Field
                                            name="audienceType"
                                            errorRes={errors.audienceType}
                                            component={RenderRadioGroup}
                                            className=""
                                            onBlur={e => e.preventDefault()}
                                            onChange={value => {
                                                if (value === "byProgramme") {
                                                    updateForm("graduation", null);
                                                    this.setState({ graduation: null }, () =>
                                                        handleRecalculateLiveParams()
                                                    );
                                                } else if (value === "byStatus") {
                                                    updateForm("lseProgrammes", []);
                                                    this.setState({ lseProgrammes: [] }, () =>
                                                        handleRecalculateLiveParams()
                                                    );
                                                }
                                            }}
                                        >
                                            <RenderRadio
                                                value="byStatus"
                                                label={
                                                    <div css={formsCss.inlineAlignTopColumn}>
                                                        <div css={formsCss.label}>
                                                            by student status
                                                        </div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    classes={{
                                                                        root: classes.checkbox,
                                                                        checked:
                                                                            classes.checkboxChecked,
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.checkboxLabel,
                                                            }}
                                                            checked={
                                                                formFields.graduation ===
                                                                STUDENT_TYPE_UG
                                                            }
                                                            label="Undergraduate (UG)"
                                                            onChange={() => {
                                                                updateForm(
                                                                    "graduation",
                                                                    formFields.graduation ===
                                                                        STUDENT_TYPE_UG
                                                                        ? null
                                                                        : STUDENT_TYPE_UG
                                                                );
                                                                updateForm(
                                                                    "audienceType",
                                                                    "byStatus"
                                                                );
                                                                updateForm("lseProgrammes", []);

                                                                this.setState(
                                                                    {
                                                                        lseProgrammes: [],
                                                                        graduation:
                                                                            formFields.graduation ===
                                                                            STUDENT_TYPE_UG
                                                                                ? null
                                                                                : STUDENT_TYPE_UG,
                                                                    },
                                                                    () =>
                                                                        handleRecalculateLiveParams()
                                                                );
                                                            }}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    classes={{
                                                                        root: classes.checkbox,
                                                                        checked:
                                                                            classes.checkboxChecked,
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.checkboxLabel,
                                                            }}
                                                            checked={
                                                                formFields.graduation ===
                                                                STUDENT_TYPE_PGT
                                                            }
                                                            label="Taught postgraduate (PGT)"
                                                            onChange={() => {
                                                                updateForm(
                                                                    "graduation",
                                                                    formFields.graduation ===
                                                                        STUDENT_TYPE_PGT
                                                                        ? null
                                                                        : STUDENT_TYPE_PGT
                                                                );
                                                                updateForm(
                                                                    "audienceType",
                                                                    "byStatus"
                                                                );
                                                                updateForm("lseProgrammes", []);

                                                                this.setState(
                                                                    {
                                                                        lseProgrammes: [],
                                                                        graduation:
                                                                            formFields.graduation ===
                                                                            STUDENT_TYPE_PGT
                                                                                ? null
                                                                                : STUDENT_TYPE_PGT,
                                                                    },
                                                                    () =>
                                                                        handleRecalculateLiveParams()
                                                                );
                                                            }}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    classes={{
                                                                        root: classes.checkbox,
                                                                        checked:
                                                                            classes.checkboxChecked,
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.checkboxLabel,
                                                            }}
                                                            checked={
                                                                formFields.graduation ===
                                                                STUDENT_TYPE_PGR
                                                            }
                                                            label="Research postgraduate (PGR)"
                                                            onChange={() => {
                                                                updateForm(
                                                                    "graduation",
                                                                    formFields.graduation ===
                                                                        STUDENT_TYPE_PGR
                                                                        ? null
                                                                        : STUDENT_TYPE_PGR
                                                                );
                                                                updateForm(
                                                                    "audienceType",
                                                                    "byStatus"
                                                                );
                                                                updateForm("lseProgrammes", []);

                                                                this.setState(
                                                                    {
                                                                        lseProgrammes: [],
                                                                        graduation:
                                                                            formFields.graduation ===
                                                                            STUDENT_TYPE_PGR
                                                                                ? null
                                                                                : STUDENT_TYPE_PGR,
                                                                    },
                                                                    () =>
                                                                        handleRecalculateLiveParams()
                                                                );
                                                            }}
                                                        />

                                                        {submitFailed && formErrors.graduation && (
                                                            <span css={formsCss.genericError}>
                                                                {formErrors.graduation}
                                                            </span>
                                                        )}
                                                    </div>
                                                }
                                                // className="col-lg-6 col-sm-12 col-xs-12"
                                            />

                                            <RenderRadio
                                                value="byProgramme"
                                                disabled={
                                                    formFields.lseDepartmentId === ALL_DEPARTAMENTS
                                                }
                                                label={
                                                    <div css={formsCss.inlineAlignTop}>
                                                        <div css={formsCss.label}>by programme</div>
                                                        <TextField
                                                            select
                                                            margin="none"
                                                            color="primary"
                                                            fullWidth={true}
                                                            error={!!errors.lseProgrammes}
                                                            helperText={errors.lseProgrammes}
                                                            label={"Select value"}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.inputRoot,
                                                                    error: classes.inputError,
                                                                },
                                                            }}
                                                            SelectProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        disablePadding: true,
                                                                        dense: true,
                                                                    },
                                                                },
                                                            }}
                                                            onChange={({ target: { value } }) => {
                                                                const newProgrammes =
                                                                    formFields.lseProgrammes &&
                                                                    formFields.lseProgrammes.includes(
                                                                        value
                                                                    )
                                                                        ? formFields.lseProgrammes
                                                                        : [
                                                                              ...(formFields.lseProgrammes
                                                                                  ? formFields.lseProgrammes
                                                                                  : []),
                                                                              value,
                                                                          ];
                                                                updateForm(
                                                                    "lseProgrammes",
                                                                    newProgrammes
                                                                );
                                                                updateForm("graduation", null);
                                                                updateForm(
                                                                    "audienceType",
                                                                    value ? "byProgramme" : null
                                                                );

                                                                this.setState(
                                                                    {
                                                                        lseProgrammes: newProgrammes.map(
                                                                            i => {
                                                                                return { id: i };
                                                                            }
                                                                        ),
                                                                        graduation: null,
                                                                    },
                                                                    () =>
                                                                        handleRecalculateLiveParams()
                                                                );
                                                            }}
                                                            value={
                                                                formFields.lseProgrammes
                                                                    ? formFields.lseProgrammes
                                                                    : ""
                                                            }
                                                            disabled={
                                                                formFields.lseDepartmentId ===
                                                                ALL_DEPARTAMENTS
                                                            }
                                                        >
                                                            {programmes && programmes.length > 0 ? (
                                                                programmes.map(d => (
                                                                    <MenuItem
                                                                        key={`tia_${d.id}`}
                                                                        value={d.id}
                                                                    >
                                                                        {d.name}
                                                                    </MenuItem>
                                                                ))
                                                            ) : (
                                                                <MenuItem
                                                                    key={"1"}
                                                                    value={""}
                                                                ></MenuItem>
                                                            )}
                                                        </TextField>
                                                        <div>
                                                            {programmes &&
                                                                programmes.length > 0 &&
                                                                formFields.lseProgrammes &&
                                                                formFields.lseProgrammes.map(
                                                                    item => (
                                                                        <Chip
                                                                            key={item}
                                                                            css={
                                                                                globalsCss.chipsItem
                                                                            }
                                                                            label={`${
                                                                                programmes.find(
                                                                                    p =>
                                                                                        p.id ===
                                                                                        item
                                                                                )?.name
                                                                            }`}
                                                                            onDelete={() => {
                                                                                const newProgrammes = formFields.lseProgrammes.filter(
                                                                                    i => i !== item
                                                                                );
                                                                                updateForm(
                                                                                    "lseProgrammes",
                                                                                    newProgrammes
                                                                                );

                                                                                this.setState(
                                                                                    {
                                                                                        lseProgrammes: newProgrammes.map(
                                                                                            i => {
                                                                                                return {
                                                                                                    id: i,
                                                                                                };
                                                                                            }
                                                                                        ),
                                                                                    },
                                                                                    () =>
                                                                                        handleRecalculateLiveParams()
                                                                                );
                                                                            }}
                                                                        />
                                                                    )
                                                                )}
                                                        </div>
                                                        {submitFailed &&
                                                            formErrors.lseProgrammes && (
                                                                <span css={formsCss.genericError}>
                                                                    {formErrors.lseProgrammes}
                                                                </span>
                                                            )}
                                                    </div>
                                                }
                                            />
                                        </Field>
                                    </div>
                                    <div className="col-lg-1 col-sm-0 col-xs-0"></div>
                                    <div className="col-lg-4 col-sm-12 col-xs-12">
                                        {yearGroups && (
                                            <div>
                                                <div css={formsCss.label}>
                                                    Filter by year group <em>(optional)</em>
                                                </div>
                                                <Field
                                                    name="yearGroup"
                                                    component={RenderSelectField}
                                                    label="Select value"
                                                    errorRes={errors.yearGroup}
                                                    options={[
                                                        { value: "ALL", label: "ALL" },
                                                        ...yearGroups.map(yg => ({
                                                            value: `${yg}`,
                                                            label: `${yg}`,
                                                        })),
                                                    ]}
                                                    onChange={(e, value) => {
                                                        this.setState(
                                                            {
                                                                yearGroup:
                                                                    value !== "ALL"
                                                                        ? Number(value)
                                                                        : null,
                                                            },
                                                            () => handleRecalculateLiveParams()
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-2 col-sm-12 col-xs-12">
                                        <div css={globalsCss.filters}>
                                            <Button
                                                disabled={submitting}
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            graduation: null,
                                                            yearGroup: null,
                                                            lseProgrammes: [],
                                                        },
                                                        () => {
                                                            updateForm("audienceType", null);
                                                            updateForm("lseProgrammes", []);
                                                            updateForm("graduation", null);
                                                            updateForm("yearGroup", null);
                                                            handleRecalculateLiveParams();
                                                        }
                                                    );
                                                }}
                                                css={formsCss.btnBack}
                                            >
                                                Reset filters
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {error && <div css={formsCss.genericError}>{error}</div>}
                        <div css={formsCss.actions}>
                            <Button
                                disabled={submitting}
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Save Audience
                            </Button>
                            <Button
                                disabled={submitting}
                                onClick={() => goBack()}
                                css={formsCss.btnBack}
                            >
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
                {renderStudentListModal()}
            </Paper>
        );
    }
}

const FORM_NAME = "CustomAudience";

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
})(withStyles(formsCss)(FormCustom));

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = ({
    form,
    OfficeHoursReport: { departments, programmes, yearGroups },
    Audience: { errorRes, customAudienceLiveParams, customAudienceLiveParamsStudents },
    Auth: { permissionLevel },
}) => ({
    departments,
    programmes,
    yearGroups,
    formFields: selector(
        { form },
        "lseDepartmentId",
        "audienceType",
        "graduation",
        "lseProgrammes"
    ),
    formErrors: form[FORM_NAME]
        ? form[FORM_NAME].syncErrors
            ? form[FORM_NAME].syncErrors
            : {}
        : {},
    errorRes,
    customAudienceLiveParams,
    customAudienceLiveParamsStudents,
    permissionLevel,
});

const dispatchToProps = {
    updateForm: (key, newValue) => change(FORM_NAME, key, newValue),
    addField: (key, newValue) => registerField(FORM_NAME, key, newValue),
    getOfficeReportDepartmentList: actionCreators.getOfficeReportDepartmentList.create,
    getOfficeReportProgrammeList: actionCreators.getOfficeReportProgrammeList.create,
    getOfficeReportYearGroupList: actionCreators.getOfficeReportYearGroupList.create,
    getCustomAudienceLiveParams: audienceActionCreators.getCustomAudienceLiveParams.create,
    getCustomAudienceLiveParamsStudents:
        audienceActionCreators.getCustomAudienceLiveParamsStudents.create,
    clearCustomAudienceLiveParamsStudents:
        audienceActionCreators.clearCustomAudienceLiveParamsStudents.create,
};

export default connect(mapStateToProps, dispatchToProps)(form);
