import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";

const startLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getStandardNotifications.type,
            actionCreators.loadMoreStandardNotifications.type,
            actionCreators.getStandardNotification.type,
            actionCreators.postStandardNotification.type,
            actionCreators.putStandardNotification.type,
            actionCreators.deleteStandardNotification.type,
            actionCreators.getTimetableNotifications.type,
            actionCreators.loadMoreTimetableNotifications.type,
            actionCreators.getTimetableNotification.type,
            actionCreators.postTimetableNotification.type,
            actionCreators.putTimetableNotification.type,
            actionCreators.getNotificationEmailPreview.type,
            actionCreators.getLseModuleGroups.type,
            actionCreators.getSelectedTeachingsStudents.type,
            actionCreators.getTemplateList.type,
            actionCreators.loadMoreTemplates.type,
            actionCreators.postTemplate.type,
            actionCreators.putTemplate.type,
            actionCreators.getTemplate.type,
            actionCreators.removeTemplate.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const clearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.setNotificationEmailPreview.type,
            actionCreators.setLseModuleGroups.type,
            actionCreators.updateSelectedTeachingsStudents.type,
            actionCreators.updateNotifications.type,
            actionCreators.updateMoreNotifications.type,
            actionCreators.setNotification.type,
            actionCreators.updateDeletedNotification.type,
            actionCreators.getNotificationEmails.type,
            actionCreators.updateNotificationEmails.type,
            actionCreators.setTemplateList.type,
            actionCreators.updateTemplateList.type,
            actionCreators.setTemplate.type,
            actionCreators.postRemoveTemplate.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

// STANDARD NOTIFICATIONS

const apiGetStandardNotificationsList = query =>
    ajax.get(ajax.apiUrl(`manager/notification/standard/${query ? `?${query}` : ""}`));

const apiGetStandardNotification = id =>
    ajax.get(ajax.apiUrl(`manager/notification/standard/${id}/`));

const apiPostStandardNotification = model =>
    ajax.post(ajax.apiUrl(`manager/notification/standard/`), model);

const apiPutStandardNotification = (id, model) =>
    ajax.put(ajax.apiUrl(`manager/notification/standard/${id}/`), model);

const apiDeleteStandardNotification = id =>
    ajax.remove(ajax.apiUrl(`manager/notification/standard/${id}/`));

const getStandardNotificationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getStandardNotifications.type),
        switchMap(({ payload: params }) =>
            apiGetStandardNotificationsList(params).pipe(
                mergeMap(({ response }) => of(actionCreators.updateNotifications.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreStandardNotificationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreStandardNotifications.type),
        switchMap(({ payload: params }) =>
            apiGetStandardNotificationsList(params).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateMoreNotifications.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getStandardNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getStandardNotification.type),
        switchMap(({ payload: id }) =>
            apiGetStandardNotification(id).pipe(
                mergeMap(({ response }) => of(actionCreators.setNotification.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const postStandardNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postStandardNotification.type),
        switchMap(({ payload }) =>
            apiPostStandardNotification(payload).pipe(
                mapTo(push("/notifications/standard/list")),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const putStandardNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putStandardNotification.type),
        switchMap(({ payload }) =>
            apiPutStandardNotification(payload.id, payload).pipe(
                mapTo(push("/notifications/standard/list")),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const deleteStandardNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteStandardNotification.type),
        switchMap(({ payload: id }) =>
            apiDeleteStandardNotification(id).pipe(
                mapTo(actionCreators.updateDeletedNotification.create(JSON.parse(id))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

// TIMETABLE NOTIFICATIONS

const apiGetTimetableNotificationsList = query =>
    ajax.get(ajax.apiUrl(`manager/notification/timetable/${query ? `?${query}` : ""}`));

const apiGetTimetableNotification = id =>
    ajax.get(ajax.apiUrl(`manager/notification/timetable/${id}/`));

const apiPostTimetableNotification = model =>
    ajax.post(ajax.apiUrl("manager/notification/timetable/"), model);

const apiPutTimetableNotification = (id, model) =>
    ajax.put(ajax.apiUrl(`manager/notification/timetable/${id}/`), model);

const apiPostNotificationPreview = model =>
    ajax.post(ajax.apiUrl("manager/notification/timetable/preview/"), model);

const apiDeleteTimetableNotification = id =>
    ajax.remove(ajax.apiUrl(`manager/notification/timetable/${id}/`));

const apiGetModuleGroupDetails = teachingCode =>
    ajax.get(ajax.apiUrl(`manager/notification/timetable/lse-module/${teachingCode}/`));

const apiGetSelectedTeachingStudents = (page, teachingCode, teachingGroup) =>
    ajax.get(
        ajax.apiUrl(
            `manager/notification/timetable/teachings-audience/?page=${page}&teachingCode=${teachingCode}${
                teachingGroup ? `&teachingGroup=${teachingGroup}` : ""
            }`
        )
    );

const getTimetableNotificationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getTimetableNotifications.type),
        switchMap(({ payload: params }) =>
            apiGetTimetableNotificationsList(params).pipe(
                mergeMap(({ response }) => of(actionCreators.updateNotifications.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreTimetableNotificationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreTimetableNotifications.type),
        switchMap(({ payload: params }) =>
            apiGetTimetableNotificationsList(params).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateMoreNotifications.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getTimetableNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getTimetableNotification.type),
        switchMap(({ payload: id }) =>
            apiGetTimetableNotification(id).pipe(
                mergeMap(({ response }) => of(actionCreators.setNotification.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const deleteTimetabledNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteTimetabledNotification.type),
        switchMap(({ payload: id }) =>
            apiDeleteTimetableNotification(id).pipe(
                mapTo(actionCreators.updateDeletedNotification.create(JSON.parse(id))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getNotificationEmailPreviewEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getNotificationEmailPreview.type),
        switchMap(({ payload: model }) =>
            apiPostNotificationPreview(model).pipe(
                mergeMap(({ response }) => {
                    return of(actionCreators.setNotificationEmailPreview.create(response));
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getLseModuleGroupsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getLseModuleGroups.type),
        switchMap(({ payload: code }) =>
            apiGetModuleGroupDetails(code).pipe(
                mergeMap(({ response }) => of(actionCreators.setLseModuleGroups.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const postTimetableNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postTimetableNotification.type),
        switchMap(({ payload }) =>
            apiPostTimetableNotification(payload).pipe(
                mergeMap(() =>
                    of(
                        UIActionCreators.clearLoading.create(),
                        push("/notifications/timetable/list")
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const putTimetableNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putTimetableNotification.type),
        switchMap(({ payload }) =>
            apiPutTimetableNotification(payload.id, payload).pipe(
                mergeMap(({ response }) =>
                    of(
                        UIActionCreators.clearLoading.create(),
                        actionCreators.setNotification.create(response),
                        push("/notifications/timetable/list")
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getSelectedTeachingsStudentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSelectedTeachingsStudents.type),
        switchMap(({ payload: { page, teachingCode, teachingGroup } }) =>
            apiGetSelectedTeachingStudents(page, teachingCode, teachingGroup).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateSelectedTeachingsStudents.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

// EMAIL TEMPLATES

const apiGetNotificationEmails = () => ajax.get(ajax.apiUrl(`manager/notification-emails/`));

const apiGetNotificationTemplateList = query =>
    ajax.get(ajax.apiUrl("manager/timetable-notification-template/"), query);

const apiGetNotificationTemplateDetails = id =>
    ajax.get(ajax.apiUrl(`manager/timetable-notification-template/${id}/`));

const apiPostNotificationTemplate = model =>
    ajax.post(ajax.apiUrl("manager/timetable-notification-template/"), model);

const apiPutNotificationTemplate = (id, model) =>
    ajax.put(ajax.apiUrl(`manager/timetable-notification-template/${id}/`), model);

const apiRemoveNotificationTemplate = id =>
    ajax.remove(ajax.apiUrl(`manager/timetable-notification-template/${id}/`));

const getNotificationEmailsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getNotificationEmails.type),
        switchMap(() =>
            apiGetNotificationEmails().pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateNotificationEmails.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getTemplateListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getTemplateList.type),
        switchMap(({ payload: query }) =>
            apiGetNotificationTemplateList(query).pipe(
                mergeMap(({ response }) => of(actionCreators.setTemplateList.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreTemplatesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreTemplates.type),
        switchMap(({ payload: query }) =>
            apiGetNotificationTemplateList(query).pipe(
                mergeMap(({ response }) => of(actionCreators.updateTemplateList.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const postTemplateEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postTemplate.type),
        switchMap(({ payload }) =>
            apiPostNotificationTemplate(payload).pipe(
                mergeMap(() =>
                    of(
                        UIActionCreators.clearLoading.create(),
                        push("/notifications/email-templates/list")
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getTemplateEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getTemplate.type),
        switchMap(({ payload: id }) =>
            apiGetNotificationTemplateDetails(id).pipe(
                mergeMap(({ response }) => of(actionCreators.setTemplate.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const putTemplateEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putTemplate.type),
        switchMap(({ payload }) =>
            apiPutNotificationTemplate(payload.id, payload).pipe(
                mergeMap(({ response }) =>
                    of(
                        actionCreators.setTemplate.create(response),
                        UIActionCreators.clearLoading.create(),
                        push("/notifications/email-templates/list")
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const removeTemplateEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeTemplate.type),
        switchMap(({ payload: id }) =>
            apiRemoveNotificationTemplate(id).pipe(
                mapTo(actionCreators.postRemoveTemplate.create(id)),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    startLoadingEpic,
    clearLoadingEpic,

    // standard
    getStandardNotificationsEpic,
    loadMoreStandardNotificationsEpic,
    getStandardNotificationEpic,
    postStandardNotificationEpic,
    putStandardNotificationEpic,
    deleteStandardNotificationEpic,

    // timetable
    getTimetableNotificationsEpic,
    loadMoreTimetableNotificationsEpic,
    getTimetableNotificationEpic,
    getTemplateListEpic,
    loadMoreTemplatesEpic,
    postTemplateEpic,
    getTemplateEpic,
    deleteTimetabledNotificationEpic,

    //template
    putTemplateEpic,
    removeTemplateEpic,
    postTimetableNotificationEpic,
    putTimetableNotificationEpic,
    getNotificationEmailPreviewEpic,
    getLseModuleGroupsEpic,
    getSelectedTeachingsStudentsEpic,

    // other
    getNotificationEmailsEpic
);
