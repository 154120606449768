import { of, empty, concat } from "rxjs";
import {
    filter,
    mergeMap,
    withLatestFrom,
    map,
    switchMap,
    tap,
    catchError,
    mapTo,
    mergeMapTo,
} from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import * as queryString from "query-string";
import * as ajax from "../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { service, redirectUrl, logoutUrl } from "./sso";

const authStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.checkStorage.type),
        mapTo(UIActionCreators.setLoading.create())
    );

const authClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.login.type,
            actionCreators.errorResponse.type,
            actionCreators.updateCurrentUser.type,
            actionCreators.setNotAuth.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const validateEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.validate.type),
        switchMap(({ payload }) =>
            ajax
                .post(ajax.apiUrl("auth/validate/backend"), payload, {
                    "Content-Type": "application/json",
                })
                .pipe(
                    tap(({ response }) => {
                        localStorage.setItem("token", response.id);
                        window.location = window.location.href.split("?")[0];
                    }),
                    mergeMapTo(empty()),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const restoreUserEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionCreators.restoreUser.type),
        withLatestFrom(state$),
        map(([, { Auth }]) => {
            const token = localStorage.getItem("token");
            return [token, Auth];
        }),
        filter(([token, Auth]) => token && Auth.token !== token),
        mergeMap(([token]) =>
            of(actionCreators.setAuth.create({ token }), actionCreators.getCurrentUser.create())
        )
    );

const checkStorageEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.checkStorage.type),
        // TODO: refactoring
        mergeMap(() => {
            const token = localStorage.getItem("token");
            if (token) {
                return of(actionCreators.login.create());
            }

            const { ticket } = queryString.parse(window.location.search);
            if (ticket) {
                return of(actionCreators.validate.create({ ticket, service }));
            }

            window.location.href = redirectUrl;
            return empty();
        })
    );

const getCurrentUserEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getCurrentUser.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl(`staff/profile`, process.env)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateCurrentUser.create(response))),
                catchError(res => {
                    const handleError = errorHandler(actionCreators.errorResponse.create);
                    let errorResponse = handleError(res);
                    errorResponse = concat(errorResponse, of(actionCreators.setInitiated.create()));
                    return errorResponse;
                })
            )
        )
    );

const loginEpic = action$ =>
    action$.pipe(ofType(actionCreators.login.type), mapTo(actionCreators.getCurrentUser.create()));

const logoutEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.logout.type),
        tap(() => {
            localStorage.removeItem("token");
            window.location.href = logoutUrl;
        }),
        mergeMapTo(empty())
    );

export const epics = combineEpics(
    authStartLoadingEpic,
    authClearLoadingEpic,
    validateEpic,
    checkStorageEpic,
    restoreUserEpic,
    getCurrentUserEpic,
    logoutEpic,
    loginEpic
);
