export const icon = {
    body: {
        userSelect: "none",
        // fontSize: 24,
        // width: "1em",
        // height: "1em",
        display: "inline-block",
        // flexShrink: 0,
    },
};
