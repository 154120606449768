import { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { RenderTextField } from "../../../../common/components/FormFields";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button } from "@material-ui/core";
import formsCss from "../../../../common/styles/forms.css";
import globalsCss from "../../../../common/styles/globals.css";

class Form extends Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    render() {
        const { handleSubmit, errors, fieldLabel, buttonLabel, simpleLayout } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className={!simpleLayout ? "row" : null}>
                    <div className={!simpleLayout ? "col-xs-12" : null}>
                        <Field
                            name="comment"
                            error={errors.Comment}
                            component={RenderTextField}
                            label={fieldLabel}
                            fullWidth
                            multiline
                            rows={5}
                            rowsMax={5}
                            css={simpleLayout ? { marginTop: 0 } : null}
                        />
                    </div>
                </div>

                <div
                    css={
                        !simpleLayout
                            ? formsCss.actions
                            : [globalsCss.helpers.mt20, globalsCss.helpers.mb20]
                    }
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        css={formsCss.btnSubmit}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: "OfficeHoursAppointmentComment",
    enableReinitialize: true,
})(Form);

export default form;
