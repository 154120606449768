import ActionCreator from "../../../actionCreator";

export default {
    getListArticle: ActionCreator("HOW_TO_ARTICLE_GET_LIST"),
    createListArticle: ActionCreator("HOW_TO_ARTICLE_CREATE_LIST_ARTICLE"),
    updateListArticle: ActionCreator("HOW_TO_ARTICLE_UPDATE_LIST_ARTICLE"),
    pushListArticles: ActionCreator("HOW_TO_ARTICLE_PUSH_LIST_ARTICLES"),
    getOneArticle: ActionCreator("HOW_TO_ARTICLE_GET_ONE_ARTICLE"),
    updateOneArticle: ActionCreator("HOW_TO_ARTICLE_UPDATE_ONE_ARTICLE"),
    postArticle: ActionCreator("HOW_TO_ARTICLE_POST_ARTICLE"),
    putArticle: ActionCreator("HOW_TO_ARTICLE_PUT_ARTICLE"),
    setCurrentFormType: ActionCreator("HOW_TO_ARTICLE_SET_CURRENT_FORM_TYPE_ARTICLE"),
    setCategoryId: ActionCreator("HOW_TO_ARTICLE_SET_CATEGORY_ID"),
    loadMoreArticles: ActionCreator("HOW_TO_ARTICLE_LOAD_MORE_ARTICLES"),
    errorResponse: ActionCreator("HOW_TO_ARTICLE_ERROR_RESPONSE_ARTICLE"),
    removeArticle: ActionCreator("HOW_TO_ARTICLE_REMOVE_ARTICLE"),
    clearArticle: ActionCreator("HOW_TO_ARTICLE_CLEAR_ARTICLE"),
    moveUpArticle: ActionCreator("MOVE_UP_HOW_TO_ARTICLE"),
    moveDownArticle: ActionCreator("MOVE_DOWN_HOW_TO_ARTICLE"),
};
