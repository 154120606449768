import { Component } from "react";
import { withStyles, Paper } from "@material-ui/core";
import Downshift from "downshift";
import { AvatarChip } from "../AvatarChip";
import { RenderInput, renderSuggestion } from "./helpers";
import { styles } from "./styles";
import globalsCss from "../../styles/globals.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class AutoCompleteDownshift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: !!props.inputValue && props.inputValue.length > 0 ? props.inputValue : "",
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.inputValue !== this.props.inputValue) {
            this.setState({
                inputValue:
                    !!this.props.inputValue && this.props.inputValue.length > 0
                        ? this.props.inputValue
                        : "",
            });
        }
    }

    handleInputChange = event => {
        this.setState(
            { inputValue: event.target.value },
            this.props.onUpdateInput(event.target.value)
        );
    };

    handleNewRequest = selectedItems => {
        const { onChange, onNewRequest } = this.props;
        onNewRequest && onNewRequest(selectedItems);
        onChange(selectedItems);
    };

    handleChange = item => {
        const { blacklistedIds } = this.props;
        if (!item) return;
        if (blacklistedIds && blacklistedIds.indexOf(item.id) >= 0) return;

        const { selectedItems, single, onChangeCallback } = this.props;

        if (single) {
            this.setState({ inputValue: "" }, () => {
                this.handleNewRequest(item);
            });
        } else {
            this.setState({ inputValue: "" }, () => {
                if (selectedItems.map(obj => obj.id).indexOf(item.id) === -1) {
                    this.handleNewRequest([...selectedItems, item]);
                }
            });
        }

        if (onChangeCallback) onChangeCallback();
    };

    handleDelete = item => () => {
        const { selectedItems, single, onChangeCallback } = this.props;
        if (single) this.handleNewRequest(null);
        else this.handleNewRequest(selectedItems.filter(obj => obj.id !== item.id));
        if (onChangeCallback) onChangeCallback();
    };

    render() {
        const {
            classes,
            floatingLabelText,
            errorRes,
            selectedItems,
            dataSource,
            labelCallback,
            subtitleCallback,
            numCallback,
            noAvatar,
            onBlur,
            disabled,
        } = this.props;
        const { inputValue } = this.state;

        return (
            <Downshift
                inputValue={inputValue}
                itemToString={item => (item && item.id ? labelCallback(item) : "")}
                onChange={this.handleChange}
                selectedItem={selectedItems}
            >
                {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    selectedItem: selectedItem2,
                    highlightedIndex,
                }) => (
                    <div className={classes.container}>
                        <RenderInput
                            fullWidth={true}
                            // classes,
                            autoComplete="off"
                            label={floatingLabelText}
                            error={errorRes}
                            // placeholder: floatingLabelText,
                            margin="normal"
                            InputProps={getInputProps({
                                onChange: this.handleInputChange,
                                onBlur,
                            })}
                            disabled={!!disabled}
                        />
                        {isOpen ? (
                            <Paper className={classes.paper} square>
                                {dataSource.map((item, index) =>
                                    renderSuggestion({
                                        id: item.id,
                                        label: labelCallback(item),
                                        subtitle: subtitleCallback(item),
                                        index,
                                        itemProps: getItemProps({ item }),
                                        highlightedIndex,
                                        selectedItem: selectedItem2,
                                    })
                                )}
                            </Paper>
                        ) : null}

                        {selectedItems.length > 0 && (
                            <div css={globalsCss.chipsContainer}>
                                {selectedItems.map(item => {
                                    return (
                                        <AvatarChip
                                            key={item.id}
                                            onDelete={
                                                disabled ? undefined : this.handleDelete(item)
                                            }
                                            name={labelCallback(item)}
                                            numFollowers={numCallback && numCallback(item)}
                                            noAvatar={!!noAvatar}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </Downshift>
        );
    }
}

export default withStyles(styles)(AutoCompleteDownshift);
