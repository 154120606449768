import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import actionCreators from "../../../store/Notification/actionCreators";
import mainMenuActionCreators from "../../../store/MainMenu/actionCreators";
import { MainTitle } from "../../../common/components/MainTitle";
import formsCss from "../../../common/styles/forms.css";
import TimetableNotificationForm from "../components/TimetableNotificationForm";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import { CurrentFormType } from "src/types/generic";
import { TimetableNotification } from "../types/Notification";
import { convertFormToNotification } from "./helpers";

interface MatchParams {
    id?: string;
}

const AddEditTimetableNotification: FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
    const dispatch = useDispatch();
    const notification = useSelector(
        (state: AppState) => state.Notification.notification
    ) as TimetableNotification;
    const { error, errors } = useSelector((state: AppState) => state.Notification.errorRes);
    const { push } = history;
    const id = match.params.id;
    const [preparedNotification, setPreparedNotification] = useState<any>(
        id ? undefined : notification
    );

    useEffect(() => {
        dispatch(mainMenuActionCreators.setTitle.create("Notifications"));

        if (id) dispatch(actionCreators.getTimetableNotification.create(id));
        return () => {
            dispatch(mainMenuActionCreators.clearTitle.create());
        };
    }, []);

    useEffect(() => {
        if (notification && notification.id) {
            const { audience, ...rest } = notification;
            if (audience.lseModules && audience.lseModules[0]) {
                const { module, group, teachings } = audience.lseModules[0];
                const converted = {
                    ...rest,
                    template: notification.template ? notification.template.id : undefined,
                    module,
                    group: group ? group.teachingGroup : "*",
                    teachings:
                        !teachings || !group || teachings.length === group.teachings.length
                            ? ["*"]
                            : teachings,
                };
                setPreparedNotification(converted);
            }
        }
    }, [notification]);

    const onSubmit = values => {
        const data = convertFormToNotification(values);
        if (notification && notification.id)
            dispatch(actionCreators.putTimetableNotification.create(data));
        else dispatch(actionCreators.postTimetableNotification.create(data));
    };

    return (
        <div>
            <MainTitle
                title={
                    notification && notification.id
                        ? "Edit Timetable Notification"
                        : "Add Timetable Notification"
                }
            />
            <div css={formsCss.genericError}>{error}</div>
            {preparedNotification && (
                <TimetableNotificationForm
                    {...{
                        formType:
                            notification && notification.id
                                ? CurrentFormType.EDIT
                                : CurrentFormType.NEW,
                        initialValues: preparedNotification as TimetableNotification,
                        onSubmit,
                        error,
                        errors,
                        push,
                    }}
                />
            )}
        </div>
    );
};

export default AddEditTimetableNotification;
