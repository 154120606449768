import { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Button from "@material-ui/core/Button";
import { Subject } from "rxjs";
import validate from "./validate";

import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";

import { RenderAutocomplete } from "../../../../common/components/FormFields";

import formsCss from "../../../../common/styles/forms.css";
import globalsCss from "../../../../common/styles/globals.css";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { default as CalendarEventsActions } from "../../../../store/CalendarAndEvents/actionCreators";

class FormByName extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const { getStudentUsers, setSearchBy } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            rawUpdateListOnFilterChange({
                getFn: filter => {
                    getStudentUsers({ filter: filter });
                    setSearchBy("name");
                },
                filter: query.filter,
            });
        });

        this.props.initialize(this.props.initialValues);
    }

    updateSearchText(value) {
        const { searchText$ } = this.state;
        this.setState({ searchText: value }, () =>
            searchText$.next({ filter: this.state.searchText })
        );
    }

    onStudentChangeHandler = searchText => {
        this.updateSearchText(searchText);
    };

    handleSubmit = data => {
        const { onSubmit } = this.props;
        onSubmit({ ...data, searchBy: "name" });
    };

    render() {
        const { students, clearStudentUsers } = this.props;

        return (
            <form onSubmit={this.props.handleSubmit(this.handleSubmit.bind(this))}>
                <h2 css={globalsCss.subtitleTitle}>Look up student calendar by name</h2>

                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="student"
                            handleInput={e => this.onStudentChangeHandler(e)}
                            dataSource={students}
                            component={RenderAutocomplete}
                            single
                            floatingLabelText={"Full Name or [SSO] Username"}
                            onDroppedFocus={clearStudentUsers}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div css={formsCss.inlineBtn}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Show Events
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const formName = "CalendarEventsByName";

const form = reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
})(FormByName);

const dispatchToProps = {
    setSearchBy: CalendarEventsActions.setSearchBy.create,
};

export default connect(null, dispatchToProps)(form);
