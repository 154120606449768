export default values => {
    const errors = {};
    const requiredFields = ["name", "startAt", "endAt", "location"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    if (values.startAt && values.endAt && values.startAt > values.endAt) {
        errors.endAt = "End date must be later than start date";
    }

    if (values && values.audience) {
        const keys = Object.keys(values.audience);
        const reducer = (accumulator, currentValue) =>
            accumulator || values.audience[currentValue].length > 0;
        const errorReducer = (accumulator, currentValue) => {
            accumulator[currentValue] = "At least one audience must be selected.";
            return accumulator;
        };

        if (!keys.reduce(reducer, false)) {
            errors.audience = keys.reduce(errorReducer, {});
        }
    }

    return errors;
};
