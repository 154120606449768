import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "./../../../store/Auth/actionCreators";
import { withStyles } from "@material-ui/core/styles";
import {
    Avatar,
    AppBar,
    Drawer,
    Toolbar,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    CircularProgress,
    Tooltip,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import TodayIcon from "@material-ui/icons/Today";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PlaceIcon from "@material-ui/icons/Place";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import PersonIcon from "@material-ui/icons/Person";
import ContactSupportIcon from "@material-ui/icons/Help";
import SupportPageCmsIcon from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import { ROUTE_SIDELINKS } from "../../../app/SideLinks/routes";
import {
    USER_TYPE_STANDARD,
    USER_TYPE_MANAGER,
    USER_TYPE_ADMIN,
} from "../../../app/UsersAndAudiences/LseStaff/values";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import sidebarCss from "./../../styles/sidebar.css";
import logo from "./../../images/logo-lse.png";
import MainMenuItem from "./MainMenuItem";
import MainMenuSubItem from "./MainMenuSubItem";

class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    getInitials = str => str.match(/\b(\w)/g).join("");

    getLoadingScreen = () => {
        if (this.props.isLoading) {
            return (
                <div className="loading" css={{ color: "#fff" }}>
                    <CircularProgress color="inherit" size={70} thickness={2} />
                </div>
            );
        } else return;
    };

    handleToggle = () => this.setState({ open: !this.state.open });
    handleClose = () => this.setState({ open: false });

    render() {
        const { isLogged, permissionLevel, classes, displayBookings, userId } = this.props;

        const isUser = [USER_TYPE_STANDARD, USER_TYPE_MANAGER, USER_TYPE_ADMIN].includes(
            permissionLevel
        );
        const isManager = [USER_TYPE_MANAGER, USER_TYPE_ADMIN].includes(permissionLevel);
        const isAdmin = [USER_TYPE_ADMIN].includes(permissionLevel);

        let usersAudiencesSection = [];
        if (isAdmin)
            usersAudiencesSection = [
                <MainMenuSubItem
                    key={1}
                    title="LSE Students"
                    to="/users-and-audiences/lse-students"
                    onClick={this.handleClose}
                />,
                <MainMenuSubItem
                    key={2}
                    title="LSE Staff"
                    to="/users-and-audiences/lse-staff"
                    onClick={this.handleClose}
                />,
            ];

        if (isManager)
            usersAudiencesSection.push(
                <MainMenuSubItem
                    key={3}
                    title="Audiences"
                    to="/users-and-audiences/audiences"
                    onClick={this.handleClose}
                />
            );

        return isLogged && permissionLevel ? (
            <div>
                <Drawer open={this.state.open} onClose={this.handleClose}>
                    <div css={sidebarCss.sidebar}>
                        <div css={sidebarCss.logo}>
                            <img src={logo} alt="LSE" css={sidebarCss.logoImg} />
                        </div>
                        <List component="nav" css={sidebarCss.menu}>
                            <MainMenuItem
                                title="Dashboard"
                                to={"/"}
                                onClick={this.handleClose}
                                icon={<DashboardIcon css={sidebarCss.menuItemIcon} />}
                            />
                            {isUser && (
                                <MainMenuItem
                                    title="Channels"
                                    onClick={this.handleClose}
                                    icon={<DeviceHubIcon css={sidebarCss.menuItemIcon} />}
                                >
                                    <MainMenuSubItem
                                        title="Pages"
                                        to={"/channels/pages"}
                                        onClick={this.handleClose}
                                    />
                                    <MainMenuSubItem
                                        title="Groups"
                                        to={"/channels/groups"}
                                        onClick={this.handleClose}
                                    />
                                    <MainMenuSubItem
                                        title="Posts"
                                        to={"/channels/posts"}
                                        onClick={this.handleClose}
                                    />
                                    <MainMenuSubItem
                                        title="Events"
                                        to={"/channels/events"}
                                        onClick={this.handleClose}
                                    />
                                </MainMenuItem>
                            )}

                            {isManager && (
                                <MainMenuItem
                                    title="Calendar &amp; Events"
                                    onClick={this.handleClose}
                                    icon={<TodayIcon css={sidebarCss.menuItemIcon} />}
                                >
                                    <MainMenuSubItem
                                        title="LSE Calendar Events"
                                        to={"/calendar/lse-calendar/events"}
                                        onClick={this.handleClose}
                                    />
                                    <MainMenuSubItem
                                        title="Calendar Banners"
                                        to={"/calendar/notification-banners"}
                                        onClick={this.handleClose}
                                    />
                                    <MainMenuSubItem
                                        title="Student Calendar Lookup"
                                        to={"/calendar/browse-events"}
                                        onClick={this.handleClose}
                                    />
                                    <MainMenuSubItem
                                        title="Add Key Academic Date"
                                        to={"/calendar/add-new-kad-event"}
                                        onClick={this.handleClose}
                                    />
                                </MainMenuItem>
                            )}

                            {((isUser && !!displayBookings) || isManager) && (
                                <MainMenuItem
                                    title="Bookings"
                                    onClick={this.handleClose}
                                    icon={<ScheduleIcon css={sidebarCss.menuItemIcon} />}
                                >
                                    {isUser && !!displayBookings && (
                                        <div>
                                            <MainMenuSubItem
                                                title="Manage Availability"
                                                to={"/bookings/availability"}
                                                onClick={this.handleClose}
                                            />
                                            <MainMenuSubItem
                                                title="Browse Sessions"
                                                to={`/bookings/browse/${userId}`}
                                                onClick={this.handleClose}
                                            />
                                            <MainMenuSubItem
                                                title="View Bookings"
                                                to={"/bookings/appointments"}
                                                onClick={this.handleClose}
                                            />
                                        </div>
                                    )}
                                    {isManager && (
                                        <MainMenuSubItem
                                            title="Manage Teams"
                                            to={"/bookings/manage-teams"}
                                            onClick={this.handleClose}
                                        />
                                    )}
                                    {isManager && (
                                        <MainMenuSubItem
                                            title="Administrators"
                                            to={"/bookings/administrators"}
                                            onClick={this.handleClose}
                                        />
                                    )}
                                    {((isUser && !!displayBookings) || isManager) && (
                                        <MainMenuSubItem
                                            title="Reports"
                                            to={"/bookings/reports"}
                                            onClick={this.handleClose}
                                        />
                                    )}
                                </MainMenuItem>
                            )}

                            {isManager && (
                                <MainMenuItem
                                    title="Campus Mapping"
                                    to="/campus-mapping"
                                    onClick={this.handleClose}
                                    icon={<PlaceIcon css={sidebarCss.menuItemIcon} />}
                                />
                            )}

                            {isManager && (
                                <MainMenuItem
                                    title="Notifications"
                                    onClick={this.handleClose}
                                    icon={<NotificationsIcon css={sidebarCss.menuItemIcon} />}
                                >
                                    <MainMenuSubItem
                                        title="Standard Notifications"
                                        to="/notifications/standard/list"
                                        onClick={this.handleClose}
                                    />
                                    <MainMenuSubItem
                                        title="Timetable Notifications"
                                        to="/notifications/timetable/list"
                                        onClick={this.handleClose}
                                    />
                                    <MainMenuSubItem
                                        title="Email Templates"
                                        to="/notifications/email-templates/list"
                                        onClick={this.handleClose}
                                    />
                                </MainMenuItem>
                            )}

                            {isManager && (
                                <MainMenuItem
                                    title="Side links"
                                    to={`${ROUTE_SIDELINKS}`}
                                    onClick={this.handleClose}
                                    icon={<InsertLinkIcon css={sidebarCss.menuItemIcon} />}
                                />
                            )}

                            {isAdmin && (
                                <MainMenuItem
                                    title="CMS Support Pages"
                                    onClick={this.handleClose}
                                    icon={<SupportPageCmsIcon css={sidebarCss.menuItemIcon} />}
                                >
                                    <MainMenuSubItem
                                        title="Overview"
                                        to="/cms-support-pages/overview"
                                        onClick={this.handleClose}
                                    />
                                    <MainMenuSubItem
                                        title="Knowledge Base"
                                        to="/knowledge-base/categories"
                                        onClick={this.handleClose}
                                    />
                                </MainMenuItem>
                            )}

                            {isManager && (
                                <MainMenuItem
                                    title="Users &amp; Audiences"
                                    hideDrawer={this.handleClose}
                                    icon={<PersonIcon css={sidebarCss.menuItemIcon} />}
                                >
                                    {usersAudiencesSection}
                                </MainMenuItem>
                            )}

                            {isAdmin && (
                                <MainMenuItem
                                    title="Settings"
                                    onClick={this.handleClose}
                                    icon={<SettingsIcon css={sidebarCss.menuItemIcon} />}
                                >
                                    <MainMenuSubItem
                                        title="Academic Years"
                                        to="/settings/school-years"
                                        onClick={this.handleClose}
                                    />
                                </MainMenuItem>
                            )}

                            <MainMenuItem
                                title="Hub Support"
                                to="/support"
                                onClick={this.handleClose}
                                icon={<ContactSupportIcon css={sidebarCss.menuItemIcon} />}
                            />
                        </List>
                        <ListItem
                            ContainerComponent="div"
                            classes={{
                                root: classes.adminRoot,
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    {this.getInitials(
                                        this.props.firstName + " " + this.props.lastName
                                    )}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={this.props.firstName + " " + this.props.lastName}
                                secondary={this.props.lseId}
                                classes={{
                                    primary: classes.adminTitlePrimary,
                                    secondary: classes.adminTitleSecondary,
                                }}
                            />
                            <ListItemSecondaryAction>
                                <Tooltip title="Logout" aria-label="Logout" placement="left">
                                    <IconButton aria-label="Logout" onClick={this.props.logout}>
                                        <LogoutIcon />
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </div>
                </Drawer>

                <AppBar
                    position="static"
                    color="default"
                    classes={{
                        root: classes.mainAppBar,
                    }}
                    // title={this.props.title}
                    // onLeftIconButtonClick={this.handleToggle}
                    // css={topbarCss.topbar}
                    // titlecss={topbarCss.heading}
                    // iconStyleLeft={topbarCss.iconLeft}
                >
                    <Toolbar>
                        <IconButton
                            classes={{
                                root: classes.mainAppBarIcon,
                            }}
                            aria-label="Menu"
                            onClick={this.handleToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            classes={{
                                root: classes.mainAppBarTitle,
                            }}
                        >
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {this.getLoadingScreen()}
            </div>
        ) : (
            <div>{this.getLoadingScreen()}</div>
        );
    }
}

const mapStateToProps = state => ({
    title: state.MainMenu.title,
    firstName: state.Auth.firstName,
    lastName: state.Auth.lastName,
    lseId: state.Auth.lseId,
    userId: state.Auth.id,
    isLoading: state.MainMenu.isLoading,
    isLogged: state.Auth.isLogged,
    permissionLevel: state.Auth.permissionLevel,
    displayBookings: !!state.Auth.hasBookings || !!state.Auth.manageBookings,
});

const dispatchToProps = {
    logout: actionCreators.logout.create,
};

export default connect(mapStateToProps, dispatchToProps)(withStyles(sidebarCss)(MainMenu));
