import actionCreators from "./actionCreators";
import group from "../../../fixtures/ChannelGroup";
import { removeProp } from "../../helpers";
import channelActionCreators from "../actionCreators";

export const initialState = {
    groups: {
        data: [],
        count: 0,
        nextPage: 0,
    },
    group,
    errorRes: { error: "", errors: {} },
    currentFormType: null,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListGroup.type:
            return {
                ...state,
                groups: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListGroup.type:
            payload.data = [
                ...state.groups.data,
                ...payload.data.filter(
                    group => !state.groups.data.map(item => item.id).includes(group.id)
                ),
            ];
            return {
                ...state,
                groups: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneGroup.type:
            return {
                ...state,
                group: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearGroup.type:
            return {
                ...state,
                group: initialState.group,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.clearErrors.type:
            return {
                ...state,
                errorRes: state.errorRes.errors[payload]
                    ? {
                          ...state.errorRes,
                          errors: removeProp(state.errorRes.errors, payload),
                      }
                    : state.errorRes,
            };
        case channelActionCreators.confirmSetChannelAsRecommended.type:
            return {
                ...state,
                group:
                    state.group.id === Number(payload)
                        ? { ...state.group, isRecommended: true }
                        : state.group,
                groups: {
                    ...state.groups,
                    data: state.groups.data.map(p =>
                        p.id === Number(payload) ? { ...p, isRecommended: true } : p
                    ),
                },
                errorRes: initialState.errorRes,
            };
        case channelActionCreators.confirmChannelRemoveFromRecommended.type:
            return {
                ...state,
                group:
                    state.group.id === Number(payload)
                        ? { ...state.group, isRecommended: false }
                        : state.group,
                groups: {
                    ...state.groups,
                    data: state.groups.data.map(p =>
                        p.id === Number(payload) ? { ...p, isRecommended: false } : p
                    ),
                },
                errorRes: initialState.errorRes,
            };
        default:
            return state;
    }
};
