import React, { Component } from "react";
import { connect } from "react-redux";

import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as audienceActionCreators } from "../../../store/UsersAndAudiences/Audiences/actionCreators";
import { MainTitle } from "../../../common/components/MainTitle";
import Form from "./components/Form";

class NewAudienceContainer extends Component {
    state = {};

    componentDidMount() {
        this.props.setTitle("Audiences");
    }

    componentWillUnmount() {
        this.props.clearTitle();
        this.props.clearOneAudience();
    }

    onSubmit = values => {
        this.props.postAudience(values);
    };

    render() {
        const {
            initialValues,
            errorRes: { error, errors },
            history: { goBack },
        } = this.props;
        return (
            <div>
                <MainTitle title="Audiences" type="[Users &amp; Audiences]" />
                <Form
                    currentFormType="NEW"
                    onSubmit={this.onSubmit}
                    {...{ initialValues, error, errors, goBack }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    clearOneAudience: audienceActionCreators.clearOneAudience.create,
    postAudience: audienceActionCreators.postAudience.create,
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
};
const mapStateToProps = ({ Audience }) => ({
    errorRes: Audience.errorRes,
    initialValues: Audience.audience,
});
export default connect(mapStateToProps, dispatchToProps)(NewAudienceContainer);
