import { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import validate from "./validate";
import { RenderAutocomplete } from "../../../../common/components/FormFields";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button } from "@material-ui/core";
import formsCss from "../../../../common/styles/forms.css";
import globalsCss from "../../../../common/styles/globals.css";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";

class Form extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };

    componentDidMount() {
        const { getUsers, clearUsers } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            if (query !== "") {
                rawUpdateListOnFilterChange({
                    getFn: getUsers,
                    filter: { filter: query },
                });
            } else clearUsers();
        });
        this.props.initialize(this.props.initialValues);
    }

    onChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () => searchText$.next(this.state.searchText));
    };

    render() {
        const { handleSubmit, students, clearUsers, errors } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="row" css={{ marginTop: -8 }}>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="users"
                            errorRes={errors.users}
                            handleInput={this.onChangeHandler}
                            dataSource={students}
                            component={RenderAutocomplete}
                            onDroppedFocus={() => clearUsers()}
                            labelCallback={item => `${item.firstName} ${item.lastName}`}
                            floatingLabelText={"Full name or email address"}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            css={[formsCss.btnSubmit, globalsCss.helpers.mt25]}
                        >
                            Add Attendees
                        </Button>
                    </div>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: "OfficeHoursAddDropInAttendee",
    enableReinitialize: true,
    validate,
})(Form);

export default form;
