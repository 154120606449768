import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import formsCss from "../../styles/forms.css";

const renderTextFieldSimple = ({ input, classes, ...custom }) => (
    <TextField
        margin="normal"
        color="primary"
        InputProps={{
            classes: {
                root: classes.inputRoot,
                error: classes.inputError,
            },
        }}
        {...input}
        {...custom}
    />
);

export const RenderTextFieldSimple = withStyles(formsCss)(renderTextFieldSimple);
