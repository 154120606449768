import { Component } from "react";
import { connect } from "react-redux";
import { TableComponent, RedirectButton } from "../../../common/components/OfficeHours";
import actionCreators from "../../../store/OfficeHours/Teams/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import Paper from "@material-ui/core/Paper";
import PromptDialog from "../../../common/components/PromptDialog";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";

import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import { EditIcon, DeleteIcon } from "../../../common/components/icons";
import { statusBooleanCallback } from "src/common/services/tableCallbacks";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            name: "Name",
            isActive: "Is Active?",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            isActive: tableCss.columnWidth150,
        },
    },
];

const callbacks = {
    isActive: statusBooleanCallback,
};

const buttons = items => index => {
    let buttons = [
        {
            path: `/bookings/manage-teams/edit/${items[index] ? items[index].id : ""}`,
            label: "Edit",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
        },
        {
            path: `${items[index] ? items[index].id : null}`,
            label: "Delete",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        },
    ];

    return buttons;
};

const columns = ["isActive", "name"];

class OfficeHoursManageTeamsListContainer extends Component {
    state = {
        removeDialog: false,
        removeId: null,
    };

    handleOpen = itemId => {
        this.setState({ removeDialog: true });
        this.setState({ removeId: itemId });
    };

    handleClose = confirmed => {
        if (confirmed === true) this.props.removeOfficeHoursTeam({ id: this.state.removeId });
        this.setState({ removeDialog: false });
        this.setState({ removeId: null });
    };

    componentDidMount() {
        const { setTitle, getListOfficeHoursTeams } = this.props;
        setTitle("Bookings \u203A Manage Teams");
        getListOfficeHoursTeams();
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    remove = itemID => {
        this.handleOpen(itemID);
    };

    render() {
        const {
            officeHoursTeams,
            history: { push },
        } = this.props;
        const { remove } = this;

        return (
            <div>
                <div css={globalsCss.actions}>
                    <RedirectButton
                        label="Add new team"
                        url="/bookings/manage-teams/new"
                        {...{ push }}
                    />
                </div>
                <Paper elevation={1}>
                    <TableComponent
                        items={officeHoursTeams}
                        fns={{ push, remove }}
                        buttons={buttons(officeHoursTeams)}
                        decorators={decorators}
                        columns={columns}
                        callbacks={callbacks}
                    />
                    <PromptDialog open={this.state.removeDialog} handleClose={this.handleClose}>
                        Are you sure you want to delete this team?
                    </PromptDialog>
                </Paper>
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getListOfficeHoursTeams: actionCreators.getListOfficeHoursTeams.create,
    removeOfficeHoursTeam: actionCreators.removeOfficeHoursTeam.create,
};
const mapStateToProps = ({ OfficeHoursTeams }) => ({
    officeHoursTeams: OfficeHoursTeams.teams,
});
export default connect(mapStateToProps, dispatchToProps)(OfficeHoursManageTeamsListContainer);
