import { Component, Fragment } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import actionCreators from "../../store/CmsSupport/actionCreators";
import {
    ContentContainer,
    Header,
    MainCategory,
    Row,
    Col,
    Breadcrumbs,
    BreadcrumbsItem,
    BreadcrumbsDivider,
    Footer,
} from "../common/UIElements";
import Wysiwyg from "../../common/components/Wysiwyg";
import { knowledgeBaseStyle } from "./KnowledgeBaseStyles";

class KnowledgeBase extends Component {
    componentDidMount() {
        const { getHomepage, getKnowledgeBaseCategories } = this.props;
        getHomepage();
        getKnowledgeBaseCategories();
    }

    renderCategories() {
        const { knowledgebaseCategories } = this.props;

        if (knowledgebaseCategories === null) return null;

        return (
            <Row>
                {knowledgebaseCategories.map(category => (
                    <Col key={`${category.id}Col`}>
                        <MainCategory
                            to={`/support/knowledge-base/${category.id}/`}
                            title={category.name}
                            text={category.description}
                            key={category.id}
                        />
                    </Col>
                ))}
            </Row>
        );
    }

    render() {
        const { homepage } = this.props;

        return (
            <Fragment>
                <Header />
                <ContentContainer>
                    <div css={knowledgeBaseStyle.contentWiderContainer}>
                        <Breadcrumbs>
                            <BreadcrumbsItem to="/support">Home</BreadcrumbsItem>
                            <BreadcrumbsDivider />
                            <BreadcrumbsItem>Knowledge Base</BreadcrumbsItem>
                        </Breadcrumbs>
                        <div css={knowledgeBaseStyle.intro}>
                            {homepage && homepage.howToTeaser && (
                                <Wysiwyg html={homepage.howToTeaser} />
                            )}
                        </div>
                        {this.renderCategories()}
                    </div>
                    <Footer />
                </ContentContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    homepage: state.CmsSupport.homepage,
    knowledgebaseCategories: state.CmsSupport.knowledgebaseCategories,
});

const dispatchToProps = {
    getHomepage: actionCreators.getHomepage.create,
    getKnowledgeBaseCategories: actionCreators.getKnowledgeBaseCategories.create,
};

export default connect(mapStateToProps, dispatchToProps)(KnowledgeBase);
