import { Component, Fragment } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Paper from "@material-ui/core/Paper";
import { Subject } from "rxjs";

import tableCss from "../../../common/styles/table.css";

import { default as calendarActionCreators } from "../../../store/CalendarAndEvents/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import CalendarFilterRow from "./List/CalendarFilterRow";
import { TableComponent } from "../../../common/components/CalendarAndEvents";
import { MainTitle } from "../../../common/components/MainTitle";

import { rawUpdateListOnFilterChange } from "../../../common/services/FilterList";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import {
    dateTimeFormatCallback,
    dateFormatCallback,
    calendarCategoryCallback,
} from "../../../common/services/tableCallbacks";
import PromptDialog from "../../../common/components/PromptDialog";
import { PreviewIcon, DeleteIcon } from "../../../common/components/icons";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

const buttons = (subject, items) => index => {
    const buttons = [
        {
            path: `/calendar/browse-events/${subject.id}/${items[index].calendarType}/${items[index].uid}`,
            label: "Details",
            fnLabel: "push",
            icon: <PreviewIcon css={tableCss.actionsMenuItemIcon} />,
        },
    ];

    if (items[index].source === "Custom event") {
        buttons.push({
            path: `${items[index] ? items[index].uid : null}`,
            label: "Delete",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        });
    }

    return buttons;
};

const decorators = [
    // none (?)
];

const callbacks = {
    // eslint-disable-next-line react/display-name
    Event: item => (
        <Fragment>
            <strong className="eventItem">{item.name}</strong>
            <span className="eventItemDate">
                {item.isAllDay
                    ? dateFormatCallback(item, "startAt")
                    : dateTimeFormatCallback(item, "startAt")}
            </span>
        </Fragment>
    ),
    // eslint-disable-next-line react/display-name
    Calendar: item => (
        <Fragment>
            <span className="calendarItem">{calendarCategoryCallback(item, "calendarType")}</span>
            {item.source && (
                <span className="calendarSourceItem">
                    [<strong>Source:</strong> {item.source}]
                </span>
            )}
        </Fragment>
    ),
    Invitees: item => {
        const results = [];
        for (let key in ["invited", "confirmed", "registered"]) {
            if (item[key]) {
                results.push(
                    <span key={key} className="audienceItem">
                        {item[key]} {key}
                    </span>
                );
            }
        }
        return results.length > 0 ? results : "n/a";
    },
};

const columns = ["Event", "Calendar", "location", "Invitees"];

class CalendarEventListContainer extends Component {
    state = {
        filter: "",
        from: "",
        to: "",
        filter$: new Subject(),
        removeDialogOpen: false,
        removeId: null,
    };

    componentDidMount() {
        const { filter$ } = this.state;
        const {
            setTitle,
            getListEvents,
            subject,
            getCalendarSubject,
            match,
            selectCalendarSubject,
        } = this.props;
        if (subject.id !== parseInt(match.params.user, 10)) {
            selectCalendarSubject({ id: null });
        }
        if (subject.id === null) {
            getCalendarSubject(match.params.user);
        } else {
            setTitle("Calendar & Events \u203A Student Calendar Lookup");
            getListEvents({ id: subject.id });
            filter$.pipe(debounceTime(200), distinctUntilChanged()).subscribe(filter => {
                rawUpdateListOnFilterChange({
                    getFn: getListEvents,
                    filter: { id: subject.id, query: filter },
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.subject.id !== this.props.subject.id) {
            const { filter$ } = this.state;
            if (this.props.subject.id) {
                this.props.getListEvents({ id: this.props.subject.id });
                filter$.pipe(debounceTime(200), distinctUntilChanged()).subscribe(filter => {
                    rawUpdateListOnFilterChange({
                        getFn: this.props.getListEvents,
                        filter: {
                            id: this.props.subject.id,
                            query: filter,
                        },
                    });
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.clearTitle();
        this.props.clearListEvents();
    }

    handler = ({ field, value }) => {
        const { filter$ } = this.state;
        this.setState({ [field]: value }, () =>
            filter$.next({
                calendarType: this.state.filter ? this.state.filter : undefined,
                from: this.state.from ? this.state.from.toISOString() : undefined,
                to: this.state.to ? this.state.to.toISOString() : undefined,
            })
        );
    };

    onFilterChangeHandler = field => ({ target: { value } }) => {
        this.handler({ field, value });
    };

    onDateChangeHandler = (field, newValue = undefined) => value => {
        this.handler({ field, value: newValue !== undefined ? newValue : value });
    };

    /* dialog */
    handleOpen = itemId => {
        this.setState({
            removeDialogOpen: true,
            removeId: itemId,
        });
    };

    handleClose = confirmed => {
        if (confirmed === true) {
            this.props.deleteEvent({ id: this.state.removeId, userId: this.props.subject.id });
        }

        this.setState({
            removeDialogOpen: false,
            removeId: null,
        });
    };

    remove = itemID => {
        this.handleOpen(itemID);
    };

    render() {
        const {
            subject,
            events,
            history: { push },
        } = this.props;
        const { filter, from, to } = this.state;
        const { onFilterChangeHandler, onDateChangeHandler, remove } = this;
        return (
            subject.id && (
                <div>
                    <MainTitle
                        title={`${this.props.subject.firstName} ${this.props.subject.lastName}`}
                        type="[Calendar Events]"
                    />
                    <div>
                        <Paper elevation={1}>
                            <CalendarFilterRow
                                {...{
                                    from,
                                    to,
                                    filter,
                                    onFilterChangeHandler,
                                    onDateChangeHandler,
                                }}
                            />
                            <TableComponent
                                items={events.data}
                                fns={{ push, remove }}
                                buttons={buttons(subject, events.data)}
                                callbacks={callbacks}
                                decorators={decorators}
                                columns={columns}
                                config={{ key: "uid" }}
                            />
                            <PromptDialog
                                open={this.state.removeDialogOpen}
                                handleClose={this.handleClose}
                            >
                                Are you sure you want to delete this event?
                            </PromptDialog>
                        </Paper>
                    </div>
                </div>
            )
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getCalendarSubject: calendarActionCreators.getCalendarSubject.create,
    getListEvents: calendarActionCreators.getListEvents.create,
    clearListEvents: calendarActionCreators.clearListEvents.create,
    selectCalendarSubject: calendarActionCreators.selectCalendarSubject.create,
    deleteEvent: calendarActionCreators.deleteEvent.create,
};

const mapStateToProps = state => ({
    subject: state.CalendarEvents.subject,
    events: state.CalendarEvents.events,
});

export default connect(mapStateToProps, dispatchToProps)(CalendarEventListContainer);
