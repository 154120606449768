const spacingFactor = 8;

export const spacing = {
    space0comma5: spacingFactor / 4, // 2
    space0: spacingFactor / 2, // 4
    space1: spacingFactor, // 8
    space1comma5: spacingFactor * 1.5, // 12  <3
    space2: spacingFactor * 2, // 16
    space2comma5: spacingFactor * 2.5, // 20
    space3: spacingFactor * 3, // 24
    space4: spacingFactor * 4, // 32
    space5: spacingFactor * 5, // 40
    space6: spacingFactor * 6, // 48
    space7: spacingFactor * 7, // 56
    space8: spacingFactor * 8, // 64
    space9: spacingFactor * 9, // 72
    space10: spacingFactor * 10, // 80
    space11: spacingFactor * 11, // 88
    space12: spacingFactor * 12, // 96
    space13: spacingFactor * 13, // 104
    space14: spacingFactor * 14, // 112
    space15: spacingFactor * 15, // 120
};
