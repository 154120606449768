import React from "react";
import moment from "moment-timezone";
import { FormatTime } from "../components/Time";

export const convertUtc2London = value => moment.utc(value).tz("Europe/London");

export const convertLondon2Utc = value => (value === null ? null : moment(value).tz("UTC"));

export const convertFromUTCDate = value => {
    if (!value) return null;
    const diff = moment().utcOffset();
    const newValue = moment(value).subtract(diff, "minutes");
    return newValue.toDate();
};

export const convertToUTCDate = value =>
    value ? moment.utc(convertUtc2London(value).format("dddd D MMMM YYYY")).toDate() : null;

export const convertToDate = value => (value ? moment(value).toDate() : null);

export default post =>
    Object.entries({
        ...post,
        publicationDate:
            post.publicationDate !== undefined ? (
                post.publicationDate !== null ? (
                    new Date(post.publicationDate) > new Date() ? (
                        <em>
                            <FormatTime
                                date={new Date(post.publicationDate)}
                                format="D MMM YYYY h.mma"
                            />{" "}
                            (scheduled)
                        </em>
                    ) : (
                        <FormatTime
                            date={new Date(post.publicationDate)}
                            format="D MMM YYYY h.mma"
                        />
                    )
                ) : (
                    "n/a"
                )
            ) : (
                undefined
            ),
        startAt:
            post.startAt !== undefined ? (
                post.startAt !== null ? (
                    <FormatTime date={new Date(post.startAt)} format="D MMM YYYY h.mma" />
                ) : (
                    "n/a"
                )
            ) : (
                undefined
            ),
        endAt:
            post.endAt !== undefined ? (
                post.endAt !== null ? (
                    <FormatTime date={new Date(post.endAt)} format="D MMM YYYY h.mma" />
                ) : (
                    "n/a"
                )
            ) : (
                undefined
            ),
        scheduledAt:
            post.scheduledAt !== undefined ? (
                post.scheduledAt !== null ? (
                    <FormatTime date={new Date(post.scheduledAt)} format="D MMM YYYY h.mma" />
                ) : (
                    "n/a"
                )
            ) : (
                undefined
            ),
    })
        .filter(prop => prop[1] !== undefined)
        .reduce((obj, arr) => {
            obj[arr[0]] = arr[1];
            return obj;
        }, {});
