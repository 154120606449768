// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { MainTitle } from "../../../common/components/MainTitle";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import Form from "./Form";
import { Dialog, Paper, Button, Typography } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";
import { composeAvailabilityRequest, isSafeUpdate } from "./Form/composeRequest.js";
import PromptDialog from "../../../common/components/PromptDialog";
import MessageForm from "./MessageForm";
import formsCss from "../../../common/styles/forms.css";
import { GetFormattedDate } from "../../../common/components/Time";

class OfficeHoursEditAvailabilityContainer extends Component {
    state = {
        request: false,
        removeDialogReason: false,
        reasonMsg: "",
        displayCancellationSlots: false,
    };

    componentDidMount() {
        const {
            setTitle,
            getOneMyOfficeHoursAcademic,
            getOneMyOfficeHoursAvailability,
            match,
            academic,
        } = this.props;
        setTitle("Bookings \u203A Manage Availability");
        if (!academic) getOneMyOfficeHoursAcademic(match.params.userId);
        getOneMyOfficeHoursAvailability(match.params.id);
    }

    componentDidUpdate(prevProps) {
        const { slotsForCancellation } = this.props;
        if (
            slotsForCancellation !== prevProps.slotsForCancellation &&
            prevProps.slotsForCancellation === null
        ) {
            if (slotsForCancellation.length > 0) {
                // show cancellation popup
                this.props.clearLoading();
                this.setState({ removeDialogReason: true });
            } else {
                // hide popup - hide popup with empty cancellation msg
                this.onCancellationMessageProvided("");
            }
        }
    }

    componentWillUnmount() {
        const { clearOneMyOfficeHoursAvailability, clearTitle } = this.props;
        clearTitle();
        clearOneMyOfficeHoursAvailability();
    }

    onSubmit = values => {
        const {
            safePutMyOfficeHoursAvailability,
            getSlotsForCancellation,
            initialValues,
            academic,
        } = this.props;

        const request = composeAvailabilityRequest(values);

        if (isSafeUpdate(initialValues, values)) {
            safePutMyOfficeHoursAvailability({
                id: initialValues.id,
                availability: request,
                academicId: academic.id,
                isAcademicContext: true,
            });
        } else {
            getSlotsForCancellation({
                id: initialValues.id,
                availability: request,
            });
            this.setState({ request: request });
        }
    };

    handleForceSubmit = confirmed => {
        const {
            putMyOfficeHoursAvailability,
            academic,
            initialValues,
            hideConfirmForceRequest,
        } = this.props;
        const { request, reasonMsg } = this.state;
        if (confirmed) {
            putMyOfficeHoursAvailability({
                id: initialValues.id,
                availability: { ...request, reason: reasonMsg },
                action: "force",
                academicId: academic.id,
                isAcademicContext: true,
            });
        } else hideConfirmForceRequest();
    };

    onCancellationMessageProvided = values => {
        const { putMyOfficeHoursAvailability, initialValues, academic } = this.props;
        const { request } = this.state;

        this.setState(
            {
                removeDialogReason: false,
                displayCancellationSlots: false,
                reasonMsg: values.message,
            },
            () => {
                putMyOfficeHoursAvailability({
                    id: initialValues.id,
                    availability: { ...request, reason: values.message },
                    action: "validate",
                    academicId: academic.id,
                });
            }
        );
    };

    render() {
        const {
            history: { push },
            academic,
            initialValues,
            confirmForceRequest,
            slotsForCancellation,
            errorRes: { error, errors },
        } = this.props;
        const { onSubmit } = this;
        const { displayCancellationSlots } = this.state;
        const currentFormType = CurrentFormTypes.EDIT;

        return academic ? (
            <div>
                <MainTitle
                    title={`${academic.firstName} ${academic.lastName}`}
                    type={"[Availability]"}
                />
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Edit Availability</h2>
                        <Form
                            {...{
                                initialValues,
                                onSubmit,
                                currentFormType,
                                push,
                                errors,
                                formError: error,
                                academicId: academic.id,
                            }}
                        />
                    </div>
                </Paper>

                <PromptDialog
                    open={confirmForceRequest === 1}
                    handleClose={this.handleForceSubmit}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "You are changing your online meeting settings, which affects existing booked appointments. Students who have already booked will receive email updates with the updated information. "
                    }
                </PromptDialog>

                <PromptDialog
                    open={confirmForceRequest === 2}
                    handleClose={this.handleForceSubmit}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "Sessions resulting in this availability rule overlaps with some other existing sessions. Do you want to go ahead and create only those sessions that don’t cause any conflicts?"
                    }
                </PromptDialog>

                <PromptDialog
                    open={confirmForceRequest === 3}
                    handleClose={() => this.props.showConfirmForceRequest(1)}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "Sessions resulting in this availability rule overlaps with some other existing sessions. Do you want to go ahead and create only those sessions that don’t cause any conflicts?"
                    }
                </PromptDialog>

                <Dialog
                    open={this.state.removeDialogReason}
                    onClose={() => {
                        this.setState({ removeDialogReason: false }, () =>
                            this.props.clearSlotsForCancellation()
                        );
                    }}
                    maxWidth="sm"
                    fullWidth
                >
                    {!!slotsForCancellation && (
                        <div css={globalsCss.innerDialog}>
                            <h2 css={globalsCss.subtitleTitle}>
                                Some of your existing appointments will be cancelled
                            </h2>
                            <p>
                                Saving this change will cancel{" "}
                                <strong>{slotsForCancellation.length} </strong> existing{" "}
                                {`appointment${slotsForCancellation.length > 1 ? "s" : ""}`}. Are
                                you sure?
                            </p>

                            {displayCancellationSlots ? (
                                <div>
                                    <Button
                                        color="secondary"
                                        onClick={() =>
                                            this.setState({ displayCancellationSlots: false })
                                        }
                                    >
                                        Hide{" "}
                                        {`appointment${slotsForCancellation.length > 1 ? "s" : ""}`}
                                    </Button>
                                    <div style={globalsCss.table}>
                                        <div style={globalsCss.tableHeaderItem}>
                                            <Typography
                                                component="div"
                                                variant="subtitle1"
                                                style={globalsCss.tableItemText}
                                            >
                                                <strong>Date &amp; Time</strong>
                                            </Typography>
                                            <Typography
                                                component="div"
                                                variant="subtitle1"
                                                style={globalsCss.tableItemText}
                                            >
                                                <strong>Student</strong>
                                            </Typography>
                                        </div>
                                        {slotsForCancellation.map((slot, index) => (
                                            <div key={`slot-${index}`} style={globalsCss.tableItem}>
                                                <Typography
                                                    component="div"
                                                    variant="subtitle1"
                                                    style={globalsCss.tableItemText}
                                                >
                                                    {`${GetFormattedDate(
                                                        slot.start
                                                    )} ${GetFormattedDate(slot.start, "h.mma")}`}
                                                </Typography>
                                                <Typography
                                                    component="div"
                                                    variant="subtitle1"
                                                    style={globalsCss.tableItemText}
                                                >
                                                    {slot.student}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <Button
                                    color="secondary"
                                    onClick={() =>
                                        this.setState({ displayCancellationSlots: true })
                                    }
                                >
                                    Show{" "}
                                    {`appointment${slotsForCancellation.length > 1 ? "s" : ""}`}
                                </Button>
                            )}

                            <p>
                                Please add a note so that students know why their appointments have
                                been cancelled. They will receive an email with your explanation.
                            </p>
                            <p>
                                Alternatively, please contact{" "}
                                <a href="mailto:studenthub@lse.ac.uk">studenthub@lse.ac.uk</a> if
                                you require any support.
                            </p>
                            {error && <div css={formsCss.genericError}>{error}</div>}
                            <MessageForm
                                {...{
                                    onSubmit: this.onCancellationMessageProvided,
                                    push,
                                    errors: {
                                        ...errors,
                                        ...(errors.reason ? { message: errors.reason } : {}),
                                    },
                                    fieldLabel: "Reason for cancellation",
                                    buttonLabel: "Confirm",
                                }}
                            />
                        </div>
                    )}
                </Dialog>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneMyOfficeHoursAcademic: actionCreators.getOneMyOfficeHoursAcademic.create,
    putMyOfficeHoursAvailability: actionCreators.putMyOfficeHoursAvailability.create,
    safePutMyOfficeHoursAvailability: actionCreators.safePutMyOfficeHoursAvailability.create,
    getOneMyOfficeHoursAvailability: actionCreators.getOneMyOfficeHoursAvailability.create,
    clearOneMyOfficeHoursAvailability: actionCreators.clearOneMyOfficeHoursAvailability.create,
    hideConfirmForceRequest: actionCreators.hideConfirmForceRequest.create,
    getSlotsForCancellation: actionCreators.getSlotsForCancellation.create,
    clearSlotsForCancellation: actionCreators.clearSlotsForCancellation.create,
    clearLoading: mainMenuActionCreators.clearLoading.create,
    showConfirmForceRequest: actionCreators.showConfirmForceRequest.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities }) => ({
    academic: OfficeHoursAvailabilities.academic,
    errorRes: OfficeHoursAvailabilities.errorRes,
    initialValues: OfficeHoursAvailabilities.availability,
    confirmForceRequest: OfficeHoursAvailabilities.confirmForceRequest,
    slotsForCancellation: OfficeHoursAvailabilities.slotsForCancellation,
});

export default connect(mapStateToProps, dispatchToProps)(OfficeHoursEditAvailabilityContainer);
