import ActionCreator from "../actionCreator";

export default {
    // standard
    getStandardNotifications: ActionCreator("GET_STANDARD_NOTIFICATIONS"),
    loadMoreStandardNotifications: ActionCreator("LOAD_MORE_STANDARD_NOTIFICATIONS"),
    getStandardNotification: ActionCreator("GET_STANDARD_NOTIFICATION"),
    postStandardNotification: ActionCreator("POST_STANDARD_NOTIFICATION"),
    putStandardNotification: ActionCreator("PUT_STANDARD_NOTIFICATION"),
    deleteStandardNotification: ActionCreator("DELETE_STANDARD_NOTIFICATION"),

    // timetable
    getTimetableNotifications: ActionCreator("GET_TIMETABLE_NOTIFICATIONS"),
    loadMoreTimetableNotifications: ActionCreator("LOAD_MORE_TIMETABLE_NOTIFICATIONS"),
    getTimetableNotification: ActionCreator("GET_TIMETABLE_NOTIFICATION"),
    postTimetableNotification: ActionCreator("POST_NOTIFICATION_TIMETABLE"),
    putTimetableNotification: ActionCreator("PUT_NOTIFICATION_TIMETABLE"),
    deleteTimetabledNotification: ActionCreator("DELETE_TIMETABLE_NOTIFICATION"),

    getNotificationEmailPreview: ActionCreator("NOTIFICATION_GET_EMAIL_PREVIEW"),
    setNotificationEmailPreview: ActionCreator("NOTIFICATION_SET_EMAIL_PREVIEW"),
    getLseModuleGroups: ActionCreator("NOTIFICATION_GET_LSE_GROUPS"),
    setLseModuleGroups: ActionCreator("NOTIFICATION_SET_LSE_GROUPS"),
    getSelectedTeachingsStudents: ActionCreator("NOTIFICATION_GET_SELECTED_TEACHINGS_STUDENTS"),
    updateSelectedTeachingsStudents: ActionCreator(
        "NOTIFICATION_UPDATE_SELECTED_TEACHINGS_STUDENTS"
    ),
    clearSelectedTeachingsStudents: ActionCreator("NOTIFICATION_CLEAR_SELECTED_TEACHINGS_STUDENTS"),

    // shared
    updateNotifications: ActionCreator("UPDATE_NOTIFICATIONS"),
    updateMoreNotifications: ActionCreator("UPDATE_MORE_NOTIFICATIONS"),
    setNotification: ActionCreator("UPDATE_NOTIFICATION"),
    updateDeletedNotification: ActionCreator("UPDATE_DELETED_NOTIFICATION"),
    getNotificationEmails: ActionCreator("GET_NOTIFICATION_EMAILS"),
    updateNotificationEmails: ActionCreator("UPDATE_NOTIFICATION_EMAILS"),
    clearNotification: ActionCreator("CLEAR_NOTIFICATION"),

    // templates
    getTemplateList: ActionCreator("NOTIFICATION_GET_TEMPLATE_LIST"),
    setTemplateList: ActionCreator("NOTIFICATION_SET_TEMPLATE_LIST"),
    loadMoreTemplates: ActionCreator("NOTIFICATION_LOAD_MORE_TEMPLATES"),
    updateTemplateList: ActionCreator("NOTIFICATION_UPDATE_TEMPLATES"),
    postTemplate: ActionCreator("NOTIFICATION_POST_TEMPLATE"),
    getTemplate: ActionCreator("NOTIFICATION_GET_TEMPLATE"),
    setTemplate: ActionCreator("NOTIFICATION_SET_TEMPLATE"),
    putTemplate: ActionCreator("NOTIFICATION_PUT_TEMPLATE"),
    removeTemplate: ActionCreator("NOTIFICATION_REMOVE_TEMPLATE"),
    postRemoveTemplate: ActionCreator("NOTIFICATION_POST_REMOVE_TEMPLATE"),

    // otehr
    errorResponse: ActionCreator("ERROR_RESPONSE_NOTIFICATION"),
    clearErrors: ActionCreator("CLEAR_ERROR_NOTIFICATION"),
};
