import { createStyles, IconButton, withStyles } from "@material-ui/core";

import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import { default as NotificationActions } from "../../store/Snackbar/actionCreators";
import { CloseIcon } from "./icons";

const styles = () =>
    createStyles({
        iconClose: {
            fontSize: 20,
        },
    });

class Notifier extends Component {
    displayed = [];

    storeDisplayed = id => {
        this.displayed = [...this.displayed, id];
    };

    shouldComponentUpdate({ notifications: newSnacks = [] }) {
        if (!newSnacks.length) {
            this.displayed = [];
            return false;
        }

        const { notifications: currentSnacks } = this.props;
        let notExists = false;
        for (let i = 0; i < newSnacks.length; i += 1) {
            const newSnack = newSnacks[i];
            if (newSnack.dismissed) {
                this.props.closeSnackbar(newSnack.key);
                this.props.removeSnackbar(newSnack.key);
            }

            if (notExists) continue;
            notExists =
                notExists || !currentSnacks.filter(({ key }) => newSnack.key === key).length;
        }
        return notExists;
    }

    componentDidUpdate() {
        const { notifications = [] } = this.props;

        notifications.forEach(({ key, message, variant, dismissed, ...options }) => {
            // Do nothing if snackbar is already displayed
            if (this.displayed.includes(key)) return;
            // Display snackbar using notistack
            const autoHideDuration = variant === "error" ? undefined : 2000;

            this.props.enqueueSnackbar(message, {
                key,
                variant,
                autoHideDuration,
                ...options,
                action: () => (
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => this.props.dismissSnackbar(key)}
                    >
                        <CloseIcon />
                    </IconButton>
                ),
                onClose: () => {
                    // Dispatch action to remove snackbar from redux store
                    this.props.removeSnackbar(key);
                },
            });
            // Keep track of snackbars that we've displayed
            this.storeDisplayed(key);
        });
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
    notifications: state.Snackbar,
});

const mapDispatchToProps = {
    removeSnackbar: NotificationActions.removeSnackbar.create,
    dismissSnackbar: NotificationActions.dismissSnackbar.create,
};

export default withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Notifier))
);
