import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../store/Discussion/actionCreators";
import Comment from "./Comment";
import commentsCss from "../../common/styles/comments.css";
import Button from "@material-ui/core/Button";
import previewCss from "../../common/styles/preview.css";
import { spacing } from "../../common/styles/spacing";
import formsCss from "../../common/styles/forms.css";
import { AutoRenewIcon } from "../../common/components/icons";

class CommentListContainer extends Component {
    componentDidMount() {
        const {
            getComments,
            page,
            match: {
                params: { id },
            },
        } = this.props;
        getComments({ page, id });
    }

    componentWillUnmount() {
        this.props.clearComments();
    }

    onLoadMore = () => {
        const {
            getComments,
            page,
            match: {
                params: { id },
            },
        } = this.props;
        getComments({ page, id });
    };

    render() {
        const {
            comments,
            hasMore,
            match: {
                params: { id },
            },
        } = this.props;

        return comments && comments.length > 0 ? (
            <div css={{ marginTop: spacing.space3 }}>
                <div css={previewCss.paper}>
                    {comments.map(comment => {
                        return <Comment key={comment.id} postId={id} {...{ comment }} />;
                    })}

                    {hasMore && (
                        <div css={commentsCss.loadMore}>
                            <Button onClick={this.onLoadMore} variant="contained" color="secondary">
                                <AutoRenewIcon css={formsCss.btnIcon} />
                                Load more
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        ) : null;
    }
}

const mapStateToProps = ({ Discussion: { comments, page, hasMore, postId } }) => ({
    comments,
    hasMore,
    page,
    postId,
});

const mapDispatchToProps = {
    getComments: actionCreators.getComments.create,
    clearComments: actionCreators.clearComments.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentListContainer);
