import React, { FC, useEffect, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { Paper, Button, Chip } from "@material-ui/core";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Notification/actionCreators";
import mainMenuActionCreators from "../../../store/MainMenu/actionCreators";
import { MainTitle } from "../../../common/components/MainTitle";
import globalsCss from "../../../common/styles/globals.css";
import formsCss from "../../../common/styles/forms.css";
import { NotificationPreview } from "../components/NotificationPreview";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import { DateTimeFull } from "../../../common/components/Time";
import { StandardNotification } from "../types/Notification";

interface MatchParams {
    id?: string;
}

const PreviewStandardNotification: FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
    const id = match.params.id;
    const { push } = history;
    const dispatch = useDispatch();

    const notificationsEmails = useSelector(
        (state: AppState) => state.Notification.notificationsEmails
    );
    const notification: StandardNotification = useSelector(
        (state: AppState) => state.Notification.notification as StandardNotification
    );
    const { error } = useSelector((state: AppState) => state.Notification.errorRes);

    useEffect(() => {
        dispatch(mainMenuActionCreators.setTitle.create("Notifications"));
        const id = match.params.id;

        if (id) dispatch(actionCreators.getStandardNotification.create(id));

        return () => {
            dispatch(mainMenuActionCreators.clearTitle.create());
        };
    }, []);

    useEffect(() => {
        if (!notificationsEmails) dispatch(actionCreators.getNotificationEmails.create());
    }, [notificationsEmails]);

    return (
        <div>
            <MainTitle title="Notification Details" type={"[Standard]"} />

            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    {notification && notification.id && (
                        <div>
                            {error && <div css={formsCss.genericError}>{error}</div>}
                            <Fragment>
                                <p>
                                    Status: <strong>{notification.status || "draft"}</strong>
                                </p>
                                {notification.finishedAt ? (
                                    <p>
                                        Sent date:{" "}
                                        <strong>
                                            <DateTimeFull date={notification.finishedAt} />
                                        </strong>
                                    </p>
                                ) : (
                                    notification.scheduledAt && (
                                        <p>
                                            Scheduled at:{" "}
                                            <strong>
                                                <DateTimeFull date={notification.scheduledAt} />
                                            </strong>
                                        </p>
                                    )
                                )}
                            </Fragment>
                            <hr />

                            <div css={globalsCss.helpers.mt35}>
                                <Fragment>
                                    {notification.audience.pages &&
                                        notification.audience.pages.length > 0 && (
                                            <div css={{ margin: "15px 0" }}>
                                                <strong>Page Channels&apos; Followers:</strong>
                                                <div css={globalsCss.chipsContainer}>
                                                    {notification.audience.pages &&
                                                        notification.audience.pages.map(item => (
                                                            <Chip
                                                                key={item.id}
                                                                css={globalsCss.chipsItem}
                                                                label={item.name}
                                                            />
                                                        ))}
                                                </div>
                                            </div>
                                        )}
                                    {notification.audience.students &&
                                        notification.audience.students.length > 0 && (
                                            <div css={{ margin: "15px 0" }}>
                                                <strong>LSE Student(s):</strong>
                                                <div css={globalsCss.chipsContainer}>
                                                    {notification.audience.students &&
                                                        notification.audience.students.map(item => (
                                                            <Chip
                                                                key={item.id}
                                                                css={globalsCss.chipsItem}
                                                                label={`${item.firstName} ${item.lastName}`}
                                                            />
                                                        ))}
                                                </div>
                                            </div>
                                        )}
                                    {notification.audience.audiences &&
                                        notification.audience.audiences.length > 0 && (
                                            <div css={{ margin: "15px 0" }}>
                                                <strong>Custom Audience:</strong>
                                                <div css={globalsCss.chipsContainer}>
                                                    {notification.audience.audiences &&
                                                        notification.audience.audiences.map(
                                                            item => (
                                                                <Chip
                                                                    key={item.id}
                                                                    css={globalsCss.chipsItem}
                                                                    label={item.name}
                                                                />
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    {notification.audience["students-broadcast"] && (
                                        <div css={{ margin: "15px 0" }}>
                                            <strong>ALL Students:</strong>
                                        </div>
                                    )}
                                </Fragment>
                            </div>

                            <hr />

                            <div css={globalsCss.helpers.mt35}>
                                <NotificationPreview
                                    notification={notification}
                                    notificationsEmails={notificationsEmails}
                                />
                            </div>
                        </div>
                    )}

                    <div css={formsCss.actions}>
                        <Button
                            onClick={() => push("/notifications/standard/list")}
                            css={formsCss.btnBack}
                        >
                            Back to the list
                        </Button>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default PreviewStandardNotification;
