import actionCreators from "./actionCreators";

const initialState = {
    departments: [],
    selectedDepartment: null,
    programmes: [],
    yearGroups: [],
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.setOfficeReportDepartmentList.type:
            return { ...state, departments: payload };

        case actionCreators.setOfficeReportProgrammeList.type:
            return {
                ...state,
                selectedDepartment: payload.department,
                programmes: payload.programmes,
            };
        case actionCreators.setOfficeReportYearGroupList.type:
            return { ...state, yearGroups: payload.yearGroup };
        case actionCreators.clearErrorResponse.type:
            return {
                ...state,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
