export default values => {
    let errors = {};

    if (!values.lseDepartmentId) {
        errors = { lseDepartmentId: "Required" };
    } else {
        if (values.audienceType === "byProgramme" && !values.lseProgrammes) {
            errors = { lseProgrammes: "Required" };
        } else if (values.audienceType === "byStatus" && !values.graduation) {
            errors = { graduation: "Required" };
        }
    }

    return errors;
};
