export default values => {
    const errors = {};

    if (
        !values["academic"] ||
        (values["academic"] && Object.keys(values["academic"]).length === 0)
    ) {
        errors["academic"] = "Required";
    }

    if (!values["admins"] || (values["admins"] && values["admins"].length === 0)) {
        errors["admins"] = "Required";
    }

    return errors;
};
