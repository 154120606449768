import { Component } from "react";
import { reduxForm, Field } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button, Paper } from "@material-ui/core";
import {
    RenderTextField,
    RenderTextDescription,
    RenderDatepicker,
    RenderTimepicker,
    RenderCheckboxField,
} from "../../../../common/components/FormFields";
import globalsCss from "../../../../common/styles/globals.css";
import formsCss from "../../../../common/styles/forms.css";
import validate from "./validate";
import { Subject } from "rxjs";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";
import CalendarEvent from "../../../../fixtures/CalendarEvent";
import { RenderAutocomplete } from "../../../../common/components/FormFields";
import { SubTitle } from "../../../../common/components/SubTitle";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import moment from "moment-timezone";

class EventForm extends Component {
    state = {
        subject$: {},
    };

    componentDidMount() {
        const keys = Object.keys(CalendarEvent.audience);
        const reducer = generator => (accumulator, currentValue) => {
            accumulator[currentValue] = generator();
            return accumulator;
        };
        this.setState(
            {
                subject$: keys.reduce(
                    reducer(() => new Subject()),
                    {}
                ),
            },
            () => {
                const {
                    subject$: { students, pages, audiences },
                } = this.state;
                const { getStudentUsers, getPages, getAudiences } = this.props;
                students.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                    rawUpdateListOnFilterChange({
                        getFn: getStudentUsers,
                        filter: term,
                    });
                });
                pages.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                    rawUpdateListOnFilterChange({
                        getFn: getPages,
                        filter: term,
                    });
                });
                audiences.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                    rawUpdateListOnFilterChange({
                        getFn: getAudiences,
                        filter: term,
                    });
                });
            }
        );
        this.props.initialize(this.props.initialValues);
    }

    updateSearchText(value, stateKey) {
        const { subject$ } = this.state;
        const filter = { filter: value };
        if (stateKey !== "students") {
            filter.withFollowers = "yes";
        }

        return subject$[stateKey].next(filter);
    }

    inputChangeHandler = stateKey => searchText => this.updateSearchText(searchText, stateKey);

    onChangeStartAt = (event, newValue) => {
        const { formState, changeEndAt } = this.props;
        if (formState && formState.values) {
            if (!formState.values.endAt || formState.values.endAt <= newValue) {
                // update endAt date
                const endAt = moment(newValue);
                endAt.set("hour", endAt.get("hour") + 1);
                changeEndAt(endAt.utc().format());
            }
        }
    };

    normalizeStartAt = (value, previousValue, allValues) => {
        const { formState } = this.props;
        if (!(formState && formState.values && formState.values.startAt)) {
            const myValue = moment(value).tz("Europe/London");
            myValue.set({ hour: 0, minute: 0, second: 0 });
            return myValue.toISOString();
        } else return value;
    };

    normalizeEndAt = (value, previousValue, allValues) => {
        const { startAt } = allValues;
        const { formState } = this.props;
        if (startAt && value) {
            return value > startAt ? value : startAt;
        } else if (!(formState && formState.values && formState.values.endAt)) {
            const myValue = moment(value).tz("Europe/London");
            myValue.set({ hour: 0, minute: 0, second: 0 });
            return myValue.toISOString();
        }

        return value;
    };

    render() {
        const { students, pages, audiences, formState, handleSubmit, errors } = this.props;
        const isAllDay = formState && formState.values && formState.values.isAllDay;
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <SubTitle title="Add Key Academic Date" />
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="name"
                                    errorRes={errors.name}
                                    component={RenderTextField}
                                    label="Name"
                                    fullWidth
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="location"
                                    errorRes={errors.location}
                                    component={RenderTextField}
                                    label="Location"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <hr css={globalsCss.hrDouble} />
                        <div className="row">
                            <div className="col-lg-12 col-sm-12 col-xs-12">
                                <Field
                                    name="isAllDay"
                                    label="All Day"
                                    component={RenderCheckboxField}
                                />
                            </div>
                        </div>
                        <div className="row" css={globalsCss.helpers.mt15}>
                            <div className="col-lg-4 col-sm-4 col-xs-12">
                                <label css={formsCss.labelDateTime}>From</label>
                                <Field
                                    name="startAt"
                                    errorRes={errors.startAt}
                                    component={RenderDatepicker}
                                    format={null}
                                    fullWidth
                                    hintText="Enter Date"
                                    onChange={this.onChangeStartAt}
                                    normalize={this.normalizeStartAt}
                                />
                                {!isAllDay && (
                                    <Field
                                        name="startAt"
                                        component={RenderTimepicker}
                                        format={null}
                                        hintText="HH:MM"
                                        fullWidth
                                        onChange={this.onChangeStartAt}
                                    />
                                )}
                            </div>
                            <div className="col-lg-4 col-sm-4 col-xs-12">
                                <label css={formsCss.labelDateTime}>To</label>
                                <Field
                                    name="endAt"
                                    errorRes={errors.endAt}
                                    component={RenderDatepicker}
                                    format={null}
                                    hintText="Enter Date"
                                    fullWidth
                                    normalize={this.normalizeEndAt}
                                />
                                {!isAllDay && (
                                    <Field
                                        name="endAt"
                                        component={RenderTimepicker}
                                        format={null}
                                        hintText="HH:MM"
                                        fullWidth
                                        normalize={this.normalizeEndAt}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <Field
                                    name="notes"
                                    errorRes={errors.notes}
                                    component={RenderTextDescription}
                                    fullWidth
                                    label="Notes"
                                />
                            </div>
                        </div>
                        <hr css={globalsCss.hrDouble} />
                        <h4>Add to calendar(s) of:</h4>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <div>
                                    <label css={formsCss.ownersLabel}>
                                        Page Channels&apos; Followers
                                    </label>
                                    <Field
                                        name="audience.pages"
                                        handleInput={this.inputChangeHandler("pages")}
                                        dataSource={pages}
                                        floatingLabelText="Page name or ID"
                                        labelCallback={item => item.name}
                                        numCallback={item => item.numFollowers}
                                        component={RenderAutocomplete}
                                    />
                                </div>
                            </div>
                            );
                            <div className="col-sm-6 col-xs-12">
                                <div>
                                    <label css={formsCss.ownersLabel}>LSE Student(s)</label>
                                    <Field
                                        name="audience.students"
                                        handleInput={this.inputChangeHandler("students")}
                                        dataSource={students}
                                        component={RenderAutocomplete}
                                    />
                                </div>
                            </div>
                            );
                            <div className="col-sm-6 col-xs-12">
                                <div>
                                    <label css={formsCss.ownersLabel}>Custom Audience</label>
                                    <Field
                                        name="audience.audiences"
                                        handleInput={this.inputChangeHandler("audiences")}
                                        dataSource={audiences}
                                        floatingLabelText="Audience name or ID"
                                        labelCallback={item => item.name}
                                        component={RenderAutocomplete}
                                    />
                                </div>
                            </div>
                            );
                        </div>

                        <div css={formsCss.actions}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                Add New Event
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}

const form = reduxForm({
    form: "NewCalendarEvent",
    enableReinitialize: true,
    validate,
})(EventForm);
export default form;
