import { spacing } from "../../UIGlobals";

export const footerStyle = {
    body: {
        // display: "flex",
        marginTop: spacing.space4,
    },
    shape: {
        marginBottom: spacing.space2comma5,
    },
    inner: {
        display: "flex",
    },
    logo: {
        width: 40,
        marginRight: spacing.space2,
    },
    text: {
        color: "rgba(0,0,0,.87)",
        fontSize: 10,
        lineHeight: 1.6,
        letterSpacing: 0.4,
        margin: `0 0 ${spacing.space2}px`,
    },
};
