import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";

import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";
import { default as UIActionCreators } from "../MainMenu/actionCreators";

const feedbackStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.sendFeedback.type),
        mapTo(UIActionCreators.setLoading.create())
    );

const feedbackClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updateFeedback.type, actionCreators.errorResponse.type),
        mapTo(UIActionCreators.clearLoading.create())
    );

const sendFeedbackEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.sendFeedback.type),
        switchMap(action =>
            ajax
                .post(ajax.apiUrl(`all/backend/report-issue/`), action.payload)
                .pipe(
                    mapTo(actionCreators.updateFeedback.create(action.payload)),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getSubjectsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSubjects.type),
        switchMap(() =>
            ajax.get(ajax.apiUrl(`all/backend/request-types/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateSubjects.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    sendFeedbackEpic,
    feedbackStartLoadingEpic,
    feedbackClearLoadingEpic,
    getSubjectsEpic
);
