import { Component } from "react";
import { reduxForm, Field, Fields, change, registerField, formValueSelector } from "redux-form";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import validate from "./validate";
import {
    RenderRadioGroup,
    RenderFileInput,
    RenderBasicEditor,
    RenderDatepicker,
    RenderTimepicker,
    RenderTextField,
    RenderAutocomplete,
    RenderRadio,
} from "../../../../common/components/FormFields";
import currentFormTypes from "../../../../common/components/CurrentFormTypes";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";

import { Button, Paper } from "@material-ui/core";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../../../../common/styles/globals.css";
import formsCss from "../../../../common/styles/forms.css";

import { InfoTooltip } from "../../../../common/components/InfoTooltip";
import { connect } from "react-redux";
import actionCreators from "../../../../store/Channels/Events/actionCreators";
import moment from "moment-timezone";

class Form extends Component {
    state = {
        searchText: "",
        searchText$: new Subject(),
    };
    componentDidMount() {
        const { getChannels, clearChannels, addField } = this.props;
        const { searchText$ } = this.state;
        searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
            if (query !== "") {
                rawUpdateListOnFilterChange({
                    getFn: getChannels,
                    filter: { filter: query },
                });
            } else clearChannels();
        });
        addField("charCount", "Field");
        this.props.initialize(this.props.initialValues);
        if (this.props.status === "archived") this.props.updateForm("status", "live");
    }

    componentDidUpdate(prevProps) {
        if (this.props.status === "archived") this.props.updateForm("status", "live");
    }

    onChangeHandler = searchText => {
        const { searchText$ } = this.state;
        this.setState({ searchText: searchText }, () => searchText$.next(this.state.searchText));
    };

    onStatusChange = (event, curr) => {
        this.props.setDateFieldStatus(curr === "scheduled" ? true : false);
    };

    onChangeStartAt = (event, newValue) => {
        const { formValues, changeEndAt } = this.props;
        if (formValues && formValues.values) {
            if (!formValues.values.endAt || formValues.values.endAt <= newValue) {
                // update endAt date
                const endAt = moment(newValue);
                endAt.set("hour", endAt.get("hour") + 1);
                changeEndAt(endAt.utc().format());
            }
        }
    };

    normalizeStartAt = (value, previousValue, allValues) => {
        const { formValues } = this.props;
        if (!(formValues && formValues.values && formValues.values.startAt)) {
            const myValue = moment(value).tz("Europe/London");
            myValue.set({ hour: 0, minute: 0, second: 0 });
            return myValue.toISOString();
        } else return value;
    };

    normalizeEndAt = (value, previousValue, allValues) => {
        const { startAt } = allValues;
        const { formValues } = this.props;
        if (startAt && value) {
            return value > startAt ? value : startAt;
        } else if (!(formValues && formValues.values && formValues.values.endAt)) {
            const myValue = moment(value).tz("Europe/London");
            myValue.set({ hour: 0, minute: 0, second: 0 });
            return myValue.toISOString();
        }

        return value;
    };

    render() {
        const {
            handleSubmit,
            goBack,
            currentFormType,
            errors,
            formErrors,
            hasDate,
            channels,
            clearChannels,
            updateForm,
            initialValues: { status },
        } = this.props;

        const { onStatusChange } = this;
        return (
            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    <h2 css={globalsCss.subtitleTitle}>
                        {currentFormType === currentFormTypes.NEW
                            ? "Add New Event"
                            : status === "archived"
                            ? "Unarchive Event"
                            : "Edit Event"}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Field
                            name="status"
                            errorRes={errors.status}
                            onChange={onStatusChange}
                            component={RenderRadioGroup}
                            row
                        >
                            <RenderRadio
                                value="live"
                                label="Publish live"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                            <RenderRadio
                                value="scheduled"
                                label="Schedule publication in the future"
                                className="col-lg-4 col-sm-3 col-xs-12"
                            />
                            <RenderRadio
                                value="draft"
                                label="Save as draft"
                                className="col-lg-2 col-sm-3 col-xs-12"
                            />
                            {(status === "live" || status === "archived") && (
                                <RenderRadio
                                    value="archived"
                                    disabled={status === "archived"}
                                    label="Archived"
                                    className="col-lg-2 col-sm-3 col-xs-12"
                                />
                            )}
                        </Field>
                        {hasDate && (
                            <div className="row">
                                <div className="col-sm-3 col-xs-12 col-sm-offset-2">
                                    <div css={formsCss.scheduledAt}>
                                        <label css={formsCss.labelScheduled}>Scheduled at</label>
                                        <Field
                                            name="publicationDate"
                                            errorRes={null}
                                            component={RenderDatepicker}
                                            format={null}
                                            hintText="Enter Date"
                                            fullWidth
                                            normalize={this.normalizeDate}
                                        />
                                        <Field
                                            name="publicationDate"
                                            component={RenderTimepicker}
                                            format={null}
                                            hintText="HH:MM"
                                            fullWidth
                                            errorRes={errors.publicationDate}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <hr />
                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="name"
                                    errorRes={errors.name}
                                    component={RenderTextField}
                                    label="Name"
                                    fullWidth
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="location"
                                    errorRes={errors.location}
                                    component={RenderTextField}
                                    label="Location"
                                    fullWidth
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <Field
                                    name="signpostUrl"
                                    errorRes={errors.signpostUrl}
                                    component={RenderTextField}
                                    label="Event URL (optional)"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <hr css={globalsCss.hrDouble} />
                        <div className="row">
                            <div className="col-lg-4 col-sm-4 col-xs-12">
                                <label css={formsCss.label}>From</label>
                                <Field
                                    name="startAt"
                                    errorRes={errors.startAt}
                                    component={RenderDatepicker}
                                    format={null}
                                    hintText="Enter Date"
                                    fullWidth
                                    onChange={this.onChangeStartAt}
                                    normalize={this.normalizeStartAt}
                                />
                                <Field
                                    name="startAt"
                                    component={RenderTimepicker}
                                    format={null}
                                    hintText="HH:MM"
                                    fullWidth
                                    onChange={this.onChangeStartAt}
                                />
                            </div>
                            <div className="col-lg-4 col-sm-4 col-xs-12">
                                <label css={formsCss.label}>To</label>
                                <Field
                                    name="endAt"
                                    errorRes={errors.endAt}
                                    component={RenderDatepicker}
                                    format={null}
                                    hintText="Enter Date"
                                    fullWidth
                                    normalize={this.normalizeEndAt}
                                />
                                <Field
                                    name="endAt"
                                    component={RenderTimepicker}
                                    format={null}
                                    hintText="HH:MM"
                                    fullWidth
                                    normalize={this.normalizeEndAt}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-xs-12">
                                <div css={globalsCss.helpers.mt35}>
                                    <Field
                                        name="description"
                                        errorRes={errors.description}
                                        label="Description"
                                        setCharCount={count => updateForm("charCount", count)}
                                        component={RenderBasicEditor}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <label css={formsCss.label} htmlFor="image">
                                    Image <small css={formsCss.labelHelp}>(min. 747px/420px)</small>
                                </label>
                                <Fields
                                    names={["imageFile", "imageUrl", "imageRemove"]}
                                    errorRes={errors.imageFile}
                                    component={RenderFileInput}
                                    onChangeCallback={() => this.props.clearErrors("imageFile")}
                                    onClearCallback={() => this.props.clearErrors("imageFile")}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-xs-6">
                                <h4 css={globalsCss.helpers.mt35}>Primary Channel</h4>
                                <div css={formsCss.inlineAlignTop}>
                                    <Field
                                        single
                                        name="channel"
                                        floatingLabelText="Page or Group name"
                                        errorRes={errors.channel}
                                        handleInput={this.onChangeHandler}
                                        dataSource={channels}
                                        component={RenderAutocomplete}
                                        labelCallback={item => item.name}
                                        onDroppedFocus={() => clearChannels()}
                                        onChangeCallback={() => this.props.clearErrors("channel")}
                                        noAvatar
                                    />
                                    <InfoTooltip
                                        mt={44}
                                        text="A primary Page or Group (only one) that this event is associated with"
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <h4 css={globalsCss.helpers.mt35}>Additional Channel(s)</h4>
                                <div css={formsCss.inlineAlignTop}>
                                    <Field
                                        name="additionalChannels"
                                        floatingLabelText="Page or Group name"
                                        errorRes={errors.additionalChannels}
                                        handleInput={this.onChangeHandler}
                                        dataSource={channels}
                                        component={RenderAutocomplete}
                                        labelCallback={item => item.name}
                                        onDroppedFocus={() => clearChannels()}
                                        onChangeCallback={() =>
                                            this.props.clearErrors("additionalChannels")
                                        }
                                        noAvatar
                                    />
                                    <InfoTooltip
                                        mt={44}
                                        text="Additional Page(s) or Group(s) where this event should also appear"
                                    />
                                </div>
                            </div>
                        </div>
                        <hr css={globalsCss.hrDouble} />
                        <Field name="info" errorRes={errors.info} component={RenderRadioGroup} row>
                            <RenderRadio
                                value="none"
                                disabled={
                                    !!(
                                        this.props.initialValues.id > 0 &&
                                        this.props.initialValues.info !== "none"
                                    )
                                }
                                label="Don't invite anyone and don't add to anyone’s calendars"
                                className="col-xs-12"
                            />
                            <RenderRadio
                                value="email"
                                disabled={
                                    !!(
                                        this.props.initialValues.id > 0 &&
                                        this.props.initialValues.info !== "none"
                                    )
                                }
                                label="Send e-mail invites to all channel followers"
                                className="col-xs-12"
                            />
                            <RenderRadio
                                value="register"
                                disabled={
                                    !!(
                                        this.props.initialValues.id > 0 &&
                                        this.props.initialValues.info !== "none"
                                    )
                                }
                                label="Automatically add event to all channel followers’ calendars"
                                className="col-xs-12"
                            />
                        </Field>
                        {formErrors && Object.keys(formErrors).length > 0 && (
                            <div css={[formsCss.genericError, formsCss.genericErrorMarginTop]}>
                                Please check for errors on this page
                            </div>
                        )}
                        <div css={formsCss.actions}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                css={formsCss.btnSubmit}
                            >
                                {currentFormType === currentFormTypes.NEW
                                    ? "Add New Event"
                                    : "Save Event"}
                            </Button>
                            <Button onClick={() => goBack()} css={formsCss.btnBack}>
                                Back to the list
                            </Button>
                        </div>
                    </form>
                </div>
            </Paper>
        );
    }
}

const FORM_NAME = "Event";
const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
})(Form);

const selector = formValueSelector(FORM_NAME);

const filterTouched = form => {
    let res = {};
    for (const [fieldName, errorValue] of Object.entries(form.syncErrors)) {
        if (form.fields && form.fields[fieldName] && form.fields[fieldName].touched === true)
            res = { ...res, [fieldName]: errorValue };
    }
    return res;
};

const mapStateToProps = ({ form }) => ({
    status: selector({ form }, "status"),
    formErrors: form[FORM_NAME]
        ? form[FORM_NAME].syncErrors && form[FORM_NAME].anyTouched
            ? filterTouched(form[FORM_NAME])
            : null
        : null,
});

const dispatchToProps = {
    clearErrors: actionCreators.clearErrors.create,
    updateForm: (key, newValue) => change(FORM_NAME, key, newValue),
    addField: (key, newValue) => registerField(FORM_NAME, key, newValue),
};

export default connect(mapStateToProps, dispatchToProps)(form);
