/** @jsx jsx */
import { jsx } from "@emotion/core";
import SvgElements from "../SvgElements/SvgElements";
import { footerStyle } from "./FooterStyles";

const Footer = () => (
    <div css={footerStyle.body}>
        <div css={footerStyle.shape}>
            <SvgElements
                name="footerShape"
                width="100%"
                viewBox="0 0 800 44"
                css={{ display: "block" }}
            />
        </div>
        <div css={footerStyle.inner}>
            <div css={footerStyle.logo}>
                <SvgElements name="lse" width="40" height="40" viewBox="0 0 40 40" />
            </div>
            <p css={footerStyle.text}>
                London School of Economics and Political Science <br />
                Houghton Street <br />
                London WC2A 2AE UK <br />
                <br />
                LSE is a private company limited by guarantee, registration number 70527.
            </p>
        </div>
    </div>
);

export { Footer };
