import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainMenu/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import * as ajax from "../../common/services/utils";

const channelStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getChannel.type, actionCreators.getChannelPosts.type),
        mapTo(UIActionCreators.setLoading.create())
    );

const channelClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createChannel.type,
            actionCreators.updateChannelPosts.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getChannelEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getChannel.type),
        switchMap(({ payload: { id } }) =>
            ajax.get(ajax.apiUrl(`staff/channel/${id}/preview/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.createChannel.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getChannelPostsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getChannelPosts.type),
        switchMap(({ payload: { id, param } }) =>
            ajax.get(ajax.apiUrl(`api/channel/${id}/posts/?${param || ""}`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateChannelPosts.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const setChannelAsRecommendedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.setChannelAsRecommended.type),
        switchMap(action =>
            ajax
                .put(
                    ajax.apiUrl(
                        `staff/${action.payload.type}/${action.payload.id}/set-as-recommended/`
                    )
                )
                .pipe(
                    mergeMap(() =>
                        of(actionCreators.confirmSetChannelAsRecommended.create(action.payload.id))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const removeChannelFromRecommendedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeChannelFromRecommended.type),
        switchMap(action =>
            ajax
                .put(
                    ajax.apiUrl(
                        `staff/${action.payload.type}/${action.payload.id}/remove-from-recommended/`
                    )
                )
                .pipe(
                    mergeMap(() =>
                        of(
                            actionCreators.confirmChannelRemoveFromRecommended.create(
                                action.payload.id
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    channelStartLoadingEpic,
    channelClearLoadingEpic,
    getChannelEpic,
    getChannelPostsEpic,
    setChannelAsRecommendedEpic,
    removeChannelFromRecommendedEpic
);
