import { Component, Fragment } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { ContentContainer, Header, MainBox, Icon, Button, Footer } from "../common/UIElements";
import Modal from "react-modal";
import { home, modalDefaultStyle, modalStyle, formStyle } from "./HomeStyles";
import Form from "./Form";
import { startSubmit, stopSubmit } from "redux-form";
import bugsnagClient from "../../common/services/bugsnag";
import Wysiwyg from "../../common/components/Wysiwyg";

import actionCreators from "../../store/CmsSupport/actionCreators";
import authActionCreators from "../../store/Auth/actionCreators";
import feedbackActionCreators from "../../store/Feedback/actionCreators";
import browser from "browser-detect";

class Home extends Component {
    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    onSubmit = values => {
        const { sendFeedback } = this.props;
        const userAgent = browser();
        const capitalize = s => {
            if (typeof s !== "string") return "";
            return s.charAt(0).toUpperCase() + s.slice(1);
        };

        sendFeedback({
            subject: values.subject,
            requestType: Number(values.requestType),
            body: values.body,
            name: values.name,
            email: values.email,
            environment: {
                app: bugsnagClient.app,
                device: bugsnagClient.device,
            },
            system: capitalize(userAgent.os),
            platform: `${userAgent.mobile ? "Mobile web" : "Desktop web"} v.${
                process.env.REACT_APP_VERSION
            }`,
            browser: capitalize(`${userAgent.name} ${userAgent.version}`),
        });
    };

    componentDidMount() {
        const { getHomepage, getSubjects, restoreUser, history } = this.props;
        restoreUser();
        getHomepage();
        getSubjects();

        const { match } = this.props;
        if (match.params.type === "form") {
            this.setState({ modalIsOpen: true });
        }

        if (history.location.pathname === "/support/feedback") {
            this.openModal();
        }
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
        this.props.history.replace({ pathname: "/support/feedback" });
    };

    closeModal = () => {
        const { clearFeedback, history } = this.props;
        this.setState({ modalIsOpen: false });
        clearFeedback();

        if (history.location.pathname === "/support/feedback") {
            history.push("/support");
        }
    };

    downloadGuide = type => {
        const { downloadGuide, homepage } = this.props;
        downloadGuide({ id: homepage.id, fileType: type });
    };

    render() {
        const {
            subjects,
            feedbackSent,
            homepage,
            initialValues,
            errorRes: { errors },
            history: { push },
        } = this.props;
        const { openModal, closeModal, onSubmit, downloadGuide } = this;

        return (
            <Fragment>
                <Header />
                <ContentContainer>
                    <div css={home.contentContainer}>
                        <h5 css={home.headline}>
                            Welcome to the Student Hub Content Management System and Knowledge Base
                        </h5>
                        <div css={home.paragraph}>
                            {homepage && <Wysiwyg html={homepage.homepageTeaser} />}
                        </div>

                        <div css={home.boxContainer}>
                            {homepage && homepage.linkType === "download" ? (
                                <MainBox
                                    title="CMS manual for editors"
                                    linkText="PDF Download"
                                    icon="file"
                                    onClick={() => downloadGuide("user-guide")}
                                />
                            ) : (
                                <MainBox
                                    title="CMS manual for editors"
                                    href={homepage ? homepage.userGuideLink : ""}
                                    linkText="Click to view"
                                    icon="file"
                                    target="_blank"
                                />
                            )}
                            <MainBox
                                title="Get in touch"
                                linkText="Share your feedback or ask for help"
                                icon="cloud"
                                onClick={() => openModal()}
                            />
                        </div>
                        <div css={home.boxContainer}>
                            {/* 
                            <MainBox
                                title="Editors guide"
                                linkText="PDF download"
                                icon="file"
                                onClick={() => downloadGuide("editor-guide")}
                            />
                            */}
                            <MainBox
                                title="Bookings guides"
                                linkText="Knowledge base"
                                icon="file"
                                onClick={() =>
                                    push(
                                        `support/knowledge-base/${process.env.REACT_APP_KNOWLEDGE_BASE_BOOKING_ID}/`
                                    )
                                }
                            />
                            <MainBox
                                title="How to guides"
                                linkText="Knowledge base"
                                onClick={() => push("/support/knowledge-base")}
                                icon="file"
                            />
                        </div>
                    </div>
                    <Footer />
                </ContentContainer>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={modalDefaultStyle}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <button type="button" css={modalStyle.btnClose} onClick={() => closeModal()}>
                        <Icon name="times" width="18" height="18" />
                    </button>

                    {feedbackSent === false ? (
                        <Fragment>
                            <h2>Send us feedback</h2>
                            <p>
                                If you need additional help or have ideas about how to improve the
                                Hub, please share your feedback with us. We&apos;d love to hear your
                                suggestions.{" "}
                            </p>
                            <Form {...{ onSubmit, subjects, errors, initialValues }} />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h2>Thank you</h2>
                            <p>
                                Thank you for your message! We&apos;ll get back to you with a
                                response within 5 working days.
                            </p>
                            <div css={formStyle.actions}>
                                <Button type="button" onClick={() => closeModal()}>
                                    Close
                                </Button>
                            </div>
                        </Fragment>
                    )}
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    homepage: state.CmsSupport.homepage,
    subjects: state.Feedback.subjects,
    errorRes: state.Feedback.errorRes,
    feedbackSent: state.Feedback.feedbackSent,
    initialValues: state.Auth.isLogged
        ? {
              name: `${state.Auth.firstName} ${state.Auth.lastName}`,
              email: state.Auth.email,
          }
        : {},
});

const dispatchToProps = {
    restoreUser: authActionCreators.restoreUser.create,
    getHomepage: actionCreators.getHomepage.create,
    sendFeedback: feedbackActionCreators.sendFeedback.create,
    clearFeedback: feedbackActionCreators.clearFeedback.create,
    getSubjects: feedbackActionCreators.getSubjects.create,
    downloadGuide: actionCreators.downloadGuide.create,
    startSubmit: startSubmit,
    stopSubmit: stopSubmit,
};

export default connect(mapStateToProps, dispatchToProps)(Home);
