import { Component } from "react";
import { connect } from "react-redux";
import actionCreators from "../../../store/OfficeHours/Availabilities/actionCreators";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { MainTitle } from "../../../common/components/MainTitle";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import Form from "./Form";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";
import formsCss from "../../../common/styles/forms.css";
import { composeAvailabilityRequest } from "./Form/composeRequest.js";
import PromptDialog from "../../../common/components/PromptDialog";
import { ACADEMIC, NONE, MEETING_ZOOM, MEETING_CUSTOM, MEETING_NONE } from "./types";
import { TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

class OfficeHoursNewAvailabilityContainer extends Component {
    state = {
        request: false,
        template: ACADEMIC,
    };

    componentDidMount() {
        const {
            setTitle,
            getOneMyOfficeHoursAcademic,
            getOfficeHoursDefaultAvailability,
            match,
            academic,
        } = this.props;
        setTitle("Bookings \u203A Manage Availability");
        if (!academic) getOneMyOfficeHoursAcademic(match.params.userId);
        getOfficeHoursDefaultAvailability(match.params.userId);
    }

    componentWillUnmount() {
        const { clearOfficeHoursDefaultAvailability, clearTitle } = this.props;
        clearTitle();
        clearOfficeHoursDefaultAvailability();
    }

    onSubmit = values => {
        const { pushMyOfficeHoursAvailability, academic } = this.props;
        const request = composeAvailabilityRequest(values);

        this.setState({ request }, () => {
            pushMyOfficeHoursAvailability({
                availability: request,
                action: "validate",
                academicId: academic.id,
                isAcademicContext: true,
            });
        });
    };

    handleForceSubmit = confirmed => {
        const { pushMyOfficeHoursAvailability, academic, hideConfirmForceRequest } = this.props;
        if (confirmed) {
            pushMyOfficeHoursAvailability({
                availability: this.state.request,
                action: "force",
                academicId: academic.id,
                isAcademicContext: true,
            });
        } else hideConfirmForceRequest();
    };

    render() {
        const {
            history: { push },
            academic,
            initialValues,
            availabilityTemplate,
            confirmForceRequest,
            errorRes: { error, errors },
            classes,
        } = this.props;
        const { template } = this.state;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.NEW;

        const formInitialValues = availabilityTemplate
            ? {
                  ...initialValues,
                  repeating: "no",
                  daily: { every: 1, config: null, ntimes: null, until: null },
                  weekly: {
                      every: 1,
                      config: null,
                      ntimes: null,
                      until: null,
                  },
                  monthly: { every: 1, config: null, ntimes: null, until: null },
                  timeInAdvanceValue: 0,
                  summaryAtTimeValue: 0,

                  // users' default availability template
                  // academic default settings
                  ...(template === ACADEMIC
                      ? {
                            ...availabilityTemplate.settings,
                            onlineMeetings:
                                !availabilityTemplate.settings.meetingUrl &&
                                !!availabilityTemplate.settings.integrateWithZoom
                                    ? MEETING_ZOOM
                                    : !!availabilityTemplate.settings.meetingUrl &&
                                      !availabilityTemplate.settings.integrateWithZoom
                                    ? MEETING_CUSTOM
                                    : MEETING_NONE,
                            meetingUrl: availabilityTemplate.settings.meetingUrl,
                            integrateWithZoom: availabilityTemplate.settings.integrateWithZoom,
                        }
                      : {}),
              }
            : {};

        return academic ? (
            <div>
                <MainTitle
                    title={`${academic.firstName} ${academic.lastName}`}
                    type={"[Availability]"}
                />

                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <div css={globalsCss.rowFlexMiddle}>
                            <h2 css={globalsCss.subtitleTitle}>Add New Availability</h2>

                            <TextField
                                select
                                margin="none"
                                color="primary"
                                value={template}
                                onChange={({ target: { value } }) => {
                                    this.setState({ template: value });
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                        error: classes.inputError,
                                    },
                                }}
                            >
                                <MenuItem key={`tpl${ACADEMIC}`} value={ACADEMIC}>
                                    Use my default settings
                                </MenuItem>
                                <MenuItem key={`tpl${NONE}`} value={NONE}>
                                    Don&apos;t use saved settings
                                </MenuItem>
                            </TextField>
                        </div>

                        <Form
                            {...{
                                initialValues: formInitialValues,
                                onSubmit,
                                currentFormType,
                                push,
                                errors,
                                formError: error,
                                academicId: academic.id,
                            }}
                        />
                    </div>
                </Paper>

                <PromptDialog
                    open={confirmForceRequest === 1}
                    handleClose={this.handleForceSubmit}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "You are changing your online meeting settings, which affects existing booked appointments. Students who have already booked will receive email updates with the updated information. "
                    }
                </PromptDialog>

                <PromptDialog
                    open={confirmForceRequest === 2}
                    handleClose={this.handleForceSubmit}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "Sessions resulting in this availability rule overlaps with some other existing sessions. Do you want to go ahead and create only those sessions that don’t cause any conflicts?"
                    }
                </PromptDialog>

                <PromptDialog
                    open={confirmForceRequest === 3}
                    handleClose={() => this.props.showConfirmForceRequest(1)}
                    confirmLabel={"Yes, Confirm"}
                >
                    {
                        "Sessions resulting in this availability rule overlaps with some other existing sessions. Do you want to go ahead and create only those sessions that don’t cause any conflicts?"
                    }
                </PromptDialog>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOneMyOfficeHoursAcademic: actionCreators.getOneMyOfficeHoursAcademic.create,
    pushMyOfficeHoursAvailability: actionCreators.pushMyOfficeHoursAvailability.create,
    hideConfirmForceRequest: actionCreators.hideConfirmForceRequest.create,
    getOfficeHoursDefaultAvailability: actionCreators.getOfficeHoursDefaultAvailability.create,
    clearOfficeHoursDefaultAvailability: actionCreators.clearOfficeHoursDefaultAvailability.create,
    showConfirmForceRequest: actionCreators.showConfirmForceRequest.create,
};

const mapStateToProps = ({ OfficeHoursAvailabilities }) => ({
    academic: OfficeHoursAvailabilities.academic,
    errorRes: OfficeHoursAvailabilities.errorRes,
    initialValues: OfficeHoursAvailabilities.availability,
    confirmForceRequest: OfficeHoursAvailabilities.confirmForceRequest,
    availabilityTemplate: OfficeHoursAvailabilities.availabilityTemplate,
});

export default connect(
    mapStateToProps,
    dispatchToProps
)(withStyles(formsCss)(OfficeHoursNewAvailabilityContainer));
