import { fontWeight } from "./typography";
import { spacing } from "./spacing";
import * as CSS from "csstype";
import { StyleRules } from "@material-ui/core/styles";

const style: StyleRules<string> = {
    inner: {
        padding: "35px",

        "@media (max-width: 767px)": {
            padding: "25px 15px",
        },
    },
    innerSmall: {
        padding: "20px",

        "@media (max-width: 767px)": {
            padding: "15px 10px",
        },
    },
    innerSmallBottom: {
        padding: "35px 35px 10px",

        "@media (max-width: 767px)": {
            padding: "25px 15px 10px",
        },
    },
    innerDialog: {
        padding: "35px",
        width: "100%",

        "@media (max-width: 767px)": {
            padding: "25px 15px",
        },
    },
    innerTop20: {
        padding: "10px 35px 35px",

        "@media (max-width: 767px)": {
            padding: "10px 15px 25px",
        },
    },
    innerPre: {
        whiteSpace: "pre-line",
        padding: 10,
    },
    rowFlexMiddle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        "@media (max-width: 767px)": {
            flexDirection: "column",
            width: "100%",
        },
    },
    mainTitle: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0 0 35px",

        "@media (max-width: 767px)": {
            display: "block",
            margin: "0 0 20px",
        },
    },
    mainTitleInner: {
        "@media (max-width: 767px)": {
            // padding: "0 15px",
        },
    },
    mainTitleLeftElement: {
        "@media (max-width: 767px)": {
            margin: "0 0 20px",
        },
    },
    mainTitleRightElement: {
        marginLeft: spacing.space3,
        "@media (max-width: 767px)": {
            marginLeft: 0,
        },
    },
    mainTitleText: {
        fontSize: 24,
        fontWeight: 500 as CSS.FontWeightProperty,
        margin: 0,

        "@media (max-width: 767px)": {
            fontSize: 20,
        },
    },
    mainTitleType: {
        display: "inline-block",
        color: "#999",
        marginLeft: 16,
    },
    mainSubtitleText: {
        color: "#999",
    },
    subtitleTitle: {
        fontSize: 20,
        fontWeight: 500 as CSS.FontWeightProperty,
        marginBottom: 25,

        "@media (max-width: 767px)": {
            fontSize: 18,
        },
    },
    subtitleTitleAddOn: {
        fontSize: 16,
        fontWeight: 500 as CSS.FontWeightProperty,
        color: "rgb(153, 153, 153)",

        "@media (max-width: 767px)": {
            fontSize: 14,
        },
    },
    subtitleTitleType: {
        display: "inline-block",
        color: "#999",
        marginLeft: spacing.space1,
    },
    subtitleTable: {
        display: "inline-block",
        color: "#999",
    },
    subtitleTableItem: {
        display: "inline-block",
        marginRight: spacing.space2,
        marginBottom: spacing.space2,
    },
    subtitleTableHr: {
        margin: "15px -35px 10px -35px",

        "@media (max-width: 767px)": {
            margin: "10px -15px 10px -15px",
        },
    },
    subtitleTableInner: {
        padding: "35px 35px 0px 15px",

        "@media (max-width: 767px)": {
            padding: "25px 15px 0px 15px",
        },
    },
    subTitleMarginMobile: {
        "@media (max-width: 767px)": {
            paddingRight: 16,
            paddingLeft: 16,
        },
    },
    subtitleCardTitle: {
        fontSize: 16,
        fontWeight: 500 as CSS.FontWeightProperty,
        // color: "rgb(153, 153, 153)",

        // "@media (max-width: 767px)": {
        //     fontSize: 14,
        // },
    },
    toolbarText: {
        borderBottom: "1px solid #e0e0e0",
        padding: "12px 24px",

        "@media (max-width: 767px)": {
            padding: "12px 15px",
        },
    },
    actions: {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 0 35px",

        "@media (max-width: 767px)": {
            margin: "5px 10px 20px 0",
        },
    },
    actionsBottom: {
        display: "flex",
        flexWrap: "wrap",
        margin: "35px 0",

        "@media (max-width: 767px)": {
            margin: "10px",
        },
    },
    actionsItem: {
        marginRight: 5,
        "@media (max-width: 767px)": {
            // margin: "5px",
        },
    },
    actionsNotifications: {
        margin: "0 0 35px",
        "@media (max-width: 767px)": {
            margin: "5px 10px 20px 0",
            // marginRight: "2rem",
            // marginLeft: "2rem",
        },
    },
    // actionsBooking: {
    //     borderTop: "1px solid #efefef",
    //     paddingTop: 35,
    //     paddingRight: 35,
    //     paddingBottom: 35,
    //     paddingLeft: 35,
    //     "@media (max-width: 767px)": {
    //         paddingTop: 25,
    //         paddingRight: 15,
    //         paddingBottom: 25,
    //         paddingLeft: 15,
    //     },
    // },
    actionsBooking: {
        borderTop: "1px solid #efefef",
        padding: "10px 29px",
        "@media (max-width: 767px)": {
            padding: "10px 8px",
        },
    },
    actionsSmall: {
        borderTop: "1px solid #efefef",
        padding: "10px 29px",
        "@media (max-width: 767px)": {
            padding: "10px 8px",
        },
    },
    actionsTableHeader: {
        borderBottom: "1px solid #efefef",
        paddingTop: 30,
        paddingRight: 35,
        paddingBottom: 10,
        paddingLeft: 35,
        "@media (max-width: 767px)": {
            paddingTop: 20,
            paddingRight: 15,
            paddingBottom: 10,
            paddingLeft: 15,
        },
    },
    actionsTableList: {
        // borderTop: "1px solid #efefef",
        paddingTop: 35,
        paddingRight: 35,
        paddingBottom: 35,
        paddingLeft: 35,
        "@media (max-width: 767px)": {
            paddingTop: 25,
            paddingRight: 15,
            paddingBottom: 25,
            paddingLeft: 15,
        },
    },
    actionsTableListSmall: {
        padding: "10px 29px",
        "@media (max-width: 767px)": {
            padding: "10px 8px",
        },
    },
    actionsSpaceBetween: {
        justifyContent: "space-between",
    },
    actionsRight: {
        flexDirection: "row-reverse",
        margin: "0 0 15px",
    },
    actionsAttendance: {
        margin: "10px 0 0 -8px",
    },
    textBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "@media (max-width: 767px)": {
            display: "block",
            marginTop: 15,
            padding: 10,
            backgroundColor: "rgba(0, 0, 0, 0.03)",
            textAlign: "center",
        },
        text: {
            fontSize: 14,
            fontWeight: fontWeight.normal as CSS.FontWeightProperty,
            paddingRight: 15,
            "@media (max-width: 767px)": {
                paddingLeft: 15,
                fontSize: 13,
            },
        },
        btn: {
            whiteSpace: "nowrap",
            "@media (max-width: 767px)": {
                marginTop: 8,
            },
        },
    },
    filters: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        height: "100%",
        paddingTop: 15,
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    chipsItem: {
        margin: "10px 10px 0 0",
    },
    showOnMobile: {
        display: "none",

        "@media (max-width: 767px)": {
            display: "inline",
        },
    },
    showOnDesktop: {
        "@media (max-width: 767px)": {
            display: "none",
        },
    },
    hrDouble: {
        margin: "35px 0",

        "@media (max-width: 767px)": {
            margin: "25px 0",
        },
    },
    hrDialog: {
        margin: "35px -24px 10px -24px",
    },
    hrFullwidth: {
        marginRight: -35,
        marginLeft: -35,
    },
    noMargin: {
        margin: 0,
    },
    block: {
        display: "block",
    },
    helpers: {
        mt0: {
            marginTop: 0,
        },
        mt5: {
            marginTop: 5,
        },
        mt10: {
            marginTop: 10,
        },
        mt15: {
            marginTop: 15,
        },
        mt20: {
            marginTop: 20,
        },
        mt25: {
            marginTop: 25,
        },
        mt30: {
            marginTop: 30,
        },
        mt35: {
            marginTop: 35,
        },
        mt40: {
            marginTop: 40,
        },
        mt45: {
            marginTop: 45,
        },
        mr5: {
            marginRight: 5,
        },
        mr10: {
            marginRight: 10,
        },
        mr15: {
            marginRight: 15,
        },
        mr20: {
            marginRight: 20,
        },
        mr25: {
            marginRight: 25,
        },
        mr30: {
            marginRight: 30,
        },
        mr35: {
            marginRight: 35,
        },
        mr40: {
            marginRight: 40,
        },
        mr45: {
            marginRight: 45,
        },
        mb0: {
            marginBottom: 0,
        },
        mb5: {
            marginBottom: 5,
        },
        mb10: {
            marginBottom: 10,
        },
        mb15: {
            marginBottom: 15,
        },
        mb20: {
            marginBottom: 20,
        },
        mb25: {
            marginBottom: 25,
        },
        mb30: {
            marginBottom: 30,
        },
        mb35: {
            marginBottom: 35,
        },
        ml5: {
            marginLeft: 5,
        },
        ml10: {
            marginLeft: 10,
        },
        ml15: {
            marginLeft: 15,
        },
        ml20: {
            marginLeft: 20,
        },
        ml25: {
            marginLeft: 25,
        },
        ml30: {
            marginLeft: 30,
        },
        ml35: {
            marginLeft: 35,
        },
        ml40: {
            marginLeft: 40,
        },
        ml45: {
            marginLeft: 45,
        },
        pt5: {
            paddingTop: 5,
        },
        pt10: {
            paddingTop: 10,
        },
        pt15: {
            paddingTop: 15,
        },
        pt20: {
            paddingTop: 20,
        },
        pt25: {
            paddingTop: 25,
        },
        pt30: {
            paddingTop: 30,
        },
        pt35: {
            paddingTop: 35,
        },
        pb5: {
            paddingBottom: 5,
        },
        pb10: {
            paddingBottom: 10,
        },
        pb15: {
            paddingBottom: 15,
        },
        pb20: {
            paddingBottom: 20,
        },
        pb25: {
            paddingBottom: 25,
        },
        pb30: {
            paddingBottom: 30,
        },
        pb35: {
            paddingBottom: 35,
        },
    },
    rotate180deg: {
        transform: "rotate(180deg)",
    },
    userCard: {
        display: "flex",
        flexDirection: "row-reverse",
        "@media (max-width: 767px)": {
            flexDirection: "column",
        },
    },
    userCardAvatar: {
        width: 150,
        height: 150,
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // margin: "0 32px 0 0",

        "@media (max-width: 767px)": {
            width: 250,
            height: 250,
            margin: "0 auto 24px",
        },
    },
    userCardBody: {
        flex: 1,
    },
    card: {
        margin: "16px 0",
    },
    table: {
        // borderTop: "1px solid #efefef",
        margin: "12px 0 20px",
    },
    tableHeaderItem: {
        borderBottom: "1px solid #efefef",
        display: "flex",
        padding: "4px 0",
    },
    tableItem: {
        borderBottom: "1px solid #efefef",
        display: "flex",
        padding: "8px 0",
        "& > *": {
            flex: 1,
        },
    },
    tableItemText: {
        fontSize: 14,
        flex: 1,
    },
    listingRow: {
        display: "block",
        marginTop: 8,
    },
    listingRowTitle: {
        fontSize: 14,
        color: "#666",
    },
    listingRowValue: {
        fontSize: 16,
    },
};

export default style;
