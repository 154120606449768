import actionCreators from "./actionCreators";

export const initialState = {
    subject: null,
    body: null,
    name: null,
    email: null,
    feedbackSent: false,
    subjects: [],
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateFeedback.type:
            return {
                ...state,
                feedbackSent: true,
            };
        case actionCreators.clearFeedback.type:
            return {
                ...state,
                subject: null,
                body: null,
                name: null,
                email: null,
                feedbackSent: false,
            };
        case actionCreators.updateSubjects.type:
            return {
                ...state,
                subjects: payload,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
