import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./Form";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { default as administratorsActionCreators } from "../../../store/OfficeHours/Administrators/actionCreators";
import actionCreators from "../../../store/OfficeHours/Academics/actionCreators";
import CurrentFormTypes from "../../../common/components/CurrentFormTypes";
import { Paper } from "@material-ui/core";
import globalsCss from "../../../common/styles/globals.css";

class OfficeHoursAcademicsEditContainer extends Component {
    componentDidMount() {
        const { setTitle, getOneOfficeHoursAcademic, match } = this.props;
        setTitle("Bookings \u203A Associated Academics");
        getOneOfficeHoursAcademic({ id: match.params.id });
    }

    componentWillUnmount() {
        const { clearTitle, clearOfficeHoursAcademic } = this.props;
        clearTitle();
        clearOfficeHoursAcademic();
    }

    onSubmit = values => {
        const {
            initialValues,
            addOfficeHoursAcademicToAdmin,
            removeOfficeHoursAcademicFromAdmin,
            history: { push },
        } = this.props;

        // same academic as before - only update associated administrators
        // -----
        if (values.academic.id === initialValues.academic.id) {
            const newAdmins = values.admins;
            const originalAdmins = initialValues.admins;

            let toAdd = [];
            let toRemove = [];

            // collect
            for (let { id } of originalAdmins) {
                const isRemoved = newAdmins.length > 0 && newAdmins.find(obj => obj.id === id);

                if (!isRemoved) {
                    toRemove.push({
                        academicId: values.academic.id,
                        id,
                    });
                }
            }
            for (let { id } of newAdmins) {
                const isAdded =
                    originalAdmins.length > 0 && originalAdmins.find(obj => obj.id === id);

                if (!isAdded) {
                    toAdd.push({
                        academicId: values.academic.id,
                        id,
                    });
                }
            }

            // execute
            const lastToAdd = toAdd.slice(-1)[0];
            for (let item of toAdd) {
                addOfficeHoursAcademicToAdmin({
                    ...item,
                    redirectToListAcademics: lastToAdd.id === item.id && toRemove.length === 0,
                });
            }
            const lastToRemove = toRemove.slice(-1)[0];
            for (let item of toRemove) {
                removeOfficeHoursAcademicFromAdmin({
                    ...item,
                    redirectToListAcademics: lastToRemove.id === item.id,
                });
            }

            if (toRemove.length === 0 && toAdd.length === 0) {
                push("/bookings/administrators/academics");
            }
        }

        // new academic - remove old admins and associate all with new academic
        // -----
        else {
            const newAdmins = values.admins;
            const originalAdmins = initialValues.admins;

            for (let item of newAdmins) {
                addOfficeHoursAcademicToAdmin({
                    academicId: values.academic.id,
                    id: item.id,
                });
            }

            const lastToRemove = originalAdmins.slice(-1)[0];
            for (let item of originalAdmins) {
                removeOfficeHoursAcademicFromAdmin({
                    academicId: initialValues.academic.id,
                    id: item.id,
                    redirectToListAcademics: lastToRemove.id === item.id,
                });
            }
        }
    };

    render() {
        const {
            initialValues,
            history: { push },
            admins,
            academic,
            errorRes: { error, errors },
            getAcademics,
            getAdmins,
            clearAcademics,
            clearAdmins,
        } = this.props;
        const { onSubmit } = this;
        const currentFormType = CurrentFormTypes.EDIT;

        return initialValues && initialValues.admins ? (
            <div>
                <Paper elevation={1}>
                    <div css={globalsCss.inner}>
                        <h2 css={globalsCss.subtitleTitle}>Edit Managed Academic</h2>
                        <Form
                            {...{
                                initialValues,
                                onSubmit,
                                currentFormType,
                                push,
                                admins,
                                academic,
                                getAcademics,
                                getAdmins,
                                clearAcademics,
                                clearAdmins,
                                errors,
                                formError: error,
                            }}
                        />
                    </div>
                </Paper>
            </div>
        ) : (
            <div />
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getAcademics: AutoCompleteActionCreators.getOfficeHoursAcademics.create,
    getAdmins: AutoCompleteActionCreators.getOfficeHoursAdministrators.create,
    clearAcademics: AutoCompleteActionCreators.clearOfficeHoursAcademics.create,
    clearAdmins: AutoCompleteActionCreators.clearOfficeHoursAdministrators.create,
    getOneOfficeHoursAcademic: actionCreators.getOneOfficeHoursAcademic.create,
    clearOfficeHoursAcademic: actionCreators.clearOfficeHoursAcademic.create,
    addOfficeHoursAcademicToAdmin:
        administratorsActionCreators.addOfficeHoursAcademicToAdmin.create,
    removeOfficeHoursAcademicFromAdmin:
        administratorsActionCreators.removeOfficeHoursAcademicFromAdmin.create,
};
const mapStateToProps = ({ OfficeHoursAcademics, AutoComplete }) => ({
    initialValues: {
        academic: {
            id: OfficeHoursAcademics.academic.id,
            firstName: OfficeHoursAcademics.academic.firstName,
            lastName: OfficeHoursAcademics.academic.lastName,
        },
        admins: OfficeHoursAcademics.academic.administrators,
    },
    errorRes: OfficeHoursAcademics.errorRes,
    admins: AutoComplete.officehoursadministrators,
    academic: AutoComplete.officehoursacademics,
});
export default connect(mapStateToProps, dispatchToProps)(OfficeHoursAcademicsEditContainer);
