import actionCreators from "../../store/Auth/actionCreators";
import mainMenuActionCreators from "../../store/MainMenu/actionCreators";
import { switchCase } from "./utils";
import bugsnagClient from "../../common/services/bugsnag";
import { of } from "rxjs";

export default action => errorRes => {
    const error = errorRes.response
        ? {
              ...errorRes.response, // pass all other fields too
              error: errorRes.response.error ? errorRes.response.error : "",
              errors: errorRes.response.errors ? errorRes.response.errors : {},
          }
        : { error: "An unknown error occurred ...", errors: {} };

    const metadata =
        errorRes.status < 500
            ? {
                  ajax: {
                      error,
                      status: errorRes.status,
                      url: errorRes.request && errorRes.request.url,
                      method: errorRes.request && errorRes.request.method,
                  },
              }
            : errorRes;

    if (process.env.REACT_APP_BUGSNAG_KEY !== "DEV") {
        bugsnagClient.notify(errorRes, function(event) {
            (event.severity = errorRes.status < 500 ? "info" : "error"),
                event.addMetadata(metadata);
            event.groupingHash = `${event.errorClass}:${event.errorMessage}`;
        });
    }

    return switchCase({
        401: () => of(actionCreators.setNotAuth.create()),
        500: () => of(mainMenuActionCreators.errorPageRedirect.create()),
    })(() => of(action(error)))(errorRes.status)();
};
