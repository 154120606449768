import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./Form/";
import { default as mainMenuActionCreators } from "../../../store/MainMenu/actionCreators";
import { default as AutoCompleteActionCreators } from "../../../store/AutoComplete/actionCreators";
import { default as officeHoursTeamsActionCreators } from "../../../store/OfficeHours/Teams/actionCreators";
import actionCreators from "../../../store/Channels/Pages/actionCreators";
import currentFormTypes from "../../../common/components/CurrentFormTypes";
import ChannelPageValues from "../../../fixtures/ChannelPage";
import formsCss from "../../../common/styles/forms.css";

class NewPageChannelContainer extends Component {
    componentDidMount() {
        const {
            setTitle,
            getOnePage,
            match,
            setCurrentFormType,
            getListOfficeHoursTeams,
        } = this.props;
        setTitle("Channels \u203A Pages");
        const type = match.params.id ? currentFormTypes.EDIT : currentFormTypes.NEW;
        setCurrentFormType(type);
        getListOfficeHoursTeams("mode=available");
        getOnePage({ id: match.params.id });
    }

    componentWillUnmount() {
        this.props.clearTitle();
    }

    onSubmit = values => {
        this.props.putPage({
            ...values,
            officeHoursTeam: values.officeHoursTeam ? { id: values.officeHoursTeam } : null,
        });
    };

    render() {
        const {
            currentFormType,
            initialValues,
            history: { goBack },
            formObject,
            owners,
            audiences,
            updateForm,
            hasActivePosts,
            errorRes: { error, errors },
            getOwners,
            getAudiences,
            officeHoursTeams,
        } = this.props;
        const { onSubmit } = this;

        return (
            <div>
                <div css={formsCss.genericError}>{error}</div>
                <Form
                    {...{
                        initialValues: {
                            ...initialValues,
                            officeHoursTeam:
                                initialValues.officeHoursTeam && initialValues.officeHoursTeam.id
                                    ? initialValues.officeHoursTeam.id
                                    : null,
                        },
                        onSubmit,
                        currentFormType,
                        goBack,
                        formObject,
                        getOwners,
                        owners,
                        getAudiences,
                        audiences,
                        updateForm,
                        errors,
                        hasActivePosts,
                        officeHoursTeams,
                    }}
                />
            </div>
        );
    }
}

const dispatchToProps = {
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
    getOnePage: actionCreators.getOnePage.create,
    setCurrentFormType: actionCreators.setCurrentFormType.create,
    updateForm: actionCreators.updateForm.create,
    getOwners: AutoCompleteActionCreators.getStaffUsers.create,
    getAudiences: AutoCompleteActionCreators.getAudiences.create,
    putPage: actionCreators.putPage.create,
    getListOfficeHoursTeams: officeHoursTeamsActionCreators.getListOfficeHoursTeams.create,
};
const mapStateToProps = ({ ChannelPage, form, AutoComplete, OfficeHoursTeams }) => ({
    initialValues: ChannelPage.page,
    hasActivePosts: ChannelPage.page.hasActivePosts,
    errorRes: ChannelPage.errorRes,
    currentFormType: ChannelPage.currentFormType,
    formObject: form.Page ? form.Page : { values: ChannelPageValues },
    owners: AutoComplete.staff.map(owner =>
        Object.assign({ fullName: `${owner.firstName} ${owner.lastName}` }, owner)
    ),
    audiences: AutoComplete.audiences,
    officeHoursTeams: ChannelPage.page.officeHoursTeam
        ? [ChannelPage.page.officeHoursTeam, ...OfficeHoursTeams.teams]
        : OfficeHoursTeams.teams,
});
export default connect(mapStateToProps, dispatchToProps)(NewPageChannelContainer);
