import actionCreators from "./actionCreators";
import { Building, BuildingDetails } from "../../app/CampusMapping/types/Building";
import { Dictionary } from "../../types/generic";

export interface CampusMappingState {
    errorRes: { error: string; errors: Dictionary<string> };
    buildings: Building[];
    building: BuildingDetails;
}

export const initialState = {
    buildings: [],
    building: {
        id: null,
        name: null,
    },
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListBuildings.type:
            return {
                ...state,
                buildings: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneBuilding.type:
            return {
                ...state,
                building: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
