import React, { FC, useState, useEffect } from "react";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { Button, Paper } from "@material-ui/core";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Notification/actionCreators";
import mainMenuActionCreators from "../../../store/MainMenu/actionCreators";
import globalsCss from "../../../common/styles/globals.css";
import tableCss from "../../../common/styles/table.css";
import formsCss from "../../../common/styles/forms.css";
import { MainTitle } from "../../../common/components/MainTitle";
import { RedirectButton } from "../../../common/components/RedirectButton";
import { TableComponent } from "../../../common/components/DefaultTable";
import PromptDialog from "../../../common/components/PromptDialog";
import { rawUpdateListOnFilterChange } from "../../../common/services/FilterList";
import { AutoRenewIcon, EditIcon, DeleteIcon, PostIcon } from "../../../common/components/icons";
import { decorateTableItemsJobTypes } from "../../../common/services/decorateTableItems";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import { capitalize } from "lodash";
import { statusCallback } from "../../../common/services/tableCallbacks";
import { prepareFilterQuery } from "../../../common/services/utils";
import { GetFormattedDate, GetFormattedTime } from "../../../common/components/Time";
import { Notification, NotificationDelivery, MessageType } from "../types/Notification";
import StandardNotificationsFilterRow from "../components/StandardNotificationsFilterRow";

const ListStandardNotification: FC<{ history }> = ({ history }) => {
    const dispatch = useDispatch();
    const { nextPage, data: notifications, count } = useSelector(
        (state: AppState) => state.Notification.notifications
    );
    const notificationsEmails = useSelector(
        (state: AppState) => state.Notification.notificationsEmails
    );

    const [filter, setFilter] = useState(undefined);
    const [from, setFrom] = useState<Date | undefined>(undefined);
    const [to, setTo] = useState<Date | undefined>(undefined);

    const [subscription, setSubscription] = useState<Subscription | undefined>(undefined);
    const [removeDialog, setRemoveDialog] = useState(false);
    const [removeId, setRemoveId] = useState(null);
    const [filter$, setFilter$] = useState(new Subject());
    const { push } = history;

    const decorators = [
        {
            type: decorateTableItemsJobTypes.TRANSLATE,
            payload: {
                date: "Date",
                time: "Time",
                type: "Type",
                numRecipients: "No. of recipients",
                message: "Message",
            },
        },
        {
            type: decorateTableItemsJobTypes.STYLE_ROW,
            payload: {
                type: tableCss.columnWidth150,
                numRecipients: tableCss.columnWidth150,
                date: tableCss.columnWidth200,
                time: tableCss.columnWidth150,
            },
        },
    ];

    const callbacks = {
        status: statusCallback,
        numRecipients: item => (item.status === "sent" ? item.numRecipients : "-"),
        type: item =>
            `${
                item.type === MessageType.EMAIL_AND_MOBILE
                    ? "Mobile + Email"
                    : capitalize(item.type)
            }`,
    };

    const buttons = items => index => [
        {
            path: `/notifications/standard/preview/${items[index].id}`,
            label: "Preview",
            fnLabel: "push",
            icon: <PostIcon css={tableCss.actionsMenuItemIcon} />,
            hide: !items[index].id,
        },

        {
            path: `/notifications/standard/edit/${items[index].id}`,
            label: "Edit",
            fnLabel: "push",
            icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
            hide: !(
                items[index].status === NotificationDelivery.DRAFT ||
                items[index].status === NotificationDelivery.SCHEDULE
            ),
        },
        {
            path: `${items[index] ? items[index].id : null}`,
            label: "Delete",
            fnLabel: "remove",
            icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
            hide: !(
                items[index].status === NotificationDelivery.DRAFT ||
                items[index].status === NotificationDelivery.SCHEDULE
            ),
        },
    ];

    const columns = ["status", "date", "time", "type", "numRecipients", "message"];

    const getNotifications = payload =>
        dispatch(actionCreators.getStandardNotifications.create(payload));

    useEffect(() => {
        dispatch(mainMenuActionCreators.setTitle.create("Notifications"));
        dispatch(actionCreators.getStandardNotifications.create());
        setSubscription(
            filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
                rawUpdateListOnFilterChange({
                    getFn: getNotifications,
                    filter: prepareFilterQuery(term),
                });
            })
        );

        return () => {
            dispatch(mainMenuActionCreators.clearTitle.create());

            if (subscription) {
                subscription.unsubscribe();
                setSubscription(undefined);
            }
        };
    }, []);

    useEffect(() => {
        if (!notificationsEmails) dispatch(actionCreators.getNotificationEmails.create());
    }, [notificationsEmails]);

    const handler = ({ field, value }) => {
        switch (field) {
            case "filter":
                setFilter(value || undefined);
                break;
            case "from":
                setFrom(value || undefined);
                break;
            case "to":
                setTo(value || undefined);
                break;
        }

        filter$.next({
            filter: field === "filter" ? value || undefined : filter,
            from:
                field === "from"
                    ? value
                        ? value.toISOString()
                        : undefined
                    : from
                    ? from.toISOString()
                    : undefined,
            to:
                field === "to"
                    ? value
                        ? value.toISOString()
                        : undefined
                    : to
                    ? to.toISOString()
                    : undefined,
        });
    };

    const onFilterChangeHandler = field => ({ target: { value } }) => {
        handler({ field, value });
    };

    const onDateChangeHandler = (field, newValue = undefined) => value => {
        handler({ field, value: newValue !== undefined ? newValue : value });
    };

    const onLoadMoreClickHandler = page => () => {
        dispatch(
            actionCreators.loadMoreStandardNotifications.create(
                prepareFilterQuery({ filter, from, to, page })
            )
        );
    };

    const handleOpen = id => {
        setRemoveDialog(true);
        setRemoveId(id);
    };

    const handleClose = confirmed => {
        if (confirmed === true && removeId) {
            dispatch(actionCreators.deleteStandardNotification.create(removeId));
        }
        setRemoveDialog(false);
        setRemoveId(null);
    };

    const remove = itemID => {
        handleOpen(itemID);
    };

    const RedirectButtonJS = RedirectButton as any;

    return (
        <div>
            <MainTitle title="Standard Notifications" />
            <div css={globalsCss.actions}>
                <div css={globalsCss.actionsItem}>
                    <RedirectButtonJS
                        label="Add standard notification"
                        url="/notifications/standard/new"
                        {...{ push }}
                    />
                </div>
            </div>
            <Paper elevation={1}>
                <StandardNotificationsFilterRow
                    {...{
                        from,
                        to,
                        filter,
                        onFilterChangeHandler,
                        onDateChangeHandler,
                    }}
                />
                <TableComponent
                    items={notifications.map((item: Notification) => ({
                        ...item,
                        date: GetFormattedDate(item.date, "ddd D MMM YYYY"),
                        time: `${GetFormattedTime(item.date, "h.mma")}`,
                    }))}
                    fns={{ push, remove }}
                    buttons={buttons(notifications)}
                    callbacks={callbacks}
                    decorators={decorators}
                    columns={columns}
                />
            </Paper>
            <PromptDialog open={removeDialog} handleClose={handleClose}>
                Are you sure you wanted to delete this notification?
            </PromptDialog>
            {nextPage > 0 && (
                <div css={tableCss.loadMore}>
                    <Button
                        onClick={onLoadMoreClickHandler(nextPage)}
                        variant="contained"
                        color="secondary"
                    >
                        <AutoRenewIcon css={formsCss.btnIcon} />
                        Load more
                    </Button>
                    <span css={tableCss.loadMoreLabel}>
                        <small>
                            (showing {notifications.length} out of {count})
                        </small>
                    </span>
                </div>
            )}
        </div>
    );
};

export default ListStandardNotification;
