import { store } from "../../store";
import qs from "query-string";
import { ajax } from "rxjs/ajax";

const baseHeaders = () => ({
    ...(store.getState().Auth.token != null ? { "x-api-token": store.getState().Auth.token } : {}),
    "Content-Type": "application/json",
    "X-Api-Version": process.env.REACT_APP_API_VERSION ? process.env.REACT_APP_API_VERSION : "1",
});

const withQuery = (url, querystring) => [url, querystring].filter(item => !!item).join("?");
export const get = (url, query, headers) =>
    ajax.get(withQuery(url, prepareFilterQuery(query)), Object.assign({}, baseHeaders(), headers));

export const post = (url, body, headers) =>
    ajax.post(url, body, Object.assign({}, baseHeaders(), headers));

export const put = (url, body, headers) =>
    ajax.put(url, body, Object.assign({}, baseHeaders(), headers));

export const patch = (url, body, headers) =>
    ajax.patch(url, body, Object.assign({}, baseHeaders(), headers));

export const remove = (url, headers, body) =>
    ajax({
        url,
        method: "DELETE",
        headers: Object.assign({}, baseHeaders(), headers),
        body,
    });

export const blob = (url, query, headers) =>
    ajax({
        url: withQuery(url, prepareFilterQuery(query)),
        method: "GET",
        headers: Object.assign({}, baseHeaders(), headers),
        responseType: "blob",
    });

export const switchCase = cases => defaultCase => key =>
    cases.hasOwnProperty(key) ? cases[key] : defaultCase;

export const curry = (f, arr = []) => (...args) =>
    (a => (a.length === f.length ? f(...a) : curry(f, a)))([...arr, ...args]);

export const compose = (...fns) => fns.reduce((f, g) => f(g(fns)));

export const prepareFilterQuery = params => qs.stringify(params);

export const apiUrl = endpoint => `https://${process.env.REACT_APP_API_HOST}/${endpoint}`;
