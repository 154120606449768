import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../../MainMenu/actionCreators";
import { default as froalaActionCreators } from "../../../Froala/actionCreators";
import errorHandler from "../../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../../common/services/utils";

const articleStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListArticle.type,
            actionCreators.loadMoreArticles.type,
            actionCreators.getOneArticle.type,
            actionCreators.postArticle.type,
            actionCreators.moveDownArticle.type,
            actionCreators.moveUpArticle.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const articleClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListArticle.type,
            actionCreators.updateListArticle.type,
            actionCreators.updateOneArticle.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListArticlesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListArticle.type),
        switchMap(({ payload: { categoryId, param } }) =>
            ajax
                .get(
                    ajax.apiUrl(
                        `admin/how-to-category/${categoryId}/articles/?${param ? param : ""}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.createListArticle.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const loadMoreArticlesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreArticles.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `admin/how-to-category/${action.payload.categoryId}/articles/?${action.payload.param}`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateListArticle.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const getOneArticleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneArticle.type),
        switchMap(action =>
            ajax
                .get(
                    ajax.apiUrl(
                        `admin/how-to-category/${action.payload.categoryId}/articles/${action.payload.id}/`
                    )
                )
                .pipe(
                    mergeMap(({ response }) =>
                        of(actionCreators.updateOneArticle.create(response))
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const newArticleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postArticle.type),
        switchMap(action =>
            ajax
                .post(
                    ajax.apiUrl(`admin/how-to-category/${action.payload.categoryId}/articles/`),
                    action.payload.article
                )
                .pipe(
                    mergeMap(() =>
                        of(
                            froalaActionCreators.commitFroalaChanges.create(),
                            actionCreators.pushListArticles.create({
                                categoryId: action.payload.categoryId,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const editArticleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putArticle.type),
        switchMap(action =>
            ajax
                .put(
                    ajax.apiUrl(
                        `admin/how-to-category/${action.payload.categoryId}/articles/${action.payload.article.id}/`
                    ),
                    action.payload.article
                )
                .pipe(
                    mergeMap(() =>
                        of(
                            froalaActionCreators.commitFroalaChanges.create(),
                            actionCreators.pushListArticles.create({
                                categoryId: action.payload.categoryId,
                            })
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const pushListArticlesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListArticles.type),
        mergeMap(action =>
            of(push(`/knowledge-base/categories/${action.payload.categoryId}/articles`))
        )
    );

const removeArticleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeArticle.type),
        switchMap(action =>
            ajax
                .remove(
                    ajax.apiUrl(
                        `admin/how-to-category/${action.payload.categoryId}/articles/${action.payload.id}/`
                    )
                )
                .pipe(
                    mapTo(
                        actionCreators.getListArticle.create({
                            categoryId: action.payload.categoryId,
                        })
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const moveUpArticleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.moveUpArticle.type),
        switchMap(action =>
            ajax
                .patch(
                    ajax.apiUrl(
                        `admin/how-to-category/${action.payload.categoryId}/articles/${action.payload.id}/up/`
                    ),
                    action.payload
                )
                .pipe(
                    mapTo(
                        actionCreators.getListArticle.create({
                            categoryId: action.payload.categoryId,
                        })
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const moveDownArticleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.moveDownArticle.type),
        switchMap(action =>
            ajax
                .patch(
                    ajax.apiUrl(
                        `admin/how-to-category/${action.payload.categoryId}/articles/${action.payload.id}/down/`
                    ),
                    action.payload
                )
                .pipe(
                    mapTo(
                        actionCreators.getListArticle.create({
                            categoryId: action.payload.categoryId,
                        })
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    articleStartLoadingEpic,
    articleClearLoadingEpic,
    getListArticlesEpic,
    loadMoreArticlesEpic,
    getOneArticleEpic,
    newArticleEpic,
    pushListArticlesEpic,
    editArticleEpic,
    removeArticleEpic,
    moveUpArticleEpic,
    moveDownArticleEpic
);
