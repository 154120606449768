import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Paper, Button } from "@material-ui/core";
import actionCreators from "../../../../store/NotificationBanner/actionCreators";
import { default as mainMenuActionCreators } from "../../../../store/MainMenu/actionCreators";

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { TableComponent } from "../../../../common/components/DefaultTable";
import { RedirectButton } from "../../../../common/components/RedirectButton";

import { MainTitle } from "../../../../common/components/MainTitle";
import { rawUpdateListOnFilterChange } from "../../../../common/services/FilterList";
import PromptDialog from "../../../../common/components/PromptDialog";
import globalsCss from "../../../../common/styles/globals.css";
import tableCss from "../../../../common/styles/table.css";

import { decorateTableItemsJobTypes } from "../../../../common/services/decorateTableItems";

import parseDates from "../../../../common/services/parseDates";
//import TriggerTask from "./components/TriggerTask";
import formsCss from "../../../../common/styles/forms.css";
import { DeleteIcon, AutoRenewIcon, EditIcon } from "../../../../common/components/icons";
import FilterRow from "../../../../common/components/FilterRow";

const decorators = [
    {
        type: decorateTableItemsJobTypes.TRANSLATE,
        payload: {
            teaser: "Teaser",
            startAt: "Start at",
            endAt: "End at",
        },
    },
    {
        type: decorateTableItemsJobTypes.STYLE_ROW,
        payload: {
            startAt: tableCss.columnWidth250,
            endAt: tableCss.columnWidth250,
        },
    },
];

const buttons = items => index => [
    {
        path: `/calendar/notification-banners/edit/${items[index].id}`,
        label: "Edit",
        fnLabel: "push",
        icon: <EditIcon css={tableCss.actionsMenuItemIcon} />,
        hide: !items[index].id,
    },
    {
        path: items[index].id,
        label: "Delete",
        fnLabel: "remove",
        icon: <DeleteIcon css={tableCss.actionsMenuItemIcon} />,
        hide: !items[index].id,
    },
];

const columns = ["id", "teaser", "startAt", "endAt"];

class NotificationBannerListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: undefined,
            removeDialog: false,
            removeId: null,
        };
        this.filter$ = new Subject();
    }

    componentDidMount() {
        const { getNotifications, setTitle } = this.props;
        setTitle("Notifications");
        getNotifications({});
        this.subscription = this.filter$
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(filter => {
                rawUpdateListOnFilterChange({
                    getFn: getNotifications,
                    filter,
                });
            });
    }

    componentWillUnmount() {
        this.props.clearTitle();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onFilterChangeHandler = ({ target: { value } }) => {
        // do not query empty filter
        this.setState({ filter: value || undefined }, () =>
            this.filter$.next({ filter: this.state.filter })
        );
    };

    onLoadMoreClickHandler = page => () => {
        const { filter } = this.state;
        const { loadMoreTemplates } = this.props;
        loadMoreTemplates({ filter, page });
    };

    handleOpen = id => {
        this.setState({ removeDialog: true, removeId: id });
    };

    handleClose = confirmed => {
        if (confirmed === true && this.state.removeId) {
            this.props.deleteNotification(this.state.removeId);
        }

        this.setState({ removeDialog: false, removeId: null });
    };

    remove = itemID => {
        this.handleOpen(itemID);
    };

    render() {
        const {
            notifications,
            count,
            nextPage,
            history: { push },
        } = this.props;
        const { filter, removeDialog } = this.state;

        return (
            <div>
                <MainTitle title="Calendar Banners" />
                <div css={globalsCss.actions}>
                    <div css={globalsCss.actionsItem}>
                        <RedirectButton
                            label="Add new calendar banner"
                            url="/calendar/notification-banners/new"
                            {...{ push }}
                        />
                    </div>
                </div>
                <Paper elevation={1}>
                    <FilterRow
                        filter={filter || ""}
                        onFilterChangeHandler={this.onFilterChangeHandler}
                    />
                    <TableComponent
                        items={notifications}
                        fns={{ push, remove: this.remove }}
                        decorators={decorators}
                        columns={columns}
                        buttons={buttons(notifications)}
                    />
                </Paper>
                <PromptDialog open={removeDialog} handleClose={this.handleClose}>
                    Are you sure you wanted to delete this notification?
                </PromptDialog>
                {nextPage > 0 && (
                    <div css={tableCss.loadMore}>
                        <Button
                            onClick={this.onLoadMoreClickHandler(nextPage)}
                            variant="contained"
                            color="secondary"
                        >
                            <AutoRenewIcon css={formsCss.btnIcon} />
                            Load more
                        </Button>
                        <span css={tableCss.loadMoreLabel}>
                            <small>
                                (showing {notifications.length} out of {count})
                            </small>
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ NotificationBanner: { notifications } }) => ({
    notifications: notifications.data.map(parseDates),
    count: notifications.count,
    nextPage: notifications.nextPage,
    // task,
});

const mapDispatchToProps = {
    getNotifications: actionCreators.getNotificationBanners.create,
    deleteNotification: actionCreators.deleteNotificationBanner.create,
    loadMoreNotifications: actionCreators.loadMoreNotificationBanners.create,
    setTitle: mainMenuActionCreators.setTitle.create,
    clearTitle: mainMenuActionCreators.clearTitle.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBannerListContainer);
