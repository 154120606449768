import React, { FC, useEffect, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { Paper, Button, Chip } from "@material-ui/core";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Notification/actionCreators";
import mainMenuActionCreators from "../../../store/MainMenu/actionCreators";
import { MainTitle } from "../../../common/components/MainTitle";
import globalsCss from "../../../common/styles/globals.css";
import formsCss from "../../../common/styles/forms.css";
import { NotificationPreview } from "../components/NotificationPreview";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store/reducers";
import { DateTimeFull } from "../../../common/components/Time";
import { TimetableNotification } from "../types/Notification";
import { moduleLabel, groupLabel } from "./helpers";
import { LseGroup, LseGroupTeaching } from "../types/LseGroups";

interface TeachingLabelProps {
    teaching: LseGroupTeaching;
}

export const TeachingLabel: FC<TeachingLabelProps> = ({ teaching }) => (
    <Fragment>
        <span css={globalsCss.block}>
            {teaching.day.substring(0, 3)} {teaching.startTime} - {teaching.finishTime}
        </span>
        <div css={globalsCss.listingRow}>
            <div css={globalsCss.listingRowTitle}>Week</div>
            <div css={globalsCss.listingRowValue}>{teaching.weeksNumber}</div>
        </div>
        <div css={globalsCss.listingRow}>
            <div css={globalsCss.listingRowTitle}>Room</div>
            <div css={globalsCss.listingRowValue}>{teaching.roomId}</div>
        </div>
    </Fragment>
);

interface ReceipientslProps {
    notification: TimetableNotification;
}

export const Receipients: FC<ReceipientslProps> = ({ notification: { audience } }) => {
    if (!audience || !audience.lseModules) {
        return null;
    }

    const { module, group, teachings } = audience.lseModules[0];
    if (!module) return null;

    const parsedTeachings =
        group && teachings ? group.teachings.filter(t => teachings.includes(t.id)) : [];

    return (
        <Fragment>
            <p>
                <strong>Module:</strong>
                <br />
                {moduleLabel(module)}
            </p>
            <p>
                <strong>Group code:</strong>
                <br /> {group ? groupLabel(group) : "All groups"}
            </p>
            {group && parsedTeachings.length > 0 && (
                <div className="row">
                    <div className="col-xs-12">
                        <strong>Teachings:</strong>
                    </div>
                    {parsedTeachings.map(t => (
                        <div
                            className="col-sm-4 col-xs-12"
                            css={globalsCss.helpers.mb20}
                            key={t.id}
                        >
                            <TeachingLabel teaching={t} />
                        </div>
                    ))}
                </div>
            )}
        </Fragment>
    );
};

interface MatchParams {
    id?: string;
}

const PreviewTimetableNotification: FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
    const id = match.params.id;
    const { push } = history;
    const dispatch = useDispatch();

    const notificationsEmails = useSelector(
        (state: AppState) => state.Notification.notificationsEmails
    );
    const notification: TimetableNotification = useSelector(
        (state: AppState) => state.Notification.notification as TimetableNotification
    );

    const notificationEmailPreview = useSelector(
        (state: AppState) => state.Notification.notificationEmailPreview
    );

    const { error } = useSelector((state: AppState) => state.Notification.errorRes);

    useEffect(() => {
        dispatch(mainMenuActionCreators.setTitle.create("Notifications"));
        const id = match.params.id;

        if (id) dispatch(actionCreators.getTimetableNotification.create(id));

        return () => {
            dispatch(mainMenuActionCreators.clearTitle.create());
        };
    }, []);

    useEffect(() => {
        if (!notificationsEmails) dispatch(actionCreators.getNotificationEmails.create());
    }, [notificationsEmails]);

    useEffect(() => {
        if (notification && notification.message) {
            const { audience, message, additionalEmailText, subject } = notification;
            dispatch(
                actionCreators.getNotificationEmailPreview.create({
                    audience,
                    message,
                    subject,
                    additionalEmailText,
                })
            );
        }
    }, [notification]);

    return (
        <div>
            <MainTitle title="Notification Details" type={"[Timetable]"} />

            <Paper elevation={1}>
                <div css={globalsCss.inner}>
                    {notification && notification.id && (
                        <div>
                            {error && <div css={formsCss.genericError}>{error}</div>}
                            <Fragment>
                                <p>
                                    Status: <strong>{notification.status || "draft"}</strong>
                                </p>
                                {notification.finishedAt ? (
                                    <p>
                                        Sent date:{" "}
                                        <strong>
                                            <DateTimeFull date={notification.finishedAt} />
                                        </strong>
                                    </p>
                                ) : (
                                    notification.scheduledAt && (
                                        <p>
                                            Scheduled at:{" "}
                                            <strong>
                                                <DateTimeFull date={notification.scheduledAt} />
                                            </strong>
                                        </p>
                                    )
                                )}
                            </Fragment>

                            <hr />

                            <div css={globalsCss.helpers.mt35}>
                                <Receipients notification={notification} />
                                {notification.additionalEmail &&
                                    notification.additionalEmail.length > 0 && (
                                        <div>
                                            <strong>Additional recipients:</strong>
                                            <br />
                                            {notification.additionalEmail.map(i => (
                                                <div key={i}>
                                                    {i}
                                                    <br />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                            </div>

                            <hr />

                            <div css={globalsCss.helpers.mt35}>
                                <NotificationPreview
                                    notification={notification}
                                    notificationsEmails={notificationsEmails}
                                    notificationEmailPreview={notificationEmailPreview}
                                />
                            </div>
                        </div>
                    )}

                    <div css={formsCss.actions}>
                        <Button
                            onClick={() => push("/notifications/timetable/list")}
                            css={formsCss.btnBack}
                        >
                            Back to the list
                        </Button>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default PreviewTimetableNotification;
