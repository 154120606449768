/** @jsx jsx */
import { jsx } from "@emotion/core";
import globalsCss from "../styles/globals.css";

export const SubTitle = ({ title, subtitle, type, marginTop, noMarginBottom, style }) => {
    return (
        <h2
            css={[
                globalsCss.subtitleTitle,
                marginTop && globalsCss.helpers.mt20,
                noMarginBottom && { marginBottom: 0 },
                style,
            ]}
        >
            {title}
            {type && <span css={globalsCss.subtitleTitleType}>{type}</span>}
            {subtitle && <span css={globalsCss.subtitleSubtitle}>{subtitle}</span>}
        </h2>
    );
};
