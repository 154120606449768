import actionCreators from "./actionCreators";
import page from "../../../fixtures/ChannelPage";
import { removeProp } from "../../helpers";
import channelActionCreators from "../actionCreators";

export const initialState = {
    pages: {
        data: [],
        count: 0,
        nextPage: 0,
        queryParams: "",
    },
    page,
    errorRes: { error: "", errors: {} },
    currentFormType: null,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListPage.type:
            return {
                ...state,
                pages: { ...payload, queryParams: state.pages.queryParams },
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListPage.type:
            payload.data = [
                ...state.pages.data,
                ...payload.data.filter(
                    page => !state.pages.data.map(item => item.id).includes(page.id)
                ),
            ];
            return {
                ...state,
                pages: { ...payload, queryParams: state.pages.queryParams },
                errorRes: initialState.errorRes,
            };

        case actionCreators.getListPage.type:
        case actionCreators.loadMorePages.type:
            return {
                ...state,
                pages: { ...state.pages, queryParams: payload },
            };

        case actionCreators.updateOnePage.type:
            return {
                ...state,
                page: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.setCurrentFormType.type:
            return {
                ...state,
                currentFormType: payload,
            };
        case actionCreators.clearErrors.type:
            return {
                ...state,
                errorRes: state.errorRes.errors[payload]
                    ? {
                          ...state.errorRes,
                          errors: removeProp(state.errorRes.errors, payload),
                      }
                    : state.errorRes,
            };
        case channelActionCreators.confirmSetChannelAsRecommended.type:
            return {
                ...state,
                page:
                    state.page.id === Number(payload)
                        ? { ...state.page, isRecommended: true }
                        : state.page,
                pages: {
                    ...state.pages,
                    data: state.pages.data.map(p =>
                        p.id === Number(payload) ? { ...p, isRecommended: true } : p
                    ),
                },
                errorRes: initialState.errorRes,
            };
        case channelActionCreators.confirmChannelRemoveFromRecommended.type:
            return {
                ...state,
                page:
                    state.page.id === Number(payload)
                        ? { ...state.page, isRecommended: false }
                        : state.page,
                pages: {
                    ...state.pages,
                    data: state.pages.data.map(p =>
                        p.id === Number(payload) ? { ...p, isRecommended: false } : p
                    ),
                },
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearQueryParams.type:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    queryParams: initialState.pages.queryParams,
                },
            };
        default:
            return state;
    }
};
